import React from "react";
import Traffic from '../reusable/traffic/Traffic'
import tonicLogo from '../../assets/tonicLogo.svg'

const TonicTiktok = () => {
    return(
        <Traffic trafficName='tonic_tiktok' trafficToSend='TikTok' clientName='Tonic' logo={tonicLogo} minusDate={2} />
    )
}

export default TonicTiktok