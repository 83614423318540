import React, { useState, useEffect, useContext } from "react";
import './sidebar.css'
import { NavLink, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { IoHomeOutline, IoTrendingUp, IoEarth, IoCloudDoneOutline } from "react-icons/io5";
import { HiOutlineViewGridAdd, HiOutlineChartBar } from "react-icons/hi";
import { BiBarChartAlt2 } from "react-icons/bi";
import { IoChevronDownOutline, IoMailOutline } from "react-icons/io5";
import { FiFacebook } from "react-icons/fi";
import { AiOutlinePieChart } from "react-icons/ai";
import { BsDatabaseAdd, BsPostcardHeart } from "react-icons/bs";
import { TbBrandTiktok, TbWorldWww, TbBellRinging, TbFileUpload } from "react-icons/tb";
import { GiAutoRepair } from "react-icons/gi";
import { MdOutlineCampaign, MdCircle } from "react-icons/md";

import { Context } from "../../AppContext";
import { host } from "../../hooks/host";
import { access_pages_list } from '../../local/permissionList'

const Sidebar = ({hideSidebar, handleSidebar, errorAlerts, setErrorAlerts}) => {
    const appContext = useContext(Context)
    const location = useLocation()
    const username = Cookies.get('userArray')
    const cookieHaveUser = username ? true : false

    const [selectedMulti, setSelectedMulti] = useState('')
    const [rules, setRules] = useState({ display: false, loading: false, count: 3, title: '', message: '' })
    const [dupCamp, setDupCamp] = useState({ loading: false, data: [] })
    
    // returning html attributes and setting state as per the value passed --- this way i can make html elements looks shorter, cleaner and less messy
    const multi_link_container_props = (name) => ({
        className: "sidebar-link-multi-container",
        onMouseEnter: () => (setSelectedMulti(name)),
        onMouseLeave: () => (setSelectedMulti(''))
    });

    // returning html attributes --- this way i can make html elements look shorter, cleaner and less messy
    const multi_link_props = () => ({
        className: "sidebar-link-multi-body",
        onClick: () => handleSidebar()
    });

    // returning html attributes --- this way i can make html elements look shorter, cleaner and less messy
    const multi_link_dot_props = (path) => ({
        size: (location?.pathname === path) ? 7 : 4,
        color: (location?.pathname === path) ? "#FFF" : '#777',
        style: { position: 'absolute', transition: '200ms', left: (location?.pathname === path) ? '-18px' : '-16px' }
    });

    useEffect(() => {
        if (window.innerWidth < 790) {
            if (hideSidebar === true) {
                document.getElementById('sidebar').style.opacity = 0
                document.getElementById('sidebar').style.pointerEvents = 'none'
            } else {
                document.getElementById('sidebar').style.opacity = 1
                document.getElementById('sidebar').style.pointerEvents = 'all'
            }
        }
    }, [hideSidebar])
    
    // listen window resize to control the sidebar
    useEffect(() => {
        let timeout;
        const handleResize = () => {
            clearTimeout(timeout);
            
            timeout = setTimeout(() => {
                if (window.innerWidth > 785) {
                    document.getElementById('sidebar').style.opacity = 1
                    document.getElementById('sidebar').style.pointerEvents = 'all'
                }
                else {
                    document.getElementById('sidebar').style.opacity = 0
                    document.getElementById('sidebar').style.pointerEvents = 'none'
                }
            }, 100);
        }

        window.addEventListener("resize", handleResize);
    
        return () => window.removeEventListener("resize", handleResize);
    }, [])

    // check duplicate campaigns for first time
    useEffect(() => {
        if (appContext.role !== 'widget') {
            handleCheckDupCamp()
        }
    }, [])

    // return true/false depending on what value was passed to control heights
    const styleCondition = (type) => {
        let result = false;

        if (type === 'Performance') {
            if (selectedMulti === 'Performance' || location?.pathname === '/bestPerforming' || location?.pathname === '/stock' || location?.pathname === '/fbAccounts') {
                result = true
            } else {
                result = false
            }
        } else if (type === 'FB-Fan-Pages') {
            if (selectedMulti === 'FB-Fan-Pages' || location?.pathname === '/FFPData' || location?.pathname === '/FFPLinksSetUp' || location?.pathname === '/FFPPostsCreation') {
                result = true
            } else {
                result = false
            }
        } else if (type === 'Tonic') {
            if (selectedMulti === 'Tonic' || location?.pathname === '/tonicTotal' || location?.pathname === '/tonicFacebook' || location?.pathname === '/tonicTiktok') {
                result = true
            } else {
                result = false
            }
        } else if (type === 'System1') {
            if (selectedMulti === 'System1' || location?.pathname === '/system1Total' || location?.pathname === '/system1Facebook') {
                result = true
            } else {
                result = false
            }
        } else if (type === 'Sedo') {
            if (selectedMulti === 'Sedo' || location?.pathname === '/sedoTotal' || location?.pathname === '/sedoFacebook' || location?.pathname === '/sedoTiktok' || location?.pathname === '/sedoDomain') {
                result = true
            } else {
                result = false
            }
        } else if (type === 'Crossroads') {
            if (selectedMulti === 'Crossroads' || location?.pathname === '/crossroadsTotal' || location?.pathname === '/crossroadsFacebook') {
                result = true
            } else {
                result = false
            }
        } else if (type === 'Ads') {
            if (selectedMulti === 'Ads' || location?.pathname === '/adsTotal' || location?.pathname === '/adsFacebook') {
                result = true
            } else {
                result = false
            }
        }

        return result
    }
    
    // conditionally return inline styles
    const inlineStyleProps = ({ isActive, cond, type }) => {
        let result = {}

        if (cond === 'neutral') {
            result = {
                background: isActive ? '#FFF' : '#333',
                color: isActive ? '#1c1c1e' : '#FFF'
            }
        } else if (cond === 'single') {
            result = {
                background: (isActive && cookieHaveUser) ? '#FFF' : '#333',
                color: (isActive && cookieHaveUser) ? '#1c1c1e' : '#FFF'
            }
        } else if (cond === 'multi-client') {
            result = {
                background: (isActive && cookieHaveUser) ? '#FFF' : '#333',
                color: (isActive && cookieHaveUser) ? '#1c1c1e' : '#FFF',
                height: styleCondition(type) ? '40px' : '0px',
                fontWeight: (isActive && cookieHaveUser) ? '500' : '400',
                border: (isActive && cookieHaveUser) ? '1px solid #FFF' : '1px solid #444'
            }
        } else if (cond === 'multi-performance') {
            result = {
                background: isActive ? '#FFF' : '#333',
                color: isActive ? '#1c1c1e' : '#FFF',
                height: styleCondition(type) ? '40px' : '0px',
                fontWeight: isActive ? '500' : '400',
                border: isActive ? '1px solid #FFF' : '1px solid #444'
            }
        }

        return result
    }

    // API to check duplicate campaigns
    const handleCheckDupCamp = async () => {
        setDupCamp(p => { return { ...p, loading: true } })
        const token = Cookies.get("token")
        const username = Cookies.get("userArray") ? Cookies.get("userArray") : ""
        const url = `${host}/api/unused_domains/duplicate_campaign`

        await axios.post(url, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
            }
        })
        .then((response) => {
            const obj = response.data
            setRules(p => { return { ...p, display_dup_camp: false } })

            if (obj && Array.isArray(obj) && (obj.length > 0)) {
                setDupCamp(p => {
                    return {
                        ...p,
                        loading: false,
                        data: (appContext.role === 'admin') ? 
                            obj.map(e => { return { type: "Error", title: e?.campaign_name+` "`+e?.User+`"`, content: e?.act_name }}) :
                            obj.filter(f => f?.User === username).map(e => { return { type: "Error", title: e?.campaign_name+` "`+e?.User+`"`, content: e?.act_name }})
                    }
                })
            } else {
                setDupCamp(p => { return { ...p, loading: false, data: [] } })
            }
        })
        .catch((error) => {
            console.log(error)
            setDupCamp(p => { return { ...p, loading: false, data: [] } })
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        })
    }

    // API to run AUTO OPTIMIZATION
    const handleRunAuto = async () => {
        setRules(p => { return { ...p, display: true, loading: true } })
        const token = Cookies.get("token");
        const url = `${host}/api/data/run_rules`;

        await axios.post(url,{
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
            }
        })
        .then((response) => {
            if (response?.data?.finish === false) {
                setRules(p => { return { ...p, loading: false, count: 3, title: 'Already Executed', message: 'All ON AUTOs have already been executed for today. Getting you back in...' } })
                let intervalID = null
                let temp_count = 3

                intervalID = setInterval(() => {
                    if (temp_count === 1) {
                        setRules(p => { return { ...p, display: false, loading: false, count: 3, title: '', message: '' } })
                        clearInterval(intervalID)
                    } else {
                        setRules(p => { return { ...p, count: temp_count } })
                        temp_count = temp_count - 1
                    }
                }, 1000);
            } else {
                setRules(p => { return { ...p, loading: false, count: 3, title: 'Successful', message: 'Fetching updated results in...' } })
                let intervalID = null
                let temp_count = 3

                intervalID = setInterval(() => {
                    if (temp_count === 1) {
                        setRules(p => { return { ...p, display: false, loading: false, count: 3, title: '', message: '' } })
                        clearInterval(intervalID)
                    } else {
                        setRules(p => { return { ...p, count: temp_count } })
                        temp_count = temp_count - 1
                    }
                }, 1000);
            }

            appContext.setRulesInfo(p => { return { ...p, refresh_after_opt: !p.refresh_after_opt } })
        })
        .catch((error) => {
            console.log(error)
            window.alert("Error occurred in the server!")
            setRules(p => { return { ...p, display: false, loading: false, count: 3, title: '', message: '' } })
            appContext.setRulesInfo(p => { return { ...p, refresh_after_opt: !p.refresh_after_opt } })
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        })
    }

    // conditionally return AUTO OPTIMIZATION pending and executed arrays
    const ruleFilterFunc = (cond) => {
        let result = []

        if (cond === 'pending') {
            result = appContext.rulesInfo.data?.filter(f => (f.state === true) && (f.finish === false))
        }

        else if (cond === 'pending_list') {
            const temp = appContext.rulesInfo.data?.filter(f => (f.state === true) && (f.finish === false))
            const traffic_names = Array.from(new Set(temp.map(e => e?.traffic)))
            traffic_names.map(name => {
                result.push({ traffic: name, count: temp.filter(f => f?.traffic === name)?.length })
            })
        }
        
        else if (cond === 'executed') {
            result = appContext.rulesInfo.data?.filter(f => (f.state === true) && f.result && (f.finish === true))
        }
        
        else if (cond === 'executed_list') {
            const temp = appContext.rulesInfo.data?.filter(f => (f.state === true) && f.result && (f.finish === true))
            const traffic_names = Array.from(new Set(temp.map(e => e?.traffic)))
            traffic_names.map(name => {
                result.push({ traffic: name, count: temp.filter(f => f?.traffic === name)?.length })
            })
        }

        return result
    }

    // conditionally return true or false to display sidebar links
    const linkCondition = ({ type, cond }) => {
        let result = false
        const user_permissions = Cookies.get('userPermissions') ? JSON.parse(Cookies.get('userPermissions')) : []

        if (appContext.role === "admin") {
            result = true
        } else {
            if (type === "compartment") {
                if (user_permissions.find(f => access_pages_list[cond].find(item => item === f))) {
                    result = true
                }
            }
            else if (type === "multi-link-menu") {
                if (cond === "Performance") {
                    if (user_permissions.find(f => (f === 'best-performing') || (f === 'topic-trends') || (f === 'facebook-accounts'))) {
                        result = true
                    }
                } else if (cond === "FB-Fan-Pages") {
                    if (user_permissions.find(f => (f === 'fb-fan-page-data') || (f === 'fb-fan-page-links-set-up') || (f === 'fb-fan-page-posts-creation'))) {
                        result = true
                    }
                }
                else if (user_permissions.find(f => f === cond)) {
                    result = true
                }
            }
            else if (type === "link") {
                if (user_permissions.find(f => f === cond) ) {
                    result = true
                }
            }
        }

        return result
    }
    
    return(
        <>
            {rules.display ? (
                <div style={{ position: 'fixed', zIndex: 990, top: 0, left: 0, height: '100vh', width: '100vw', background: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ height: 'fit-content', width: 'fit-content', maxWidth: '500px', background: '#FFF', padding: '20px', borderRadius: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                        {rules.loading ? (
                            <>
                                <p style={{ fontSize: '24px', fontWeight: '700', textAlign: 'center' }}>Optimizing</p>
                                <div className="loader" />
                                <p style={{ color: '#F00', textAlign: 'center' }}>Depending on the number of ON AUTOs, it can take longer. Please wait and do not do anything to avoid having issues related to FACEBOOK.</p>
                            </>
                        ) : (
                            <>
                                <p style={{ fontSize: '24px', fontWeight: '700', textAlign: 'center' }}>{rules.title}</p>
                                <p style={{ textAlign: 'center' }}>{rules.message}</p>
                                <p style={{ fontSize: '36px' }}>{rules.count}</p>
                            </>
                        )}
                    </div>
                </div>
            ) : (<></>)}
            
            <div id="sidebar" className="sidebar">
                <div className="sidebar-container">
                    <div className="sidebar-section">
                        <h3 className="sidebar-title">DASHBOARD</h3>
                        <div className="sidebar-links">
                            {(appContext.role === "widget") ? (
                                <NavLink to='/' className="sidebar-link-single" onClick={() => handleSidebar()} style={({isActive}) => inlineStyleProps({isActive, cond: 'neutral', type: ''})}>
                                    <HiOutlineViewGridAdd size={16}/>
                                    Widget
                                </NavLink>
                            ) : (
                                <>
                                    {(appContext.role === 'admin') ? (
                                        <NavLink to={(cookieHaveUser) ? '/userPage' : '/'} className="sidebar-link-single" onClick={() => handleSidebar()} style={({isActive}) => inlineStyleProps({isActive, cond: 'neutral', type: ''})}>
                                            <IoHomeOutline size={16}/>
                                            Home
                                        </NavLink>
                                    ) : (
                                        <NavLink to='/' className="sidebar-link-single" onClick={() => handleSidebar()} style={({isActive}) => inlineStyleProps({isActive, cond: 'neutral', type: ''})}>
                                            <IoHomeOutline size={16}/>
                                            Home
                                        </NavLink>
                                    )}
                                    <NavLink to='/widget' className="sidebar-link-single" onClick={() => handleSidebar()} style={({isActive}) => inlineStyleProps({isActive, cond: 'neutral', type: ''})}>
                                        <HiOutlineViewGridAdd size={16}/>
                                        Widget
                                    </NavLink>
                                </>
                            )}

                            {linkCondition({ type: "multi-link-menu", cond: "Performance" }) && (
                                <div {...multi_link_container_props('Performance')}>
                                    <div className="sidebar-link-multi-head" style={{ borderColor: styleCondition('Performance') ? '#777' : '#333', background: styleCondition('Performance') ? 'rgba(255,255,255,0.1)' : '#333' }}>
                                        <div><AiOutlinePieChart size={16}/>Performance</div>
                                        <div style={{ transform: styleCondition('Performance') ? 'rotate(180deg)' : 'rotate(0)' }}><IoChevronDownOutline size={16}/></div>
                                    </div>
                                    <div className="sidebar-link-multi-body-container" style={{ height: styleCondition('Performance') ? 'fit-content' : '0px' }}>
                                        <div>
                                            {linkCondition({ type: "link", cond: "best-performing" }) && (
                                                <NavLink to='/bestPerforming' style={({isActive}) => inlineStyleProps({isActive, cond: 'multi-performance', type: 'Performance'})} {...multi_link_props()}>
                                                    <MdCircle {...multi_link_dot_props('/bestPerforming')}/>
                                                    <HiOutlineChartBar size={12}/>
                                                    Best Performing
                                                </NavLink>    
                                            )}
                                            {linkCondition({ type: "link", cond: "topic-trends" }) && (
                                                <NavLink to='/stock' style={({isActive}) => inlineStyleProps({isActive, cond: 'multi-performance', type: 'Performance'})} {...multi_link_props()}>
                                                    <MdCircle {...multi_link_dot_props('/stock')}/>
                                                    <IoTrendingUp size={12}/>
                                                    Topic Trends
                                                </NavLink>
                                            )}
                                            {linkCondition({ type: "link", cond: "facebook-accounts" }) && (
                                                <NavLink to='/fbAccounts' style={({isActive}) => inlineStyleProps({isActive, cond: 'multi-performance', type: 'Performance'})} {...multi_link_props()}>
                                                    <MdCircle {...multi_link_dot_props('/fbAccounts')}/>
                                                    <FiFacebook size={12}/>
                                                    FB Accounts
                                                </NavLink>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {linkCondition({ type: "multi-link-menu", cond: "FB-Fan-Pages" }) && (
                                <div {...multi_link_container_props('FB-Fan-Pages')}>
                                    <div className="sidebar-link-multi-head" style={{ borderColor: styleCondition('FB-Fan-Pages') ? '#777' : '#333', background: styleCondition('FB-Fan-Pages') ? 'rgba(255,255,255,0.1)' : '#333' }}>
                                        <div><FiFacebook size={16}/>FB Fan Pages</div>
                                        <div style={{ transform: styleCondition('FB-Fan-Pages') ? 'rotate(180deg)' : 'rotate(0)' }}><IoChevronDownOutline size={16}/></div>
                                    </div>
                                    <div className="sidebar-link-multi-body-container" style={{ height: styleCondition('FB-Fan-Pages') ? 'fit-content' : '0px' }}>
                                        <div>
                                            {linkCondition({ type: "link", cond: "fb-fan-page-data" }) && (
                                                <NavLink to='/FFPData' style={({isActive}) => inlineStyleProps({isActive, cond: 'multi-performance', type: 'FB-Fan-Pages'})} {...multi_link_props()}>
                                                    <MdCircle {...multi_link_dot_props('/FFPData')}/>
                                                    <HiOutlineChartBar size={12}/>
                                                    Data
                                                </NavLink>
                                            )}
                                            {linkCondition({ type: "link", cond: "fb-fan-page-links-set-up" }) && (
                                                <NavLink to='/FFPLinksSetUp' style={({isActive}) => inlineStyleProps({isActive, cond: 'multi-performance', type: 'FB-Fan-Pages'})} {...multi_link_props()}>
                                                    <MdCircle {...multi_link_dot_props('/FFPLinksSetUp')}/>
                                                    <TbWorldWww size={12}/>
                                                    Links Set Up
                                                </NavLink>
                                            )}
                                            {linkCondition({ type: "link", cond: "fb-fan-page-posts-creation" }) && (
                                                <NavLink to='/FFPPostsCreation' style={({isActive}) => inlineStyleProps({isActive, cond: 'multi-performance', type: 'FB-Fan-Pages'})} {...multi_link_props()}>
                                                    <MdCircle {...multi_link_dot_props('/FFPPostsCreation')}/>
                                                    <BsPostcardHeart size={12}/>
                                                    Posts Creation
                                                </NavLink>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    
                    {linkCondition({ type: "compartment", cond: "Clients" }) && (
                        <div className="sidebar-section">
                            <h3 className="sidebar-title">CLIENTS</h3>
                            <div className="sidebar-links">
                                {linkCondition({ type: "multi-link-menu", cond: "ads-facebook" }) && (
                                    <div {...multi_link_container_props('Ads')}>
                                        <div className="sidebar-link-multi-head" style={{ borderColor: styleCondition('Ads') ? '#777' : '#333', background: styleCondition('Ads') ? 'rgba(255,255,255,0.1)' : '#333' }}>
                                            <div><BiBarChartAlt2 size={16}/>Ads</div>
                                            <div style={{ transform: styleCondition('Ads') ? 'rotate(180deg)' : 'rotate(0)' }}><IoChevronDownOutline size={16}/></div>
                                        </div>
                                        <div className="sidebar-link-multi-body-container" style={{ height: styleCondition('Ads') ? 'fit-content' : '0px' }}>
                                            <div>
                                                {linkCondition({ type: "link", cond: "ads-facebook" }) && (
                                                    <NavLink to={(cookieHaveUser) ? '/adsTotal' : '/'} style={({isActive}) => inlineStyleProps({isActive, cond: 'multi-client', type: 'Ads'})} {...multi_link_props()}>
                                                        <MdCircle {...multi_link_dot_props('/adsTotal')}/>
                                                        <BsDatabaseAdd size={12}/>
                                                        Total
                                                    </NavLink>
                                                )}
                                                {linkCondition({ type: "link", cond: "ads-facebook" }) && (
                                                    <NavLink to={(cookieHaveUser) ? '/adsFacebook' : '/'} style={({isActive}) => inlineStyleProps({isActive, cond: 'multi-client', type: 'Ads'})} {...multi_link_props()}>
                                                        <MdCircle {...multi_link_dot_props('/adsFacebook')}/>
                                                        <FiFacebook size={12}/>
                                                        Facebook
                                                    </NavLink>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {linkCondition({ type: "multi-link-menu", cond: "crossroads-facebook" }) && (
                                    <div {...multi_link_container_props('Crossroads')}>
                                        <div className="sidebar-link-multi-head" style={{ borderColor: styleCondition('Crossroads') ? '#777' : '#333', background: styleCondition('Crossroads') ? 'rgba(255,255,255,0.1)' : '#333' }}>
                                            <div><BiBarChartAlt2 size={16}/>Crossroads</div>
                                            <div style={{ transform: styleCondition('Crossroads') ? 'rotate(180deg)' : 'rotate(0)' }}><IoChevronDownOutline size={16}/></div>
                                        </div>
                                        <div className="sidebar-link-multi-body-container" style={{ height: styleCondition('Crossroads') ? 'fit-content' : '0px' }}>
                                            <div>
                                                {linkCondition({ type: "link", cond: "crossroads-facebook" }) && (
                                                    <NavLink to={(cookieHaveUser) ? '/crossroadsTotal' : '/'} style={({isActive}) => inlineStyleProps({isActive, cond: 'multi-client', type: 'Crossroads'})} {...multi_link_props()}>
                                                        <MdCircle {...multi_link_dot_props('/crossroadsTotal')}/>
                                                        <BsDatabaseAdd size={12}/>
                                                        Total
                                                    </NavLink>
                                                )}
                                                {linkCondition({ type: "link", cond: "crossroads-facebook" }) && (
                                                    <NavLink to={(cookieHaveUser) ? '/crossroadsFacebook' : '/'} style={({isActive}) => inlineStyleProps({isActive, cond: 'multi-client', type: 'Crossroads'})} {...multi_link_props()}>
                                                        <MdCircle {...multi_link_dot_props('/crossroadsFacebook')}/>
                                                        <FiFacebook size={12}/>
                                                        Facebook
                                                    </NavLink>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {(linkCondition({ type: "multi-link-menu", cond: "sedo-facebook" }) || linkCondition({ type: "multi-link-menu", cond: "sedo-tiktok" }) || linkCondition({ type: "multi-link-menu", cond: "sedo-domain" })) && (
                                    <div {...multi_link_container_props('Sedo')}>
                                        <div className="sidebar-link-multi-head" style={{ borderColor: styleCondition('Sedo') ? '#777' : '#333', background: styleCondition('Sedo') ? 'rgba(255,255,255,0.1)' : '#333' }}>
                                            <div><BiBarChartAlt2 size={16}/>Sedo</div>
                                            <div style={{ transform: styleCondition('Sedo') ? 'rotate(180deg)' : 'rotate(0)' }}><IoChevronDownOutline size={16}/></div>
                                        </div>
                                        <div className="sidebar-link-multi-body-container" style={{ height: styleCondition('Sedo') ? 'fit-content' : '0px' }}>
                                            <div>
                                                {(linkCondition({ type: "link", cond: "sedo-facebook" }) || linkCondition({ type: "link", cond: "sedo-tiktok" }) || linkCondition({ type: "link", cond: "sedo-domain" })) && (
                                                    <NavLink to={(cookieHaveUser) ? '/sedoTotal' : '/'} style={({isActive}) => inlineStyleProps({isActive, cond: 'multi-client', type: 'Sedo'})} {...multi_link_props()}>
                                                        <MdCircle {...multi_link_dot_props('/sedoTotal')}/>
                                                        <BsDatabaseAdd size={12}/>
                                                        Total
                                                    </NavLink>
                                                )}
                                                {linkCondition({ type: "link", cond: "sedo-facebook" }) && (
                                                    <NavLink to={(cookieHaveUser) ? '/sedoFacebook' : '/'} style={({isActive}) => inlineStyleProps({isActive, cond: 'multi-client', type: 'Sedo'})} {...multi_link_props()}>
                                                        <MdCircle {...multi_link_dot_props('/sedoFacebook')}/>
                                                        <FiFacebook size={12}/>
                                                        Facebook
                                                    </NavLink>
                                                )}
                                                {linkCondition({ type: "link", cond: "sedo-tiktok" }) && (
                                                    <NavLink to={(cookieHaveUser) ? '/sedoTiktok' : '/'} style={({isActive}) => inlineStyleProps({isActive, cond: 'multi-client', type: 'Sedo'})} {...multi_link_props()}>
                                                        <MdCircle {...multi_link_dot_props('/sedoTiktok')}/>
                                                        <TbBrandTiktok size={12}/>
                                                        TikTok
                                                    </NavLink>
                                                )}
                                                {linkCondition({ type: "link", cond: "sedo-domain" }) && (
                                                    <NavLink to={(cookieHaveUser) ? '/sedoDomain' : '/'} style={({isActive}) => inlineStyleProps({isActive, cond: 'multi-client', type: 'Sedo'})} {...multi_link_props()}>
                                                        <MdCircle {...multi_link_dot_props('/sedoDomain')}/>
                                                        <TbWorldWww size={12}/>
                                                        Domain
                                                    </NavLink>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {linkCondition({ type: "multi-link-menu", cond: "system1-facebook" }) && (
                                    <div {...multi_link_container_props('System1')}>
                                        <div className="sidebar-link-multi-head" style={{ borderColor: styleCondition('System1') ? '#777' : '#333', background: styleCondition('System1') ? 'rgba(255,255,255,0.1)' : '#333' }}>
                                            <div><BiBarChartAlt2 size={16}/>System1</div>
                                            <div style={{ transform: styleCondition('System1') ? 'rotate(180deg)' : 'rotate(0)' }}><IoChevronDownOutline size={16}/></div>
                                        </div>
                                        <div className="sidebar-link-multi-body-container" style={{ height: styleCondition('System1') ? 'fit-content' : '0px' }}>
                                            <div>
                                                {linkCondition({ type: "link", cond: "system1-facebook" }) && (
                                                    <NavLink to={(cookieHaveUser) ? '/system1Total' : '/'} style={({isActive}) => inlineStyleProps({isActive, cond: 'multi-client', type: 'System1'})} {...multi_link_props()}>
                                                        <MdCircle {...multi_link_dot_props('/system1Total')}/>
                                                        <BsDatabaseAdd size={12}/>
                                                        Total
                                                    </NavLink>
                                                )}
                                                {linkCondition({ type: "link", cond: "system1-facebook" }) && (
                                                    <NavLink to={(cookieHaveUser) ? '/system1Facebook' : '/'} style={({isActive}) => inlineStyleProps({isActive, cond: 'multi-client', type: 'System1'})} {...multi_link_props()}>
                                                        <MdCircle {...multi_link_dot_props('/system1Facebook')}/>
                                                        <FiFacebook size={12}/>
                                                        Facebook
                                                    </NavLink>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {(linkCondition({ type: "multi-link-menu", cond: "tonic-facebook" }) || linkCondition({ type: "multi-link-menu", cond: "tonic-tiktok" })) && (
                                    <div {...multi_link_container_props('Tonic')}>
                                        <div className="sidebar-link-multi-head" style={{ borderColor: styleCondition('Tonic') ? '#777' : '#333', background: styleCondition('Tonic') ? 'rgba(255,255,255,0.1)' : '#333' }}>
                                            <div><BiBarChartAlt2 size={16}/>Tonic</div>
                                            <div style={{ transform: styleCondition('Tonic') ? 'rotate(180deg)' : 'rotate(0)' }}><IoChevronDownOutline size={16}/></div>
                                        </div>
                                        <div className="sidebar-link-multi-body-container" style={{ height: styleCondition('Tonic') ? 'fit-content' : '0px' }}>
                                            <div>
                                                {(linkCondition({ type: "link", cond: "tonic-facebook" }) || linkCondition({ type: "link", cond: "tonic-tiktok" })) && (
                                                    <NavLink to={(cookieHaveUser) ? '/tonicTotal' : '/'} style={({isActive}) => inlineStyleProps({isActive, cond: 'multi-client', type: 'Tonic'})} {...multi_link_props()}>
                                                        <MdCircle {...multi_link_dot_props('/tonicTotal')}/>
                                                        <BsDatabaseAdd size={12}/>
                                                        Total
                                                    </NavLink>
                                                )}
                                                {linkCondition({ type: "link", cond: "tonic-facebook" }) && (
                                                    <NavLink to={(cookieHaveUser) ? '/tonicFacebook' : '/'} style={({isActive}) => inlineStyleProps({isActive, cond: 'multi-client', type: 'Tonic'})} {...multi_link_props()}>
                                                        <MdCircle {...multi_link_dot_props('/tonicFacebook')}/>
                                                        <FiFacebook size={12}/>
                                                        Facebook
                                                    </NavLink>
                                                )}
                                                {linkCondition({ type: "link", cond: "tonic-tiktok" }) && (
                                                    <NavLink to={(cookieHaveUser) ? '/tonicTiktok' : '/'} style={({isActive}) => inlineStyleProps({isActive, cond: 'multi-client', type: 'Tonic'})} {...multi_link_props()}>
                                                        <MdCircle {...multi_link_dot_props('/tonicTiktok')}/>
                                                        <TbBrandTiktok size={12}/>
                                                        TikTok
                                                    </NavLink>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {linkCondition({ type: "compartment", cond: "Rotations" }) && (
                        <div className="sidebar-section">
                            <h3 className="sidebar-title">ROTATIONS</h3>
                            <div className="sidebar-links">
                                {linkCondition({ type: "link", cond: "rotation-by-clients" }) && (
                                    <NavLink to={(cookieHaveUser) ? '/allFacebook' : '/'} className="sidebar-link-single" onClick={() => handleSidebar()} style={({isActive}) => inlineStyleProps({isActive, cond: 'single', type: ''}) }>
                                        <BiBarChartAlt2 size={16}/>
                                        By Clients
                                    </NavLink>
                                )}
                                {linkCondition({ type: "link", cond: "rotation-by-countries" }) && (
                                    <NavLink to={(cookieHaveUser) ? '/allCountriesFacebook' : '/'} className="sidebar-link-single" onClick={() => handleSidebar()} style={({isActive}) => inlineStyleProps({isActive, cond: 'single', type: ''}) }>
                                        <IoEarth size={16}/>
                                        By Countries
                                    </NavLink>
                                )}
                                {linkCondition({ type: "link", cond: "rotation-by-emailoctopus" }) && (
                                    <NavLink to={(cookieHaveUser) ? '/allEmailOctopus' : '/'} className="sidebar-link-single" onClick={() => handleSidebar()} style={({isActive}) => inlineStyleProps({isActive, cond: 'single', type: ''}) }>
                                        <IoMailOutline size={16}/>
                                        By EmailOctopus
                                    </NavLink>
                                )}
                            </div>
                        </div>
                    )}

                    {linkCondition({ type: "compartment", cond: "Others" }) && (
                        <div className="sidebar-section" style={{ marginBottom: '0px' }}>
                            <h3 className="sidebar-title">OTHERS</h3>
                            <div className="sidebar-links">
                                {(appContext.role === 'admin') ? (
                                    <div className="sidebar-link-single"
                                        style={{ height: '40px', padding: '5px 8px', flexDirection: 'column', background: '#4caf50', color: '#FFF', cursor: 'pointer', pointerEvents: rules.loading ? 'none' : 'all' }}
                                        onClick={() => (ruleFilterFunc('pending')?.length > 0) ? (
                                            appContext.setDialogInfo({
                                                mode: "Confirm",
                                                type: "Confirm",
                                                message: [
                                                    { type: "Confirm", title: "Confirm to start optimization", content: "This confirmation is to prevent accidental clicks on this heavy duty button." },
                                                ],
                                                confirm_function: () => handleRunAuto(),
                                                cancel_function: () => {}
                                            })
                                        ) : (
                                            appContext.setDialogInfo({
                                                mode: "Alert",
                                                type: "Info",
                                                message: [
                                                    { type: "Info", title: "Optimization aborted", content: "No rules pending to be executed today." },
                                                ],
                                                confirm_function: () => {},
                                                cancel_function: () => {}
                                            })
                                        )}
                                        onMouseEnter={e => {
                                            e.currentTarget.style.height = 'fit-content'
                                            e.currentTarget.style.filter = 'invert(0%)'
                                            const temp = e.currentTarget.childNodes
                                            temp[0].lastChild.childNodes[1].style.display = 'flex'
                                            temp[0].lastChild.childNodes[2].style.display = 'none'
                                            temp[1].style.display = 'flex'
                                            temp[2].style.display = 'flex'
                                        }}
                                        onMouseLeave={e => {
                                            e.currentTarget.style.height = '40px'
                                            e.currentTarget.style.filter = 'invert(0%)'
                                            const temp = e.currentTarget.childNodes
                                            temp[0].lastChild.childNodes[1].style.display = 'none'
                                            temp[0].lastChild.childNodes[2].style.display = 'flex'
                                            temp[1].style.display = 'none'
                                            temp[2].style.display = 'none'
                                        }}
                                    >
                                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '5px' }}>
                                            <GiAutoRepair size={16}/>
                                            <div style={{ height: '30px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <p>Auto Opt</p>
                                                <div style={{ height: '30px', width: 'fit-content', display: 'none', alignItems: 'center', justifyContent: 'center', padding: '0px 5px', fontSize: '14px', fontWeight: '700', color: '#FFF', borderRadius: '5px', border: '1px solid #FFF', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                                    RUN&nbsp;{'>'}
                                                </div>
                                                <div style={{ height: '30px', width: 'fit-content', display: 'flex' }}>
                                                    <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px 5px', fontSize: '14px', fontWeight: '700', background: '#FFF', color: '#F57D0D', borderRight: '1px solid #4caf50', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }}>{ruleFilterFunc('pending')?.length}</div>
                                                    <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px 5px', fontSize: '14px', fontWeight: '700', background: '#FFF', color: '#1976d2', borderLeft: '1px solid #4caf50', borderTopRightRadius: '5px', borderBottomRightRadius: '5px' }}>{ruleFilterFunc('executed')?.length}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ width: '100%', background: '#FEF2E7', borderRadius: '5px', padding: '5px', display: 'none', flexDirection: 'column', overflow: 'hidden' }}>
                                            <p>
                                                <span style={{ color: '#000', fontSize: '12px', fontWeight: '700', textDecoration: 'underline dotted', textUnderlineOffset: '2px' }}>Pending</span>&nbsp;
                                                <span style={{ fontSize: '12px', fontWeight: '700', background: '#F57D0D', color: '#FFF', borderRadius: '5px', padding: '0px 5px' }}>{ruleFilterFunc('pending')?.length}</span>
                                            </p>
                                            {ruleFilterFunc('pending_list').map((e, i) => (
                                                <div key={i} style={{ widows: '100%', display: 'flex', justifyContent: 'space-between', gap: '5px' }}>
                                                    <p style={{ color: '#000', fontSize: '12px' }}>{e.traffic}</p>
                                                    <p style={{ color: '#000', fontSize: '12px', fontWeight: '700' }}>{e.count}</p>
                                                </div>
                                            ))}
                                        </div>

                                        <div style={{ width: '100%', background: '#E8F1FB', borderRadius: '5px', padding: '5px', display: 'none', flexDirection: 'column', overflow: 'hidden' }}>
                                            <p>
                                                <span style={{ color: '#000', fontSize: '12px', fontWeight: '700', textDecoration: 'underline dotted', textUnderlineOffset: '2px' }}>Executed</span>&nbsp;
                                                <span style={{ fontSize: '12px', fontWeight: '700', background: '#1976d2', color: '#FFF', borderRadius: '5px', padding: '0px 5px' }}>{ruleFilterFunc('executed')?.length}</span>
                                            </p>
                                            {ruleFilterFunc('executed_list').map((e, i) => (
                                                <div key={i} style={{ widows: '100%', display: 'flex', justifyContent: 'space-between', gap: '5px' }}>
                                                    <p style={{ color: '#000', fontSize: '12px' }}>{e.traffic}</p>
                                                    <p style={{ color: '#000', fontSize: '12px', fontWeight: '700' }}>{e.count}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ) : (<></>)}
                                {(appContext.role !== 'widget') ? (
                                    <div className="sidebar-link-single"
                                        style={{ height: '40px', padding: '5px 8px', flexDirection: 'column', background: (dupCamp.data.length > 0) ? '#F00' : 'transparent', color: '#FFF', cursor: 'pointer', pointerEvents: (dupCamp.loading || (dupCamp.data.length === 0)) ? 'none' : 'all' }}
                                        onClick={() => (dupCamp.data.length > 0) && (
                                            appContext.setDialogInfo({
                                                mode: "Alert",
                                                type: "Error",
                                                message: dupCamp.data,
                                                confirm_function: () => {},
                                                cancel_function: () => {}
                                            })
                                        )}
                                        onMouseEnter={e => {
                                            if (dupCamp.loading || (dupCamp.data.length === 0)) {
                                                e.currentTarget.style.opacity = '0.5'
                                            }
                                        }}
                                        onMouseLeave={e => e.currentTarget.style.opacity = '1' }
                                    >
                                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '5px' }}>
                                            <TbBellRinging size={16}/>
                                            <div style={{ height: '30px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <p>Dup Camps</p>
                                                {dupCamp.loading ? (
                                                    <div className="loader" />
                                                ) : (
                                                    <div style={{ height: '30px', width: 'fit-content', display: 'flex' }}>
                                                        <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px 5px', fontSize: '14px', fontWeight: '700', background: '#FFF', color: '#000', borderRadius: '5px' }}>{dupCamp.data.length}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ) : (<></>)}
                                {linkCondition({ type: "link", cond: "error-alert" }) && (
                                    <NavLink to='/errorAlert' className="sidebar-link-single" onClick={() => handleSidebar()} style={({isActive}) => inlineStyleProps({isActive, cond: 'neutral', type: ''})}>
                                        <TbBellRinging size={16}/>
                                        Error Alert
                                        {errorAlerts.display ? (
                                            <div style={{ height: '20px', minWidth: '20px', background: '#FF0000', color: '#FFF', fontSize: '12px', borderRadius: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{errorAlerts.errors}</div>
                                        ) : (<></>)}
                                    </NavLink>
                                )}
                                {linkCondition({ type: "link", cond: "states" }) && (
                                    <NavLink to='/fetchStates' className="sidebar-link-single" onClick={() => handleSidebar()} style={({isActive}) => inlineStyleProps({isActive, cond: 'neutral', type: ''})}>
                                        <IoCloudDoneOutline size={16}/>
                                        States
                                    </NavLink>
                                )}
                                {linkCondition({ type: "link", cond: "unused-domain" }) && (
                                    <NavLink to='/unusedDomains' className="sidebar-link-single" onClick={() => handleSidebar()} style={({isActive}) => inlineStyleProps({isActive, cond: 'neutral', type: ''})}>
                                        <TbWorldWww size={16}/>
                                        Unused Domain
                                    </NavLink>
                                )}
                                {(appContext.role === 'admin') && (
                                    <NavLink to='/createCampaign' className="sidebar-link-single" onClick={() => handleSidebar()} style={({isActive}) => inlineStyleProps({isActive, cond: 'neutral', type: ''})}>
                                        <MdOutlineCampaign size={16}/>
                                        Create Camp
                                    </NavLink>
                                )}
                                {(appContext.role === 'admin') && (
                                    <NavLink to='/campaignCSV' className="sidebar-link-single" onClick={() => handleSidebar()} style={({isActive}) => inlineStyleProps({isActive, cond: 'neutral', type: ''})}>
                                        <TbFileUpload size={16}/>
                                        Upload Camp
                                    </NavLink>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default Sidebar