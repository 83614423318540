// 2023-01-01 - 2024-09-08

export const weekly_data = {
    Southpow: [
        {
        date: "2023-01-01",
        tonic: {
            revenue: 37.790000000000006,
            spent: 100.68,
            result: -62.89,
            "ROI%": -62.465236392530784,
        },
        sedo: {
            revenue: 21101.688000000002,
            spent: 21635.56,
            result: -533.8719999999994,
            "ROI%": -2.4675672827511708,
        },
        system1: {
            revenue: 10520.79,
            spent: 10326.949999999997,
            result: 193.84000000000378,
            "ROI%": 1.8770304881887023,
        },
        crossroads: {
            revenue: undefined,
            spent: undefined,
            result: NaN,
            "ROI%": NaN,
        },
        },
        {
        date: "2023-01-08",
        tonic: {
            revenue: 50.85000000000001,
            spent: 325.74000000000007,
            result: -274.89000000000004,
            "ROI%": -84.38939031129121,
        },
        sedo: {
            revenue: 18243.205,
            spent: 17765.160000000003,
            result: 478.04499999999825,
            "ROI%": 2.6909130005021042,
        },
        system1: {
            revenue: 8451.49,
            spent: 7324.779999999997,
            result: 1126.7100000000028,
            "ROI%": 15.38216847468461,
        },
        crossroads: {
            revenue: undefined,
            spent: undefined,
            result: NaN,
            "ROI%": NaN,
        },
        },
        {
        date: "2023-01-15",
        tonic: {
            revenue: 57.25999999999999,
            spent: 217.89000000000001,
            result: -160.63000000000002,
            "ROI%": -73.72068474918537,
        },
        sedo: {
            revenue: 16169.397999999996,
            spent: 14654.959999999997,
            result: 1514.4379999999983,
            "ROI%": 10.333962016955333,
        },
        system1: {
            revenue: 7294.219999999998,
            spent: 6772.579999999998,
            result: 521.6400000000003,
            "ROI%": 7.702234598926849,
        },
        crossroads: {
            revenue: undefined,
            spent: undefined,
            result: NaN,
            "ROI%": NaN,
        },
        },
        {
        date: "2023-01-22",
        tonic: {
            revenue: 37.089999999999996,
            spent: 129.26999999999998,
            result: -92.17999999999998,
            "ROI%": -71.30811479848379,
        },
        sedo: {
            revenue: 24592.738999999998,
            spent: 22577.430000000004,
            result: 2015.3089999999938,
            "ROI%": 8.926210822046588,
        },
        system1: {
            revenue: 9997.140000000001,
            spent: 9988.689999999999,
            result: 8.450000000002547,
            "ROI%": 0.08459567771150756,
        },
        crossroads: {
            revenue: undefined,
            spent: undefined,
            result: NaN,
            "ROI%": NaN,
        },
        },
        {
        date: "2023-01-29",
        tonic: {
            revenue: 36.13,
            spent: 155.84,
            result: -119.71000000000001,
            "ROI%": -76.81596509240246,
        },
        sedo: {
            revenue: 25346.883,
            spent: 23665.829999999998,
            result: 1681.0530000000035,
            "ROI%": 7.103291961448233,
        },
        system1: {
            revenue: 8861.390000000001,
            spent: 8123.3099999999995,
            result: 738.0800000000017,
            "ROI%": 9.085951416356153,
        },
        crossroads: {
            revenue: undefined,
            spent: undefined,
            result: NaN,
            "ROI%": NaN,
        },
        },
        {
        date: "2023-02-05",
        tonic: {
            revenue: 10.32,
            spent: 68.67,
            result: -58.35,
            "ROI%": -84.97160332022717,
        },
        sedo: {
            revenue: 22340.002,
            spent: 20922.940000000002,
            result: 1417.061999999998,
            "ROI%": 6.772767115902445,
        },
        system1: {
            revenue: 6847.459999999999,
            spent: 6409.799999999997,
            result: 437.6600000000017,
            "ROI%": 6.8279821523292705,
        },
        crossroads: {
            revenue: undefined,
            spent: undefined,
            result: NaN,
            "ROI%": NaN,
        },
        },
        {
        date: "2023-02-12",
        tonic: { revenue: 0, spent: 0, result: 0, "ROI%": NaN },
        sedo: {
            revenue: 16860.576,
            spent: 16549.109999999997,
            result: 311.466000000004,
            "ROI%": 1.8820709995885254,
        },
        system1: {
            revenue: 4947.049999999999,
            spent: 4914.949999999999,
            result: 32.100000000000364,
            "ROI%": 0.6531093907364394,
        },
        crossroads: {
            revenue: undefined,
            spent: undefined,
            result: NaN,
            "ROI%": NaN,
        },
        },
        {
        date: "2023-02-19",
        tonic: { revenue: 0, spent: 0, result: 0, "ROI%": NaN },
        sedo: {
            revenue: 16613.388,
            spent: 16291.039999999997,
            result: 322.3480000000018,
            "ROI%": 1.978682760584971,
        },
        system1: {
            revenue: 4876.7,
            spent: 5225.04,
            result: -348.34000000000015,
            "ROI%": -6.666743221104532,
        },
        crossroads: {
            revenue: undefined,
            spent: undefined,
            result: NaN,
            "ROI%": NaN,
        },
        },
        {
        date: "2023-02-26",
        tonic: { revenue: 0, spent: 0, result: 0, "ROI%": NaN },
        sedo: {
            revenue: 18748.931,
            spent: 17500.41,
            result: 1248.5210000000006,
            "ROI%": 7.134238569267803,
        },
        system1: {
            revenue: 4666.809999999999,
            spent: 4653.03,
            result: 13.779999999998836,
            "ROI%": 0.29615111013681084,
        },
        crossroads: {
            revenue: undefined,
            spent: undefined,
            result: NaN,
            "ROI%": NaN,
        },
        },
        {
        date: "2023-03-05",
        tonic: { revenue: 0, spent: 0, result: 0, "ROI%": NaN },
        sedo: {
            revenue: 22535.191999999995,
            spent: 19591.55,
            result: 2943.641999999996,
            "ROI%": 15.025059273002883,
        },
        system1: {
            revenue: 3957.9000000000005,
            spent: 3626.189999999999,
            result: 331.7100000000014,
            "ROI%": 9.14761774755326,
        },
        crossroads: {
            revenue: undefined,
            spent: undefined,
            result: NaN,
            "ROI%": NaN,
        },
        },
        {
        date: "2023-03-12",
        tonic: {
            revenue: 92.72,
            spent: 194.52,
            result: -101.80000000000001,
            "ROI%": -52.333950236479545,
        },
        sedo: {
            revenue: 28424.634,
            spent: 23556.77,
            result: 4867.863999999998,
            "ROI%": 20.6643949913337,
        },
        system1: {
            revenue: 3297.4199999999996,
            spent: 3068.43,
            result: 228.98999999999978,
            "ROI%": 7.462774122270988,
        },
        crossroads: {
            revenue: undefined,
            spent: undefined,
            result: NaN,
            "ROI%": NaN,
        },
        },
        {
        date: "2023-03-19",
        tonic: {
            revenue: 77.54,
            spent: 118.52,
            result: -40.97999999999999,
            "ROI%": -34.57644279446506,
        },
        sedo: {
            revenue: 33304.725999999995,
            spent: 28621.94,
            result: 4682.785999999996,
            "ROI%": 16.360826694486796,
        },
        system1: {
            revenue: 5140.2699999999995,
            spent: 4450.799999999999,
            result: 689.4700000000003,
            "ROI%": 15.49092298013841,
        },
        crossroads: {
            revenue: undefined,
            spent: undefined,
            result: NaN,
            "ROI%": NaN,
        },
        },
    
        {
        date: "2023-03-26",
        tonic: { revenue: 0, spent: 0, result: 0, "ROI%": NaN },
        sedo: {
            revenue: 28387.292999999998,
            spent: 23688.46,
            result: 4698.832999999999,
            "ROI%": 19.835958099428996,
        },
        system1: {
            revenue: 4730.780000000001,
            spent: 4097.400000000001,
            result: 633.3800000000001,
            "ROI%": 15.458095377556491,
        },
        crossroads: {
            revenue: undefined,
            spent: undefined,
            result: NaN,
            "ROI%": NaN,
        },
        },
        {
        date: "2023-04-02",
        tonic: {
            revenue: 11.48,
            spent: 67.55,
            result: -56.06999999999999,
            "ROI%": -83.00518134715026,
        },
        sedo: {
            revenue: 22718.747000000003,
            spent: 19831.280000000002,
            result: 2887.4670000000006,
            "ROI%": 14.560164548128007,
        },
        system1: {
            revenue: 6196.42,
            spent: 5124.290000000001,
            result: 1072.1299999999992,
            "ROI%": 20.92250828895319,
        },
        crossroads: {
            revenue: undefined,
            spent: undefined,
            result: NaN,
            "ROI%": NaN,
        },
        },
        {
        date: "2023-04-09",
        tonic: {
            revenue: 156.23,
            spent: 291.62,
            result: -135.39000000000001,
            "ROI%": -46.426856868527544,
        },
        sedo: {
            revenue: 20566.195,
            spent: 17592.76,
            result: 2973.4350000000013,
            "ROI%": 16.90146969548838,
        },
        system1: {
            revenue: 5602.719999999999,
            spent: 4441.469999999999,
            result: 1161.25,
            "ROI%": 26.14562295816476,
        },
        crossroads: {
            revenue: undefined,
            spent: undefined,
            result: NaN,
            "ROI%": NaN,
        },
        },
        {
        date: "2023-04-16",
        tonic: {
            revenue: 649.02,
            spent: 1122.56,
            result: -473.53999999999996,
            "ROI%": -42.18393671607754,
        },
        sedo: {
            revenue: 22370.858,
            spent: 19780.380000000005,
            result: 2590.4779999999955,
            "ROI%": 13.096199365229566,
        },
        system1: {
            revenue: 5213.599999999999,
            spent: 4553.39,
            result: 660.2099999999991,
            "ROI%": 14.499307109647951,
        },
        crossroads: {
            revenue: undefined,
            spent: undefined,
            result: NaN,
            "ROI%": NaN,
        },
        },
        {
        date: "2023-04-23",
        tonic: {
            revenue: 405.6,
            spent: 598.7199999999999,
            result: -193.1199999999999,
            "ROI%": -32.25547835382147,
        },
        sedo: {
            revenue: 23274.657,
            spent: 20658.149999999998,
            result: 2616.5070000000014,
            "ROI%": 12.665737251399566,
        },
        system1: {
            revenue: 7065.11,
            spent: 6100.0199999999995,
            result: 965.0900000000001,
            "ROI%": 15.8210956685388,
        },
        crossroads: {
            revenue: undefined,
            spent: undefined,
            result: NaN,
            "ROI%": NaN,
        },
        },
        {
        date: "2023-04-30",
        tonic: {
            revenue: 50.66000000000001,
            spent: 86.77999999999999,
            result: -36.119999999999976,
            "ROI%": -41.622493662134104,
        },
        sedo: {
            revenue: 22721.489999999994,
            spent: 19822.759999999995,
            result: 2898.7299999999996,
            "ROI%": 14.623241163188183,
        },
        system1: {
            revenue: 7864.02,
            spent: 6391.360000000001,
            result: 1472.6599999999999,
            "ROI%": 23.041418414860047,
        },
        crossroads: {
            revenue: undefined,
            spent: undefined,
            result: NaN,
            "ROI%": NaN,
        },
        },
        {
        date: "2023-05-07",
        tonic: {
            revenue: 119.24,
            spent: 195.14,
            result: -75.89999999999999,
            "ROI%": -38.89515219842165,
        },
        sedo: {
            revenue: 22958.579000000005,
            spent: 18913.31,
            result: 4045.269000000004,
            "ROI%": 21.388477215252145,
        },
        system1: {
            revenue: 6777.5,
            spent: 5386.049999999999,
            result: 1391.4500000000007,
            "ROI%": 25.83433128173709,
        },
        crossroads: {
            revenue: undefined,
            spent: undefined,
            result: NaN,
            "ROI%": NaN,
        },
        },
        {
        date: "2023-05-14",
        tonic: {
            revenue: 72.73,
            spent: 71.81,
            result: 0.9200000000000017,
            "ROI%": 1.28115861300655,
        },
        sedo: {
            revenue: 19090.484,
            spent: 15889.080000000002,
            result: 3201.4039999999986,
            "ROI%": 20.148454158453475,
        },
        system1: {
            revenue: 6907.87,
            spent: 5827.299999999999,
            result: 1080.5700000000006,
            "ROI%": 18.54323614710074,
        },
        crossroads: {
            revenue: undefined,
            spent: undefined,
            result: NaN,
            "ROI%": NaN,
        },
        },
        {
        date: "2023-05-21",
        tonic: {
            revenue: 254.52000000000004,
            spent: 353.72,
            result: -99.19999999999999,
            "ROI%": -28.044781182856493,
        },
        sedo: {
            revenue: 20127.433999999997,
            spent: 18225.480000000003,
            result: 1901.9539999999943,
            "ROI%": 10.435686741858063,
        },
        system1: {
            revenue: 8370.02,
            spent: 6877.71,
            result: 1492.3100000000004,
            "ROI%": 21.697774404561997,
        },
        crossroads: {
            revenue: undefined,
            spent: undefined,
            result: NaN,
            "ROI%": NaN,
        },
        },
        {
        date: "2023-05-28",
        tonic: {
            revenue: 1354.56,
            spent: 1393.06,
            result: -38.5,
            "ROI%": -2.7637000559918468,
        },
        sedo: {
            revenue: 27422.162000000004,
            spent: 22205.549999999996,
            result: 5216.612000000008,
            "ROI%": 23.49237915746294,
        },
        system1: {
            revenue: 9725.000000000002,
            spent: 7271.339999999999,
            result: 2453.6600000000026,
            "ROI%": 33.74426171792273,
        },
        crossroads: {
            revenue: undefined,
            spent: undefined,
            result: NaN,
            "ROI%": NaN,
        },
        },
        {
        date: "2023-06-04",
        tonic: {
            revenue: 2611.36,
            spent: 2774.3900000000003,
            result: -163.0300000000002,
            "ROI%": -5.8762466704392775,
        },
        sedo: {
            revenue: 29955.957000000002,
            spent: 24925.729999999996,
            result: 5030.227000000006,
            "ROI%": 20.180861302758267,
        },
        system1: {
            revenue: 12792.35,
            spent: 10085.349999999999,
            result: 2707.000000000002,
            "ROI%": 26.840912809173734,
        },
        crossroads: {
            revenue: undefined,
            spent: undefined,
            result: NaN,
            "ROI%": NaN,
        },
        },
        {
        date: "2023-06-11",
        tonic: {
            revenue: 1842.35,
            spent: 1903.3099999999997,
            result: -60.95999999999981,
            "ROI%": -3.2028413658310995,
        },
        sedo: {
            revenue: 13981.636999999997,
            spent: 11600.57999999999,
            result: 2381.057000000006,
            "ROI%": 20.525327181916843,
        },
        system1: {
            revenue: 5572.23,
            spent: 4312.889999999999,
            result: 1259.3400000000001,
            "ROI%": 29.199446310942314,
        },
        crossroads: {
            revenue: undefined,
            spent: undefined,
            result: NaN,
            "ROI%": NaN,
        },
        },
        {
        date: "2023-06-18",
        tonic: {
            revenue: 1966.1299999999997,
            spent: 1744.2399999999998,
            result: 221.88999999999987,
            "ROI%": 12.72129982112553,
        },
        sedo: {
            revenue: 4665.689,
            spent: 4103.63,
            result: 562.0590000000002,
            "ROI%": 13.696629569430009,
        },
        system1: {
            revenue: 1556.1399999999994,
            spent: 1586.17,
            result: -30.030000000000655,
            "ROI%": -1.8932396905754545,
        },
        crossroads: {
            revenue: undefined,
            spent: undefined,
            result: NaN,
            "ROI%": NaN,
        },
        },
        {
        date: "2023-06-25",
        tonic: {
            revenue: 5079.219999999999,
            spent: 3884.0500000000006,
            result: 1195.1699999999987,
            "ROI%": 30.771231060362215,
        },
        sedo: {
            revenue: 16377.643000000005,
            spent: 12615.34,
            result: 3762.3030000000053,
            "ROI%": 29.823239009016046,
        },
        system1: {
            revenue: 6074.960000000001,
            spent: 5658.219999999999,
            result: 416.7400000000016,
            "ROI%": 7.36521379515116,
        },
        crossroads: { revenue: 0, spent: 0, result: 0, "ROI%": NaN },
        },
        {
        date: "2023-07-02",
        tonic: {
            revenue: 6487.5199999999995,
            spent: 5069.62,
            result: 1417.8999999999996,
            "ROI%": 27.96856569131414,
        },
        sedo: {
            revenue: 19005.149,
            spent: 14785.76,
            result: 4219.389000000001,
            "ROI%": 28.536842204932313,
        },
        system1: {
            revenue: 4990.9,
            spent: 4404.07,
            result: 586.8299999999999,
            "ROI%": 13.324720088463614,
        },
        crossroads: { revenue: 0, spent: 0, result: 0, "ROI%": NaN },
        },
        {
        date: "2023-07-09",
        tonic: {
            revenue: 5688.18,
            spent: 4729.460499999999,
            result: 958.7195000000011,
            "ROI%": 20.27122332452087,
        },
        sedo: {
            revenue: 23530.307000000004,
            spent: 18686.380000000005,
            result: 4843.927,
            "ROI%": 25.922233198725486,
        },
        system1: {
            revenue: 4660.550000000001,
            spent: 4082.1100000000006,
            result: 578.4400000000005,
            "ROI%": 14.170122804138074,
        },
        crossroads: { revenue: 0, spent: 0, result: 0, "ROI%": NaN },
        },
        {
        date: "2023-07-16",
        tonic: {
            revenue: 5702.75,
            spent: 4814.0695000000005,
            result: 888.6804999999995,
            "ROI%": 18.460067932130997,
        },
        sedo: {
            revenue: 31870.72200000002,
            spent: 24208.13749999999,
            result: 7662.58450000003,
            "ROI%": 31.65292868978471,
        },
        system1: {
            revenue: 4906.5,
            spent: 4069.3500000000004,
            result: 837.1499999999996,
            "ROI%": 20.572081536363296,
        },
        crossroads: { revenue: 0, spent: 0, result: 0, "ROI%": NaN },
        },
        {
        date: "2023-07-23",
        tonic: {
            revenue: 3790,
            spent: 3506.29,
            result: 283.71000000000004,
            "ROI%": 8.091458493165149,
        },
        sedo: {
            revenue: 28392.614999999994,
            spent: 22788.060499999992,
            result: 5604.554500000002,
            "ROI%": 24.59425847144825,
        },
        system1: {
            revenue: 3407.5299999999997,
            spent: 3374.0800000000004,
            result: 33.44999999999936,
            "ROI%": 0.9913813543247052,
        },
        crossroads: { revenue: 0, spent: 0, result: 0, "ROI%": NaN },
        },
        {
        date: "2023-07-30",
        tonic: {
            revenue: 4297.780000000001,
            spent: 3753.7200000000003,
            result: 544.0600000000004,
            "ROI%": 14.493888729047466,
        },
        sedo: {
            revenue: 21872.527,
            spent: 19340.235000000004,
            result: 2532.291999999994,
            "ROI%": 13.093387955213531,
        },
        system1: {
            revenue: 2555.16,
            spent: 2333.6899999999996,
            result: 221.47000000000025,
            "ROI%": 9.490120795821234,
        },
        crossroads: { revenue: 0, spent: 0, result: 0, "ROI%": NaN },
        },
        {
        date: "2023-08-06",
        tonic: {
            revenue: 5806.999999999999,
            spent: 4844.52,
            result: 962.4799999999987,
            "ROI%": 19.867396563539796,
        },
        sedo: {
            revenue: 21117.903000000002,
            spent: 17844.6775,
            result: 3273.2255000000005,
            "ROI%": 18.34286722189291,
        },
        system1: {
            revenue: 2913.6100000000006,
            spent: 2545.6600000000003,
            result: 367.9500000000003,
            "ROI%": 14.454011926180254,
        },
        crossroads: { revenue: 0, spent: 0, result: 0, "ROI%": NaN },
        },
        {
        date: "2023-08-13",
        tonic: {
            revenue: 6356.280000000002,
            spent: 5835.73,
            result: 520.550000000002,
            "ROI%": 8.920049419695598,
        },
        sedo: {
            revenue: 24442.144000000008,
            spent: 20874.839,
            result: 3567.3050000000076,
            "ROI%": 17.089018027875614,
        },
        system1: {
            revenue: 4298.99,
            spent: 3863.3099999999995,
            result: 435.6800000000003,
            "ROI%": 11.277376136007744,
        },
        crossroads: { revenue: 0, spent: 0, result: 0, "ROI%": NaN },
        },
        {
        date: "2023-08-20",
        tonic: {
            revenue: 4760.299999999999,
            spent: 3987.2400000000007,
            result: 773.0599999999986,
            "ROI%": 19.388348832776515,
        },
        sedo: {
            revenue: 15771.742,
            spent: 13951.661500000006,
            result: 1820.0804999999946,
            "ROI%": 13.045618258441793,
        },
        system1: {
            revenue: 3225.07,
            spent: 2926.3299999999995,
            result: 298.7400000000007,
            "ROI%": 10.20869143261358,
        },
        crossroads: { revenue: 0, spent: 0, result: 0, "ROI%": NaN },
        },
        {
        date: "2023-08-27",
        tonic: {
            revenue: 6350.179999999999,
            spent: 5319.8099999999995,
            result: 1030.37,
            "ROI%": 19.368548876745596,
        },
        sedo: {
            revenue: 16900.975999999995,
            spent: 14576.052000000003,
            result: 2324.923999999992,
            "ROI%": 15.950299847997185,
        },
        system1: {
            revenue: 3106.35,
            spent: 3070.5700000000006,
            result: 35.77999999999929,
            "ROI%": 1.1652559622480352,
        },
        crossroads: { revenue: 0, spent: 0, result: 0, "ROI%": NaN },
        },
        {
        date: "2023-09-03",
        tonic: {
            revenue: 7706.870000000001,
            spent: 6017.51,
            result: 1689.3600000000006,
            "ROI%": 28.074070504245128,
        },
        sedo: {
            revenue: 14859.604000000001,
            spent: 12726.455500000004,
            result: 2133.1484999999975,
            "ROI%": 16.761528769734802,
        },
        system1: {
            revenue: 2849,
            spent: 2818.27,
            result: 30.730000000000018,
            "ROI%": 1.0903852363329358,
        },
        crossroads: { revenue: 0, spent: 0, result: 0, "ROI%": NaN },
        },
        {
        date: "2023-09-10",
        tonic: {
            revenue: 10305.409999999998,
            spent: 8305.66,
            result: 1999.7499999999982,
            "ROI%": 24.07695475133822,
        },
        sedo: {
            revenue: 14383.246000000003,
            spent: 12640.259999999998,
            result: 1742.9860000000044,
            "ROI%": 13.789162564694113,
        },
        system1: {
            revenue: 2372.38,
            spent: 2238.79,
            result: 133.59000000000015,
            "ROI%": 5.9670625650463105,
        },
        crossroads: { revenue: 0, spent: 0, result: 0, "ROI%": NaN },
        },
        {
        date: "2023-09-17",
        tonic: {
            revenue: 14510.779999999995,
            spent: 10331.960000000003,
            result: 4178.819999999992,
            "ROI%": 40.44556889496273,
        },
        sedo: {
            revenue: 15829.835849999996,
            spent: 12235.430500000002,
            result: 3594.4053499999936,
            "ROI%": 29.37702396331696,
        },
        system1: {
            revenue: 1706.9,
            spent: 1401.0800000000002,
            result: 305.81999999999994,
            "ROI%": 21.827447397721755,
        },
        crossroads: { revenue: NaN, spent: 172.8, result: NaN, "ROI%": NaN },
        },
        {
        date: "2023-09-24",
        tonic: {
            revenue: 14693.149999999994,
            spent: 10718.39,
            result: 3974.7599999999948,
            "ROI%": 37.083554526379395,
        },
        sedo: {
            revenue: 12174.04239,
            spent: 9260.446499999996,
            result: 2913.595890000004,
            "ROI%": 31.462801388680404,
        },
        system1: {
            revenue: 589.8000000000001,
            spent: 392.86000000000007,
            result: 196.94,
            "ROI%": 50.129817237692805,
        },
        crossroads: { revenue: NaN, spent: 2038.45, result: NaN, "ROI%": NaN },
        },
        {
        date: "2023-10-01",
        tonic: {
            revenue: 14176.070000000002,
            spent: 11451.479999999998,
            result: 2724.590000000004,
            "ROI%": 23.79247049289703,
        },
        sedo: {
            revenue: 11598.011926,
            spent: 8602.232499999998,
            result: 2995.779426000001,
            "ROI%": 34.825604004541866,
        },
        system1: { revenue: 0, spent: 0, result: 0, "ROI%": NaN },
        crossroads: {
            revenue: 4109.037600000001,
            spent: 3433.2999999999997,
            result: 675.7376000000008,
            "ROI%": 19.68186875600737,
        },
        },
        {
        date: "2023-10-08",
        tonic: {
            revenue: 14045.100299999998,
            spent: 11043.590000000006,
            result: 3001.5102999999926,
            "ROI%": 27.178755277948486,
        },
        sedo: {
            revenue: 13880.146455999997,
            spent: 9338.900000000005,
            result: 4541.246455999992,
            "ROI%": 48.62720937155327,
        },
        system1: {
            revenue: 105.2881,
            spent: 181.01,
            result: -75.72189999999999,
            "ROI%": -41.83299265233965,
        },
        crossroads: {
            revenue: 6164.9328000000005,
            spent: 4681.570000000001,
            result: 1483.3627999999999,
            "ROI%": 31.685156902492096,
        },
        },
        {
        date: "2023-10-15",
        tonic: {
            revenue: 10488.554100000005,
            spent: 8545.480000000003,
            result: 1943.0741000000016,
            "ROI%": 22.738033439900395,
        },
        sedo: {
            revenue: 14855.610148,
            spent: 11353.490000000005,
            result: 3502.1201479999945,
            "ROI%": 30.84619925679235,
        },
        system1: {
            revenue: 402.0899999999999,
            spent: 420.47999999999996,
            result: -18.390000000000043,
            "ROI%": -4.373573059360735,
        },
        crossroads: {
            revenue: 7922.195700000001,
            spent: 6692.889999999999,
            result: 1229.3057000000017,
            "ROI%": 18.367337577638377,
        },
        },
        {
        date: "2023-10-22",
        tonic: {
            revenue: 11536.259400000003,
            spent: 9743.630000000003,
            result: 1792.6293999999998,
            "ROI%": 18.397962566312543,
        },
        sedo: {
            revenue: 15122.386410000003,
            spent: 11615.550000000003,
            result: 3506.83641,
            "ROI%": 30.190876970957035,
        },
        system1: {
            revenue: 388.42240000000004,
            spent: 524.9,
            result: -136.47759999999994,
            "ROI%": -26.000685844922835,
        },
        crossroads: {
            revenue: 10441.362799999997,
            spent: 8872.52,
            result: 1568.8427999999967,
            "ROI%": 17.682042982151593,
        },
        },
        {
        date: "2023-10-29",
        tonic: {
            revenue: 12983.832299999998,
            spent: 11578.33,
            result: 1405.5022999999983,
            "ROI%": 12.139076188016729,
        },
        sedo: {
            revenue: 12292.400896999996,
            spent: 9727.910000000002,
            result: 2564.490896999994,
            "ROI%": 26.362198015812165,
        },
        system1: {
            revenue: 537.9678,
            spent: 626.1199999999999,
            result: -88.15219999999988,
            "ROI%": -14.0791222129943,
        },
        crossroads: {
            revenue: 7916.252999999999,
            spent: 6891.039999999999,
            result: 1025.2129999999997,
            "ROI%": 14.877478580881842,
        },
        },
        {
        date: "2023-11-05",
        tonic: {
            revenue: 15216.4647,
            spent: 12935.100000000002,
            result: 2281.3646999999983,
            "ROI%": 17.63700860449473,
        },
        sedo: {
            revenue: 11651.207779,
            spent: 9233.370000000004,
            result: 2417.837778999996,
            "ROI%": 26.18586473844322,
        },
        system1: {
            revenue: 262.84590000000003,
            spent: 200.3,
            result: 62.54590000000002,
            "ROI%": 31.226110833749377,
        },
        crossroads: {
            revenue: 10085.784900000002,
            spent: 8125.57,
            result: 1960.2149000000027,
            "ROI%": 24.124029452703045,
        },
        },
        {
        date: "2023-11-12",
        tonic: {
            revenue: 14840.2983,
            spent: 13105.750000000005,
            result: 1734.548299999995,
            "ROI%": 13.235017454170839,
        },
        sedo: {
            revenue: 11092.365969999999,
            spent: 8978.97,
            result: 2113.3959699999996,
            "ROI%": 23.537175978981995,
        },
        system1: {
            revenue: 363.8625,
            spent: 298.11000000000007,
            result: 65.75249999999994,
            "ROI%": 22.05645567072554,
        },
        crossroads: {
            revenue: 9350.136300000002,
            spent: 7563.080000000001,
            result: 1787.056300000001,
            "ROI%": 23.628684345531205,
        },
        },
        {
        date: "2023-11-19",
        tonic: {
            revenue: 13782.2745,
            spent: 12956.02,
            result: 826.2544999999991,
            "ROI%": 6.3773790099119765,
        },
        sedo: {
            revenue: 11933.734982999997,
            spent: 10331.35,
            result: 1602.3849829999963,
            "ROI%": 15.509928353990476,
        },
        system1: {
            revenue: 392.3205,
            spent: 363.19,
            result: 29.130499999999984,
            "ROI%": 8.020732949695741,
        },
        crossroads: {
            revenue: 10886.1522,
            spent: 9213.560000000003,
            result: 1672.5921999999973,
            "ROI%": 18.153593182222693,
        },
        },
        {
        date: "2023-11-26",
        tonic: {
            revenue: 9281.176800000001,
            spent: 8302.970000000005,
            result: 978.2067999999963,
            "ROI%": 11.78140833942547,
        },
        sedo: {
            revenue: 9363.878636579999,
            spent: 7639.160000000002,
            result: 1724.718636579997,
            "ROI%": 22.5773335887715,
        },
        system1: {
            revenue: 704.3820000000001,
            spent: 674.33,
            result: 30.05200000000002,
            "ROI%": 4.456571708214074,
        },
        crossroads: {
            revenue: 10452.902400000003,
            spent: 7973.57,
            result: 2479.332400000003,
            "ROI%": 31.094383068061138,
        },
        },
        {
        date: "2023-12-03",
        tonic: {
            revenue: 8937.2442,
            spent: 7917.100000000001,
            result: 1020.1441999999979,
            "ROI%": 12.88532669790703,
        },
        sedo: {
            revenue: 9580.636014150003,
            spent: 7826.030000000002,
            result: 1754.606014150001,
            "ROI%": 22.42012890507703,
        },
        system1: {
            revenue: 1027.7523,
            spent: 943.46,
            result: 84.29230000000007,
            "ROI%": 8.934379835923089,
        },
        crossroads: {
            revenue: 12376.021500000003,
            spent: 10339.150000000001,
            result: 2036.871500000001,
            "ROI%": 19.700570162924436,
        },
        },
        {
        date: "2023-12-10",
        tonic: {
            revenue: 8547.9741,
            spent: 7769.770000000002,
            result: 778.2040999999972,
            "ROI%": 10.015793260289518,
        },
        sedo: {
            revenue: 9076.092936066003,
            spent: 7955.910000000003,
            result: 1120.1829360660004,
            "ROI%": 14.07988446407764,
        },
        system1: {
            revenue: 1001.5356,
            spent: 1001.39,
            result: 0.14560000000005857,
            "ROI%": 0.014539789692324945,
        },
        crossroads: {
            revenue: 14046.403800000002,
            spent: 12228.510000000004,
            result: 1817.893799999998,
            "ROI%": 14.866028649442953,
        },
        },
        {
            "date": "2023-12-17",
            "tonic": {
                "revenue": 8934.286799999998,
                "spent": 8598.66,
                "result": 335.6267999999982,
                "ROI%": 3.903245389397858
            },
            "sedo": {
                "revenue": 8407.55955753,
                "spent": 6957.440000000001,
                "result": 1450.119557529998,
                "ROI%": 20.84271740079682
            },
            "system1": {
                "revenue": 610.2009,
                "spent": 588.79,
                "result": 21.410900000000083,
                "ROI%": 3.636423852307291
            },
            "crossroads": {
                "revenue": 12018.873599999997,
                "spent": 10288.74,
                "result": 1730.1335999999974,
                "ROI%": 16.815796686474705
            }
        },
        {
            "date": "2023-12-24",
            "tonic": {
                "revenue": 8939.252999999999,
                "spent": 8013.950000000002,
                "result": 925.3029999999972,
                "ROI%": 11.546153894147038
            },
            "sedo": {
                "revenue": 10590.052210949998,
                "spent": 8619.900000000003,
                "result": 1970.1522109499947,
                "ROI%": 22.855859243726663
            },
            "system1": {
                "revenue": 762.1071,
                "spent": 742,
                "result": 20.107099999999946,
                "ROI%": 2.7098517520215637
            },
            "crossroads": {
                "revenue": 12816.274200000005,
                "spent": 11045.400000000003,
                "result": 1770.874200000002,
                "ROI%": 16.032685099679522
            }
        },
        {
            "date": "2023-12-31",
            "tonic": {
                "revenue": 1093.4568000000002,
                "spent": 1069.6399999999994,
                "result": 23.81680000000074,
                "ROI%": 2.226618301484673
            },
            "sedo": {
                "revenue": 1279.8095211,
                "spent": 1120.37,
                "result": 159.4395211000001,
                "ROI%": 14.230970224122409
            },
            "system1": {
                "revenue": 92.65589999999996,
                "spent": 99.94,
                "result": -7.284100000000038,
                "ROI%": -7.288473083850344
            },
            "crossroads": {
                "revenue": 1484.884500000001,
                "spent": 1401.5600000000002,
                "result": 83.32450000000085,
                "ROI%": 5.945125431661924
            }
        },
        {
            "date": "2024-01-01",
            "tonic": {
                "revenue": 7433.7597000000005,
                "spent": 6031.940000000004,
                "result": 1401.8196999999964,
                "ROI%": 23.239947678524576
            },
            "sedo": {
                "revenue": 9430.199670780004,
                "spent": 7285.930000000002,
                "result": 2144.2696707800023,
                "ROI%": 29.430280976896594
            },
            "system1": {
                "revenue": 583.6215,
                "spent": 553.9,
                "result": 29.721499999999992,
                "ROI%": 5.365860263585476
            },
            "crossroads": {
                "revenue": 10009.673700000001,
                "spent": 8236.92,
                "result": 1772.7537000000011,
                "ROI%": 21.52204586180273
            }
        },
        {
            "date": "2024-01-07",
            "tonic": {
                "revenue": 8277.5766,
                "spent": 6722.5999999999985,
                "result": 1554.9766000000018,
                "ROI%": 23.130583405230155
            },
            "sedo": {
                "revenue": 11592.555086710005,
                "spent": 9052.820000000007,
                "result": 2539.7350867099976,
                "ROI%": 28.054629239397187
            },
            "system1": {
                "revenue": 729.2780999999999,
                "spent": 652.2799999999999,
                "result": 76.99810000000002,
                "ROI%": 11.804455141963576
            },
            "crossroads": {
                "revenue": 10374.801000000003,
                "spent": 8347.57,
                "result": 2027.2310000000034,
                "ROI%": 24.28528302248443
            }
        },
        {
            "date": "2024-01-14",
            "tonic": {
                "revenue": 8020.598999999998,
                "spent": 6649.740000000002,
                "result": 1370.8589999999967,
                "ROI%": 20.61522706150911
            },
            "sedo": {
                "revenue": 10066.225493400003,
                "spent": 7758.4100000000035,
                "result": 2307.8154933999995,
                "ROI%": 29.745985239243588
            },
            "system1": {
                "revenue": 753.9882,
                "spent": 672.0600000000001,
                "result": 81.92819999999995,
                "ROI%": 12.190607981430212
            },
            "crossroads": {
                "revenue": 11164.5291,
                "spent": 8723.140000000003,
                "result": 2441.3890999999967,
                "ROI%": 27.987503353150302
            }
        },
        {
            "date": "2024-01-21",
            "tonic": {
                "revenue": 6874.839,
                "spent": 5839.450000000001,
                "result": 1035.3889999999992,
                "ROI%": 17.730933563948636
            },
            "sedo": {
                "revenue": 10360.0920549,
                "spent": 8125.650000000003,
                "result": 2234.442054899997,
                "ROI%": 27.498625401044798
            },
            "system1": {
                "revenue": 625.6947,
                "spent": 592.19,
                "result": 33.50469999999996,
                "ROI%": 5.657761866968358
            },
            "crossroads": {
                "revenue": 9994.217099999998,
                "spent": 8124.68,
                "result": 1869.5370999999977,
                "ROI%": 23.010593647995957
            }
        },
        {
            "date": "2024-01-28",
            "tonic": {
                "revenue": 6833.4686,
                "spent": 5665.2361999999985,
                "result": 1168.2324000000017,
                "ROI%": 20.62107136856892
            },
            "sedo": {
                "revenue": 11752.612296880005,
                "spent": 8718.642600000001,
                "result": 3033.9696968800035,
                "ROI%": 34.79864740504448
            },
            "system1": {
                "revenue": 899.4859,
                "spent": 733.0642999999999,
                "result": 166.42160000000013,
                "ROI%": 22.7021831509187
            },
            "crossroads": {
                "revenue": 9873.574899999996,
                "spent": 7508.5144,
                "result": 2365.060499999996,
                "ROI%": 31.498381357569173
            }
        },
        {
            "date": "2024-02-04",
            "tonic": {
                "revenue": 5435.789999999999,
                "spent": 4402.240600000001,
                "result": 1033.549399999998,
                "ROI%": 23.477803553036104
            },
            "sedo": {
                "revenue": 10303.536762000002,
                "spent": 7960.004800000003,
                "result": 2343.531961999998,
                "ROI%": 29.44133855296165
            },
            "system1": {
                "revenue": 907.73,
                "spent": 768.0504000000001,
                "result": 139.67959999999994,
                "ROI%": 18.186254443718795
            },
            "crossroads": {
                "revenue": 9162.8,
                "spent": 7440.441899999999,
                "result": 1722.3581000000004,
                "ROI%": 23.148599547561833
            }
        },
        {
            "date": "2024-02-11",
            "tonic": {
                "revenue": 5129.099999999997,
                "spent": 4937.2329,
                "result": 191.86709999999675,
                "ROI%": 3.886126174035587
            },
            "sedo": {
                "revenue": 10509.259559000002,
                "spent": 8691.4696,
                "result": 1817.7899590000015,
                "ROI%": 20.914644388792446
            },
            "system1": {
                "revenue": 612.17,
                "spent": 608.4725,
                "result": 3.697499999999991,
                "ROI%": 0.6076692044422627
            },
            "crossroads": {
                "revenue": 8613.359999999997,
                "spent": 7426.3823999999995,
                "result": 1186.9775999999974,
                "ROI%": 15.983254511644818
            }
        },
        {
            "date": "2024-02-18",
            "tonic": {
                "revenue": 3734.049999999999,
                "spent": 3486.5087999999996,
                "result": 247.5411999999992,
                "ROI%": 7.099973474898413
            },
            "sedo": {
                "revenue": 10658.903508000003,
                "spent": 9373.1133,
                "result": 1285.7902080000022,
                "ROI%": 13.717856243133241
            },
            "system1": {
                "revenue": 614.87,
                "spent": 613.1487,
                "result": 1.721300000000042,
                "ROI%": 0.2807312483904978
            },
            "crossroads": {
                "revenue": 8450.49,
                "spent": 7365.499099999998,
                "result": 1084.9909000000016,
                "ROI%": 14.73071797673564
            }
        },
        {
            "date": "2024-02-25",
            "tonic": {
                "revenue": 3621.959999999999,
                "spent": 3810.2172000000005,
                "result": -188.25720000000138,
                "ROI%": -4.940852190788525
            },
            "sedo": {
                "revenue": 9874.352951999997,
                "spent": 8825.009100000003,
                "result": 1049.3438519999945,
                "ROI%": 11.890569631253921
            },
            "system1": {
                "revenue": 557.56,
                "spent": 561.35,
                "result": -3.7900000000000773,
                "ROI%": -0.6751581010065122
            },
            "crossroads": {
                "revenue": 8542.35,
                "spent": 8134.558900000004,
                "result": 407.79109999999673,
                "ROI%": 5.013069608482357
            }
        },
        {
            "date": "2024-03-03",
            "tonic": {
                "revenue": 3673.2,
                "spent": 3665.9039,
                "result": 7.296100000000024,
                "ROI%": 0.19902594827976294
            },
            "sedo": {
                "revenue": 9362.364198000001,
                "spent": 8325.902,
                "result": 1036.462198000001,
                "ROI%": 12.448647581967709
            },
            "system1": {
                "revenue": 320.15000000000003,
                "spent": 342.3514,
                "result": -22.20139999999998,
                "ROI%": -6.484974210708638
            },
            "crossroads": {
                "revenue": 7900.489999999999,
                "spent": 7501.438500000002,
                "result": 399.05149999999685,
                "ROI%": 5.319666354659791
            }
        },
        {
            "date": "2024-03-10",
            "tonic": {
                "revenue": 2949.4900000000002,
                "spent": 3118.8090999999995,
                "result": -169.31909999999925,
                "ROI%": -5.428966460306894
            },
            "sedo": {
                "revenue": 10370.715511,
                "spent": 10067.745299999999,
                "result": 302.97021100000165,
                "ROI%": 3.0093154124588573
            },
            "system1": {
                "revenue": 233.51,
                "spent": 258.7051,
                "result": -25.195100000000025,
                "ROI%": -9.738926677518156
            },
            "crossroads": {
                "revenue": 10323.68,
                "spent": 9761.3409,
                "result": 562.339100000001,
                "ROI%": 5.760879634887051
            }
        },
        {
            "date": "2024-03-17",
            "tonic": {
                "revenue": 2740.36,
                "spent": 2615.0155,
                "result": 125.34450000000015,
                "ROI%": 4.7932603076348945
            },
            "sedo": {
                "revenue": 8822.997254,
                "spent": 7396.4918,
                "result": 1426.505454,
                "ROI%": 19.28624397312251
            },
            "system1": {
                "revenue": 269.15999999999997,
                "spent": 304.4886,
                "result": -35.32860000000005,
                "ROI%": -11.602601870808972
            },
            "crossroads": {
                "revenue": 8479.569999999998,
                "spent": 7638.397599999999,
                "result": 841.1723999999986,
                "ROI%": 11.012419672942908
            }
        },
        {
            "date": "2024-03-24",
            "tonic": {
                "revenue": 2256.8399999999997,
                "spent": 2061.8334,
                "result": 195.0065999999997,
                "ROI%": 9.45792225501827
            },
            "sedo": {
                "revenue": 9031.965047999998,
                "spent": 7716.770299999999,
                "result": 1315.194747999999,
                "ROI%": 17.04333156061415
            },
            "system1": {
                "revenue": 152.30999999999997,
                "spent": 141.3469,
                "result": 10.963099999999969,
                "ROI%": 7.756165858607411
            },
            "crossroads": {
                "revenue": 9395.090000000002,
                "spent": 8708.3204,
                "result": 686.7696000000014,
                "ROI%": 7.886361186251278
            }
        },
        {
            "date": "2024-03-31",
            "tonic": {
                "revenue": 2819.66,
                "spent": 2565.2356000000004,
                "result": 254.42439999999942,
                "ROI%": 9.918168919845005
            },
            "sedo": {
                "revenue": 7972.025723000001,
                "spent": 7056.766899999999,
                "result": 915.2588230000019,
                "ROI%": 12.96994552845443
            },
            "system1": {
                "revenue": 128.52,
                "spent": 132.5095,
                "result": -3.9894999999999925,
                "ROI%": -3.0107275327429295
            },
            "crossroads": {
                "revenue": 7581.020000000001,
                "spent": 6887.743899999999,
                "result": 693.2761000000028,
                "ROI%": 10.065358266296798
            }
        },
        {
            "date": "2024-04-07",
            "tonic": {
                "revenue": 2292.0899999999992,
                "spent": 2023.0539,
                "result": 269.0360999999991,
                "ROI%": 13.298513697534165
            },
            "sedo": {
                "revenue": 6281.808354000001,
                "spent": 5158.919800000001,
                "result": 1122.888554,
                "ROI%": 21.76596259550303
            },
            "system1": {
                "revenue": 133.15,
                "spent": 148.0625,
                "result": -14.912499999999994,
                "ROI%": -10.071760236386663
            },
            "crossroads": {
                "revenue": 4598.99,
                "spent": 4562.6322,
                "result": 36.35779999999977,
                "ROI%": 0.7968601983740831
            }
        },
        {
            "date": "2024-04-14",
            "tonic": {
                "revenue": 2326.8899999999994,
                "spent": 2395.5019,
                "result": -68.61190000000079,
                "ROI%": -2.8641972690566786
            },
            "sedo": {
                "revenue": 6753.825552000001,
                "spent": 6115.779500000001,
                "result": 638.0460520000006,
                "ROI%": 10.432783784961508
            },
            "system1": {
                "revenue": 93.97,
                "spent": 113.25880000000001,
                "result": -19.28880000000001,
                "ROI%": -17.030729621009588
            },
            "crossroads": {
                "revenue": 4397.030000000001,
                "spent": 4403.219099999999,
                "result": -6.189099999998689,
                "ROI%": -0.14055852909973243
            }
        },
        {
            "date": "2024-04-21",
            "tonic": {
                "revenue": 1716.1199999999994,
                "spent": 1802.5000000000002,
                "result": -86.38000000000079,
                "ROI%": -4.792233009708779
            },
            "sedo": {
                "revenue": 6656.383046000001,
                "spent": 6941.3142,
                "result": -284.93115399999897,
                "ROI%": -4.104858904096275
            },
            "system1": {
                "revenue": 56.75,
                "spent": 70.27690000000001,
                "result": -13.526900000000012,
                "ROI%": -19.248003255692847
            },
            "crossroads": {
                "revenue": 4033.8400000000006,
                "spent": 4366.602599999998,
                "result": -332.76259999999775,
                "ROI%": -7.620629365264375
            }
        },
        {
            "date": "2024-04-28",
            "tonic": {
                "revenue": 1151.3999999999999,
                "spent": 1199.3835000000001,
                "result": -47.98350000000028,
                "ROI%": -4.0006803495295955
            },
            "sedo": {
                "revenue": 4668.60563,
                "spent": 4792.3634,
                "result": -123.75777000000016,
                "ROI%": -2.5823953584154347
            },
            "system1": {
                "revenue": null,
                "spent": 115.63810000000001,
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 2508.3400000000006,
                "spent": 2697.1683000000003,
                "result": -188.82829999999967,
                "ROI%": -7.000983216360646
            }
        },
        {
            "date": "2024-05-05",
            "tonic": {
                "revenue": 1023.0099999999998,
                "spent": 1053.1338,
                "result": -30.1238000000003,
                "ROI%": -2.860396276332622
            },
            "sedo": {
                "revenue": 6665.582058,
                "spent": 6778.141600000001,
                "result": -112.55954200000087,
                "ROI%": -1.6606254139040266
            },
            "system1": {
                "revenue": 295.97999999999996,
                "spent": 275.4117,
                "result": 20.568299999999965,
                "ROI%": 7.468201241995143
            },
            "crossroads": {
                "revenue": 2999.44,
                "spent": 3143.9617000000003,
                "result": -144.52170000000024,
                "ROI%": -4.596802181146176
            }
        },
        {
            "date": "2024-05-12",
            "tonic": {
                "revenue": 909.1399999999999,
                "spent": 955.4692,
                "result": -46.32920000000013,
                "ROI%": -4.8488428512400095
            },
            "sedo": {
                "revenue": 9381.756922000002,
                "spent": 9768.231599999997,
                "result": -386.47467799999504,
                "ROI%": -3.9564446649687834
            },
            "system1": {
                "revenue": 636.46,
                "spent": 444.1669,
                "result": 192.29310000000004,
                "ROI%": 43.29298288548742
            },
            "crossroads": {
                "revenue": 2452.27,
                "spent": 2598.6693999999998,
                "result": -146.3993999999998,
                "ROI%": -5.633629271965102
            }
        },
        {
            "date": "2024-05-19",
            "tonic": {
                "revenue": 839.4699999999999,
                "spent": 839.6766,
                "result": -0.20660000000009404,
                "ROI%": -0.024604710908948668
            },
            "sedo": {
                "revenue": 11822.733733000001,
                "spent": 11841.745199999998,
                "result": -19.011466999996628,
                "ROI%": -0.1605461583483181
            },
            "system1": {
                "revenue": 1180.99,
                "spent": 1072.4772,
                "result": 108.51279999999997,
                "ROI%": 10.117958684809336
            },
            "crossroads": {
                "revenue": 3685.9000000000005,
                "spent": 4021.3775000000005,
                "result": -335.47749999999996,
                "ROI%": -8.342352838051138
            }
        },
        {
            "date": "2024-05-26",
            "tonic": {
                "revenue": 581.7399999999999,
                "spent": 605.4958,
                "result": -23.755800000000136,
                "ROI%": -3.9233632999601586
            },
            "sedo": {
                "revenue": 11215.590016000002,
                "spent": 10946.252899999998,
                "result": 269.33711600000424,
                "ROI%": 2.460541689110829
            },
            "system1": {
                "revenue": 1166.04,
                "spent": 1070.5408,
                "result": 95.49919999999997,
                "ROI%": 8.92065019847912
            },
            "crossroads": {
                "revenue": 3928.8400000000006,
                "spent": 3994.8447,
                "result": -66.0046999999995,
                "ROI%": -1.6522469571845777
            }
        },
        {
            "date": "2024-06-02",
            "tonic": {
                "revenue": 1411.1399999999999,
                "spent": 1692.9492000000005,
                "result": -281.8092000000006,
                "ROI%": -16.64605175394516
            },
            "sedo": {
                "revenue": 12001.855258999998,
                "spent": 11412.132199999998,
                "result": 589.7230589999999,
                "ROI%": 5.16750988040604
            },
            "system1": {
                "revenue": 836.34,
                "spent": 858.4741000000001,
                "result": -22.134100000000103,
                "ROI%": -2.5783072546976205
            },
            "crossroads": {
                "revenue": 4259.450000000001,
                "spent": 4181.9751,
                "result": 77.47490000000107,
                "ROI%": 1.8525911357052616
            }
        },
        {
            "date": "2024-06-09",
            "tonic": {
                "revenue": 1806.3699999999992,
                "spent": 2051.4716000000008,
                "result": -245.10160000000155,
                "ROI%": -11.947598982116126
            },
            "sedo": {
                "revenue": 12911.968077000001,
                "spent": 12583.303999999995,
                "result": 328.6640770000067,
                "ROI%": 2.6119060383505532
            },
            "system1": {
                "revenue": 388.28000000000003,
                "spent": 380.2966,
                "result": 7.983400000000017,
                "ROI%": 2.099256212124967
            },
            "crossroads": {
                "revenue": 4314.19,
                "spent": 4284.285,
                "result": 29.904999999999745,
                "ROI%": 0.6980161217099212
            }
        },
        {
            "date": "2024-06-16",
            "tonic": {
                "revenue": 1910.2499999999993,
                "spent": 2520.6881000000003,
                "result": -610.438100000001,
                "ROI%": -24.217121507416994
            },
            "sedo": {
                "revenue": 13892.459383000001,
                "spent": 12968.276799999998,
                "result": 924.1825830000034,
                "ROI%": 7.126487175227503
            },
            "system1": {
                "revenue": 73.46000000000001,
                "spent": 61.7176,
                "result": 11.74240000000001,
                "ROI%": 19.026015269550367
            },
            "crossroads": {
                "revenue": 3784.59,
                "spent": 4121.431699999999,
                "result": -336.84169999999904,
                "ROI%": -8.172929324535428
            }
        },
        {
            "date": "2024-06-23",
            "tonic": {
                "revenue": 1693.67,
                "spent": 1914.7803,
                "result": -221.11029999999982,
                "ROI%": -11.5475545680097
            },
            "sedo": {
                "revenue": 15163.221450000003,
                "spent": 15380.207199999995,
                "result": -216.98574999999255,
                "ROI%": -1.4108116176743901
            },
            "system1": {
                "revenue": 139.1,
                "spent": 121.0044,
                "result": 18.09559999999999,
                "ROI%": 14.954497522404143
            },
            "crossroads": {
                "revenue": 3997.140000000001,
                "spent": 4301.135799999999,
                "result": -303.9957999999983,
                "ROI%": -7.0678028812761085
            }
        },
        {
            "date": "2024-06-30",
            "tonic": {
                "revenue": 486.9599999999998,
                "spent": 866.5596000000002,
                "result": -379.59960000000035,
                "ROI%": -43.80536549361409
            },
            "sedo": {
                "revenue": 13084.802001000004,
                "spent": 13010.774599999997,
                "result": 74.02740100000665,
                "ROI%": 0.568969975085154
            },
            "system1": {
                "revenue": 144.25,
                "spent": 140.2757,
                "result": 3.9742999999999995,
                "ROI%": 2.833206321551063
            },
            "crossroads": {
                "revenue": 2994.2400000000002,
                "spent": 3296.3398999999995,
                "result": -302.09989999999925,
                "ROI%": -9.164707195395694
            }
        },
        {
            "date": "2024-07-07",
            "tonic": {
                "revenue": 545.9699999999999,
                "spent": 850.5328000000001,
                "result": -304.56280000000015,
                "ROI%": -35.808472054222975
            },
            "sedo": {
                "revenue": 15968.325780999994,
                "spent": 15934.130899999995,
                "result": 34.19488099999944,
                "ROI%": 0.2146014816534425
            },
            "system1": {
                "revenue": 172.74,
                "spent": 212.0049,
                "result": -39.26489999999998,
                "ROI%": -18.52075117131726
            },
            "crossroads": {
                "revenue": 3344.97,
                "spent": 3542.3759999999997,
                "result": -197.40599999999995,
                "ROI%": -5.572700357048488
            }
        },
        {
            "date": "2024-07-14",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 17613.585988000006,
                "spent": 15855.315299999998,
                "result": 1758.2706880000078,
                "ROI%": 11.089471604516167
            },
            "system1": {
                "revenue": 167.93999999999997,
                "spent": 185.7914,
                "result": -17.85140000000004,
                "ROI%": -9.608302644794131
            },
            "crossroads": {
                "revenue": 3911.71,
                "spent": 3576.0261,
                "result": 335.6839,
                "ROI%": 9.387065155928243
            }
        },
        {
            "date": "2024-07-21",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 18829.957752199996,
                "spent": 17793.054299999996,
                "result": 1036.9034522000002,
                "ROI%": 5.827574258568968
            },
            "system1": {
                "revenue": 68.4,
                "spent": 77.0543,
                "result": -8.654299999999992,
                "ROI%": -11.23143030304602
            },
            "crossroads": {
                "revenue": 3391.2899999999995,
                "spent": 3213.0129000000006,
                "result": 178.27709999999888,
                "ROI%": 5.548595836636672
            }
        },
        {
            "date": "2024-07-28",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 16296.738584000004,
                "spent": 15222.730499999998,
                "result": 1074.0080840000064,
                "ROI%": 7.055291979320044
            },
            "system1": {
                "revenue": 49.050000000000004,
                "spent": 48.9971,
                "result": 0.05290000000000106,
                "ROI%": 0.10796557347272362
            },
            "crossroads": {
                "revenue": 2938.9,
                "spent": 2866.9535,
                "result": 71.94650000000001,
                "ROI%": 2.509510530952097
            }
        },
        {
            "date": "2024-08-04",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 17556.142988,
                "spent": 15832.731899999999,
                "result": 1723.4110880000007,
                "ROI%": 10.885115082381969
            },
            "system1": {
                "revenue": 5.64,
                "spent": 5.7062,
                "result": -0.06620000000000026,
                "ROI%": -1.160141600364517
            },
            "crossroads": {
                "revenue": 2437.3100000000004,
                "spent": 2445.8072,
                "result": -8.497199999999793,
                "ROI%": -0.34741904431386805
            }
        },
        {
            "date": "2024-08-11",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 21099.215921,
                "spent": 17262.12000000001,
                "result": 3837.095920999989,
                "ROI%": 22.228416445952103
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 2473.64,
                "spent": 2363.3999999999996,
                "result": 110.24000000000024,
                "ROI%": 4.664466446644666
            }
        },
        {
            "date": "2024-08-18",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 21973.053984000002,
                "spent": 19255.11,
                "result": 2717.9439840000014,
                "ROI%": 14.115442518894984
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 4357.49,
                "spent": 4312.5199999999995,
                "result": 44.970000000000255,
                "ROI%": 1.0427777726248255
            }
        },
        {
            "date": "2024-08-25",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 17875.223193000005,
                "spent": 16803.9,
                "result": 1071.3231930000038,
                "ROI%": 6.375443754128529
            },
            "system1": {
                "revenue": 17.52,
                "spent": 23.919999999999998,
                "result": -6.399999999999999,
                "ROI%": -26.755852842809357
            },
            "crossroads": {
                "revenue": 3926.61,
                "spent": 4333.74,
                "result": -407.12999999999965,
                "ROI%": -9.394426061554217
            }
        },
        {
            "date": "2024-09-01",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 13701.051111000006,
                "spent": 13267.150000000003,
                "result": 433.90111100000286,
                "ROI%": 3.2704922383481216
            },
            "system1": {
                "revenue": 58.93,
                "spent": 58.529999999999994,
                "result": 0.4000000000000057,
                "ROI%": 0.6834102169827494
            },
            "crossroads": {
                "revenue": 5450.0599999999995,
                "spent": 4889.61,
                "result": 560.4499999999998,
                "ROI%": 11.462059346246424
            }
        },
        {
            "date": "2024-09-08",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 10282.172111,
                "spent": 12212.309999999998,
                "result": -1930.1378889999978,
                "ROI%": -15.80485501105031
            },
            "system1": {
                "revenue": 22.48,
                "spent": 28.050000000000004,
                "result": -5.570000000000004,
                "ROI%": -19.857397504456344
            },
            "crossroads": {
                "revenue": 6908.940000000001,
                "spent": 6545.869999999999,
                "result": 363.07000000000244,
                "ROI%": 5.546550725877575
            }
        },
    ],
    
    UniverseFenix: [
        {
            date: "2023-08-06",
            tonic: { revenue: undefined, spent: undefined, result: NaN, "ROI%": NaN },
            sedo: {
                revenue: 392.74830000000003,
                spent: 495.80000000000007,
                result: -103.05170000000004,
                "ROI%": -20.784933440903597,
            },
            system1: { revenue: undefined, spent: undefined, result: NaN, "ROI%": NaN },
            crossroads: {
                revenue: undefined,
                spent: undefined,
                result: NaN,
                "ROI%": NaN,
            },
        },
        {
            date: "2023-08-13",
            tonic: { revenue: undefined, spent: undefined, result: NaN, "ROI%": NaN },
            sedo: {
                revenue: 2978.0469299999995,
                spent: 2884.9700000000003,
                result: 93.07692999999927,
                "ROI%": 3.226270290505595,
            },
            system1: { revenue: undefined, spent: undefined, result: NaN, "ROI%": NaN },
            crossroads: {
                revenue: undefined,
                spent: undefined,
                result: NaN,
                "ROI%": NaN,
            },
        },
        {
            date: "2023-08-20",
            tonic: { revenue: undefined, spent: undefined, result: NaN, "ROI%": NaN },
            sedo: {
                revenue: 3972.785030000001,
                spent: 3452.96,
                result: 519.8250300000009,
                "ROI%": 15.054475869978257,
            },
            system1: { revenue: undefined, spent: undefined, result: NaN, "ROI%": NaN },
            crossroads: {
                revenue: undefined,
                spent: undefined,
                result: NaN,
                "ROI%": NaN,
            },
        },
        {
            date: "2023-08-27",
            tonic: { revenue: undefined, spent: undefined, result: NaN, "ROI%": NaN },
            sedo: {
                revenue: 3428.046779999999,
                spent: 3051.8199999999997,
                result: 376.2267799999995,
                "ROI%": 12.327947913048586,
            },
            system1: { revenue: undefined, spent: undefined, result: NaN, "ROI%": NaN },
            crossroads: {
                revenue: undefined,
                spent: undefined,
                result: NaN,
                "ROI%": NaN,
            },
        },
        {
            date: "2023-09-03",
            tonic: { revenue: undefined, spent: undefined, result: NaN, "ROI%": NaN },
            sedo: {
                revenue: 2301.41815,
                spent: 1771.91,
                result: 529.5081499999999,
                "ROI%": 29.88346755760731,
            },
            system1: { revenue: undefined, spent: undefined, result: NaN, "ROI%": NaN },
            crossroads: {
                revenue: undefined,
                spent: undefined,
                result: NaN,
                "ROI%": NaN,
            },
        },
        {
            date: "2023-09-10",
            tonic: { revenue: undefined, spent: undefined, result: NaN, "ROI%": NaN },
            sedo: {
                revenue: 2005.14332,
                spent: 1822.32,
                result: 182.82331999999997,
                "ROI%": 10.032448746652612,
            },
            system1: { revenue: undefined, spent: undefined, result: NaN, "ROI%": NaN },
            crossroads: {
                revenue: undefined,
                spent: undefined,
                result: NaN,
                "ROI%": NaN,
            },
        },
        {
            date: "2023-09-17",
            tonic: { revenue: undefined, spent: undefined, result: NaN, "ROI%": NaN },
            sedo: {
                revenue: 2368.7133910000002,
                spent: 2156.39,
                result: 212.32339100000036,
                "ROI%": 9.846242609175547,
            },
            system1: { revenue: undefined, spent: undefined, result: NaN, "ROI%": NaN },
            crossroads: {
                revenue: undefined,
                spent: undefined,
                result: NaN,
                "ROI%": NaN,
            },
        },
        {
            date: "2023-09-24",
            tonic: { revenue: 0, spent: 0, result: 0, "ROI%": NaN },
            sedo: {
                revenue: 1987.7137860000003,
                spent: 1941.43,
                result: 46.28378600000019,
                "ROI%": 2.384004882998614,
            },
            system1: { revenue: undefined, spent: undefined, result: NaN, "ROI%": NaN },
            crossroads: { revenue: 0, spent: 0, result: 0, "ROI%": NaN },
        },
        {
            date: "2023-10-01",
            tonic: {
                revenue: 141.86999999999998,
                spent: 152.42,
                result: -10.550000000000011,
                "ROI%": -6.921663823645197,
            },
            sedo: {
                revenue: 2050.336491,
                spent: 2105.27,
                result: -54.93350899999996,
                "ROI%": -2.6093331971671074,
            },
            system1: { revenue: undefined, spent: undefined, result: NaN, "ROI%": NaN },
            crossroads: { revenue: 0, spent: 0, result: 0, "ROI%": NaN },
        },
        {
            date: "2023-10-08",
            tonic: {
                revenue: 280.4053,
                spent: 255.7,
                result: 24.705300000000022,
                "ROI%": 9.661830269847481,
            },
            sedo: {
                revenue: 2723.519809,
                spent: 2368.44,
                result: 355.07980899999984,
                "ROI%": 14.992138665112886,
            },
            system1: { revenue: undefined, spent: undefined, result: NaN, "ROI%": NaN },
            crossroads: { revenue: 0, spent: 0, result: 0, "ROI%": NaN },
        },
        {
            date: "2023-10-15",
            tonic: { revenue: 0, spent: 0, result: 0, "ROI%": NaN },
            sedo: {
                revenue: 3174.4306540000002,
                spent: 3132.46,
                result: 41.970654000000195,
                "ROI%": 1.3398624084585364,
            },
            system1: { revenue: undefined, spent: undefined, result: NaN, "ROI%": NaN },
            crossroads: {
                revenue: 12.424800000000001,
                spent: 46.31,
                result: -33.8852,
                "ROI%": -73.17037356942345,
            },
        },
        {
            date: "2023-10-22",
            tonic: {
                revenue: 26.7003,
                spent: 97,
                result: -70.2997,
                "ROI%": -72.47391752577319,
            },
            sedo: {
                revenue: 3568.7822079999996,
                spent: 3434.0999999999995,
                result: 134.68220800000017,
                "ROI%": 3.921906991642654,
            },
            system1: { revenue: undefined, spent: undefined, result: NaN, "ROI%": NaN },
            crossroads: {
                revenue: 533.096,
                spent: 689.8700000000001,
                result: -156.77400000000011,
                "ROI%": -22.725151115427554,
            },
        },
        {
            date: "2023-10-29",
            tonic: {
                revenue: 269.02110000000005,
                spent: 486.33,
                result: -217.30889999999994,
                "ROI%": -44.68342483498857,
            },
            sedo: {
                revenue: 2988.848733,
                spent: 2986.3399999999992,
                result: 2.508733000000575,
                "ROI%": 0.08400694495604988,
            },
            system1: { revenue: undefined, spent: undefined, result: NaN, "ROI%": NaN },
            crossroads: {
                revenue: 396.5799,
                spent: 586.6099999999999,
                result: -190.0300999999999,
                "ROI%": -32.394623344300285,
            },
        },
        {
            date: "2023-11-05",
            tonic: {
                revenue: 524.5572000000001,
                spent: 763.76,
                result: -239.2027999999999,
                "ROI%": -31.31910547816067,
            },
            sedo: {
                revenue: 2487.2600119999997,
                spent: 2386.5699999999997,
                result: 100.69001200000002,
                "ROI%": 4.219026133740056,
            },
            system1: { revenue: undefined, spent: undefined, result: NaN, "ROI%": NaN },

            crossroads: {
                revenue: 727.4460000000001,
                spent: 953.59,
                result: -226.1439999999999,
                "ROI%": -23.715013789993588,
            },
        },
        {
            date: "2023-11-12",
            tonic: {
                revenue: 613.6605000000001,
                spent: 830.3200000000002,
                result: -216.6595000000001,
                "ROI%": -26.093494074573663,
            },
            sedo: {
                revenue: 2364.56578,
                spent: 2487.69,
                result: -123.12422000000015,
                "ROI%": -4.94933934694436,
            },
            system1: { revenue: undefined, spent: undefined, result: NaN, "ROI%": NaN },
            crossroads: {
                revenue: 872.2842,
                spent: 1088.52,
                result: -216.23579999999993,
                "ROI%": -19.865119611950167,
            },
        },
        {
            date: "2023-11-19",
            tonic: {
                revenue: 514.9503,
                spent: 564.31,
                result: -49.359699999999975,
                "ROI%": -8.746912158210908,
            },
            sedo: {
                revenue: 2466.5652510000004,
                spent: 2440.4100000000003,
                result: 26.155251000000135,
                "ROI%": 1.0717564261742973,
            },
            system1: { revenue: undefined, spent: undefined, result: NaN, "ROI%": NaN },
            crossroads: {
                revenue: 688.7579999999999,
                spent: 721.6700000000001,
                result: -32.91200000000015,
                "ROI%": -4.560533207698825,
            },
        },
        {
            date: "2023-11-26",
            tonic: {
                revenue: 560.9387999999999,
                spent: 659.1800000000001,
                result: -98.24120000000016,
                "ROI%": -14.903546830911152,
            },
            sedo: {
                revenue: 2002.02332343,
                spent: 1959.12,
                result: 42.90332343,
                "ROI%": 2.1899283060762054,
            },
            system1: { revenue: undefined, spent: undefined, result: NaN, "ROI%": NaN },
            crossroads: {
                revenue: 997.7970000000003,
                spent: 856.6800000000001,
                result: 141.1170000000002,
                "ROI%": 16.472545174394206,
            },
        },
        {
            date: "2023-12-03",
            tonic: {
                revenue: 362.4024,
                spent: 432.80000000000007,
                result: -70.39760000000007,
                "ROI%": -16.2656192236599,
            },
            sedo: {
                revenue: 2172.6803406,
                spent: 1763.0599999999997,
                result: 409.6203406000002,
                "ROI%": 23.233488400848536,
            },
            system1: { revenue: undefined, spent: undefined, result: NaN, "ROI%": NaN },
            crossroads: {
                revenue: 1309.8213,
                spent: 1232.5200000000002,
                result: 77.30129999999986,
                "ROI%": 6.271808976730586,
            },
        },
        {
            date: "2023-12-10",
            tonic: {
                revenue: 270.7974,
                spent: 254.04999999999995,
                result: 16.747400000000027,
                "ROI%": 6.592166896280283,
            },
            sedo: {
                revenue: 2344.812990258,
                spent: 1982.3099999999995,
                result: 362.5029902580004,
                "ROI%": 18.28689711790792,
            },
            system1: { revenue: undefined, spent: undefined, result: NaN, "ROI%": NaN },
            crossroads: {
                revenue: 1204.1082,
                spent: 1124.8000000000002,
                result: 79.30819999999972,
                "ROI%": 7.050871266002812,
            },
        },
        {
            "date": "2023-12-17",
            "tonic": {
                "revenue": 194.47230000000002,
                "spent": 332.71000000000004,
                "result": -138.23770000000002,
                "ROI%": -41.549006642421325
            },
            "sedo": {
                "revenue": 2287.8305346900006,
                "spent": 1995.17,
                "result": 292.66053469000053,
                "ROI%": 14.668451043770725
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 1360.0041,
                "spent": 1441.92,
                "result": -81.91589999999997,
                "ROI%": -5.681029460719033
            }
        },
        {
            "date": "2023-12-24",
            "tonic": {
                "revenue": 339.9522,
                "spent": 482.78999999999996,
                "result": -142.83779999999996,
                "ROI%": -29.585906916050455
            },
            "sedo": {
                "revenue": 2327.588789850001,
                "spent": 1839.29,
                "result": 488.29878985000096,
                "ROI%": 26.548221860065624
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 1162.3326000000002,
                "spent": 1168.93,
                "result": -6.59739999999988,
                "ROI%": -0.5643964993626516
            }
        },
        {
            "date": "2023-12-31",
            "tonic": {
                "revenue": 31.1085,
                "spent": 38.230000000000004,
                "result": -7.121500000000005,
                "ROI%": -18.628040805650027
            },
            "sedo": {
                "revenue": 310.93890630000016,
                "spent": 245.46999999999997,
                "result": 65.46890630000019,
                "ROI%": 26.67083810648967
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 154.01730000000003,
                "spent": 158.95000000000005,
                "result": -4.932700000000011,
                "ROI%": -3.103302925448259
            }
        },
        {
            "date": "2024-01-01",
            "tonic": {
                "revenue": 492.1652999999999,
                "spent": 808.66,
                "result": -316.4947000000001,
                "ROI%": -39.13816684391463
            },
            "sedo": {
                "revenue": 2457.8770310400005,
                "spent": 2168.73,
                "result": 289.14703104000046,
                "ROI%": 13.332550895685525
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 1249.5015,
                "spent": 1392.9900000000002,
                "result": -143.48850000000016,
                "ROI%": -10.300755927896121
            }
        },
        {
            "date": "2024-01-07",
            "tonic": {
                "revenue": 42.39869999999999,
                "spent": 45.25,
                "result": -2.851300000000009,
                "ROI%": -6.301215469613275
            },
            "sedo": {
                "revenue": 2867.5746394900007,
                "spent": 2337.02,
                "result": 530.5546394900007,
                "ROI%": 22.702186523435852
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 414.2406000000001,
                "spent": 438.7900000000001,
                "result": -24.54939999999999,
                "ROI%": -5.594794776544587
            }
        },
        {
            "date": "2024-01-14",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 2628.8754484200003,
                "spent": 2154,
                "result": 474.87544842000034,
                "ROI%": 22.046213947075223
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            }
        },
        {
            "date": "2024-01-21",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 2822.41591818,
                "spent": 2198.48,
                "result": 623.93591818,
                "ROI%": 28.38033178286816
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            }
        },
        {
            "date": "2024-01-28",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 3286.7715394000006,
                "spent": 2646.6256,
                "result": 640.1459394000008,
                "ROI%": 24.187249583016236
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            }
        },
        {
            "date": "2024-02-04",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 3282.44341,
                "spent": 2663.8872,
                "result": 618.5562099999997,
                "ROI%": 23.22006014368776
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            }
        },
        {
            "date": "2024-02-11",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 2541.5919350000004,
                "spent": 2157.532,
                "result": 384.0599350000002,
                "ROI%": 17.800891713309476
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 559.57,
                "spent": 535.5896,
                "result": 23.98040000000003,
                "ROI%": 4.4773834294019155
            }
        },
        {
            "date": "2024-02-18",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 2515.1704200000004,
                "spent": 2343.4840000000004,
                "result": 171.68642,
                "ROI%": 7.326118718967134
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 1013.56,
                "spent": 937.8928,
                "result": 75.66719999999998,
                "ROI%": 8.0677877045223
            }
        },
        {
            "date": "2024-02-25",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 3686.9856479999994,
                "spent": 3336.4136,
                "result": 350.57204799999954,
                "ROI%": 10.5074517140201
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 983.3699999999999,
                "spent": 1194.18,
                "result": -210.81000000000017,
                "ROI%": -17.653117620459234
            }
        },
        {
            "date": "2024-03-03",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 3685.606651000001,
                "spent": 3243.4896,
                "result": 442.1170510000011,
                "ROI%": 13.630907002137494
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 702.42,
                "spent": 735.8208000000001,
                "result": -33.40080000000012,
                "ROI%": -4.539257384406659
            }
        },
        {
            "date": "2024-03-10",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 3299.484718,
                "spent": 3197.3656,
                "result": 102.11911800000007,
                "ROI%": 3.1938517759745766
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 846.22,
                "spent": 954.9176000000001,
                "result": -108.69760000000008,
                "ROI%": -11.382929794151874
            }
        },
        {
            "date": "2024-03-17",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 3221.0836320000008,
                "spent": 3297.3824000000004,
                "result": -76.29876799999965,
                "ROI%": -2.3139193076301856
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 702.6699999999998,
                "spent": 729.3935999999999,
                "result": -26.723600000000033,
                "ROI%": -3.6638105955412836
            }
        },
        {
            "date": "2024-03-24",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 4077.552528,
                "spent": 3911.1487999999995,
                "result": 166.40372800000068,
                "ROI%": 4.254599773856738
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 1293.69,
                "spent": 1533.8544000000002,
                "result": -240.16440000000011,
                "ROI%": -15.657574799798478
            }
        },
        {
            "date": "2024-03-31",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 3493.452099,
                "spent": 3204.0632,
                "result": 289.38889900000004,
                "ROI%": 9.031934794544627
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 1235.9999999999998,
                "spent": 1168.2944,
                "result": 67.70559999999978,
                "ROI%": 5.795251607813912
            }
        },
        {
            "date": "2024-04-07",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 2948.0521,
                "spent": 2565.7943999999998,
                "result": 382.2577000000001,
                "ROI%": 14.898220215930014
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 596.27,
                "spent": 627.7128,
                "result": -31.442800000000034,
                "ROI%": -5.009106075262448
            }
        },
        {
            "date": "2024-04-14",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 2734.3883010000004,
                "spent": 2674.952799999999,
                "result": 59.435501000001295,
                "ROI%": 2.2219270934425994
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 508.23,
                "spent": 594.1936000000001,
                "result": -85.96360000000004,
                "ROI%": -14.467271273201199
            }
        },
        {
            "date": "2024-04-21",
            "tonic": {
                "revenue": 8.650000000000002,
                "spent": 17.887999999999998,
                "result": -9.237999999999996,
                "ROI%": -51.64355992844363
            },
            "sedo": {
                "revenue": 2511.3017010000003,
                "spent": 2587.6344,
                "result": -76.3326989999996,
                "ROI%": -2.9499027760644836
            },
            "system1": {
                "revenue": 6.26,
                "spent": 25.8856,
                "result": -19.6256,
                "ROI%": -75.81667027227493
            },
            "crossroads": {
                "revenue": 765.09,
                "spent": 782.5480000000001,
                "result": -17.458000000000084,
                "ROI%": -2.230917464487814
            }
        },
        {
            "date": "2024-04-28",
            "tonic": {
                "revenue": 20.46999999999999,
                "spent": 53.56,
                "result": -33.09000000000001,
                "ROI%": -61.781179985063496
            },
            "sedo": {
                "revenue": 2486.2928979999997,
                "spent": 2547.8544000000006,
                "result": -61.56150200000093,
                "ROI%": -2.416209576183037
            },
            "system1": {
                "revenue": 3.4000000000000004,
                "spent": 16.380000000000003,
                "result": -12.980000000000002,
                "ROI%": -79.24297924297925
            },
            "crossroads": {
                "revenue": 1292.1100000000001,
                "spent": 2397.6056000000003,
                "result": -1105.4956000000002,
                "ROI%": -46.10831739799073
            }
        },
        {
            "date": "2024-05-05",
            "tonic": {
                "revenue": 55.66,
                "spent": 102.0864,
                "result": -46.4264,
                "ROI%": -45.47755626606482
            },
            "sedo": {
                "revenue": 3663.453259000001,
                "spent": 3485.924,
                "result": 177.52925900000082,
                "ROI%": 5.092746112651936
            },
            "system1": {
                "revenue": 32.2,
                "spent": 37.419200000000004,
                "result": -5.219200000000001,
                "ROI%": -13.947919784495665
            },
            "crossroads": {
                "revenue": 1086.1200000000001,
                "spent": 1107.4440000000002,
                "result": -21.32400000000007,
                "ROI%": -1.9255149696056906
            }
        },
        {
            "date": "2024-05-12",
            "tonic": {
                "revenue": 19.800000000000004,
                "spent": 20.997600000000006,
                "result": -1.1976000000000013,
                "ROI%": -5.703508972453997
            },
            "sedo": {
                "revenue": 5205.806813000002,
                "spent": 4617.256800000001,
                "result": 588.550013000001,
                "ROI%": 12.746746358140637
            },
            "system1": {
                "revenue": 66.1,
                "spent": 61.17280000000001,
                "result": 4.927199999999985,
                "ROI%": 8.054560196688708
            },
            "crossroads": {
                "revenue": 1312.5800000000004,
                "spent": 1283.5784,
                "result": 29.00160000000028,
                "ROI%": 2.2594334713018194
            }
        },
        {
            "date": "2024-05-19",
            "tonic": {
                "revenue": 2.540000000000001,
                "spent": 7.384000000000001,
                "result": -4.844,
                "ROI%": -65.60130010834236
            },
            "sedo": {
                "revenue": 5618.380614000001,
                "spent": 5740.124000000001,
                "result": -121.7433860000001,
                "ROI%": -2.1209190951275603
            },
            "system1": {
                "revenue": 9.649999999999999,
                "spent": 9.672,
                "result": -0.022000000000002018,
                "ROI%": -0.2274607113316951
            },
            "crossroads": {
                "revenue": 1742.45,
                "spent": 1843.0048000000002,
                "result": -100.55480000000011,
                "ROI%": -5.45602485679908
            }
        },
        {
            "date": "2024-05-26",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 4828.162701000001,
                "spent": 4978.844,
                "result": -150.68129899999894,
                "ROI%": -3.0264314166099426
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 1405.4100000000003,
                "spent": 1486.6904,
                "result": -81.28039999999964,
                "ROI%": -5.467204200686282
            }
        },
        {
            "date": "2024-06-02",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 3274.382684,
                "spent": 3336.5487999999996,
                "result": -62.16611599999942,
                "ROI%": -1.8631861760870838
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 941.2799999999999,
                "spent": 1035.9648,
                "result": -94.68480000000011,
                "ROI%": -9.139769999907344
            }
        },
        {
            "date": "2024-06-09",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 3021.5834870000003,
                "spent": 3271.4136,
                "result": -249.83011299999953,
                "ROI%": -7.63676329400842
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 583.97,
                "spent": 820.7887999999999,
                "result": -236.8187999999999,
                "ROI%": -28.85258668246934
            }
        },
        {
            "date": "2024-06-16",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 3273.867658,
                "spent": 3365.3256000000006,
                "result": -91.45794200000046,
                "ROI%": -2.7176550762279983
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 499.98,
                "spent": 613.4128,
                "result": -113.43279999999993,
                "ROI%": -18.492082330202418
            }
        },
        {
            "date": "2024-06-23",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 3366.3829610000003,
                "spent": 3623.6720000000005,
                "result": -257.28903900000023,
                "ROI%": -7.100229794528867
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 443.98,
                "spent": 509.0592,
                "result": -65.07919999999996,
                "ROI%": -12.784210559400544
            }
        },
        {
            "date": "2024-06-30",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 4104.308694,
                "spent": 5293.4544,
                "result": -1189.1457059999993,
                "ROI%": -22.464455460313392
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 453.18,
                "spent": 694.2624000000002,
                "result": -241.08240000000018,
                "ROI%": -34.72496854215353
            }
        },
        {
            "date": "2024-07-07",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 717.0550919999995,
                "spent": 801.2159999999997,
                "result": -84.16090800000018,
                "ROI%": -10.504147196261703
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 641.61,
                "spent": 737.2664000000002,
                "result": -95.65640000000019,
                "ROI%": -12.974468930090966
            }
        },
        {
            "date": "2024-07-14",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 489.87,
                "spent": 408.32480000000004,
                "result": 81.54519999999997,
                "ROI%": 19.97067040747953
            }
        },
        {
            "date": "2024-07-21",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "result": null,
                "ROI%": null
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 642.72,
                "spent": 581.3808,
                "result": 61.339200000000005,
                "ROI%": 10.550606418375018
            }
        },
        {
            "date": "2024-07-28",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "result": null,
                "ROI%": null
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 507.6099999999999,
                "spent": 504.23359999999997,
                "result": 3.376399999999933,
                "ROI%": 0.6696102758721123
            }
        },
        {
            "date": "2024-08-04",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "result": null,
                "ROI%": null
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 206.17,
                "spent": 186.48680000000002,
                "result": 19.68319999999997,
                "ROI%": 10.554741676086433
            }
        },
        {
            "date": "2024-08-11",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "result": null,
                "ROI%": null
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 15.34,
                "spent": 11.33,
                "result": 4.01,
                "ROI%": 35.39276257722859
            }
        },
        {
            "date": "2024-08-18",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "result": null,
                "ROI%": null
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            }
        },
        {
            "date": "2024-08-25",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "result": null,
                "ROI%": null
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            }
        },
        {
            "date": "2024-09-01",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "result": null,
                "ROI%": null
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            }
        },
        {
            "date": "2024-09-08",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "result": null,
                "ROI%": null
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            }
        },
    ],

    LFC: [
        {
            "date": "2024-02-04",
            "tonic": {
                "revenue": 2585.5099999999993,
                "spent": 2190.8599999999997,
                "result": 394.64999999999964,
                "ROI%": 18.013474160831812
            },
            "sedo": {
                "revenue": 3449.6725690000003,
                "spent": 2704.8299999999995,
                "result": 744.8425690000008,
                "ROI%": 27.53750028652451
            },
            "system1": {
                "revenue": 224.77,
                "spent": 280.28000000000003,
                "result": -55.51000000000002,
                "ROI%": -19.805194805194816
            },
            "crossroads": {
                "revenue": 3329.6499999999996,
                "spent": 2528.25,
                "result": 801.3999999999996,
                "ROI%": 31.69781469395825
            }
        },
        {
            "date": "2024-02-11",
            "tonic": {
                "revenue": 2587.649999999999,
                "spent": 2123.49,
                "result": 464.1599999999994,
                "ROI%": 21.858355819900233
            },
            "sedo": {
                "revenue": 4805.962405000001,
                "spent": 3813.540000000001,
                "result": 992.4224050000003,
                "ROI%": 26.02365269539588
            },
            "system1": {
                "revenue": 270.7,
                "spent": 234.99,
                "result": 35.70999999999998,
                "ROI%": 15.196391335801508
            },
            "crossroads": {
                "revenue": 5942.490000000001,
                "spent": 4316.09,
                "result": 1626.4000000000005,
                "ROI%": 37.68225407718562
            }
        },
        {
            "date": "2024-02-18",
            "tonic": {
                "revenue": 2065.9999999999995,
                "spent": 1645.0399999999997,
                "result": 420.9599999999998,
                "ROI%": 25.58965131546953
            },
            "sedo": {
                "revenue": 5110.883136,
                "spent": 4356.54,
                "result": 754.3431360000004,
                "ROI%": 17.31518902615379
            },
            "system1": {
                "revenue": 205.98000000000002,
                "spent": 192.57,
                "result": 13.410000000000025,
                "ROI%": 6.963701511138809
            },
            "crossroads": {
                "revenue": 7244.959999999999,
                "spent": 5082.8,
                "result": 2162.159999999999,
                "ROI%": 42.53875816479105
            }
        },
        {
            "date": "2024-02-25",
            "tonic": {
                "revenue": 2309.31,
                "spent": 1833.79,
                "result": 475.52,
                "ROI%": 25.930995370244148
            },
            "sedo": {
                "revenue": 5765.743512000001,
                "spent": 5279.69,
                "result": 486.0535120000013,
                "ROI%": 9.20609944902071
            },
            "system1": {
                "revenue": 256.7,
                "spent": 240.57999999999998,
                "result": 16.120000000000005,
                "ROI%": 6.700473854850775
            },
            "crossroads": {
                "revenue": 7348.379999999999,
                "spent": 5999.110000000001,
                "result": 1349.2699999999986,
                "ROI%": 22.491169523479293
            }
        },
        {
            "date": "2024-03-03",
            "tonic": {
                "revenue": 2682.1699999999996,
                "spent": 2191.78,
                "result": 490.3899999999994,
                "ROI%": 22.37405214026953
            },
            "sedo": {
                "revenue": 6561.201123999999,
                "spent": 5988.960000000001,
                "result": 572.2411239999983,
                "ROI%": 9.554933143650945
            },
            "system1": {
                "revenue": 262.41,
                "spent": 243.38,
                "result": 19.03000000000003,
                "ROI%": 7.819048401676398
            },
            "crossroads": {
                "revenue": 8035.5199999999995,
                "spent": 6839.669999999998,
                "result": 1195.8500000000013,
                "ROI%": 17.484030662298068
            }
        },
        {
            "date": "2024-03-10",
            "tonic": {
                "revenue": 2510.7199999999993,
                "spent": 2133.6599999999994,
                "result": 377.05999999999995,
                "ROI%": 17.67198147783622
            },
            "sedo": {
                "revenue": 6826.332754,
                "spent": 7175.200000000001,
                "result": -348.8672460000007,
                "ROI%": -4.862125738655376
            },
            "system1": {
                "revenue": 284.67999999999995,
                "spent": 276.19,
                "result": 8.489999999999952,
                "ROI%": 3.073970817191052
            },
            "crossroads": {
                "revenue": 8429.98,
                "spent": 8102.309999999999,
                "result": 327.670000000001,
                "ROI%": 4.044155308794672
            }
        },
        {
            "date": "2024-03-17",
            "tonic": {
                "revenue": 2470.039999999999,
                "spent": 2031.6599999999999,
                "result": 438.3799999999992,
                "ROI%": 21.577429294271646
            },
            "sedo": {
                "revenue": 5301.983168,
                "spent": 5125.29,
                "result": 176.6931679999998,
                "ROI%": 3.4474764940130243
            },
            "system1": {
                "revenue": 191.65999999999997,
                "spent": 182.90999999999997,
                "result": 8.75,
                "ROI%": 4.783773440489858
            },
            "crossroads": {
                "revenue": 6693.85,
                "spent": 6283.689999999998,
                "result": 410.1600000000026,
                "ROI%": 6.527374838669675
            }
        },
        {
            "date": "2024-03-24",
            "tonic": {
                "revenue": 2419.9899999999993,
                "spent": 2409.9,
                "result": 10.089999999999236,
                "ROI%": 0.41868957218138547
            },
            "sedo": {
                "revenue": 4944.529872000001,
                "spent": 4914.09,
                "result": 30.439872000000832,
                "ROI%": 0.6194406695848143
            },
            "system1": {
                "revenue": 113.77,
                "spent": 114.2,
                "result": -0.4300000000000068,
                "ROI%": -0.37653239929947846
            },
            "crossroads": {
                "revenue": 6064.710000000001,
                "spent": 6167.830000000002,
                "result": -103.1200000000008,
                "ROI%": -1.6719008143869174
            }
        },
        {
            "date": "2024-03-31",
            "tonic": {
                "revenue": 2032.1699999999998,
                "spent": 2093.9799999999996,
                "result": -61.80999999999972,
                "ROI%": -2.951795146085434
            },
            "sedo": {
                "revenue": 4773.420639000002,
                "spent": 4549.110000000001,
                "result": 224.31063900000117,
                "ROI%": 4.9308686534289325
            },
            "system1": {
                "revenue": 82.77,
                "spent": 95.18,
                "result": -12.41000000000001,
                "ROI%": -13.038453456608545
            },
            "crossroads": {
                "revenue": 4000.3200000000006,
                "spent": 4185.32,
                "result": -184.9999999999991,
                "ROI%": -4.4202115967237665
            }
        },
        {
            "date": "2024-04-07",
            "tonic": {
                "revenue": 1829.8399999999997,
                "spent": 2055.3099999999995,
                "result": -225.4699999999998,
                "ROI%": -10.970121295570978
            },
            "sedo": {
                "revenue": 5380.473110000002,
                "spent": 4891.99,
                "result": 488.48311000000194,
                "ROI%": 9.985366078017366
            },
            "system1": {
                "revenue": 97.39999999999999,
                "spent": 82.17999999999999,
                "result": 15.219999999999999,
                "ROI%": 18.520321246045256
            },
            "crossroads": {
                "revenue": 6348.59,
                "spent": 6169.010000000001,
                "result": 179.57999999999902,
                "ROI%": 2.911001927375678
            }
        },
        {
            "date": "2024-04-14",
            "tonic": {
                "revenue": 1604.8799999999997,
                "spent": 1554.4399999999998,
                "result": 50.43999999999983,
                "ROI%": 3.2448984843416184
            },
            "sedo": {
                "revenue": 5408.573579000001,
                "spent": 5265.95,
                "result": 142.62357900000097,
                "ROI%": 2.7084111888643347
            },
            "system1": {
                "revenue": 322.95000000000005,
                "spent": 434.10999999999996,
                "result": -111.15999999999991,
                "ROI%": -25.606413121098328
            },
            "crossroads": {
                "revenue": 6823.489999999999,
                "spent": 6677.549999999999,
                "result": 145.9399999999996,
                "ROI%": 2.185532118816025
            }
        },
        {
            "date": "2024-04-21",
            "tonic": {
                "revenue": 1857.1699999999994,
                "spent": 1852.2,
                "result": 4.969999999999345,
                "ROI%": 0.2683295540438113
            },
            "sedo": {
                "revenue": 6730.319220000001,
                "spent": 6427.280000000001,
                "result": 303.03922000000057,
                "ROI%": 4.71489059135437
            },
            "system1": {
                "revenue": 212.81,
                "spent": 258.31,
                "result": -45.5,
                "ROI%": -17.61449421238047
            },
            "crossroads": {
                "revenue": 7369.780000000002,
                "spent": 7797.080000000002,
                "result": -427.3000000000002,
                "ROI%": -5.480256711486864
            }
        },
        {
            "date": "2024-04-28",
            "tonic": {
                "revenue": 2351.8699999999994,
                "spent": 2528.73,
                "result": -176.86000000000058,
                "ROI%": -6.994024668509513
            },
            "sedo": {
                "revenue": 10428.752307,
                "spent": 9395.059999999998,
                "result": 1033.692307000003,
                "ROI%": 11.002508839751979
            },
            "system1": {
                "revenue": 190.41,
                "spent": 157.61,
                "result": 32.79999999999998,
                "ROI%": 20.81086225493305
            },
            "crossroads": {
                "revenue": 6957.549999999998,
                "spent": 7226.169999999999,
                "result": -268.6200000000008,
                "ROI%": -3.7173219008133085
            }
        },
        {
            "date": "2024-05-05",
            "tonic": {
                "revenue": 1827.0599999999995,
                "spent": 1970.1200000000001,
                "result": -143.06000000000063,
                "ROI%": -7.261486609952728
            },
            "sedo": {
                "revenue": 11823.168825000004,
                "spent": 11036.7,
                "result": 786.4688250000036,
                "ROI%": 7.125941857620521
            },
            "system1": {
                "revenue": 376.9200000000001,
                "spent": 396.41,
                "result": -19.489999999999952,
                "ROI%": -4.916626724855567
            },
            "crossroads": {
                "revenue": 7871.610000000001,
                "spent": 8278.25,
                "result": -406.6399999999994,
                "ROI%": -4.912149306918723
            }
        },
        {
            "date": "2024-05-12",
            "tonic": {
                "revenue": 2013.62,
                "spent": 1954.29,
                "result": 59.32999999999993,
                "ROI%": 3.0358851552226174
            },
            "sedo": {
                "revenue": 16799.837400999997,
                "spent": 16654.89,
                "result": 144.94740099999763,
                "ROI%": 0.8702993595274267
            },
            "system1": {
                "revenue": 173.77,
                "spent": 177.22,
                "result": -3.4499999999999886,
                "ROI%": -1.9467328743933976
            },
            "crossroads": {
                "revenue": 6145.22,
                "spent": 6171.879999999999,
                "result": -26.659999999998945,
                "ROI%": -0.431959143729288
            }
        },
        {
            "date": "2024-05-19",
            "tonic": {
                "revenue": 2669.23,
                "spent": 2653.84,
                "result": 15.389999999999873,
                "ROI%": 0.579914388207281
            },
            "sedo": {
                "revenue": 16308.370838000003,
                "spent": 15745.039999999995,
                "result": 563.3308380000071,
                "ROI%": 3.5778304659753735
            },
            "system1": {
                "revenue": 154.51999999999998,
                "spent": 152.66,
                "result": 1.8599999999999852,
                "ROI%": 1.2183938163238484
            },
            "crossroads": {
                "revenue": 6569.1900000000005,
                "spent": 6535.749999999999,
                "result": 33.44000000000142,
                "ROI%": 0.5116474773362079
            }
        },
        {
            "date": "2024-05-26",
            "tonic": {
                "revenue": 2870.52,
                "spent": 3221.23,
                "result": -350.71000000000004,
                "ROI%": -10.887456033875264
            },
            "sedo": {
                "revenue": 13025.254461999994,
                "spent": 11587.169999999998,
                "result": 1438.0844619999953,
                "ROI%": 12.41100684636538
            },
            "system1": {
                "revenue": 317.99,
                "spent": 411.69000000000005,
                "result": -93.70000000000005,
                "ROI%": -22.75984357161943
            },
            "crossroads": {
                "revenue": 6641.96,
                "spent": 7124.589999999999,
                "result": -482.6299999999992,
                "ROI%": -6.774144196367782
            }
        },
        {
            "date": "2024-06-02",
            "tonic": {
                "revenue": 2522.24,
                "spent": 2670.5600000000004,
                "result": -148.32000000000062,
                "ROI%": -5.5538913186747525
            },
            "sedo": {
                "revenue": 16107.187087,
                "spent": 15628.640000000001,
                "result": 478.547086999999,
                "ROI%": 3.0619880360671203
            },
            "system1": {
                "revenue": 264,
                "spent": 284.49,
                "result": -20.49000000000001,
                "ROI%": -7.202362121691452
            },
            "crossroads": {
                "revenue": 7157.9699999999975,
                "spent": 7091.129999999998,
                "result": 66.83999999999924,
                "ROI%": 0.9425860194355318
            }
        },
        {
            "date": "2024-06-09",
            "tonic": {
                "revenue": 2910.229999999999,
                "spent": 2939.1800000000003,
                "result": -28.950000000001182,
                "ROI%": -0.9849685966834665
            },
            "sedo": {
                "revenue": 20194.051831,
                "spent": 19367.799999999996,
                "result": 826.251831000005,
                "ROI%": 4.266110921219779
            },
            "system1": {
                "revenue": 480.3,
                "spent": 604.7700000000001,
                "result": -124.47000000000008,
                "ROI%": -20.581378044545872
            },
            "crossroads": {
                "revenue": 5443.97,
                "spent": 5680.0700000000015,
                "result": -236.10000000000127,
                "ROI%": -4.156638914661281
            }
        },
        {
            "date": "2024-06-16",
            "tonic": {
                "revenue": 1698.2599999999995,
                "spent": 1989.5399999999997,
                "result": -291.2800000000002,
                "ROI%": -14.640570182052148
            },
            "sedo": {
                "revenue": 20691.69541699999,
                "spent": 19452.000000000004,
                "result": 1239.695416999988,
                "ROI%": 6.373100025704237
            },
            "system1": {
                "revenue": 237.57,
                "spent": 230.29999999999998,
                "result": 7.27000000000001,
                "ROI%": 3.156752062527146
            },
            "crossroads": {
                "revenue": 5334.090000000001,
                "spent": 5644.6799999999985,
                "result": -310.5899999999974,
                "ROI%": -5.502349114564464
            }
        },
        {
            "date": "2024-06-23",
            "tonic": {
                "revenue": 2319.6499999999996,
                "spent": 2406.2900000000004,
                "result": -86.64000000000078,
                "ROI%": -3.600563523099909
            },
            "sedo": {
                "revenue": 22063.682051999996,
                "spent": 21689.059999999994,
                "result": 374.62205200000244,
                "ROI%": 1.7272396867360928
            },
            "system1": {
                "revenue": 181.62,
                "spent": 189.2,
                "result": -7.579999999999984,
                "ROI%": -4.006342494714577
            },
            "crossroads": {
                "revenue": 5083.030000000001,
                "spent": 5371.829999999999,
                "result": -288.79999999999836,
                "ROI%": -5.376193959972642
            }
        },
        {
            "date": "2024-06-30",
            "tonic": {
                "revenue": 1506.9099999999985,
                "spent": 1695.63,
                "result": -188.72000000000162,
                "ROI%": -11.12978656900394
            },
            "sedo": {
                "revenue": 20204.96335,
                "spent": 19419.429999999993,
                "result": 785.5333500000088,
                "ROI%": 4.04508963445378
            },
            "system1": {
                "revenue": 156.77999999999997,
                "spent": 173.73000000000002,
                "result": -16.950000000000045,
                "ROI%": -9.756518735969632
            },
            "crossroads": {
                "revenue": 5023.9400000000005,
                "spent": 5118.57,
                "result": -94.6299999999992,
                "ROI%": -1.848758539982831
            }
        },
        {
            "date": "2024-07-07",
            "tonic": {
                "revenue": 843.5600000000006,
                "spent": 932.0500000000001,
                "result": -88.48999999999944,
                "ROI%": -9.49412585161734
            },
            "sedo": {
                "revenue": 21958.623423000005,
                "spent": 21759.419999999995,
                "result": 199.20342300000993,
                "ROI%": 0.9154813087849378
            },
            "system1": {
                "revenue": 130.79,
                "spent": 152.39,
                "result": -21.599999999999994,
                "ROI%": -14.17415840934444
            },
            "crossroads": {
                "revenue": 5282.59,
                "spent": 5528.34,
                "result": -245.75,
                "ROI%": -4.445276520619212
            }
        },
        {
            "date": "2024-07-14",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 20935.245229999997,
                "spent": 18812.72,
                "result": 2122.5252299999956,
                "ROI%": 11.282394199243884
            },
            "system1": {
                "revenue": 73.07,
                "spent": 70.94,
                "result": 2.1299999999999955,
                "ROI%": 3.002537355511703
            },
            "crossroads": {
                "revenue": 3961.840000000001,
                "spent": 3596.7299999999996,
                "result": 365.1100000000015,
                "ROI%": 10.151165086064328
            }
        },
        {
            "date": "2024-07-21",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 20273.5468392,
                "spent": 19425.789999999997,
                "result": 847.756839200003,
                "ROI%": 4.364079088675421
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 4292.23,
                "spent": 3810.09,
                "result": 482.1399999999994,
                "ROI%": 12.654294255516252
            }
        },
        {
            "date": "2024-07-28",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 15375.3033322,
                "spent": 14481.650000000003,
                "result": 893.6533321999959,
                "ROI%": 6.170935854685045
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 4735.919999999999,
                "spent": 4493.9800000000005,
                "result": 241.9399999999987,
                "ROI%": 5.383646567185396
            }
        },
        {
            "date": "2024-08-04",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 13147.400804,
                "spent": 12880.35,
                "result": 267.0508040000004,
                "ROI%": 2.0733194672505117
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 4049.4599999999996,
                "spent": 3844.1099999999997,
                "result": 205.3499999999999,
                "ROI%": 5.341938706228477
            }
        },
        {
            "date": "2024-08-11",
            "tonic": {
                "revenue": 219.72000000000003,
                "spent": 312.43,
                "result": -92.70999999999998,
                "ROI%": -29.67384694171493
            },
            "sedo": {
                "revenue": 11001.432446000003,
                "spent": 9783.390000000001,
                "result": 1218.0424460000013,
                "ROI%": 12.45010621062843
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 2279.8099999999995,
                "spent": 2167.77,
                "result": 112.03999999999951,
                "ROI%": 5.168444991857979
            }
        },
        {
            "date": "2024-08-18",
            "tonic": {
                "revenue": 907.3699999999998,
                "spent": 1163.08,
                "result": -255.71000000000015,
                "ROI%": -21.985589985211696
            },
            "sedo": {
                "revenue": 12058.735359999999,
                "spent": 11039.919999999998,
                "result": 1018.8153600000005,
                "ROI%": 9.22846687294836
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 2640.66,
                "spent": 2665.16,
                "result": -24.5,
                "ROI%": -0.9192693872037694
            }
        },
        {
            "date": "2024-08-25",
            "tonic": {
                "revenue": 883.3799999999999,
                "spent": 1184.2800000000002,
                "result": -300.9000000000003,
                "ROI%": -25.407842739892615
            },
            "sedo": {
                "revenue": 8617.168081,
                "spent": 8623.98,
                "result": -6.811918999999762,
                "ROI%": -0.07898811221732638
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 1796.0500000000004,
                "spent": 1868.67,
                "result": -72.61999999999966,
                "ROI%": -3.8861864320612893
            }
        },
        {
            "date": "2024-09-01",
            "tonic": {
                "revenue": 816.1899999999999,
                "spent": 1017.59,
                "result": -201.4000000000001,
                "ROI%": -19.79186116215765
            },
            "sedo": {
                "revenue": 7007.22252,
                "spent": 6876.700000000001,
                "result": 130.52251999999953,
                "ROI%": 1.8980400482789594
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 1979.02,
                "spent": 2070.9,
                "result": -91.88000000000011,
                "ROI%": -4.436718335023427
            }
        },
        {
            "date": "2024-09-08",
            "tonic": {
                "revenue": 964.7099999999996,
                "spent": 1274.04,
                "result": -309.3300000000004,
                "ROI%": -24.27945747386271
            },
            "sedo": {
                "revenue": 7255.615925999998,
                "spent": 8751.06,
                "result": -1495.4440740000018,
                "ROI%": -17.088719240869132
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 2898.5399999999995,
                "spent": 2971.68,
                "result": -73.14000000000033,
                "ROI%": -2.461234049426597
            }
        },
    ],

    Moneyball: [
        {
            "date": "2024-02-25",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 38.050000000000004,
                "spent": 76.4,
                "result": -38.35,
                "ROI%": -50.19633507853403
            }
        },
        {
            "date": "2024-03-03",
            "tonic": {
                "revenue": 102,
                "spent": 233.44,
                "result": -131.44,
                "ROI%": -56.30568882796436
            },
            "sedo": {
                "revenue": 206.22135100000003,
                "spent": 303.12,
                "result": -96.89864899999998,
                "ROI%": -31.967091910794398
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 536.69,
                "spent": 663.25,
                "result": -126.55999999999995,
                "ROI%": -19.081794195250655
            }
        },
        {
            "date": "2024-03-10",
            "tonic": {
                "revenue": 59.52000000000002,
                "spent": 65.63999999999999,
                "result": -6.119999999999969,
                "ROI%": -9.32358318098716
            },
            "sedo": {
                "revenue": 633.7045270000001,
                "spent": 850.4000000000001,
                "result": -216.695473,
                "ROI%": -25.48159372060207
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 1189.3700000000001,
                "spent": 1143.78,
                "result": 45.590000000000146,
                "ROI%": 3.9859063805976813
            }
        },
        {
            "date": "2024-03-17",
            "tonic": {
                "revenue": 121.84,
                "spent": 198.45,
                "result": -76.60999999999999,
                "ROI%": -38.604182413706226
            },
            "sedo": {
                "revenue": 822.900506,
                "spent": 971.7499999999999,
                "result": -148.84949399999994,
                "ROI%": -15.317673681502441
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 1994.2300000000005,
                "spent": 2018.9099999999999,
                "result": -24.67999999999938,
                "ROI%": -1.2224418126612568
            }
        },
        {
            "date": "2024-03-24",
            "tonic": {
                "revenue": 680.58,
                "spent": 909.21,
                "result": -228.63,
                "ROI%": -25.146005873230603
            },
            "sedo": {
                "revenue": 1169.663976,
                "spent": 1137.67,
                "result": 31.993975999999975,
                "ROI%": 2.812236940413304
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 2713.6899999999996,
                "spent": 2903.4000000000005,
                "result": -189.71000000000095,
                "ROI%": -6.534063511744881
            }
        },
        {
            "date": "2024-03-31",
            "tonic": {
                "revenue": 858.0299999999997,
                "spent": 898.3399999999999,
                "result": -40.31000000000017,
                "ROI%": -4.48716521584257
            },
            "sedo": {
                "revenue": 1609.6344190000002,
                "spent": 1725.0300000000002,
                "result": -115.39558099999999,
                "ROI%": -6.6894825597236025
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 3054.9800000000005,
                "spent": 2974.2200000000003,
                "result": 80.76000000000022,
                "ROI%": 2.7153337681812495
            }
        },
        {
            "date": "2024-04-07",
            "tonic": {
                "revenue": 904.9299999999998,
                "spent": 927.9299999999998,
                "result": -23,
                "ROI%": -2.4786352418824653
            },
            "sedo": {
                "revenue": 1939.1186669999997,
                "spent": 1735.1500000000003,
                "result": 203.9686669999994,
                "ROI%": 11.75510284413448
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 4661.13,
                "spent": 4188.51,
                "result": 472.6199999999999,
                "ROI%": 11.283726193801602
            }
        },
        {
            "date": "2024-04-14",
            "tonic": {
                "revenue": 945.8799999999993,
                "spent": 1176.54,
                "result": -230.66000000000065,
                "ROI%": -19.60494330834487
            },
            "sedo": {
                "revenue": 2634.58549,
                "spent": 2488.75,
                "result": 145.83548999999994,
                "ROI%": 5.859788648920139
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 6275.530000000001,
                "spent": 5784.52,
                "result": 491.0100000000002,
                "ROI%": 8.488344754620947
            }
        },
        {
            "date": "2024-04-21",
            "tonic": {
                "revenue": 873.4199999999992,
                "spent": 1023.2499999999999,
                "result": -149.83000000000072,
                "ROI%": -14.642560469093645
            },
            "sedo": {
                "revenue": 4937.7438839999995,
                "spent": 4849.799999999999,
                "result": 87.94388400000025,
                "ROI%": 1.8133507361128398
            },
            "system1": {
                "revenue": 200.97,
                "spent": 301.01,
                "result": -100.03999999999999,
                "ROI%": -33.234776253280614
            },
            "crossroads": {
                "revenue": 7431.529999999997,
                "spent": 7911.629999999999,
                "result": -480.1000000000022,
                "ROI%": -6.068281757362293
            }
        },
        {
            "date": "2024-04-28",
            "tonic": {
                "revenue": 705.9100000000005,
                "spent": 822.23,
                "result": -116.31999999999948,
                "ROI%": -14.146893205064215
            },
            "sedo": {
                "revenue": 8327.493682,
                "spent": 6700.009999999998,
                "result": 1627.4836820000019,
                "ROI%": 24.29076496900755
            },
            "system1": {
                "revenue": 187.45,
                "spent": 146.93,
                "result": 40.51999999999998,
                "ROI%": 27.577758116109695
            },
            "crossroads": {
                "revenue": 6802.4800000000005,
                "spent": 6647.899999999999,
                "result": 154.58000000000175,
                "ROI%": 2.325245566269074
            }
        },
        {
            "date": "2024-05-05",
            "tonic": {
                "revenue": 889.430000000001,
                "spent": 1190.03,
                "result": -300.599999999999,
                "ROI%": -25.259867398300795
            },
            "sedo": {
                "revenue": 7896.243652000001,
                "spent": 7497.59,
                "result": 398.6536520000009,
                "ROI%": 5.317090585108031
            },
            "system1": {
                "revenue": 125.22999999999999,
                "spent": 114.38000000000001,
                "result": 10.84999999999998,
                "ROI%": 9.48592411260709
            },
            "crossroads": {
                "revenue": 6064.769999999999,
                "spent": 6077.950000000001,
                "result": -13.18000000000211,
                "ROI%": -0.2168494311404645
            }
        },
        {
            "date": "2024-05-12",
            "tonic": {
                "revenue": 225.6399999999999,
                "spent": 305.20000000000005,
                "result": -79.56000000000014,
                "ROI%": -26.06815203145483
            },
            "sedo": {
                "revenue": 11297.417232000002,
                "spent": 10709.330000000004,
                "result": 588.087231999998,
                "ROI%": 5.491354099649537
            },
            "system1": {
                "revenue": 121.79,
                "spent": 110.31000000000002,
                "result": 11.47999999999999,
                "ROI%": 10.407034720333597
            },
            "crossroads": {
                "revenue": 6053.0999999999985,
                "spent": 5570.570000000001,
                "result": 482.5299999999979,
                "ROI%": 8.662129728196533
            }
        },
        {
            "date": "2024-05-19",
            "tonic": {
                "revenue": 579.7600000000003,
                "spent": 589.3600000000001,
                "result": -9.599999999999795,
                "ROI%": -1.6288855707886185
            },
            "sedo": {
                "revenue": 15797.455974000002,
                "spent": 15030.300000000005,
                "result": 767.1559739999975,
                "ROI%": 5.104062952835253
            },
            "system1": {
                "revenue": 18.78,
                "spent": 19.86,
                "result": -1.0799999999999983,
                "ROI%": -5.438066465256785
            },
            "crossroads": {
                "revenue": 6592.110000000001,
                "spent": 6300.11,
                "result": 292.0000000000009,
                "ROI%": 4.634839709147953
            }
        },
        {
            "date": "2024-05-26",
            "tonic": {
                "revenue": 761.2099999999998,
                "spent": 904.21,
                "result": -143.00000000000023,
                "ROI%": -15.814910253149183
            },
            "sedo": {
                "revenue": 15863.586302,
                "spent": 15273.219999999998,
                "result": 590.3663020000022,
                "ROI%": 3.8653689398830293
            },
            "system1": {
                "revenue": 152.39,
                "spent": 177.87,
                "result": -25.480000000000018,
                "ROI%": -14.325068870523427
            },
            "crossroads": {
                "revenue": 5571.12,
                "spent": 5485.26,
                "result": 85.85999999999967,
                "ROI%": 1.565285875236544
            }
        },
        {
            "date": "2024-06-02",
            "tonic": {
                "revenue": 582.9799999999998,
                "spent": 661.13,
                "result": -78.1500000000002,
                "ROI%": -11.82067066991367
            },
            "sedo": {
                "revenue": 14515.860081999996,
                "spent": 13891.52,
                "result": 624.3400819999952,
                "ROI%": 4.4943971717997355
            },
            "system1": {
                "revenue": 176.19,
                "spent": 131.51999999999998,
                "result": 44.670000000000016,
                "ROI%": 33.96441605839418
            },
            "crossroads": {
                "revenue": 4837.819999999999,
                "spent": 4712.219999999999,
                "result": 125.59999999999945,
                "ROI%": 2.6654103585995514
            }
        },
        {
            "date": "2024-06-09",
            "tonic": {
                "revenue": 808.15,
                "spent": 816.3300000000002,
                "result": -8.180000000000177,
                "ROI%": -1.0020457413056238
            },
            "sedo": {
                "revenue": 13136.758431999999,
                "spent": 12898.240000000002,
                "result": 238.51843199999712,
                "ROI%": 1.8492323913960051
            },
            "system1": {
                "revenue": 120.88999999999999,
                "spent": 118.13999999999999,
                "result": 2.75,
                "ROI%": 2.3277467411545683
            },
            "crossroads": {
                "revenue": 3598.539999999999,
                "spent": 3348.07,
                "result": 250.4699999999989,
                "ROI%": 7.481026382363543
            }
        },
        {
            "date": "2024-06-16",
            "tonic": {
                "revenue": 1225.08,
                "spent": 1649.2899999999997,
                "result": -424.2099999999998,
                "ROI%": -25.72076469268594
            },
            "sedo": {
                "revenue": 12622.385432999998,
                "spent": 12340.149999999996,
                "result": 282.23543300000165,
                "ROI%": 2.287131299052292
            },
            "system1": {
                "revenue": 24.180000000000003,
                "spent": 32.32,
                "result": -8.139999999999997,
                "ROI%": -25.18564356435643
            },
            "crossroads": {
                "revenue": 3609.5300000000007,
                "spent": 3831.95,
                "result": -222.41999999999916,
                "ROI%": -5.8043554848053684
            }
        },
        {
            "date": "2024-06-23",
            "tonic": {
                "revenue": 1250.02,
                "spent": 1478.6599999999999,
                "result": -228.63999999999987,
                "ROI%": -15.4626486142859
            },
            "sedo": {
                "revenue": 8481.283951999998,
                "spent": 9147.719999999998,
                "result": -666.4360479999996,
                "ROI%": -7.285269422326001
            },
            "system1": {
                "revenue": 1.66,
                "spent": 0.28,
                "result": 1.38,
                "ROI%": 492.8571428571428
            },
            "crossroads": {
                "revenue": 3050.7300000000005,
                "spent": 3494.0600000000004,
                "result": -443.3299999999999,
                "ROI%": -12.688104955266933
            }
        },
        {
            "date": "2024-06-30",
            "tonic": {
                "revenue": 694.87,
                "spent": 874.1500000000001,
                "result": -179.2800000000001,
                "ROI%": -20.509065949779792
            },
            "sedo": {
                "revenue": 6778.611693000001,
                "spent": 7879.149999999998,
                "result": -1100.538306999997,
                "ROI%": -13.96772884130899
            },
            "system1": {
                "revenue": 10.38,
                "spent": 23.400000000000002,
                "result": -13.020000000000001,
                "ROI%": -55.64102564102564
            },
            "crossroads": {
                "revenue": 2100.9,
                "spent": 2345.03,
                "result": -244.1300000000001,
                "ROI%": -10.410527797085756
            }
        },
        {
            "date": "2024-07-07",
            "tonic": {
                "revenue": 1.8599999999999999,
                "spent": 1.1900000000000002,
                "result": 0.6699999999999997,
                "ROI%": 56.30252100840334
            },
            "sedo": {
                "revenue": 7717.5479,
                "spent": 8394.23,
                "result": -676.6821,
                "ROI%": -8.061276615008161
            },
            "system1": {
                "revenue": 0.6,
                "spent": 0.67,
                "result": -0.07000000000000006,
                "ROI%": -10.447761194029859
            },
            "crossroads": {
                "revenue": 2203.1600000000003,
                "spent": 2384.97,
                "result": -181.8099999999995,
                "ROI%": -7.6231566854090165
            }
        },
        {
            "date": "2024-07-14",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 5287.954278000001,
                "spent": 4921.579999999999,
                "result": 366.37427800000205,
                "ROI%": 7.444241036415189
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 239.28999999999996,
                "spent": 196.57000000000002,
                "result": 42.71999999999994,
                "ROI%": 21.73271608078544
            }
        },
        {
            "date": "2024-07-21",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 5841.328607200001,
                "spent": 5341.53,
                "result": 499.79860720000124,
                "ROI%": 9.356843586013763
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 553.47,
                "spent": 482.22999999999996,
                "result": 71.24000000000007,
                "ROI%": 14.77303361466522
            }
        },
        {
            "date": "2024-07-28",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 5794.8661058,
                "spent": 5793.7,
                "result": 1.1661057999999684,
                "ROI%": 0.02012713464625282
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 415.7700000000001,
                "spent": 457.97,
                "result": -42.19999999999993,
                "ROI%": -9.214577374063794
            }
        },
        {
            "date": "2024-08-04",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 5708.059679999999,
                "spent": 5910.299999999999,
                "result": -202.24031999999988,
                "ROI%": -3.421828333587129
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 940.6500000000001,
                "spent": 948.66,
                "result": -8.009999999999877,
                "ROI%": -0.8443488710391356
            }
        },
        {
            "date": "2024-08-11",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 7258.209043000002,
                "spent": 6873.84,
                "result": 384.3690430000015,
                "ROI%": 5.591765927050996
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 2157.66,
                "spent": 2117.2400000000002,
                "result": 40.41999999999962,
                "ROI%": 1.9090891915890307
            }
        },
        {
            "date": "2024-08-18",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 9769.770416,
                "spent": 9519.27,
                "result": 250.50041599999895,
                "ROI%": 2.6315086766107054
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 3319.4799999999996,
                "spent": 3238.949999999999,
                "result": 80.53000000000065,
                "ROI%": 2.486299572392303
            }
        },
        {
            "date": "2024-08-25",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 6339.012912000001,
                "spent": 6710.189999999999,
                "result": -371.17708799999764,
                "ROI%": -5.531543637363434
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 3921.2400000000007,
                "spent": 4228.240000000001,
                "result": -307,
                "ROI%": -7.260704217357572
            }
        },
        {
            "date": "2024-09-01",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 3881.8733740000002,
                "spent": 4126.650000000001,
                "result": -244.7766260000003,
                "ROI%": -5.931606169653358
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 3556.94,
                "spent": 3325.1099999999997,
                "result": 231.83000000000038,
                "ROI%": 6.972100171122175
            }
        },
        {
            "date": "2024-09-08",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 4607.3667270000005,
                "spent": 6009.469999999999,
                "result": -1402.1032729999988,
                "ROI%": -23.331562899889658
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 4347.909999999999,
                "spent": 4200.920000000002,
                "result": 146.98999999999705,
                "ROI%": 3.498995458137677
            }
        },
    ],

    Pablo: [
        {
            "date": "2024-04-14",
            "tonic": {
                "revenue": 28.18,
                "spent": 75.13,
                "result": -46.949999999999996,
                "ROI%": -62.4916810861174
            },
            "sedo": {
                "revenue": 177.91623700000002,
                "spent": 198.1,
                "result": -20.18376299999997,
                "ROI%": -10.188673902069645
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 73.17,
                "spent": 108.27,
                "result": -35.099999999999994,
                "ROI%": -32.418952618453865
            }
        },
        {
            "date": "2024-04-21",
            "tonic": {
                "revenue": 275.27,
                "spent": 491.78999999999996,
                "result": -216.51999999999998,
                "ROI%": -44.026922060228955
            },
            "sedo": {
                "revenue": 696.093208,
                "spent": 782.5400000000001,
                "result": -86.44679200000007,
                "ROI%": -11.046948654381893
            },
            "system1": {
                "revenue": 150.79,
                "spent": 338.84,
                "result": -188.04999999999998,
                "ROI%": -55.49817022783614
            },
            "crossroads": {
                "revenue": 706.1700000000001,
                "spent": 803.1999999999998,
                "result": -97.02999999999975,
                "ROI%": -12.080428286852563
            }
        },
        {
            "date": "2024-04-28",
            "tonic": {
                "revenue": 233.59999999999997,
                "spent": 374.80999999999995,
                "result": -141.20999999999998,
                "ROI%": -37.67508871161388
            },
            "sedo": {
                "revenue": 1239.8800050000002,
                "spent": 1466.4599999999998,
                "result": -226.5799949999996,
                "ROI%": -15.450813182766632
            },
            "system1": {
                "revenue": 36.99,
                "spent": 43.32,
                "result": -6.329999999999998,
                "ROI%": -14.612188365650969
            },
            "crossroads": {
                "revenue": 1140.06,
                "spent": 1427.31,
                "result": -287.25,
                "ROI%": -20.125270613952118
            }
        },
        {
            "date": "2024-05-05",
            "tonic": {
                "revenue": 100.46999999999997,
                "spent": 115.05999999999999,
                "result": -14.590000000000018,
                "ROI%": -12.680340691812987
            },
            "sedo": {
                "revenue": 1573.517835,
                "spent": 1696.6100000000001,
                "result": -123.09216500000002,
                "ROI%": -7.255183277241084
            },
            "system1": {
                "revenue": 40.17,
                "spent": 41.14,
                "result": -0.9699999999999989,
                "ROI%": -2.3578026251823037
            },
            "crossroads": {
                "revenue": 1476.6899999999998,
                "spent": 1743.1399999999996,
                "result": -266.4499999999998,
                "ROI%": -15.28563397088013
            }
        },
        {
            "date": "2024-05-12",
            "tonic": {
                "revenue": 68.86,
                "spent": 48.03000000000001,
                "result": 20.82999999999999,
                "ROI%": 43.36872787840931
            },
            "sedo": {
                "revenue": 1096.1595169999998,
                "spent": 925.2500000000001,
                "result": 170.9095169999997,
                "ROI%": 18.471712185895672
            },
            "system1": {
                "revenue": 58.57,
                "spent": 48.94,
                "result": 9.630000000000003,
                "ROI%": 19.677155700858194
            },
            "crossroads": {
                "revenue": 865.46,
                "spent": 753.53,
                "result": 111.93000000000006,
                "ROI%": 14.854086764959607
            }
        },
        {
            "date": "2024-05-19",
            "tonic": {
                "revenue": 73.48999999999998,
                "spent": 57.06,
                "result": 16.42999999999998,
                "ROI%": 28.794251664914093
            },
            "sedo": {
                "revenue": 833.275974,
                "spent": 744.5200000000001,
                "result": 88.75597399999992,
                "ROI%": 11.921234352334388
            },
            "system1": {
                "revenue": 63.26,
                "spent": 54.73,
                "result": 8.530000000000001,
                "ROI%": 15.58560204640964
            },
            "crossroads": {
                "revenue": 749.2600000000001,
                "spent": 651.84,
                "result": 97.42000000000007,
                "ROI%": 14.945385370643116
            }
        },
        {
            "date": "2024-05-26",
            "tonic": {
                "revenue": 103.37999999999997,
                "spent": 82.55000000000001,
                "result": 20.829999999999956,
                "ROI%": 25.233192004845485
            },
            "sedo": {
                "revenue": 815.8335730000001,
                "spent": 861.2800000000001,
                "result": -45.44642699999997,
                "ROI%": -5.27661468976407
            },
            "system1": {
                "revenue": 62.24,
                "spent": 62.87,
                "result": -0.6299999999999955,
                "ROI%": -1.0020677588674975
            },
            "crossroads": {
                "revenue": 632.57,
                "spent": 617.35,
                "result": 15.220000000000027,
                "ROI%": 2.4653762047460948
            }
        },
        {
            "date": "2024-06-02",
            "tonic": {
                "revenue": 124.62000000000003,
                "spent": 102.75000000000001,
                "result": 21.87000000000002,
                "ROI%": 21.284671532846723
            },
            "sedo": {
                "revenue": 1149.769298,
                "spent": 1187.13,
                "result": -37.360702000000174,
                "ROI%": -3.1471449630621873
            },
            "system1": {
                "revenue": 18.25,
                "spent": 32.19,
                "result": -13.939999999999998,
                "ROI%": -43.305374339857096
            },
            "crossroads": {
                "revenue": 639.9100000000001,
                "spent": 565.46,
                "result": 74.45000000000005,
                "ROI%": 13.166271707989964
            }
        },
        {
            "date": "2024-06-09",
            "tonic": {
                "revenue": 188.20999999999995,
                "spent": 151.52999999999997,
                "result": 36.67999999999998,
                "ROI%": 24.20642777007853
            },
            "sedo": {
                "revenue": 1574.039919,
                "spent": 1977.2499999999998,
                "result": -403.2100809999997,
                "ROI%": -20.392468377797435
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 701.31,
                "spent": 571.6999999999999,
                "result": 129.61,
                "ROI%": 22.670981283890157
            }
        },
        {
            "date": "2024-06-16",
            "tonic": {
                "revenue": 267.9399999999999,
                "spent": 185.92,
                "result": 82.0199999999999,
                "ROI%": 44.11574870912216
            },
            "sedo": {
                "revenue": 1459.7832380000002,
                "spent": 1467.04,
                "result": -7.256761999999753,
                "ROI%": -0.4946533155196664
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 848.7,
                "spent": 761.61,
                "result": 87.09000000000003,
                "ROI%": 11.43498641036751
            }
        },
        {
            "date": "2024-06-23",
            "tonic": {
                "revenue": 332.71999999999986,
                "spent": 271.11,
                "result": 61.60999999999984,
                "ROI%": 22.72509313562754
            },
            "sedo": {
                "revenue": 1862.961378,
                "spent": 1903.0100000000002,
                "result": -40.04862200000025,
                "ROI%": -2.1044882580753765
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 1109.33,
                "spent": 954.3,
                "result": 155.02999999999997,
                "ROI%": 16.2454154877921
            }
        },
        {
            "date": "2024-06-30",
            "tonic": {
                "revenue": 310.6999999999998,
                "spent": 286.07,
                "result": 24.629999999999825,
                "ROI%": 8.609780822875468
            },
            "sedo": {
                "revenue": 1800.7200050000001,
                "spent": 1854.5700000000002,
                "result": -53.849995000000035,
                "ROI%": -2.903637770480494
            },
            "system1": {
                "revenue": 26.960000000000004,
                "spent": 29.6,
                "result": -2.639999999999997,
                "ROI%": -8.918918918918905
            },
            "crossroads": {
                "revenue": 1476.8100000000002,
                "spent": 1263.8700000000001,
                "result": 212.94000000000005,
                "ROI%": 16.848251798048853
            }
        },
        {
            "date": "2024-07-07",
            "tonic": {
                "revenue": 271.59999999999985,
                "spent": 264.44000000000005,
                "result": 7.159999999999798,
                "ROI%": 2.70760853123575
            },
            "sedo": {
                "revenue": 1591.251996,
                "spent": 1464.02,
                "result": 127.23199599999998,
                "ROI%": 8.69059138536359
            },
            "system1": {
                "revenue": 7.4399999999999995,
                "spent": 7.83,
                "result": -0.39000000000000057,
                "ROI%": -4.980842911877403
            },
            "crossroads": {
                "revenue": 1456.4299999999998,
                "spent": 1254.12,
                "result": 202.30999999999995,
                "ROI%": 16.13163014703536
            }
        },
        {
            "date": "2024-07-14",
            "tonic": {
                "revenue": 86.53999999999998,
                "spent": 67.77000000000001,
                "result": 18.769999999999968,
                "ROI%": 27.6966209237125
            },
            "sedo": {
                "revenue": 1940.171115,
                "spent": 1615.11,
                "result": 325.0611150000002,
                "ROI%": 20.126252391477983
            },
            "system1": {
                "revenue": 0,
                "spent": 4.77,
                "result": -4.77,
                "ROI%": -100
            },
            "crossroads": {
                "revenue": 1324.3500000000001,
                "spent": 1174.88,
                "result": 149.47000000000003,
                "ROI%": 12.722150347269512
            }
        },
        {
            "date": "2024-07-21",
            "tonic": {
                "revenue": 89.21999999999994,
                "spent": 80,
                "result": 9.219999999999942,
                "ROI%": 11.524999999999919
            },
            "sedo": {
                "revenue": 1845.8618714000002,
                "spent": 1613.74,
                "result": 232.12187140000015,
                "ROI%": 14.384093559061562
            },
            "system1": {
                "revenue": 21.639999999999997,
                "spent": 23.21,
                "result": -1.5700000000000038,
                "ROI%": -6.764325721671705
            },
            "crossroads": {
                "revenue": 983.98,
                "spent": 874.3700000000001,
                "result": 109.6099999999999,
                "ROI%": 12.53588297860173
            }
        },
        {
            "date": "2024-07-28",
            "tonic": {
                "revenue": 30.130000000000006,
                "spent": 39.93,
                "result": -9.799999999999994,
                "ROI%": -24.54295016278486
            },
            "sedo": {
                "revenue": 1578.5610168000003,
                "spent": 1198.85,
                "result": 379.7110168000004,
                "ROI%": 31.672937965550354
            },
            "system1": {
                "revenue": 8.66,
                "spent": 11.28,
                "result": -2.619999999999999,
                "ROI%": -23.226950354609922
            },
            "crossroads": {
                "revenue": 608.26,
                "spent": 580.69,
                "result": 27.569999999999936,
                "ROI%": 4.747800030997595
            }
        },
        {
            "date": "2024-08-04",
            "tonic": {
                "revenue": 1.62,
                "spent": 1.81,
                "result": -0.18999999999999995,
                "ROI%": -10.497237569060768
            },
            "sedo": {
                "revenue": 1884.3631149999999,
                "spent": 1592.8,
                "result": 291.5631149999999,
                "ROI%": 18.305067491210437
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 559.1200000000001,
                "spent": 562.85,
                "result": -3.7299999999999045,
                "ROI%": -0.6626987652127436
            }
        },
        {
            "date": "2024-08-11",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 2211.1385760000003,
                "spent": 2001.75,
                "result": 209.38857600000028,
                "ROI%": 10.46027605844888
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 480.44,
                "spent": 551.2500000000001,
                "result": -70.81000000000012,
                "ROI%": -12.845351473922918
            }
        },
        {
            "date": "2024-08-18",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 2651.083456,
                "spent": 2505.17,
                "result": 145.91345599999977,
                "ROI%": 5.824493188087021
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 396.01,
                "spent": 359.75,
                "result": 36.25999999999999,
                "ROI%": 10.079221681723416
            }
        },
        {
            "date": "2024-08-25",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1464.2366330000002,
                "spent": 1550.7100000000003,
                "result": -86.47336700000005,
                "ROI%": -5.576372564825149
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 255.04,
                "spent": 290.44,
                "result": -35.400000000000006,
                "ROI%": -12.188403801129322
            }
        },
        {
            "date": "2024-09-01",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1973.1194090000004,
                "spent": 1931.5600000000002,
                "result": 41.55940900000019,
                "ROI%": 2.1515981382923766
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 292.67999999999995,
                "spent": 261.26,
                "result": 31.41999999999996,
                "ROI%": 12.026333920232712
            }
        },
        {
            "date": "2024-09-08",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1738.7576130000004,
                "spent": 1887.4099999999999,
                "result": -148.6523869999994,
                "ROI%": -7.875998696626563
            },
            "system1": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 518.66,
                "spent": 507.19000000000005,
                "result": 11.469999999999914,
                "ROI%": 2.2614799187681056
            }
        },
    ],

    DMVF: [
        {
            "date": "2024-04-07",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 129.44083900000004,
                "spent": 203.62,
                "result": -74.17916099999997,
                "ROI%": -36.43019398880265
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            }
        },
        {
            "date": "2024-04-14",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 437.727364,
                "spent": 398.49,
                "result": 39.237364000000014,
                "ROI%": 9.846511581219097
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            }
        },
        {
            "date": "2024-04-21",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 393.1898790000001,
                "spent": 388.53999999999996,
                "result": 4.649879000000112,
                "ROI%": 1.1967568332733158
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            }
        },
        {
            "date": "2024-04-28",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 539.248708,
                "spent": 693.9300000000001,
                "result": -154.6812920000001,
                "ROI%": -22.290618938509667
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 201.87999999999997,
                "spent": 273.46,
                "result": -71.58000000000001,
                "ROI%": -26.175674687340024
            }
        },
        {
            "date": "2024-05-05",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1306.1968610000004,
                "spent": 1697.0900000000001,
                "result": -390.8931389999998,
                "ROI%": -23.033141377298772
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 623.41,
                "spent": 916.2399999999999,
                "result": -292.8299999999999,
                "ROI%": -31.959966820920283
            }
        },
        {
            "date": "2024-05-12",
            "tonic": {
                "revenue": 87.86999999999999,
                "spent": 235.20000000000005,
                "result": -147.33000000000004,
                "ROI%": -62.640306122449
            },
            "sedo": {
                "revenue": 1773.739986,
                "spent": 3055.41,
                "result": -1281.6700139999998,
                "ROI%": -41.947562323877975
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 293.6,
                "spent": 479.1300000000001,
                "result": -185.5300000000001,
                "ROI%": -38.72226744307391
            }
        },
        {
            "date": "2024-05-19",
            "tonic": {
                "revenue": 270.84000000000003,
                "spent": 673.96,
                "result": -403.12,
                "ROI%": -59.813638791619674
            },
            "sedo": {
                "revenue": 2012.1312500000004,
                "spent": 2780.9200000000005,
                "result": -768.7887500000002,
                "ROI%": -27.64512283704673
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 302.04,
                "spent": 520.1999999999999,
                "result": -218.1599999999999,
                "ROI%": -41.93771626297577
            }
        },
        {
            "date": "2024-05-26",
            "tonic": {
                "revenue": 0,
                "spent": 0,
                "result": 0,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1330.5131340000003,
                "spent": 1827.2600000000004,
                "result": -496.7468660000002,
                "ROI%": -27.185341221282144
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "revenue": 209.97000000000006,
                "spent": 428.76,
                "result": -218.78999999999994,
                "ROI%": -51.02854743912677
            }
        },
        {
            "date": "2024-06-02",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 865.078875,
                "spent": 1217.78,
                "result": -352.70112499999993,
                "ROI%": -28.962630770746767
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-06-09",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 819.1687810000001,
                "spent": 865.9999999999999,
                "result": -46.831218999999805,
                "ROI%": -5.407762009237849
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-06-16",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 888.644309,
                "spent": 928.3000000000001,
                "result": -39.65569100000005,
                "ROI%": -4.271861574921909
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-06-23",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 711.164158,
                "spent": 751.6700000000001,
                "result": -40.50584200000003,
                "ROI%": -5.388779916718778
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-06-30",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 495.6107,
                "spent": 602.7699999999999,
                "result": -107.15929999999986,
                "ROI%": -17.77780911458764
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-07-07",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 662.649997,
                "spent": 1233.9400000000005,
                "result": -571.2900030000005,
                "ROI%": -46.29803742483429
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-07-14",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 2262.7335070000004,
                "spent": 2866.2000000000003,
                "result": -603.4664929999999,
                "ROI%": -21.05458422301304
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-07-21",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 3877.5840798000004,
                "spent": 4558.320000000001,
                "result": -680.7359202000002,
                "ROI%": -14.933921273627126
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-07-28",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 3278.6951177999995,
                "spent": 3913.0099999999998,
                "result": -634.3148822000003,
                "ROI%": -16.210407900823164
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-08-04",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 4280.115535000001,
                "spent": 4386.610000000001,
                "result": -106.49446499999976,
                "ROI%": -2.4277167334228467
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-08-11",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 6164.900412,
                "spent": 5649.780000000001,
                "result": 515.1204119999993,
                "ROI%": 9.11753045251318
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-08-18",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 5318.301856,
                "spent": 4671.779999999999,
                "result": 646.5218560000012,
                "ROI%": 13.83887631694989
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-08-25",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 3008.223109,
                "spent": 3106.0899999999997,
                "result": -97.86689099999967,
                "ROI%": -3.1508066733417106
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-09-01",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 2580.695508,
                "spent": 2739.9500000000003,
                "result": -159.25449200000048,
                "ROI%": -5.8123138013467575
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-09-08",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 943.3815040000002,
                "spent": 1284.5199999999995,
                "result": -341.13849599999935,
                "ROI%": -26.557663251642595
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
    ],

    JPQ: [
        {
            "date": "2024-05-12",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 19.213321,
                "spent": 33.54,
                "result": -14.326678999999999,
                "ROI%": -42.71520274299344
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-05-19",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 392.838751,
                "spent": 514.71,
                "result": -121.87124900000003,
                "ROI%": -23.677653241631212
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-05-26",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 484.752849,
                "spent": 531.67,
                "result": -46.91715099999993,
                "ROI%": -8.824487181898533
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-06-02",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 464.36646199999996,
                "spent": 585.39,
                "result": -121.02353800000003,
                "ROI%": -20.6740016057671
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-06-09",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 824.2244910000002,
                "spent": 872.24,
                "result": -48.01550899999984,
                "ROI%": -5.504850614509749
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-06-16",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1515.2089170000002,
                "spent": 1382.2099999999998,
                "result": 132.99891700000035,
                "ROI%": 9.622193226789012
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-06-23",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1630.9268490000002,
                "spent": 1539.91,
                "result": 91.0168490000001,
                "ROI%": 5.910530420609006
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-06-30",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1808.860766,
                "spent": 1599.7499999999998,
                "result": 209.11076600000024,
                "ROI%": 13.071465291451801
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-07-07",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1569.99503,
                "spent": 1408.9900000000005,
                "result": 161.00502999999958,
                "ROI%": 11.42698173869221
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-07-14",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1494.5721390000003,
                "spent": 1363.1800000000003,
                "result": 131.39213900000004,
                "ROI%": 9.638649261286103
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-07-21",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 801.5152757000001,
                "spent": 755.78,
                "result": 45.7352757000001,
                "ROI%": 6.051400632459192
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-07-28",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1194.4010095999997,
                "spent": 902.4100000000001,
                "result": 291.99100959999964,
                "ROI%": 32.35680118793005
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-08-04",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1717.189161,
                "spent": 1424.8899999999999,
                "result": 292.29916100000014,
                "ROI%": 20.513805346377634
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-08-11",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 2331.9631170000007,
                "spent": 1805.3900000000003,
                "result": 526.5731170000004,
                "ROI%": 29.166723921147252
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-08-18",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 2411.8482080000003,
                "spent": 2085.89,
                "result": 325.95820800000047,
                "ROI%": 15.626816754478924
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-08-25",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1247.889705,
                "spent": 1091.01,
                "result": 156.87970500000006,
                "ROI%": 14.379309538867657
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-09-01",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1337.876475,
                "spent": 1217.4099999999999,
                "result": 120.46647500000017,
                "ROI%": 9.89530848276261
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-09-08",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1327.327459,
                "spent": 1327.54,
                "result": -0.21254099999987375,
                "ROI%": -0.016010139054178918
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
    ],

    Universeleo: [
        {
            "date": "2024-07-07",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 866.253019,
                "spent": 1084.1699999999998,
                "result": -217.91698099999985,
                "ROI%": -20.099890330852165
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-07-14",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 665.160183,
                "spent": 700.1199999999999,
                "result": -34.95981699999993,
                "ROI%": -4.993403559389808
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-07-21",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 660.6158215000002,
                "spent": 598.0300000000001,
                "result": 62.585821500000065,
                "ROI%": 10.465331421500611
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-07-28",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 659.260817,
                "spent": 677.17,
                "result": -17.909182999999985,
                "ROI%": -2.6447100432683057
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-08-04",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 888.508268,
                "spent": 777.3599999999999,
                "result": 111.14826800000014,
                "ROI%": 14.298171760831547
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-08-11",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1913.124091,
                "spent": 1405.95,
                "result": 507.17409099999986,
                "ROI%": 36.07340879832142
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-08-18",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 2709.944272,
                "spent": 2195.2499999999995,
                "result": 514.6942720000006,
                "ROI%": 23.44581582963219
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-08-25",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 2545.996345,
                "spent": 2242.7299999999996,
                "result": 303.26634500000046,
                "ROI%": 13.522195939769865
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-09-01",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1664.330492,
                "spent": 1452.9399999999998,
                "result": 211.39049200000022,
                "ROI%": 14.54915495478135
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-09-08",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1085.397459,
                "spent": 1220.5,
                "result": -135.10254099999997,
                "ROI%": -11.069442113887751
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
    ],

    Universefede: [
        {
            "date": "2024-07-07",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1054.5538020000001,
                "spent": 1040.3700000000001,
                "result": 14.183802000000014,
                "ROI%": 1.3633420802214635
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-07-14",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1375.880949,
                "spent": 1271.24,
                "result": 104.64094900000009,
                "ROI%": 8.231407838016436
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-07-21",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1528.6976177000001,
                "spent": 1411.9499999999998,
                "result": 116.74761770000032,
                "ROI%": 8.268537674846876
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-07-28",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1557.4937186000002,
                "spent": 1297.16,
                "result": 260.3337186000001,
                "ROI%": 20.06951483240311
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-08-04",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1454.863984,
                "spent": 1402.7200000000003,
                "result": 52.14398399999982,
                "ROI%": 3.717348009581367
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-08-11",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 849.410641,
                "spent": 804.2999999999998,
                "result": 45.110641000000214,
                "ROI%": 5.608683451448493
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-08-18",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 552.7982880000002,
                "spent": 551.0600000000001,
                "result": 1.738288000000125,
                "ROI%": 0.3154444162160486
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-08-25",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 311.10332500000004,
                "spent": 254.09999999999997,
                "result": 57.003325000000075,
                "ROI%": 22.433421881149187
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-09-01",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 712.556291,
                "spent": 810.6299999999999,
                "result": -98.0737089999999,
                "ROI%": -12.098455398887275
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-09-08",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 550.776711,
                "spent": 751.5699999999999,
                "result": -200.79328899999996,
                "ROI%": -26.716511968279733
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
    ],

    Universecoco: [
        {
            "date": "2024-07-07",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 2079.839085,
                "spent": 1880.85,
                "result": 198.98908500000016,
                "ROI%": 10.579742403700454
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-07-14",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 3464.4466260000004,
                "spent": 2982.7400000000007,
                "result": 481.7066259999997,
                "ROI%": 16.149802731716466
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-07-21",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 3105.3516138000005,
                "spent": 2784.8599999999997,
                "result": 320.4916138000008,
                "ROI%": 11.508356391344666
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-07-28",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 2762.9659998000006,
                "spent": 2551.71,
                "result": 211.2559998000006,
                "ROI%": 8.278997213633232
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-08-04",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 2011.0311430000004,
                "spent": 1897.5499999999995,
                "result": 113.48114300000088,
                "ROI%": 5.980403309530757
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-08-11",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1391.2261920000003,
                "spent": 1265.9599999999998,
                "result": 125.2661920000005,
                "ROI%": 9.89495655470951
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-08-18",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 2104.65248,
                "spent": 1987.15,
                "result": 117.5024800000001,
                "ROI%": 5.913115768814636
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-08-25",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1785.9218339999998,
                "spent": 1778.4300000000003,
                "result": 7.491833999999471,
                "ROI%": 0.42126111232938523
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-09-01",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1585.0500460000003,
                "spent": 1444.0600000000002,
                "result": 140.99004600000012,
                "ROI%": 9.76344791767656
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-09-08",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1589.638681,
                "spent": 1621.3600000000001,
                "result": -31.72131900000022,
                "ROI%": -1.9564636478018538
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
    ],

    Primo: [
        {
            "date": "2024-07-21",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 43.9773253,
                "spent": 82.41,
                "result": -38.4326747,
                "ROI%": -46.635935808761076
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-07-28",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 558.5512661999999,
                "spent": 685.48,
                "result": -126.92873380000015,
                "ROI%": -18.516766907860205
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-08-04",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1265.884035,
                "spent": 1236.23,
                "result": 29.65403500000002,
                "ROI%": 2.3987474013735355
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-08-11",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1161.070612,
                "spent": 1151.5299999999997,
                "result": 9.540612000000237,
                "ROI%": 0.8285161480812597
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-08-18",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 508.75484800000004,
                "spent": 578.48,
                "result": -69.72515199999998,
                "ROI%": -12.053165537270083
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-08-25",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 1307.180935,
                "spent": 1506.7,
                "result": -199.51906499999996,
                "ROI%": -13.242122851264348
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-09-01",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 849.0358010000001,
                "spent": 995.2299999999998,
                "result": -146.19419899999968,
                "ROI%": -14.68948876139181
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
        {
            "date": "2024-09-08",
            "tonic": {
                "result": null,
                "ROI%": null
            },
            "sedo": {
                "revenue": 824.444385,
                "spent": 1030.9099999999999,
                "result": -206.46561499999984,
                "ROI%": -20.027511130942553
            },
            "system1": {
                "result": null,
                "ROI%": null
            },
            "crossroads": {
                "result": null,
                "ROI%": null
            }
        },
    ],
}