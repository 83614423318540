import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import moment from 'moment'
import { host } from './host'
import { Context } from '../AppContext'

const useFetchBestPerforming = ({ loginStatus, bestPerform, setBestPerform }) => {
    const appContext = useContext(Context)

    useEffect(() => {
        const controller = new AbortController();
        const token = Cookies.get('token')
        const url = `${host}/api/fb/top`;
        const url_fetch_users = `${host}/api/admin`;
        
        if ((loginStatus === false) || ((Cookies.get('role') !== 'admin') && Cookies.get('userPermissions') && !JSON.parse(Cookies.get('userPermissions'))?.find(f => f === 'best-performing'))) {
            return
        }

        const getData = async () => {
            setBestPerform(p => { return { ...p, loading: true } })
            let usernames_to_send = []
            // just in case fetch users api failed
            const local_usernames = [ "Southpow", "UniverseFenix", "LFC", "Moneyball", "Pablo", "DMVF", "JPQ", "Universeleo", "Universefede", "Universecoco", "Primo" ]
            // just in case fetch users api failed

            try {
                const response = await axios.post(url_fetch_users, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                        "Authorization": "Bearer "+token,
                        "Content-Type": "application/json",
                    },
                })
                if (response.data && Array.isArray(response.data) && (response.data.length > 0)) {
                    usernames_to_send = response.data.filter(f => f.role === 'manager').map(e => e.username)
                } else {
                    usernames_to_send = local_usernames
                }
            } catch (error) {
                usernames_to_send = local_usernames
            }

            await axios.post(url, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                    "Authorization": "Bearer "+token,
                    "Content-Type": "application/json",
                },
                lastDate: moment().utc().add(8, 'hours').subtract(2, 'day').format('YYYY-MM-DD'),
                account_id: 'all',
                username: usernames_to_send
            },
            {
                signal: controller.signal
            })

            .then((response) => {
                console.log(response);
                const obj = response.data

                if (obj.result) {
                    let temp = obj.result.map((e, i) => { return { ...e, uniqueID: i, keyword: (e?.keyword && Array.isArray(e?.keyword)) ? e.keyword.join(', ') : '' } })
                    setBestPerform(p => { return { ...p, data: temp, loading: false } })
                } else {
                    setBestPerform(p => { return { ...p, loading: false } })
                }
            })
            
            .catch((error) => {
                console.log(error);
                setBestPerform(p => { return { ...p, loading: false } })
                if (error?.response?.data?.message === 'Invalid') {
                    appContext.forceLogout()
                }
            }) 
        }

        getData()
        
        return () => {
            controller.abort()
        }
    }, [loginStatus, bestPerform.refresh])

    return
}

export default useFetchBestPerforming