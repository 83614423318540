import React, { useState, useEffect, useContext } from "react";
import './fbFanPages.css'
import Cookies from "js-cookie";
import axios from "axios";
import moment from "moment";
import { FaPencil, FaTrashCan } from "react-icons/fa6";
import { MdOutlineCancel } from 'react-icons/md'

import { host } from "../../hooks/host";
import { Context } from "../../AppContext";
import useFetchFFPLinksSetUp from "../../hooks/useFetchFFPLinksSetUp";

const FFPLinksSetUp = () => {
    const appContext = useContext(Context)

    const [global, setGlobal] = useState({ display_form: '', expand_row: null })
    const [loading, setLoading] = useState({ fan_page: false })

    const [settings, setSettings] = useState({ loading: false, data: [], display: false, refresh: false, topic: '', link: '', facebook_page_id: '', facebook_page_name: '', link_type: 'Post', client: '', client_id: '' })
    const [settingsUpdate, setSettingsUpdate] = useState({ display: false, filter_link: '', topic: '', link: '', facebook_page_id: '', facebook_page_name: '', link_type: 'Post', client: '', client_id: '' })

    const fetch = useFetchFFPLinksSetUp({ setLoading })
    const [data] = fetch

    // fetch settings
    useEffect(() => {
        const fetchSettings = async () => {
            setSettings(p => { return { ...p, loading: true } })
    
            const response = await settingsCRUDapi({
                action: "read",
                rules: {},
                filter: {},
                update: {}
            })
    
            if (response.result && Array.isArray(response.result)) {
                let temp_settings = response.result.reverse().map((e, i) => { return { ...e, id: 101+i } })
                setSettings(p => { return { ...p, data: temp_settings, loading: false } })
            } else {
                setSettings(p => { return { ...p, data: [], loading: false } })
            }
        }

        fetchSettings()
    }, [settings.refresh])

    return (
        <div className="widget-container" style={{ gridAutoFlow: 'row', gridAutoRows: 'min-content 1fr', position: 'relative' }}>
            {/* loader */}
            {(loading.fan_page || settings.loading) ? (
                <div className="ripple-logo-container" style={{ zIndex: 500 }}>
                    <div className="loader" style={{ width: '200px', height: '200px', border: '5px solid #0000FF', borderTop: '5px solid #E6E6FF' }}></div>
                </div>
            ) : (<></>)}
            
            {/* settings update form */}
            {settingsUpdate.display && (
                <div className="widget-all-form-container">
                    <div className='widget-all-form'>
                        <div className='widget-all-form-nav'>
                            <p style={{ fontSize: '24px', fontWeight: 600, color: '#36a2eb' }}>Update Setting</p>
                            <MdOutlineCancel size={24} color='#FF0000' style={{ cursor: settings.loading ? 'not-allowed' : 'pointer' }} onClick={() => !settings.loading && setSettingsUpdate(p => { return { ...p, display: false, filter_link: '', topic: '', link: '', facebook_page_id: '', facebook_page_name: '', client: '', client_id: '' } })}/>
                        </div>

                        <label className='widget-all-form-label'>Topic:</label>
                        <input className='widget2-input' type="text" autoComplete="off" placeholder='TOPIC' value={settingsUpdate.topic} onChange={e => setSettingsUpdate(p => { return { ...p, topic: e.target.value } })} style={{ maxWidth: '100%' }} />
                        
                        <label className='widget-all-form-label'>Link:</label>
                        <input className='widget2-input' type="text" autoComplete="off" placeholder='www.topic.com' value={settingsUpdate.link} onChange={e => setSettingsUpdate(p => { return { ...p, link: e.target.value.replace(/\s/g, '') } })} style={{ maxWidth: '100%' }}/>
                        
                        <label className='widget-all-form-label'>Link Type:</label>
                        <select className='widget2-select' style={{ maxWidth: '100%' }} value={settingsUpdate.link_type} onChange={e => setSettingsUpdate(p => { return { ...p, link_type: e.target.value } })}>
                            <option value="Post">Post</option>
                            <option value="Fixed">Fixed</option>
                        </select>

                        <label className='widget-all-form-label'>Fan Page:</label>
                        <select className='widget2-select' style={{ maxWidth: '100%' }}
                            value={settingsUpdate.facebook_page_id}
                            onChange={e => setSettingsUpdate(p => {
                                return {
                                    ...p,
                                    facebook_page_id: e.target.value,
                                    facebook_page_name: data.fb_fan_pages_post.find(f => f.id === e.target.value) ? data.fb_fan_pages_post.find(f => f.id === e.target.value).name : ""
                                }
                            })}
                        >
                            <option value="">Choose</option>
                            {data.fb_fan_pages_post.map((e, i) => (
                                <option key={i} value={e.id}>{e.name}</option>
                            ))}
                        </select>

                        <label className='widget-all-form-label'>Client:</label>
                        <select className='widget2-select' style={{ maxWidth: '100%' }} value={settingsUpdate.client} onChange={e => setSettingsUpdate(p => { return { ...p, client: e.target.value } })}>
                            <option value="">Choose</option>
                            {['Sedo','Tonic','System1','Crossroads','Ads','Adcombo','Other'].map((e, i) => (
                                <option key={i} value={e}>{e}</option>
                            ))}
                        </select>

                        <label className='widget-all-form-label'>Client ID:</label>
                        <input className='widget2-input' type="text" autoComplete="off" placeholder='CLIENTID' value={settingsUpdate.client_id} onChange={e => setSettingsUpdate(p => { return { ...p, client_id: e.target.value } })} style={{ maxWidth: '100%' }}/>

                        <button className='widget2-button' style={{ maxWidth: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: settings.loading ? 'not-allowed' : 'pointer' }} disabled={settings.loading} onClick={() => !settings.loading && handleUpdateSettings({ settingsUpdate, setSettingsUpdate, setSettings, appContext })}>
                            {settings.loading ? (
                                <div className="loader" style={{ borderWidth: '3px', height: '20px', width: '20px' }}/>
                            ) : 'Update'}
                        </button>
                    </div>
                </div>
            )}

            {/* top nav */}
            <div className="fbfp-top">
                <div className="fbfp-top-left">
                    <div className="fbfp-field">
                        <label>Topic</label>
                        <input type="text" placeholder="TOPIC" autoComplete="off" value={settings.topic} onChange={e => setSettings(p => { return { ...p, topic: e.target.value } })} />
                    </div>
                    <div className="fbfp-field">
                        <label>Link</label>
                        <input type="text" placeholder="www.topic.com" autoComplete="off" value={settings.link} onChange={e => setSettings(p => { return { ...p, link: e.target.value.replace(/\s/g, '') } })} />
                    </div>
                    <div className="fbfp-field">
                        <label>Link Type</label>
                        <select value={settings.link_type} onChange={e => setSettings(p => { return { ...p, link_type: e.target.value } })}>
                            <option value="Post">Post</option>
                            <option value="Fixed">Fixed</option>
                        </select>
                    </div>
                    <div className="fbfp-field">
                        <label>FB Fan Page</label>
                        <select value={settings.facebook_page_id}
                            onChange={e => setSettings(p => {
                                return {
                                    ...p,
                                    facebook_page_id: e.target.value,
                                    facebook_page_name: data.fb_fan_pages_post.find(f => f.id === e.target.value) ? data.fb_fan_pages_post.find(f => f.id === e.target.value).name : ""
                                }
                            })}
                        >
                            <option value="">Choose</option>
                            {data.fb_fan_pages_post.map((e, i) => (
                                <option key={i} value={e.id}>{e.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="fbfp-field">
                        <label>Client</label>
                        <select value={settings.client} onChange={e => setSettings(p => { return { ...p, client: e.target.value } })}>
                            <option value="">Choose</option>
                            {['Sedo','Tonic','System1','Crossroads','Ads','Adcombo','Other'].map((e, i) => (
                                <option key={i} value={e}>{e}</option>
                            ))}
                        </select>
                    </div>
                    <div className="fbfp-field">
                        <label>Client ID</label>
                        <input type="text" placeholder="CLIENTID" autoComplete="off" value={settings.client_id} onChange={e => setSettings(p => { return { ...p, client_id: e.target.value } })} />
                    </div>
                    <button style={{
                            background: (settings.loading || !settings.topic.trim() || !settings.link.trim() || !settings.facebook_page_id.trim()) ? '#AAA' : '#4caf50',
                            cursor: (settings.loading || !settings.topic.trim() || !settings.link.trim() || !settings.facebook_page_id.trim()) ? 'not-allowed' : 'pointer',
                        }}
                        onClick={() => !settings.loading && handleSubmitSettings({ settings, setSettings, appContext })}
                    >
                        {settings.loading ? (<div className="loader" style={{ borderWidth: '3px', height: '20px', width: '20px' }} />) : 'SUBMIT'}
                    </button>
                </div>
            </div>

            {/* settings page */}
            <div id="widget-table-container-scroll" className="widget-table-container">
                <table className="widget-table">
                    <thead>
                        <tr>
                            <th style={{ fontSize: '14px', fontWeight: '500', textAlign: 'left' }}>Action</th>
                            <th style={{ minWidth: '200px', maxWidth: '200px', fontSize: '14px', fontWeight: '500', textAlign: 'left' }}>Topic</th>
                            <th style={{ minWidth: '200px', maxWidth: '200px', fontSize: '14px', fontWeight: '500', textAlign: 'left' }}>Link</th>
                            <th style={{ minWidth: '100px', maxWidth: '100px', fontSize: '14px', fontWeight: '500', textAlign: 'left' }}>Link Type</th>
                            <th style={{ minWidth: '200px', maxWidth: '200px', fontSize: '14px', fontWeight: '500', textAlign: 'left' }}>Fan Page</th>
                            <th style={{ minWidth: '100px', maxWidth: '100px', fontSize: '14px', fontWeight: '500', textAlign: 'left' }}>Client</th>
                            <th style={{ minWidth: '200px', maxWidth: '200px', fontSize: '14px', fontWeight: '500', textAlign: 'left' }}>Client ID</th>
                        </tr>
                    </thead>

                    <tbody>
                        {settings.data.map((fd, i) => {
                            return (
                                <tr key={i} style={{ background: (fd.link_type === 'Fixed') ? '#E8F1FB' : '#C9E7CB', cursor: 'zoom-in' }}
                                    onMouseEnter={e => e.currentTarget.style.background = '#FFF'}
                                    onMouseLeave={e => e.currentTarget.style.background = (fd.link_type === 'Fixed') ? '#E8F1FB' : '#C9E7CB'}
                                >
                                    <td>
                                        <div className="widget-table-cell">
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', gap: '10px' }}>
                                                    <div style={{ height: '30px', width: '30px', background: '#4CAF50', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', cursor: 'pointer' }}
                                                        onMouseEnter={e => e.currentTarget.style.opacity = '50%'}
                                                        onMouseLeave={e => e.currentTarget.style.opacity = '100%'}
                                                        onClick={() => !settings.loading && setSettingsUpdate(p => {
                                                            return {
                                                                ...p,
                                                                display: true,
                                                                filter_link: fd.link ? fd.link : '',
                                                                topic: fd.topic ? fd.topic : '',
                                                                link: fd.link ? fd.link : '',
                                                                facebook_page_id: fd.facebook_page_id ? fd.facebook_page_id : '',
                                                                facebook_page_name: fd.facebook_page_name ? fd.facebook_page_name : '',
                                                                link_type: fd.link_type ? fd.link_type : '',
                                                                client: fd.client ? fd.client : '',
                                                                client_id: fd.client_id ? fd.client_id : ''
                                                            }
                                                        })}
                                                    >
                                                        <FaPencil size={16} color='#FFF'/>
                                                    </div>
                                                    <div style={{ height: '30px', width: '30px', background: '#EF5350', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', cursor: 'pointer' }}
                                                        onMouseEnter={e => e.currentTarget.style.opacity = '50%'}
                                                        onMouseLeave={e => e.currentTarget.style.opacity = '100%'}
                                                        onClick={() => !settings.loading && handleDeleteSettings({ setSettings, appContext, link: fd?.link })}
                                                    >
                                                        <FaTrashCan size={16} color='#FFF'/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ minWidth: '200px', maxWidth: '200px' }} onClick={() => setGlobal(p => { return { ...p, expand_row: (p.expand_row === fd.id) ? null : fd.id } })}>
                                        <div className="widget-table-cell">
                                            <p style={{ fontSize: '14px' }}>{fd?.topic}</p>
                                        </div>
                                    </td>
                                    <td style={{ minWidth: '200px', maxWidth: '200px' }} onClick={() => setGlobal(p => { return { ...p, expand_row: (p.expand_row === fd.id) ? null : fd.id } })}>
                                        <div className="widget-table-cell">
                                            {(global.expand_row === fd.id) ? (
                                                <p style={{ fontSize: '14px' }}>{fd?.link}</p>
                                            ) : (
                                                <p style={{ fontSize: '14px' }}>{(fd?.link?.length > 40) ? fd?.link?.substring(0, 40)+'...' : fd?.link}</p>
                                            )}
                                        </div>
                                    </td>
                                    <td style={{ minWidth: '100px', maxWidth: '100px' }} onClick={() => setGlobal(p => { return { ...p, expand_row: (p.expand_row === fd.id) ? null : fd.id } })}>
                                        <div className="widget-table-cell">
                                            <p style={{ fontSize: '14px' }}>{fd?.link_type}</p>
                                        </div>
                                    </td>
                                    <td style={{ minWidth: '200px', maxWidth: '200px' }} onClick={() => setGlobal(p => { return { ...p, expand_row: (p.expand_row === fd.id) ? null : fd.id } })}>
                                        <div className="widget-table-cell">
                                            <p style={{ fontSize: '14px' }}>{fd?.facebook_page_name}</p>
                                        </div>
                                    </td>
                                    <td style={{ minWidth: '100px', maxWidth: '100px' }} onClick={() => setGlobal(p => { return { ...p, expand_row: (p.expand_row === fd.id) ? null : fd.id } })}>
                                        <div className="widget-table-cell">
                                            <p style={{ fontSize: '14px' }}>{fd?.client}</p>
                                        </div>
                                    </td>
                                    <td style={{ minWidth: '200px', maxWidth: '200px' }} onClick={() => setGlobal(p => { return { ...p, expand_row: (p.expand_row === fd.id) ? null : fd.id } })}>
                                        <div className="widget-table-cell">
                                            <p style={{ fontSize: '14px' }}>{fd?.client_id}</p>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default FFPLinksSetUp





// settings CRUD api
const settingsCRUDapi = async (params) => {
    let result
    const token = Cookies.get("token");
    const url = `${host}/api/facebook_page/topic`;

    try {
        const response = await axios.post(url, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
            },
            action: params.action,
            rules: params.rules,
            filter: params.filter,
            update: params.update
        })

        result = response.data
    } catch (error) {
        console.log(error)
        window.alert(error)
    }

    return result
}

// handle SUBMIT click for settings
const handleSubmitSettings = async ({ settings, setSettings, appContext }) => {
    if (settings.link_type === 'Post') {
        if (!settings.topic.trim() || !settings.link.trim() || !settings.facebook_page_id.trim() || !settings.client.trim() || !settings.client_id.trim()) {
            appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "All fields required.", content: "" }], confirm_function: () => {}, cancel_function: () => {} }})
            return
        }
    } else {
        if (!settings.topic.trim() || !settings.link.trim() || !settings.facebook_page_id.trim()) {
            appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Topic, Link and FB Fan Page required.", content: "" }], confirm_function: () => {}, cancel_function: () => {} }})
            return
        }
    }

    setSettings(p => { return { ...p, loading: true } })
    
    const validate_link = await settingsCRUDapi({
            action: "read_one",
            rules: { link: settings.link.trim() },
            filter: {},
            update: {}
        })

    if (validate_link.result && (validate_link.result.link === settings.link.trim())) {
        appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Same link found", content: "The input link is already present in the database. Please use another one." }], confirm_function: () => {}, cancel_function: () => {} }})
        setSettings(p => { return { ...p, loading: false } })
        return
    }

    const create_setting = await settingsCRUDapi({
        action: "create_one",
        rules: {},
        filter: {},
        update: { topic: settings.topic.trim(), link: settings.link.trim(), link_type: settings.link_type, facebook_page_id: settings.facebook_page_id, facebook_page_name: settings.facebook_page_name, client: settings.client, client_id: settings.client_id }
    })

    if (create_setting.result && (create_setting?.result?.acknowledged === true)) {
        setSettings(p => { return { ...p, loading: false, refresh: !p.refresh, topic: '', link: '', facebook_page_id: '', facebook_page_name: '', client: '', client_id: '' } })
    } else {
        setSettings(p => { return { ...p, loading: false } })
        appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Failed", content: "Please try again." }], confirm_function: () => {}, cancel_function: () => {} }})
    }
}

// handle update click for settings
const handleUpdateSettings = async ({ settingsUpdate, setSettingsUpdate, setSettings, appContext }) => {
    if (settingsUpdate.link_type === 'Post') {
        if (!settingsUpdate.topic.trim() || !settingsUpdate.link.trim() || !settingsUpdate.facebook_page_id.trim() || !settingsUpdate.client.trim() || !settingsUpdate.client_id.trim()) {
            appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "All fields required.", content: "" }], confirm_function: () => {}, cancel_function: () => {} }})
            return
        }
    } else {
        if (!settingsUpdate.topic.trim() || !settingsUpdate.link.trim() || !settingsUpdate.facebook_page_id.trim()) {
            appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Topic, Link and FB Fan Page required.", content: "" }], confirm_function: () => {}, cancel_function: () => {} }})
            return
        }
    }

    setSettings(p => { return { ...p, loading: true } })

    if (settingsUpdate.link.trim() !== settingsUpdate.filter_link.trim()) {
        const validate_link = await settingsCRUDapi({
                action: "read_one",
                rules: { link: settingsUpdate.link.trim() },
                filter: {},
                update: {}
            })
    
        if (validate_link.result && (validate_link.result.link === settingsUpdate.link.trim())) {
            appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Same link found", content: "The inputed link is already present in the database. Please use another one." }], confirm_function: () => {}, cancel_function: () => {} }})
            setSettings(p => { return { ...p, loading: false } })
            return
        }
    }

    const update_setting = await settingsCRUDapi({
        action: "update_one",
        rules: {},
        filter: { link: settingsUpdate.filter_link.trim() },
        update: { topic: settingsUpdate.topic.trim(), link: settingsUpdate.link.trim(), link_type: settingsUpdate.link_type, facebook_page_id: settingsUpdate.facebook_page_id, facebook_page_name: settingsUpdate.facebook_page_name, client: settingsUpdate.client, client_id: settingsUpdate.client_id }
    })

    if (update_setting.result && (update_setting?.result?.acknowledged === true)) {
        setSettings(p => { return { ...p, loading: false, refresh: !p.refresh } })
        setSettingsUpdate(p => { return { ...p, display: false, filter_link: '', topic: '', link: '', facebook_page_id: '', facebook_page_name: '', client: '', client_id: '' } })
    } else {
        setSettings(p => { return { ...p, loading: false } })
        appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Failed", content: "Please try again." }], confirm_function: () => {}, cancel_function: () => {} }})
    }
}

// handle delete click for settings
const handleDeleteSettings = async ({ setSettings, appContext, link }) => {
    setSettings(p => { return { ...p, loading: true } })
    
    const delete_setting = await settingsCRUDapi({
        action: "delete",
        rules: {},
        filter: { link },
        update: {}
    })

    if (delete_setting.result && (delete_setting?.result?.acknowledged === true)) {
        setSettings(p => { return { ...p, loading: false, refresh: !p.refresh } })
    } else {
        setSettings(p => { return { ...p, loading: false } })
        appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Failed", content: "Please try again." }], confirm_function: () => {}, cancel_function: () => {} }})
    }
}