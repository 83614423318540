import { useState, useEffect, useContext } from "react";
import axios from "axios";
import moment from "moment";
import Cookies from "js-cookie";
import { host } from './host'
import { Context } from "../AppContext";

const useFetchEmailoctopusEmails = ({ traffic, trafficToSend, clientName, minusDate }) => {
  const appContext = useContext(Context)

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({ noGroup: [], mainGroup: [], subGroup: {}, campaignNames: [], latestDate: '', dates: [], totalByDate: [] });
  const [totalValue, setTotalValue] = useState({});

  useEffect(() => {
    const controller = new AbortController();
    const token = Cookies.get("token");
    const url = `${host}/api/data/emailPage`;

    const getData = async () => {
      setLoading(true);
      let user = Cookies.get("userArray");

      await axios.post(url, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type, x-access-token",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
            "Authorization": "Bearer "+token,
            "Content-Type": "application/json",
          },
          data: {
            traffic: traffic,
            trafficName: trafficToSend,
            username: user,
            lastDate: moment().utc().add(8, 'hours').subtract(1, 'day').format('YYYY-MM-DD'),
            account_id: 'all',
          },
        },
        {
          signal: controller.signal
        }
      )

      .then(async (response) => {
        const obj = response.data;
        console.log(response);

        if (obj.result) {
          const tableData = obj.result.filter(f => f.date !== 'Total')
          const total = obj.result.find(f => f.date === 'Total')
          
          const groupDates = Array.from(new Set(tableData.map(e => e.date)))

          // main group based on dates to display in data grid
          let mainGroup = []
          groupDates.map((e, i) => {
            const organizeCampaign = tableData.filter(f => (f.date === e)).map(m => {return { campaign_ID: m.campaign_ID, revenue: m.revenue?.all ? m.revenue : {all: 0}, result: m.result ? m.result : 0 }})
            const groupCampaignID = Array.from(new Set(organizeCampaign.map(e => e.campaign_ID)))

            const diffCampaign = groupCampaignID.map(m => {
              let temp = organizeCampaign.filter(f => f.campaign_ID === m)
              return {
                campaign_ID: m,
                revenue: temp[0].revenue,
                result: (temp.length === 1) ? temp[0].result : temp.map(item => item.result).reduce((a, b) => a+b, 0),
              }
            })

            mainGroup.push({ id: i, date: e, campaign_ID: diffCampaign })
          })

          // sub group to display when item clicked
          let subGroup = {}
          groupDates.forEach(e => {
            const groupCampaignID = Array.from(new Set(tableData.filter(f => f.date === e).map(e => e.campaign_ID)))
            let res = {}
            groupCampaignID.forEach(element => {
              res = { ...res, [element]: tableData.filter(f => f.campaign_ID === element && f.date === e && (f.date === f.complete_date)) }
            });
            subGroup = { ...subGroup, [e]: res }
          });

          setData({
            noGroup: tableData,
            mainGroup: mainGroup.map(e => { return { ...e, campaign_ID: e.campaign_ID.filter(f => f.revenue?.all > 0) } }),
            subGroup: subGroup,
            campaignNames: Array.from(new Set(tableData.map(e => e.campaign_ID))),
            latestDate: groupDates.sort((a, b) => new Date(b) - new Date(a))?.[0],
            dates: groupDates.sort((a, b) => new Date(a) - new Date(b)),
            totalByDate: obj.totalByDate ? obj.totalByDate : []
          })

          const temp_total_revenue = {all: 0, tonic: 0, sedo: 0, crossroads: 0 }

          mainGroup.map(e => {
            e.campaign_ID.map(m => {
              temp_total_revenue.all = temp_total_revenue.all + ( m?.revenue?.all ? m?.revenue?.all : 0 )
              temp_total_revenue.tonic = temp_total_revenue.tonic + ( m?.revenue?.tonic ? m?.revenue?.tonic : 0 )
              temp_total_revenue.sedo = temp_total_revenue.sedo + ( m?.revenue?.sedo ? m?.revenue?.sedo : 0 )
              temp_total_revenue.crossroads = temp_total_revenue.crossroads + ( m?.revenue?.crossroads ? m?.revenue?.crossroads : 0 )
            })
          })

          const filter_for_total = tableData.filter(f => (f?.complete_date === f?.date) && (f?.revenue?.all > 0))

          setTotalValue({
            ...total,
            revenue: temp_total_revenue,
            recipientsTotal: (filter_for_total.length > 0) ? filter_for_total?.map(e => e?.recipients ? e?.recipients : 0).reduce((a, b) => a + b, 0) : 0,
            total_clicksTotal: (filter_for_total.length > 0) ? filter_for_total?.map(e => e?.clicked?.total ? e?.clicked?.total : 0).reduce((a, b) => a + b, 0) : 0
          })
        } else {
          setData({ noGroup: [], mainGroup: [], subGroup: {}, campaignNames: [], latestDate: '', dates: [], totalByDate: [] })
          setTotalValue({})
        }
      })
      .catch((error) => {
        console.log(error);
        setData({ noGroup: [], mainGroup: [], subGroup: {}, campaignNames: [], latestDate: '', dates: [], totalByDate: [] })
        setTotalValue({})
        if (error?.response?.data?.message === 'Invalid') {
          appContext.forceLogout()
        }
      })
      .finally(() => {
        setLoading(false)
      })
    };

    getData();

    return () => {
      controller.abort()
    };
  }, []);

  return [loading, data, totalValue];
};

export default useFetchEmailoctopusEmails;