import { useState, useEffect, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { host } from './host'
import { Context } from "../AppContext";

const useFetchFFPLinksSetUp = ({ setLoading }) => {
    const appContext = useContext(Context)
    
    const [data, setData] = useState({ fb_fan_pages_post: [] });

    // get fb account on username change
    useEffect(() => {
        const token = Cookies.get("token");
        const url_fan_page_accounts = `${host}/api/facebook_page/accounts`;

        // API to fetch fan page accounts id and token
        const fetchFbFanPageAccounts = () => {
            setLoading(p => { return { ...p, fan_page: true } })
            axios.post(url_fan_page_accounts, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                        "Authorization": "Bearer "+token,
                        "Content-Type": "application/json",
                    },
                },
            )

            .then((response) => {
                const obj = response.data
                const fan_page_result = obj.result ? obj.result : []
                fan_page_result.sort((a, b) => {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                });
                setData(p => { return { ...p, fb_fan_pages_post: fan_page_result } })
            })
            
            .catch((error) => {
                console.log(error);
                setData(p => { return { ...p, fb_fan_pages_post: [] } })
                if (error?.response?.data?.message === 'Invalid') {
                    appContext.forceLogout()
                }
            })

            .finally(() => setLoading(p => { return { ...p, fan_page: false } }))
        };

        fetchFbFanPageAccounts()
    }, [])

    return [data]
};

export default useFetchFFPLinksSetUp