import React, { useContext } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import useFetchFacebookAccounts from '../../hooks/useFetchFacebookAccounts';
import { Context } from '../../AppContext';

const FBaccounts = () => {
    const appContext = useContext(Context)
    const fetch = useFetchFacebookAccounts()
    const [loading, data] = fetch

    const admin_columns = [
        { field: 'index', headerName: 'ID', hide: true },
        { field: 'account_name', headerName: 'FB Account', minWidth: 300, flex: 1, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left' },
        { field: 'ROI_7', headerName: 'ROI(7)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', type: 'number', headerAlign: 'left', align: 'left',
                renderCell: (params) => {
                    const roi = params.row["ROI_7"]
                    return(
                        <div style={{color: (roi > 0) ? '#4caf50' : '#ef5350'}}>{(roi === "-") ? '-' : (roi*1).toFixed(0)+'%'}</div>
                    )
                }},
        { field: 'ROI_30', headerName: 'ROI(30)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', type: 'number', headerAlign: 'left', align: 'left',
                renderCell: (params) => {
                    const roi = params.row["ROI_30"]
                    return(
                        <div style={{color: (roi > 0) ? '#4caf50' : '#ef5350'}}>{(roi === "-") ? '-' : (roi*1).toFixed(0)+'%'}</div>
                    )
                }},
        { field: 'result_7', headerName: 'Result(7)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', type: 'number', headerAlign: 'left', align: 'left',
                renderCell: (params) => {
                    const result = params.row["result_7"]
                    return(
                        <div style={{color: (result > 0) ? '#4caf50' : '#ef5350'}}>$ {(result*1).toFixed(1)}</div>
                    )
                }},
        { field: 'result_30', headerName: 'Result(30)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', type: 'number', headerAlign: 'left', align: 'left',
                renderCell: (params) => {
                    const result = params.row["result_30"]
                    return(
                        <div style={{color: (result > 0) ? '#4caf50' : '#ef5350'}}>$ {(result*1).toFixed(1)}</div>
                    )
                }},
        { field: 'activeCampaign_today', headerName: 'Active Campaigns (Today)', minWidth: 200, flex: 1, headerClassName: 'datagridHeader', type: 'number', headerAlign: 'left', align: 'left' }
    ]

    const manager_columns = [
        { field: 'index', headerName: 'ID', hide: true },
        { field: 'account_name', headerName: 'FB Account', minWidth: 300, flex: 1, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left' },
        { field: 'ROI_7', headerName: 'ROI(7)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', type: 'number', headerAlign: 'left', align: 'left',
                renderCell: (params) => {
                    const roi = params.row["ROI_7"]
                    return(
                        <div style={{color: (roi > 0) ? '#4caf50' : '#ef5350'}}>{(roi === "-") ? '-' : (roi*1).toFixed(0)+'%'}</div>
                    )
                }},
        { field: 'ROI_30', headerName: 'ROI(30)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', type: 'number', headerAlign: 'left', align: 'left',
                renderCell: (params) => {
                    const roi = params.row["ROI_30"]
                    return(
                        <div style={{color: (roi > 0) ? '#4caf50' : '#ef5350'}}>{(roi === "-") ? '-' : (roi*1).toFixed(0)+'%'}</div>
                    )
                }},
        { field: 'activeCampaign_today', headerName: 'Active Campaigns (Today)', minWidth: 200, flex: 1, headerClassName: 'datagridHeader', type: 'number', headerAlign: 'left', align: 'left' }
    ]

    return (
        <div className="widget-container">
            <p style={{ fontWeight: 700 }}>Facebook Accounts</p>

            <div className="widget-datagrid-container">
                <DataGrid
                    components={{ Toolbar: GridToolbar }}
                    sx={{
                        boxShadow: 3,
                        '& .datagridHeader': {
                            color: '#1976d2',
                        }
                    }}
                    loading={loading}
                    // getRowHeight={() => 'auto'}
                    getRowId={(row) => row.index}
                    rows={data}
                    columns={(appContext.role === 'admin') ? admin_columns : manager_columns}
                    disableSelectionOnClick={true}
                />
            </div>
        </div>
    )
}

export default FBaccounts