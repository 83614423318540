import React, { useState, useEffect, useContext } from 'react'
import './createCampaign.css'
import axios from 'axios'
import Cookies from 'js-cookie'

import { host } from '../../hooks/host'
import { tonicCountries } from '../../local/TonicCountries'
import { tonicOffers } from '../../local/TonicOffers'
import { Context } from '../../AppContext'

const CreateCampaign = () => {
    const [selectedNav, setSelectedNav] = useState('tonic')
    return (
        <div className="create-campaign-container">
            <div className='create-campaign'>
                <div className='create-campaign-nav'>
                    <div style={{ background: (selectedNav === 'tonic') ? '#1c1c1e' : '#DDD', color: (selectedNav === 'tonic') ? '#FFF' : '#000' }} onClick={() => setSelectedNav('tonic')}>Tonic</div>
                    {/* <div style={{ background: (selectedNav === 'system1') ? '#1c1c1e' : '#DDD', color: (selectedNav === 'system1') ? '#FFF' : '#000' }} onClick={() => setSelectedNav('system1')}>System1</div>
                    <div style={{ background: (selectedNav === 'sedo') ? '#1c1c1e' : '#DDD', color: (selectedNav === 'sedo') ? '#FFF' : '#000' }} onClick={() => setSelectedNav('sedo')}>Sedo</div>
                    <div style={{ background: (selectedNav === 'crossroads') ? '#1c1c1e' : '#DDD', color: (selectedNav === 'crossroads') ? '#FFF' : '#000' }} onClick={() => setSelectedNav('crossroads')}>Crossroads</div> */}
                </div>

                <TonicForm/>
            </div>
        </div>
    )
}

export default CreateCampaign

const TonicForm = () => {
    const appContext = useContext(Context)

    const [details, setDetails] = useState({ country: '', countryCode: '', url: '', offer: '', offer_id: '', campaign_name: '', username: '', imprint: false })
    const [error, setError] = useState({ top: '', country: '', offer: '', url: '', campaign_name: '', username: '' })
    const [change, setChange] = useState({ country: false, offer: false })
    const [filterInput, setFilterInput] = useState({ country: '', offer: '' })
    const [filterList, setFilterList] = useState({ country: [], offer: [] })
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (filterInput.country === '') {
            setFilterList(p => { return { ...p, country: tonicCountries } })
        } else {
            setFilterList(p => { return { ...p, country: tonicCountries.filter(f => f.code.toLowerCase().includes(filterInput.country.toLowerCase()) || f.name.toLowerCase().includes(filterInput.country.toLowerCase()) )}})
        }
    }, [filterInput.country])
    
    useEffect(() => {
        if (filterInput.offer === '') {
            setFilterList(p => { return { ...p, offer: tonicOffers } })
        } else {
            setFilterList(p => { return { ...p, offer: tonicOffers.filter(f => f.name.toLowerCase().includes(filterInput.offer.toLowerCase()) )}})
        }
    }, [filterInput.offer])

    // validate the form and call createCampaignAPI()
    const handleCreate = () => {
        const regexWhiteSpace = /^\S+$/

        if (details.country === '' || details.countryCode === '') {
            setError(p => { return { ...p, country: 'You need to select a country by clicking on it!' } })
        }
        if (details.offer === '' || details.offer_id === '') {
            setError(p => { return { ...p, offer: 'You need to select an offer by clicking on it!' } })
        }
        if (details.campaign_name.trim() === '') {
            setError(p => { return { ...p, campaign_name: 'Campaign Name is mandatory!' } })
        }
        if (details.username.trim() === '') {
            setError(p => { return { ...p, username: 'User Account is mandatory!' } })
        }
        if (details.url !== '' && !regexWhiteSpace.test(details.url)) {
            setError(p => { return { ...p, url: 'No spaces in-between please!' } })
            return
        }
        if (details.country !== '' && details.countryCode !== '' && details.offer !== '' && details.offer_id !== '' && details.campaign_name.trim() !== '' && details.username.trim() !== '') {
            setError({ top: '', country: '', offer: '', url: '', campaign_name: '', username: '' })
            createCampaignAPI()
        }
    }

    // API to create a campaign
    const createCampaignAPI = () => {
        setLoading(true)
        const token = Cookies.get('token')
        const url = `${host}/api/new`;
    
        axios.post(url, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
            },
            data: { ...details, url: (details.url === '') ? '' : 'https://www.'+details.url.trim()+'.bond' }
        })
        .then((response) => {
            const obj = response.data
            
            if (obj.success) {
                window.alert(obj.data.data)
            } else {
                window.alert(obj.message)
                setError(p => { return { ...p, top: obj.message } })
            }
        })
        .catch((error) => {
            console.log(error);
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        })
        .finally(() => {
            setLoading(false)
        }) 
    }

    return (
        <div className='create-campaign-form-container'>
            <div className='create-campaign-form'>
                <p style={{ fontWeight: '700' }}>NEW CAMPAIGN</p>
                <p style={{ color: '#555', marginBottom: '15px' }}>"Notice: Feature currently available for Tonic only"</p>
                {(error.top !== '') && <p style={{ color: '#FF0000', fontSize: '12px', marginBottom: '20px' }}>{error.top}</p>}

                <p style={{ color: '#555', fontWeight: '600' }}>Select Account:</p>
                {(error.username !== '') && <p style={{ color: '#FF0000', fontSize: '12px' }}>{error.username}</p>}
                <select value={details.username} className='create-campaign-form-input' style={{ borderColor: (error.username === '') ? '#AAA' : '#FF7F7F' }}
                    onChange={e => {
                        setDetails(p => {return{...p, username: e.target.value}});
                        setError(p => { return { ...p, username: '' } });
                    }}>
                    <option value="" disabled>Click to select</option>
                    <option value="tonic@tharsis.media">tonic@tharsis.media</option>
                    <option value="diego@3xm.asia">diego@3xm.asia</option>
                </select>
                
                <p style={{ color: '#555', fontWeight: '600' }}>Select Country:</p>
                {(error.country !== '') && <p style={{ color: '#FF0000', fontSize: '12px' }}>{error.country}</p>}
                {change.country ? (
                    <>
                        <input type="text" placeholder='Country Code / Name' value={filterInput.country} onChange={e => setFilterInput(p => {return{...p, country: e.target.value.trimStart()}})} className='create-campaign-form-input' style={{ borderColor: (error.country === '') ? '#AAA' : '#FF7F7F' }} />
                        <div className='create-campaign-form-filtered-list'>
                            {filterList.country.map((e, i) => (
                                <div key={i} onClick={() => {
                                    setDetails(p => {return{...p, country: e.name, countryCode: e.code}});
                                    setChange(p => {return{...p, country: false}});
                                    setError(p => { return { ...p, country: '' } });
                                }}>{e.name}</div>
                            ))}
                        </div>
                    </>
                ) : (
                    <div className='create-campaign-form-input' style={{ borderColor: (error.country === '') ? '#AAA' : '#FF7F7F' }} onClick={() => setChange(p => {return{...p, country: true}})}>{(details.country === '') ? 'Click to select' : details.country}</div>
                )}

                <p style={{ color: '#555', fontWeight: '600' }}>Select Offer:</p>
                {(error.offer !== '') && <p style={{ color: '#FF0000', fontSize: '12px' }}>{error.offer}</p>}
                {change.offer ? (
                    <>
                        <input type="text" placeholder='Offer Name' value={filterInput.offer} onChange={e => setFilterInput(p => {return{...p, offer: e.target.value.trimStart()}})} className='create-campaign-form-input' style={{ borderColor: (error.offer === '') ? '#AAA' : '#FF7F7F' }} />
                        <div className='create-campaign-form-filtered-list'>
                            {filterList.offer.map((e, i) => (
                                <div key={i} onClick={() => {
                                    setDetails(p => {return{...p, offer: e.name, offer_id: e.id}});
                                    setChange(p => {return{...p, offer: false}});
                                    setError(p => { return { ...p, offer: '' } });
                                }}>{e.name}</div>
                            ))}
                        </div>
                    </>
                ) : (
                    <div className='create-campaign-form-input' style={{ borderColor: (error.offer === '') ? '#AAA' : '#FF7F7F' }} onClick={() => setChange(p => {return{...p, offer: true}})}>{(details.offer === '') ? 'Click to select' : details.offer}</div>
                )}

                <p style={{ color: '#555', fontWeight: '600' }}>Target Domain {'(optional)'}:</p>
                {(error.url !== '') && <p style={{ color: '#FF0000', fontSize: '12px' }}>{error.url}</p>}
                <div className='create-campaign-form-domain' style={{ borderColor: (error.url === '') ? '#AAA' : '#FF7F7F' }}>
                    <div>https://www.</div>
                    <input type="text" placeholder='Suggested domain' value={details.url} onChange={e => { setDetails(p => {return{...p, url: e.target.value.trim()}}); setError(p => { return { ...p, url: '' } }); }} />
                    <div>.bond</div>
                </div>

                <p style={{ color: '#555', fontWeight: '600' }}>Campaign Name:</p>
                {(error.campaign_name !== '') && <p style={{ color: '#FF0000', fontSize: '12px' }}>{error.campaign_name}</p>}
                <input type="text" placeholder='Suggested domain' value={details.campaign_name} onChange={e => { setDetails(p => {return{...p, campaign_name: e.target.value.trimStart()}}); setError(p => { return { ...p, campaign_name: '' } }); }} className='create-campaign-form-input' style={{ borderColor: (error.campaign_name === '') ? '#AAA' : '#FF7F7F' }} />
                
                <div style={{ height: 'fit-content', display: 'flex', alignItems: 'center', gap: '10px', margin: '15px 0px' }}>
                    <input type="checkbox" placeholder='Suggested domain' value={details.imprint}  onChange={e => setDetails(p => {return{...p, imprint: e.target.checked }})} style={{ height: '20px', width: '20px', cursor: 'pointer' }} />
                    <p style={{ color: '#555', fontWeight: '600' }}>Imprint</p>
                </div>

                <div onClick={() => (loading === false) && handleCreate()} className='create-campaign-form-button' style={{ pointerEvents: loading ? 'none' : 'all', cursor: loading ? 'not-allowed' : 'pointer' }}>
                    {loading ? <div className='loader'/> : 'Create New Campaign'}
                </div>
            </div>
        </div>
    )
}