import React from "react";
import Traffic from '../reusable/traffic/Traffic'
import crossroadsLogo from '../../assets/crossroadsLogo.svg'

const CrossroadsFacebook = () => {
    return(
        <Traffic trafficName='crossroads_facebook' trafficToSend='Facebook' clientName='Crossroads' logo={crossroadsLogo} minusDate={2} />
    )
}

export default CrossroadsFacebook