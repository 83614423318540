import React, { useState, useEffect } from "react";
import '../dashboard/home.css'
import companyLogo from '../../assets/companyLogo.png'
import companyLogoRed from '../../assets/companyLogoRed.png'
import useFetchGrandTotal from "../../hooks/useFetchGrandTotal";

import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Bar } from 'react-chartjs-2';
import moment from "moment/moment";
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import { BiLineChart, BiLineChartDown } from 'react-icons/bi'
import { FaDollarSign, FaSync } from 'react-icons/fa'
import { HiOutlineDocumentChartBar } from 'react-icons/hi2'
import { MdCampaign } from 'react-icons/md'
import useFetchUsers from "../../hooks/useFetchUsers";
import useFetchCPM from "../../hooks/useFetchCPM";

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
 
const UserPage = () => {
  const navigate = useNavigate()

  const cookie_users = Cookies.get('userArray')

  const [fromDate, setFromDate] = useState(moment().utc().add(8, 'hours').subtract(2, 'days').format('YYYY-MM-01'))
  const [toDate, setToDate] = useState(moment().utc().add(8, 'hours').subtract(2, 'days').format('YYYY-MM-DD'))
  const [hoverDetails, setHoverDetails] = useState({ revenueO: false, spent: false, result: false, roi: false, conversion: false, activeCampaigns: false, resultPerAC: false })
  const [displayWeekly, setDisplayWeekly] = useState(false)
  const [dateArray, setDateArray] = useState([])
  const [totals, setTotals] = useState({})
  const [forChart, setForChart] = useState({})
  const [capital, setCapital] = useState({})
  const [options, setOptions] = useState({
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false
    },
    plugins : {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Result & ROI',
      },
      tooltip: {
        mode: 'index'
      },
      zoom: {
        // limits: {
        //   x: {min: 0, max: 2, minRange: 0.05}
        // },
        pan: {
          enabled: true,
          mode: 'x',
        },
        zoom: {
          wheel: {
            enabled: true,
            speed: 0.1,
            modifierKey: 'ctrl'
          },
          pinch: {
            enabled: true
          },
          mode: 'x',
          onZoomComplete({chart}) {
            chart.update('none');
          }
        }
      }
    },
    scales: {
      'result-y-axis': {
        type: 'linear',
        position: 'left',
        suggestedMin: -300,
        ticks: {
          color: '#4caf50',
        }
      },
      'roi-y-axis': {
        type: 'linear',
        position: 'right',
        suggestedMin: -20,
        ticks: {
          color: 'rgb(33, 150, 243)',
        }
      },
      'bar-y-axis': {
        type: 'linear',
        position: 'right',
        suggestedMin: 0,
        ticks: {
          color: 'rgb(239, 83, 80)',
        }
      },
    }
  })
  
  const fetch = useFetchGrandTotal({fromDate: fromDate, toDate: toDate, displayWeekly: displayWeekly})
  const [dailyData, weeklyData] = fetch

  const fetchUsers = useFetchUsers()
  const [userLoading, users] = fetchUsers
  
  const fetchCPM = useFetchCPM()
  const [cpmLoading, cpmData] = fetchCPM

  // calculate the chart data using the states only - no need to refetch unless its necessary
  useEffect(() => {
    if (displayWeekly) {
      if (weeklyData.data.length === 0) {
        return
      }
  
      const obj = weeklyData.data.filter(f => (new Date(f?.date) >= new Date(fromDate)) && (new Date(f?.date) <= new Date(toDate)) )
      const local_date = obj.map(e => e?.date)
  
      // revenueO means original revenue
      // revenue means all
      let sedo = { revenue: 0, revenueO: 0, spent: 0, result: 0, conversion: 0 }
      let system1 = { revenue: 0, revenueO: 0, spent: 0, result: 0, conversion: 0 }
      let tonic = { revenue: 0, revenueO: 0, spent: 0, result: 0, conversion: 0 }
      let crossroads = { revenue: 0, revenueO: 0, spent: 0, result: 0, conversion: 0 }
      let chart = { bodis: [], sedo: [], system1: [], tonic: [], adcombo: [], crossroads: [], roi: [], result: [], spent: [] }
  
      const filtered_data = local_date.map(e => obj.find(f => f.date === e))
  
      sedo.revenue = filtered_data.map(e => e?.sedo?.revenue ? e.sedo.revenue : 0).reduce((a, b) => a + b, 0)
      sedo.revenueO = filtered_data.map(e => e?.sedo?.revenue ? e.sedo.revenue : 0).reduce((a, b) => a + b, 0)
      sedo.spent = filtered_data.map(e => e?.sedo?.spent ? e.sedo.spent : 0).reduce((a, b) => a + b, 0)
      sedo.result = sedo.revenueO - sedo.spent
  
      system1.revenue = filtered_data.map(e => e?.system1?.revenue ? e.system1.revenue : 0).reduce((a, b) => a + b, 0)
      system1.revenueO = filtered_data.map(e => e?.system1?.revenue ? e.system1.revenue : 0).reduce((a, b) => a + b, 0)
      system1.spent = filtered_data.map(e => e?.system1?.spent ? e.system1.spent : 0).reduce((a, b) => a + b, 0)
      system1.result = system1.revenueO - system1.spent
      
      tonic.revenue = filtered_data.map(e => e?.tonic?.revenue ? e.tonic.revenue : 0).reduce((a, b) => a + b, 0)
      tonic.revenueO = filtered_data.map(e => e?.tonic?.revenue ? e.tonic.revenue : 0).reduce((a, b) => a + b, 0)
      tonic.spent = filtered_data.map(e => e?.tonic?.spent ? e.tonic.spent : 0).reduce((a, b) => a + b, 0)
      tonic.result = tonic.revenueO - tonic.spent
  
      crossroads.revenue = filtered_data.map(e => e?.crossroads?.revenue ? e.crossroads.revenue : 0).reduce((a, b) => a + b, 0)
      crossroads.revenueO = filtered_data.map(e => e?.crossroads?.revenue ? e.crossroads.revenue : 0).reduce((a, b) => a + b, 0)
      crossroads.spent = filtered_data.map(e => e?.crossroads?.spent ? e.crossroads.spent : 0).reduce((a, b) => a + b, 0)
      crossroads.result = crossroads.revenueO - crossroads.spent
  
  
      chart.tonic = local_date.map(e => {
          let finding = obj.find(f => f.date === e)
          return (finding) ? {
              result: (finding?.tonic?.revenue) - (finding?.tonic?.spent)
          } : 0
      })
      chart.sedo = local_date.map(e => {
          let finding = obj.find(f => f.date === e)
          return (finding) ? {
              result: (finding?.sedo?.revenue) - (finding?.sedo?.spent)
          } : 0
      })
      chart.system1 = local_date.map(e => {
          let finding = obj.find(f => f.date === e)
          return (finding) ? {
              result: (finding?.system1?.revenue) - (finding?.system1?.spent)
          } : 0
      })
      chart.crossroads = local_date.map(e => {
          let finding = obj.find(f => f.date === e)
          return (finding) ? {
              result: (finding?.crossroads?.revenue) - (finding?.crossroads?.spent)
          } : 0
      })
  
      chart.result = local_date.map(e => {
          let finding = obj.find(f => f.date === e)
          let temp_crossroads_r = finding?.crossroads?.revenue ? finding.crossroads.revenue : 0
          let temp_sedo_r = finding?.sedo?.revenue ? finding.sedo.revenue : 0
          let temp_system1_r = finding?.system1?.revenue ? finding.system1.revenue : 0
          let temp_tonic_r = finding?.tonic?.revenue ? finding.tonic.revenue : 0
  
          let temp_crossroads = finding?.crossroads?.spent ? finding.crossroads.spent : 0
          let temp_sedo = finding?.sedo?.spent ? finding.sedo.spent : 0
          let temp_system1 = finding?.system1?.spent ? finding.system1.spent : 0
          let temp_tonic = finding?.tonic?.spent ? finding.tonic.spent : 0
  
          let rev = ( temp_crossroads_r + temp_sedo_r + temp_system1_r + temp_tonic_r )
          let spe = ( temp_crossroads + temp_sedo + temp_system1 + temp_tonic )
          let result = rev - spe
  
          return result
      });
  
      chart.spent = local_date.map(e => {
          let finding = obj.find(f => f.date === e)
          let temp_crossroads = finding?.crossroads?.spent ? finding.crossroads.spent : 0
          let temp_sedo = finding?.sedo?.spent ? finding.sedo.spent : 0
          let temp_system1 = finding?.system1?.spent ? finding.system1.spent : 0
          let temp_tonic = finding?.tonic?.spent ? finding.tonic.spent : 0
          let result = ( temp_crossroads + temp_sedo + temp_system1 + temp_tonic )
          return result
      });
  
      chart.roi = local_date.map(e => {
          let finding = obj.find(f => f.date === e)
          let temp_crossroads_r = finding?.crossroads?.revenue ? finding.crossroads.revenue : 0
          let temp_sedo_r = finding?.sedo?.revenue ? finding.sedo.revenue : 0
          let temp_system1_r = finding?.system1?.revenue ? finding.system1.revenue : 0
          let temp_tonic_r = finding?.tonic?.revenue ? finding.tonic.revenue : 0
  
          let temp_crossroads_s = finding?.crossroads?.spent ? finding.crossroads.spent : 0
          let temp_sedo_s = finding?.sedo?.spent ? finding.sedo.spent : 0
          let temp_system1_s = finding?.system1?.spent ? finding.system1.spent : 0
          let temp_tonic_s = finding?.tonic?.spent ? finding.tonic.spent : 0
  
          let result = ( temp_crossroads_r + temp_sedo_r + temp_system1_r + temp_tonic_r ) / ( temp_crossroads_s + temp_sedo_s + temp_system1_s + temp_tonic_s )
          return (result - 1) * 100
      });
  
      setTotals({ sedo, system1, tonic, crossroads }) // removed active camps
      setForChart(chart)
      setDateArray(local_date)
    } else {
      setTotals(dailyData.totals) // removed active camps
      setForChart(dailyData.forChart)
      setDateArray(dailyData.dateArray)
      setCapital(dailyData.capital)
    }
  }, [weeklyData.data, dailyData, fromDate, toDate, displayWeekly])

  const theme = createTheme({
    palette: {
      // bodis: {
      //   main: '#176392',
      // },
      tonic: {
        main: '#4bc0c0',
      },
      system1: {
        main: '#36a2eb',
      },
      sedo: {
        main: '#9966ff',
      },
      // adcombo: {
      //   main: '#ff9f40',
      // },
      crossroads: {
        main: '#ff9f40',
        // main: '#ffcd56',
      },
      negative: {
        main: '#ef5350',
      },
    },
  });

  const chartData = {
    labels: dateArray,
    datasets: [
      {
        type: 'line',
        label: 'Result',
        data: forChart?.result && forChart.result.map(e => e),
        borderWidth: 4,
        pointRadius: 4,
        borderColor: '#4caf50',
        backgroundColor: '#4caf50',
        yAxisID: 'result-y-axis'
      },
      {
        type: 'bar',
        label: 'Spent',
        data: forChart?.spent && forChart.spent.map(e => e),
        borderWidth: 2,
        borderColor: 'rgb(239, 83, 80, 0.3)',
        backgroundColor: 'rgba(239, 83, 80, 0.1)',
        yAxisID: 'bar-y-axis'
      },
      {
        type: 'line',
        label: 'ROI',
        data: forChart?.roi && forChart.roi.map(e => e),
        borderWidth: 2,
        pointRadius: 2,
        borderColor: 'rgba(33, 150, 243, 0.5)',
        backgroundColor: 'rgba(33, 150, 243, 0.5)',
        yAxisID: 'roi-y-axis'
      },
      // {
      //   type: 'bar',
      //   label: 'Bodis',
      //   data: forChart?.bodis && forChart.bodis.map(e => e.result),
      //   borderWidth: 2,
      //   borderColor: 'rgb(23, 99, 146, 0.3)',
      //   backgroundColor: 'rgba(23, 99, 146, 0.1)',
      //   yAxisID: 'bar-y-axis'
      // },
      {
        type: 'bar',
        label: 'Tonic',
        data: forChart?.tonic && forChart.tonic.map(e => e.result),
        borderWidth: 2,
        borderColor: 'rgb(75, 192, 192, 0.3)',
        backgroundColor: 'rgba(75, 192, 192, 0.1)',
        yAxisID: 'bar-y-axis'
      },
      {
        type: 'bar',
        label: 'System1',
        data: forChart?.system1 && forChart.system1.map(e => e.result),
        borderWidth: 2,
        borderColor: 'rgb(54, 162, 235, 0.3)',
        backgroundColor: 'rgba(54, 162, 235, 0.1)',
        yAxisID: 'bar-y-axis'
      },
      {
        type: 'bar',
        label: 'Sedo',
        data: forChart?.sedo && forChart.sedo.map(e => e.result),
        borderWidth: 2,
        borderColor: 'rgb(153, 102, 255, 0.3)',
        backgroundColor: 'rgba(153, 102, 255, 0.1)',
        yAxisID: 'bar-y-axis'
      },
      // {
      //   type: 'bar',
      //   label: 'Adcombo',
      //   data: forChart?.adcombo && forChart.adcombo.map(e => e.result),
      //   borderWidth: 2,
      //   borderColor: 'rgb(255, 159, 64, 0.3)',
      //   backgroundColor: 'rgba(255, 159, 64, 0.1)',
      //   yAxisID: 'bar-y-axis'
      // },
      {
        type: 'bar',
        label: 'Crossroads',
        data: forChart?.crossroads && forChart.crossroads.map(e => e.result),
        borderWidth: 2,
        borderColor: 'rgb(255, 159, 64, 0.3)',
        backgroundColor: 'rgba(255, 159, 64, 0.1)',
        // borderColor: 'rgb(255, 205, 86, 0.3)',
        // backgroundColor: 'rgba(255, 205, 86, 0.1)',
        yAxisID: 'bar-y-axis'
      },
    ],
  };

  const mouseOverAnimation = () => {
    if (document.getElementById('backButton')) {
      document.getElementById('backButton').style.background = '#ef5350'
      document.getElementById('backButton').style.color = '#FFFFFF'
    }
  }
  
  const mouseOutAnimation = () => {
    if (document.getElementById('backButton')) {
      document.getElementById('backButton').style.background = '#FFFFFF'
      document.getElementById('backButton').style.color = '#ef5350'
    }
  }

  return (
    <div className="home">
      {/* loader */}
      {(weeklyData.loading && displayWeekly) ? (
        <div className="ripple-logo-container">
          <div className="ripple-loader"></div>
          <img src={companyLogo} alt="logo" className="ripple-logo-normal"/>
          <img src={companyLogoRed} alt="logo" className="ripple-logo-red"/>
        </div>
      ) : (<></>)}
      {(dailyData.loading) ? (
        <div className="ripple-logo-container">
          <div className="ripple-loader"></div>
          <img src={companyLogo} alt="logo" className="ripple-logo-normal"/>
          <img src={companyLogoRed} alt="logo" className="ripple-logo-red"/>
        </div>
      ) : (<></>)}
      {/* date container */}
      <div className="home-date-container">
        <div className="home-date-form" style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '10px' }}>
          <button id="backButton"
            onClick={() => {Cookies.set('userArray', ''); navigate('/');}}
            onMouseEnter={() => mouseOverAnimation()} onMouseLeave={() => mouseOutAnimation()}
            style={{padding: '2px 5px', border: '2px solid #ef5350', borderRadius: '5px', background: '#FFF', color: '#ef5350', fontWeight: '700', cursor: 'pointer', transition: '300ms', zIndex: 20}}
          >
            Back
          </button>
          <button
            onClick={() => setDisplayWeekly(!displayWeekly)}
            onMouseEnter={e => { e.currentTarget.style.background = '#36a2eb'; e.currentTarget.style.color = '#FFF' }}
            onMouseLeave={e => { e.currentTarget.style.background = '#FFF'; e.currentTarget.style.color = '#36a2eb' }}
            style={{display: 'flex', alignItems: 'center', gap: '5px', textAlign: 'left', padding: '2px 5px', border: '2px solid #36a2eb', borderRadius: '5px', background: '#FFF', color: '#36a2eb', fontWeight: '700', cursor: 'pointer', transition: '300ms', zIndex: 20}}
          >
            <FaSync size={16}/>
            {displayWeekly ? 'Display Daily Data' : 'Display Weekly Data'}
          </button>
          <p style={{ fontSize: '20px', fontWeight: '600' }}><span style={{ fontSize: '20px', fontWeight: '600', color: '#AAA' }}>Users:</span> {(cookie_users.split(',').length === users.filter(f => f.role === 'manager').length) ? 'Total' : cookie_users.split(',').join(' - ')}</p>
        </div>

        <div className="home-date-form" style={{ zIndex: 20 }}>
          <div className="home-date-wrapper">
            <label htmlFor="fromDate">From:</label>
            <input id="fromDate" type="date" className="home-date" value={fromDate} onChange={(e) => {setFromDate(e.target.value);}}/>
          </div>
          <div className="home-date-wrapper">
            <label htmlFor="toDate">To:</label>
            <input id="toDate" type="date" className="home-date" value={toDate} onChange={(e) => {setToDate(e.target.value);}}/>
          </div>
        </div>
      </div>

      {/* widgets */}
      <div className="home-widget-container">
        <div className="home-widget-container-inside">
          <div className="home-widget">
            <div className="home-widget-total" onMouseOver={() => setHoverDetails(p => {return{...p, revenueO: true}})} onMouseOut={() => setHoverDetails(p => {return{...p, revenueO: false}})}>
              {(hoverDetails.revenueO) ? (
                <>
                  {/* <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{fontWeight: 700, color: '#176392'}}>Bodis:&nbsp;</div>
                    <div>$ {Number(totals?.bodis?.revenueO.toFixed(0))?.toLocaleString()}</div>
                  </div> */}
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{fontWeight: 700, color: '#4bc0c0'}}>Tonic:&nbsp;</div>
                    <div>$ {Number(totals?.tonic?.revenueO.toFixed(0))?.toLocaleString()}</div>
                  </div>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{fontWeight: 700, color: '#36a2eb'}}>System1:&nbsp;</div>
                    <div>$ {Number(totals?.system1?.revenueO.toFixed(0))?.toLocaleString()}</div>
                  </div>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{fontWeight: 700, color: '#9966ff'}}>Sedo:&nbsp;</div>
                    <div>$ {Number(totals?.sedo?.revenueO.toFixed(0))?.toLocaleString()}</div>
                  </div>
                  {/* <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{fontWeight: 700, color: '#9966ff'}}>Adcombo:&nbsp;</div>
                    <div>$ {Number(totals?.adcombo?.revenueO.toFixed(0))?.toLocaleString()}</div>
                  </div> */}
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{fontWeight: 700, color: '#9966ff'}}>Crossroads:&nbsp;</div>
                    <div>$ {Number(totals?.crossroads?.revenueO.toFixed(0))?.toLocaleString()}</div>
                  </div>
                </>
              ) : (
                <>
                  <div className="home-widget-total-face">
                    <BiLineChart color={'#4caf50'} size={30}/>
                    Revenue
                  </div>
                  <p>$ { Number((totals?.sedo?.revenueO + totals?.system1?.revenueO + totals?.tonic?.revenueO + totals?.crossroads?.revenueO).toFixed(0))?.toLocaleString() }</p>
                  {/* <p style={{ fontSize: '14px' }}>RT$ { Number((totals?.sedo?.revenue + totals?.system1?.revenue + totals?.tonic?.revenue + totals?.crossroads?.revenue).toFixed(0))?.toLocaleString() } {'('}{((((totals?.sedo?.revenue + totals?.system1?.revenue + totals?.tonic?.revenue + totals?.crossroads?.revenue) / (totals?.sedo?.revenueO + totals?.system1?.revenueO + totals?.tonic?.revenueO + totals?.crossroads?.revenueO))-1)*100).toFixed(1)}{'%)'}</p> */}
                </>
              )}
            </div>
          </div>
          
          <div className="home-widget">
            <div className="home-widget-total" onMouseOver={() => setHoverDetails(p => {return{...p, spent: true}})} onMouseOut={() => setHoverDetails(p => {return{...p, spent: false}})}>
              {(hoverDetails.spent) ? (
                <>
                  {/* <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{fontWeight: 700, color: '#176392'}}>Bodis:&nbsp;</div>
                    <div>$ {Number(totals?.bodis?.spent.toFixed(0))?.toLocaleString()}</div>
                  </div> */}
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{fontWeight: 700, color: '#4bc0c0'}}>Tonic:&nbsp;</div>
                    <div>$ {Number(totals?.tonic?.spent.toFixed(0))?.toLocaleString()}</div>
                  </div>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{fontWeight: 700, color: '#36a2eb'}}>System1:&nbsp;</div>
                    <div>$ {Number(totals?.system1?.spent.toFixed(0))?.toLocaleString()}</div>
                  </div>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{fontWeight: 700, color: '#9966ff'}}>Sedo:&nbsp;</div>
                    <div>$ {Number(totals?.sedo?.spent.toFixed(0))?.toLocaleString()}</div>
                  </div>
                  {/* <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{fontWeight: 700, color: '#36a2eb'}}>Adcombo:&nbsp;</div>
                    <div>$ {Number(totals?.adcombo?.spent.toFixed(0))?.toLocaleString()}</div>
                  </div> */}
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{fontWeight: 700, color: '#9966ff'}}>Crossroads:&nbsp;</div>
                    <div>$ {Number(totals?.crossroads?.spent.toFixed(0))?.toLocaleString()}</div>
                  </div>
                </>
              ) : (
                <>
                  <div className="home-widget-total-face">
                  <BiLineChartDown color={'#FF0000'} size={30}/>
                    Spent
                  </div>
                  <p>$ { Number((totals?.sedo?.spent + totals?.system1?.spent + totals?.tonic?.spent + totals?.crossroads?.spent).toFixed(0))?.toLocaleString() }</p>
                </>
              )}
            </div>
          </div>
          
          <div className="home-widget">
            <div className="home-widget-total" onMouseOver={() => setHoverDetails(p => {return{...p, result: true}})} onMouseOut={() => setHoverDetails(p => {return{...p, result: false}})}>
              {(hoverDetails.result) ? (
                <>
                  {/* <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{fontWeight: 700, color: '#176392'}}>Bodis:&nbsp;</div>
                    <div style={{color: (totals?.bodis?.result > 0) ? '#4caf50' : '#ef5350'}}>$ {Number(totals?.bodis?.result.toFixed(0))?.toLocaleString()}</div>
                  </div> */}
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{fontWeight: 700, color: '#4bc0c0'}}>Tonic:&nbsp;</div>
                    <div style={{color: (totals?.tonic?.result > 0) ? '#4caf50' : '#ef5350'}}>$ {Number(totals?.tonic?.result.toFixed(0))?.toLocaleString()}</div>
                  </div>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{fontWeight: 700, color: '#36a2eb'}}>System1:&nbsp;</div>
                    <div style={{color: (totals?.system1?.result > 0) ? '#4caf50' : '#ef5350'}}>$ {Number(totals?.system1?.result.toFixed(0))?.toLocaleString()}</div>
                  </div>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{fontWeight: 700, color: '#9966ff'}}>Sedo:&nbsp;</div>
                    <div style={{color: (totals?.sedo?.result > 0) ? '#4caf50' : '#ef5350'}}>$ {Number(totals?.sedo?.result.toFixed(0))?.toLocaleString()}</div>
                  </div>
                  {/* <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{fontWeight: 700, color: '#9966ff'}}>Adcombo:&nbsp;</div>
                    <div style={{color: (totals?.adcombo?.result > 0) ? '#4caf50' : '#ef5350'}}>$ {Number(totals?.adcombo?.result.toFixed(0))?.toLocaleString()}</div>
                  </div> */}
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{fontWeight: 700, color: '#9966ff'}}>Crossroads:&nbsp;</div>
                    <div style={{color: (totals?.crossroads?.result > 0) ? '#4caf50' : '#ef5350'}}>$ {Number(totals?.crossroads?.result.toFixed(0))?.toLocaleString()}</div>
                  </div>
                </>
              ) : (
                <>
                  <div className="home-widget-total-face">
                  <HiOutlineDocumentChartBar color={'#2196f3'} size={30}/>
                    Result
                  </div>
                  <p style={{color: ((totals?.sedo?.result + totals?.system1?.result + totals?.tonic?.result + totals?.crossroads?.result) > 0) ? '#4caf50' : '#ef5350'}}>$ { Number((totals?.sedo?.result + totals?.system1?.result + totals?.tonic?.result + totals?.crossroads?.result).toFixed(0))?.toLocaleString() }</p>
                </>
              )}
            </div>
          </div>
          
          <div className="home-widget">
            <div className="home-widget-total" onMouseOver={() => setHoverDetails(p => {return{...p, roi: true}})} onMouseOut={() => setHoverDetails(p => {return{...p, roi: false}})}>
              <div className="home-widget-total-face">
                <FaDollarSign color={'#4caf50'} size={30}/>
                ROI <p>{'('}{(((totals?.sedo?.result + totals?.system1?.result + totals?.tonic?.result + totals?.crossroads?.result) / (totals?.sedo?.spent + totals?.system1?.spent + totals?.tonic?.spent + totals?.crossroads?.spent)) * 100).toFixed(1)}{'%)'}</p>
              </div>
              <div className="home-roi-container">
                {/* <div className="home-roi">
                  <div className="home-roi-absolute">
                    {((totals?.bodis?.result/totals?.bodis?.spent) < 0) ? (
                      <div className="home-roi-absolute-value" style={{boxShadow: 'inset 0 0 20px -10px#ef5350', color: '#ef5350'}}>{((totals?.bodis?.result / totals?.bodis?.spent)*100) ? ((totals?.bodis?.result / totals?.bodis?.spent)*100).toFixed(0) : '-'}</div>
                    ) : (
                      <div className="home-roi-absolute-value" style={{boxShadow: 'inset 0 0 20px -10px #176392', color: '#1c1c1e'}}>{((totals?.bodis?.result / totals?.bodis?.spent)*100) ? ((totals?.bodis?.result / totals?.bodis?.spent)*100).toFixed(0) : '-'}</div>
                    )}
                    <ThemeProvider theme={theme}>
                      <CircularProgress variant="determinate" size={40} value={(totals?.bodis?.result / totals?.bodis?.spent) ? (totals?.bodis?.result / totals?.bodis?.spent)*100 : 0} thickness={5} color={((totals?.bodis?.result/totals?.bodis?.spent) < 0) ? 'negative' : 'bodis'}/>
                    </ThemeProvider>
                  </div>
                  <p>Bodis</p>
                </div> */}
                <div className="home-roi">
                  <div className="home-roi-absolute">
                    {((totals?.tonic?.result/totals?.tonic?.spent) < 0) ? (
                      <div className="home-roi-absolute-value" style={{boxShadow: 'inset 0 0 20px -10px#ef5350', color: '#ef5350'}}>{((totals?.tonic?.result / totals?.tonic?.spent)*100) ? ((totals?.tonic?.result / totals?.tonic?.spent)*100).toFixed(0) : '-'}</div>
                    ) : (
                      <div className="home-roi-absolute-value" style={{boxShadow: 'inset 0 0 20px -10px #4bc0c0', color: '#1c1c1e'}}>{((totals?.tonic?.result / totals?.tonic?.spent)*100) ? ((totals?.tonic?.result / totals?.tonic?.spent)*100).toFixed(0) : '-'}</div>
                    )}
                    <ThemeProvider theme={theme}>
                      <CircularProgress variant="determinate" size={40} value={(totals?.tonic?.result / totals?.tonic?.spent)*100} thickness={5} color={((totals?.tonic?.result/totals?.tonic?.spent) < 0) ? 'negative' : 'tonic'}/>
                    </ThemeProvider>
                  </div>
                  <p>Tonic</p>
                </div>
                <div className="home-roi">
                  <div className="home-roi-absolute">
                    {((totals?.system1?.result/totals?.system1?.spent) < 0) ? (
                      <div className="home-roi-absolute-value" style={{boxShadow: 'inset 0 0 20px -10px#ef5350', color: '#ef5350'}}>{((totals?.system1?.result / totals?.system1?.spent)*100) ? ((totals?.system1?.result / totals?.system1?.spent)*100).toFixed(0) : '-'}</div>
                    ) : (
                      <div className="home-roi-absolute-value" style={{boxShadow: 'inset 0 0 20px -10px #36a2eb', color: '#1c1c1e'}}>{((totals?.system1?.result / totals?.system1?.spent)*100) ? ((totals?.system1?.result / totals?.system1?.spent)*100).toFixed(0) : '-'}</div>
                    )}
                    <ThemeProvider theme={theme}>
                      <CircularProgress variant="determinate" size={40} value={(totals?.system1?.result / totals?.system1?.spent)*100} thickness={5} color={((totals?.system1?.result/totals?.system1?.spent) < 0) ? 'negative' : 'system1'}/>
                    </ThemeProvider>
                  </div>
                  <p>System1</p>
                </div>
                <div className="home-roi">
                  <div className="home-roi-absolute">
                    {((totals?.sedo?.result/totals?.sedo?.spent) < 0) ? (
                      <div className="home-roi-absolute-value" style={{boxShadow: 'inset 0 0 20px -10px#ef5350', color: '#ef5350'}}>{((totals?.sedo?.result / totals?.sedo?.spent)*100) ? ((totals?.sedo?.result / totals?.sedo?.spent)*100).toFixed(0) : '-'}</div>
                    ) : (
                      <div className="home-roi-absolute-value" style={{boxShadow: 'inset 0 0 20px -10px #9966ff', color: '#1c1c1e'}}>{((totals?.sedo?.result / totals?.sedo?.spent)*100) ? ((totals?.sedo?.result / totals?.sedo?.spent)*100).toFixed(0) : '-'}</div>
                    )}
                    <ThemeProvider theme={theme}>
                      <CircularProgress variant="determinate" size={40} value={(totals?.sedo?.result / totals?.sedo?.spent)*100} thickness={5} color={((totals?.sedo?.result/totals?.sedo?.spent) < 0) ? 'negative' : 'sedo'}/>
                    </ThemeProvider>
                  </div>
                  <p>Sedo</p>
                </div>
                {/* <div className="home-roi">
                  <div className="home-roi-absolute">
                    {((totals?.adcombo?.result/totals?.adcombo?.spent) < 0) ? (
                      <div className="home-roi-absolute-value" style={{boxShadow: 'inset 0 0 20px -10px#ef5350', color: '#ef5350'}}>{((totals?.adcombo?.result / totals?.adcombo?.spent)*100) ? ((totals?.adcombo?.result / totals?.adcombo?.spent)*100).toFixed(0) : '-'}</div>
                    ) : (
                      <div className="home-roi-absolute-value" style={{boxShadow: 'inset 0 0 20px -10px #ff9f40', color: '#1c1c1e'}}>{((totals?.adcombo?.result / totals?.adcombo?.spent)*100) ? ((totals?.adcombo?.result / totals?.adcombo?.spent)*100).toFixed(0) : '-'}</div>
                    )}
                    <ThemeProvider theme={theme}>
                      <CircularProgress variant="determinate" size={40} value={(totals?.adcombo?.result / totals?.adcombo?.spent)*100} thickness={5} color={((totals?.adcombo?.result/totals?.adcombo?.spent) < 0) ? 'negative' : 'adcombo'}/>
                    </ThemeProvider>
                  </div>
                  <p>Adcombo</p>
                </div> */}
                <div className="home-roi">
                  <div className="home-roi-absolute">
                    {((totals?.crossroads?.result/totals?.crossroads?.spent) < 0) ? (
                      <div className="home-roi-absolute-value" style={{boxShadow: 'inset 0 0 20px -10px#ef5350', color: '#ef5350'}}>{((totals?.crossroads?.result / totals?.crossroads?.spent)*100) ? ((totals?.crossroads?.result / totals?.crossroads?.spent)*100).toFixed(0) : '-'}</div>
                    ) : (
                      <div className="home-roi-absolute-value" style={{boxShadow: 'inset 0 0 20px -10px #ffcd56', color: '#1c1c1e'}}>{((totals?.crossroads?.result / totals?.crossroads?.spent)*100) ? ((totals?.crossroads?.result / totals?.crossroads?.spent)*100).toFixed(0) : '-'}</div>
                    )}
                    <ThemeProvider theme={theme}>
                      <CircularProgress variant="determinate" size={40} value={(totals?.crossroads?.result / totals?.crossroads?.spent)*100} thickness={5} color={((totals?.crossroads?.result/totals?.crossroads?.spent) < 0) ? 'negative' : 'crossroads'}/>
                    </ThemeProvider>
                  </div>
                  <p>Crossroads</p>
                </div>
              </div>
            </div>
          </div>

          {(Cookies.get('userArray') === 'UniverseFenix') ? (
            <div className="home-widget">
              <div className="home-widget-total" onMouseOver={() => setHoverDetails(p => {return{...p, activeCampaigns: true}})} onMouseOut={() => setHoverDetails(p => {return{...p, activeCampaigns: false}})}>  
                <div className="home-widget-total-face">
                  <FaDollarSign color={'#4caf50'} size={30}/>
                  Capital
                </div>
                <p style={{ fontSize: '14px' }}>Total: <span style={{ fontWeight: 600, display: 'inline', whiteSpace: "nowrap" }}>{ capital?.Total_Capital ? Number(capital?.Total_Capital).toFixed(0) : '-' }</span></p>
                <p style={{ fontSize: '14px' }}>UF: <span style={{ fontWeight: 600, display: 'inline', whiteSpace: "nowrap" }}>{ capital?.UF_Capital ? Number(capital?.UF_Capital).toFixed(0) : '-' }</span></p>
                <p style={{ fontSize: '14px' }}>SP: <span style={{ fontWeight: 600, display: 'inline', whiteSpace: "nowrap" }}>{ capital?.SP_Capital ? Number(capital?.SP_Capital).toFixed(0) : '-' }</span></p>
              </div>
            </div>
          ) : (<></>)}

          <div className="home-widget" style={{ height: '140px', padding: '0px', overflow: 'hidden' }}>
            <div className="home-widget-total" onMouseOver={() => setHoverDetails(p => {return{...p, activeCampaigns: true}})} onMouseOut={() => setHoverDetails(p => {return{...p, activeCampaigns: false}})} style={{ height: '100%', width: '100%', padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', overflowY: 'scroll', gap: '0px' }}>
              <div className="home-widget-total-face">
                <MdCampaign color={'#4caf50'} size={30}/>
                CPM
              </div>
              {cpmLoading ? (
                <div className="loader"/>
              ) : (
                cpmData?.map((e, i) => (
                  <div key={i} style={{ width: '100%', borderBottom: '1px solid #EEE', padding: '5px 0px', display: 'grid', gridTemplateColumns: '1fr min-content 40px', gap: '5px' }}>
                    <p style={{ fontSize: '12px' }}>{e?.id}:</p>
                    <div style={{ height: '100%', width: '1px', background: '#EEE' }}/>
                    <p style={{ fontSize: '14px', fontWeight: 600, color: '#1976d2' }}>{e?.cpm ? Number(e?.cpm)?.toFixed(3) : '-'}</p>
                  </div>
                ))
              )}
            </div>
          </div>

        </div>
      </div>

      {/* chart */}
      <div className="home-chart-container">
        <Bar options={options} data={chartData}/>
      </div>
    </div>
  )
}

export default UserPage