import React from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import { host } from './host'

const updateSettings = ({ id, act_id, state, setSwitchState, inputValue, setInputValue, setUpdateSettingsLoading, settingsData, setSettingsData, currentObj, trafficToSend, action, appContext }) => {
    setUpdateSettingsLoading(true)

    const controller = new AbortController();
    const token = Cookies.get('token')
    const url = `${host}/api/bidding/update`;

    axios.post(url, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type, x-access-token",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
            "Authorization": "Bearer "+token,
            "Content-Type": "application/json",
        },
        data: [{id: id, act_id: act_id, state: state, daily_budget: inputValue, user: Cookies.get('userArray'), action: action}], traffic: trafficToSend
    },
    {
        signal: controller.signal
    })

    .then((response) => {
        console.log(response)
        if (trafficToSend === 'TikTok') {
            let tempObj = currentObj
            let newArr = {...settingsData}
            tempObj = {...tempObj, status: (state) ? 'ENABLE' : 'DISABLE', daily_budget: inputValue*1 }
            let index = newArr.week_result.findIndex(f => f.adgroup_id === currentObj.adgroup_id)

            newArr.week_result[index] = tempObj

            if (response.data?.budgetResult?.message === 'OK' && response.data?.statusResult?.message === 'OK') {
                setSettingsData(newArr);
            } else if (response.data?.budgetResult?.message !== 'OK') {
                window.alert(response.data?.budgetResult?.message ? response.data?.budgetResult?.message : 'Failed! Please try again.')
            } else {
                window.alert('Failed! Please try again.')
            }
        } else {
            if (response?.data?.result?.[0]?.result?.success === true) {
                let tempObj = currentObj
                let newArr = [...settingsData]
                tempObj = {...tempObj, status: (state) ? 'ACTIVE' : 'PAUSED', budget: inputValue }
                let index = newArr.findIndex(f => f.adset_id === currentObj.adset_id)
        
                newArr[index] = tempObj
        
                setSettingsData(newArr);
            } else {
                if (response?.data?.result?.[0]?.error?.error?.error_user_msg || response?.data?.result?.[0]?.error?.error?.error_user_msg || response?.data?.result?.[0]?.error?.error?.message) {
                    const error_msg = response?.data?.result?.[0]?.error?.error?.error_user_msg || response?.data?.result?.[0]?.error?.error?.error_user_msg || response?.data?.result?.[0]?.error?.error?.message
                    window.alert(`Adset Update failed. Facebook says "${error_msg}"`)
                } else {
                    window.alert('Adset Update failed')
                }
                setSwitchState((currentObj.status === 'ACTIVE') ? true : false)
                setInputValue(currentObj.budget)
            }
        }

        setUpdateSettingsLoading(false)
    })
    
    .catch((error) => {
        console.log(error);
        alert(error);
        setUpdateSettingsLoading(false)
        if (error?.response?.data?.message === 'Invalid') {
            appContext.forceLogout()
        }
    }) 
}

export default updateSettings