import React from "react";
import Traffic from '../reusable/traffic/Traffic'
import tonicLogo from '../../assets/tonicLogo.svg'

const TonicFacebook = () => {
    return(
        <Traffic trafficName='tonic_facebook' trafficToSend='Facebook' clientName='Tonic' logo={tonicLogo} minusDate={2} />
    )
}

export default TonicFacebook