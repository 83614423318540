import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import { host } from './host'
import { Context } from '../AppContext'

const useFetchCPM = () => {
    const appContext = useContext(Context)

    const [cpmData, setCpmData] = useState([])
    const [cpmLoading, setCpmLoading] = useState(true)

    useEffect(() => {
        const controller = new AbortController();
        const token = Cookies.get('token')
        const url = `${host}/api/data/fetch_cpm`;
        
        const getData = async () => {
            const selected_users = Cookies.get('userArray') ? Cookies.get('userArray').split(',') : []
            const facebook_accounts = window.localStorage.getItem('fbAccounts') ? JSON.parse(window.localStorage.getItem('fbAccounts')) : []
            let data_to_send = []

            if ((selected_users.length === 0) || (facebook_accounts.length === 0) || (Cookies.get('role') !== 'admin')) {
                return
            }
            
            selected_users.map(e => {
                facebook_accounts.map(m => {
                    if (e === m.username) {
                        data_to_send.push({
                            act_name: m.act_name,
                            act_id: m.act_id
                        })
                    }
                })
            })
            
            setCpmLoading(true)
            await axios.post(url, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                    "Authorization": "Bearer "+token,
                    "Content-Type": "application/json",
                },
                ids: data_to_send
            },
            {
                signal: controller.signal
            })

            .then((response) => {
                const obj = response.data
                console.log(response)

                setCpmData(obj)
                setCpmLoading(false)
            })

            .catch((error) => {
                console.log(error);
                setCpmLoading(false)
                if (error?.response?.data?.message === 'Invalid') {
                    appContext.forceLogout()
                }
            })
        }
    
        getData()

        return () => {
            controller.abort()
        }
    }, [])

    return [cpmLoading, cpmData]
}

export default useFetchCPM