import React from "react";
import Traffic from '../reusable/traffic/Traffic'
import facebookLogo from '../../assets/facebookLogo.png'

const AllCountriesFacebook = () => {
    return(
        <Traffic trafficName='all_country_facebook' trafficToSend='Facebook' clientName='All' levelUrlFor='AllCountries' logo={facebookLogo} minusDate={2}/>
    )
}

export default AllCountriesFacebook