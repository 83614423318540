import { useState, useEffect, useContext } from 'react'
import axios from "axios";
import moment from "moment";
import Cookies from "js-cookie";
import { host } from './host'
import { Context } from "../AppContext";

const useFetchTraffic = ({ clientName, minusDate, traffic, trafficToSend, account, fromDate, toDate, refresh, setOffCampaign, selectedCampaign }) => {
    const appContext = useContext(Context)
    const user = Cookies.get("userArray")
    const token = Cookies.get("token");

    const [loading, setLoading] = useState({ active_campaigns: false, main_table: false, pulse_schedule: false, off_campaigns: false, single_campaign: false, fb_live_status: false })
    const [totalValue, setTotalValue] = useState({});
    const [activeCampCount, setActiveCampCount] = useState({ active_campaigns: 0, result_per_account: 0, date: '', error: '' });
    const [chartData, setChartData] = useState({ date: [], result: [], spent: [], roi: [] });
    const [chartDataSingle, setChartDataSingle] = useState({ date: [], CPtrend: [], CPA: [], CPL: [] });
    const [data, setData] = useState({ account_list: [], tonic_revenues: null, campaigns: [], off_campaigns: {}, single_campaign: [] });

    const dateCondition = () => {
        let date = ''

        if (clientName === 'Sedo') {
            if ((moment().utc().add(8, 'hours').format('HH') * 1) >= 11) {
                date = moment().utc().add(8, 'hours').subtract(2, 'days').format('YYYY-MM-DD')
            } else {
                date = moment().utc().add(8, 'hours').subtract(2, 'days').format('YYYY-MM-DD')
            }
        } else {
            date = moment().utc().add(8, 'hours').subtract(minusDate, 'days').format('YYYY-MM-DD')
        }

        return date
    }

    // fetch latest date campaign data to get the active campaigns
    useEffect(() => {
        const controller = new AbortController();
        const url = `${host}/api/data`;
    
        const getData = () => {
            setLoading(p => { return { ...p, active_campaigns: true } })
            axios.post(url, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                    "Authorization": "Bearer "+token,
                    "Content-Type": "application/json",
                },
                data: {
                    traffic: traffic,
                    from: dateCondition(),
                    to: dateCondition(),
                    username: user,
                    lastDate: dateCondition(),
                    trafficName: trafficToSend,
                    account_id: 'all',
                },
            },
            {
                signal: controller.signal
            })
            .then((response) => {
                const obj = response.data;
                console.log(response)
    
                if (obj.message) {
                    setActiveCampCount(p => { return { ...p, error: 'Error Return!' } })
                } else {
                    const ac = obj.data.length
                    const totalResult = obj.totalByDate.find(f => f.date !== 'Total').result
                    const rpac = totalResult / ac
                    setActiveCampCount(p => { return { ...p, active_campaigns: ac, result_per_account: rpac, date: dateCondition(), error: '' } })
                }
            })
            .catch((error) => {
                console.log(error);
                if (error?.response?.data?.message === 'Invalid') {
                    appContext.forceLogout()
                }
            })
            .finally(() => setLoading(p => { return { ...p, active_campaigns: false } }) )
        };
    
        getData();
    
        return () => {
            controller.abort()
        }
    }, []);





    // fetch all campaigns data within the selected date range
    useEffect(() => {
        const controller = new AbortController()
        const url = `${host}/api/data`;
        const url_search_widget = `${host}/api/index/search`;
        const url_live_facebook_status = `${host}/api/facebook_page/facebook_status`;
        let timeoutID

        // fetch live facebook ON/OFF status of a account's campaigns
        const fetchLiveFacebookStatus = async (campaigns) => {
            if (account === 'all') {
                const final_campaigns = campaigns.map(m => {
                    let row = { ...m }
                    row.fb_live_status = 'ERROR'
                    return row
                })
                
                setData(p => { return { ...p, campaigns: final_campaigns } })
                setLoading(p => { return { ...p, fb_live_status: false } })
                return
            }

            try {
                const response = await axios.post(url_live_facebook_status, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                        "Authorization": "Bearer "+token,
                        "Content-Type": "application/json",
                    },
                    act_id: account
                })
        
                console.log(response)
                const obj = response.data
    
                if (obj.data && Array.isArray(obj.data)) {
                    let final_campaigns = campaigns.map(m => {
                        let row = { ...m }
                        let find_campaign = obj?.data?.find(f => f.id === m.campaign_id)
                        row.fb_live_status = find_campaign?.status ? find_campaign?.status : 'ERROR'
                        return row
                    })
    
                    if (obj.data && Array.isArray(obj.data)) {
                        let count = 0
                        obj.data.filter(fil => fil.status === "ACTIVE").forEach(item => {
                            const find_campaign = final_campaigns.find(f => f.campaign_id === item.id)
                            if (!find_campaign) {
                                final_campaigns.push({ id: final_campaigns.length+count, campaign_name: item.name, campaign_id: item.id, fb_live_status: item.status })
                                count += 1
                            }
                        })
                    }
    
                    setData(p => { return { ...p, campaigns: final_campaigns } })
                } else {
                    const final_campaigns = campaigns.map(m => {
                        let row = { ...m }
                        row.fb_live_status = 'ERROR'
                        return row
                    })
                    
                    setData(p => { return { ...p, campaigns: final_campaigns } })

                    if (obj?.error?.message) {
                        window.alert("Error fetching live facebook status. Facebook says: \n\n" + obj?.error?.message)
                    } else {
                        window.alert("Error fetching live facebook status.")
                    }
                }
            } catch (error) {
                console.log(error);
                window.alert("Error fetching live facebook status\n\n" + JSON.stringify(error))
            }

            setLoading(p => { return { ...p, fb_live_status: false } })
        }
        
        // search widgets whose schedule is true
        const searchScheduledWidgets = async (campaigns) => {
            try {
                const response = await axios.post(url_search_widget, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                        "Authorization": "Bearer "+token,
                        "Content-Type": "application/json",
                    },
                    field: "schedule",
                    keyword: "true"
                })
        
                const obj = response.data        
                const final_campaigns = campaigns.map(m => {
                    let row = { ...m }
                    let find_widget = obj.result.find(f => f?.widget?.toString() === row?.campaign_name?.toString())
                    if (find_widget) {
                        row.schedule = true
                        row.active_day = find_widget?.active_day
                        row.off_day = find_widget?.off_day
                        row.turn_on_date = find_widget?.turn_on_date
                        row.turn_off_date = find_widget?.turn_off_date
                    } else {
                        row.schedule = false
                        row.active_day = 1
                        row.off_day = 1
                        row.turn_on_date = "-"
                        row.turn_off_date = "-"
                    }
                    return row
                })
                
                fetchLiveFacebookStatus(final_campaigns)
            } catch (error) {
                console.log(error);
                window.alert("Error fetching scheduled widgets.\n" + error)
            }

            setLoading(p => { return { ...p, pulse_schedule: false } })
        }
    
        const getData = () => {
            setLoading(p => { return { ...p, main_table: true, pulse_schedule: true, fb_live_status: true } })
            setData(p => { return { ...p, campaigns: [] } })
        
            axios.post(url, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                    "Authorization": "Bearer "+token,
                    "Content-Type": "application/json",
                },
                data: {
                    traffic: traffic,
                    from: fromDate,
                    trafficName: trafficToSend,
                    to: toDate,
                    username: user,
                    lastDate: dateCondition(),
                    account_id: account,
                },
            },
            {
                signal: controller.signal
            })
        
            .then((response) => {
                const obj = response.data;
                console.log(response);
        
                if (obj.message) {
                    setTotalValue({})
                    setChartData({ date: [], result: [], spent: [], roi: [] })
                    setData(p => { return { ...p, account_list: [], tonic_revenues: null, campaigns: [] } })
                } else {
                    let tempAccList = obj.accountList ? obj.accountList : []
                    let localAccList = localStorage.getItem('fbAccounts') ? JSON.parse(localStorage.getItem('fbAccounts')) : []
                    tempAccList.forEach(e => {
                        let findAcc = localAccList?.find(f => f?.act_id === e?.act_id)
                        if (!findAcc) {
                            localAccList.push({ ...e, traffic: trafficToSend, username: user })
                        }
                    })
                    window.localStorage.setItem('fbAccounts', JSON.stringify(localAccList))

                    let ordered = obj.totalByDate.filter((e) => e.date !== "Total").sort((a, b) => new Date(a.date) - new Date(b.date))
                    setChartData({ 
                        date: ordered.map((data) => data.date),
                        result: ordered.map((data) => data.result ? Number(data.result.toFixed(2)) : 0),
                        spent: ordered.map((data) => data.spent ? Number(data.spent.toFixed(2)) : 0),
                        roi: ordered.map((data) => data["ROI%"] ? Number(data["ROI%"].toFixed(2)) : 0)
                    })
                    setTotalValue(obj.totalByDate.find(f => f.date === 'Total'))
                    setData(p => { return { ...p, account_list: obj.accountList, tonic_revenues: obj.tonicRevenues, campaigns: obj.data } })
                    searchScheduledWidgets(obj.data)
                }
            })
            .catch((error) => {
                console.log(error);
                setTotalValue({})
                setChartData({ date: [], result: [], spent: [], roi: [] })
                setData(p => { return { ...p, account_list: [], tonic_revenues: null, campaigns: [] } })

                if (error.name === 'CanceledError') {
                    timeoutID = setTimeout(() => {
                        setLoading(p => { return { ...p, main_table: true, pulse_schedule: true } })
                    }, 100);
                }
        
                if (error?.response?.data?.message === 'Invalid') {
                    appContext.forceLogout()
                }
            })
            .finally(() => setLoading(p => { return { ...p, main_table: false } }))
        };        
    
        getData();
    
        return () => {
            clearTimeout(timeoutID)
            controller.abort()
        }
    }, [fromDate, toDate, refresh.main_table, account])





    // fetch single campaign data
    useEffect(() => {
        const controller = new AbortController();
        const token = Cookies.get("token");
        const url = `${host}/api/data/campaign`;
        let timeoutID
    
        const getData = () => {
            setLoading(p => { return { ...p, single_campaign: true } })
            axios.post(url, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                    "Authorization": "Bearer "+token,
                    "Content-Type": "application/json",
                },
                data: {
                    traffic: traffic,
                    from: moment().utc().add(8, 'hours').subtract(minusDate + 13, 'days').format('YYYY-MM-DD'),
                    to: moment().utc().add(8, 'hours').subtract(minusDate, 'days').format('YYYY-MM-DD'),
                    name: selectedCampaign.name,
                    username: "",
                    act_id: selectedCampaign.act_id,
                    act_name: selectedCampaign.act_name
                },
            },
            {
                signal: controller.signal
            })
        
            .then((response) => {
                const obj = response.data;
                console.log(response)
        
                if (obj.message) {
                    setData(p => { return { ...p, single_campaign: [] } })
                    setChartDataSingle({ date: [], CPtrend: [], CPA: [], CPL: [] })
                } else {
                    let top = [];
                    let bottom = [];
                    let date = [];
                    let cpa = [];
                    let cpl = [];
        
                    obj.data.sort((a, b) => {return new Date(a.date) - new Date(b.date)}).forEach((e) => {
                        if (e.date !== "Total") {
                            date.push(e.date);
                            cpa.push((e.revenue && e.lead) ? (Number(e.revenue) / Number(e.lead)) : 0);
                            cpl.push((e.spent && e.lead) ? (Number(e.spent) / Number(e.lead)) : 0);
                            bottom.push(e)
                        }
                        if (e.date === "Total") {
                            top.push(e)
                        }
                    });
        
                    bottom.forEach((e) => top.push(e))

                    setData(p => { return { ...p, single_campaign: top?.filter(f => (f?.date !== 'Total') && (moment(f?.date) > moment(toDate).utc().add(8, 'hours').subtract(7, 'days'))) } })
                    setChartDataSingle({
                        date: date,
                        CPtrend: cpa.map((e, i) => (e + cpl[i])/2),
                        CPA: cpa,
                        CPL: cpl
                    })
                }
            })
            .catch((error) => {
                console.log(error);
                setData(p => { return { ...p, single_campaign: [] } })
                setChartDataSingle({ date: [], CPtrend: [], CPA: [], CPL: [] })

                if (error.name === 'CanceledError') {
                    timeoutID = setTimeout(() => {
                        setLoading(p => { return { ...p, single_campaign: true } })
                    }, 100);
                }

                if (error?.response?.data?.message === 'Invalid') {
                    appContext.forceLogout()
                }
            })
            .finally(() => setLoading(p => { return { ...p, single_campaign: false } })) 
        };
    
        if (selectedCampaign.name && selectedCampaign.act_id && selectedCampaign.act_name) {
            getData()
        } else {
            setLoading(p => { return { ...p, single_campaign: false } })
            setData(p => { return { ...p, single_campaign: [] } })
            setChartDataSingle({ date: [], CPtrend: [], CPA: [], CPL: [] })
        }
    
        return () => {
            clearTimeout(timeoutID)
            controller.abort()
        };
    }, [selectedCampaign.name])





    // fetch off campaigns
    useEffect(() => {
        const controller = new AbortController();
        const url = `${host}/api/bidding/V2/fetch_off_campaign`;
        
        const getData = async () => {
            setLoading(p => { return { ...p, off_campaigns: true } })
            await axios.post(url, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                    "Authorization": "Bearer "+token,
                    "Content-Type": "application/json",
                },
                traffic: traffic,
                username: user ? user.split(',') : '',
                lastDate: moment().utc().add(8, 'hours').subtract(minusDate, 'days').format('YYYY-MM-DD')
            },
            {
                signal: controller.signal
            })
            .then((response) => {
                const obj = response.data
                console.log(response)

                if (obj.result) {
                    setData(p => { return { ...p, off_campaigns: obj.result } })
                    setOffCampaign(obj.result)
                } else {
                    setData(p => { return { ...p, off_campaigns: {} } })
                    setOffCampaign({})
                }
            })
            .catch((error) => {
                console.log(error);
                if (error?.response?.data?.message === 'Invalid') {
                    appContext.forceLogout()
                }
            })
            .finally(() => setLoading(p => { return { ...p, off_campaigns: false } }))
        }
    
        getData()

        return () => {
            controller.abort()
        }
    }, [refresh.off_campaigns])

    return {
        loading,
        totalValue,
        activeCampCount,
        chartData,
        accountList: data.account_list,
        tonicRevenues: data.tonic_revenues,
        campaignDataSingle: data.single_campaign,
        chartDataSingle,
        data,
        setData
    }
}

export default useFetchTraffic