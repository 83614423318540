import React from 'react'
import './pageNotFound.css'
import { useNavigate } from 'react-router-dom';
import { LiaLongArrowAltRightSolid } from "react-icons/lia";

import error404 from "../../assets/error404.png"

const PageNotFound = () => {
  const navigate = useNavigate()

  return (
    <div className='page-not-found-main'>
      <div className='page-not-found-container'>
        <div className='page-not-found'>
          <img src={error404} alt="404" />
          <p>PAGE DOESN'T EXIST</p>
          <div onClick={() => navigate('/')}>Back to home&nbsp;<LiaLongArrowAltRightSolid size={24}/></div>
        </div>
      </div>
    </div>
  )
}

export default PageNotFound