import React from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import { host } from './host'

const updateSettingsRule = ({ data, settingsData, setSettingsData, setUpdateSettingsLoading, trafficToSend, appContext }) => {
    setUpdateSettingsLoading(true)

    const token = Cookies.get('token')
    const url = `${host}/api/bidding/update/max_value`;

    axios.post(url, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type, x-access-token",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
            "Authorization": "Bearer "+token,
            "Content-Type": "application/json",
        },
        data: data, traffic: trafficToSend
    })

    .then((response) => {
        let newArr = {...settingsData}

        let index = newArr.today_result.findIndex(f => f.adgroup_id === data.adgroup_id)

        newArr.today_result[index] = data

        if (response.data.result) {
            if (response.data.result[0].success === true) {
                setSettingsData(newArr)
            }
        } else {
            window.alert('Error! Please refresh the page to check!')
        }

        setUpdateSettingsLoading(false)
    })
    
    .catch((error) => {
        console.log(error)
        setUpdateSettingsLoading(false)
        if (error?.response?.data?.message === 'Invalid') {
            appContext.forceLogout()
        }
    }) 
}

export default updateSettingsRule