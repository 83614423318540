export const access_pages_list = {
    Dashboard: [
        "widget-ads-creation",
        "best-performing",
        "topic-trends",
        "facebook-accounts",
        "fb-fan-page-data",
        "fb-fan-page-links-set-up",
        "fb-fan-page-posts-creation",
    ],
    Clients: [
        "ads-facebook",
        "crossroads-facebook",
        "sedo-facebook",
        "sedo-tiktok",
        "sedo-domain",
        "system1-facebook",
        "tonic-facebook",
        "tonic-tiktok",
    ],
    Rotations: [
        "rotation-by-clients",
        "rotation-by-countries",
        "rotation-by-emailoctopus",
    ],
    Others: [
        "error-alert",
        "states",
        "unused-domain",
    ],
}