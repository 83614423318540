import { useState, useEffect, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import { host } from './host'
import { Context } from "../AppContext";

const useFetchStock = ({ stock, triggerChartDataFetch }) => {
    const appContext = useContext(Context)

    const [loading, setLoading] = useState({ list: false, chart: false });
    const [stockList, setStockList] = useState([]);
    const [stockChart, setStockChart] = useState([]);
    const [stockChartDates, setStockChartDates] = useState([]);

    // run once with date only to get stock names from all clients
    useEffect(() => {
        const controller = new AbortController();
        const token = Cookies.get("token");

        const url = `${host}/api/data/stock`;

        const getData = async () => {
            setLoading(p => { return { ...p, list: true } })

            let result = []

            await Promise.all(['tonic_stock', 'sedo_stock', 'crossroads_stock'].map(async e => {
                try {
                    const response = await axios.post(url, {
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                            "Authorization": "Bearer "+token,
                            "Content-Type": "application/json",
                        },
                        lastDate: moment().utc().add(8, 'hours').subtract(2, 'days').format('YYYY-MM-DD'),
                        traffic: e
                    },
                    {
                        signal: controller.signal
                    })
                    if (response.data) {
                        const obj = response.data;
                        console.log(response);

                        if (Array.isArray(obj?.result)) {
                            if (obj.result.length > 0) {
                                obj.result.map(item => {
                                    if (item) {
                                        result.push({
                                            stock: item,
                                            traffic: e
                                        })
                                    }
                                })
                            }
                        }
                    }
                } catch (error) {
                    console.log(error);
                    if (error?.response?.data?.message === 'Invalid') {
                        appContext.forceLogout()
                    }
                }
            }))
            
            setStockList(result)
            setLoading(p => { return { ...p, list: false } })
        }

        getData();

        return () => {
            controller.abort()
        };
    }, []);






    // run every time the stock changes to get chart data
    useEffect(() => {
        if (stock.length === 0) {
            setLoading(p => { return { ...p, chart: false } })
            return
        }

        let stock_api = []

        stock.map(e => {
            const check = stockChart.filter(f => (f.stock === e.stock) && (f.traffic === e.traffic))
            if (check.length === 0) {
                stock_api.push(e)
            }
        })

        if (stock_api.length === 0) {
            setLoading(p => { return { ...p, chart: false } })
            return
        }

        const controller = new AbortController();
        const token = Cookies.get("token");

        const url = `${host}/api/data/stock`;

        const getAllData = async () => {
            setLoading(p => { return { ...p, chart: true } })

            let result = [...stockChart]
            let result_dates = [...stockChartDates]

            await Promise.all(stock_api.map(async (e) => {
                try {
                    const response = await axios.post(url, {
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                            "Authorization": "Bearer "+token,
                            "Content-Type": "application/json",
                        },
                        id: e.stock,
                        traffic: e.traffic,
                        lastDate: moment().utc().add(8, 'hours').subtract(2, 'days').format('YYYY-MM-DD')
                    },
                    {
                        signal: controller.signal
                    })
                    if (response.data) {
                        console.log(response);
                        const obj = response.data;
                        let temp = []

                        if (Array.isArray(obj?.result)) {
                            if (obj.result.length > 0) {
                                temp = obj.result.map(m => {
                                    return { date: m.date, cpc: m.cpc.toFixed(3) }
                                })
                            }
                        }

                        temp.sort((a, b) => new Date(a.date) - new Date(b.date) )

                        if (temp.length > result_dates.length) {
                            result_dates = temp.map(m => m?.date)
                        }

                        result.push({
                            data: temp,
                            stock: e.stock,
                            traffic: e.traffic,
                            // color: "rgba(" + Math.floor(Math.random() * 255) + "," + Math.floor(Math.random() * 255) + "," + Math.floor(Math.random() * 255)
                            color: '#' + Math.floor(Math.random()*16777215).toString(16)
                        })
                    }
                } catch (error) {
                    console.log(error);
                    if (error?.response?.data?.message === 'Invalid') {
                        appContext.forceLogout()
                    }
                }
            }))
        
            setStockChart(result)
            setStockChartDates(result_dates)
            setLoading(p => { return { ...p, chart: false } })
        }

        getAllData()

        return () => {
            controller.abort()
        };
    }, [triggerChartDataFetch]);

    return [loading, stockList, stockChart, stockChartDates];
};

export default useFetchStock;