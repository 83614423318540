import React from "react";
import Sum from "../reusable/sum/Sum";
import system1Logo from '../../assets/system1Logo.svg'

const System1Total = () => {
    return(
        <Sum clientName='system1' logo={system1Logo} minusDate={2} />
    )
}

export default System1Total