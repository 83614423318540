import React, { useState, useEffect, useContext } from "react";
import './traffic.css'
import './rulesNeverDelete.css'
import '../sum/sum.css'
import { DataGrid, GridToolbar, GridFooterContainer, GridFooter } from '@mui/x-data-grid';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from "react-chartjs-2";
import chartTrendline from "chartjs-plugin-trendline"
import moment from "moment";
import Cookies from "js-cookie";
import axios from "axios";
import { MdDoneAll, MdRemoveDone, MdHorizontalRule } from 'react-icons/md'
import { HiRefresh } from 'react-icons/hi'
import { FaEdit, FaCheck, FaCopy, FaLink, FaThumbsUp, FaThumbsDown } from 'react-icons/fa'
import { FaLinkSlash, FaRecycle } from "react-icons/fa6";
import { ImCross } from 'react-icons/im'
import { IoPulseSharp } from "react-icons/io5"
import { TbArrowsExchange2 } from 'react-icons/tb'
import { GiCancel } from 'react-icons/gi'
import { PiArrowFatLinesUpFill, PiArrowFatLinesDownFill, PiArrowFatUpFill, PiArrowFatDownFill } from 'react-icons/pi'

import { host } from "../../../hooks/host";
import turtleIcon from "../../../assets/turtleIcon.jpg";
import crawlingBaby from "../../../assets/crawlingBaby.png";
import useFetchUsers from "../../../hooks/useFetchUsers";
import useFetchTraffic from '../../../hooks/useFetchTraffic'
import updateSettings from "../../../hooks/updateSettings";
import updateSettingsRule from "../../../hooks/updateSettingsRule";
import updateSettingsTikTokLive from "../../../hooks/updateSettingsTikTokLive";
import updateSettingsTikTokLiveRule from "../../../hooks/updateSettingsTikTokLiveRule";
import { copyCampaign } from "../../../hooks/copyCampaign";
import { Context } from "../../../AppContext";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, chartTrendline);

const Traffic = ({trafficName, trafficToSend, clientName, logo, minusDate, levelUrlFor}) => {
    const appContext = useContext(Context)

    const [fromDate, setFromDate] = useState(moment().utc().add(8, 'hours').subtract(minusDate + 6, 'days').format('YYYY-MM-DD'))
    const [toDate, setToDate] = useState(moment().utc().add(8, 'hours').subtract(minusDate, 'days').format('YYYY-MM-DD'))
    
    const [offCampaign, setOffCampaign] = useState({})
    const [account, setAccount] = useState("all")
    const [campaignName, setCampaignName] = useState({ name: '', act_id: '' })
    const [selectedCampaign, setSelectedCampaign] = useState('')
    const [selectedCampaignInfo, setSelectedCampaignInfo] = useState({ loading: false, loadingCountry: false, country: '', topic: '', url: null })
    const [refresh, setRefresh] = useState({ main_table: false, adsets: false, off_campaigns: false })
    const [settingsData, setSettingsData] = useState([])
    const [actionLoading, setActionLoading] = useState({ settings: false, multi_fb_status: false, multi_delete_widget: false, update_adsets: false })
    const [checkedCampaigns, setCheckedCampaigns] = useState([])
    const [userToSend, setUserToSend] = useState('')
    const [checkedAdsets, setCheckedAdsets] = useState([])
    const [copyC2WLoading, setCopyC2WLoading] = useState(false)
    const [levelURL, setLevelURL] = useState({ display: false, selectedCampaign: '', spent: 0, revenue: 0, actions: 0, clicks: null })
    const [levelCountry, setLevelCountry] = useState({ display: false, data: [], countries: [], url: null, revenue: null, revenue_by_campaign: null, totalSpent: 0, campaign: null })


    // live TikTok
    const [tiktokLive, setTiktokLive] = useState({ loading: false, data: [], campaign: '' })
    
    const username = Cookies.get('userArray')

    // check and get accounts list
    const getAccountList = () => {
        let localData = window.localStorage.getItem('fbAccounts')
        let fb_accounts = []

        if (localData) {
            fb_accounts = JSON.parse(localData)
        }

        return fb_accounts.filter(f => ( f.username === username && f.client.toLowerCase() === clientName.toLowerCase() && f.traffic.toLowerCase() === trafficToSend.toLowerCase() ))
    }
    
    const fetch = useFetchUsers()
    const [userLoading, users] = fetch

    const { loading, totalValue, activeCampCount, chartData, accountList, tonicRevenues, campaignDataSingle, chartDataSingle, data, setData } = useFetchTraffic({ clientName, minusDate, traffic: trafficName, trafficToSend, account, fromDate, toDate, refresh, setOffCampaign, selectedCampaign: { name: campaignName.name, act_id: campaignName.act_id, act_name: getAccountList()?.find(f => f?.act_id === campaignName.act_id)?.act_name } })

    // chart js options settings
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: campaignName.name,
            },
            tooltip: {
                mode: 'index'
            }
        }
    };
    
    // chart js options settings
    const optionsROI = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Daily Chart',
            },
            tooltip: {
                mode: 'index'
            },
        },
        scales: {
            'result-y-axis': {
                type: 'linear',
                position: 'left',
                suggestedMin: -300,
                ticks: {
                    color: '#4caf50',
                }
            },
            'roi-y-axis': {
                type: 'linear',
                position: 'right',
                suggestedMin: -20,
                ticks: {
                    color: 'rgb(33, 150, 243)',
                }
            },
            'bar-y-axis': {
                type: 'linear',
                position: 'right',
                suggestedMin: 0,
                ticks: {
                    color: 'rgb(239, 83, 80)',
                }
            },
        }
    };

    // chart js data sets
    const dataCpaCpl = {
        labels: chartDataSingle.date,
        datasets: [
            {
                label: 'CPL',
                data: chartDataSingle.CPL,
                borderColor: 'rgba(239, 83, 80, 0.5)',
                backgroundColor: 'rgba(239, 83, 80, 0.5)',
                borderDash: [3, 3],
                borderWidth: 2,
                pointRadius: 2,
                pointHoverRadius: 2,
                trendlineLinear: (chartDataSingle.CPL?.length > 1) && {
                    colorMin: "#ef5350",
                    colorMax: "#ef5350",
                    lineStyle: "solid",
                    width: 2,
                }
            },
            {
                label: 'CPA',
                data: chartDataSingle.CPA,
                borderColor: 'rgba(76, 175, 80, 0.5)',
                backgroundColor: 'rgba(76, 175, 80, 0.5)',
                borderDash: [3, 3],
                borderWidth: 2,
                pointRadius: 2,
                pointHoverRadius: 2,
                trendlineLinear: (chartDataSingle.CPA?.length > 1) && {
                    colorMin: "#4caf50",
                    colorMax: "#4caf50",
                    lineStyle: "solid",
                    width: 2,
                }
            },
            {
                label: 'Camp Trend',
                data: chartDataSingle.CPtrend,
                borderColor: '#1E90FF',
                backgroundColor: '#1E90FF',
                borderWidth: 0,
                pointRadius: 0,
                pointHoverRadius: 0,
                showLine: false,
                trendlineLinear: (chartDataSingle.CPtrend?.length > 1) && {
                    colorMin: "#1E90FF",
                    colorMax: "#1E90FF",
                    lineStyle: "dotted",
                    width: 2,
                }
            },
        ],
    };

    // chart js data sets
    const dataResult = {
        labels: (chartData.date) ? chartData.date : [],
        datasets: [
            {
                type: 'line',
                label: 'Result',
                data: (chartData.result) ? chartData.result : [],
                borderColor: '#4caf50',
                backgroundColor: '#4caf50',
                borderWidth: 4,
                yAxisID: 'result-y-axis'
            },
            {
                type: 'bar',
                label: 'Spent',
                data: (chartData.spent) ? chartData.spent : [],
                borderColor: 'rgba(239, 83, 80, 0.4)',
                backgroundColor: 'rgba(239, 83, 80, 0.1)',
                borderWidth: 1,
                yAxisID: 'bar-y-axis'
            },
            {
                type: 'line',
                label: 'ROI',
                data: (chartData.roi) ? chartData.roi : [],
                borderColor: 'rgba(33, 150, 243, 0.5)',
                backgroundColor: 'rgba(33, 150, 243, 0.5)',
                borderWidth: 2,
                pointRadius: 2,
                yAxisID: 'roi-y-axis'
            },
        ],
    };

    // condition to check if the date is before yesterday
    const checkCampDate = (date) => {
        let result = false
        if (moment(date).isBefore(moment().utc().add(8, 'hours').subtract(1, 'day').format('YYYY-MM-DD'))) {
            result = true
        }
        return result
    }

    // calculate slope value
    const getSlope = (value) => {
        const n = value.length
        const x = value.reduce((a, b) => a + b, 0)
        let totalY = Array.from(Array(n).keys(), (key)=> key+1 )
         totalY = totalY.reduce((a, b) => a + b, 0)
        let total = 0
        let xSquare = 0
        value.map((a, b) => {
            total += a*(b+1)
            xSquare += a**2
        })

        const slope = ( (n*total) - (x*totalY) ) / ( (n*xSquare) - (x**2) )

        // console.log('main array '+value);
        // console.log('main array length '+n);
        // console.log('main array sum'+x);
        // console.log('total y'+totalY);
        // console.log('total'+total);
        // console.log('xSquare'+xSquare);
        // console.log('CPA = '+slope);
        return slope
    }

    // main table campaign status component to turn on or off
    // const CampaignStatusButton = ({ state, params }) => {
    //     const [active, setActive] = useState(state)
    //     const [isRealTimeOff, setIsRealTimeOff] = useState(false)
    //     const [activeLoading, setActiveLoading] = useState(false)
    //     const [turnOnDate, setTurnOnDate] = useState(moment().utc().add(8, 'hours').format('YYYY-MM-DD'))
    //     const [displayConfirm, setDisplayConfirm] = useState(false)
    //     const [displaySchedule, setDisplaySchedule] = useState(false)

    //     const find_rule = appContext.rulesInfo.data.find(f => (f?.campaign_name === params.row.campaign_name) && (f?.traffic === trafficName))
    //     const url = `${host}/api/bidding/V2/off_campaign`;
        
    //     const handleCampaignStatusChange = async (auto) => {
    //         setActiveLoading(true)
    //         const token = Cookies.get("token");

    //         await axios.post(url,{
    //             headers: {
    //                 "Access-Control-Allow-Origin": "*",
    //                 "Access-Control-Allow-Headers": "Content-Type, x-access-token",
    //                 "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
    //                 "Authorization": "Bearer "+token,
    //                 "Content-Type": "application/json",
    //             },
    //             traffic: trafficName,
    //             campaign_name: params.row.campaign_name,
    //             campaign_id: params.row.campaign_id,
    //             url: params.row.campaign_ID,
    //             state: isRealTimeOff ? false : !active,
    //             turnOfDate: moment().utc().add(8, 'hours').format('YYYY-MM-DD'),
    //             turnOnDate: turnOnDate,
    //             auto: auto,
    //             act_id: params.row.act_id,
    //         })
    //         .then((response) => {
    //             if ((active === true) || isRealTimeOff) {
    //                 const data_to_send = [
    //                     {
    //                         traffic: trafficName,
    //                         campaign_name: params.row.campaign_name,
    //                         campaign_id: params.row.campaign_id,
    //                         state: false,
    //                         act_id: params.row.act_id
    //                     }
    //                 ]
    //                 ruleChangeAPI(data_to_send)
    //             }
    //             setActive(prev => !prev)
    //             setRefresh(p => { return { ...p, off_campaigns: !p.off_campaigns } })
    //             setActiveLoading(false)

    //             // manipulate table only in the frontend
    //             const row_index = data.campaigns.findIndex(f => f.campaign_id === params.row.campaign_id)
    //             setData(p => {
    //                 let temp = [...p.campaigns]
    //                 temp[row_index].off = isRealTimeOff ? false : !active
    //                 temp[row_index].fb_live_status = isRealTimeOff ? 'PAUSED' : (active ? 'PAUSED' : 'ACTIVE')
    //                 return { ...p, campaigns: temp }
    //             })
    //         })
    //         .catch((error) => {
    //             console.log(error)
    //             window.alert(error)
    //             setActiveLoading(false)
    //             if (error?.response?.data?.message === 'Invalid') {
    //                 appContext.forceLogout()
    //             }
    //         })
    //     }

    //     useEffect(() => {
    //         if ((find_rule?.finish === true) && (find_rule?.result?.action === "off") && state !== false) {
    //             setActive(false)
    //             setIsRealTimeOff(true)
    //         }
    //     }, [appContext.rulesInfo.data])
        
    //     return(
    //         <div className='rulesSwitchContainer' title={(account === 'all') ? 'Please select an account first to use this feature!' : ''} style={{ position: 'relative', background: 'rgba(255, 0, 0, 0.1)', cursor: (account === 'all') ? 'not-allowed' : 'default' }}>
    //             {activeLoading ? (
    //                 <div className="loader"/>
    //             ) : (
    //                 <div className="rulesSwitch" style={{position: 'relative', background: `${isRealTimeOff ? '#ef4444' : (active ? '#1976d2' : '#98989d')}`, pointerEvents: `${(activeLoading) ? 'none' : 'all'}`, cursor: (account === 'all') ? 'not-allowed' : 'default' }}
    //                     onClick={() => {
    //                         if (account === 'all') {
    //                             return
    //                         }

    //                         if (active || isRealTimeOff) {
    //                             setDisplayConfirm(true)
    //                         } else {
    //                             if ((find_rule?.finish === true) && (find_rule?.result?.action === "off")) {
    //                                 appContext.setDialogInfo({
    //                                     mode: "Confirm",
    //                                     type: "Confirm",
    //                                     message: [
    //                                         { type: "Confirm", title: "Confirm to proceed", content: "Once this is turned ON it will still display RED because it was turned off by Auto Optimization." },
    //                                     ],
    //                                     confirm_function: () => handleCampaignStatusChange(false),
    //                                     cancel_function: () => {}
    //                                 })
    //                             } else {
    //                                 handleCampaignStatusChange(false)
    //                             }
    //                         }
    //                     }}
    //                 >
    //                     <div className="rulesSwitchButton" style={{left: `${(active) ? '22.5px' : '2.5px' }`}}/>
    //                 </div>
    //             )}
    //             {displayConfirm ? (
    //                 <div style={{ position: 'absolute', zIndex: 10, top: '100%', left: 0, height: '200px', width: '200px', background: '#000', borderRadius: '10px', padding: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
    //                     <p style={{ color: '#FFF', textAlign: 'center', fontSize: '14px' }}>Do you wanna auto turn on campaign in future?</p>
    //                     <div style={{ height: '40px', width: '100%', display: 'flex', gap: '5px' }}>
    //                         <div style={{ flex: 1, fontSize: '14px', borderRadius: '5px', border: '1px solid #FFF', color: '#FFF', background: '#000', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => {setDisplayConfirm(false); setDisplaySchedule(false); handleCampaignStatusChange(false);}}>NO</div>
    //                         <div style={{ flex: 1, fontSize: '14px', borderRadius: '5px', border: '1px solid #FFF', color: '#000', background: '#FFF', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => {setDisplaySchedule(true); setDisplayConfirm(false);}}>YES</div>
    //                     </div>
    //                     <div style={{ height: '40px', width: '100%', display: 'flex', gap: '5px' }}>
    //                         <div style={{ flex: 1, fontSize: '14px', borderRadius: '5px', border: '1px solid #FFF', color: '#FFF', background: '#000', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => {setDisplaySchedule(false); setDisplayConfirm(false);}}>CANCEL</div>
    //                     </div>
    //                 </div>
    //             ) : (<></>)}

    //             {displaySchedule ? (
    //                 <div style={{ position: 'absolute', zIndex: 10, top: '100%', left: 0, height: '200px', width: '200px', background: '#000', borderRadius: '10px', padding: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
    //                     <p style={{ color: '#FFF', textAlign: 'center', fontSize: '14px' }}>Pick Turn On Date:</p>
    //                     <input type="date" min={moment().utc().add(8, 'hours').format('YYYY-MM-DD')} value={turnOnDate} onChange={(e) => {setTurnOnDate(e.target.value);}} style={{ height: '40px', width: '100%', background: '#FFF', borderRadius: '5px', border: 0, outline: 0, padding: '0px 5px' }}/>
    //                     <div style={{ height: '40px', width: '100%', display: 'flex', gap: '5px' }}>
    //                         <div style={{ flex: 1, fontSize: '14px', borderRadius: '5px', border: '1px solid #FFF', color: '#FFF', background: '#000', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => {setDisplaySchedule(false); setDisplayConfirm(true);}}>CANCEL</div>
    //                         <div style={{ flex: 1, fontSize: '14px', borderRadius: '5px', border: '1px solid #FFF', color: '#000', background: '#FFF', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => {setDisplayConfirm(false); setDisplaySchedule(false); handleCampaignStatusChange(true);}}>DONE</div>
    //                     </div>
    //                 </div>
    //             ) : (<></>)}
    //         </div>
    //     )
    // }

    // main table campaign STATUS ERROR COMPONENT
    const CampaignStatusButton = ({ state, params }) => {
        return(
            <div className='rulesSwitchContainer' title="Could not get this campaign's live status!" style={{ position: 'relative', background: 'rgba(255, 0, 0, 0.1)', cursor: 'not-allowed' }}>
                <div className="rulesSwitch" style={{ position: 'relative', background: '#98989d', cursor: 'not-allowed' }}>
                    <div className="rulesSwitchButton" style={{ left: '2.5px' }}/>
                </div>
            </div>
        )
    }

    // main table campaign LIVE STATUS component to turn on or off
    const CampaignStatusButtonLive = ({ params }) => {
        const active = (params.row.fb_live_status === 'ACTIVE') ? true : false
        const [activeLoading, setActiveLoading] = useState(false)
        const [turnOnDate, setTurnOnDate] = useState(moment().utc().add(8, 'hours').format('YYYY-MM-DD'))
        const [displayConfirm, setDisplayConfirm] = useState(false)
        const [displaySchedule, setDisplaySchedule] = useState(false)
        const url = `${host}/api/bidding/V2/off_campaign`;
        
        const handleCampaignStatusChange = async (auto) => {
            setActiveLoading(true)
            const token = Cookies.get("token");

            await axios.post(url,{
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                    "Authorization": "Bearer "+token,
                    "Content-Type": "application/json",
                },
                traffic: trafficName,
                campaign_name: params.row.campaign_name,
                campaign_id: params.row.campaign_id,
                url: params.row.campaign_ID,
                state: !active,
                turnOfDate: moment().utc().add(8, 'hours').format('YYYY-MM-DD'),
                turnOnDate: turnOnDate,
                auto: auto,
                act_id: params.row.act_id,
            })
            .then((response) => {
                console.log(response)
                if (active) {
                    const data_to_send = [
                        {
                            traffic: trafficName,
                            campaign_name: params.row.campaign_name,
                            campaign_id: params.row.campaign_id,
                            state: false,
                            act_id: params.row.act_id
                        }
                    ]
                    ruleChangeAPI(data_to_send)
                }
                setRefresh(p => { return { ...p, off_campaigns: !p.off_campaigns } })
                setActiveLoading(false)

                // manipulate table only in the frontend
                const row_index = data.campaigns.findIndex(f => f.campaign_id === params.row.campaign_id)
                setData(p => {
                    let temp = [...p.campaigns]
                    temp[row_index].off = !active
                    temp[row_index].fb_live_status = active ? 'PAUSED' : 'ACTIVE'
                    return { ...p, campaigns: temp }
                })
            })
            .catch((error) => {
                console.log(error)
                window.alert(error)
                setActiveLoading(false)
                if (error?.response?.data?.message === 'Invalid') {
                    appContext.forceLogout()
                }
            })
        }
        
        return(
            <div className='rulesSwitchContainer' style={{ position: 'relative' }}>
                {activeLoading ? (
                    <div className="loader"/>
                ) : (
                    <div className="rulesSwitch" style={{position: 'relative', background: active ? '#1976d2' : '#98989d', pointerEvents: activeLoading ? 'none' : 'all', opacity: params.row.date ? '1' : '0.5', cursor: params.row.date ? 'pointer' : 'not-allowed' }} onClick={() => params.row.date && ( active ? setDisplayConfirm(true) : handleCampaignStatusChange(false) )}>
                        <div className="rulesSwitchButton" style={{left: `${active ? '22.5px' : '2.5px' }`}}/>
                    </div>
                )}
                
                {displayConfirm ? (
                    <div style={{ position: 'absolute', zIndex: 10, top: '100%', left: 0, height: '200px', width: '200px', background: '#000', borderRadius: '10px', padding: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                        <p style={{ color: '#FFF', textAlign: 'center', fontSize: '14px' }}>Do you wanna auto turn on campaign in future?</p>
                        <div style={{ height: '40px', width: '100%', display: 'flex', gap: '5px' }}>
                            <div style={{ flex: 1, fontSize: '14px', borderRadius: '5px', border: '1px solid #FFF', color: '#FFF', background: '#000', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => {setDisplayConfirm(false); setDisplaySchedule(false); handleCampaignStatusChange(false);}}>NO</div>
                            <div style={{ flex: 1, fontSize: '14px', borderRadius: '5px', border: '1px solid #FFF', color: '#000', background: '#FFF', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => {setDisplaySchedule(true); setDisplayConfirm(false);}}>YES</div>
                        </div>
                        <div style={{ height: '40px', width: '100%', display: 'flex', gap: '5px' }}>
                            <div style={{ flex: 1, fontSize: '14px', borderRadius: '5px', border: '1px solid #FFF', color: '#FFF', background: '#000', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => {setDisplaySchedule(false); setDisplayConfirm(false);}}>CANCEL</div>
                        </div>
                    </div>
                ) : (<></>)}

                {displaySchedule ? (
                    <div style={{ position: 'absolute', zIndex: 10, top: '100%', left: 0, height: '200px', width: '200px', background: '#000', borderRadius: '10px', padding: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                        <p style={{ color: '#FFF', textAlign: 'center', fontSize: '14px' }}>Pick Turn On Date:</p>
                        <input type="date" min={moment().utc().add(8, 'hours').format('YYYY-MM-DD')} value={turnOnDate} onChange={(e) => {setTurnOnDate(e.target.value);}} style={{ height: '40px', width: '100%', background: '#FFF', borderRadius: '5px', border: 0, outline: 0, padding: '0px 5px' }}/>
                        <div style={{ height: '40px', width: '100%', display: 'flex', gap: '5px' }}>
                            <div style={{ flex: 1, fontSize: '14px', borderRadius: '5px', border: '1px solid #FFF', color: '#FFF', background: '#000', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => {setDisplaySchedule(false); setDisplayConfirm(true);}}>CANCEL</div>
                            <div style={{ flex: 1, fontSize: '14px', borderRadius: '5px', border: '1px solid #FFF', color: '#000', background: '#FFF', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => {setDisplayConfirm(false); setDisplaySchedule(false); handleCampaignStatusChange(true);}}>DONE</div>
                        </div>
                    </div>
                ) : (<></>)}
            </div>
        )
    }

    // main table campaign status component to turn on or off
    // const PulseComponent = ({ params }) => {
    //     const [pulseForm, setPulseForm] = useState({ loading: false, display_info: false, display: false, on: '1', off: '1' })

    //     useEffect(() => {
    //         setPulseForm(p => {
    //             return {
    //                 ...p,
    //                 on: params.row.active_day ? params.row.active_day.toString() : '1',
    //                 off: params.row.off_day ? params.row.off_day.toString() : '1'
    //             } 
    //         })
    //     }, [])

    //     // dismiss-class is a className that has no actual CSS functionality
    //     // if dismiss-class is clicked, it will change the state which will close the dialogs
    //     useEffect(() => {
    //         document.addEventListener('click', (e) => {
    //             if (!e.target.closest('.dismiss-class')) {
    //                 setPulseForm(p => { return { ...p, display: false } })
    //             }
    //         })
            
    //         return () => {
    //             document.removeEventListener('click', (e) => {
    //                 if (!e.target.closest('.dismiss-class')) {
    //                     setPulseForm(p => { return { ...p, display: false } })
    //                 }
    //             })
    //         }
    //     }, [])

    //     const handleBlurEvent = (variable) => {
    //         let temp_no = parseInt(pulseForm[variable] ? pulseForm[variable] : 0)
    //         if (temp_no < 1) {
    //             temp_no = 1
    //         } else if (temp_no > 30) {
    //             temp_no = 30
    //         }
    //         setPulseForm(p => { return { ...p, [variable]: temp_no.toString() } })
    //     }

    //     const handlePulseButton = async (cond) => {
    //         setPulseForm(p => { return { ...p, loading: true } })

    //         const widget = params.row.campaign_name
    //         const token = Cookies.get("token");
    //         const url_find = `${host}/api/index/search_by_id`;
    //         const url_update = `${host}/api/index/update`;
    //         const url_fetch_camp_real_time = `${host}/api/bidding/fetch_campaign_state`;
    //         const url_on_off_camp_real_time = `${host}/api/bidding/V2/off_campaign`;
        
    //         try {
    //             let live_fb_status = ""

    //             if (cond === "Activate" || cond === "Update") {
    //                 const response = await axios.post(url_fetch_camp_real_time, {
    //                     headers: {
    //                         "Access-Control-Allow-Origin": "*",
    //                         "Access-Control-Allow-Headers": "Content-Type, x-access-token",
    //                         "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
    //                         "Authorization": "Bearer "+token,
    //                         "Content-Type": "application/json",
    //                     },
    //                     data: [{ campaign_id: params.row.campaign_id, act_id: params.row.act_id }], traffic: trafficToSend
    //                 })
    
    //                 live_fb_status = response?.data?.result?.[0]?.effective_status
    //             }

    //             if (cond === "Activate") {
    //                 await axios.post(url_on_off_camp_real_time, {
    //                     headers: {
    //                         "Access-Control-Allow-Origin": "*",
    //                         "Access-Control-Allow-Headers": "Content-Type, x-access-token",
    //                         "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
    //                         "Authorization": "Bearer "+token,
    //                         "Content-Type": "application/json",
    //                     },
    //                     traffic: trafficName,
    //                     campaign_name: params.row.campaign_name,
    //                     campaign_id: params.row.campaign_id,
    //                     url: params.row.campaign_ID,
    //                     state: (live_fb_status === 'ACTIVE') ? false : true,
    //                     turnOfDate: moment().utc().add(8, 'hours').format('YYYY-MM-DD'),
    //                     turnOnDate: moment().utc().add(8, 'hours').format('YYYY-MM-DD'),
    //                     auto: false,
    //                     act_id: params.row.act_id,
    //                 })
    
    //                 if (live_fb_status === "ACTIVE") {
    //                     const data_to_send = [
    //                         {
    //                             traffic: trafficName,
    //                             campaign_name: params.row.campaign_name,
    //                             campaign_id: params.row.campaign_id,
    //                             state: false,
    //                             act_id: params.row.act_id
    //                         }
    //                     ]
    //                     ruleChangeAPI(data_to_send)
    //                 }
    //             }
                
    //             const response_find = await axios.post(url_find, {
    //                 headers: {
    //                     "Access-Control-Allow-Origin": "*",
    //                     "Access-Control-Allow-Headers": "Content-Type, x-access-token",
    //                     "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
    //                     "Authorization": "Bearer "+token,
    //                     "Content-Type": "application/json",
    //                 },
    //                 id: Number(widget)
    //             })

    //             if (!response_find?.data?.result) {
    //                 appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Widget not found in the database", content: "" }], confirm_function: () => {}, cancel_function: () => {} }})
    //             } else {
    //                 try {
    //                     let update_details = {}

    //                     if (cond === "Activate") {
    //                         update_details = {
    //                             ...response_find.data.result,
    //                             active_day: Number(pulseForm.on),
    //                             turn_on_date: (live_fb_status === 'ACTIVE') ? moment().utc().add(8, 'hours').add(Number(pulseForm.off), 'days').format('YYYY-MM-DD') : moment().utc().add(8, 'hours').add(Number(pulseForm.on)+Number(pulseForm.off), 'days').format('YYYY-MM-DD'),
    //                             off_day: Number(pulseForm.off),
    //                             turn_off_date: (live_fb_status === 'ACTIVE') ? moment().utc().add(8, 'hours').add(Number(pulseForm.on)+Number(pulseForm.off), 'days').format('YYYY-MM-DD') : moment().utc().add(8, 'hours').add(Number(pulseForm.on), 'days').format('YYYY-MM-DD'),
    //                             schedule: "true",
    //                             campaign_id: params.row.campaign_id
    //                         }
    //                     } else if (cond === "Update") {
    //                         update_details = {
    //                             ...response_find.data.result,
    //                             active_day: Number(pulseForm.on),
    //                             turn_on_date: (live_fb_status === 'ACTIVE') ? moment().utc().add(8, 'hours').add(Number(pulseForm.on)+Number(pulseForm.off), 'days').format('YYYY-MM-DD') : moment().utc().add(8, 'hours').add(Number(pulseForm.off), 'days').format('YYYY-MM-DD'),
    //                             off_day: Number(pulseForm.off),
    //                             turn_off_date: (live_fb_status === 'ACTIVE') ? moment().utc().add(8, 'hours').add(Number(pulseForm.on), 'days').format('YYYY-MM-DD') : moment().utc().add(8, 'hours').add(Number(pulseForm.on)+Number(pulseForm.off), 'days').format('YYYY-MM-DD'),
    //                             schedule: "true",
    //                             campaign_id: params.row.campaign_id
    //                         }
    //                     } else {
    //                         update_details = {
    //                             ...response_find.data.result,
    //                             schedule: "false"
    //                         }
    //                     }

    //                     const response_update = await axios.post(url_update, {
    //                         headers: {
    //                             "Access-Control-Allow-Origin": "*",
    //                             "Access-Control-Allow-Headers": "Content-Type, x-access-token",
    //                             "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
    //                             "Authorization": "Bearer "+token,
    //                             "Content-Type": "application/json",
    //                         },
    //                             data: update_details
    //                         }
    //                     )
    //                     console.log(response_update);
    //                 } catch (error) {
    //                     console.log(error);
    //                     appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Update widget failed", content: error }], confirm_function: () => {}, cancel_function: () => {} }})
    //                     if (error?.response?.data?.message === 'Invalid') {
    //                         appContext.forceLogout()
    //                     }
    //                 }
    //             }

    //             // manipulate table only in the frontend
    //             const row_index = data.campaigns.findIndex(f => f.campaign_id === params.row.campaign_id)
    //             setData(p => {
    //                 let temp = [...p.campaigns]

    //                 if (cond === "Activate") {
    //                     temp[row_index].active_day = Number(pulseForm.on)
    //                     temp[row_index].turn_on_date = (live_fb_status === 'ACTIVE') ? moment().utc().add(8, 'hours').add(Number(pulseForm.off), 'days').format('YYYY-MM-DD') : moment().utc().add(8, 'hours').add(Number(pulseForm.on)+Number(pulseForm.off), 'days').format('YYYY-MM-DD')
    //                     temp[row_index].off_day = Number(pulseForm.off)
    //                     temp[row_index].turn_off_date = (live_fb_status === 'ACTIVE') ? moment().utc().add(8, 'hours').add(Number(pulseForm.on)+Number(pulseForm.off), 'days').format('YYYY-MM-DD') : moment().utc().add(8, 'hours').add(Number(pulseForm.on), 'days').format('YYYY-MM-DD')
    //                     temp[row_index].schedule = true
    //                     temp[row_index].off = (live_fb_status === 'ACTIVE') ? false : true
    //                     temp[row_index].fb_live_status = (live_fb_status === 'ACTIVE') ? "PAUSED" : "ACTIVE"
    //                 } else if (cond === "Update") {
    //                     temp[row_index].active_day = Number(pulseForm.on)
    //                     temp[row_index].turn_on_date = (live_fb_status === 'ACTIVE') ? moment().utc().add(8, 'hours').add(Number(pulseForm.on)+Number(pulseForm.off), 'days').format('YYYY-MM-DD') : moment().utc().add(8, 'hours').add(Number(pulseForm.off), 'days').format('YYYY-MM-DD')
    //                     temp[row_index].off_day = Number(pulseForm.off)
    //                     temp[row_index].turn_off_date = (live_fb_status === 'ACTIVE') ? moment().utc().add(8, 'hours').add(Number(pulseForm.on), 'days').format('YYYY-MM-DD') : moment().utc().add(8, 'hours').add(Number(pulseForm.on)+Number(pulseForm.off), 'days').format('YYYY-MM-DD')
    //                     temp[row_index].schedule = true
    //                 } else {
    //                     temp[row_index].schedule = false
    //                 }

    //                 return { ...p, campaigns: temp }
    //             })

    //             if (offCampaign.autoTurnOnCampaignList && offCampaign?.autoTurnOnCampaignList?.find(f => f?.campaign_id === params?.row?.campaign_id)) {
    //                 const auto_row_index = offCampaign.autoTurnOnCampaignList.findIndex(f => f.campaign_id === params.row.campaign_id)
    //                 let temp_auto = [ ...offCampaign.autoTurnOnCampaignList ]
    //                 temp_auto[auto_row_index].auto = false
    //                 temp_auto[auto_row_index].turnOfDate = moment().utc().add(8, 'hours').format('YYYY-MM-DD')
    //                 temp_auto[auto_row_index].turnOnDate = moment().utc().add(8, 'hours').format('YYYY-MM-DD')
    //                 setOffCampaign(p => {
    //                     return {
    //                         ...p,
    //                         autoTurnOnCampaignList: temp_auto
    //                     }
    //                 })
    //             }
    //         } catch (error) {
    //             console.log(error);
    //             appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Find widget failed", content: error }], confirm_function: () => {}, cancel_function: () => {} }})
    //             if (error?.response?.data?.message === 'Invalid') {
    //                 appContext.forceLogout()
    //             }
    //         }

    //         setPulseForm(p => { return { ...p, display: false, loading: false } })
    //     }

    //     return(
    //         <div className="dismiss-class" style={{ position: 'relative', height: '100%', width: '100%' }}>
    //             <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0 10px', cursor: 'pointer' }}
    //                 onMouseEnter={e => { e.currentTarget.style.background = '#CCC'; setPulseForm(p => { return { ...p, display_info: true } }); }}
    //                 onMouseLeave={e => { e.currentTarget.style.background = 'transparent'; setPulseForm(p => { return { ...p, display_info: false } }); }}
    //                 onClick={() => (!pulseForm.loading && !loading.pulse_schedule) && setPulseForm(p => { return { ...p, display: !p.display } })}
    //             >
    //                 {(pulseForm.loading || loading.pulse_schedule) ? (
    //                     <div className="loader"/>
    //                 ) : (
    //                     params.row.schedule ? (
    //                         <div style={{ height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#4caf50', borderRadius: '3px' }}>
    //                             <FaCheck color={"#FFF"} size={20}/>
    //                         </div>
    //                     ) : (<>-</>)
    //                 )}
    //             </div>

    //             {/* pulse info only visible when active */}
    //             {(pulseForm.display_info && !pulseForm.display && params?.row?.schedule) && (
    //                 <div style={{ position: 'absolute', zIndex: 20, top: 0, left: '100%', height: 'fit-content', width: 'fit-content', background: '#000', borderRadius: '10px', padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center', gap: '10px' }}>
    //                     <p style={{ color: '#FFF', fontSize: '14px' }}>Pulse <span style={{ color: '#4caf50', fontSize: '14px', fontWeight: '700' }}>ACTIVE</span></p>
    //                     <div style={{ display: 'grid', gridTemplateColumns: '30px 30px 1fr', alignItems: 'center', gap: '10px' }}>
    //                         <p style={{ color: '#AAA', fontSize: '14px', whiteSpace: 'nowrap', textAlign: 'center' }}>&#8226;</p>
    //                         <p style={{ color: '#AAA', fontSize: '14px', whiteSpace: 'nowrap' }}>Day</p>
    //                         <p style={{ color: '#AAA', fontSize: '14px', whiteSpace: 'nowrap' }}>Schedule</p>
    //                     </div>
    //                     <div style={{ display: 'grid', gridTemplateColumns: '30px 30px 1fr', alignItems: 'center', gap: '10px' }}>
    //                         <p style={{ color: '#FFF', fontSize: '14px', whiteSpace: 'nowrap' }}>ON</p>
    //                         <p style={{ color: '#000', fontSize: '14px', textAlign: 'center', whiteSpace: 'nowrap', background: '#FFF', borderRadius: '5px' }}>{params.row?.active_day}</p>
    //                         <p style={{ color: '#FFF', fontSize: '14px', textAlign: 'center', whiteSpace: 'nowrap' }}>{params.row?.turn_on_date}</p>
    //                     </div>
    //                     <div style={{ display: 'grid', gridTemplateColumns: '30px 30px 1fr', alignItems: 'center', gap: '10px' }}>
    //                         <p style={{ color: '#FFF', fontSize: '14px', whiteSpace: 'nowrap' }}>OFF</p>
    //                         <p style={{ color: '#000', fontSize: '14px', textAlign: 'center', whiteSpace: 'nowrap', background: '#FFF', borderRadius: '5px' }}>{params.row?.off_day}</p>
    //                         <p style={{ color: '#FFF', fontSize: '14px', textAlign: 'center', whiteSpace: 'nowrap' }}>{params.row?.turn_off_date}</p>
    //                     </div>
    //                 </div>
    //             )}

    //             {/* pulse setup form */}
    //             {pulseForm.display ? (
    //                 <div style={{ position: 'absolute', zIndex: 10, top: '100%', left: 0, height: 'fit-content', width: '200px', background: '#000', borderRadius: '10px', padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
    //                     {pulseForm.loading ? (
    //                         <div className="loader"/>
    //                     ) : (
    //                         <>
    //                             <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }}>
    //                                 <p style={{ color: '#FFF', fontSize: '14px' }}>Pulse setup:</p>
    //                                 <div style={{ height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: "center", borderRadius: '5px', cursor: 'pointer' }}
    //                                     onMouseEnter={e => e.currentTarget.style.background = 'rgba(255, 255, 255, 0.5)'}
    //                                     onMouseLeave={e => e.currentTarget.style.background = 'transparent'}
    //                                     onClick={() => setPulseForm(p => { return { ...p, display: false } })}
    //                                 >
    //                                     <ImCross size={16} color={"#FFF"}/>
    //                                 </div>
    //                             </div>
    //                             <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }}>
    //                                 <div>
    //                                     <p style={{ color: '#FFF', fontSize: '14px' }}>ON:</p>
    //                                     <input style={{ height: '30px', width: '100%', background: 'rgba(255,255,255,0.2)', color: '#FFF', borderRadius: '5px', border: 0, outline: 0, padding: '0px 5px' }}
    //                                         type="number" value={pulseForm.on} onChange={e => setPulseForm(p => { return { ...p, on: e.target.value } })}
    //                                         placeholder="1-30" onBlur={() => handleBlurEvent('on')} onWheel={e => e.currentTarget.blur()}
    //                                     />
    //                                 </div>
    //                                 <div>
    //                                     <p style={{ color: '#FFF', fontSize: '14px' }}>OFF:</p>
    //                                     <input style={{ height: '30px', width: '100%', background: 'rgba(255,255,255,0.2)', color: '#FFF', borderRadius: '5px', border: 0, outline: 0, padding: '0px 5px' }}
    //                                         type="number" value={pulseForm.off} onChange={e => setPulseForm(p => { return { ...p, off: e.target.value } })}
    //                                         placeholder="1-30" onBlur={() => handleBlurEvent('off')} onWheel={e => e.currentTarget.blur()}
    //                                     />
    //                                 </div>
    //                             </div>
    //                             <div style={{ width: '100%', display: 'grid', gridTemplateColumns: params.row.schedule ? '1fr 1fr' : '1fr', gap: '10px' }}>
    //                                 {params.row.schedule && (
    //                                     <div style={{ height: '30px', fontSize: '14px', borderRadius: '5px', border: '1px solid #ef5350', color: '#FFF', background: '#ef5350', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
    //                                         onMouseEnter={e => e.currentTarget.style.opacity = '0.5'}
    //                                         onMouseLeave={e => e.currentTarget.style.opacity = '1'}
    //                                         onClick={() => handlePulseButton('Deactivate')}
    //                                     >
    //                                         Deactivate
    //                                     </div>
    //                                 )}
    //                                 <div style={{ height: '30px', fontSize: '14px', borderRadius: '5px', border: '1px solid #4caf50', color: '#FFF', background: '#4caf50', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
    //                                     onMouseEnter={e => e.currentTarget.style.opacity = '0.5'}
    //                                     onMouseLeave={e => e.currentTarget.style.opacity = '1'}
    //                                     onClick={() => handlePulseButton(params.row.schedule ? 'Update' : 'Activate')}
    //                                 >
    //                                     {params.row.schedule ? 'Update' : 'Activate'}
    //                                 </div>
    //                             </div>
    //                         </>
    //                     )}
    //                 </div>
    //             ) : (<></>)}
    //         </div>
    //     )
    // }

    // auto-optimization rule change API
    const ruleChangeAPI = async (rulesToSend) => {
        appContext.setRulesInfo(p => { return { ...p, singleLoading: rulesToSend.map(e => e?.campaign_name) } })
        const token = Cookies.get("token");
        const url = `${host}/api/data/rules_update`;

        await axios.post(url,{
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
            },
            data: rulesToSend
        })
        .then((response) => {
            if (response.data === "done") {
                let temp_rules = [...appContext.rulesInfo.data]
                rulesToSend.forEach(item => {
                    const fin = temp_rules.find(f => f.campaign_id === item.campaign_id)
                    if (fin) {
                        const index = temp_rules.findIndex(f => f.campaign_id === item.campaign_id)
                        temp_rules[index].state = item.state
                    } else {
                        temp_rules.push(item)
                    }
                })
                appContext.setRulesInfo(p => { return { ...p, data: temp_rules } })
                setCheckedCampaigns([])
            } else {
                appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error Updating Auto Rules", title: "Please try again. If the problem persist please contact developers.", content: "" }], confirm_function: () => {}, cancel_function: () => {} }})
            }
        })
        .catch((error) => {
            console.log(error)
            window.alert(error)
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        })
        .finally(() => appContext.setRulesInfo(p => { return { ...p, singleLoading: [] } }))
    }

    // main table auto-optimization rule component to turn on or off
    const CampaignRuleButton = ({ params }) => {
        const find_rule = appContext.rulesInfo.data.find(f => (f?.campaign_name === params.row.campaign_name) && (f?.traffic === trafficName))

        const is_pending = find_rule && find_rule.state && !find_rule.finish
        const active = find_rule ? find_rule?.state : false
        const activeLoading = appContext.rulesInfo.singleLoading.find(f => f === params.row.campaign_name)

        const handleRuleChange = () => {
            const data_to_send = [
                {
                    traffic: trafficName,
                    campaign_name: params.row.campaign_name,
                    campaign_id: params.row.campaign_id,
                    state: !active,
                    act_id: params.row.act_id
                }
            ]
            ruleChangeAPI(data_to_send)
        }
        
        return (
            (params.row.off === false && (find_rule?.state === undefined || find_rule?.state === false)) ? (
                <></>
            ) : (
                <div className='rulesSwitchContainer' style={{ position: 'relative' }}>
                    {(activeLoading || appContext.rulesInfo.loading) ? (
                        <div className="loader"/>
                    ) : (
                        <div className="rulesSwitch2" onClick={() => handleRuleChange()} style={{position: 'relative', background: `${is_pending ? "#F57D0D" : (active ? '#4caf50' : '#98989d')}`, opacity: (activeLoading || (appContext.rulesInfo.singleLoading.length > 0)) ? '50%' : '100%', pointerEvents: `${(activeLoading || (appContext.rulesInfo.singleLoading.length > 0)) ? 'none' : 'all'}` }}>
                            <div className="rulesSwitchButton2" style={{ left: `${(active) ? '25px' : '0px' }`, border: is_pending ? "2px solid #F57D0D" : (active ? "2px solid #4caf50" : "2px solid #98989d") }}>
                                {active ? (<FaCheck size={16} color={is_pending ? "#F57D0D" : "#4caf50"} />) : (<MdHorizontalRule size={16} color={is_pending ? "#F57D0D" : "#98989d"} style={{ transform: 'rotate(-45deg)' }}/>)}
                            </div>
                        </div>
                    )}
                </div>
            )
        )
    }
    
    // multiple conditions to determine which icon to show as alert
    const getAlertConditions = ({ ROI1, ROI3, ROI7, revenue, spent, campaign_id }) => {
        let result = <></>
        let yellow = false
        const link_error = ((ROI3 === 0) && (spent > 5) && (revenue === 0)) ? true : false

        // #facc15
        // #ef4444
        // #4ade80
        // #15803d
        // #fb923c
        // &#128640; - rocket
        // &#128034; - turtle

        // CASE 1
        if (ROI1 && ROI3 && ROI7) {
            // 1.1 -1.3
            if (ROI1 > 0 && ROI3 > 0) { //GG
                if (ROI1 < 50) {
                    yellow = true
                    result = <>
                        <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><TbArrowsExchange2 size={24} color="#facc15"/></div>
                        <div style={{ flex: 1, borderBottom: 'none', justifyContent: 'flex-start' }}/>
                    </>
                } //YELLOW
                if (ROI1 >= 50 && ROI1 < 80) {
                    result = <>
                        <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><PiArrowFatLinesUpFill size={24} color="#4ade80"/></div>
                        <div style={{ flex: 1, borderBottom: 'none', justifyContent: 'flex-start' }}/>
                    </>
                } //GREEN
                if (ROI1 >= 80) {
                    result = <>
                        <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><PiArrowFatUpFill size={24} color="#15803d"/></div>
                        <div style={{ flex: 1, borderBottom: 'none', justifyContent: 'flex-start' }}>&#128640;</div>
                    </>
                } //GREEN DARK
            }
            // 1.4 - 1.6
            else if (ROI1 < 0 && ROI3 > 0) { //RG
                if (ROI1 > -20) {
                    yellow = true
                    result = <>
                        <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><TbArrowsExchange2 size={24} color="#facc15"/></div>
                        <div style={{ flex: 1, borderBottom: 'none', justifyContent: 'flex-start' }}/>
                    </>
                } //YELLOW
                if (ROI1 <= -20 && ROI1 > -40) {
                    result = <>
                        <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><PiArrowFatLinesDownFill size={24} color="#fb923c"/></div>
                        <div style={{ flex: 1, borderBottom: 'none', justifyContent: 'flex-start' }}/>
                    </>
                } //ORANGE
                if (ROI1 <= -40) {
                    result = <>
                        <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><PiArrowFatDownFill size={24} color="#ef4444"/></div>
                        <div style={{ flex: 1, borderBottom: 'none', justifyContent: 'flex-start' }}/>
                    </>
                } //RED
            }
            // 1.7 - 1.8
            else if (ROI1 < 0 && ROI3 < 0 && ROI7 > 0) { //RRG
                if (ROI1 > -20) {
                    result = <>
                        <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><PiArrowFatLinesDownFill size={24} color="#fb923c"/></div>
                        <div style={{ flex: 1, borderBottom: 'none', justifyContent: 'flex-start' }}/>
                    </>
                } //ORANGE
                if (ROI1 <= -20) {
                    result = <>
                        <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><PiArrowFatDownFill size={24} color="#ef4444"/></div>
                        <div style={{ flex: 1, borderBottom: 'none', justifyContent: 'flex-start' }}/>
                    </>
                } //RED
            }
            // 1.9
            else if (ROI1 < 0 && ROI3 < 0 && ROI7 < 0) { //RRR
                if (ROI7 < -20) {
                    result = <>
                        <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><GiCancel size={24} color="#ef4444"/></div>
                        <div style={{ flex: 1, borderBottom: 'none', justifyContent: 'flex-start' }}><img src={turtleIcon} alt="turtle" style={{ width: '24px' }}/></div>
                    </> // RED TURTLE
                } else {
                    result = <>
                        <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><FaRecycle size={24} color="#fb923c"/></div>
                        <div style={{ flex: 1, borderBottom: 'none', justifyContent: 'flex-start' }}/>
                    </>
                } // ORANGE RECYCLE
            }
            // 1.10
            else if (
                (ROI1 > 0 && ROI3 < 0 && ROI7 > 0) || // GRG
                (ROI1 > 0 && ROI3 < 0 && ROI7 < 0) // GRR
            ) {
                yellow = true
                result = <>
                    <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><TbArrowsExchange2 size={24} color="#facc15"/></div>
                    <div style={{ flex: 1, borderBottom: 'none', justifyContent: 'flex-start' }}/>
                </>
            }//YELLOW
        }

        // CASE 2
        else if (ROI1 && ROI3) {
            if (link_error) {
                result = <>
                    <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><FaLinkSlash size={24} color="#ef4444"/></div>
                    <div style={{ flex: 1, borderBottom: 'none', justifyContent: 'flex-start', fontSize: '10px', fontWeight: '700', color: '#ef4444' }}>Link Error</div>
                </>
            }
            // 2.1
            else if (ROI1 > 0 && ROI3 > 0) { // GG
                if (ROI1 >= 50) {
                    result = <>
                        <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><PiArrowFatLinesUpFill size={24} color="#4ade80"/></div>
                        <div style={{ flex: 1, borderBottom: 'none', justifyContent: 'flex-start' }}/>
                    </>
                } //GREEN
                if (ROI1 < 50) {
                    yellow = true
                    result = <>
                        <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><TbArrowsExchange2 size={24} color="#facc15"/></div>
                        <div style={{ flex: 1, borderBottom: 'none', justifyContent: 'flex-start' }}/>
                    </>
                } //YELLOW
            } else if (ROI1 < 0 && ROI3 < 0) { // RR
                if (ROI1 < -50 && ROI3 < -50) {
                    result = <>
                        <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><GiCancel size={24} color="#ef4444"/></div>
                        <div style={{ flex: 1, borderBottom: 'none', justifyContent: 'flex-start' }}><img src={turtleIcon} alt="turtle" style={{ width: '24px' }}/></div>
                    </>
                } //RED TURTLE
                if (ROI1 < -50 && ROI3 > -50) {
                    result = <>
                        <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><PiArrowFatDownFill size={24} color="#ef4444"/></div>
                        <div style={{ flex: 1, borderBottom: 'none', justifyContent: 'flex-start' }}/>
                    </>
                } //RED
                if (ROI1 > -50 && ROI3 > -50) {
                    yellow = true
                    result = <>
                        <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><TbArrowsExchange2 size={24} color="#facc15"/></div>
                        <div style={{ flex: 1, borderBottom: 'none', justifyContent: 'flex-start' }}/>
                    </>
                } //YELLOW
                if (ROI1 > -50 && ROI3 < -50) {
                    result = <>
                        <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><PiArrowFatLinesDownFill size={24} color="#fb923c"/></div>
                        <div style={{ flex: 1, borderBottom: 'none', justifyContent: 'flex-start' }}/>
                    </>
                } //ORANGE
            } else {
                yellow = true
                result = <>
                    <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><TbArrowsExchange2 size={24} color="#facc15"/></div>
                    <div style={{ flex: 1, borderBottom: 'none', justifyContent: 'flex-start' }}/>
                </>
            } //YELLOW;
        }
        
        // BABY Condition
        else {
            if ((ROI1 && ROI1 != 0) || (spent >= 5 && ROI3 === null)) {
                result = <>
                    <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><img src={crawlingBaby} alt="crawlingBaby" style={{ width: '24px' }}/></div>
                    <div style={{ flex: 1, borderBottom: 'none', justifyContent: 'flex-start' }}/>
                </>
            } else {
                result = <>-</>
            }
        }

        return { result, yellow }
    }

    // if alert is yellow then run this condition with trend values
    const yellowAlertTrendCondition = ({ client, ads, camp }) => {
        let result = <>
                <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><TbArrowsExchange2 size={24} color="#facc15"/></div>
                <div style={{ flex: 1, borderBottom: 'none', justifyContent: 'flex-start' }}/>
            </>

        if (client === "R" && ads === "R") {
            if (camp === "G") {
                result = <>
                    <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><PiArrowFatLinesDownFill size={24} color="#fb923c"/></div>
                    <div style={{ flex: 1, borderBottom: 'none', justifyContent: 'flex-start' }}/>
                </>
            } else {
                result = <>
                    <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><PiArrowFatDownFill size={24} color="#ef4444"/></div>
                    <div style={{ flex: 1, borderBottom: 'none', justifyContent: 'flex-start' }}/>
                </>
            }
        } else if (client === "G" && ads === "G") {
            if (camp === "R") {
                result = <>
                    <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><PiArrowFatLinesUpFill size={24} color="#4ade80"/></div>
                    <div style={{ flex: 1, borderBottom: 'none', justifyContent: 'flex-start' }}/>
                </>
            } else {
                result = <>
                    <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><PiArrowFatUpFill size={24} color="#15803d"/></div>
                    <div style={{ flex: 1, borderBottom: 'none', justifyContent: 'flex-start' }}>&#128640;</div>
                </>
            }
        } else if (client === "R" && ads === "G" && camp === "G") {
            result = <>
                <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><PiArrowFatLinesUpFill size={24} color="#4ade80"/></div>
                <div style={{ flex: 1, borderBottom: 'none', justifyContent: 'flex-start' }}/>
            </>
        } else if (client === "G" && ads === "R" && camp === "R") {
            result = <>
                <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><PiArrowFatLinesDownFill size={24} color="#fb923c"/></div>
                <div style={{ flex: 1, borderBottom: 'none', justifyContent: 'flex-start' }}/>
            </>
        }

        return result
    };

    // Custom datagrid footer component
    const CustomDatagridFooter = () => {
        return (
            <GridFooterContainer>
                {(account === 'all') ? (<p></p>) : (<p style={{ fontSize: '16px', color: "#4caf50", paddingLeft: "10px" }}>&#x25cf; {data.campaigns.filter(f => f.fb_live_status === "ACTIVE").length} Active</p>)}
                <GridFooter sx={{ border: 'none' }}/>
            </GridFooterContainer>
        )
    }

    // total table rows with rules mapped inside
    const totalRows = data.campaigns.map(e => {
        const finding = appContext.rulesInfo.data.find(f => f?.campaign_id === e.campaign_id)
        if (finding) {
            e.auto = finding.state
        }
        return e
    })

    // total table columns with configurations
    const totalColumns = [
        { field: 'id', headerName: 'ID', hide: true},
        { field: 'check', headerName: 'Select', width: 80, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const camp = params.row.campaign_name
                return (
                    <div className='rulesSwitchContainer' style={{ padding: '10px 0px' }}>
                        <input type="checkbox" style={{ height: '30px', width: '30px' }} checked={(checkedCampaigns.find(f => f === camp)) ? true : false} onChange={e => handleChangeCheckBox({e, camp})} />
                    </div>
                )
            }},
        // { field: 'pulse', headerName: 'Pulse', hide: (Cookies.get('role') !== 'admin'), minWidth: 80, flex: 1, headerClassName: 'datagridHeader',
        //     renderCell: (params) => {
        //         return ((Cookies.get('role') === 'admin') && params.row.date) ? <PulseComponent params={params}/> : <></>
        //     }},
        { field: 'auto', headerName: 'Auto', minWidth: 80, flex: 1, headerClassName: 'datagridHeader',
            renderCell: (params) => {
                return params.row.date ? <CampaignRuleButton params={params}/> : <></>
            }},
        { field: 'off', headerName: 'Status', minWidth: 80, hide: (account === 'all'), flex: 1, headerClassName: 'datagridHeader',
            renderCell: (params) => {
                let state = (params.row.off === false) ? false : true
                return (
                    loading.fb_live_status ? (
                        <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div className="loader"/>
                        </div>
                    ) : (
                        (params.row.fb_live_status === "ERROR") ? <CampaignStatusButton state={state} params={params}/> : <CampaignStatusButtonLive params={params}/>
                    )
                )
            }},
        { field: 'campaign_name', headerName: 'Campaign', minWidth: 400, flex: 4, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                let campaign = params.row.campaign_name
                let spent = Number(params.row.todaySpend)
                return(
                    <>
                        {(typeof username !== 'undefined') ? (
                            (username !== '') ? (
                                ((username.split(',').length < 2) && params.row.date) ? (
                                    <Settings cell={params} spent={spent}/>
                                ) : (
                                    <div className="total-settings" style={{ padding: '0px 10px' }}>{campaign}</div>
                                )
                            ) : (
                                <div className="total-settings" style={{ padding: '0px 10px' }}>{campaign}</div>
                            )
                        ) : (
                            <div className="total-settings" style={{ padding: '0px 10px' }}>{campaign}</div>
                        )}
                    </>
                )
            }},
        { field: 'alert', headerName: 'Alerts', minWidth: (selectedCampaign === '') ? 80 : 160, flex: 1, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const ROI1 = params.row["todayROI"]
                const ROI3 = params.row["last3DaysROI"]
                const ROI7 = params.row["last7DaysROI"]
                const revenue = Number(params.row.revenue)
                const spent = Number(params.row.spent)
                const todaySpend = params.row.todaySpend
                const campaign = params.row.campaign_name
                const campaign_id = params.row.campaign_id
                return (
                    // (params?.row?.schedule && (Cookies.get('role') === 'admin')) ? (
                    //     <div className="total-settings-grid-adset" style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    //         <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><IoPulseSharp size={26} color={moment(params?.row?.turn_on_date).isAfter(moment(params?.row?.turn_off_date)) ? "#ef5350" : "#4caf50"}/></div>
                    //         <div style={{ flex: 1, borderBottom: 'none' }}/>
                    //     </div>
                    // ) : (
                    // )
                    (campaign === selectedCampaign && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                        <div className="total-settings-grid">
                            <div className="total-settings-grid-adset" style={{ height: '80px', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', fontSize: '20px' }}>
                                {todaySpend ? (
                                    getAlertConditions({ ROI1, ROI3, ROI7, revenue, spent, campaign_id }).yellow ? (
                                        loading.single_campaign ? (
                                            <div><div className="loader" style={{ height: '20px', width: '20px' }}/></div>
                                        ) : (
                                            yellowAlertTrendCondition({
                                                client: (getSlope(chartDataSingle.CPA) > 0) ? "G" : "R",
                                                ads: (getSlope(chartDataSingle.CPL) > 0) ? "R" : "G",
                                                camp: (getSlope(chartDataSingle.CPtrend) > 0) ? "G" : "R"
                                            })
                                        )
                                    ) : (
                                        getAlertConditions({ ROI1, ROI3, ROI7, revenue, spent, campaign_id }).result
                                    )
                                ) : (<></>)}
                            </div>
                            <div className="total-settings-grid-trend">
                                <div>Trend</div>
                                <div>
                                    <div>
                                        <div style={{ color: "#4caf50" }}>Client</div>
                                        {loading.single_campaign ? (
                                            <div><div className="loader" style={{ height: '20px', width: '20px' }}/></div>
                                        ) : (
                                            <div>
                                                {(getSlope(chartDataSingle.CPA) > 0) ? <FaThumbsUp size={14} color={"#4caf50"}/> : <FaThumbsDown size={14} color={"#ef5350"}/>}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <div style={{ color: "#ef5350" }}>Ads</div>
                                        {loading.single_campaign ? (
                                            <div><div className="loader" style={{ height: '20px', width: '20px' }}/></div>
                                        ) : (
                                            <div>
                                                {(getSlope(chartDataSingle.CPL) > 0) ? <FaThumbsDown size={14} color={"#ef5350"}/> : <FaThumbsUp size={14} color={"#4caf50"}/>}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="total-settings-grid-adset" style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', fontSize: '20px' }}>
                            {todaySpend ? getAlertConditions({ ROI1, ROI3, ROI7, revenue, spent, campaign_id }).result : <></>}
                        </div>
                    )
                )
            }},
        { field: 'revenue', headerName: 'Revenue', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'firstBlock firstBlockLeftBorder', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                let revenue = params.row.revenue
                let campaign = params.row.campaign_name
                let temp = (trafficToSend === 'TikTok') ? (settingsData.week_result ? settingsData.week_result : []) : settingsData
                return (
                    <>
                        {(clientName === 'All') ? (
                            (campaign === selectedCampaign && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                                <div className="total-settings-grid">
                                    <div className="total-settings-grid-adset">
                                        <div style={{ position: 'relative', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onMouseEnter={e => e.currentTarget.lastChild.style.display = 'flex'} onMouseLeave={e => e.currentTarget.lastChild.style.display = 'none'} >
                                            <p style={{ textAlign: 'center' }}>$ {revenue?.all?.toFixed(0)}</p>
                                            <div style={{ position: 'absolute', zIndex: 10, top: '100%', background: 'white', display: 'none', flexDirection: 'column', alignItems: 'start', gap: '5px', padding: '10px', borderRadius: '10px', boxShadow: '0px 2px 4px rgba(0,0,0,0.2)' }}>
                                                {revenue?.sedo ? (<p style={{ whiteSpace: 'nowrap' }}>Sedo: ${revenue?.sedo?.toFixed(0)}</p>) : (<></>)}
                                                {revenue?.tonic ? (<p style={{ whiteSpace: 'nowrap' }}>Tonic: ${revenue?.tonic?.toFixed(0)}</p>) : (<></>)}
                                                {revenue?.system1 ? (<p style={{ whiteSpace: 'nowrap' }}>System1: ${revenue?.system1?.toFixed(0)}</p>) : (<></>)}
                                                {revenue?.crossroads ? (<p style={{ whiteSpace: 'nowrap' }}>Crossroads: ${revenue?.crossroads?.toFixed(0)}</p>) : (<></>)}
                                                {revenue?.adcombo ? (<p style={{ whiteSpace: 'nowrap' }}>Adcombo: ${revenue?.adcombo?.toFixed(0)}</p>) : (<></>)}
                                                {revenue?.bodis ? (<p style={{ whiteSpace: 'nowrap' }}>Bodis: ${revenue?.bodis?.toFixed(0)}</p>) : (<></>)}
                                            </div>
                                        </div>
                                        {temp.filter(f => f.campaign_name === campaign).map((e, i) => (
                                            <div key={i}>---</div>
                                        ))}
                                    </div>
                                    <div className="total-settings-grid-camp">
                                        {loading.single_campaign ? (
                                            <div>
                                                <div>
                                                    <div className="loader" style={{ height: '20px', width: '20px' }}/>
                                                </div>
                                            </div>
                                        ) : (
                                            campaignDataSingle?.map((e, i) => (
                                                <div key={i} style={{ background: checkCampDate(e.date) ? 'transparent' : '#FFFF99' }}>
                                                    <div>$ {e.revenue?.toFixed(0)}</div>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div style={{ position: 'relative', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onMouseEnter={e => e.currentTarget.lastChild.style.display = 'flex'} onMouseLeave={e => e.currentTarget.lastChild.style.display = 'none'} >
                                    <p style={{ width: '100%', textAlign: 'left' }}>$ {revenue?.all?.toFixed(0)}</p>
                                    <div style={{ position: 'absolute', zIndex: 10, top: '100%', background: 'white', display: 'none', flexDirection: 'column', gap: '5px', padding: '10px', borderRadius: '10px', boxShadow: '0px 2px 4px rgba(0,0,0,0.2)' }}>
                                        {revenue?.sedo ? (<p style={{ whiteSpace: 'nowrap' }}>Sedo: ${revenue?.sedo?.toFixed(0)}</p>) : (<></>)}
                                        {revenue?.tonic ? (<p style={{ whiteSpace: 'nowrap' }}>Tonic: ${revenue?.tonic?.toFixed(0)}</p>) : (<></>)}
                                        {revenue?.system1 ? (<p style={{ whiteSpace: 'nowrap' }}>System1: ${revenue?.system1?.toFixed(0)}</p>) : (<></>)}
                                        {revenue?.crossroads ? (<p style={{ whiteSpace: 'nowrap' }}>Crossroads: ${revenue?.crossroads?.toFixed(0)}</p>) : (<></>)}
                                        {revenue?.adcombo ? (<p style={{ whiteSpace: 'nowrap' }}>Adcombo: ${revenue?.adcombo?.toFixed(0)}</p>) : (<></>)}
                                        {revenue?.bodis ? (<p style={{ whiteSpace: 'nowrap' }}>Bodis: ${revenue?.bodis?.toFixed(0)}</p>) : (<></>)}
                                    </div>
                                </div>
                            )
                        ) : (
                            (campaign === selectedCampaign && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                                <div className="total-settings-grid">
                                    <div className="total-settings-grid-adset">
                                        <div>{revenue ? '$ '+revenue?.toFixed(0) : '-'}</div>
                                        {temp.filter(f => f.campaign_name === campaign).map((e, i) => (
                                            <div key={i}>---</div>
                                        ))}
                                    </div>
                                    <div className="total-settings-grid-camp">
                                        {loading.single_campaign ? (
                                            <div>
                                                <div>
                                                    <div className="loader" style={{ height: '20px', width: '20px' }}/>
                                                </div>
                                            </div>
                                        ) : (
                                            campaignDataSingle?.map((e, i) => (
                                                <div key={i} style={{ background: checkCampDate(e.date) ? 'transparent' : '#FFFF99' }}>
                                                    <div>$ {e.revenue?.toFixed(0)}</div>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="total-settings-grid-adset">{revenue ? '$ '+revenue?.toFixed(0) : '-'}</div>
                            )
                        )}
                    </>
                )
            },
            valueGetter: (params) => parseFloat(params.row.revenue),
        },
        { field: 'spent', headerName: 'Spent', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'firstBlock', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                let spent = Number(params.row.spent)
                let campaign = params.row.campaign_name
                let temp = (trafficToSend === 'TikTok') ? (settingsData.today_result ? settingsData.today_result : []) : settingsData
                return(
                    <>
                        {(campaign === selectedCampaign && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                            <div className="total-settings-grid">
                                <div className="total-settings-grid-adset">
                                    <div>{spent ? '$ '+spent?.toFixed(0) : '-'}</div>
                                    {temp.filter(f => f.campaign_name === campaign).map((e, i) => (
                                        <div key={i}>$ {(e.spend * 1).toFixed(0)}</div>
                                    ))}
                                </div>
                                <div className="total-settings-grid-camp">
                                    {loading.single_campaign ? (
                                        <div>
                                            <div>
                                                <div className="loader" style={{ height: '20px', width: '20px' }}/>
                                            </div>
                                        </div>
                                    ) : (
                                        campaignDataSingle?.map((e, i) => (
                                            <div key={i} style={{ background: checkCampDate(e.date) ? 'transparent' : '#FFFF99' }}>
                                                <div>$ {e.spent?.toFixed(0)}</div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="total-settings-grid-adset">{spent ? '$ '+spent?.toFixed(0) : '-'}</div>
                        )}
                    </>
                )
            }},
        { field: 'result', headerName: 'Result', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'firstBlock', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const resultData = Number(params.row.result)
                let campaign = params.row.campaign_name
                let temp = (trafficToSend === 'TikTok') ? (settingsData.week_result ? settingsData.week_result : []) : settingsData
                return(
                    <>
                        {(campaign === selectedCampaign && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                            <div className="total-settings-grid">
                                <div className="total-settings-grid-adset">
                                    <div style={{color: (resultData > 0) ? '#4caf50' : '#ef5350'}}>{resultData ? '$ '+resultData?.toFixed(0) : '-'}</div>
                                    {temp.filter(f => f.campaign_name === campaign).map((e, i) => (
                                        <div key={i}>---</div>
                                    ))}
                                </div>
                                <div className="total-settings-grid-camp">
                                    {loading.single_campaign ? (
                                        <div>
                                            <div>
                                                <div className="loader" style={{ height: '20px', width: '20px' }}/>
                                            </div>
                                        </div>
                                    ) : (
                                        campaignDataSingle?.map((e, i) => (
                                            <div key={i} style={{ background: checkCampDate(e.date) ? 'transparent' : '#FFFF99' }}>
                                                <div style={{ color: (e.result > 0) ? '#4caf50' : '#ef5350' }}>$ {e.result?.toFixed(0)}</div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="total-settings-grid-adset" style={{color: (resultData > 0) ? '#4caf50' : '#ef5350'}}>{resultData ? '$ '+resultData?.toFixed(0) : '-'}</div>
                        )}
                    </>
                )
            }},
        { field: 'ROI%', headerName: 'ROI', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'firstBlock', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const ROIdata = Number(params.row["ROI%"])
                let campaign = params.row.campaign_name
                let temp = (trafficToSend === 'TikTok') ? (settingsData.week_result ? settingsData.week_result : []) : settingsData
                return(
                    <>
                        {(campaign === selectedCampaign && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                            <div className="total-settings-grid">
                                <div className="total-settings-grid-adset">
                                    <div style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{ROIdata ? ROIdata?.toFixed(0)+' %' : '-'}</div>
                                    {temp.filter(f => f.campaign_name === campaign).map((e, i) => (
                                        <div key={i}>---</div>
                                    ))}
                                </div>
                                <div className="total-settings-grid-camp">
                                    {loading.single_campaign ? (
                                        <div>
                                            <div>
                                                <div className="loader" style={{ height: '20px', width: '20px' }}/>
                                            </div>
                                        </div>
                                    ) : (
                                        campaignDataSingle?.map((e, i) => (
                                            <div key={i} style={{ background: checkCampDate(e.date) ? 'transparent' : '#FFFF99' }}>
                                                <div style={{ color: (e["ROI%"] > 0) ? '#4caf50' : '#ef5350' }}>{e["ROI%"]?.toFixed(0)} %</div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="total-settings-grid-adset" style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{ROIdata ? ROIdata?.toFixed(0)+' %' : '-'}</div>
                        )}
                    </>
                )
            }},
        { field: 'todayROI', headerName: 'ROI(1)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'secondBlock secondBlockLeftBorder', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const ROIdata = params.row["todayROI"]
                let campaign = params.row.campaign_name
                let temp = (trafficToSend === 'TikTok') ? (settingsData.week_result ? settingsData.week_result : []) : settingsData
                return(
                    <>
                        {(campaign === selectedCampaign && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                            <div className="total-settings-grid">
                                <div className="total-settings-grid-adset">
                                    <div style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{(ROIdata === "-" || !ROIdata) ? ((ROIdata === 0) ? '0' : '-') : (ROIdata*1).toFixed(0)+'%'}</div>
                                    {temp.filter(f => f.campaign_name === campaign).map((e, i) => (
                                        <div key={i}>---</div>
                                    ))}
                                </div>
                                <div className="total-settings-grid-camp" style={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px', borderRight: '2px solid #10b981' }}>
                                    {loading.single_campaign ? (
                                        <div>
                                            <div>
                                                <div className="loader" style={{ height: '20px', width: '20px' }}/>
                                            </div>
                                        </div>
                                    ) : (
                                        campaignDataSingle?.map((e, i) => (
                                            <div key={i} style={{ background: checkCampDate(e.date) ? 'transparent' : '#FFFF99' }}>
                                                <div style={{ background: checkCampDate(e.date) ? 'transparent' : '#FFFF99', fontSize: '12px' }}>{e.date}</div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="total-settings-grid-adset" style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{(ROIdata === "-" || !ROIdata) ? ((ROIdata === 0) ? '0' : '-') : (ROIdata*1).toFixed(0)+'%'}</div>
                        )}
                    </>
                )
            }},
        { field: 'last3DaysROI', headerName: 'ROI(3)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'secondBlock', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const ROIdata = params.row["last3DaysROI"]
                let campaign = params.row.campaign_name
                let temp = (trafficToSend === 'TikTok') ? (settingsData.week_result ? settingsData.week_result : []) : settingsData
                return(
                    <>
                        {(campaign === selectedCampaign && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                            <div className="total-settings-grid">
                                <div className="total-settings-grid-adset">
                                    <div style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{(ROIdata === "-" || !ROIdata) ? ((ROIdata === 0) ? '0' : '-') : (ROIdata*1).toFixed(0)+'%'}</div>
                                    {temp.filter(f => f.campaign_name === campaign).map((e, i) => (
                                        <div key={i}>---</div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="total-settings-grid-adset" style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{(ROIdata === "-" || !ROIdata) ? ((ROIdata === 0) ? '0' : '-') : (ROIdata*1).toFixed(0)+'%'}</div>
                        )}
                    </>
                )
            }},
        { field: 'last7DaysROI', headerName: 'ROI(7)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'secondBlock', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const ROIdata = params.row["last7DaysROI"]
                let campaign = params.row.campaign_name
                let temp = (trafficToSend === 'TikTok') ? (settingsData.week_result ? settingsData.week_result : []) : settingsData
                return(
                    <>
                        {(campaign === selectedCampaign && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                            <div className="total-settings-grid">
                                <div className="total-settings-grid-adset">
                                    <div style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{(ROIdata === "-" || !ROIdata) ? ((ROIdata === 0) ? '0' : '-') : (ROIdata*1).toFixed(0)+'%'}</div>
                                    {temp.filter(f => f.campaign_name === campaign).map((e, i) => (
                                        <div key={i}>---</div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="total-settings-grid-adset" style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{(ROIdata === "-" || !ROIdata) ? ((ROIdata === 0) ? '0' : '-') : (ROIdata*1).toFixed(0)+'%'}</div>
                        )}
                    </>
                )
            }},
        { field: 'last14DaysROI', headerName: 'ROI(14)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'secondBlock', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const ROIdata = params.row["last14DaysROI"]
                let campaign = params.row.campaign_name
                let temp = (trafficToSend === 'TikTok') ? (settingsData.week_result ? settingsData.week_result : []) : settingsData
                return(
                    <>
                        {(campaign === selectedCampaign && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                            <div className="total-settings-grid">
                                <div className="total-settings-grid-adset">
                                    <div style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{(ROIdata === "-" || !ROIdata) ? ((ROIdata === 0) ? '0' : '-') : (ROIdata*1).toFixed(0)+'%'}</div>
                                    {temp.filter(f => f.campaign_name === campaign).map((e, i) => (
                                        <div key={i}>---</div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="total-settings-grid-adset" style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{(ROIdata === "-" || !ROIdata) ? ((ROIdata === 0) ? '0' : '-') : (ROIdata*1).toFixed(0)+'%'}</div>
                        )}
                    </>
                )
            }},
        { field: 'last30DaysROI', headerName: 'ROI(30)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'secondBlock', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const ROIdata = params.row["last30DaysROI"]
                let campaign = params.row.campaign_name
                let temp = (trafficToSend === 'TikTok') ? (settingsData.week_result ? settingsData.week_result : []) : settingsData
                return(
                    <>
                        {(campaign === selectedCampaign && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                            <div className="total-settings-grid">
                                <div className="total-settings-grid-adset">
                                    <div style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{(ROIdata === "-" || !ROIdata) ? ((ROIdata === 0) ? '0' : '-') : (ROIdata*1).toFixed(0)+'%'}</div>
                                    {temp.filter(f => f.campaign_name === campaign).map((e, i) => (
                                        <div key={i}>---</div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="total-settings-grid-adset" style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{(ROIdata === "-" || !ROIdata) ? ((ROIdata === 0) ? '0' : '-') : (ROIdata*1).toFixed(0)+'%'}</div>
                        )}
                    </>
                )
            }},
        { field: 'cpc', headerName: 'CPC', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'thirdBlock thirdBlockLeftBorder', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                // CPC is gotten from ad level API
                let campaign = params.row.campaign_name
                let temp = (trafficToSend === 'TikTok') ? (settingsData.week_result ? settingsData.week_result : []) : settingsData
                let average_cpc = temp?.filter(f => (f?.campaign_name === campaign) && f.cost_per_unique_inline_link_click)?.map(e => Number(e?.cost_per_unique_inline_link_click))?.reduce((a, b) => a + b, 0) / temp?.filter(f => (f?.campaign_name === campaign) && f?.cost_per_unique_inline_link_click)?.length
                return(
                    <>
                        {(campaign === selectedCampaign && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                            <div className="total-settings-grid">
                                <div className="total-settings-grid-adset">
                                    <div>{average_cpc ? average_cpc?.toFixed(3) : '-'}</div>
                                    {temp.filter(f => f.campaign_name === campaign).map((e, i) => (
                                        <div key={i}>{e.cost_per_unique_inline_link_click ? (Number(e.cost_per_unique_inline_link_click).toFixed(3)) : '-'}</div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="total-settings-grid-adset">{average_cpc ? average_cpc?.toFixed(3) : '-'}</div>
                        )}
                    </>
                )
            }},
        { field: 'cost_per_conversion', headerName: 'CPL', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'thirdBlock', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                // CPL is gotten from ad level API
                let campaign = params.row.campaign_name
                let temp = (trafficToSend === 'TikTok') ? (settingsData.week_result ? settingsData.week_result : []) : settingsData
                let average_cpl = temp?.filter(f => (f?.campaign_name === campaign) && f.cpl)?.map(e => Number(e?.cpl))?.reduce((a, b) => a + b, 0) / temp?.filter(f => (f?.campaign_name === campaign) && f?.cpl)?.length
                return(
                    <>
                        {(campaign === selectedCampaign && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                            <div className="total-settings-grid">
                                <div className="total-settings-grid-adset">
                                    <div>{average_cpl ? average_cpl?.toFixed(3) : '-'}</div>
                                    {temp.filter(f => f.campaign_name === campaign).map((e, i) => (
                                        <div key={i}>{e.cpl ? (Number(e.cpl).toFixed(3)) : '-'}</div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="total-settings-grid-adset">{average_cpl ? average_cpl?.toFixed(3) : '-'}</div>
                        )}
                    </>
                )
            }},
    ];

    // total table columns with configurations for southpow only
    const southpowTotalColumns = [
        { field: 'id', headerName: 'ID', hide: true},
        { field: 'check', headerName: 'Select', width: 80, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const camp = params.row.campaign_name
                return (
                    <div className='rulesSwitchContainer'>
                        <input type="checkbox" style={{ height: '30px', width: '30px' }} checked={(checkedCampaigns.find(f => f === camp)) ? true : false} onChange={e => handleChangeCheckBox({e, camp})} />
                    </div>
                )
            }},
        // { field: 'pulse', headerName: 'Pulse', hide: (Cookies.get('role') !== 'admin'), minWidth: 80, flex: 1, headerClassName: 'datagridHeader',
        //     renderCell: (params) => {
        //         return ((Cookies.get('role') === 'admin') && params.row.date) ? <PulseComponent params={params}/> : <></>
        //     }},
        { field: 'auto', headerName: 'Auto', minWidth: 80, flex: 1, headerClassName: 'datagridHeader',
            renderCell: (params) => {
                return params.row.date ? <CampaignRuleButton params={params}/> : <></>
            }},
        { field: 'off', headerName: 'Status', minWidth: 80, hide: (account === 'all'), flex: 1, headerClassName: 'datagridHeader',
            renderCell: (params) => {
                let state = (params.row.off === false) ? false : true
                return (
                    loading.fb_live_status ? (
                        <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div className="loader"/>
                        </div>
                    ) : (
                        (params.row.fb_live_status === "ERROR") ? <CampaignStatusButton state={state} params={params}/> : <CampaignStatusButtonLive params={params}/>
                    )
                )
            }},
        { field: 'campaign_name', headerName: 'Campaign', minWidth: 400, flex: 4, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                let campaign = params.row.campaign_name
                let spent = Number(params.row.todaySpend)
                return(
                    <>
                        {(typeof username !== 'undefined') ? (
                            (username !== '') ? (
                                ((username.split(',').length < 2) && params.row.date) ? (
                                    <Settings cell={params} spent={spent}/>
                                ) : (
                                    <div className="total-settings" style={{ padding: '0px 10px' }}>{campaign}</div>
                                )
                            ) : (
                                <div className="total-settings" style={{ padding: '0px 10px' }}>{campaign}</div>
                            )
                        ) : (
                            <div className="total-settings" style={{ padding: '0px 10px' }}>{campaign}</div>
                        )}
                    </>
                )
            }},
        { field: 'alert', headerName: 'Alerts', minWidth: (selectedCampaign === '') ? 80 : 160, flex: 1, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const ROI1 = params.row["todayROI"]
                const ROI3 = params.row["last3DaysROI"]
                const ROI7 = params.row["last7DaysROI"]
                const revenue = Number(params.row.revenue)
                const spent = Number(params.row.spent)
                const todaySpend = params.row.todaySpend
                const campaign = params.row.campaign_name
                const campaign_id = params.row.campaign_id
                return (
                    // (params?.row?.schedule && (Cookies.get('role') === 'admin')) ? (
                    //     <div className="total-settings-grid-adset" style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    //         <div style={{ flex: 1, height: 'fit-content', justifyContent: 'flex-end', borderBottom: 'none' }}><IoPulseSharp size={26} color={moment(params?.row?.turn_on_date).isAfter(moment(params?.row?.turn_off_date)) ? "#ef5350" : "#4caf50"}/></div>
                    //         <div style={{ flex: 1, borderBottom: 'none' }}/>
                    //     </div>
                    // ) : (
                    // )
                    (campaign === selectedCampaign && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                        <div className="total-settings-grid">
                            <div className="total-settings-grid-adset" style={{ height: '80px', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', fontSize: '20px' }}>
                                {todaySpend ? (
                                    getAlertConditions({ ROI1, ROI3, ROI7, revenue, spent, campaign_id }).yellow ? (
                                        loading.single_campaign ? (
                                            <div><div className="loader" style={{ height: '20px', width: '20px' }}/></div>
                                        ) : (
                                            yellowAlertTrendCondition({
                                                client: (getSlope(chartDataSingle.CPA) > 0) ? "G" : "R",
                                                ads: (getSlope(chartDataSingle.CPL) > 0) ? "R" : "G",
                                                camp: (getSlope(chartDataSingle.CPtrend) > 0) ? "G" : "R"
                                            })
                                        )
                                    ) : (
                                        getAlertConditions({ ROI1, ROI3, ROI7, revenue, spent, campaign_id }).result
                                    )
                                ) : (<></>)}
                            </div>
                            <div className="total-settings-grid-trend">
                                <div>Trend</div>
                                <div>
                                    <div>
                                        <div style={{ color: "#4caf50" }}>Client</div>
                                        {loading.single_campaign ? (
                                            <div><div className="loader" style={{ height: '20px', width: '20px' }}/></div>
                                        ) : (
                                            <div>
                                                {(getSlope(chartDataSingle.CPA) > 0) ? <FaThumbsUp size={14} color={"#4caf50"}/> : <FaThumbsDown size={14} color={"#ef5350"}/>}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <div style={{ color: "#ef5350" }}>Ads</div>
                                        {loading.single_campaign ? (
                                            <div><div className="loader" style={{ height: '20px', width: '20px' }}/></div>
                                        ) : (
                                            <div>
                                                {(getSlope(chartDataSingle.CPL) > 0) ? <FaThumbsDown size={14} color={"#ef5350"}/> : <FaThumbsUp size={14} color={"#4caf50"}/>}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="total-settings-grid-adset" style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', fontSize: '20px' }}>
                            {todaySpend ? getAlertConditions({ ROI1, ROI3, ROI7, revenue, spent, campaign_id }).result : <></>}
                        </div>
                    )
                )
            }},
        { field: 'revenue', headerName: 'Revenue', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'firstBlock firstBlockLeftBorder', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                let revenue = params.row.revenue
                let campaign = params.row.campaign_name
                let temp = (trafficToSend === 'TikTok') ? (settingsData.week_result ? settingsData.week_result : []) : settingsData
                return (
                    <>
                        {(clientName === 'All') ? (
                            (campaign === selectedCampaign && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                                <div className="total-settings-grid">
                                    <div className="total-settings-grid-adset">
                                        <div style={{ position: 'relative', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onMouseEnter={e => e.currentTarget.lastChild.style.display = 'flex'} onMouseLeave={e => e.currentTarget.lastChild.style.display = 'none'} >
                                            <p style={{ textAlign: 'center' }}>$ {revenue?.all?.toFixed(0)}</p>
                                            <div style={{ position: 'absolute', zIndex: 10, top: '100%', background: 'white', display: 'none', flexDirection: 'column', alignItems: 'start', gap: '5px', padding: '10px', borderRadius: '10px', boxShadow: '0px 2px 4px rgba(0,0,0,0.2)' }}>
                                                {revenue?.sedo ? (<p style={{ whiteSpace: 'nowrap' }}>Sedo: ${revenue?.sedo?.toFixed(0)}</p>) : (<></>)}
                                                {revenue?.tonic ? (<p style={{ whiteSpace: 'nowrap' }}>Tonic: ${revenue?.tonic?.toFixed(0)}</p>) : (<></>)}
                                                {revenue?.system1 ? (<p style={{ whiteSpace: 'nowrap' }}>System1: ${revenue?.system1?.toFixed(0)}</p>) : (<></>)}
                                                {revenue?.crossroads ? (<p style={{ whiteSpace: 'nowrap' }}>Crossroads: ${revenue?.crossroads?.toFixed(0)}</p>) : (<></>)}
                                                {revenue?.adcombo ? (<p style={{ whiteSpace: 'nowrap' }}>Adcombo: ${revenue?.adcombo?.toFixed(0)}</p>) : (<></>)}
                                                {revenue?.bodis ? (<p style={{ whiteSpace: 'nowrap' }}>Bodis: ${revenue?.bodis?.toFixed(0)}</p>) : (<></>)}
                                            </div>
                                        </div>
                                        {temp.filter(f => f.campaign_name === campaign).map((e, i) => (
                                            <div key={i}>---</div>
                                        ))}
                                    </div>
                                
                                    <div className="total-settings-grid-camp">
                                        {loading.single_campaign ? (
                                            <div>
                                                <div>
                                                    <div className="loader" style={{ height: '20px', width: '20px' }}/>
                                                </div>
                                            </div>
                                        ) : (
                                            campaignDataSingle?.map((e, i) => (
                                                <div key={i} style={{ background: checkCampDate(e.date) ? 'transparent' : '#FFFF99' }}>
                                                    <div>$ {e.revenue?.toFixed(0)}</div>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div style={{ position: 'relative', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px 10px' }} onMouseEnter={e => e.currentTarget.lastChild.style.display = 'flex'} onMouseLeave={e => e.currentTarget.lastChild.style.display = 'none'} >
                                    <p style={{ width: '100%', textAlign: 'left' }}>$ {revenue?.all?.toFixed(0)}</p>
                                    <div style={{ position: 'absolute', zIndex: 10, top: '100%', background: 'white', display: 'none', flexDirection: 'column', gap: '5px', padding: '10px', borderRadius: '10px', boxShadow: '0px 2px 4px rgba(0,0,0,0.2)' }}>
                                        {revenue?.sedo ? (<p style={{ whiteSpace: 'nowrap' }}>Sedo: ${revenue?.sedo?.toFixed(0)}</p>) : (<></>)}
                                        {revenue?.tonic ? (<p style={{ whiteSpace: 'nowrap' }}>Tonic: ${revenue?.tonic?.toFixed(0)}</p>) : (<></>)}
                                        {revenue?.system1 ? (<p style={{ whiteSpace: 'nowrap' }}>System1: ${revenue?.system1?.toFixed(0)}</p>) : (<></>)}
                                        {revenue?.crossroads ? (<p style={{ whiteSpace: 'nowrap' }}>Crossroads: ${revenue?.crossroads?.toFixed(0)}</p>) : (<></>)}
                                        {revenue?.adcombo ? (<p style={{ whiteSpace: 'nowrap' }}>Adcombo: ${revenue?.adcombo?.toFixed(0)}</p>) : (<></>)}
                                        {revenue?.bodis ? (<p style={{ whiteSpace: 'nowrap' }}>Bodis: ${revenue?.bodis?.toFixed(0)}</p>) : (<></>)}
                                    </div>
                                </div>
                            )
                        ) : (
                            (campaign === selectedCampaign && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                                <div className="total-settings-grid">
                                    <div className="total-settings-grid-adset">
                                        <div>{revenue ? '$ '+revenue?.toFixed(0) : '-'}</div>
                                        {temp.filter(f => f.campaign_name === campaign).map((e, i) => (
                                            <div key={i}>---</div>
                                        ))}
                                    </div>
                                    <div className="total-settings-grid-camp">
                                        {loading.single_campaign ? (
                                            <div>
                                                <div>
                                                    <div className="loader" style={{ height: '20px', width: '20px' }}/>
                                                </div>
                                            </div>
                                        ) : (
                                            campaignDataSingle?.map((e, i) => (
                                                <div key={i} style={{ background: checkCampDate(e.date) ? 'transparent' : '#FFFF99' }}>
                                                    <div>$ {e.revenue?.toFixed(0)}</div>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="total-settings-grid-adset">{revenue ? '$ '+revenue?.toFixed(0) : '-'}</div>
                            )
                        )}
                    </>
                )
            },
            valueGetter: (params) => parseFloat(params.row.revenue),
        },
        { field: 'spent', headerName: 'Spent', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'firstBlock', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                let spent = Number(params.row.spent)
                let campaign = params.row.campaign_name
                let temp = (trafficToSend === 'TikTok') ? (settingsData.today_result ? settingsData.today_result : []) : settingsData
                return(
                    <>
                        {(campaign === selectedCampaign && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                            <div className="total-settings-grid">
                                <div className="total-settings-grid-adset">
                                    <div>{spent ? '$ '+spent?.toFixed(0) : '-'}</div>
                                    {temp.filter(f => f.campaign_name === campaign).map((e, i) => (
                                        <div key={i}>$ {(e.spend * 1).toFixed(0)}</div>
                                    ))}
                                </div>
                                <div className="total-settings-grid-camp">
                                    {loading.single_campaign ? (
                                        <div>
                                            <div>
                                                <div className="loader" style={{ height: '20px', width: '20px' }}/>
                                            </div>
                                        </div>
                                    ) : (
                                        campaignDataSingle?.map((e, i) => (
                                            <div key={i} style={{ background: checkCampDate(e.date) ? 'transparent' : '#FFFF99' }}>
                                                <div>$ {e.spent?.toFixed(0)}</div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="total-settings-grid-adset">{spent ? '$ '+spent?.toFixed(0) : '-'}</div>
                        )}
                    </>
                )
            }},
        { field: 'result', headerName: 'Result', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'firstBlock', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const resultData = Number(params.row.result)
                let campaign = params.row.campaign_name
                let temp = (trafficToSend === 'TikTok') ? (settingsData.week_result ? settingsData.week_result : []) : settingsData
                return(
                    <>
                        {(campaign === selectedCampaign && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                            <div className="total-settings-grid">
                                <div className="total-settings-grid-adset">
                                    <div style={{color: (resultData > 0) ? '#4caf50' : '#ef5350'}}>{resultData ? '$ '+resultData?.toFixed(0) : '-'}</div>
                                    {temp.filter(f => f.campaign_name === campaign).map((e, i) => (
                                        <div key={i}>---</div>
                                    ))}
                                </div>
                                <div className="total-settings-grid-camp">
                                    {loading.single_campaign ? (
                                        <div>
                                            <div>
                                                <div className="loader" style={{ height: '20px', width: '20px' }}/>
                                            </div>
                                        </div>
                                    ) : (
                                        campaignDataSingle?.map((e, i) => (
                                            <div key={i} style={{ background: checkCampDate(e.date) ? 'transparent' : '#FFFF99' }}>
                                                <div style={{ color: (e.result > 0) ? '#4caf50' : '#ef5350' }}>$ {e.result?.toFixed(0)}</div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="total-settings-grid-adset" style={{color: (resultData > 0) ? '#4caf50' : '#ef5350'}}>{resultData ? '$ '+resultData?.toFixed(0) : '-'}</div>
                        )}
                    </>
                )
            }},
        { field: 'ROI%', headerName: 'ROI', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'firstBlock', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const ROIdata = Number(params.row["ROI%"])
                let campaign = params.row.campaign_name
                let temp = (trafficToSend === 'TikTok') ? (settingsData.week_result ? settingsData.week_result : []) : settingsData
                return(
                    <>
                        {(campaign === selectedCampaign && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                            <div className="total-settings-grid">
                                <div className="total-settings-grid-adset">
                                    <div style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{ROIdata ? ROIdata?.toFixed(0)+' %' : '-'}</div>
                                    {temp.filter(f => f.campaign_name === campaign).map((e, i) => (
                                        <div key={i}>---</div>
                                    ))}
                                </div>
                                <div className="total-settings-grid-camp">
                                    {loading.single_campaign ? (
                                        <div>
                                            <div>
                                                <div className="loader" style={{ height: '20px', width: '20px' }}/>
                                            </div>
                                        </div>
                                    ) : (
                                        campaignDataSingle?.map((e, i) => (
                                            <div key={i} style={{ background: checkCampDate(e.date) ? 'transparent' : '#FFFF99' }}>
                                                <div style={{ color: (e["ROI%"] > 0) ? '#4caf50' : '#ef5350' }}>{e["ROI%"]?.toFixed(0)} %</div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="total-settings-grid-adset" style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{ROIdata ? ROIdata?.toFixed(0)+' %' : '-'}</div>
                        )}
                    </>
                )
            }},
        { field: 'todayROI', headerName: 'ROI(1)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'secondBlock secondBlockLeftBorder', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const ROIdata = params.row["todayROI"]
                let campaign = params.row.campaign_name
                let temp = (trafficToSend === 'TikTok') ? (settingsData.week_result ? settingsData.week_result : []) : settingsData
                return(
                    <>
                        {(campaign === selectedCampaign && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                            <div className="total-settings-grid">
                                <div className="total-settings-grid-adset">
                                    <div style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{(ROIdata === "-" || !ROIdata) ? ((ROIdata === 0) ? '0' : '-') : (ROIdata*1).toFixed(0)+'%'}</div>
                                    {temp.filter(f => f.campaign_name === campaign).map((e, i) => (
                                        <div key={i}>---</div>
                                    ))}
                                </div>
                                <div className="total-settings-grid-camp" style={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px', borderRight: '2px solid #10b981' }}>
                                    {loading.single_campaign ? (
                                        <div>
                                            <div>
                                                <div className="loader" style={{ height: '20px', width: '20px' }}/>
                                            </div>
                                        </div>
                                    ) : (
                                        campaignDataSingle?.map((e, i) => (
                                            <div key={i} style={{ background: checkCampDate(e.date) ? 'transparent' : '#FFFF99' }}>
                                                <div style={{ background: checkCampDate(e.date) ? 'transparent' : '#FFFF99', fontSize: '12px' }}>{e.date}</div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="total-settings-grid-adset" style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{(ROIdata === "-" || !ROIdata) ? ((ROIdata === 0) ? '0' : '-') : (ROIdata*1).toFixed(0)+'%'}</div>
                        )}
                    </>
                )
            }},
        { field: 'last3DaysROI', headerName: 'ROI(3)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'secondBlock', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const ROIdata = params.row["last3DaysROI"]
                let campaign = params.row.campaign_name
                let temp = (trafficToSend === 'TikTok') ? (settingsData.week_result ? settingsData.week_result : []) : settingsData
                return(
                    <>
                        {(campaign === selectedCampaign && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                            <div className="total-settings-grid">
                                <div className="total-settings-grid-adset">
                                    <div style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{(ROIdata === "-" || !ROIdata) ? ((ROIdata === 0) ? '0' : '-') : (ROIdata*1).toFixed(0)+'%'}</div>
                                    {temp.filter(f => f.campaign_name === campaign).map((e, i) => (
                                        <div key={i}>---</div>
                                    ))}    
                                </div>
                            </div>
                        ) : (
                            <div className="total-settings-grid-adset" style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{(ROIdata === "-" || !ROIdata) ? ((ROIdata === 0) ? '0' : '-') : (ROIdata*1).toFixed(0)+'%'}</div>
                        )}
                    </>
                )
            }},
        { field: 'last7DaysROI', headerName: 'ROI(7)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'secondBlock', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const ROIdata = params.row["last7DaysROI"]
                let campaign = params.row.campaign_name
                let temp = (trafficToSend === 'TikTok') ? (settingsData.week_result ? settingsData.week_result : []) : settingsData
                return(
                    <>
                        {(campaign === selectedCampaign && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                            <div className="total-settings-grid">
                                <div className="total-settings-grid-adset">
                                    <div style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{(ROIdata === "-" || !ROIdata) ? ((ROIdata === 0) ? '0' : '-') : (ROIdata*1).toFixed(0)+'%'}</div>
                                    {temp.filter(f => f.campaign_name === campaign).map((e, i) => (
                                        <div key={i}>---</div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="total-settings-grid-adset" style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{(ROIdata === "-" || !ROIdata) ? ((ROIdata === 0) ? '0' : '-') : (ROIdata*1).toFixed(0)+'%'}</div>
                        )}
                    </>
                )
            }},
        { field: 'last14DaysROI', headerName: 'ROI(14)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'secondBlock', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const ROIdata = params.row["last14DaysROI"]
                let campaign = params.row.campaign_name
                let temp = (trafficToSend === 'TikTok') ? (settingsData.week_result ? settingsData.week_result : []) : settingsData
                return(
                    <>
                        {(campaign === selectedCampaign && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                            <div className="total-settings-grid">
                                <div className="total-settings-grid-adset">
                                    <div style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{(ROIdata === "-" || !ROIdata) ? ((ROIdata === 0) ? '0' : '-') : (ROIdata*1).toFixed(0)+'%'}</div>
                                    {temp.filter(f => f.campaign_name === campaign).map((e, i) => (
                                        <div key={i}>---</div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="total-settings-grid-adset" style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{(ROIdata === "-" || !ROIdata) ? ((ROIdata === 0) ? '0' : '-') : (ROIdata*1).toFixed(0)+'%'}</div>
                        )}
                    </>
                )
            }},
        { field: 'last30DaysROI', headerName: 'ROI(30)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'secondBlock', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const ROIdata = params.row["last30DaysROI"]
                let campaign = params.row.campaign_name
                let temp = (trafficToSend === 'TikTok') ? (settingsData.week_result ? settingsData.week_result : []) : settingsData
                return(
                    <>
                        {(campaign === selectedCampaign && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                            <div className="total-settings-grid">
                                <div className="total-settings-grid-adset">
                                    <div style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{(ROIdata === "-" || !ROIdata) ? ((ROIdata === 0) ? '0' : '-') : (ROIdata*1).toFixed(0)+'%'}</div>
                                    {temp.filter(f => f.campaign_name === campaign).map((e, i) => (
                                        <div key={i}>---</div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="total-settings-grid-adset" style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{(ROIdata === "-" || !ROIdata) ? ((ROIdata === 0) ? '0' : '-') : (ROIdata*1).toFixed(0)+'%'}</div>
                        )}
                    </>
                )
            }},
        { field: 'cpc', headerName: 'CPC', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'thirdBlock thirdBlockLeftBorder', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                // CPC is gotten from ad level API
                let campaign = params.row.campaign_name
                let temp = (trafficToSend === 'TikTok') ? (settingsData.week_result ? settingsData.week_result : []) : settingsData
                let average_cpc = temp?.filter(f => (f?.campaign_name === campaign) && f.cost_per_unique_inline_link_click)?.map(e => Number(e?.cost_per_unique_inline_link_click))?.reduce((a, b) => a + b, 0) / temp?.filter(f => (f?.campaign_name === campaign) && f?.cost_per_unique_inline_link_click)?.length
                return(
                    <>
                        {(campaign === selectedCampaign && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                            <div className="total-settings-grid">
                                <div className="total-settings-grid-adset">
                                    <div>{average_cpc ? average_cpc?.toFixed(3) : '-'}</div>
                                    {temp.filter(f => f.campaign_name === campaign).map((e, i) => (
                                        <div key={i}>{e.cost_per_unique_inline_link_click ? (Number(e.cost_per_unique_inline_link_click).toFixed(3)) : '-'}</div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="total-settings-grid-adset">{average_cpc ? average_cpc?.toFixed(3) : '-'}</div>
                        )}
                    </>
                )
            }},
        { field: 'cost_per_conversion', headerName: 'CPL', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'thirdBlock', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                // CPL is gotten from ad level API
                let campaign = params.row.campaign_name
                let temp = (trafficToSend === 'TikTok') ? (settingsData.week_result ? settingsData.week_result : []) : settingsData
                let average_cpl = temp?.filter(f => (f?.campaign_name === campaign) && f.cpl)?.map(e => Number(e?.cpl))?.reduce((a, b) => a + b, 0) / temp?.filter(f => (f?.campaign_name === campaign) && f?.cpl)?.length
                return(
                    <>
                        {(campaign === selectedCampaign && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                            <div className="total-settings-grid">
                                <div className="total-settings-grid-adset">
                                    <div>{average_cpl ? average_cpl?.toFixed(3) : '-'}</div>
                                    {temp.filter(f => f.campaign_name === campaign).map((e, i) => (
                                        <div key={i}>{e.cpl ? (Number(e.cpl).toFixed(3)) : '-'}</div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="total-settings-grid-adset">{average_cpl ? average_cpl?.toFixed(3) : '-'}</div>
                        )}
                    </>
                )
            }},
        { field: 'email_lead', headerName: 'Emails', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'fourthBlock fourthBlockLeftBorder', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                let email_lead = params.row.email_lead
                return (
                    <div className="total-settings-grid-adset">
                        {email_lead ? email_lead.toFixed(0) : ''}
                    </div>
                )
            }},
        { field: 'B_E_Days_3', headerName: 'B.E.Days(3)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'fourthBlock', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                let B_E_Days_3 = params.row.B_E_Days_3
                let cpe = params.row.cpe
                return (
                    <div className="total-settings-grid-adset">
                        {B_E_Days_3 ? ((cpe < 0) ? (B_E_Days_3*-1).toFixed(0) : '') : ''}
                    </div>
                )
            }},
        { field: 'cpe', headerName: 'CPE', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'fourthBlock', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                let cpe = params.row.cpe
                return (
                    <div className="total-settings-grid-adset" style={{color: (cpe > 0) ? '#4caf50' : '#ef5350'}}>{cpe ? cpe.toFixed(3) : ''}</div>
                )
            }},
        { field: 'convertkit_form_name', headerName: 'Form', minWidth: 200, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'fourthBlock', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                let form = params.row.convertkit_form_name
                return (
                    <div className="total-settings-grid-adset">
                        {form ? form : ''}
                    </div>
                )
            }},
    ];

    // campaigns check box on value change
    const handleChangeCheckBox = ({e, camp}) => {
        e.preventDefault();
        const value = e.target.checked
        let temp = [...checkedCampaigns]

        if (value === true) {
            temp.push(camp)
        } else {
            const index = temp.indexOf(camp);
            if (index > -1) { 
                temp.splice(index, 1);
            }
        }
        setCheckedCampaigns(temp);
    }

    // select all campaigns - update state
    const handleCheckAllCampaigns = (e) => {
        e.preventDefault()
        setCheckedCampaigns([])

        let camp = (account === 'all') ? data.campaigns : data.campaigns.filter(f => f.act_id === account)

        let temp = camp.map(m => m.campaign_name)

        setCheckedCampaigns(temp)
    }

    // update state of all the selected campaigns - API use
    const handleUpdateCheckedStatus = ({e, state}) => {
        e.preventDefault()

        let camp = (account === 'all') ? data.campaigns : data.campaigns.filter(f => f.act_id === account)
        let temp = [...camp]
        let filteredCamps = temp.filter(f => checkedCampaigns.find(find => f.campaign_name === find))
        let filtered_camps_to_change = filteredCamps.filter(f => f?.off !== state)

        if (filtered_camps_to_change.length === 0) {
            window.alert('All the selected campaigns are already ' + (state ? 'ON' : 'OFF'))
            return;
        }

        const url = `${host}/api/bidding/Off_campaign`;
        const token = Cookies.get("token");

        setActionLoading(p => { return { ...p, multi_fb_status: true } })

        axios.post(url,{
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
            },
            traffic: trafficName,
            data: filtered_camps_to_change.map(e => {return{ ...e, state: state }})
        })
        .then((response) => {
            if (state === false) {
                handleUpdateCheckedRules(false)
            } else {
                setCheckedCampaigns([])
            }
            
            // manipulate table only in the frontend
            let temp_campaigns = [...data.campaigns]
            filtered_camps_to_change.map(m => {
                const row_index = temp_campaigns.findIndex(f => f.campaign_id === m.campaign_id)
                temp_campaigns[row_index].off = state
            })
            setData(p => { return { ...p, campaigns: temp_campaigns } })
        })
        .catch((error) => {
            console.log(error)
            window.alert(error)
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        })
        .finally(() => setActionLoading(p => { return { ...p, multi_fb_status: false } }))
    }

    const handleOffAndDeleteCampaign = (e) => {
        e.preventDefault()

        let camp = (account === 'all') ? data.campaigns : data.campaigns.filter(f => f.act_id === account)
        let temp = [...camp]
        let filteredCamps = temp.filter(f => checkedCampaigns.find(find => f.campaign_name === find))
        let filtered_camps_to_change = filteredCamps.filter(f => {
            let result = false
            const find_auto = appContext.rulesInfo.data.find(find => find?.campaign_name === f.campaign_name)

            if (find_auto?.result?.action === 'off') {
                result = false
            } else {
                if (f.off === undefined || f.off === true) {
                    result = true
                }
            }
            return result
        })
        
        if (filtered_camps_to_change.length > 0) {
            window.alert("Some ON campaigns found! Please check your selection and try again.")
            return;
        }

        for (let i = 0; i < filteredCamps.length; i++) {
            const element = filteredCamps[i];
            if (!Number(element.campaign_name)) {
                window.alert("Alphabets detected! Campaign name other than widget number can not be deleted!")
                return
            }
        }

        const url_delete = `${host}/api/index/delete_widget`;
        const token = Cookies.get("token");

        setActionLoading(p => { return { ...p, multi_delete_widget: true } })

        axios.post(url_delete, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
            },
            data: filteredCamps.map(e => {return{ ...e, state: false }})
        })
        .then((response) => {
            console.log(response)
            setCheckedCampaigns([])
            if (response?.data?.result?.deletedCount > 0) {
                window.alert("Successfully deleted!")
            } else if (response?.data?.result?.deletedCount === 0) {
                window.alert("Nothing deleted right now! Reason can be its already deleted from database!")
            }
        })
        .catch((error) => {
            console.log(error)
            window.alert(error)
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        })
        .finally(() => setActionLoading(p => { return { ...p, multi_delete_widget: false } }))
    }

    // update Auto-optimization rules of all the selected campaigns - API use
    const handleUpdateCheckedRules = (state) => {
        let result = []

        checkedCampaigns.map(e => {
            let finding = data.campaigns.find(f => (f?.campaign_name === e) && (f?.off !== false))
            if (finding) {
                result.push({
                    traffic: trafficName,
                    campaign_name: finding?.campaign_name,
                    campaign_id: finding?.campaign_id,
                    state: state,
                    act_id: finding?.act_id
                })   
            }
        })

        ruleChangeAPI(result)
    }

    // adsets check box on value change
    const handleChangeAdsetsCheckBox = ({e, adsetID}) => {
        e.preventDefault();

        const value = e.target.checked
        let temp = [...checkedAdsets]

        if (value === true) {
            temp.push(adsetID)
        } else {
            const index = temp.indexOf(adsetID);
            if (index > -1) { 
                temp.splice(index, 1);
            }
        }
        setCheckedAdsets(temp);
    }

    // select all adsets - update state
    const handleCheckAllAdsets = ({e, camp_id}) => {
        e.preventDefault()

        if (trafficToSend === 'TikTok') {
            setCheckedAdsets([])
            let temp = settingsData.week_result.filter(f => f.campaign_id === camp_id)
            let result = temp.map(e => e.adgroup_id)
            setCheckedAdsets(result)
        } else {
            setCheckedAdsets([])
            let temp = settingsData.filter(f => f.campaign_id === camp_id)
            let result = temp.map(e => e.adset_id)
            setCheckedAdsets(result)
        }
    }

    // update state of all adsets - API use
    const handleUpdateCheckedAdsets = ({e, state}) => {
        e.preventDefault()
        setActionLoading(p => { return { ...p, update_adsets: true } })

        let user = Cookies.get('userArray')
        let temp = (trafficToSend === 'TikTok' && settingsData.week_result) ? settingsData.week_result.filter(f => checkedAdsets.find(find => f.adgroup_id === find)) : settingsData.filter(f => checkedAdsets.find(find => f.adset_id === find))
        let result = (trafficToSend === 'TikTok') ? temp.map(e => { return { id: e.adgroup_id, act_id: account, state: state, daily_budget: e.daily_budget, user: user, action: 'status' } }) : temp.map(e => { return { id: e.adset_id, act_id: account, state: state, daily_budget: e.budget, user: user, action: 'status' } })

        const url = `${host}/api/bidding/update`;
        const token = Cookies.get("token");

        axios.post(url,{
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
            },
            data: result, traffic: trafficToSend
        })
        .then((response) => {
            if (response?.data?.result?.[0]?.result?.success === true) {
                let temp_settings = [...settingsData]
                result.map(e => {
                    const index = temp_settings.findIndex(f => f.adset_id === e.id)
                    temp_settings[index].state = e.state ? "ACTIVE" : "PAUSED"
                })
                setSettingsData(temp_settings);
            } else {
                if (response?.data?.result?.[0]?.error?.error?.error_user_msg || response?.data?.result?.[0]?.error?.error?.error_user_msg) {
                    const error_msg = response?.data?.result?.[0]?.error?.error?.error_user_msg || response?.data?.result?.[0]?.error?.error?.error_user_msg
                    window.alert(`Adset Update failed. Facebook says "${error_msg}"`)
                } else {
                    window.alert('Adset Update failed')
                }
            }
        })
        .catch((error) => {
            console.log(error)
            window.alert(error)
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        })
        .finally(() => setActionLoading(p => { return { ...p, update_adsets: false } }))
    }

    // adsets component
    const Settings = ({cell, spent}) => {
        let autoFind =  offCampaign.autoTurnOnCampaignList ? offCampaign.autoTurnOnCampaignList.find(f => f.campaign_id === cell.row.campaign_id) : undefined
        const [activeLoading, setActiveLoading] = useState(false)
        const [turnOnDate, setTurnOnDate] = useState(autoFind?.turnOnDate ? autoFind?.turnOnDate : moment().utc().add(8, 'hours').format('YYYY-MM-DD'))
        const [displaySchedule, setDisplaySchedule] = useState(false)
        let temp = (trafficToSend === 'TikTok') ? (settingsData.week_result ? settingsData.week_result : []) : settingsData
        const find_rule = appContext.rulesInfo.data.find(f => (f?.campaign_id === cell.row.campaign_id) && (f?.traffic === trafficName))
           
        const handleFunc = (e) => {
            e.preventDefault()

            const tempCampaignName = cell.row.campaign_name

            setCampaignName(p => { return { ...p, name: tempCampaignName, act_id: cell.row.act_id } })
            setCheckedAdsets([])

            setLevelURL(p => { return {
                ...p,
                selectedCampaign: cell?.row?.campaign_name,
                spent: cell?.row?.spent ? Number(cell?.row?.spent) : 0,
                revenue: cell?.row?.revenue ? cell?.row?.revenue : 0,
                actions: cell?.row?.actions ? Number(cell?.row?.actions) : 0,
                clicks: cell?.row?.clicks ? cell?.row?.clicks : null
            }})

            if (trafficToSend === 'Facebook' || trafficToSend === 'TikTok') {
                if (selectedCampaign === tempCampaignName) {
                    setSelectedCampaign('')
                } else {
                    setSelectedCampaign(tempCampaignName)
                    if (Number(tempCampaignName)) {
                        getSelectedCampaignInfo(Number(tempCampaignName))

                        if (levelUrlFor === 'AllCountries') {
                            const names = cell?.row?.url?.id ? Object.values(cell?.row?.url?.id)?.find(f => f !== '') : null
                            const revenues = cell?.row?.url?.revenue ? Object.values(cell?.row?.url?.revenue)?.[0] : null
                            const urls = cell?.row?.url?.url ? cell?.row?.url?.url : null
                            const spent = cell?.row?.spent ? Number(cell?.row?.spent) : 0
                            let final_revenue = {}
                            let final_revenue_by_campaign = []

                            if (names && revenues) {
                                Object.keys(names).map(e => {
                                    final_revenue[e] = revenues[names[e]] ? revenues[names[e]] : 0
                                })
                            }

                            if (names && revenues) {
                                Object.keys(names).map(e => {
                                    final_revenue_by_campaign.push({
                                        country: e,
                                        campaign: names[e]
                                    })
                                })
                            }
                            
                            setLevelCountry(p => { return { ...p, revenue: final_revenue, url: urls, totalSpent: spent, campaign: names }})
                            getSelectedCampaignCountryInfo({ cName: tempCampaignName, revenue_by_campaign: final_revenue_by_campaign })
                        }
                    } else {
                        setSelectedCampaignInfo({ loading: false, country: '', topic: '', url: null })
                    }
                }
            } else {
                setSelectedCampaign('')
            }
        }

        const getSchedule = () => {
            let result = <></>

            if (offCampaign.autoTurnOnCampaignList) {
                let autoFind = offCampaign.autoTurnOnCampaignList.find(f => f.campaign_id === cell.row.campaign_id)
                if (autoFind && (new Date(moment(autoFind.turnOnDate)) > new Date())) {
                    result = <span className="dismiss-class" style={{ display: 'inline', fontSize: '14px', color: '#4caf50', marginLeft: '5px', cursor: 'pointer' }}
                            onMouseEnter={e => {
                                e.currentTarget.style.background = "#4caf50"
                                e.currentTarget.style.color = "#FFF"
                            }}
                            onMouseLeave={e => {
                                e.currentTarget.style.background = "transparent"
                                e.currentTarget.style.color = "#4caf50"
                            }}
                            onClick={() => setDisplaySchedule(!displaySchedule)}
                        >
                            Scheduled: {autoFind.turnOnDate} <FaEdit size={14} color="#4caf50"/>
                        </span>
                } else {
                    // if (cell.row.schedule) {
                    //     if (moment(cell.row.turn_on_date).isAfter(moment(cell.row.turn_off_date))) {
                    //         result = <span style={{ display: 'inline', fontSize: '14px', color: '#ef5350', textDecoration: 'underline overline', textUnderlineOffset: '4px', marginLeft: '5px' }}>Pulse OFF scheduled {cell.row.turn_off_date}</span>
                    //     } else {
                    //         result = <span style={{ display: 'inline', fontSize: '14px', color: '#4caf50', textDecoration: 'underline overline', textUnderlineOffset: '4px', marginLeft: '5px' }}>Pulse ON scheduled {cell.row.turn_on_date}</span>
                    //     }
                    // } else {
                    // }
                    result = <></>
                }
            } else {
                result = <></>
            }

            return result
        }

        const handleCampaignStatusChange = async (auto) => {
            setActiveLoading(true)
            const token = Cookies.get("token");
            const url = `${host}/api/bidding/V2/off_campaign`;

            await axios.post(url,{
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                    "Authorization": "Bearer "+token,
                    "Content-Type": "application/json",
                },
                traffic: trafficName,
                campaign_name: cell.row.campaign_name,
                campaign_id: cell.row.campaign_id,
                url: cell.row.campaign_ID,
                state: false,
                turnOfDate: autoFind?.turnOfDate ? autoFind?.turnOfDate : moment().utc().add(8, 'hours').format('YYYY-MM-DD'),
                turnOnDate: turnOnDate,
                auto: auto,
                act_id: cell.row.act_id,
            })
            .then((response) => {
                setActiveLoading(false)

                // manipulate table only in the frontend
                const row_index = data.campaigns.findIndex(f => f.campaign_id === cell.row.campaign_id)
                setData(p => {
                    let temp = [...p.campaigns]
                    temp[row_index].off = false
                    return { ...p, campaigns: temp }
                })
            })
            .catch((error) => {
                console.log(error)
                window.alert(error)
                setActiveLoading(false)
                if (error?.response?.data?.message === 'Invalid') {
                    appContext.forceLogout()
                }
            })
        }

        // dismiss-class is a className that has no actual CSS functionality
        // if dismiss-class is clicked, it will change the state which will close the dialogs
        useEffect(() => {
            document.addEventListener('click', (e) => {
                if (!e.target.closest('.dismiss-class')) {
                    setDisplaySchedule(false)
                }
            })
            
            return () => {
                document.removeEventListener('click', (e) => {
                    if (!e.target.closest('.dismiss-class')) {
                        setDisplaySchedule(false)
                    }
                })
            }
        }, [])

        return (
            <div className="total-settings" style={{ height: '100%', justifyContent: (selectedCampaign === cell.row.campaign_name) ? 'space-between' : 'center' }}>
                <div>
                    <div style={{ position: 'relative', color: (selectedCampaign === cell.row.campaign_name) ? '#36a2eb' : '#000000', padding: (selectedCampaign === cell.row.campaign_name) ? '0px' : '0px 10px' }}
                        onMouseEnter={e => {
                            e.currentTarget.firstChild.style.textDecoration = 'underline'
                            if (find_rule && find_rule.result) {
                                e.currentTarget.lastChild.style.scale = 1
                            }   
                        }}
                        onMouseLeave={e => {
                            e.currentTarget.firstChild.style.textDecoration = 'none'
                            if (find_rule && find_rule.result) {
                                e.currentTarget.lastChild.style.scale = 0
                            }   
                        }}
                    >
                        <span style={{ display: 'inline', textUnderlineOffset: '5px' }} onClick={e => handleFunc(e)}>{cell.row.campaign_name}</span>{getSchedule()}{activeLoading ? <div className="loader"/> : ''}
                        {(find_rule && find_rule.result && !displaySchedule) ? (
                            find_rule?.result?.message ? (
                                <div style={{ position: 'absolute', zIndex: 10, background: '#1c1c1e', padding: '10px', borderRadius: '10px', display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px', scale: 0, transition: '100ms', transformOrigin: 'top left' }}>
                                    <div>
                                        <p style={{ fontSize: '14px', color: '#FFF', fontWeight: '700', borderBottom: '1px solid #333' }}>Date</p>
                                        <p style={{ fontSize: '14px', color: '#CCC' }}>HKT {find_rule?.result?.date ? moment(find_rule?.result?.date).add(8, 'hours').format('YYYY-MM-DD HH:MM') : ''}</p>
                                    </div>
                                    <div>
                                        <p style={{ fontSize: '14px', color: '#FFF', fontWeight: '700', borderBottom: '1px solid #333' }}>Message</p>
                                        <p style={{ fontSize: '14px', color: '#CCC' }}>{find_rule?.result?.message}</p>
                                    </div>
                                </div>
                            ) : (
                                <div style={{ position: 'absolute', zIndex: 10, background: '#1c1c1e', padding: '10px', borderRadius: '10px', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '10px', scale: 0, transition: '100ms', transformOrigin: 'top left' }}>
                                    <div>
                                        <p style={{ fontSize: '14px', color: '#FFF', fontWeight: '700', borderBottom: '1px solid #333' }}>Date</p>
                                        <p style={{ fontSize: '14px', color: '#CCC' }}>HKT {find_rule?.result?.date ? moment(find_rule?.result?.date).add(8, 'hours').format('YYYY-MM-DD HH:MM') : ''}</p>
                                    </div>
                                    <div>
                                        <p style={{ fontSize: '14px', color: '#FFF', fontWeight: '700', borderBottom: '1px solid #333' }}>Budget</p>
                                        <p style={{ fontSize: '14px', color: '#CCC' }}>{find_rule?.result?.budget / 100}</p>
                                    </div>
                                    <div>
                                        <p style={{ fontSize: '14px', color: '#FFF', fontWeight: '700', borderBottom: '1px solid #333' }}>New Budget</p>
                                        <p style={{ fontSize: '14px', color: '#CCC' }}>{find_rule?.result?.new_budget / 100}</p>
                                    </div>
                                </div>
                            )
                        ) : (<></>)}
                    </div>

                    {displaySchedule ? (
                        <div className="dismiss-class" style={{ position: 'absolute', zIndex: 10, height: 'fit-content', width: '200px', background: '#000', borderRadius: '10px', padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }}>
                                <p style={{ fontSize: '14px', color: '#FFF' }}>Turn On Date:</p>
                                <div style={{ height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: "center", borderRadius: '5px', cursor: 'pointer' }}
                                    onMouseEnter={e => e.currentTarget.style.background = 'rgba(255, 255, 255, 0.5)'}
                                    onMouseLeave={e => e.currentTarget.style.background = 'transparent'}
                                    onClick={() => setDisplaySchedule(false)}
                                >
                                    <ImCross size={16} color={"#FFF"}/>
                                </div>
                            </div>
                            <input type="date" min={moment().utc().add(8, 'hours').format('YYYY-MM-DD')} value={turnOnDate} onChange={(e) => {setTurnOnDate(e.target.value);}} style={{ height: '40px', width: '100%', background: '#FFF', borderRadius: '5px', border: 0, outline: 0, padding: '0px 5px' }}/>
                            <div style={{ height: '40px', width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }}>
                                <div style={{ fontSize: '14px', borderRadius: '5px', border: '1px solid #FFF', color: '#FFF', background: '#000', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', cursor: 'pointer' }} onClick={() => {setDisplaySchedule(false); handleCampaignStatusChange(false);}}>Remove Schedule</div>
                                <div style={{ fontSize: '14px', borderRadius: '5px', border: '1px solid #FFF', color: '#000', background: '#FFF', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', cursor: 'pointer' }} onClick={() => {setDisplaySchedule(false); handleCampaignStatusChange(true);}}>UPDATE</div>
                            </div>
                        </div>
                    ) : (<></>)}

                    <div style={{ display: (selectedCampaign === cell.row.campaign_name) ? 'flex' : 'none', gap: '5px' }}>
                        {(account !== 'all') ? (
                            <>
                                {(levelUrlFor === 'AllClients') ? (
                                    <button style={{ height: '40px', padding: '0px 10px', borderRadius: '5px', border: '2px solid #FF00FF', background: '#FFFFFF', outline: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', pointerEvents: (selectedCampaignInfo.loading) ? 'none' : 'all', opacity: (selectedCampaignInfo.loading) ? '50%' : '100%', }} onClick={() => setLevelURL(p => {return{...p, display: true}})}><FaLink size={24} color={'#FF00FF'}/></button>
                                ) : (<></>)}
                                <button style={{ height: '40px', padding: '0px 10px', borderRadius: '5px', border: 0, outline: 0, background: '#555555', color: '#FFFFFF', cursor: 'pointer' }} onClick={e => handleCheckAllAdsets({e, camp_id: cell.row.campaign_id})}><MdDoneAll size={30} color={'#FFFFFF'}/></button>
                                <button style={{ height: '40px', padding: '0px 10px', borderRadius: '5px', border: '2px solid #AAAAAA', background: '#FFFFFF', outline: 0, cursor: 'pointer' }} onClick={e => {e.preventDefault(); setCheckedAdsets([])}}><MdRemoveDone size={30} color={'#AAAAAA'}/></button>
                                {(checkedAdsets.length > 0) ? (
                                    <>
                                        <button style={{ height: '40px', fontSize: '14px', padding: '0px 10px', borderRadius: '40px', background: '#4caf50', border: 0, outline: 0, color: '#FFFFFF', cursor: 'pointer' }} onClick={e => handleUpdateCheckedAdsets({e, state: true})}>ON</button>
                                        <button style={{ height: '40px', fontSize: '14px', padding: '0px 10px', borderRadius: '40px', background: '#F47174', border: 0, outline: 0, color: '#FFFFFF', cursor: 'pointer' }} onClick={e => handleUpdateCheckedAdsets({e, state: false})}>OFF</button>
                                    </>
                                ) : (<></>)}
                            </>
                        ) : (<></>)}
                        {(selectedCampaignInfo.loading || selectedCampaignInfo.loadingCountry) ? (
                            <div className="loader" style={{ height: '20px', width: '20px' }}/>
                        ) : (
                            <p style={{ fontSize: '12px', wordBreak: 'break-word' }}>{(levelUrlFor === 'AllCountries') ? '' : selectedCampaignInfo.country+' - '}{selectedCampaignInfo.topic}</p>
                        )}
                    </div>

                    <div style={{ display: ((selectedCampaign === cell.row.campaign_name) && (account !== 'all')) ? 'flex' : 'none' }}>
                        <table>
                            <tbody>
                                <tr>
                                    <th className="total-settings-th" >Sel</th>
                                    <th className="total-settings-th" >O/F</th>
                                    <th className="total-settings-th" >Name</th>
                                    <th className="total-settings-th" >Budg</th>
                                    <th className="total-settings-th" >Best Adset</th>
                                    <th className="total-settings-th" >Act</th>
                                    {(trafficToSend === 'TikTok') ? (
                                        <>
                                            <th className="total-settings-th" style={{background: 'rgba(153, 102, 255, 0.1)'}}>Rule</th>
                                            <th className="total-settings-th" style={{background: 'rgba(153, 102, 255, 0.1)'}}>Max</th>
                                            <th className="total-settings-th" style={{background: 'rgba(153, 102, 255, 0.1)'}}>Act</th>
                                        </>
                                    ) : (<></>)}
                                </tr>
                                {(trafficToSend === 'Facebook' || trafficToSend === 'TikTok') && (temp.filter(f => f.campaign_name === cell.row.campaign_name).map((e, i) => (
                                    <TableCells e={e} key={i} spent={spent}/>
                                )))}
                            </tbody>
                        </table>
                    </div>
                </div>

                {(selectedCampaign === cell.row.campaign_name) ? (
                    <div className="total-settings-grid-camp" style={{ borderLeft: '2px solid #10b981', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}>
                        {loading.single_campaign ? (
                            <div>
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                    Campaign Data Loading <div className="loader" style={{ height: '20px', width: '20px' }}/>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div style={{ height: '300px', width: '100%', borderBottom: 'none' }}>
                                    <Line options={options} data={dataCpaCpl} style={{ height: '100%', width: '100%' }} />
                                </div>
                            </div>
                        )}
                    </div>
                ) : (<></>)}
            </div>
        )
    }

    // adsets component extension component with the table data
    const TableCells = ({e, spent}) => {
        const [updateSettingsLoading, setUpdateSettingsLoading] = useState(false)
        const [greenButton, setGreenButton] = useState(false)
        const [blueButton, setBlueButton] = useState(false)
        const [switchState, setSwitchState] = useState((e.status === 'ACTIVE' || e.status === 'ENABLE') ? true : false)
        const [inputValue, setInputValue] = useState(trafficToSend === 'Facebook' ? e.budget : e.daily_budget)
        // only for rules tiktok
        const [switchStateRule, setSwitchStateRule] = useState(settingsData.today_result ? settingsData.today_result.find(f => f.adgroup_id === e.adgroup_id)?.state : false)
        const [inputValueRule, setInputValueRule] = useState(settingsData.today_result ? settingsData.today_result.find(f => f.adgroup_id === e.adgroup_id)?.max : 0)
        const adsetID = trafficToSend === 'Facebook' ? e.adset_id : e.adgroup_id
  
        const handleClick = (action) => {
            if (updateSettingsLoading || !greenButton) {
                return
            }
            
            if (Number(inputValue) > 20) {
                if (window.confirm('Do you really wanna put the budget more than 20 ???')) {
                    updateSettings({
                        id: adsetID,
                        act_id: account,
                        state: switchState,
                        setSwitchState,
                        inputValue,
                        setInputValue,
                        setUpdateSettingsLoading,
                        settingsData: settingsData,
                        setSettingsData: setSettingsData,
                        currentObj: e,
                        trafficToSend,
                        action,
                        appContext
                    })
                }
            } else {
                updateSettings({
                    id: adsetID,
                    act_id: account,
                    state: switchState,
                    setSwitchState,
                    inputValue,
                    setInputValue,
                    setUpdateSettingsLoading,
                    settingsData,
                    setSettingsData,
                    currentObj: e,
                    trafficToSend,
                    action,
                    appContext
                })
            }
        }

        // tiktok function only
        const handleClickRule = (abc) => {
            abc.preventDefault();

            let temp = settingsData.today_result.find(f => f.adgroup_id === e.adgroup_id)
            temp.state = switchStateRule
            temp.max = inputValueRule*1

            updateSettingsRule({ data: [temp], settingsData: settingsData, setSettingsData: setSettingsData, setUpdateSettingsLoading: setUpdateSettingsLoading, trafficToSend: trafficToSend, appContext })
        }

        useEffect(() => {
            const temp = { state: (trafficToSend === 'TikTok') ? ((e.status === 'ENABLE') ? true : false) : ((e.status === 'ACTIVE') ? true : false), budget: trafficToSend === 'Facebook' ? e.budget : e.daily_budget }

            if (inputValue !== temp.budget || switchState !== temp.state) {
                setGreenButton(true)
            } else {
                setGreenButton(false)
            }
        }, [inputValue, switchState])

        useEffect(() => {
            if (trafficToSend === 'TikTok') {
                let abc = settingsData.today_result ? settingsData.today_result.find(f => f.adgroup_id === e.adgroup_id) : {}
                if (inputValueRule !== abc.max || switchStateRule !== abc.state) {
                    setBlueButton(true)
                } else {
                    setBlueButton(false)
                }
            }
        }, [inputValueRule, switchStateRule])

        const numberInputOnWheelPreventChange = (e) => {
            // Prevent the input value change
            e.target.blur()
        
            // Prevent the page/container scrolling
            e.stopPropagation()
        
            let timeoutID;
            // Refocus immediately, on the next tick (after the current function is done)
            timeoutID = setTimeout(() => {
                e.target.focus()
            }, 0)
            
            return () => clearTimeout(timeoutID)
        }

        return (
            <tr className="total-settings-tr">
                <td style={{ width: '20px' }}>
                    <input type="checkbox" style={{ height: '16px', width: '16px' }} checked={(checkedAdsets.find(f => f === adsetID)) ? true : false} onChange={e => handleChangeAdsetsCheckBox({e, adsetID: adsetID})} />
                </td>
                <td className="total-settings-switch" style={{ width: 'fit-content' }}>
                    <div onClick={() => setSwitchState(!switchState)} style={{ height: '20px', width: '30px' }}>
                        <div style={{ height: '16px', width: '16px', top: '2px', left: (switchState) ? '12px' : 'calc(100% - 28px)', background: (switchState) ? '#36a2eb' : '#AAAAAA' }}></div>
                    </div>
                </td>
                {(levelUrlFor === 'AllCountries') ? (
                    <td style={{ fontSize: '12px', cursor: 'pointer', pointerEvents: (selectedCampaignInfo.loadingCountry) ? 'none' : 'all' }}
                        onMouseEnter={e => e.currentTarget.style.opacity = '50%'}
                        onMouseLeave={e => e.currentTarget.style.opacity = '100%'}
                        onClick={() => {
                            let selectedAdset = (trafficToSend === 'Facebook') ? e.adset_name : e.adgroup_name
                            setLevelCountry(p => { return { ...p, display: true, countries: p.data.filter(f => f.adset_name === selectedAdset).map(e => e.country) } })
                        }}
                    >
                        {trafficToSend === 'Facebook' ? e.adset_name : e.adgroup_name}
                    </td>
                ) : (
                    <td style={{ fontSize: '12px' }}>{trafficToSend === 'Facebook' ? e.adset_name : e.adgroup_name}</td>
                )}
                <td className="total-settings-input" style={{ width: 'fit-content' }}>
                    <input type="number" value={inputValue} onChange={(e) => setInputValue(e.target.value)} onWheel={numberInputOnWheelPreventChange}
                        style={{ maxWidth: '40px', fontSize: '12px' }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleClick('all')
                            }
                        }}
                    />
                </td>
                <td style={{ fontSize: '12px' }}>{(e.relative_weight) ? (e.relative_weight*100).toFixed(0)+'%' : '-'}</td>
                <td className="total-settings-button" style={{ width: 'fit-content' }}>
                    <div style={{ height: '20px', width: '20px', pointerEvents: (updateSettingsLoading || !greenButton) ? 'none' : 'all', background: (greenButton) ? '#4caf50' : '#AAAAAA' }} onClick={() => handleClick('all')}>
                        {(updateSettingsLoading) ? (
                            <div className="loadingSpinner" style={{ borderTopColor: '#FFFFFF', borderBottomColor: '#FFFFFF', borderRightColor: 'rgba(255, 255, 255, 0.5)', borderLeftColor: 'rgba(255, 255, 255, 0.5)', height: '16px', width: '16px' }}></div>
                        ) : (
                            <>&#10004;</>
                        )}
                    </div>
                </td>
                {(trafficToSend === 'TikTok') ? (
                    <>
                        <td className="total-settings-switch" style={{ width: 'fit-content', background: 'rgba(153, 102, 255, 0.1)' }}>
                            <div onClick={() => setSwitchStateRule(!switchStateRule)} style={{ height: '20px', width: '30px' }}>
                                <div style={{ height: '16px', width: '16px', top: '2px', left: (switchStateRule) ? '12px' : 'calc(100% - 28px)', background: (switchStateRule) ? '#F47174' : '#AAAAAA' }}></div>
                            </div>
                        </td>
                        <td className="total-settings-input" style={{ width: 'fit-content', background: 'rgba(153, 102, 255, 0.1)' }}>
                            <input type="number" value={inputValueRule} onChange={(e) => setInputValueRule(e.target.value)} onWheel={numberInputOnWheelPreventChange}
                                style={{ maxWidth: '40px', fontSize: '12px' }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleClickRule(e)
                                    }
                                }}
                            />
                        </td>
                        <td className="total-settings-button" style={{ width: 'fit-content', background: 'rgba(153, 102, 255, 0.1)' }}>
                            <div style={{ height: '20px', width: '20px', pointerEvents: (updateSettingsLoading || !blueButton) ? 'none' : 'all', background: (blueButton) ? '#36a2eb' : '#AAAAAA' }} onClick={e => handleClickRule(e)}>
                                {(updateSettingsLoading) ? (
                                    <div className="loadingSpinner" style={{ borderTopColor: '#FFFFFF', borderBottomColor: '#FFFFFF', borderRightColor: 'rgba(255, 255, 255, 0.5)', borderLeftColor: 'rgba(255, 255, 255, 0.5)', height: '16px', width: '16px' }}></div>
                                ) : (
                                    <>&#10004;</>
                                )}
                            </div>
                        </td>
                    </>
                ) : (<></>)}
            </tr>
        )
    }

    // API call to get the selected campaign's information like country and topic
    const getSelectedCampaignInfo = (e) => {
        setSelectedCampaignInfo(p => { return { ...p, loading: true } })

        const controller = new AbortController();
        const token = Cookies.get('token')
        const url = `${host}/api/index/search_by_id`;
    
        axios.post(url, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
            },
            id: Number(e)
        },
        {
            signal: controller.signal
        })
    
        .then((response) => {
            const obj = response.data

            if (obj.result) {
                setSelectedCampaignInfo({ loading: false, country: obj.result.country ? obj.result.country : '', topic: obj.result.topic ? obj.result.topic : '', url: obj.result.url ? obj.result.url : null })
            } else {
                setSelectedCampaignInfo({ loading: false, country: '', topic: '', url: null })
            }
        })
        
        .catch((error) => {
            console.log(error);
            setSelectedCampaignInfo({ loading: false, country: '', topic: '', url: null })
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        })
    }

    // API call to get the selected campaign's country information incase of client AllCountries
    const getSelectedCampaignCountryInfo = ({ cName, revenue_by_campaign }) => {
        setSelectedCampaignInfo(p => { return { ...p, loadingCountry: true } })
        const controller = new AbortController()
        const token = Cookies.get('token')
        
        const url = `${host}/api/bidding/v2/fetch/country`;
    
        axios.post(url, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
            },
            from: fromDate,
            to: toDate,
            id: account,
            traffic: "Facebook",
            campaign: cName
        },
        {
            signal: controller.signal
        })
    
        .then((response) => {
            console.log(response);
            const obj = response.data

            if (obj.result && Array.isArray(obj.result)) {
                let final_temp = []
                let temp = []

                temp = revenue_by_campaign.map(e => {
                    let find_country = obj.result.find(f => f?.country === e?.country)
                    if (find_country) {
                        let find_action = find_country?.actions?.find(f => f?.action_type === 'link_click')
                        if (find_action) {
                            return { ...e, clicks: find_action?.value ? Number(find_action?.value) : 0 }
                        } else {
                            return { ...e, clicks: 0 }
                        }
                    } else {
                        return { ...e, clicks: 0 }
                    }
                })

                let temp_campaigns = Array.from(new Set(temp?.map(e => e?.campaign)))

                temp_campaigns.map(e => {
                    let clicks_sum = temp.filter(f => f?.campaign === e).map(m => m?.clicks).reduce((a, b) => a + b, 0)
                    temp.forEach(element => {
                        if (element?.campaign === e) {
                            const clicks_percent = element?.clicks / clicks_sum
                            final_temp.push({ ...element, clicks_percent: clicks_percent ? clicks_percent : 0 })
                        }
                    });
                })

                setLevelCountry(p => { return { ...p, data: obj.result.map(e => { return { ...e, clicks_percent: final_temp?.find(f => f?.country === e?.country)?.clicks_percent ? final_temp?.find(f => f?.country === e?.country)?.clicks_percent : 0 } }), countries: obj.result.map(e => e.country), revenue_by_campaign: revenue_by_campaign } })
            } else {
                setLevelCountry(p => { return { ...p, data: [], countries: [] } })
            }
            setSelectedCampaignInfo(p => { return { ...p, loadingCountry: false } })
        })
        
        .catch((error) => {
            console.log(error);
            setLevelCountry(p => { return { ...p, data: [], countries: [] } })
            setSelectedCampaignInfo(p => { return { ...p, loadingCountry: false } })
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        })
    }

    // API call to get the adsets
    const getSettingsData = () => {
        setActionLoading(p => { return { ...p, settings: true } })
        
        const controller = new AbortController();
        const token = Cookies.get('token')
        const username = Cookies.get('userArray')
        const url = `${host}/api/bidding/v2/fetch`;
    
        axios.post(url, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
            },
            user: username,
            id: account,
            traffic: trafficToSend,
            from: moment().utc().add(8, 'hours').subtract(minusDate + 6, 'days').format('YYYY-MM-DD'),
            to: moment().utc().add(8, 'hours').subtract(minusDate, 'days').format('YYYY-MM-DD'),
        },
        {
            signal: controller.signal
        })
        .then((response) => {
            console.log(response)
            if (response.data.result) {
                if (trafficToSend === 'TikTok') {
                    setSettingsData(response.data.result)
                } else {
                    if (Array.isArray(response.data.result)) {
                        setSettingsData(response.data.result)
                    }
                }
            } else {
                console.log('Failed to fetch data')
            }
        })
        .catch((error) => {
            console.log(error);
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        })
        .finally(() => setActionLoading(p => { return { ...p, settings: false } }))
    }

    // API call to get live TikTok data
    const getTikTokLive = ({ actName }) => {
        setTiktokLive(p => { return { ...p, loading: true } })

        const dateCondition = () => {
            let date = ''
      
            if (actName === 'PLEBS 1') {
                if ((moment().utc().add(8, 'hours').format('HH') * 1) >= 16) {
                  date = moment().utc().add(8, 'hours').format('YYYY-MM-DD')
                } else {
                  date = moment().utc().add(8, 'hours').subtract(2, 'day').format('YYYY-MM-DD')
                }
            } else if (actName === 'PLEBS 2') {
                if ((moment().utc().add(8, 'hours').format('HH') * 1) >= 6) {
                  date = moment().utc().add(8, 'hours').format('YYYY-MM-DD')
                } else {
                  date = moment().utc().add(8, 'hours').subtract(2, 'day').format('YYYY-MM-DD')
                }
            }
      
            return date
        }
        
        const controller = new AbortController();
        const token = Cookies.get('token')
        const url = `${host}/api/bidding/v2/fetch/live`;
    
        axios.post(url, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
            },
            date: dateCondition(),
            id: account,
            traffic: trafficToSend,
        },
        {
            signal: controller.signal
        })
    
        .then((response) => {
            const obj = response.data
    
            setTiktokLive(p => { return { ...p, loading: false, data: obj.result.result.map((e, i) => { return {...e, id: i} }) } })
        })
        
        .catch((error) => {
            console.log(error);
            setTiktokLive(p => { return { ...p, loading: false } })
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        })
    }

    // live TikTok row data
    const tiktokLiveRow = tiktokLive.data

    // live TikTok columns
    const tiktokLiveColumns = [
        { field: 'id', headerName: 'ID', hide: true},
        { field: 'campaign_name', flex: 2, headerName: 'Campaign', minWidth: 200, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const camp = params.row.campaign_name
                const handleLiveClick = (e) => {
                    e.preventDefault()
                    if (tiktokLive.campaign === camp) {
                        setTiktokLive(p => {return{...p, campaign: ''}})
                    } else {
                        setTiktokLive(p => {return{...p, campaign: camp}})
                    }
                }
                return (
                    <div className="total-settings">
                        <p style={{ color: (tiktokLive.campaign === camp) ? '#36a2eb' : '#000000' }} onClick={e => handleLiveClick(e)}>{camp}</p>
                    </div>
                )
            }},
        { field: 'status', flex: 1, headerName: 'ON/OFF', minWidth: 100, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                let camp = params.row.campaign_name
                let details = params.row.data
                const TikTokStatusButton = ({info}) => {
                    const [active, setActive] = useState((info.status === 'ENABLE') ? true : false)
                    const [activeLoading, setActiveLoading] = useState(false)
            
                    const handleClick = (e) => {
                        e.preventDefault()
                        updateSettingsTikTokLive({ info: info, act_id: account, active: !active, setActiveLoading: setActiveLoading, tiktokLive: tiktokLive, setTiktokLive: setTiktokLive, trafficToSend: trafficToSend, inputValue: '', action: 'status', appContext })
                    }
            
                    return(
                        <div className='rulesSwitchContainer'>
                            <div className="rulesSwitch" onClick={(e) => handleClick(e)} style={{background: `${(active) ? '#1976d2' : '#98989d'}`, pointerEvents: `${(activeLoading) ? 'none' : 'all'}`, opacity: `${(activeLoading) ? '0.5' : '1'}` }}>
                                <div className="rulesSwitchButton" style={{left: `${(active) ? '22.5px' : '2.5px' }`}}/>
                            </div>
                        </div>
                    )
                }

                return(
                    <>
                        {(camp === tiktokLive.campaign) ? (
                            <div className="total-settings-grid">
                                {details.map((e, i) => (
                                    <TikTokStatusButton info={e} key={i}/>
                                ))}
                            </div>
                        ) : (<></>)}
                    </>
                )
            }},
        { field: 'adgroup_name', flex: 1, headerName: 'Ad Name', minWidth: 200, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                let camp = params.row.campaign_name
                let details = params.row.data
                return(
                    <>
                        {(camp === tiktokLive.campaign) ? (
                            <div className="total-settings-grid">
                                {details.map((e, i) => (
                                    <div key={i} style={{ fontWeight: 900 }}>{e.adgroup_name}</div>
                                ))}
                            </div>
                        ) : (<></>)}
                    </>
                )
            }},
        { field: 'spent', flex: 1, headerName: 'Spent', minWidth: 100, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                let camp = params.row.campaign_name
                let details = params.row.data
                return(
                    <>
                        {(camp === tiktokLive.campaign) ? (
                            <div className="total-settings-grid">
                                {details.map((e, i) => (
                                    <div key={i}>$ {e.spent}</div>
                                ))}
                            </div>
                        ) : (<></>)}
                    </>
                )
            }},
        { field: 'cpc', flex: 1, headerName: 'CPC', minWidth: 100, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                let camp = params.row.campaign_name
                let details = params.row.data
                return(
                    <>
                        {(camp === tiktokLive.campaign) ? (
                            <div className="total-settings-grid">
                                {details.map((e, i) => (
                                    <div key={i}>{e.cpc}</div>
                                ))}
                            </div>
                        ) : (<></>)}
                    </>
                )
            }},
        { field: 'cpl', flex: 1, headerName: 'CPL', minWidth: 100, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                let camp = params.row.campaign_name
                let details = params.row.data
                return(
                    <>
                        {(camp === tiktokLive.campaign) ? (
                            <div className="total-settings-grid">
                                {details.map((e, i) => (
                                    <div key={i}>{e.cpl}</div>
                                ))}
                            </div>
                        ) : (<></>)}
                    </>
                )
            }},
        { field: 'daily_budget', flex: 1, headerName: 'Budget', minWidth: 150, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                let camp = params.row.campaign_name
                let details = params.row.data

                const numberInputOnWheelPreventChange = (e) => {
                    e.target.blur()
                    e.stopPropagation()
                    let timeoutID;
                    timeoutID = setTimeout(() => {
                        e.target.focus()
                    }, 0)
                    return () => clearTimeout(timeoutID)
                }

                const TikTokBudget = ({info}) => {
                    const [inputValue, setInputValue] = useState(info.daily_budget*1)
                    const [activeLoading, setActiveLoading] = useState(false)
            
                    const handleClick = (e) => {
                        e.preventDefault()
                        if (inputValue*1 === info.daily_budget*1) {
                            window.alert('Same value.')
                        } else {
                            updateSettingsTikTokLive({ info: info, act_id: account, active: info.status, setActiveLoading: setActiveLoading, tiktokLive: tiktokLive, setTiktokLive: setTiktokLive, trafficToSend: trafficToSend, inputValue: inputValue*1, action: 'budget', appContext })
                        }
                    }
            
                    return (
                        <div className="total-settings-input" style={{ width: '100%', display: 'flex', columnGap: '10px' }}>
                            <input type="number" value={inputValue} onChange={(e) => setInputValue(e.target.value)} onWheel={numberInputOnWheelPreventChange}
                                style={{ flex: 1, border: '1px solid black', borderRadius: '5px' }}
                                onKeyDown={(e) => { e.stopPropagation() }}
                            />
                            <div onClick={(e) => handleClick(e)} style={{ height: '30px', width: '30px', pointerEvents: (activeLoading) ? 'none' : 'all', background: '#4caf50', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#FFFFFF', borderRadius: '5px', cursor: 'pointer' }}>
                                {(activeLoading) ? (
                                    <div className="loadingSpinner" style={{ borderTopColor: '#FFFFFF', borderBottomColor: '#FFFFFF', borderRightColor: 'rgba(255, 255, 255, 0.5)', borderLeftColor: 'rgba(255, 255, 255, 0.5)' }}></div>
                                ) : (
                                    <>&#10004;</>
                                )}
                            </div>
                        </div>
                    )
                }

                return(
                    <>
                        {(camp === tiktokLive.campaign) ? (
                            <div className="total-settings-grid">
                                {details.map((e, i) => (
                                    <TikTokBudget info={e} key={i}/>
                                ))}
                            </div>
                        ) : (<></>)}
                    </>
                )
            }},
        { field: 'rule', flex: 1, headerName: 'Rule & Max', minWidth: 200, headerClassName: 'datagridHeader', cellClassName: 'purpleCell', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                let camp = params.row.campaign_name
                let details = params.row.data

                const numberInputOnWheelPreventChange = (e) => {
                    e.target.blur()
                    e.stopPropagation()
                    let timeoutID;
                    timeoutID = setTimeout(() => {
                        e.target.focus()
                    }, 0)
                    return () => clearTimeout(timeoutID)
                }

                const TikTokRule = ({info}) => {
                    const [inputValue, setInputValue] = useState(info.max*1)
                    const [active, setActive] = useState(info.state)
                    const [activeLoading, setActiveLoading] = useState(false)
            
                    const handleClick = (e) => {
                        e.preventDefault()
                        if (active === info.state && inputValue*1 === info.max*1) {
                            window.alert('Same value.')
                        } else {
                            let temp = { ...info, state: active, max: inputValue*1 }
                            updateSettingsTikTokLiveRule({ info: [temp], setActiveLoading, tiktokLive, setTiktokLive, trafficToSend, appContext })
                        }
                    }
            
                    return (
                        <div className="total-settings-input" style={{ width: '100%', display: 'flex', columnGap: '10px', }}>
                            {(info.max) ? (
                                <>
                                    <div className="rulesSwitch" onClick={(e) => {e.preventDefault(); setActive(!active);}} style={{background: `${(active) ? '#F47174' : '#98989d'}`, pointerEvents: `${(activeLoading) ? 'none' : 'all'}`, opacity: `${(activeLoading) ? '0.5' : '1'}` }}>
                                        <div className="rulesSwitchButton" style={{left: `${(active) ? '22.5px' : '2.5px' }`}}/>
                                    </div>
                                    <input type="number" value={inputValue} onChange={(e) => setInputValue(e.target.value)} onWheel={numberInputOnWheelPreventChange}
                                        style={{ flex: 1, border: '1px solid black', borderRadius: '5px' }}
                                        onKeyDown={(e) => { e.stopPropagation() }}
                                    />
                                    <div onClick={(e) => handleClick(e)} style={{ height: '30px', width: '30px', pointerEvents: (activeLoading) ? 'none' : 'all', background: '#1976d2', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#FFFFFF', borderRadius: '5px', cursor: 'pointer' }}>
                                        {(activeLoading) ? (
                                            <div className="loadingSpinner" style={{ borderTopColor: '#FFFFFF', borderBottomColor: '#FFFFFF', borderRightColor: 'rgba(255, 255, 255, 0.5)', borderLeftColor: 'rgba(255, 255, 255, 0.5)' }}></div>
                                        ) : (
                                            <>&#10004;</>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <p style={{ fontSize: '12px' }}>Waiting for backend refresh</p>
                            )}
                        </div>
                    )
                }

                return(
                    <>
                        {(camp === tiktokLive.campaign) ? (
                            <div className="total-settings-grid">
                                {details.map((e, i) => (
                                    <TikTokRule info={e} key={i}/>
                                ))}
                            </div>
                        ) : (<></>)}
                    </>
                )
            }},
    ];

    // copy selected campaigns to widget
    const handleCopyCampaign = async () => {
        setCopyC2WLoading(true)
        if (checkedCampaigns.length < 1) {
            window.alert('Please select some campaigns first.')
            setCopyC2WLoading(false)
            return
        }

        if ((appContext.role === 'admin') && (userToSend === '')) {
            window.alert('Please select a manager first.')
            setCopyC2WLoading(false)
            return
        }
        
        if (checkedCampaigns.filter(f => !Number(f)).length > 0) {
            window.alert('Text Campaigns can not be copied.')
            setCopyC2WLoading(false)
            return
        }

        const token = Cookies.get('token')
        const url = `${host}/api/index/search_by_id`;
        let tempResult = []

        checkedCampaigns.map(e => {
            const temp = data.campaigns.find(f => f.campaign_name === e)
            let caseToSend = 'Facebook'

            if (levelUrlFor === 'AllClients') {
                caseToSend = 'All'
            } else if (levelUrlFor === 'AllCountries') { 
                caseToSend = 'Country'
            } else {
                caseToSend = 'Facebook'
            }
            
            if (temp) {
                tempResult.push({
                    finished: false,
                    topic: '',
                    // fb_id: temp.act_id ? temp.act_id : '',
                    fb_id: '',
                    client: '',
                    country: '',
                    note: temp.act_name ? (e+' / '+temp.act_name) : e,
                    emailoctopus_list_id: null,
                    emailoctopus_campaign_id: null,
                    emailoctopus_list_name: null,
                    emailoctopus_campaign_name: null,
                    complete_date: null,
                    url: '',
                    keyword: [],
                    final_url: '',
                    case: caseToSend,
                    username: (appContext.role === 'admin') ? userToSend : username,
                })
            }
        })

        let result = await Promise.all(tempResult.map(async e => {
            const temp = {...e}
            try {
                const response = await axios.post(url, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                        "Authorization": "Bearer "+token,
                        "Content-Type": "application/json",
                    },
                    id: Number(e.note.split(' ')[0])
                })
                if (response.data) {
                    if (response.data.result) {
                        temp.topic = response.data.result.topic ? response.data.result.topic : ''
                        temp.country = response.data.result.country ? response.data.result.country : ''
                        temp.keyword = response.data.result.keyword ? response.data.result.keyword : []
                    }
                }
            } catch (error) {
                console.log(error);
                if (error?.response?.data?.message === 'Invalid') {
                    appContext.forceLogout()
                }
            }
            return temp
        }))

        copyCampaign({ setCopyC2WLoading: setCopyC2WLoading, copyData: result, setCheckedCampaigns: setCheckedCampaigns, appContext: appContext, setUserToSend })
    }

    // component to select user
    const UserSelectComponent = () => {
        const [displayUserSelect, setDisplayUserSelect] = useState(false)
        const [selected, setSelected] = useState([])

        const handleUserSelect = (name) => {
            if (selected.find(f => f === name)) {
                let temp = selected.filter(f => f !== name)
                setSelected(temp)
            } else {
                let temp = [...selected]
                temp.push(name)
                setSelected(temp)
            }
        }

        const handleUserSelectDone = () => {
            if (selected.length > 0) {
                if (username === selected.toString()) {
                    window.alert('This username is already set.')
                } else {
                    Cookies.set('userArray', selected.toString());
                    setSelected([]);
                    window.location.reload();
                }
            } else {
                window.alert('Select at least one user to proceed!')
            }
        }

        return (
            <div style={{ position: 'relative' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', fontSize: '20px' }}><FaEdit size={30} color="#4caf50" style={{ cursor: 'pointer' }} onClick={() => setDisplayUserSelect(!displayUserSelect)}/> Usernames: {username?.toString()?.replaceAll(",", " - ")}</div>
                <div style={{ position: 'absolute', height: '200px', width: '200px', background: '#FFF', border: '1px solid #AAA', borderRadius: '10px', padding: '5px', display: displayUserSelect ? 'flex' : 'none', flexDirection: 'column', gap: '5px' }}>
                    <div style={{ height: '30px', width: '100%', display: 'flex', gap: '5px' }}>
                        <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#EDF7EE', border: '1px solid #4caf50', borderRadius: '10px', cursor: 'pointer' }} onClick={() => handleUserSelectDone()}><FaCheck size={20} color="#4caf50" /></div>
                        <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#FDEEEE', border: '1px solid #ef5350', borderRadius: '10px', cursor: 'pointer' }} onClick={() => {setSelected([]); setDisplayUserSelect(false);}}><ImCross size={20} color="#ef5350" /></div>
                    </div>
                    <div style={{ flex: 1, width: '100%', display: 'flex', flexDirection: 'column', gap: '5px', overflowY: 'scroll' }}>
                        {users.filter(f => f.role === 'manager').map((name, i) => (
                            <div key={i} style={{ minHeight: '30px', width: '100%', background: selected.find(f => f === name.username) ? '#555' : '#EEE', color: selected.find(f => f === name.username) ? '#FFF' : '#000', padding: '5px', borderRadius: '10px', cursor: 'pointer' }} onClick={() => handleUserSelect(name.username)}>
                                {name.username}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (account === 'all') {
            setSettingsData([])
        } else {
            getSettingsData()
        }
        
        let actObj = accountList.find(f => f.act_id === account)

        if (actObj) {
            if (actObj.act_name === 'PLEBS 1') {
                getTikTokLive({ actName: 'PLEBS 1' })
            } else if (actObj.act_name === 'PLEBS 2') {
                getTikTokLive({ actName: 'PLEBS 2' })
            } else {
                setTiktokLive({ loading: false, data: [], campaign: '' })
            }
        }
    }, [account, refresh.adsets])

    // in all clients and all countries pages, refresh data on inner level
    useEffect(() => {
        if ((data.campaigns.length < 1) || (campaignName.name === '') || (levelUrlFor !== 'AllClients' && levelUrlFor !== 'AllCountries')) {
            return
        }

        const find_campaign = data.campaigns.find(f => f.campaign_name === campaignName.name)

        if (find_campaign) {
            setLevelURL(p => { return {
                ...p,
                spent: find_campaign?.spent ? Number(find_campaign?.spent) : 0,
                revenue: find_campaign?.revenue ? find_campaign?.revenue : 0,
                actions: find_campaign?.actions ? Number(find_campaign?.actions) : 0,
                clicks: find_campaign?.clicks ? find_campaign?.clicks : null
            }})
        }

        if (levelUrlFor === 'AllCountries') {
            const names = find_campaign?.url?.id ? Object.values(find_campaign?.url?.id)?.find(f => f !== '') : null
            const revenues = find_campaign?.url?.revenue ? Object.values(find_campaign?.url?.revenue)?.[0] : null
            const urls = find_campaign?.url?.url ? find_campaign?.url?.url : null
            const spent = find_campaign?.spent ? Number(find_campaign?.spent) : 0
            let final_revenue = {}
            let final_revenue_by_campaign = []

            if (names && revenues) {
                Object.keys(names).map(e => {
                    final_revenue[e] = revenues[names[e]] ? revenues[names[e]] : 0
                })
            }

            if (names && revenues) {
                Object.keys(names).map(e => {
                    final_revenue_by_campaign.push({
                        country: e,
                        campaign: names[e]
                    })
                })
            }

            setLevelCountry(p => { return { ...p, revenue: final_revenue, revenue_by_campaign: final_revenue_by_campaign, url: urls, totalSpent: spent, campaign: names }})
        }
    }, [data.campaigns])

    // on date change get country data again
    useEffect(() => {
        if (levelUrlFor === 'AllCountries' && campaignName.name !== '') {
            getSelectedCampaignCountryInfo({ cName: campaignName.name, revenue_by_campaign: levelCountry.revenue_by_campaign })
        }
    }, [toDate, fromDate])

    // if only 1 facebook account then directly show that
    useEffect(() => {
        if (getAccountList().length === 1) {
            setAccount(getAccountList()[0].act_id);
        }
    }, [])

    // URL level url component
    const LevelUrlComponent = () => {
        const [urlChangeLoading, setUrlChangeLoading] = useState(false)
        const [action, setAction] = useState({
            Adcombo: {state: false, ['%']: '0'},
            Bodis: {state: false, ['%']: '0'},
            Crossroads: {state: false, ['%']: '0'},
            Sedo: {state: false, ['%']: '0'},
            System1: {state: false, ['%']: '0'},
            Tonic: {state: false, ['%']: '0'}
        })

        // on url change change action state
        useEffect(() => {
            if (selectedCampaignInfo.url?.action) {
                setAction(selectedCampaignInfo.url.action)
            }
        }, [selectedCampaignInfo.url])

        // onchange function for the change% inputs
        const handleInput = ({ e, cli }) => {
            const sum = Object.values(action).map(e => Number(e["%"])).reduce((a, b) => a + b, 0) + Number(e.target.value) - action[cli]['%']

            if (sum > 100) {
                window.alert("The total % has exceeded 100. All the % as a whole should not exceed 100.")
            } else {
                setAction(p => {
                    return {
                        ...p,
                        [cli]: {
                            state: p?.[cli]?.state, ['%']: e.target.value
                        }
                    }
                })
            }
        }

        // prevent spin on scroll in change% inputs
        const numberInputOnWheelPreventChange = (e) => {
            // Prevent the input value change
            e.target.blur()
        
            // Prevent the page/container scrolling
            e.stopPropagation()
        
            let timeoutID;
            // Refocus immediately, on the next tick (after the current function is done)
            timeoutID = setTimeout(() => {
                e.target.focus()
            }, 0)
            
            return () => clearTimeout(timeoutID)
        }

        // api to change url state and %
        const urlChangeAPI = async () => {
            setUrlChangeLoading(true)
            const token = Cookies.get("token");
            const sum = Object.values(action).map(e => Number(e["%"])).reduce((a, b) => a + b, 0)

            if (sum !== 100) {
                window.alert('The % altogether should be equal to 100.')
                setUrlChangeLoading(false)
                return
            }

            let finalAction = {...action}
            let clientsArray = ['Adcombo', 'Bodis', 'Crossroads', 'Sedo', 'System1', 'Tonic']
            clientsArray.forEach(e => {
                finalAction[e]["%"] = (finalAction[e]["%"] === '') ? 0 : Number(finalAction[e]["%"])
            });

            await axios.post(`${host}/api/bidding/V2/traffic_distribution`,{
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                    "Authorization": "Bearer "+token,
                    "Content-Type": "application/json",
                },
                campaign_name: selectedCampaign ? Number(selectedCampaign) : '',
                action: { url: { ...selectedCampaignInfo.url, action: finalAction } }
            })
            .then((response) => {
                getSelectedCampaignInfo(Number(selectedCampaign))
                setUrlChangeLoading(false)
            })
            .catch((error) => {
                console.log(error)
                window.alert('Error: ' + error)
                setUrlChangeLoading(false)
                if (error?.response?.data?.message === 'Invalid') {
                    appContext.forceLogout()
                }
            })
        }

        // get calculations
        const urlCal = ({ small, capital }) => {
            const revenue = levelURL.revenue?.[small]
            const clicks = levelURL.clicks?.[small]
            const spent = levelURL.spent * (clicks / levelURL.clicks?.all)
            const actions = levelURL.actions * (selectedCampaignInfo.url?.action?.[capital]?.['%'] / 100)
            const result = revenue - spent
            const roi = ( ( revenue / spent ) - 1 ) * 100
            const cpl = spent / actions
            
            let colors = { result: '#000', roi: '#000' }

            if (result > 0) {
                colors.result = '#4caf50'
            } else if (result < 0) {
                colors.result = '#ef5350'
            }

            if (roi > 0) {
                colors.roi = '#4caf50'
            } else if (roi < 0) {
                colors.roi = '#ef5350'
            }

            return {
                revenue: revenue ? '$ '+revenue.toFixed(0) : <>&#8212;</>,
                spent: spent ? '$ '+spent.toFixed(0) : <>&#8212;</>,
                result: result ? '$ '+result.toFixed(0) : <>&#8212;</>,
                roi: (roi && roi !== Infinity) ? roi.toFixed(0)+' %' : <>&#8212;</>,
                cpl: (cpl && cpl !== Infinity) ? Math.abs(cpl).toFixed(3) : <>&#8212;</>,
                clicks: clicks ? clicks : <>&#8212;</>,
                colors: colors
            }
        }

        return (
            <div className="traffic-url-level-container" style={{ display: ((levelUrlFor === 'AllClients') && levelURL.display) ? 'flex' : 'none' }}>
                <div style={{ height: '100%', width: '100%', background: '#FFF', padding: '20px', borderRadius: '20px' }}>
                    <div className="total-settings" style={{ gap: '10px' }}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <p style={{ color: '#36a2eb', fontWeight: '600' }}>{selectedCampaign}</p>
                                <div onClick={() => urlChangeAPI()} style={{ height: '30px', width: 'fit-content', padding: '0px 10px', pointerEvents: (urlChangeLoading || selectedCampaignInfo.loading) ? 'none' : 'all', opacity: (urlChangeLoading || selectedCampaignInfo.loading) ? '50%' : '100%', background: '#4caf50', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', cursor: 'pointer' }}>
                                    {(urlChangeLoading || selectedCampaignInfo.loading) ? (
                                        <div className="loadingSpinner" style={{ height: '20px', width: '20px', border: '2px solid rgba(255,255,255,0.2)', borderTop: '2px solid #FFF' }}></div>
                                    ) : (
                                        <div style={{color: '#FFF'}}>Update</div>
                                    )}
                                </div>
                            </div>
                            <ImCross size={30} color="#F00" style={{ cursor: 'pointer' }} onClick={() => setLevelURL(p => {return{...p, display: false}})} onMouseEnter={e => e.currentTarget.style.opacity = '50%'} onMouseLeave={e => e.currentTarget.style.opacity = '100%'}/>
                        </div>

                        <div className="traffic-url-level-table">
                            <div className="traffic-url-level-table-head">
                                <div>O/F</div>
                                <div>Client</div>
                                <div>Url</div>
                                <div>Change%</div>
                                <div>Reven...</div>
                                <div>Spent</div>
                                <div>Result</div>
                                <div>ROI</div>
                                <div>CPL</div>
                                <div>Clicks</div>
                            </div>

                            {['Adcombo','Bodis','Crossroads','Sedo','System1','Tonic'].map((cli, i) => (
                                <div className="traffic-url-level-table-body" key={i}>
                                    <div className="total-settings-switch" style={{ width: 'fit-content', padding: '10px' }}>
                                        <div style={{ height: '30px', width: '50px', background: (action?.[cli]?.state) ? 'rgba(54,162,235,0.3)' : 'rgba(0,0,0,0.3)', pointerEvents: (urlChangeLoading || selectedCampaignInfo.loading) ? 'none' : 'all', opacity: (urlChangeLoading || selectedCampaignInfo.loading) ? '50%' : '100%' }}
                                            onClick={() => setAction(p => {
                                                return {
                                                    ...p,
                                                    [cli]: {
                                                        state: !p?.[cli]?.state, ['%']: p?.[cli]?.['%']
                                                    }
                                                }
                                            })}
                                        >
                                            <div style={{ height: '20px', width: '20px', top: '5px', left: (action?.[cli]?.state) ? '25px' : '5px', background: (action?.[cli]?.state) ? '#36a2eb' : '#555' }}></div>
                                        </div>
                                    </div>
                                    <div>{cli}</div>
                                    <div><p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{selectedCampaignInfo.url?.url?.[cli]}</p></div>
                                    <div style={{ padding: '10px' }}>
                                        <input type="number" value={action?.[cli]?.['%']}
                                            onChange={e => handleInput({ e: e, cli: cli })}
                                            onWheel={numberInputOnWheelPreventChange}
                                            disabled={urlChangeLoading || selectedCampaignInfo.loading}
                                            style={{ maxWidth: '100%', height: '30px', opacity: (urlChangeLoading || selectedCampaignInfo.loading) ? '50%' : '100%' }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    urlChangeAPI()
                                                }
                                            }}
                                        />
                                    </div>
                                    <div>{urlCal({ small: cli.toLowerCase(), capital: cli }).revenue}</div>
                                    <div>{urlCal({ small: cli.toLowerCase(), capital: cli }).spent}</div>
                                    <div style={{ color: urlCal({ small: cli.toLowerCase(), capital: cli }).colors.result }}>{urlCal({ small: cli.toLowerCase(), capital: cli }).result}</div>
                                    <div style={{ color: urlCal({ small: cli.toLowerCase(), capital: cli }).colors.roi }}>{urlCal({ small: cli.toLowerCase(), capital: cli }).roi ? urlCal({ small: cli.toLowerCase(), capital: cli }).roi : '0'}</div>
                                    <div>{urlCal({ small: cli.toLowerCase(), capital: cli }).cpl}</div>
                                    <div>{urlCal({ small: cli.toLowerCase(), capital: cli }).clicks}</div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // URL level url country component
    const LevelUrlCountryComponent = () => {
        // get calculations
        const urlCal = (e) => {
            const find_data = levelCountry.data.find(f => f.country === e)
            // const revenue = (levelCountry.revenue?.[e] && find_data.clicks_percent) ? Number(levelCountry.revenue?.[e])*find_data.clicks_percent : 0
            const revenue = (tonicRevenues?.[levelCountry.campaign?.[e]] && find_data.clicks_percent) ? Number(tonicRevenues?.[levelCountry.campaign?.[e]])*find_data.clicks_percent : 0
            const spent = find_data?.spend ? Number(find_data?.spend) : 0
            const result = revenue - spent
            const roi = ( ( revenue / spent ) - 1 ) * 100
            const lead = find_data?.actions?.find(f => f.action_type === 'lead') ? Number(find_data.actions.find(f => f.action_type === 'lead').value) : 0
            const clicks = find_data?.actions?.find(f => f.action_type === 'link_click') ? Number(find_data.actions.find(f => f.action_type === 'link_click').value) : 0
            const cpc = spent / clicks
            const cpl = spent / lead

            let colors = { result: '#000', roi: '#000' }

            if (result > 0) {
                colors.result = '#4caf50'
            } else if (result < 0) {
                colors.result = '#ef5350'
            }

            if (roi > 0) {
                colors.roi = '#4caf50'
            } else if (roi < 0) {
                colors.roi = '#ef5350'
            }

            return {
                revenue: revenue ? '$ '+revenue.toFixed(1) : <>&#8212;</>,
                spent: spent ? '$ '+spent.toFixed(1) : <>&#8212;</>,
                result: result ? '$ '+result.toFixed(1) : <>&#8212;</>,
                roi: (roi && roi !== Infinity) ? roi.toFixed(0)+' %' : <>&#8212;</>,
                lead: lead ? lead : <>&#8212;</>,
                clicks: clicks ? clicks : <>&#8212;</>,
                cpc: (cpc && cpc !== Infinity) ? Math.abs(cpc).toFixed(3) : <>&#8212;</>,
                cpl: (cpl && cpl !== Infinity) ? Math.abs(cpl).toFixed(3) : <>&#8212;</>,
                colors: colors
            }
        }

        return (
            <div className="traffic-url-level-container" style={{ display: ((levelUrlFor === 'AllCountries') && levelCountry.display) ? 'flex' : 'none' }}>
                <div style={{ height: '100%', width: '100%', background: '#FFF', padding: '20px', borderRadius: '20px' }}>
                    <div className="total-settings" style={{ height: '100%', width: '100%', gap: '10px' }}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <p style={{ color: '#36a2eb', fontWeight: '600' }}>{selectedCampaign}</p>
                                {(selectedCampaignInfo.loadingCountry || loading.main_table) ? (<div className="loader"/>) : (<></>)}
                            </div>

                            <ImCross size={30} color="#F00" style={{ cursor: 'pointer' }} onClick={() => setLevelCountry(p => {return{...p, display: false}})} onMouseEnter={e => e.currentTarget.style.opacity = '50%'} onMouseLeave={e => e.currentTarget.style.opacity = '100%'}/>
                        </div>

                        <div className="traffic-url-level-table">
                            <div className="traffic-url-level-table-head" style={{ gridTemplateColumns: '80px 80px minmax(100px, 1fr) 80px 80px 80px 80px 80px 80px 80px 80px' }}>
                                <div >Country</div>
                                <div >Camp...</div>
                                <div >Url</div>
                                <div style={{ background: 'rgba(75, 192, 192, 0.05)', borderLeft: '3px solid rgba(75, 192, 192, 0.3)' }}>Reven...</div>
                                <div style={{ background: 'rgba(75, 192, 192, 0.05)' }}>Spent</div>
                                <div style={{ background: 'rgba(75, 192, 192, 0.05)' }}>Result</div>
                                <div style={{ background: 'rgba(75, 192, 192, 0.05)' }}>ROI</div>
                                <div style={{ background: 'rgba(153, 102, 255, 0.05)', borderLeft: '3px solid rgba(153, 102, 255, 0.3)' }}>Lead</div>
                                <div style={{ background: 'rgba(153, 102, 255, 0.05)' }}>Clicks</div>
                                <div style={{ background: 'rgba(153, 102, 255, 0.05)' }}>CPC</div>
                                <div style={{ background: 'rgba(153, 102, 255, 0.05)' }}>CPL</div>
                            </div>

                            {levelCountry.countries.map((e, i) => (
                                <div className="traffic-url-level-table-body" style={{ gridTemplateColumns: '80px 80px minmax(100px, 1fr) 80px 80px 80px 80px 80px 80px 80px 80px' }} key={i}>
                                    <div >{e}</div>
                                    <div >{levelCountry.campaign?.[e]}</div>
                                    <div ><p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{levelCountry.url?.[e]}</p></div>
                                    <div style={{ background: 'rgba(75, 192, 192, 0.05)', borderLeft: '3px solid rgba(75, 192, 192, 0.3)' }}>{urlCal(e).revenue}</div>
                                    <div style={{ background: 'rgba(75, 192, 192, 0.05)' }}>{urlCal(e).spent}</div>
                                    <div style={{ background: 'rgba(75, 192, 192, 0.05)', color: urlCal(e).colors.result }}>{urlCal(e).result}</div>
                                    <div style={{ background: 'rgba(75, 192, 192, 0.05)', color: urlCal(e).colors.roi }}>{urlCal(e).roi}</div>
                                    <div style={{ background: 'rgba(153, 102, 255, 0.05)', borderLeft: '3px solid rgba(153, 102, 255, 0.3)' }}>{urlCal(e).lead}</div>
                                    <div style={{ background: 'rgba(153, 102, 255, 0.05)' }}>{urlCal(e).clicks}</div>
                                    <div style={{ background: 'rgba(153, 102, 255, 0.05)' }}>{urlCal(e).cpc}</div>
                                    <div style={{ background: 'rgba(153, 102, 255, 0.05)' }}>{urlCal(e).cpl}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="trafficContainer" style={{ position: 'relative' }}>
            {/* url level */}
            <LevelUrlComponent />
            {/* country level */}
            <LevelUrlCountryComponent />

            {/* all campaigns between the dates */}
            <div className="trafficTopContainer">
                <div className="trafficTopWrapper">
                    {/* logo and date */}
                    <div className="logoDateContainer">
                        <div className="logoWrapper">
                            <img src={logo} alt="LOGO"/>
                        </div>

                        <div className="dateWrapper">
                            <div className="dateWrapperDiv">
                                <label htmlFor="fromTopDate">From: </label>
                                <input id="fromTopDate" type="date" className="datePicker" value={fromDate} onChange={(e) => {e.preventDefault(); setFromDate(e.target.value);}}/>
                            </div>
                            <div className="dateWrapperDiv">
                                <label htmlFor="toTopDate">To: </label>
                                <input id="toTopDate" type="date" className="datePicker" value={toDate} onChange={(e) => {e.preventDefault(); setToDate(e.target.value);}}/>
                            </div>
                        </div>
                    </div>

                    {/* total values */}
                    <div className="totalContainer">
                        {(appContext.role === 'admin') && (<UserSelectComponent/>)}

                        <div style={{display: 'flex'}}>
                            <div className="totalTitle">{fromDate}</div>
                            <div className="totalTitle">&nbsp;&#8596;&nbsp;</div>
                            <div className="totalTitle">{toDate}</div>
                        </div>
                        {(typeof username !== 'undefined' && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                            (username !== '') ? (
                                (username.split(',').length === 1) ? (
                                    <div style={{ width: '100%', display: 'flex', gap: '10px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', marginBottom: '5px' }}>
                                            <p style={{ marginBottom: '0px', fontSize: '20px', fontWeight: '600' }}>Select Account:</p>
                                            {(actionLoading.settings && (trafficName.includes('facebook') || trafficName.includes('tiktok'))) ? (
                                                <div className="loader" style={{ border: '5px solid #0000FF', borderTop: '5px solid #FFFFFF', height: '24px', width: '24px' }}></div>
                                            ) : (<></>)}
                                        </div>
                                        <select value={account} onChange={(e) => {setAccount(e.target.value); setCampaignName(p => { return { ...p, name: '', act_id: '' } }); setCheckedCampaigns([]); setCheckedAdsets([]);}} style={{ height: '50px', width: '100%', maxWidth: '300px', background: '#E8F1FE', border: '2px solid #1877F2', outline: 0, borderRadius: '10px' }}>
                                            <option value='all'>All</option>
                                            {getAccountList()?.map((e, i) => (
                                                <option value={e.act_id} key={i}>{e.act_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                ) : (<></>)
                            ) : (<></>)
                        ) : (<></>)}

                        <div style={{lineHeight: '50px', fontSize: '20px', fontWeight: 700}}>Total:</div>

                        <div className="totalCardContainer">
                            <div className="totalCard">
                                <div className="totalCardContent">
                                    <p>Revenue</p>
                                    <div>${totalValue.revenue ? Number(totalValue.revenue).toFixed(0) : ' -'}</div>
                                    {(Cookies.get('userArray') === 'UniverseFenix') ? (
                                        <></>
                                    ) : (
                                        <div style={{ fontSize: '12px' }}>RT${totalValue.revenueTotal ? Number(totalValue.revenueTotal).toFixed(0) : ' -'}</div>
                                    )}
                                </div>
                            </div>
                            <div className="totalCard">
                                <div className="totalCardContent">
                                    <p>Spent</p>
                                    <div>${totalValue.spent ? Number(totalValue.spent).toFixed(0) : ' -'}</div>
                                </div>
                            </div>
                            <div className="totalCard">
                                <div className="totalCardContent">
                                    <p>Result</p>
                                    <div style={{color: `${((totalValue.revenue - totalValue.spent) > 0) ? '#4caf50' : '#ef5350'}`}}>${(totalValue.revenue || totalValue.spent) ? (totalValue.revenue - totalValue.spent).toFixed(0) : ' -'}</div>
                                </div>
                            </div>
                            <div className="totalCard">
                                <div className="totalCardContent">
                                    <p>ROI%</p>
                                    <div style={{color: `${(((totalValue.revenue / totalValue.spent-1)*100) > 0) ? '#4caf50' : '#ef5350'}`}}>{(totalValue.revenue || totalValue.spent) ? ((totalValue.revenue / totalValue.spent-1)*100).toFixed(0) : '- '}%</div>
                                </div>
                            </div>
                            <div className="totalCard">
                                <div className="totalCardContent">
                                    <p style={{ fontSize: '12px', fontWeight: 600, color: '#36a2eb' }}>{moment().utc().add(8, 'hours').subtract(minusDate, 'days').format('YYYY-MM-DD')}</p>
                                    <p>Off Camps</p>
                                    {(loading.off_campaigns) ? (
                                        <div className="loader" style={{ height: '20px', width: '20px' }}/>
                                    ) : (
                                        <div>{offCampaign?.count?.offCampaignList ? offCampaign?.count?.offCampaignList : '-'}</div>
                                    )}
                                </div>
                            </div>
                            <div className="totalCard">
                                <div className="totalCardContent">
                                    <p style={{ fontSize: '12px', fontWeight: 600, color: '#36a2eb' }}>{activeCampCount.date}</p>
                                    {(activeCampCount.error === '') ? (
                                        <>
                                            <p>Active Camps</p>
                                            {(loading.active_campaigns) ? (
                                                <div className="loader" style={{ height: '20px', width: '20px' }}/>
                                            ) : (
                                                <div>{activeCampCount.active_campaigns ? activeCampCount.active_campaigns : '-'}</div>
                                            )}
                                        </>
                                    ) : (
                                        <p>{activeCampCount.error}</p>
                                    )}
                                </div>
                            </div>
                            <div className="totalCard">
                                <div className="totalCardContent">
                                    <p>Green/Red %</p>
                                    <div style={{color: `${(typeof(totalValue["green/red"]) === 'number') ? ((totalValue["green/red"]*100 > 0) ? '#4caf50' : '#ef5350') : '#ef5350'}`}}>{(typeof(totalValue["green/red"]) === 'number') ? (totalValue["green/red"]*100).toFixed(0) : '- '}%</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Chart */}
                    <div className="chartContainer" style={{ height: '500px', flexDirection: 'column', marginBottom: '10px' }} >
                        <div className="chartWrapper" style={{ position: 'relative' }}>
                            {(loading.main_table) ? (
                                <div style={{ position: 'absolute', height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(255,255,255,0.6)', gap: '10px' }}>
                                    <div className="loader" style={{ height: '40px', width: '40px', borderRadius: '100%', border: '5px solid #1976d2', borderTop: '5px solid #E8F1FB' }}/>
                                    <p>Loading</p>
                                </div>
                            ) : (<></>)}
                            <Line options={optionsROI} data={dataResult} />
                        </div>
                    </div>

                    {/* select campaigns & copy campaign to widget button */}
                    {(typeof username !== 'undefined' && (trafficToSend === 'Facebook' || trafficToSend === 'TikTok')) ? (
                        (username !== '') ? (
                            (username.split(',').length === 1) ? (
                                <>
                                    <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '1fr 40px', gap: '10px', borderTop: '0.5px solid #000000', borderBottom: '0.5px solid #000000', marginBottom: '10px' }}>
                                        <div className="listDateContainer" style={{ height: 'fit-content', borderBottom: 'none', gap: '10px', alignItems: 'center', flexWrap: 'wrap', margin: '0px' }}>
                                            <p>Select campaigns:</p>
                                            <button style={{ height: '40px', padding: '0px 10px', borderRadius: '5px', border: 0, outline: 0, background: '#555555', color: '#FFFFFF', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={e => handleCheckAllCampaigns(e)}><MdDoneAll size={30} color={'#FFFFFF'}/></button>
                                            <button style={{ height: '40px', padding: '0px 10px', borderRadius: '5px', border: '2px solid #AAAAAA', outline: 0, background: '#FFFFFF', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={e => {e.preventDefault(); setCheckedCampaigns([])}}><MdRemoveDone size={30} color={'#AAAAAA'}/></button>
                                            {(appContext.role === 'admin') ? (
                                                (checkedCampaigns.length > 0) ? (
                                                    <>
                                                        {userLoading ? (
                                                            <div className='loader'></div>
                                                        ) : (
                                                            <select className='widget2-select' value={userToSend} disabled={copyC2WLoading} onChange={e => setUserToSend(e.target.value)}>
                                                                <option value="">Select Manager</option>
                                                                {users?.filter(f => f.role === 'manager').map((e, i) => (
                                                                    <option value={e.username} key={i}>{e.username}</option>
                                                                ))}
                                                            </select>
                                                        )}
                                                        <button style={{ height: '40px', padding: '0px 10px', borderRadius: '5px', border: 0, outline: 0, background: '#555', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: copyC2WLoading ? '50%' : '100%' }} disabled={copyC2WLoading}
                                                            onClick={() => {
                                                                if (checkedCampaigns.length < 1) {
                                                                    window.alert('Please select some campaigns first.')
                                                                    setCopyC2WLoading(false)
                                                                    return
                                                                }
                                                                if (window.confirm('Do you want to copy selected ' + checkedCampaigns.length + ' campaigns to widget?')) {
                                                                    handleCopyCampaign()
                                                                } else {
                                                                    return
                                                                }
                                                            }}
                                                        >
                                                            {copyC2WLoading ? (
                                                                <div className="loader" style={{ height: '30px', width: '30px' }}/>
                                                            ) : (
                                                                <FaCopy size={30} color={'#FFF'}/>
                                                            )}
                                                        </button>
                                                    </>
                                                ) : (<></>)
                                            ) : (
                                                <button style={{ height: '40px', padding: '0px 10px', borderRadius: '5px', border: 0, outline: 0, background: '#555', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: copyC2WLoading ? '50%' : '100%' }} disabled={copyC2WLoading}
                                                    onClick={() => {
                                                        if (checkedCampaigns.length < 1) {
                                                            window.alert('Please select some campaigns first.')
                                                            setCopyC2WLoading(false)
                                                            return
                                                        }
                                                        if (window.confirm('Do you want to copy selected ' + checkedCampaigns.length + ' campaigns to widget?')) {
                                                            handleCopyCampaign()
                                                        } else {
                                                            return
                                                        }
                                                    }}
                                                >
                                                    {copyC2WLoading ? (
                                                        <div className="loader" style={{ height: '30px', width: '30px' }}/>
                                                    ) : (
                                                        <FaCopy size={30} color={'#FFF'}/>
                                                    )}
                                                </button>
                                            )}
                                        </div>
                                        <button style={{ height: '40px', width: '40px', borderRadius: '5px', marginTop: '10px', background: '#EEE', border: 0, outline: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: (loading.main_table || loading.pulse_schedule || loading.fb_live_status || actionLoading.multi_fb_status || actionLoading.multi_delete_widget || actionLoading.settings || actionLoading.update_adsets) ? 'not-allowed' : 'pointer', opacity: (loading.main_table || loading.pulse_schedule || loading.fb_live_status || actionLoading.multi_fb_status || actionLoading.multi_delete_widget || actionLoading.settings || actionLoading.update_adsets) ? '50%' : '100%' }}
                                            disabled={loading.main_table || loading.pulse_schedule || loading.fb_live_status || actionLoading.multi_fb_status || actionLoading.multi_delete_widget || actionLoading.settings || actionLoading.update_adsets}
                                            onClick={() => {
                                                setRefresh(p => { return { ...p, main_table: !p.main_table, off_campaigns: !p.off_campaigns, adsets: !p.adsets } })
                                                appContext.setRulesInfo(p => { return { ...p, refresh: !p.refresh } })
                                            }}
                                        >
                                            {(loading.main_table || loading.pulse_schedule || loading.fb_live_status || actionLoading.multi_fb_status || actionLoading.multi_delete_widget || actionLoading.settings || actionLoading.update_adsets) ? <div className="loader"/> : <HiRefresh size={20} color="#555"/>}
                                        </button>
                                    </div>

                                    {(checkedCampaigns.length > 0) ? (
                                        <div style={{ height: 'fit-content', display: 'flex', flexWrap: 'wrap', gap: '19px', marginBottom: '10px' }}>
                                            <div style={{ height: 'fit-content', width: '140px', display: 'flex', flexDirection: 'column', alignItems: 'center', background: 'rgba(245, 125, 13, 0.1)', border: '1px solid rgba(245, 125, 13, 0.5)', borderRadius: '5px', padding: '5px', gap: '5px', opacity: (actionLoading.multi_fb_status || actionLoading.multi_delete_widget || (appContext.rulesInfo.singleLoading.length > 0)) ? '50%' : '100%', pointerEvents: (actionLoading.multi_fb_status || actionLoading.multi_delete_widget || (appContext.rulesInfo.singleLoading.length > 0)) ? 'none' : 'all' }}>
                                                <p style={{ fontWeight: '700', color: '#F57D0D' }}>AUTO</p>
                                                <div style={{ display: 'flex', gap: '5px' }}>
                                                    <button style={{ height: '30px', width: '60px', fontSize: '14px', borderRadius: '5px', background: '#4caf50', border: 0, outline: 0, color: '#FFFFFF', cursor: 'pointer' }}
                                                        onClick={e => {
                                                            appContext.setDialogInfo({
                                                                mode: "Confirm",
                                                                type: "Confirm",
                                                                message: [
                                                                    { type: "Confirm", title: "Confirm to turn AUTO ON", content: "You will proceed to turn ON the selected campaign AUTOs." },
                                                                ],
                                                                confirm_function: () => handleUpdateCheckedRules(true),
                                                                cancel_function: () => {}
                                                            })
                                                        }}
                                                    >
                                                        ON
                                                    </button>
                                                    <button style={{ height: '30px', width: '60px', fontSize: '14px', borderRadius: '5px', background: '#F47174', border: 0, outline: 0, color: '#FFFFFF', cursor: 'pointer' }}
                                                        onClick={e => {
                                                            appContext.setDialogInfo({
                                                                mode: "Confirm",
                                                                type: "Confirm",
                                                                message: [
                                                                    { type: "Confirm", title: "Confirm to turn AUTO OFF", content: "You will proceed to turn OFF the selected campaign AUTOs." },
                                                                ],
                                                                confirm_function: () => handleUpdateCheckedRules(false),
                                                                cancel_function: () => {}
                                                            })
                                                        }}
                                                    >
                                                        OFF
                                                    </button>
                                                </div>
                                            </div>
                                            <div style={{ height: '65px', width: '2px', borderRadius: '2px', background: '#CCC' }} />
                                            <div style={{ height: 'fit-content', width: '140px', display: 'flex', flexDirection: 'column', alignItems: 'center', background: 'rgba(25, 118, 210, 0.1)', border: '1px solid rgba(25, 118, 210, 0.5)', borderRadius: '5px', padding: '5px', gap: '5px', opacity: (actionLoading.multi_fb_status || actionLoading.multi_delete_widget || (appContext.rulesInfo.singleLoading.length > 0)) ? '50%' : '100%', pointerEvents: (actionLoading.multi_fb_status || actionLoading.multi_delete_widget || (appContext.rulesInfo.singleLoading.length > 0)) ? 'none' : 'all' }}>
                                                <p style={{ fontWeight: '700', color: '#1976d2' }}>STATUS</p>
                                                <div style={{ display: 'flex', gap: '5px' }}>
                                                    <button style={{ height: '30px', width: '60px', fontSize: '14px', borderRadius: '5px', background: '#4caf50', border: 0, outline: 0, color: '#FFFFFF', cursor: 'pointer' }}
                                                        onClick={e => {
                                                            appContext.setDialogInfo({
                                                                mode: "Confirm",
                                                                type: "Confirm",
                                                                message: [
                                                                    { type: "Confirm", title: "Confirm to turn STATUS ON", content: "You will proceed to turn ON the selected campaign STATUS." },
                                                                ],
                                                                confirm_function: () => handleUpdateCheckedStatus({e, state: true}),
                                                                cancel_function: () => {}
                                                            })
                                                        }}
                                                    >
                                                        ON
                                                    </button>
                                                    <button style={{ height: '30px', width: '60px', fontSize: '14px', borderRadius: '5px', background: '#F47174', border: 0, outline: 0, color: '#FFFFFF', cursor: 'pointer' }}
                                                        onClick={e => {
                                                            appContext.setDialogInfo({
                                                                mode: "Confirm",
                                                                type: "Confirm",
                                                                message: [
                                                                    { type: "Confirm", title: "Confirm to turn STATUS OFF", content: "You will proceed to turn OFF the selected campaign STATUS." },
                                                                ],
                                                                confirm_function: () => handleUpdateCheckedStatus({e, state: false}),
                                                                cancel_function: () => {}
                                                            })
                                                        }}
                                                    >
                                                        OFF
                                                    </button>
                                                </div>
                                            </div>
                                            <div style={{ height: '65px', width: '2px', borderRadius: '2px', background: '#CCC' }} />
                                            <div style={{ height: 'fit-content', width: '140px', display: 'flex', flexDirection: 'column', alignItems: 'center', background: 'rgba(255, 95, 95, 0.1)', border: '1px solid #F47174', borderRadius: '5px', padding: '5px', gap: '5px', opacity: (actionLoading.multi_fb_status || actionLoading.multi_delete_widget || (appContext.rulesInfo.singleLoading.length > 0)) ? '50%' : '100%', pointerEvents: (actionLoading.multi_fb_status || actionLoading.multi_delete_widget || (appContext.rulesInfo.singleLoading.length > 0)) ? 'none' : 'all' }}>
                                                <p style={{ fontWeight: '700', color: '#F47174' }}>DELETE</p>
                                                <div style={{ display: 'flex', gap: '5px' }}>
                                                    <button style={{ height: '30px', width: '120px', fontSize: '14px', borderRadius: '5px', background: '#F47174', border: 0, outline: 0, color: '#FFFFFF', cursor: 'pointer' }}
                                                        onClick={e => {
                                                            appContext.setDialogInfo({
                                                                mode: "Confirm",
                                                                type: "Delete",
                                                                message: [
                                                                    { type: "Delete", title: "Confirm to DELETE WIDGET", content: "The selected campaigns WIDGET will be permanently deleted from the system." },
                                                                ],
                                                                confirm_function: () => handleOffAndDeleteCampaign(e),
                                                                cancel_function: () => {}
                                                            })
                                                        }}
                                                    >
                                                        Delete Widget
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (<></>)}
                                </>
                            ) : (<></>)
                        ) : (<></>)
                    ) : (<></>)}

                    {/* datagrid */}
                    <div className="dataGridContainer">
                        <DataGrid
                            components={{ Toolbar: GridToolbar, Footer: CustomDatagridFooter }}
                            initialState={{
                                sorting: {
                                sortModel: [{ field: 'spent', sort: 'desc' }],
                                },
                            }}
                            sx={{
                                boxShadow: 3,
                                padding: 0,
                                '& .datagridHeader': {
                                    color: '#1976d2',
                                },
                                '& .firstBlock': {
                                    background: 'rgba(75, 192, 192, 0.05)',
                                },
                                '& .firstBlockLeftBorder': {
                                    borderLeft: '3px solid rgba(75, 192, 192, 0.3)',
                                },
                                '& .secondBlock': {
                                    background: 'rgba(153, 102, 255, 0.05)',
                                },
                                '& .secondBlockLeftBorder': {
                                    borderLeft: '3px solid rgba(153, 102, 255, 0.3)',
                                },
                                '& .thirdBlock': {
                                    background: 'rgba(54, 162, 235, 0.05)',
                                },
                                '& .thirdBlockLeftBorder': {
                                    borderLeft: '3px solid rgba(54, 162, 235, 0.3)',
                                },
                                '& .fourthBlock': {
                                    background: 'rgba(251, 96, 104, 0.05)',
                                },
                                '& .fourthBlockLeftBorder': {
                                    borderLeft: '3px solid rgba(251, 96, 104, 0.3)',
                                },
                                '& .MuiDataGrid-cell': {
                                    padding: 0,
                                },
                            }}
                            loading={loading.main_table}
                            getRowHeight={() => 'auto'} 
                            disableSelectionOnClick={true}
                            rows={totalRows}
                            columns={(username === 'Southpow') ? southpowTotalColumns : totalColumns}
                        />
                    </div>

                    {/* datagrid live TikTok */}
                    {(trafficToSend === 'TikTok') ? (
                        <>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <p style={{ margin: '20px 0px', fontWeight: 600, fontSize: '20px' }}>Live TikTok</p>
                                <button className="tiktok-live-refresh" onClick={e => {
                                    e.preventDefault()
                                    let actObj = accountList.find(f => f.act_id === account)

                                    if (actObj) {
                                        if (actObj.act_name === 'PLEBS 1') {
                                            getTikTokLive({ actName: 'PLEBS 1' })
                                        } else if (actObj.act_name === 'PLEBS 2') {
                                            getTikTokLive({ actName: 'PLEBS 2' })
                                        } else {
                                            setTiktokLive({ loading: false, data: [], campaign: '' })
                                        }
                                    }
                                }}>Refresh <HiRefresh size={20}/></button>
                            </div>
                            <div className="dataGridContainer">
                                <DataGrid
                                components={{ Toolbar: GridToolbar }}
                                sx={{
                                    boxShadow: 3,
                                    '& .datagridHeader': {
                                        color: '#1976d2',
                                    },
                                    '& .purpleCell': {
                                        background: 'rgba(153, 102, 255, 0.1)',
                                    }
                                }}
                                loading={tiktokLive.loading}
                                getRowHeight={() => 'auto'} 
                                // getRowId={(row) => row.id}
                                rows={tiktokLiveRow} columns={tiktokLiveColumns} disableSelectionOnClick={true}/>
                            </div>
                        </>
                    ) : (<></>)}
                </div>
            </div>
        </div>
    )
}

export default Traffic