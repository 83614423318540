import React, { useState, useEffect } from "react";
import './fbFanPages.css'
import moment from "moment";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from "react-chartjs-2";
import { FaArrowUpLong, FaArrowDownLong } from "react-icons/fa6";
import { LiaDotCircle } from "react-icons/lia";

import facebookLogo from "../../assets/facebookLogo.png";
import useFetchFFPData from "../../hooks/useFetchFFPData";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const FFPData = () => {
    const [global, setGlobal] = useState({ expand_row: null, fan_page_filter: '', fan_page_from: moment().subtract(32, 'days').format('YYYY-MM-DD'), fan_page_to: moment().subtract(2, 'days').format('YYYY-MM-DD') })
    const [loading, setLoading] = useState({ fan_page: false, fan_page_revenue: false })
    const [filteredFanPageData, setFilteredFanPageData] = useState([])
    const [revenueChartData, setRevenueChartData] = useState([])

    const fetch = useFetchFFPData({ global, setLoading })
    const [data] = fetch

    const fb_fan_page_links = [
        {
            "page_name": "Cars & More",
            "url": "https://www.facebook.com/profile.php?id=100093398494532"
        },
        {
            "page_name": "Healthy Mambo",
            "url": "https://www.facebook.com/healthy.mambo912/"
        },
        {
            "page_name": "Hispano Datos - Automotores",
            "url": "https://www.facebook.com/profile.php?id=100093545676606"
        },
        {
            "page_name": "Hispano Datos - Moda",
            "url": "https://www.facebook.com/profile.php?id=100093623312722"
        },
        {
            "page_name": "Hispano Datos - Salud",
            "url": "https://www.facebook.com/profile.php?id=100093514326978"
        },
        {
            "page_name": "HispanoDatos",
            "url": "https://www.facebook.com/profile.php?id=100093799988544"
        },
        {
            "page_name": "Intellectual Nuggets - Cars",
            "url": "https://www.facebook.com/profile.php?id=100094219261472"
        },
        {
            "page_name": "Intellectual Nuggets - Health",
            "url": "https://www.facebook.com/profile.php?id=100093655942601"
        },
        {
            "page_name": "Intellectual Nuggets - Senior",
            "url": "https://www.facebook.com/profile.php?id=100093793938717"
        },
        {
            "page_name": "Intellectual Nuggets - Tech",
            "url": "https://www.facebook.com/profile.php?id=100093471340365"
        },
        {
            "page_name": "Maj 5",
            "url": "https://www.facebook.com/maj5autoblog/"
        },
        {
            "page_name": "NFT Forever",
            "url": "https://www.facebook.com/nft.forever.hk/"
        },
        {
            "page_name": "Options Crypto Trade",
            "url": "https://www.facebook.com/profile.php?id=100083649819217"
        },
        {
            "page_name": "Sobre Autos",
            "url": "https://www.facebook.com/sobre.autos912/"
        },
        {
            "page_name": "Tech Mambo",
            "url": "https://www.facebook.com/tech.mambo912/"
        },
        {
            "page_name": "The Expert Roofer",
            "url": "https://www.facebook.com/profile.php?id=100067997484823"
        },
        {
            "page_name": "Top Finance Information",
            "url": "https://www.facebook.com/profile.php?id=100093393816857"
        },
        {
            "page_name": "Travel Information Search",
            "url": "https://www.facebook.com/profile.php?id=100091332376300"
        },
        {
            "page_name": "Travel Mambo",
            "url": "https://www.facebook.com/travel.mambo912/"
        },
        {
            "page_name": "Tu Salud",
            "url": "https://www.facebook.com/profile.php?id=100081929423099"
        },
        {
            "page_name": "Vehicle Mambo",
            "url": "https://www.facebook.com/profile.php?id=100083172866581"
        },
        {
            "page_name": "What Is Health",
            "url": "https://www.facebook.com/whatishealthok/"
        },
        {
            "page_name": "Intellectual Nuggets - Apparel",
            "url": "https://www.facebook.com/profile.php?id=100093233932301"
        },
        {
            "page_name": "INtellectual Nuggets - Cars",
            "url": "https://www.facebook.com/profile.php?id=100094219261472"
        },
        {
            "page_name": "Intellectual Nuggets - House",
            "url": "https://www.facebook.com/profile.php?id=100093440741747"
        },
        {
            "page_name": "Intellectual Nuiggets - Travel",
            "url": "https://www.facebook.com/profile.php?id=100093571287340"
        },
        {
            "page_name": "South Cell Phone Offers",
            "url": "https://www.facebook.com/profile.php?id=61559091841471"
        },
        {
            "page_name": "Exclusive Search Club",
            "url": "https://www.facebook.com/profile.php?id=61558622889862"
        },
        {
            "page_name": "Best Deals Search",
            "url": "https://www.facebook.com/profile.php?id=61560288731802"
        },
        {
            "page_name": "Comparison Matrix",
            "url": "https://www.facebook.com/profile.php?id=100093314456951"
        },
        {
            "page_name": "Deal Discovery Center",
            "url": "https://www.facebook.com/profile.php?id=61558454195460"
        },
        {
            "page_name": "Home Ideas",
            "url": "https://www.facebook.com/profile.php?id=100093833644400"
        },
        {
            "page_name": "Job & Work",
            "url": "https://www.facebook.com/profile.php?id=61559845016518"
        },
        {
            "page_name": "Life Pleasures",
            "url": "https://www.facebook.com/profile.php?id=61559261867024"
        },
        {
            "page_name": "Pants & Skirts",
            "url": "https://www.facebook.com/profile.php?id=61559621404493"
        },
        {
            "page_name": "Search Insights",
            "url": "https://www.facebook.com/profile.php?id=61558488933585"
        },
        {
            "page_name": "Super Deals Search",
            "url": "https://www.facebook.com/profile.php?id=61558156215974"
        },
        {
            "page_name": "The Wise Shopper",
            "url": "https://www.facebook.com/profile.php?id=61551632355846"
        },
        {
            "page_name": "Awesome Deals",
            "url": "https://www.facebook.com/profile.php?id=61551882302932"
        },
        {
            "page_name": "Do Smart Housing",
            "url": "https://www.facebook.com/profile.php?id=61551633285886"
        },
        {
            "page_name": "Financial Services",
            "url": "https://www.facebook.com/profile.php?id=61551664063743"
        },
        {
            "page_name": "Magic Deals",
            "url": "https://www.facebook.com/profile.php?id=61551699037821"
        },
        {
            "page_name": "Offer Search",
            "url": "https://www.facebook.com/profile.php?id=61552138100062"
        },
        {
            "page_name": "Phoenix Shopping",
            "url": "https://www.facebook.com/profile.php?id=61551920669875"
        },
        {
            "page_name": "Search Heroes",
            "url": "https://www.facebook.com/profile.php?id=61551674892665"
        },
        {
            "page_name": "Search Masters",
            "url": "https://www.facebook.com/profile.php?id=61551935488963"
        },
        {
            "page_name": "Shopping Offers Discovery",
            "url": "https://www.facebook.com/profile.php?id=61552057164063"
        }
    ]

    // chart js options settings
    const revenue_chart_options = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Daily Revenue Chart',
            },
            tooltip: {
                mode: 'index',
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                        }
                        return label;
                    }
                }
            },
        }
    };

    // chart js datasets for total revenue page and post
    const revenue_chart_data = {
        labels: revenueChartData.map(e => e?.date),
        datasets: [
            {
                type: 'bar',
                label: 'Page',
                data: revenueChartData.map(e => e?.page),
                borderColor: 'rgb(54, 162, 235, 1)',
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderWidth: 1,
            },
            {
                type: 'bar',
                label: 'Post',
                data: revenueChartData.map(e => e?.post),
                borderColor: 'rgb(153, 102, 255, 1)',
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                borderWidth: 1,
            }
        ],
    };

    const filterFunction = ({ ascending, descending, sort_type }) => {
        let temp_filter = ''

        if ((global.fan_page_filter === '') || ((global.fan_page_filter !== ascending) && (global.fan_page_filter !== descending))) {
            temp_filter = ascending

            if (sort_type === 'visits_page') {
                filteredFanPageData.sort((a, b) => a.visits_page.all - b.visits_page.all)
            } else if (sort_type === 'visits_post') {
                filteredFanPageData.sort((a, b) => a.visits_post.all - b.visits_post.all)
            } else if (sort_type === 'revenue_page') {
                filteredFanPageData.sort((a, b) => a.revenue_page.all - b.revenue_page.all)
            } else if (sort_type === 'revenue_post') {
                filteredFanPageData.sort((a, b) => a.revenue_post.all - b.revenue_post.all)
            } else if (sort_type === 'link_clicks') {
                filteredFanPageData.sort((a, b) => (a?.['28 Days Page Consumptions By Type']?.['link clicks'] ? a?.['28 Days Page Consumptions By Type']?.['link clicks'] : 0) - (b?.['28 Days Page Consumptions By Type']?.['link clicks'] ? b?.['28 Days Page Consumptions By Type']?.['link clicks'] : 0))
            } else if (sort_type === 'other_clicks') {
                filteredFanPageData.sort((a, b) => (a?.['28 Days Page Consumptions By Type']?.['other clicks'] ? a?.['28 Days Page Consumptions By Type']?.['other clicks'] : 0) - (b?.['28 Days Page Consumptions By Type']?.['other clicks'] ? b?.['28 Days Page Consumptions By Type']?.['other clicks'] : 0))
            } else {
                filteredFanPageData.sort((a, b) => (a[sort_type] ? a[sort_type] : 0) - (b[sort_type] ? b[sort_type] : 0))
            }
        }
        
        else if (global.fan_page_filter === ascending) {
            temp_filter = descending
            
            if (sort_type === 'visits_page') {
                filteredFanPageData.sort((a, b) => b.visits_page.all - a.visits_page.all)
            } else if (sort_type === 'visits_post') {
                filteredFanPageData.sort((a, b) => b.visits_post.all - a.visits_post.all)
            } else if (sort_type === 'revenue_page') {
                filteredFanPageData.sort((a, b) => b.revenue_page.all - a.revenue_page.all)
            } else if (sort_type === 'revenue_post') {
                filteredFanPageData.sort((a, b) => b.revenue_post.all - a.revenue_post.all)
            } else if (sort_type === 'link_clicks') {
                filteredFanPageData.sort((a, b) => (b?.['28 Days Page Consumptions By Type']?.['link clicks'] ? b?.['28 Days Page Consumptions By Type']?.['link clicks'] : 0) - (a?.['28 Days Page Consumptions By Type']?.['link clicks'] ? a?.['28 Days Page Consumptions By Type']?.['link clicks'] : 0))
            } else if (sort_type === 'other_clicks') {
                filteredFanPageData.sort((a, b) => (b?.['28 Days Page Consumptions By Type']?.['other clicks'] ? b?.['28 Days Page Consumptions By Type']?.['other clicks'] : 0) - (a?.['28 Days Page Consumptions By Type']?.['other clicks'] ? a?.['28 Days Page Consumptions By Type']?.['other clicks'] : 0))
            } else {
                filteredFanPageData.sort((a, b) => (b[sort_type] ? b[sort_type] : 0) - (a[sort_type] ? a[sort_type] : 0))
            }
        }
        
        else if (global.fan_page_filter === descending) {
            temp_filter = ''
            filteredFanPageData.sort((a, b) => {
                if (a?.name < b?.name) {
                    return -1;
                }
                if (a?.name > b?.name) {
                    return 1;
                }
                return 0;
            })
        }

        setGlobal(p => { return { ...p, fan_page_filter: temp_filter } })
    }

    // filter and map fan page and revenue
    useEffect(() => {
        if ((data.fb_fan_pages_details.length > 0) && (data.fb_fan_pages_revenue.page.length > 0 || data.fb_fan_pages_revenue.post.length > 0)) {
            const temp_fan_page_list = data.fb_fan_pages_details.map(item => {
                let result = { ...item, visits_page: {}, visits_post: {}, revenue_page: {}, revenue_post: {} }
                const filter_page = data.fb_fan_pages_revenue.page.filter(f => f?.facebook_fan_page === item?.id)
                const filter_post = data.fb_fan_pages_revenue.post.filter(f => f?.facebook_fan_page === item?.id)

                if (filter_page?.[0]?.url?.count) {
                    result.visits_page.all = Object.values(filter_page?.[0]?.url?.count)?.reduce((a, b) => a + b, 0)
                    Object.keys(filter_page?.[0]?.url?.count).map(e => (
                        result.visits_page[e] = filter_page?.[0]?.url?.count?.[e]
                    ))
                } else {
                    result.visits_page.all = 0
                }

                if (filter_page.length > 0) {
                    let temp_keys = filter_page.map(e => e.revenue ? Object.keys(e.revenue).flat() : []).flat()
                    temp_keys = Array.from(new Set(temp_keys)).sort()
                    temp_keys.map(key => {
                        result.revenue_page[key] = filter_page.map(e => e?.revenue?.[key] ? e?.revenue?.[key] : 0).reduce((a, b) => a + b, 0)
                    })
                    if (!temp_keys.find(f => f === 'all')) { result.revenue_page.all = 0; }
                } else {
                    result.revenue_page.all = 0
                }

                if (filter_post.length > 0) {
                    const widgets = Array.from(new Set(filter_post.map(e => e.widget)))?.sort()
                    result.visits_post.all = filter_post.map(e => ((e.date === filter_post?.[0]?.date) && e.count) ? e.count : 0)?.reduce((a, b) => a + b, 0)
                    widgets.map(e => (
                        result.visits_post[e] = filter_post.find(f => f.widget === e)?.count ? filter_post.find(f => f.widget === e)?.count : 0
                    ))
                } else {
                    result.visits_post.all = 0
                }

                if (filter_post.length > 0) {
                    const widgets = Array.from(new Set(filter_post.map(e => e.widget)))?.sort()
                    result.revenue_post.all = filter_post.map(e => e.revenue ? e.revenue : 0)?.reduce((a, b) => a + b, 0)
                    widgets.map(e => (
                        result.revenue_post[e] = filter_post.map(m => (m.widget === e && m.revenue) ? m.revenue : 0)?.reduce((a, b) => a + b, 0)
                    ))
                } else {
                    result.revenue_post.all = 0
                }

                return result
            })

            setFilteredFanPageData(temp_fan_page_list)
        }
        else if (data.fb_fan_pages_details.length > 0) {
            const temp_fan_page_list = data.fb_fan_pages_details.map(item => {
                return {
                    ...item,
                    visits_page: { all: 0 },
                    visits_post: { all: 0 },
                    revenue_page: { all: 0 },
                    revenue_post: { all: 0 }
                }
            })

            setFilteredFanPageData(temp_fan_page_list)
        }
        else {
            setFilteredFanPageData([])
        }
    }, [data.fb_fan_pages_details, data.fb_fan_pages_revenue])

    // filter out revenue data for chart
    useEffect(() => {
        let result = []

        const getDateRange = () => {
            const firstDate = global.fan_page_from
            const lastDate = global.fan_page_to
            if (moment(firstDate).isSame(moment(lastDate)) || moment(firstDate).isAfter(lastDate)) {
                return [ moment(lastDate).format('YYYY-MM-DD') ];
            }

            let date = firstDate;
            const dates = [ moment(date).format('YYYY-MM-DD') ];
            do {
                date = moment(date).add(1, 'day');
                dates.push(moment(date).format('YYYY-MM-DD'));
            } while (moment(date).isBefore(lastDate));

            return dates;
        };

        if (data.fb_fan_pages_revenue.page.length > 0 || data.fb_fan_pages_revenue.post.length > 0) {
            result = getDateRange().map(e => {
                const filter_page = data.fb_fan_pages_revenue.page.filter(f => f.date === e)
                const filter_post = data.fb_fan_pages_revenue.post.filter(f => f.date === e)
                return {
                    date: e,
                    page: filter_page ? filter_page.map(e => e?.revenue?.all ? e?.revenue?.all : 0).reduce((a, b) => a + b, 0) : 0,
                    post: filter_post ? filter_post.map(e => e.revenue ? e.revenue : 0).reduce((a, b) => a + b, 0) : 0,
                }
            })
        } else {
            result = getDateRange().map(e => {
                return {
                    date: e,
                    page: 0,
                    post: 0,
                }
            })
        }

        setRevenueChartData(result)
    }, [data.fb_fan_pages_revenue])

    return (
        <div className="widget-container" style={{ gridAutoFlow: 'row', gridAutoRows: 'min-content 500px 1fr', position: 'relative' }}>
            {/* loader */}
            {(loading.fan_page || loading.fan_page_revenue) ? (
                <div className="ripple-logo-container" style={{ zIndex: 500 }}>
                    <div className="loader" style={{ width: '200px', height: '200px', border: '5px solid #0000FF', borderTop: '5px solid #E6E6FF' }}></div>
                </div>
            ) : (<></>)}

            {/* top nav */}
            <div className="fbfp-top">
                <div className="fbfp-top-left">
                    <img src={facebookLogo} alt="LOGO"/>
                    <div className='widget-add-field' style={{ width: '150px' }}>
                        <p className='widget-add-field-float-name'>From:</p>
                        <input className="widget2-select" type="date" max={moment().format('YYYY-MM-DD')} value={global.fan_page_from} onChange={e => setGlobal(p => { return { ...p, fan_page_from: e.target.value } })}/>
                    </div>
                    <div className='widget-add-field' style={{ width: '150px' }}>
                        <p className='widget-add-field-float-name'>To:</p>
                        <input className="widget2-select" type="date" max={moment().format('YYYY-MM-DD')} value={global.fan_page_to} onChange={e => setGlobal(p => { return { ...p, fan_page_to: e.target.value } })}/>
                    </div>
                    <div className='widget-add-field' style={{ height: '40px', width: 'fit-content', background: '#DBEFDC', display: 'grid', gridTemplateColumns: '1fr 1px 1fr', borderRadius: '5px', border: '1px solid #4CAF50' }}>
                        <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '0px 10px' }}>
                            <p style={{ fontSize: '10px', fontWeight: '400' }}>Total Rev Page:</p>
                            <p style={{ fontWeight: '600' }}>${filteredFanPageData?.map(e => e?.revenue_page?.all ? e?.revenue_page?.all : 0)?.reduce((a, b) => a + b, 0)?.toFixed(0)}</p>
                        </div>
                        <div style={{ height: '100%', width: '0.5px', background: '#4CAF50' }}/>
                        <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '0px 10px' }}>
                            <p style={{ fontSize: '10px', fontWeight: '400' }}>Total Rev Post:</p>
                            <p style={{ fontWeight: '600' }}>${filteredFanPageData?.map(e => e?.revenue_post?.all ? e?.revenue_post?.all : 0)?.reduce((a, b) => a + b, 0)?.toFixed(0)}</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Chart */}
            <div className="chartContainer" style={{ height: '500px', flexDirection: 'column', marginBottom: '10px' }} >
                <div className="chartWrapper">
                    <Bar options={revenue_chart_options} data={revenue_chart_data} />
                </div>
            </div>

            {/* fan page */}
            <div id="widget-table-container-scroll" className="widget-table-container">
                <table className="widget-table">
                    <thead>
                        <tr>
                            <th style={{ minWidth: '250px', maxWidth: '250px', fontSize: '14px', fontWeight: '500', textAlign: 'left' }}>Name</th>
                            <th style={{ minWidth: '120px', maxWidth: '120px', fontSize: '14px', fontWeight: '500', textAlign: 'left', cursor: 'pointer' }} onClick={() => filterFunction({ ascending: 'visits-page-asc', descending: 'visits-page-desc', sort_type: 'visits_page' })}>
                                Visits Page&nbsp;
                                {((global.fan_page_filter === '') || ((global.fan_page_filter !== 'visits-page-asc') && (global.fan_page_filter !== 'visits-page-desc'))) && <LiaDotCircle size={16} color={'#000'}/>}
                                {(global.fan_page_filter === 'visits-page-asc') && <FaArrowUpLong size={16} color={'#000'}/>}
                                {(global.fan_page_filter === 'visits-page-desc') && <FaArrowDownLong size={16} color={'#000'}/>}
                            </th>
                            <th style={{ minWidth: '120px', maxWidth: '120px', fontSize: '14px', fontWeight: '500', textAlign: 'left', cursor: 'pointer' }} onClick={() => filterFunction({ ascending: 'visits-post-asc', descending: 'visits-post-desc', sort_type: 'visits_post' })}>
                                Visits Post&nbsp;
                                {((global.fan_page_filter === '') || ((global.fan_page_filter !== 'visits-post-asc') && (global.fan_page_filter !== 'visits-post-desc'))) && <LiaDotCircle size={16} color={'#000'}/>}
                                {(global.fan_page_filter === 'visits-post-asc') && <FaArrowUpLong size={16} color={'#000'}/>}
                                {(global.fan_page_filter === 'visits-post-desc') && <FaArrowDownLong size={16} color={'#000'}/>}
                            </th>
                            <th style={{ minWidth: '120px', maxWidth: '120px', fontSize: '14px', fontWeight: '500', textAlign: 'left', cursor: 'pointer' }} onClick={() => filterFunction({ ascending: 'revenue-page-asc', descending: 'revenue-page-desc', sort_type: 'revenue_page' })}>
                                Revenue Page&nbsp;
                                {((global.fan_page_filter === '') || ((global.fan_page_filter !== 'revenue-page-asc') && (global.fan_page_filter !== 'revenue-page-desc'))) && <LiaDotCircle size={16} color={'#000'}/>}
                                {(global.fan_page_filter === 'revenue-page-asc') && <FaArrowUpLong size={16} color={'#000'}/>}
                                {(global.fan_page_filter === 'revenue-page-desc') && <FaArrowDownLong size={16} color={'#000'}/>}
                            </th>
                            <th style={{ minWidth: '120px', maxWidth: '120px', fontSize: '14px', fontWeight: '500', textAlign: 'left', cursor: 'pointer' }} onClick={() => filterFunction({ ascending: 'revenue-post-asc', descending: 'revenue-post-desc', sort_type: 'revenue_post' })}>
                                Revenue Post&nbsp;
                                {((global.fan_page_filter === '') || ((global.fan_page_filter !== 'revenue-post-asc') && (global.fan_page_filter !== 'revenue-post-desc'))) && <LiaDotCircle size={16} color={'#000'}/>}
                                {(global.fan_page_filter === 'revenue-post-asc') && <FaArrowUpLong size={16} color={'#000'}/>}
                                {(global.fan_page_filter === 'revenue-post-desc') && <FaArrowDownLong size={16} color={'#000'}/>}
                            </th>
                            <th style={{ minWidth: '120px', maxWidth: '120px', fontSize: '14px', fontWeight: '500', textAlign: 'left' }}>EPC Page</th>
                            <th style={{ minWidth: '120px', maxWidth: '120px', fontSize: '14px', fontWeight: '500', textAlign: 'left' }}>EPC Post</th>
                            <th style={{ minWidth: '120px', maxWidth: '120px', fontSize: '14px', fontWeight: '500', textAlign: 'left', cursor: 'pointer' }} onClick={() => filterFunction({ ascending: 'link-clicks-asc', descending: 'link-clicks-desc', sort_type: 'link_clicks' })}>
                                Link Clicks&nbsp;
                                {((global.fan_page_filter === '') || ((global.fan_page_filter !== 'link-clicks-asc') && (global.fan_page_filter !== 'link-clicks-desc'))) && <LiaDotCircle size={16} color={'#000'}/>}
                                {(global.fan_page_filter === 'link-clicks-asc') && <FaArrowUpLong size={16} color={'#000'}/>}
                                {(global.fan_page_filter === 'link-clicks-desc') && <FaArrowDownLong size={16} color={'#000'}/>}
                            </th>
                            <th style={{ minWidth: '120px', maxWidth: '120px', fontSize: '14px', fontWeight: '500', textAlign: 'left', cursor: 'pointer' }} onClick={() => filterFunction({ ascending: 'other-clicks-asc', descending: 'other-clicks-desc', sort_type: 'other_clicks' })}>
                                Other Clicks&nbsp;
                                {((global.fan_page_filter === '') || ((global.fan_page_filter !== 'other-clicks-asc') && (global.fan_page_filter !== 'other-clicks-desc'))) && <LiaDotCircle size={16} color={'#000'}/>}
                                {(global.fan_page_filter === 'other-clicks-asc') && <FaArrowUpLong size={16} color={'#000'}/>}
                                {(global.fan_page_filter === 'other-clicks-desc') && <FaArrowDownLong size={16} color={'#000'}/>}
                            </th>
                            <th style={{ minWidth: '120px', maxWidth: '120px', fontSize: '14px', fontWeight: '500', textAlign: 'left', cursor: 'pointer' }} onClick={() => filterFunction({ ascending: 'impressions-asc', descending: 'impressions-desc', sort_type: '28 Days Total Impressions' })}>
                                Impressions&nbsp;
                                {((global.fan_page_filter === '') || ((global.fan_page_filter !== 'impressions-asc') && (global.fan_page_filter !== 'impressions-desc'))) && <LiaDotCircle size={16} color={'#000'}/>}
                                {(global.fan_page_filter === 'impressions-asc') && <FaArrowUpLong size={16} color={'#000'}/>}
                                {(global.fan_page_filter === 'impressions-desc') && <FaArrowDownLong size={16} color={'#000'}/>}
                            </th>
                            <th style={{ minWidth: '120px', maxWidth: '120px', fontSize: '14px', fontWeight: '500', textAlign: 'left', cursor: 'pointer' }} onClick={() => filterFunction({ ascending: 'organic-impressions-asc', descending: 'organic-impressions-desc', sort_type: '28 Days Organic impressions' })}>
                                Organic Impressions&nbsp;
                                {((global.fan_page_filter === '') || ((global.fan_page_filter !== 'organic-impressions-asc') && (global.fan_page_filter !== 'organic-impressions-desc'))) && <LiaDotCircle size={16} color={'#000'}/>}
                                {(global.fan_page_filter === 'organic-impressions-asc') && <FaArrowUpLong size={16} color={'#000'}/>}
                                {(global.fan_page_filter === 'organic-impressions-desc') && <FaArrowDownLong size={16} color={'#000'}/>}
                            </th>
                            <th style={{ minWidth: '120px', maxWidth: '120px', fontSize: '14px', fontWeight: '500', textAlign: 'left', cursor: 'pointer' }} onClick={() => filterFunction({ ascending: 'reach-asc', descending: 'reach-desc', sort_type: '28 Days Total Reach' })}>
                                Reach&nbsp;
                                {((global.fan_page_filter === '') || ((global.fan_page_filter !== 'reach-asc') && (global.fan_page_filter !== 'reach-desc'))) && <LiaDotCircle size={16} color={'#000'}/>}
                                {(global.fan_page_filter === 'reach-asc') && <FaArrowUpLong size={16} color={'#000'}/>}
                                {(global.fan_page_filter === 'reach-desc') && <FaArrowDownLong size={16} color={'#000'}/>}
                            </th>
                            <th style={{ minWidth: '120px', maxWidth: '120px', fontSize: '14px', fontWeight: '500', textAlign: 'left', cursor: 'pointer' }} onClick={() => filterFunction({ ascending: 'paid-reach-asc', descending: 'paid-reach-desc', sort_type: '28 Days Paid Reach' })}>
                                Paid Reach&nbsp;
                                {((global.fan_page_filter === '') || ((global.fan_page_filter !== 'paid-reach-asc') && (global.fan_page_filter !== 'paid-reach-desc'))) && <LiaDotCircle size={16} color={'#000'}/>}
                                {(global.fan_page_filter === 'paid-reach-asc') && <FaArrowUpLong size={16} color={'#000'}/>}
                                {(global.fan_page_filter === 'paid-reach-desc') && <FaArrowDownLong size={16} color={'#000'}/>}
                            </th>
                            <th style={{ minWidth: '120px', maxWidth: '120px', fontSize: '14px', fontWeight: '500', textAlign: 'left', cursor: 'pointer' }} onClick={() => filterFunction({ ascending: 'followers-asc', descending: 'followers-desc', sort_type: 'Lifetime Total Follows' })}>
                                Followers&nbsp;
                                {((global.fan_page_filter === '') || ((global.fan_page_filter !== 'followers-asc') && (global.fan_page_filter !== 'followers-desc'))) && <LiaDotCircle size={16} color={'#000'}/>}
                                {(global.fan_page_filter === 'followers-asc') && <FaArrowUpLong size={16} color={'#000'}/>}
                                {(global.fan_page_filter === 'followers-desc') && <FaArrowDownLong size={16} color={'#000'}/>}
                            </th>
                            <th style={{ minWidth: '120px', maxWidth: '120px', fontSize: '14px', fontWeight: '500', textAlign: 'left' }}>Male</th>
                            <th style={{ minWidth: '120px', maxWidth: '120px', fontSize: '14px', fontWeight: '500', textAlign: 'left' }}>Female</th>
                            <th style={{ minWidth: '250px', maxWidth: '250px', fontSize: '14px', fontWeight: '500', textAlign: 'left' }}>Country</th>
                        </tr>
                    </thead>

                    <tbody>
                        {/* total row ~ always on top */}
                        <tr style={{ background: '#000' }}>
                            <td style={{ minWidth: '120px', maxWidth: '120px', fontWeight: '700', color: '#FFF' }}>
                                Total
                            </td>
                            <td style={{ minWidth: global.expand_row ? '200px' : '120px', maxWidth: global.expand_row ? '200px' : '120px', color: '#FFF', borderLeft: '0.1rem solid #333' }}>
                                {filteredFanPageData.map(e => e.visits_page.all).reduce((a, b) => a + b, 0).toLocaleString()}
                            </td>
                            <td style={{ minWidth: '120px', maxWidth: '120px', color: '#FFF', borderLeft: '0.1rem solid #333' }}>
                                {filteredFanPageData.map(e => e.visits_post.all).reduce((a, b) => a + b, 0).toLocaleString()}
                            </td>
                            <td style={{ minWidth: global.expand_row ? '200px' : '120px', maxWidth: global.expand_row ? '200px' : '120px', color: '#FFF', borderLeft: '0.1rem solid rgba(37, 211, 102, 0.5)', background: 'rgba(37, 211, 102, 0.1)' }}>
                                $ {filteredFanPageData.map(e => e.revenue_page.all).reduce((a, b) => a + b, 0).toFixed(2)}
                            </td>
                            <td style={{ minWidth: global.expand_row ? '200px' : '120px', maxWidth: global.expand_row ? '200px' : '120px', color: '#FFF', borderLeft: '0.1rem solid #333', background: 'rgba(37, 211, 102, 0.1)' }}>
                                $ {filteredFanPageData.map(e => e.revenue_post.all).reduce((a, b) => a + b, 0).toFixed(2)}
                            </td>
                            <td style={{ minWidth: global.expand_row ? '200px' : '120px', maxWidth: global.expand_row ? '200px' : '120px', color: '#FFF', borderLeft: '0.1rem solid rgba(255, 0, 0, 0.5)', background: 'rgba(255, 0, 0, 0.1)' }}>
                                {(filteredFanPageData.map(e => e.revenue_page.all).reduce((a, b) => a + b, 0) / filteredFanPageData.map(e => e.visits_page.all).reduce((a, b) => a + b, 0)).toFixed(3)}
                            </td>
                            <td style={{ minWidth: '120px', maxWidth: '120px', color: '#FFF', borderLeft: '0.1rem solid #333', background: 'rgba(255, 0, 0, 0.1)' }}>
                                {(filteredFanPageData.map(e => e.revenue_post.all).reduce((a, b) => a + b, 0) / filteredFanPageData.map(e => e.visits_post.all).reduce((a, b) => a + b, 0)).toFixed(3)}
                            </td>
                            <td style={{ minWidth: '120px', maxWidth: '120px', color: '#FFF', borderLeft: '0.1rem solid rgba(0, 179, 182, 0.5)', background: 'rgba(0, 179, 182, 0.1)' }}>
                                {filteredFanPageData.map(e => e?.['28 Days Page Consumptions By Type']?.['link clicks'] ? e?.['28 Days Page Consumptions By Type']?.['link clicks'] : 0).reduce((a, b) => a + b, 0) ? filteredFanPageData.map(e => e?.['28 Days Page Consumptions By Type']?.['link clicks'] ? e?.['28 Days Page Consumptions By Type']?.['link clicks'] : 0).reduce((a, b) => a + b, 0).toLocaleString() : '-'}
                            </td>
                            <td style={{ minWidth: '120px', maxWidth: '120px', color: '#FFF', borderLeft: '0.1rem solid #333', background: 'rgba(0, 179, 182, 0.1)' }}>
                                {filteredFanPageData.map(e => e?.['28 Days Page Consumptions By Type']?.['other clicks'] ? e?.['28 Days Page Consumptions By Type']?.['other clicks'] : 0).reduce((a, b) => a + b, 0) ? filteredFanPageData.map(e => e?.['28 Days Page Consumptions By Type']?.['other clicks'] ? e?.['28 Days Page Consumptions By Type']?.['other clicks'] : 0).reduce((a, b) => a + b, 0).toLocaleString() : '-'}
                            </td>
                            <td style={{ minWidth: '120px', maxWidth: '120px', color: '#FFF', borderLeft: '0.1rem solid rgba(153, 102, 255, 0.5)', background: 'rgba(153, 102, 255, 0.1)' }}>
                                {filteredFanPageData.map(e => e?.['28 Days Total Impressions'] ? e?.['28 Days Total Impressions'] : 0).reduce((a, b) => a + b, 0).toLocaleString()}
                            </td>
                            <td style={{ minWidth: '120px', maxWidth: '120px', color: '#FFF', borderLeft: '0.1rem solid #333', background: 'rgba(153, 102, 255, 0.1)' }}>
                                {filteredFanPageData.map(e => e?.['28 Days Organic impressions'] ? e?.['28 Days Organic impressions'] : 0).reduce((a, b) => a + b, 0) ? filteredFanPageData.map(e => e?.['28 Days Organic impressions'] ? e?.['28 Days Organic impressions'] : 0).reduce((a, b) => a + b, 0).toLocaleString() : '-'}
                            </td>
                            <td style={{ minWidth: '120px', maxWidth: '120px', color: '#FFF', borderLeft: '0.1rem solid rgba(255, 128, 0, 0.5)', background: 'rgba(255, 128, 0, 0.1)' }}>
                                {filteredFanPageData.map(e => e?.['28 Days Total Reach'] ? e?.['28 Days Total Reach'] : 0).reduce((a, b) => a + b, 0).toLocaleString()}
                            </td>
                            <td style={{ minWidth: '120px', maxWidth: '120px', color: '#FFF', borderLeft: '0.1rem solid #333', background: 'rgba(255, 128, 0, 0.1)' }}>
                                {filteredFanPageData.map(e => e?.['28 Days Paid Reach'] ? e?.['28 Days Paid Reach'] : 0).reduce((a, b) => a + b, 0).toLocaleString()}
                            </td>
                            <td style={{ minWidth: '120px', maxWidth: '120px', color: '#FFF', borderLeft: '0.1rem solid rgba(54, 162, 235, 0.5)', background: 'rgba(54, 162, 235, 0.1)' }}>
                                {filteredFanPageData.map(e => e?.['Lifetime Total Follows'] ? e?.['Lifetime Total Follows'] : 0).reduce((a, b) => a + b, 0).toLocaleString()}
                            </td>
                            <td style={{ minWidth: global.expand_row ? '200px' : '120px', maxWidth: global.expand_row ? '200px' : '120px', color: '#FFF', borderLeft: '0.1rem solid #333', background: 'rgba(54, 162, 235, 0.1)' }}>
                                -
                            </td>
                            <td style={{ minWidth: global.expand_row ? '200px' : '120px', maxWidth: global.expand_row ? '200px' : '120px', color: '#FFF', borderLeft: '0.1rem solid #333', background: 'rgba(54, 162, 235, 0.1)' }}>
                                -
                            </td>
                            <td style={{ minWidth: '120px', maxWidth: '120px', color: '#FFF', borderLeft: '0.1rem solid #333', background: 'rgba(54, 162, 235, 0.1)' }}>
                                -
                            </td>
                        </tr>

                        {/* all the filtered data */}
                        {filteredFanPageData.map((fd, i) => {
                            return (
                                <tr key={i} style={{ background: '#FFF', cursor: 'zoom-in' }}
                                    onMouseEnter={e => e.currentTarget.style.background = '#EEE'}
                                    onMouseLeave={e => e.currentTarget.style.background = '#FFF'}
                                    onClick={() => setGlobal(p => { return { ...p, expand_row: (p.expand_row === fd.id) ? null : fd.id } })}
                                >
                                    <td style={{ minWidth: '120px', maxWidth: '120px' }}>
                                        <div className="widget-table-cell" style={{ display: 'flex', alignItems: (global.expand_row === fd.id) ? 'flex-start' : 'center' }}>
                                            {fb_fan_page_links.find(f => f.page_name.trim() === fd.name.trim()) ? (
                                                <a href={fb_fan_page_links.find(f => f.page_name.trim() === fd.name.trim()).url} target="_blank" style={{ fontSize: '14px', textUnderlineOffset: '2px' }}>{fd.name}</a>
                                            ) : (
                                                <p style={{ fontSize: '14px' }}>{fd.name}</p>
                                            )}
                                        </div>
                                    </td>
                                    <td style={{ minWidth: (global.expand_row === fd.id) ? '200px' : '120px', maxWidth: (global.expand_row === fd.id) ? '200px' : '120px' }}>
                                        <div className="widget-table-cell" style={{ display: 'flex', flexDirection: 'column', justifyContent: (global.expand_row === fd.id) ? 'flex-start' : 'center', gap: '10px' }}>
                                            <p style={{ fontSize: '14px' }}>{fd.visits_page.all ? fd.visits_page.all : '-'}</p>
                                            {(fd.visits_page.all !== '-') && (
                                                <div className="fbfp-table-cell-revenue" style={{ display: (global.expand_row === fd.id) ? 'flex' : 'none' }}>
                                                    {Object.keys(fd.visits_page).map((e, i) => (
                                                        (e !== 'all') && (
                                                            <div key={i}>
                                                                <p style={{ wordBreak: 'break-all', padding: '5px 0px' }}>{e}</p>
                                                                <p>{fd.visits_page[e]}</p>
                                                            </div>
                                                        )
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    <td style={{ minWidth: '120px', maxWidth: '120px' }}>
                                        <div className="widget-table-cell" style={{ display: 'flex', flexDirection: 'column', justifyContent: (global.expand_row === fd.id) ? 'flex-start' : 'center', gap: '10px' }}>
                                            <p style={{ fontSize: '14px' }}>{fd.visits_post.all ? fd.visits_post.all : '-'}</p>
                                            {/* {(fd.visits_post.all !== '-') && (
                                                <div className="fbfp-table-cell-revenue" style={{ display: (global.expand_row === fd.id) ? 'flex' : 'none' }}>
                                                    {Object.keys(fd.visits_post).map((e, i) => (
                                                        (e !== 'all') && (
                                                            <div key={i}>
                                                                <p style={{ wordBreak: 'break-all', padding: '5px 0px' }}>{data?.fb_fan_pages_revenue?.post?.find(f => f?.widget === Number(e))?.topic ? data?.fb_fan_pages_revenue?.post?.find(f => f?.widget === Number(e))?.topic : e}</p>
                                                                <p>{fd.visits_post[e]}</p>
                                                            </div>
                                                        )
                                                    ))}
                                                </div>
                                            )} */}
                                        </div>
                                    </td>
                                    <td style={{ minWidth: (global.expand_row === fd.id) ? '200px' : '120px', maxWidth: (global.expand_row === fd.id) ? '200px' : '120px', background: 'rgba(37, 211, 102, 0.05)', borderLeft: '3px solid rgba(37, 211, 102, 0.3)' }}>
                                        <div className="widget-table-cell" style={{ display: 'flex', flexDirection: 'column', justifyContent: (global.expand_row === fd.id) ? 'flex-start' : 'center', gap: '10px' }}>
                                            <p style={{ fontSize: '14px' }}>{fd.revenue_page.all ? '$ '+fd.revenue_page.all.toFixed(2) : '-'}</p>
                                            {(fd.revenue_page.all !== '-') && (
                                                <div className="fbfp-table-cell-revenue" style={{ display: (global.expand_row === fd.id) ? 'flex' : 'none' }}>
                                                    {Object.keys(fd.revenue_page).map((e, i) => (
                                                        (e !== 'all') && (
                                                            <div key={i}>
                                                                <p style={{ wordBreak: 'break-all', padding: '5px 0px' }}>{e}</p>
                                                                <p>$ {fd.revenue_page[e].toFixed(2)}</p>
                                                            </div>
                                                        )
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    <td style={{ minWidth: (global.expand_row === fd.id) ? '200px' : '120px', maxWidth: (global.expand_row === fd.id) ? '200px' : '120px', background: 'rgba(37, 211, 102, 0.05)' }}>
                                        <div className="widget-table-cell" style={{ display: 'flex', flexDirection: 'column', justifyContent: (global.expand_row === fd.id) ? 'flex-start' : 'center', gap: '10px' }}>
                                            <p style={{ fontSize: '14px' }}>{fd.revenue_post.all ? '$ '+fd.revenue_post.all.toFixed(2) : '-'}</p>
                                            {(fd.revenue_post.all !== '-') && (
                                                <div className="fbfp-table-cell-revenue" style={{ display: (global.expand_row === fd.id) ? 'flex' : 'none' }}>
                                                    {Object.keys(fd.revenue_post).map((e, i) => (
                                                        (e !== 'all' && fd.revenue_post[e]) && (
                                                            <div key={i}>
                                                                <p style={{ wordBreak: 'break-all', padding: '5px 0px' }}>{data?.fb_fan_pages_revenue?.post?.find(f => f?.widget === Number(e))?.topic ? data?.fb_fan_pages_revenue?.post?.find(f => f?.widget === Number(e))?.topic : e}</p>
                                                                <p>${fd.revenue_post[e].toFixed(2)}</p>
                                                            </div>
                                                        )
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    <td style={{ minWidth: (global.expand_row === fd.id) ? '200px' : '120px', maxWidth: (global.expand_row === fd.id) ? '200px' : '120px', background: 'rgba(255, 0, 0, 0.05)', borderLeft: '3px solid rgba(255, 0, 0, 0.3)' }}>
                                        <div className="widget-table-cell" style={{ display: 'flex', flexDirection: 'column', justifyContent: (global.expand_row === fd.id) ? 'flex-start' : 'center', gap: '10px' }}>
                                            <p style={{ fontSize: '14px' }}>{(fd.visits_page.all && fd.revenue_page.all) ? (fd.revenue_page.all / fd.visits_page.all).toFixed(3) : '-'}</p>
                                            {(Object.keys(fd.revenue_page).length > 0) && (
                                                <div className="fbfp-table-cell-revenue" style={{ display: (global.expand_row === fd.id) ? 'flex' : 'none' }}>
                                                    {Object.keys(fd.visits_page).map((e, i) => (
                                                        (e !== 'all') && (
                                                            <div key={i}>
                                                                <p style={{ wordBreak: 'break-all', padding: '5px 0px' }}>{e}</p>
                                                                <p>{(fd.visits_page[e] && fd.revenue_page[e.toLowerCase()]) ? (fd.revenue_page[e.toLowerCase()] / fd.visits_page[e]).toFixed(3) : '-'}</p>
                                                            </div>
                                                        )
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    <td style={{ minWidth: '120px', maxWidth: '120px', background: 'rgba(255, 0, 0, 0.05)' }}>
                                        <div className="widget-table-cell" style={{ display: 'flex', flexDirection: 'column', justifyContent: (global.expand_row === fd.id) ? 'flex-start' : 'center', gap: '10px' }}>
                                            <p style={{ fontSize: '14px' }}>{(fd.visits_post.all && fd.revenue_post.all) ? (fd.revenue_post.all / fd.visits_post.all).toFixed(3) : '-'}</p>
                                            {/* {(Object.keys(fd.revenue_post).length > 0) && (
                                                <div className="fbfp-table-cell-revenue" style={{ display: (global.expand_row === fd.id) ? 'flex' : 'none' }}>
                                                    {Object.keys(fd.revenue_post).map((e, i) => (
                                                        (e !== 'all') && (
                                                            <div key={i}>
                                                                <p style={{ wordBreak: 'break-all', padding: '5px 0px' }}>{data?.fb_fan_pages_revenue?.post?.find(f => f?.widget === Number(e))?.topic ? data?.fb_fan_pages_revenue?.post?.find(f => f?.widget === Number(e))?.topic : e}</p>
                                                                <p>{(fd.visits_post[e] && fd.revenue_post[e]) ? (fd.revenue_post[e] / fd.visits_post[e]).toFixed(3) : '-'}</p>
                                                            </div>
                                                        )
                                                    ))}
                                                </div>
                                            )} */}
                                        </div>
                                    </td>
                                    <td style={{ minWidth: '120px', maxWidth: '120px', background: 'rgba(0, 179, 182, 0.05)', borderLeft: '3px solid rgba(0, 179, 182, 0.3)' }}>
                                        <div className="widget-table-cell" style={{ display: 'flex', alignItems: (global.expand_row === fd.id) ? 'flex-start' : 'center' }}>
                                            <p style={{ fontSize: '14px' }}>{fd?.['28 Days Page Consumptions By Type']?.['link clicks'] ? fd?.['28 Days Page Consumptions By Type']?.['link clicks']?.toLocaleString() : '-'}</p>
                                        </div>
                                    </td>
                                    <td style={{ minWidth: '120px', maxWidth: '120px', background: 'rgba(0, 179, 182, 0.05)' }}>
                                        <div className="widget-table-cell" style={{ display: 'flex', alignItems: (global.expand_row === fd.id) ? 'flex-start' : 'center' }}>
                                            <p style={{ fontSize: '14px' }}>{fd?.['28 Days Page Consumptions By Type']?.['other clicks'] ? fd?.['28 Days Page Consumptions By Type']?.['other clicks']?.toLocaleString() : '-'}</p>
                                        </div>
                                    </td>
                                    <td style={{ minWidth: '120px', maxWidth: '120px', background: 'rgba(153, 102, 255, 0.05)', borderLeft: '3px solid rgba(153, 102, 255, 0.3)' }}>
                                        <div className="widget-table-cell" style={{ display: 'flex', alignItems: (global.expand_row === fd.id) ? 'flex-start' : 'center' }}>
                                            <p style={{ fontSize: '14px' }}>{fd?.['28 Days Total Impressions'] ? fd?.['28 Days Total Impressions']?.toLocaleString() : '-'}</p>
                                        </div>
                                    </td>
                                    <td style={{ minWidth: '120px', maxWidth: '120px', background: 'rgba(153, 102, 255, 0.05)' }}>
                                        <div className="widget-table-cell" style={{ display: 'flex', alignItems: (global.expand_row === fd.id) ? 'flex-start' : 'center' }}>
                                            <p style={{ fontSize: '14px' }}>{fd?.['28 Days Organic impressions'] ? fd?.['28 Days Organic impressions']?.toLocaleString() : '-'}</p>
                                        </div>
                                    </td>
                                    <td style={{ minWidth: '120px', maxWidth: '120px', background: 'rgba(255, 128, 0, 0.05)', borderLeft: '3px solid rgba(255, 128, 0, 0.3)' }}>
                                        <div className="widget-table-cell" style={{ display: 'flex', alignItems: (global.expand_row === fd.id) ? 'flex-start' : 'center' }}>
                                            <p style={{ fontSize: '14px' }}>{fd?.['28 Days Total Reach'] ? fd?.['28 Days Total Reach']?.toLocaleString() : '-'}</p>
                                        </div>
                                    </td>
                                    <td style={{ minWidth: '120px', maxWidth: '120px', background: 'rgba(255, 128, 0, 0.05)' }}>
                                        <div className="widget-table-cell" style={{ display: 'flex', alignItems: (global.expand_row === fd.id) ? 'flex-start' : 'center' }}>
                                            <p style={{ fontSize: '14px' }}>{fd?.['28 Days Paid Reach'] ? fd?.['28 Days Paid Reach']?.toLocaleString() : '-'}</p>
                                        </div>
                                    </td>
                                    <td style={{ minWidth: '120px', maxWidth: '120px', background: 'rgba(54, 162, 235, 0.05)', borderLeft: '3px solid rgba(54, 162, 235, 0.3)' }}>
                                        <div className="widget-table-cell" style={{ display: 'flex', alignItems: (global.expand_row === fd.id) ? 'flex-start' : 'center' }}>
                                            <p style={{ fontSize: '14px' }}>{fd?.['Lifetime Total Follows'] ? fd?.['Lifetime Total Follows']?.toLocaleString() : '-'}</p>
                                        </div>
                                    </td>
                                    <td style={{ minWidth: (global.expand_row === fd.id) ? '200px' : '120px', maxWidth: (global.expand_row === fd.id) ? '200px' : '120px', background: 'rgba(54, 162, 235, 0.05)' }}>
                                        {(
                                            () => {
                                                const id = fd?.id
                                                const row = fd?.['group']?.['28 Days Reach Demographics']
                                                const total = row?.total ? row?.total : 0
                                                const male_total = row?.M ? row?.M : 0
                                                const male = {
                                                    "13-17": row?.['M.13-17'] ? row?.['M.13-17'] : 0,
                                                    "18-24": row?.['M.18-24'] ? row?.['M.18-24'] : 0,
                                                    "25-34": row?.['M.25-34'] ? row?.['M.25-34'] : 0,
                                                    "35-44": row?.['M.35-44'] ? row?.['M.35-44'] : 0,
                                                    "45-54": row?.['M.45-54'] ? row?.['M.45-54'] : 0,
                                                    "55-64": row?.['M.55-64'] ? row?.['M.55-64'] : 0,
                                                    "65+": row?.['M.65+'] ? row?.['M.65+'] : 0,
                                                }
                                                return (
                                                    <div className="widget-table-cell" style={{ display: 'flex', flexDirection: 'column', justifyContent: (global.expand_row === fd.id) ? 'flex-start' : 'center', gap: '10px' }}>
                                                        <p style={{ fontSize: '14px' }}>{isNaN((male_total/total)*100) ? '-' : ((male_total/total)*100).toFixed(0)+" %"}</p>
                                                        <div className="fbfp-table-cell-revenue" style={{ display: (global.expand_row === fd.id) ? 'flex' : 'none' }}>
                                                            {row && Object.keys(male).map((e, i) => (
                                                                <div key={i}>
                                                                    <p>{e}</p>
                                                                    <p>{isNaN((male[e]/total)*100) ? '-' : ((male[e]/total)*100).toFixed(0)+" %"}</p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        )()}
                                    </td>
                                    <td style={{ minWidth: (global.expand_row === fd.id) ? '200px' : '120px', maxWidth: (global.expand_row === fd.id) ? '200px' : '120px', background: 'rgba(54, 162, 235, 0.05)' }}>
                                        {(
                                            () => {
                                                const id = fd?.id
                                                const row = fd?.['group']?.['28 Days Reach Demographics']
                                                const total = row?.total ? row?.total : 0
                                                const female_total = row?.F ? row?.F : 0
                                                const female = {
                                                    "13-17": row?.['F.13-17'] ? row?.['F.13-17'] : 0,
                                                    "18-24": row?.['F.18-24'] ? row?.['F.18-24'] : 0,
                                                    "25-34": row?.['F.25-34'] ? row?.['F.25-34'] : 0,
                                                    "35-44": row?.['F.35-44'] ? row?.['F.35-44'] : 0,
                                                    "45-54": row?.['F.45-54'] ? row?.['F.45-54'] : 0,
                                                    "55-64": row?.['F.55-64'] ? row?.['F.55-64'] : 0,
                                                    "65+": row?.['F.65+'] ? row?.['F.65+'] : 0,
                                                }
                                                
                                                return (
                                                    <div className="widget-table-cell" style={{ display: 'flex', flexDirection: 'column', justifyContent: (global.expand_row === fd.id) ? 'flex-start' : 'center', gap: '10px' }}>
                                                        <p style={{ fontSize: '14px' }}>{isNaN((female_total/total)*100) ? '-' : ((female_total/total)*100).toFixed(0)+" %"}</p>
                                                        <div className="fbfp-table-cell-revenue" style={{ display: (global.expand_row === fd.id) ? 'flex' : 'none' }}>
                                                            {row && Object.keys(female).map((e, i) => (
                                                                <div key={i}>
                                                                    <p>{e}</p>
                                                                    <p>{isNaN((female[e]/total)*100) ? '-' : ((female[e]/total)*100).toFixed(0)+" %"}</p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        )()}
                                    </td>
                                    <td style={{ minWidth: '120px', maxWidth: '120px', background: 'rgba(54, 162, 235, 0.05)' }}>
                                        {(
                                            () => {
                                                const id = fd?.id
                                                const row = fd?.['group']?.['Lifetime Likes by Country']
                                                const total = row?.total ? row?.total : 0
                                                const temp_sort = row?.total ? Object.entries(row).sort((a, b) => b[1] - a[1]).slice(1, 4) : []
                                                const sorted_countries = Object.fromEntries(temp_sort)
                                                
                                                return (
                                                    <div className="fbfp-table-cell-country">
                                                        {(Object.keys(sorted_countries)?.length > 0) ? (
                                                            Object.keys(sorted_countries).map((e, i) => (
                                                                <div key={i}>
                                                                    <p>{e}</p>
                                                                    <p>{isNaN((sorted_countries[e]/total)*100) ? '-' : ((sorted_countries[e]/total)*100).toFixed(0)+"%"}</p>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <>-</>
                                                        )}
                                                    </div>
                                                )
                                            }
                                        )()}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default FFPData