import React from "react";
import Sum from "../reusable/sum/Sum";
import sedoLogo from '../../assets/sedoLogo.png'

const SedoTotal = () => {
    return(
        <Sum clientName='sedo' logo={sedoLogo} minusDate={2} />
    )
}

export default SedoTotal