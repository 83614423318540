import React from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import { host } from './host'

export const copyCampaign = ({ setCopyC2WLoading, copyData, setCheckedCampaigns, appContext, setUserToSend }) => {
    const token = Cookies.get('token')
    const url = `${host}/api/index/copy_campaign`;

    axios.post(url, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type, x-access-token",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
            "Authorization": "Bearer "+token,
            "Content-Type": "application/json",
        },
        data: copyData
    })
    .then((response) => {
        const obj = response.data
        if (obj.success) {
            setCheckedCampaigns([])
            setUserToSend('')
        } else {
            window.alert('Copy failed! Please try again')
        }
    })
    .catch((error) => {
        console.log(error);
        window.alert('Copy failed! Please try again')
        if (error?.response?.data?.message === 'Invalid') {
            appContext.forceLogout()
        }
    })
    .finally(() => {
        setCopyC2WLoading(false)
    }) 
}