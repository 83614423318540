import React from "react";
import Traffic from '../reusable/traffic/Traffic'
import facebookLogo from '../../assets/facebookLogo.png'

const AllFacebook = () => {
    return(
        <Traffic trafficName='all_facebook' trafficToSend='Facebook' clientName='All' levelUrlFor='AllClients' logo={facebookLogo} minusDate={2}/>
    )
}

export default AllFacebook