import { useState, useEffect, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { host } from './host'
import { Context } from "../AppContext";

const useFetchWidget = ({ refresh, refreshUnusedDomains, page, setPageOptions, filterUser, uploadFbImage, users, setBulkUpdate }) => {
  const appContext = useContext(Context)
  
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ loading: { fb_images: false, unusedDomains: false }, widget: [], fb: [], unusedDomains: [], unusedDomainsV2: [], emailoctopusLists: [], emailoctopusCampaigns: [], fb_countries: [], fb_regions: [], fb_fan_pages: [], fb_images: {}, fb_videos: {}, fb_fan_pages_post: [], fb_fan_pages_links: [], fb_token: "" });
  
  const user_permissions = Cookies.get("userPermissions") ? JSON.parse(Cookies.get("userPermissions")) : []

  // one time run only APIs
  useEffect(() => {
    const controller = new AbortController();
    const token = Cookies.get("token");
    
    const urlFbCountries = `${host}/api/create_campaign/country`;
    const urlFbFanPagesPost = `${host}/api/facebook_page/accounts`;
    // const urlEmailoctopusLists = `${host}/api/emailoctopus`;
    // const urlEmailoctopusCampaigns = `${host}/api/emailoctopus/campaigns`;
    // const urlFbFanPagesLinks = `${host}/api/facebook_page/topic`;

    const getFbCountries = () => {
      axios.post(
          urlFbCountries,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "Content-Type, x-access-token",
              "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
              "Authorization": "Bearer "+token,
              "Content-Type": "application/json",
            }
          },
          {
            signal: controller.signal
          }
        )

        .then((response) => {
          const obj = response.data;
          console.log(obj)
          
          if (obj.countries && obj.regions) {
            setData(p => { return {
              ...p,
              fb_countries: obj.countries,
              fb_regions: obj.regions
            }})
          } else (
            console.log('No Data!')
          )
        })

        .catch((error) => {
          console.log(error);
          if (error?.response?.data?.message === 'Invalid') {
            appContext.forceLogout()
          }
        });
    };

    const getFbFanPagesPost = () => {
      axios.post(urlFbFanPagesPost, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type, x-access-token",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
          "Authorization": "Bearer "+token,
          "Content-Type": "application/json",
        },
      })
      
      .then((response) => {
        const obj = response.data
        setData(p => { return { ...p, fb_token: obj.token } })
      })

      .catch((error) => {
        console.log(error);
        if (error?.response?.data?.message === 'Invalid') {
          appContext.forceLogout()
        }
      })
    }

    // const getEmailoctopusLists = () => {
    //   axios.post(urlEmailoctopusLists, {
    //     headers: {
    //       "Access-Control-Allow-Origin": "*",
    //       "Access-Control-Allow-Headers": "Content-Type, x-access-token",
    //       "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
    //       "Authorization": "Bearer "+token,
    //       "Content-Type": "application/json",
    //     },
    //   },
    //   {
    //     signal: controller.signal
    //   })

    //   .then((response) => {
    //     const obj = response?.data
    //     console.log(obj);

    //     if (obj?.lists) {
    //       setData(p => { return {...p, emailoctopusLists: obj.lists} })
    //     } else {
    //       console.log('No data. Please refresh.')
    //     }
    //   })

    //   .catch((error) => {
    //     console.log(error);
    //     if (error?.response?.data?.message === 'Invalid') {
    //       appContext.forceLogout()
    //     }
    //   })
    // }

    // const getEmailoctopusCampaigns = () => {
    //   axios.post(urlEmailoctopusCampaigns, {
    //     headers: {
    //       "Access-Control-Allow-Origin": "*",
    //       "Access-Control-Allow-Headers": "Content-Type, x-access-token",
    //       "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
    //       "Authorization": "Bearer "+token,
    //       "Content-Type": "application/json",
    //     },
    //   },
    //   {
    //     signal: controller.signal
    //   })

    //   .then((response) => {
    //     const obj = response?.data
    //     console.log(obj);

    //     if (obj?.campaign) {
    //       setData(p => { return {...p, emailoctopusCampaigns: obj.campaign} })
    //     } else {
    //       console.log('No data. Please refresh.')
    //     }
    //   })

    //   .catch((error) => {
    //     console.log(error);
    //     if (error?.response?.data?.message === 'Invalid') {
    //       appContext.forceLogout()
    //     }
    //   })
    // }

    // const getFbFanPagesLinks = () => {
    //   axios.post(urlFbFanPagesLinks, {
    //     headers: {
    //       "Access-Control-Allow-Origin": "*",
    //       "Access-Control-Allow-Headers": "Content-Type, x-access-token",
    //       "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
    //       "Authorization": "Bearer "+token,
    //       "Content-Type": "application/json",
    //     },
    //     action: "read",
    //     rules: {},
    //     filter: {},
    //     update: {}
    //   })

    //   .then((response) => {
    //     const obj = response.data
    //     setData(p => {
    //       return {
    //         ...p,
    //         fb_fan_pages_links: obj.result ? obj.result : []
    //       }
    //     });
    //   })

    //   .catch((error) => {
    //     console.log(error);
    //     if (error?.response?.data?.message === 'Invalid') {
    //       appContext.forceLogout()
    //     }
    //   })
    // }

    getFbCountries()
    getFbFanPagesPost()
    // getEmailoctopusCampaigns()
    // getEmailoctopusLists()
    // getFbFanPagesLinks()

    return () => {
      controller.abort()
    };
  }, []);


  


  // get unused domains
  useEffect(() => {
    const controller = new AbortController();
    const token = Cookies.get("token");
    const urlUD = `${host}/api/unused_domains`;

    const getUnusedDomains = () => {
      setData(p => { return { ...p, loading: { ...p.loading, unusedDomains: true } } })
      axios.post(urlUD, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type, x-access-token",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
          "Authorization": "Bearer "+token,
          "Content-Type": "application/json",
        },
      },
      {
        signal: controller.signal
      })

      .then((response) => {
        const obj = response.data
        console.log(obj)

        if (obj.result) {
          setData(p => {
            return {
              ...p,
              unusedDomains: obj.result.map(e => (e.topic ? e : {...e, topic: e.domain.replace('.store', '').split('-').join(' ').toUpperCase()})),
              unusedDomainsV2: obj.unused_domainsList_v2 ? obj.unused_domainsList_v2 : []
            }}
          )
        } else {
          console.log('No data. Please refresh.')
        }

        setData(p => { return { ...p, loading: { ...p.loading, unusedDomains: false } } })
      })

      .catch((error) => {
        console.log(error);
        setData(p => { return { ...p, loading: { ...p.loading, unusedDomains: false } } })
        if (error?.response?.data?.message === 'Invalid') {
          appContext.forceLogout()
        }
      })
    }

    getUnusedDomains()

    return () => {
      controller.abort()
    };
  }, [refreshUnusedDomains])




  
  // get facebook fan page
  useEffect(() => {
    if (users.length === 0) {
      return
    }

    let usernameArray = []

    if ((Cookies.get('role') === 'admin') || (Cookies.get('manager') === 'admin')) {
      usernameArray = users.filter(f => f.role === 'manager').map(e => e.username)
    } else if (Cookies.get('role') === 'widget') {
      usernameArray = [Cookies.get('manager')]
    } else {
      usernameArray = [Cookies.get('userArray')]
    }

    const controller = new AbortController();
    const token = Cookies.get("token");
    
    const urlFbFanPages = `${host}/api/admin/facebook_page`;

    const getFbFanPages = () => {
      axios.post(
          urlFbFanPages,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "Content-Type, x-access-token",
              "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
              "Authorization": "Bearer "+token,
              "Content-Type": "application/json",
            },
            username: usernameArray
          },
          {
            signal: controller.signal
          }
        )

        .then((response) => {
          const obj = response.data;
          console.log(obj)

          if (obj && Array.isArray(obj)) {
            obj.sort((a, b) => {
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            });
            setData(p => { return {
              ...p,
              fb_fan_pages: obj
            }})
          }
        })

        .catch((error) => {
          console.log(error);
          if (error?.response?.data?.message === 'Invalid') {
            appContext.forceLogout()
          }
        });
    };

    getFbFanPages()

    return () => {
      controller.abort()
    };
  }, [users])





  // get fb account on username change
  useEffect(() => {
    const controller = new AbortController();
    const token = Cookies.get("token");
    const manager = Cookies.get("manager");
    const url = `${host}/api/index/ids`;
    
    const getData = () => {
      axios.post(
        url,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type, x-access-token",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
            "Authorization": "Bearer "+token,
            "Content-Type": "application/json",
          },
          username: ((Cookies.get('role') === 'admin') || (Cookies.get('manager') === 'admin')) ? filterUser : manager
        },
        {
          signal: controller.signal
        }
      )

      .then((response) => {
        const obj = response.data;
        
        if (obj.result) {
          let temp = obj.result
          temp = temp.map(e => { return { ...e, unique: e.id + "~" + e.client } })
          setData(p => {return{...p, fb: temp.sort((a, b) => a.name > b.name ? 1 : -1 )}})
        } else (
          console.log('No Data!')
        )
      })

      .catch((error) => {
        console.log(error);
        if (error?.response?.data?.message === 'Invalid') {
          appContext.forceLogout()
        }
      });
    };

    getData()

    return () => {
      controller.abort()
    };
  }, [filterUser])





  // fetch facebook images and videos
  useEffect(() => {
    if ((data.fb_fan_pages.length === 0) || (data.fb.length === 0)) {
      setData(p => { return { ...p, fb_images: {}, fb_videos: {} } })
      return
    }

    const controller = new AbortController();
    const token = Cookies.get("token");
    const url_fb_images = `${host}/api/create_campaign/adimages`;
    const fb_ac_ids = Array.from(new Set(data.fb.map(e => e.id)))

    const getAllFbImageData = async () => {
      setData(p => { return { ...p, loading: { ...p.loading, fb_images: true } } })
      let images_result = {}
      let videos_result = {}

      const wait_promise = await Promise.all(fb_ac_ids.map(async (id) => {
        try {
          const response = await axios.post(url_fb_images,
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
              },
              account_id: id
            },
            {
              signal: controller.signal
            }
          )

          const obj = response.data
          console.log(obj)
          
          if (obj.adImages && obj.adVideos) {
            if (obj.adImages.data) {
              images_result[id] = obj.adImages.data
            } else {
              images_result[id] = []
            }
            if (obj.adVideos.data) {
              videos_result[id] = obj.adVideos.data
            } else {
              videos_result[id] = []
            }
          } else {
            images_result[id] = []
            videos_result[id] = []
          }
        } catch (error) {
          console.log(error)
          images_result[id] = []
          videos_result[id] = []
        }
      }))

      setData(p => { return { ...p, loading: { ...p.loading, fb_images: false }, fb_images: images_result, fb_videos: videos_result } })
    }

    const getSingleFbImageData = async () => {
      setData(p => { return { ...p, loading: { ...p.loading, fb_images: true } } })
      let images_result = { ...data.fb_images }
      let videos_result = { ...data.fb_videos }

      try {
        const response = await axios.post(url_fb_images,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "Content-Type, x-access-token",
              "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
              "Authorization": "Bearer "+token,
              "Content-Type": "application/json",
            },
            account_id: uploadFbImage.act_id
          },
          {
            signal: controller.signal
          }
        )

        const obj = response.data
        console.log(obj)

        if (obj.adImages && obj.adVideos) {
          if (obj.adImages.data) {
            images_result[uploadFbImage.act_id] = obj.adImages.data
          } else {
            images_result[uploadFbImage.act_id] = []
          }
          if (obj.adVideos.data) {
            videos_result[uploadFbImage.act_id] = obj.adVideos.data
          } else {
            videos_result[uploadFbImage.act_id] = []
          }
        } else {
          images_result[uploadFbImage.act_id] = []
          videos_result[uploadFbImage.act_id] = []
        }
      } catch (error) {
        console.log(error)
        images_result[uploadFbImage.act_id] = []
        videos_result[uploadFbImage.act_id] = []
      }

      setData(p => { return { ...p, loading: { ...p.loading, fb_images: false }, fb_images: images_result, fb_videos: videos_result } })
    }

    if (Object.keys(data.fb_images).length !== fb_ac_ids.length) {
      getAllFbImageData()
    } else {
      getSingleFbImageData()
    }

    return () => {
      controller.abort()
    };
  }, [uploadFbImage.refresh, data.fb_fan_pages, data.fb]);




  
  // fetch widgets
  useEffect(() => {
    const controller = new AbortController();
    const token = Cookies.get("token");
    const manager = Cookies.get("manager");
    const url = `${host}/api/index`;

    const getData = () => {
      setLoading(true);
      axios.post(url,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type, x-access-token",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
            "Authorization": "Bearer "+token,
            "Content-Type": "application/json",
          },
          username: ((appContext.role === 'admin') || (Cookies.get('manager') === 'admin') || (Cookies.get('userArray') === 'LFCWidget')) ? filterUser : manager,
          pageNumber: page
        },
        {
          signal: controller.signal
        }
      )

      .then((response) => {
        const obj = response.data;
        console.log(obj);

        if (obj?.result?.length > 0) {
          if (obj?.result?.length < 100) {
            setPageOptions(p => { return { ...p, display_next: false, total_length: obj?.result?.length } })
          } else {
            setPageOptions(p => { return { ...p, display_next: true, total_length: obj?.result?.length } })
          }

          let temp = obj.result.filter(e => (e.case === 'Facebook') || (e.case === 'Ads Creation') || (e.case === 'Ads Creation (Keywords)'))
          setData(p => { return { ...p, widget: temp } })
        } else {
          console.log('No Data!')
          setData(p => { return { ...p, widget: [] } })
        }

        setBulkUpdate(p => {
          let temp_bulk = {}
          if (p.clear_list === true) {
            temp_bulk = { ...p, loading: false, display_list: false, keyword_error: [], duplicate_url: [], list: {}, open_drop_down: '', display_multi_delete: false, clear_list: true }
          } else {
            temp_bulk = { ...p, loading: false, duplicate_url: [], display_multi_delete: false, clear_list: true }
          }
          return temp_bulk
        })
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.data?.message === 'Invalid') {
          appContext.forceLogout()
        }
      })
      .finally(() => setLoading(false))
    };

    getData();

    return () => {
      controller.abort()
    };
  }, [refresh, page, filterUser]);





  return [loading, data];
};

export default useFetchWidget;