import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import moment from 'moment'
import { host } from './host'
import { Context } from '../AppContext'

const useFetchFacebookAccounts = () => {
    const appContext = useContext(Context)
    
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        const controller = new AbortController();
        const token = Cookies.get('token')
        const url = `${host}/api/fb`;
        
        const getData = async () => {
            setLoading(true)

            await axios.post(url, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                    "Authorization": "Bearer "+token,
                    "Content-Type": "application/json",
                },
                lastDate: moment().utc().add(8, 'hours').subtract(2, 'days').format('YYYY-MM-DD')
            },
            {
                signal: controller.signal
            })

            .then((response) => {
                const obj = response.data

                if (obj.result) {
                    setData(obj.result.map((e, i) => { return { ...e, index: i } }))
                }

                setLoading(false)
            })
            
            .catch((error) => {
                console.log(error);
                setLoading(false)
                if (error?.response?.data?.message === 'Invalid') {
                    appContext.forceLogout()
                }
            }) 
        }

        getData()
        
        return () => {
            controller.abort()
        }
    }, [])

    return [loading, data]
}

export default useFetchFacebookAccounts