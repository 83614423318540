import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";
import Cookies from "js-cookie";
import axios from "axios";
import companyLogo from "../../assets/companyLogo.png";
import { host } from '../../hooks/host'
import { Context } from "../../AppContext";

const Login = ({ setLoginStatus, setRole }) => {
  const appContext = useContext(Context)
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onLoginClick = () => {
    const user = document.getElementById("username").value;
    const pass = document.getElementById("password").value;

    if (user === "" || pass === "") {
      alert("Empty fields!", "Please fill username and password!");
      return;
    }

    loginAPI({ user, pass });
  };

  const loginAPI = async ({ user, pass }) => {
    try {
      setLoading(true);

      const url = `${host}/api/auth/login`;

      await axios.post(url, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
          },
          username: user,
          password: pass,
        })

        .then((response) => {
          const obj = response.data;
          console.log(response)

          if (obj.auth === true) {
            appContext.setRulesInfo(p => { return { ...p, refresh: !p.refresh } })

            if (obj.role === "admin") {
              Cookies.set("token", obj.token);
              Cookies.set("role", obj.role);
              Cookies.set("userArray", "");
              setRole("admin");
              if (obj.trafficList) {
                window.localStorage.setItem("fbAccounts", JSON.stringify(obj.trafficList));
              }
            }
            else if (obj.role === "widget") {
              Cookies.set("token", obj.token);
              Cookies.set("role", obj.role);
              Cookies.set("userArray", obj.result);
              Cookies.set("manager", obj.manager);
              setRole("widget");
            }
            else {
              Cookies.set("token", obj.token);
              Cookies.set("role", "manager");
              Cookies.set("userArray", obj.result);
              Cookies.set("manager", obj.manager);
              setRole("manager");
              if (obj.trafficList) {
                window.localStorage.setItem("fbAccounts", JSON.stringify(obj.trafficList));
              }
            }
            
            Cookies.set("userPermissions", (obj.pages && Array.isArray(obj.pages)) ? JSON.stringify(obj.pages) : "[]");

            document.getElementById("username").value = "";
            document.getElementById("password").value = "";
            setLoginStatus(true);
            navigate("/");
          }
          else {
            document.getElementById("username").value = "";
            document.getElementById("password").value = "";
            window.alert(
              "Failed authorization!\nPlease input username and password correctly."
            );
          }

          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      window.alert("No response from server! Please try again!");
    }
  };

  return (
    <div className="loginContainer">
      <div className="loginFormContainer">
        <div className="companyLogoWrapper">
          <img src={companyLogo} alt="logo" className="companyLogo" />
        </div>

        <div className="loginText">GOATRACKER</div>

        <input
          id="username"
          type="text"
          placeholder="Username"
          className="loginInput"
          onKeyDown={e => {
            if (e.key === 'Enter') {
              onLoginClick()
            }
          }}
        />

        <input
          id="password"
          type="password"
          placeholder="Password"
          className="loginInput"
          onKeyDown={e => {
            if (e.key === 'Enter') {
              onLoginClick()
            }
          }}
        />

        <button className="loginButton" onClick={() => onLoginClick()}>
          {loading ? <div className="loader"></div> : <p>LOGIN</p>}
        </button>
      </div>
    </div>
  );
};

export default Login;
