import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import { host } from './host'
import { Context } from '../AppContext'

const useFetchState = () => {
    const appContext = useContext(Context)

    const [states, setStates] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const controller = new AbortController();
        const token = Cookies.get('token')
        const url = `${host}/states`;

        const getData = async () => {
            setLoading(true)
            await axios.post(url, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                    "Authorization": "Bearer "+token,
                    "Content-Type": "application/json",
                },
            },
            {
                signal: controller.signal
            })

            .then((response) => {
                const obj = response.data

                if (obj.result && Array.isArray(obj.result)) {
                    if (obj.result.length > 0) {
                        setStates(obj.result[0])
                    }
                }
                
                setLoading(false)
            })

            .catch((error) => {
                console.log(error);
                setLoading(false)
                if (error?.response?.data?.message === 'Invalid') {
                    appContext.forceLogout()
                }
            })
        }
    
        getData()

        return () => {
            controller.abort()
        }
    }, [])

    return [loading, states]
}

export default useFetchState