import React, { useState } from "react";
import './adminPage.css'
import '../reusable/sum/sum.css'
import '../dashboard/home.css'
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import homeIcon from '../../assets/homeIcon.svg'
import editIcon from '../../assets/editIcon.svg'
import checkIcon from '../../assets/checkIcon.svg'
import cancelIcon from '../../assets/cancelIcon.svg'
import useFetchUsers from "../../hooks/useFetchUsers";

const AdminPage = () => {
    const navigate = useNavigate()

    const [showEdit, setShowEdit] = useState(true)
    const [singleUser, setSingleUser] = useState(true)

    const fetch = useFetchUsers()
    const userLoading = fetch[0]
    const users = fetch[1]

    let usernameArray = []
    
    const handleEdit = () => {
        setShowEdit(false)
        setSingleUser(false)
    }

    const handleDone = () => {
        setShowEdit(true)
        setSingleUser(true)
        let s = new Set(usernameArray)
        let arr = Array.from(s)

        if (arr.length > 0) {
            Cookies.set('userArray', arr.toString())
            navigate('/userPage')
        }
    }
    
    const handleCancel = () => {
        usernameArray = []
        setShowEdit(true)
        setSingleUser(true)
    }
    
    const handleUserClick = (name) => {
        if (name === 'Total') {
            Cookies.set('userArray', users?.filter(f => f?.role === 'manager')?.map(e => e?.username)?.join(','))
            navigate('/userPage')
        } else {
            if (singleUser) {
                Cookies.set('userArray', name)
                navigate('/userPage')
            }
            else {
                usernameArray.push(name)
            }
        }
    }
    
    const Content = ({name}) => {
        const [change, setChange] = useState(false)
        const [backgroundChange, setBackgroundChange] = useState(false)

        return (
            <div className="gridItem">
                <div className="gridItemWrapper" style={{background: `${(backgroundChange) ? '#36a2eb' : 'none'}`}} onClick={() => {handleUserClick(name);setBackgroundChange(true);}} onMouseEnter={() => setChange(true)} onMouseLeave={() => setChange(false)}>
                    <div className="gridIconContainer">
                        <div className={`homeIcon ${(change || backgroundChange) ? 'homeIcon-inactive' : 'homeIcon-active'}`}/>
                    </div>
                    <div className="linkUsername" style={{color: `${(change || backgroundChange) ? '#FFFFFF' : '#1c1c1e'}`}}>{name}</div>
                </div>
            </div>
        )
    }

    return (
        <div className="sumContainer">
            <div className="sumWrapper">
                <div className="sumContentContainer">
                    <div className="userListTitle">
                        List of Users:&nbsp;&nbsp;&nbsp;&nbsp;
                        <img src={editIcon} onClick={() => handleEdit()} alt="edit" style={{display: `${(showEdit) ? 'block' : 'none'}`}}/>
                        <img src={checkIcon} onClick={() => handleDone()} alt="done" style={{display: `${(!showEdit) ? 'block' : 'none'}`}}/>
                        <img src={cancelIcon} onClick={() => handleCancel()} alt="cancel" style={{display: `${(!showEdit) ? 'block' : 'none'}`, marginLeft: '20px'}}/>
                    </div>

                    {(userLoading) ? (
                        <div className="gridContainer">
                            <div className="gridItem">
                                <div className="gridItemWrapper">
                                    <div className="gridIconContainer">
                                        <div>
                                            <img src={homeIcon} alt="home" style={{height: '32px', width: '32px'}}/>
                                        </div>
                                    </div>
                                    <div className="linkUsername">
                                        <div className="skeletonMedium">
                                            <div className="loadingAnimation"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="gridContainer">
                            {showEdit && <Content name={'Total'}/>}
                            {users?.filter(f => f.role === 'manager').map((name, i) => (
                                <Content key={i} name={name.username}/>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default AdminPage