import React from "react";
import Sum from "../reusable/sum/Sum";
import crossroadsLogo from '../../assets/crossroadsLogo.svg'

const CrossroadsTotal = () => {
    return(
        <Sum clientName='crossroads' logo={crossroadsLogo} minusDate={2} />
    )
}

export default CrossroadsTotal