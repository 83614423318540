import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import moment from 'moment'
import Cookies from 'js-cookie'
import { host } from './host'
import { weekly_data } from '../local/weekly'
import { Context } from '../AppContext'

const useFetchGrandTotal = ({fromDate, toDate, displayWeekly}) => {
    const appContext = useContext(Context)
    
    const [dailyData, setDailyData] = useState({ loading: false, dateArray: [], totals: {}, forChart: {}, capital: {} })
    const [weeklyData, setWeeklyData] = useState({ loading: false, data: [] })

    // for fetching weekly data - only runs once every time the page is visited
    useEffect(() => {
        const controller = new AbortController();
        const token = Cookies.get('token')
        const user = Cookies.get('userArray')
        const url = `${host}/api/data/total`;

        if (appContext.role !== 'admin') {
            console.log('Weekly data fetch avoided!')
            setWeeklyData(p => {return { ...p, loading: false }})
            return
        }

        let obj = []
        let local_date = []

        user?.split(',')?.map(e => {
            if (weekly_data[e]) {
                weekly_data[e].map(map => {
                    obj.push(map)
                })
            }
        })

        local_date = Array.from(new Set(obj.map(e => e.date))).sort((a, b) => new Date(a) - new Date(b))

        // calculate all revenue & spent
        obj = local_date.map(e => {
            let same_date_data = obj.filter(f => f.date === e)

            return {
                date: e,
                tonic: {
                    revenue: same_date_data.map(map => map?.tonic?.revenue ? map?.tonic?.revenue : 0).reduce((a, b) => a + b, 0),
                    spent: same_date_data.map(map => map?.tonic?.spent ? map?.tonic?.spent : 0).reduce((a, b) => a + b, 0),
                    result: 0,
                    "ROI%": 0,
                },
                sedo: {
                    revenue: same_date_data.map(map => map?.sedo?.revenue ? map?.sedo?.revenue : 0).reduce((a, b) => a + b, 0),
                    spent: same_date_data.map(map => map?.sedo?.spent ? map?.sedo?.spent : 0).reduce((a, b) => a + b, 0),
                    result: 0,
                    "ROI%": 0,
                },
                system1: {
                    revenue: same_date_data.map(map => map?.system1?.revenue ? map?.system1?.revenue : 0).reduce((a, b) => a + b, 0),
                    spent: same_date_data.map(map => map?.system1?.spent ? map?.system1?.spent : 0).reduce((a, b) => a + b, 0),
                    result: 0,
                    "ROI%": 0,
                },
                crossroads: {
                    revenue: same_date_data.map(map => map?.crossroads?.revenue ? map?.crossroads?.revenue : 0).reduce((a, b) => a + b, 0),
                    spent: same_date_data.map(map => map?.crossroads?.spent ? map?.crossroads?.spent : 0).reduce((a, b) => a + b, 0),
                    result: 0,
                    "ROI%": 0,
                },
            }
        })

        // calculate all result and ROI%
        obj = obj.map(e => {
            return {
                date: e.date,
                tonic: {
                    revenue: e.tonic.revenue,
                    spent: e.tonic.spent,
                    result: e.tonic.revenue - e.tonic.spent,
                    "ROI%": ((e.tonic.revenue / e.tonic.spent) - 1) * 100,
                },
                sedo: {
                    revenue: e.sedo.revenue,
                    spent: e.sedo.spent,
                    result: e.sedo.revenue - e.sedo.spent,
                    "ROI%": ((e.sedo.revenue / e.sedo.spent) - 1) * 100,
                },
                system1: {
                    revenue: e.system1.revenue,
                    spent: e.system1.spent,
                    result: e.system1.revenue - e.system1.spent,
                    "ROI%": ((e.system1.revenue / e.system1.spent) - 1) * 100,
                },
                crossroads: {
                    revenue: e.crossroads.revenue,
                    spent: e.crossroads.spent,
                    result: e.crossroads.revenue - e.crossroads.spent,
                    "ROI%": ((e.crossroads.revenue / e.crossroads.spent) - 1) * 100,
                },
            }
        })

        const getWeeklyData = async () => {
            setWeeklyData(p => {return { ...p, loading: true }})
            
            await axios.post(url,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                    "Authorization": "Bearer "+token,
                    "Content-Type": "application/json",
                },
                data: {
                    from: (local_date.length === 0) ? fromDate : moment(local_date[local_date.length - 1]).add('7', 'days').format('YYYY-MM-DD'),
                    to: moment().subtract('2', 'day').format('YYYY-MM-DD'),
                    username: user,
                    range: 'weekly'
                },
            },
            {
                signal: controller.signal
            })
    
            .then((response) => {
                const res = response.data

                console.log(res);

                if (res.length > 0) {
                    res.map(e => {
                        obj.push(e)
                        local_date.push(e.date)
                    })
                }

                obj = obj.map(e => {
                    return {
                        date: e.date,
                        tonic: {
                            revenue: e.tonic.revenue / 0.93,
                            spent: e.tonic.spent * 1.03,
                            result: (e.tonic.revenue / 0.93) - (e.tonic.spent * 1.03),
                            "ROI%": (((e.tonic.revenue / 0.93) / (e.tonic.spent * 1.03)) - 1) * 100,
                        },
                        sedo: {
                            revenue: e.sedo.revenue / 0.93,
                            spent: e.sedo.spent * 1.03,
                            result: (e.sedo.revenue / 0.93) - (e.sedo.spent * 1.03),
                            "ROI%": (((e.sedo.revenue / 0.93) / (e.sedo.spent * 1.03)) - 1) * 100,
                        },
                        system1: {
                            revenue: e.system1.revenue / 0.93,
                            spent: e.system1.spent * 1.03,
                            result: (e.system1.revenue / 0.93) - (e.system1.spent * 1.03),
                            "ROI%": (((e.system1.revenue / 0.93) / (e.system1.spent * 1.03)) - 1) * 100,
                        },
                        crossroads: {
                            revenue: e.crossroads.revenue / 0.93,
                            spent: e.crossroads.spent * 1.03,
                            result: (e.crossroads.revenue / 0.93) - (e.crossroads.spent * 1.03),
                            "ROI%": (((e.crossroads.revenue / 0.93) / (e.crossroads.spent * 1.03)) - 1) * 100,
                        },
                    }
                })

                obj.sort((a, b) => new Date(a.date) - new Date(b.date))
                
                setWeeklyData(p => {return { ...p, loading: false, data: obj }})
            })
            
            .catch((error) => {
                console.log(error);
                                        
                if (error?.name !== 'CanceledError') {
                    setWeeklyData(p => {return { ...p, loading: false, data: [] }})
                }

                if (error?.response?.data?.message === 'Invalid') {
                    appContext.forceLogout()
                }
            })
        }
        
        getWeeklyData()

        return () => {
            controller.abort()
        }
    }, [])




    // for fetching daily data only - for both admin and manager accounts
    useEffect(() => {
        const controller = new AbortController();
        const token = Cookies.get('token')
        const user = Cookies.get('userArray')
        let timeoutID = null;

        const from = fromDate.split('-');
        const to = toDate.split('-');
        let dateArray = []

        const dates = getDatesInRange(new Date(from[0], from[1]-1, from[2]), new Date(to[0], to[1]-1, to[2]))
            dates.forEach(function (date) {
                dateArray.push(moment(date).format('YYYY-MM-DD'))
        })

        if (dates.length === dailyData.dateArray.length) {
            console.log('same length!')
            setDailyData(p => {return { ...p, loading: false }})
            return
        }

        if (displayWeekly && appContext.role === 'admin') {
            console.log('Daily data fetch avoided!')
            setDailyData(p => {return { ...p, loading: false }})
            return
        }
        
        if (moment(toDate) < moment(fromDate)) {
            console.log('To date is less than from date')
            setDailyData(p => {return { ...p, loading: false }})
            return
        }
        
        if (moment(fromDate) >= moment() || moment(toDate) >= moment()) {
            console.log('Input dates are more than today!')
            setDailyData(p => {return { ...p, loading: false }})
            return
        }

        setDailyData(p => {return { ...p, dateArray: dateArray }})

        const url = `${host}/api/data/total`;
        
        const getData = async () => {
            setDailyData(p => {return { ...p, loading: true }})

            await axios.post(url,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                    "Authorization": "Bearer "+token,
                    "Content-Type": "application/json",
                },
                    data: {client: 'all', from: fromDate, to: toDate, username: user, range: ''},
            },
            {
                signal: controller.signal
            })
    
            .then((response) => {
                const obj = response.data

                console.log(obj);

                // revenueO means original revenue
                // revenue means all
                let bodis = { revenue: 0, revenueO: 0, spent: 0, result: 0, conversion: 0 }
                let sedo = { revenue: 0, revenueO: 0, spent: 0, result: 0, conversion: 0 }
                let system1 = { revenue: 0, revenueO: 0, spent: 0, result: 0, conversion: 0 }
                let tonic = { revenue: 0, revenueO: 0, spent: 0, result: 0, conversion: 0 }
                let adcombo = { revenue: 0, revenueO: 0, spent: 0, result: 0, conversion: 0 }
                let crossroads = { revenue: 0, revenueO: 0, spent: 0, result: 0, conversion: 0 }
                let activeCamps = {}
                let chart = { bodis: [], sedo: [], system1: [], tonic: [], adcombo: [], crossroads: [], roi: [], result: [], spent: [] }

                if (obj.bodisTotal && obj.bodisTotal !== null && obj.bodisTotal.length) {
                    bodis.revenue = obj.bodisTotal.map(e => e.revenue ? e.revenue : 0).reduce((a, b) => a*1 + b*1, 0)
                    bodis.revenueO = obj.bodisTotal.map(e => e.revenueO ? e.revenueO : 0).reduce((a, b) => a*1 + b*1, 0)
                    bodis.spent = obj.bodisTotal.map(e => e.spent ? e.spent : 0).reduce((a, b) => a*1 + b*1, 0)
                    bodis.conversion = obj.bodisTotal.map(e => e.actions ? e.actions : 0).reduce((a, b) => a*1 + b*1, 0)
                    bodis.result = obj.bodisTotal.map(e => e.result ? e.result : 0).reduce((a, b) => a*1 + b*1, 0)
                    chart.bodis = dateArray.map(e => {
                        const temp = obj.bodisTotal.filter(f => f.date === e)
                        let sum_obj = {}
                        if (temp.length > 0) {
                            Object.keys(temp[0]).map(m => {
                                if (typeof(temp[0][m]) === 'number') {
                                    sum_obj[m] = temp.map(item => item[m] ? item[m] : 0).reduce((a, b) => a*1 + b*1, 0)
                                } else {
                                    sum_obj[m] = temp[0][m]
                                }
                            })
                        }
                        return (temp.length > 0) ? sum_obj : 0
                    })
                }
                if (obj.sedoTotal && obj.sedoTotal !== null && obj.sedoTotal.length) {
                    sedo.revenue = obj.sedoTotal.map(e => e.revenue ? e.revenue : 0).reduce((a, b) => a*1 + b*1, 0)
                    sedo.revenueO = obj.sedoTotal.map(e => e.revenueO ? e.revenueO : 0).reduce((a, b) => a*1 + b*1, 0)
                    sedo.spent = obj.sedoTotal.map(e => e.spent ? e.spent : 0).reduce((a, b) => a*1 + b*1, 0)
                    sedo.conversion = obj.sedoTotal.map(e => e.actions ? e.actions : 0).reduce((a, b) => a*1 + b*1, 0)
                    sedo.result = obj.sedoTotal.map(e => e.result ? e.result : 0).reduce((a, b) => a*1 + b*1, 0)
                    chart.sedo = dateArray.map(e => {
                        const temp = obj.sedoTotal.filter(f => f.date === e)
                        let sum_obj = {}
                        if (temp.length > 0) {
                            Object.keys(temp[0]).map(m => {
                                if (typeof(temp[0][m]) === 'number') {
                                    sum_obj[m] = temp.map(item => item[m] ? item[m] : 0).reduce((a, b) => a*1 + b*1, 0)
                                } else {
                                    sum_obj[m] = temp[0][m]
                                }
                            })
                        }
                        return (temp.length > 0) ? sum_obj : 0
                    })
                }
                if (obj.system1Total && obj.system1Total !== null && obj.system1Total.length) {
                    system1.revenue = obj.system1Total.map(e => e.revenue ? e.revenue : 0).reduce((a, b) => a*1 + b*1, 0)
                    system1.revenueO = obj.system1Total.map(e => e.revenueO ? e.revenueO : 0).reduce((a, b) => a*1 + b*1, 0)
                    system1.spent = obj.system1Total.map(e => e.spent ? e.spent : 0).reduce((a, b) => a*1 + b*1, 0)
                    system1.conversion = obj.system1Total.map(e => e.actions ? e.actions : 0).reduce((a, b) => a*1 + b*1, 0)
                    system1.result = obj.system1Total.map(e => e.result ? e.result : 0).reduce((a, b) => a*1 + b*1, 0)
                    chart.system1 = dateArray.map(e => {
                        const temp = obj.system1Total.filter(f => f.date === e)
                        let sum_obj = {}
                        if (temp.length > 0) {
                            Object.keys(temp[0]).map(m => {
                                if (typeof(temp[0][m]) === 'number') {
                                    sum_obj[m] = temp.map(item => item[m] ? item[m] : 0).reduce((a, b) => a*1 + b*1, 0)
                                } else {
                                    sum_obj[m] = temp[0][m]
                                }
                            })
                        }
                        return (temp.length > 0) ? sum_obj : 0
                    })
                }
                if (obj.tonicTotal && obj.tonicTotal !== null && obj.tonicTotal.length) {
                    tonic.revenue = obj.tonicTotal.map(e => e.revenue ? e.revenue : 0).reduce((a, b) => a*1 + b*1, 0)
                    tonic.revenueO = obj.tonicTotal.map(e => e.revenueO ? e.revenueO : 0).reduce((a, b) => a*1 + b*1, 0)
                    tonic.spent = obj.tonicTotal.map(e => e.spent ? e.spent : 0).reduce((a, b) => a*1 + b*1, 0)
                    tonic.conversion = obj.tonicTotal.map(e => e.actions ? e.actions : 0).reduce((a, b) => a*1 + b*1, 0)
                    tonic.result = obj.tonicTotal.map(e => e.result ? e.result : 0).reduce((a, b) => a*1 + b*1, 0)
                    chart.tonic = dateArray.map(e => {
                        const temp = obj.tonicTotal.filter(f => f.date === e)
                        let sum_obj = {}
                        if (temp.length > 0) {
                            Object.keys(temp[0]).map(m => {
                                if (typeof(temp[0][m]) === 'number') {
                                    sum_obj[m] = temp.map(item => item[m] ? item[m] : 0).reduce((a, b) => a*1 + b*1, 0)
                                } else {
                                    sum_obj[m] = temp[0][m]
                                }
                            })
                        }
                        return (temp.length > 0) ? sum_obj : 0
                    })
                }

                if (obj.adcomboTotal && obj.adcombo !== null && obj.adcomboTotal.length) {
                    adcombo.revenue = obj.adcomboTotal.map(e => e.revenue ? e.revenue : 0).reduce((a, b) => a*1 + b*1, 0)
                    adcombo.revenueO = obj.adcomboTotal.map(e => e.revenueO ? e.revenueO : 0).reduce((a, b) => a*1 + b*1, 0)
                    adcombo.spent = obj.adcomboTotal.map(e => e.spent ? e.spent : 0).reduce((a, b) => a*1 + b*1, 0)
                    adcombo.conversion = obj.adcomboTotal.map(e => e.actions ? e.actions : 0).reduce((a, b) => a*1 + b*1, 0)
                    adcombo.result = obj.adcomboTotal.map(e => e.result ? e.result : 0).reduce((a, b) => a*1 + b*1, 0)
                    chart.adcombo = dateArray.map(e => {
                        const temp = obj.adcomboTotal.filter(f => f.date === e)
                        let sum_obj = {}
                        if (temp.length > 0) {
                            Object.keys(temp[0]).map(m => {
                                if (typeof(temp[0][m]) === 'number') {
                                    sum_obj[m] = temp.map(item => item[m] ? item[m] : 0).reduce((a, b) => a*1 + b*1, 0)
                                } else {
                                    sum_obj[m] = temp[0][m]
                                }
                            })
                        }
                        return (temp.length > 0) ? sum_obj : 0
                    })
                }
                if (obj.crossroadsTotal && obj.crossroadsTotal !== null && obj.crossroadsTotal.length) {
                    crossroads.revenue = obj.crossroadsTotal.map(e => e.revenue ? e.revenue : 0).reduce((a, b) => a*1 + b*1, 0)
                    crossroads.revenueO = obj.crossroadsTotal.map(e => e.revenueO ? e.revenueO : 0).reduce((a, b) => a*1 + b*1, 0)
                    crossroads.spent = obj.crossroadsTotal.map(e => e.spent ? e.spent : 0).reduce((a, b) => a*1 + b*1, 0)
                    crossroads.conversion = obj.crossroadsTotal.map(e => e.actions ? e.actions : 0).reduce((a, b) => a*1 + b*1, 0)
                    crossroads.result = obj.crossroadsTotal.map(e => e.result ? e.result : 0).reduce((a, b) => a*1 + b*1, 0)
                    chart.crossroads = dateArray.map(e => {
                        const temp = obj.crossroadsTotal.filter(f => f.date === e)
                        let sum_obj = {}
                        if (temp.length > 0) {
                            Object.keys(temp[0]).map(m => {
                                if (typeof(temp[0][m]) === 'number') {
                                    if (m === 'ROI%') {
                                        sum_obj[m] = temp.map(item => item.result ? item.result : 0).reduce((a, b) => a*1 + b*1, 0) / temp.map(item => item.spent ? item.spent : 0).reduce((a, b) => a*1 + b*1, 0)
                                    } else {
                                        sum_obj[m] = temp.map(item => item[m] ? item[m] : 0).reduce((a, b) => a*1 + b*1, 0)
                                    }
                                } else {
                                    sum_obj[m] = temp[0][m]
                                }
                            })
                        }
                        return (temp.length > 0) ? sum_obj : 0
                    })
                }

                if (obj.last_date_result) {
                    activeCamps = obj.last_date_result
                }

                chart.roi = dateArray.map(e => {
                    let bodisR = chart.bodis.filter(f => f.date === e).length > 0 ? chart.bodis.filter(f => f.date === e)[0].result * 1 : 0
                    let tonicR = chart.tonic.filter(f => f.date === e).length > 0 ? chart.tonic.filter(f => f.date === e)[0].result * 1 : 0
                    let system1R = chart.system1.filter(f => f.date === e).length > 0 ? chart.system1.filter(f => f.date === e)[0].result * 1 : 0
                    let sedoR = chart.sedo.filter(f => f.date === e).length > 0 ? chart.sedo.filter(f => f.date === e)[0].result * 1 : 0
                    let adcomboR = chart.adcombo.filter(f => f.date === e).length > 0 ? chart.adcombo.filter(f => f.date === e)[0].result * 1 : 0
                    let crossroadsR = chart.crossroads.filter(f => f.date === e).length > 0 ? chart.crossroads.filter(f => f.date === e)[0].result * 1 : 0

                    let bodisS = chart.bodis.filter(f => f.date === e).length > 0 ? chart.bodis.filter(f => f.date === e)[0].spent * 1 : 0
                    let tonicS = chart.tonic.filter(f => f.date === e).length > 0 ? chart.tonic.filter(f => f.date === e)[0].spent * 1 : 0 
                    let system1S = chart.system1.filter(f => f.date === e).length > 0 ? chart.system1.filter(f => f.date === e)[0].spent * 1 : 0 
                    let sedoS = chart.sedo.filter(f => f.date === e).length > 0 ? chart.sedo.filter(f => f.date === e)[0].spent * 1 : 0
                    let adcomboS = chart.adcombo.filter(f => f.date === e).length > 0 ? chart.adcombo.filter(f => f.date === e)[0].spent * 1 : 0
                    let crossroadsS = chart.crossroads.filter(f => f.date === e).length > 0 ? chart.crossroads.filter(f => f.date === e)[0].spent * 1 : 0

                    let result = (bodisR + tonicR + system1R + sedoR + adcomboR + crossroadsR) / (bodisS + tonicS + system1S + sedoS + adcomboS + crossroadsS)

                    return result * 100
                });

                chart.result = dateArray.map(e => {
                    let bodisR = chart.bodis.filter(f => f.date === e).length > 0 ? chart.bodis.filter(f => f.date === e)[0].result * 1 : 0
                    let tonicR = chart.tonic.filter(f => f.date === e).length > 0 ? chart.tonic.filter(f => f.date === e)[0].result * 1 : 0
                    let system1R = chart.system1.filter(f => f.date === e).length > 0 ? chart.system1.filter(f => f.date === e)[0].result * 1 : 0
                    let sedoR = chart.sedo.filter(f => f.date === e).length > 0 ? chart.sedo.filter(f => f.date === e)[0].result * 1 : 0
                    let adcomboR = chart.adcombo.filter(f => f.date === e).length > 0 ? chart.adcombo.filter(f => f.date === e)[0].result * 1 : 0
                    let crossroadsR = chart.crossroads.filter(f => f.date === e).length > 0 ? chart.crossroads.filter(f => f.date === e)[0].result * 1 : 0

                    let result = bodisR + tonicR + system1R + sedoR + adcomboR + crossroadsR

                    return result
                });

                chart.spent = dateArray.map(e => {
                    let bodisS = chart.bodis.filter(f => f.date === e).length > 0 ? chart.bodis.filter(f => f.date === e)[0].spent * 1 : 0
                    let tonicS = chart.tonic.filter(f => f.date === e).length > 0 ? chart.tonic.filter(f => f.date === e)[0].spent * 1 : 0
                    let system1S = chart.system1.filter(f => f.date === e).length > 0 ? chart.system1.filter(f => f.date === e)[0].spent * 1 : 0
                    let sedoS = chart.sedo.filter(f => f.date === e).length > 0 ? chart.sedo.filter(f => f.date === e)[0].spent * 1 : 0
                    let adcomboS = chart.adcombo.filter(f => f.date === e).length > 0 ? chart.adcombo.filter(f => f.date === e)[0].spent * 1 : 0
                    let crossroadsS = chart.crossroads.filter(f => f.date === e).length > 0 ? chart.crossroads.filter(f => f.date === e)[0].spent * 1 : 0

                    let spent = bodisS + tonicS + system1S + sedoS + adcomboS + crossroadsS

                    return spent
                });
                
                setDailyData(p => {
                    return {
                        ...p,
                        capital: obj.capital ? obj.capital : {},
                        forChart: chart,
                        totals: { bodis, sedo, system1, tonic, adcombo, crossroads, activeCamps },
                        loading: false
                    }
                })
            })
            
            .catch((error) => {
                console.log(error);
                
                if (error?.name !== 'CanceledError') {
                    setDailyData(p => {
                        return {
                            ...p,
                            capital: {},
                            forChart: {},
                            totals: {},
                            dateArray: [],
                            loading: false
                        }
                    })
                }

                if (error?.response?.data?.message === 'Invalid') {
                    appContext.forceLogout()
                }
            })
        }

        timeoutID = setTimeout(() => {
            getData()
        }, 2000);

        return () => {
            controller.abort()
            clearTimeout(timeoutID)
        }
    }, [fromDate, toDate, displayWeekly])
    
    return [dailyData, weeklyData]
}

export default useFetchGrandTotal

// get dates in between function
function getDatesInRange(startDate, endDate) {
    const date = new Date(startDate.getTime());
  
    const dates = [];
  
    while (date <= endDate) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
  
    return dates;
}