import React, { useContext } from "react";
import './topbar.css'
import Cookies from "js-cookie";
import { NavLink } from "react-router-dom";
import companyLogo from '../../assets/companyLogo.png'
import hamburgerMenu from '../../assets/hamburgerMenu.svg'
import settings from '../../assets/settings.svg'
import { Context } from "../../AppContext";

const Topbar = ({ setLoginStatus, handleSidebar }) => {
    const appContext = useContext(Context)

    const onLogoutClick = () => {
        Object.keys(Cookies.get()).map(name => Cookies.remove(name))
        window.localStorage.removeItem("fbAccounts");
        setLoginStatus(false)
        appContext.setBestPerform(p => { return { ...p, loading: false, data: [] } })
    }

    return(
        <div className="topbar">
            <div className="topbarContainer">
                <div className="topbarMenuIcon" onClick={() => {handleSidebar()}}>
                    <img src={hamburgerMenu} alt="Menu" style={{height: '50%'}}/>
                </div>
                <NavLink to='/' className="topbarCompanyLogo">
                    <img src={companyLogo} alt="Company Logo" style={{width: '100%'}}/>
                </NavLink>
                <div className="topbarSettings">
                    <div className="topbarSettingsWrapper">
                        <img src={settings} alt="Settings" style={{height: '60%'}}/>
                        <div className="topbarSettingsList">
                            {/* <NavLink to={'/Account'} className={'topbarSettingsListItem'}>Account</NavLink>
                            {(appContext.role === 'admin') && (<NavLink to={'/rules'} className={'topbarSettingsListItem'}>Rules</NavLink>)} */}
                            {(appContext.role === 'admin') && (<NavLink to={'/settings'} className={'topbarSettingsListItem'}>Settings</NavLink>)}
                            <div onClick={onLogoutClick} className={'topbarSettingsListItem'}>LogOut</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Topbar;