import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import { host } from './host'
import { Context } from '../AppContext'

const useFetchUsers = (refresh) => {
    const appContext = useContext(Context)

    const [users, setUsers] = useState([])
    const [userLoading, setUserLoading] = useState(true)

    useEffect(() => {
        const controller = new AbortController();
        const token = Cookies.get('token')
        const url = `${host}/api/admin`;
        
        const getData = async () => {
            setUserLoading(true)
            await axios.post(url, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                    "Authorization": "Bearer "+token,
                    "Content-Type": "application/json",
                },
            },
            {
                signal: controller.signal
            })

            .then((response) => {
                const obj = response.data
                console.log(response)

                if (obj.err) {
                    setUsers([])
                    window.alert('Could not fetch Users List!')
                } else {
                    setUsers(obj?.sort((a, b) => (a?.role > b?.role) ? 1 : -1 ))
                    // Cookies.set('widgetUsers', JSON.stringify( (obj?.userList?.filter(f => f?.role === 'manager')).map(e => e?.username) ))
                }

                setUserLoading(false)
            })

            .catch((error) => {
                console.log(error);
                setUserLoading(false)
                if (error?.response?.data?.message === 'Invalid') {
                    appContext.forceLogout()
                }
            })
        }
    
        getData()

        return () => {
            controller.abort()
        }
    }, [refresh])

    return [userLoading, users]
}

export default useFetchUsers