import { useState, useEffect, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { host } from './host'
import { Context } from "../AppContext";

const useFetchTotal = ({ client, fromDate, toDate }) => {
  const appContext = useContext(Context)

  const [data, setData] = useState([{
    date: "Total",
    revenue: 0,
    spent: 0,
    actions: 0,
    result: 0,
    CPA: 0,
    clicks: 0,
    "link_clicks": 0,
    "cost_per_conversion" : 0,
    "ROI%": 0,
  }]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const token = Cookies.get("token");
    const user = Cookies.get("userArray");
    const url = `${host}/api/data/total`;

    const getData = async () => {
      setLoading(true);
      await axios
        .post(
          url,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "Content-Type, x-access-token",
              "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
              "Authorization": "Bearer "+token,
              "Content-Type": "application/json",
            },
            data: {
              client: client,
              from: fromDate,
              to: toDate,
              username: user,
            },
          },
          {
            signal: controller.signal
          }
        )

        .then((response) => {
          const obj = response.data;

          if (obj.total_All) {
            setData(obj.total_All);
          } else {
            setData([{
              date: "Total",
              revenue: 0,
              spent: 0,
              actions: 0,
              result: 0,
              CPA: 0,
              clicks: 0,
              "link_clicks": 0,
              "cost_per_conversion" : 0,
              "ROI%": 0,
            }]);
          }

          setLoading(false);
        })

        .catch((error) => {
          console.log(error);
          setLoading(false);
          if (error?.response?.data?.message === 'Invalid') {
            appContext.forceLogout()
          }
        });
    };

    getData();

    return () => {
      setData({
        date: "Total",
        revenue: 0,
        spent: 0,
        actions: 0,
        result: 0,
        CPA: 0,
        clicks: 0,
        "link_clicks": 0,
        "cost_per_conversion" : 0,
        "ROI%": 0,
      });
      controller.abort()
    };
  }, [fromDate, toDate]);

  return [loading, data];
};

export default useFetchTotal;