import React from "react";
import Sum from "../reusable/sum/Sum";
import adsLogo from '../../assets/adsLogo.png'

const AdsTotal = () => {
    return(
        <Sum clientName='ads' logo={adsLogo} minusDate={2} />
    )
}

export default AdsTotal