import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import { host } from './host'
import { Context } from '../AppContext'

const useFetchUnusedDomains = ({ refresh }) => {
    const appContext = useContext(Context)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const controller = new AbortController();
        const token = Cookies.get('token')
        const url = `${host}/api/unused_domains`;

        const getData = async () => {
            setLoading(true)
            await axios.post(url, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                    "Authorization": "Bearer "+token,
                    "Content-Type": "application/json",
                },
            },
            {
                signal: controller.signal
            })

            .then((response) => {
                const obj = response.data

                if (obj.result) {
                    setData(obj.result)
                } else {
                    window.alert('No data. Please refresh.')
                }

                setLoading(false)
            })

            .catch((error) => {
                console.log(error);
                setLoading(false)
                if (error?.response?.data?.message === 'Invalid') {
                    appContext.forceLogout()
                }
            })
        }
    
        getData()

        return () => {
            controller.abort()
        }
    }, [refresh])

    return [loading, data]
}

export default useFetchUnusedDomains