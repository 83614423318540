import React from "react";
import Traffic from "../reusable/traffic/Traffic";
import sedoLogo from '../../assets/sedoLogo.png'

const SedoFacebook = () => {
    return(
        <Traffic trafficName='sedo_facebook' trafficToSend='Facebook' clientName='Sedo' logo={sedoLogo} minusDate={2} />
    )
}

export default SedoFacebook