import React from "react";
import EmailOctopus from "../reusable/emailOctopus/EmailOctopus";
import emailOctopusLogo from '../../assets/emailOctopusLogo.png'

const AllEmailOctopus = () => {
    return(
        <EmailOctopus trafficName='all_email' trafficToSend='Facebook' clientName='All' levelUrlFor='AllClients' logo={emailOctopusLogo} minusDate={2}/>
    )
}

export default AllEmailOctopus