import React, { useContext, useState } from 'react'
import './campaignCSV.css'
import axios from 'axios'
import Cookies from 'js-cookie'
import moment from 'moment'
import { host } from '../../hooks/host'
import Papa from 'papaparse'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from "react-chartjs-2";

import useFetchCampaignCSV from '../../hooks/useFetchCampaignCSV'
import { Context } from '../../AppContext'

const CampaignCSV = () => {
    const appContext = useContext(Context)

    const [fileName, setFileName] = useState('.csv')
    const [file, setFile] = useState([])
    const [fromDate, setFromDate] = useState(moment().utc().add(8, 'hours').subtract(6, 'days').format('YYYY-MM-DD'))
    const [toDate, setToDate] = useState(moment().utc().add(8, 'hours').format('YYYY-MM-DD'))
    const [displayLoadingMsg, setDisplayLoadingMsg] = useState(false)

    const fetch = useFetchCampaignCSV({fromDate, toDate})
    const [loadingTotal, campaignData, totalValue, resultsArray] = fetch

    // parse CSV file to object
    const handleOnChange = (e) => {
        e.preventDefault()
        const files = e.target.files;
        setFileName(files[0].name)

        if (files) {
            Papa.parse(files[0], {
                complete: function(results) {
                    let res = results.data.map((ma, i) => {
                        let final = ma
                        
                        if (i === 0) {
                            final = ma.map(m => {
                                let temp = m
                                if (m === 'REVENUE') {
                                    temp = 'revenue'
                                }
                                if (m === 'COSTS') {
                                    temp = 'spent'
                                }
                                if (m === 'GROSS MARGIN') {
                                    temp = 'result'
                                }
                                if (m === 'DATE') {
                                    temp = 'date'
                                }
                                if (m === 'ROI') {
                                    temp = 'ROI%'
                                }
                                if (m === 'CAMPAIGN') {
                                    temp = 'campaign_name'
                                }
                                if (m === 'ID') {
                                    temp = 'campaign_id'
                                }
                                if (m === 'MANAGER') {
                                    temp = 'User'
                                }
                                if (m === 'SUPPLIER CONV') {
                                    temp = 'actions'
                                }
                                return temp
                            })
                        }
                        return final
                    });

                    const key = res[0];

                    const fin = res.map(e => {
                        let o = {}
                        for (let i = 0; i < e.length; i++) {
                            o[key[i]] = e[i]
                        }
                        return o
                    })

                    setFile(fin.splice(1, fin.length));
                }}
            )
        }
    }

    // manual upload API for CSV
    const uploadAPI = () => {
        const token = Cookies.get('token')
        const url = `${host}/api/data/update`;

        axios.post(url, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
            },
            data: file.splice(0, 100)
        })

        .then((response) => {
            if (response.data === null) {
                window.alert("No authorized true response.")
            }
        })
        
        .catch((error) => {
            console.log(error);
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        }) 
    }

    // call upload API
    const handleUpload = (e) => {
        e.preventDefault()

        for (let i = 0; i < file.length; i++) {
            uploadAPI()
        }

        setFileName('.csv')

        let timeoutID;

        setDisplayLoadingMsg(true)
        timeoutID = setTimeout(() => {
            setDisplayLoadingMsg(false)
        }, 10000);

        return () => clearTimeout(timeoutID)
    }

    // chart js options settings
    const optionsROI = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false
        },
        plugins : {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'chart',
            },
            tooltip: {
                mode: 'index'
            },
        },
        scales: {
            y: {
                id: 'spentAxis',
                type: 'linear',
                position: 'right',
            }
        }
    };

    // chart js data sets
    const dataResult = {
        labels: (resultsArray.date) ? resultsArray.date : [],
        datasets: [
            {
                type: 'line',
                label: 'Result',
                data: (resultsArray.result) ? resultsArray.result : [],
                borderColor: '#4caf50',
                backgroundColor: '#4caf50',
                borderWidth: 4
            },
            {
                type: 'bar',
                label: 'Spent',
                data: (resultsArray.spent) ? resultsArray.spent : [],
                borderColor: 'rgba(239, 83, 80, 0.4)',
                backgroundColor: 'rgba(239, 83, 80, 0.1)',
                borderWidth: 1,
                yAxisID: 'right-y-axis'
            },
            {
                type: 'line',
                label: 'ROI',
                data: (resultsArray.roi) ? resultsArray.roi : [],
                borderColor: 'rgba(33, 150, 243, 0.5)',
                backgroundColor: 'rgba(33, 150, 243, 0.5)',
                borderWidth: 2,
                pointRadius: 2,
                yAxisID: 'left-y-axis'
            },
        ],
    };

    // total table rows
    const totalRows = campaignData

    // total table columns with configurations
    const totalColumns = [
        { field: 'id', headerName: 'ID', hide: true},
        { field: 'off', headerName: 'Status', minWidth: 100, flex: 1, headerClassName: 'datagridHeader',
            renderCell: (params) => {
                let state = (params.row.off === false) ? false : true
                return <div style={{ color: (state) ? '#4caf50' : '#ef5350' }}>{(state) ? 'ON' : 'OFF'}</div>
            }},
        { field: 'campaign_name', headerName: 'Campaign', minWidth: 350, flex: 4, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left' },
        { field: 'revenue', headerName: 'Revenue', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'firstBlock firstBlockLeftBorder', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                let revenue = Number(params.row.revenue)
                return <div>$ {revenue.toFixed(0)}</div>
            },
            valueGetter: (params) => parseFloat(params.row.revenue),
        },
        { field: 'spent', headerName: 'Spent', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'firstBlock', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                let spent = Number(params.row.spent)
                return <div>$ {spent.toFixed(0)}</div>
            }},
        { field: 'result', headerName: 'Result', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'firstBlock', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const resultData = Number(params.row.result)
                return <div style={{color: (resultData > 0) ? '#4caf50' : '#ef5350'}}>$ {resultData.toFixed(1)}</div>
            }},
        { field: 'ROI%', headerName: 'ROI', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'firstBlock', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const ROIdata = Number(params.row["ROI%"])
                return <div style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{ROIdata.toFixed(0)} %</div>
            }},
        { field: 'todayROI', headerName: 'ROI(1)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'secondBlock secondBlockLeftBorder', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const ROIdata = params.row["todayROI"]
                return <div style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{(ROIdata === "-") ? '-' : (ROIdata*1).toFixed(0)+'%'}</div>
            }},
        { field: 'last3DaysROI', headerName: 'ROI(3)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'secondBlock', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const ROIdata = params.row["last3DaysROI"]
                return <div style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{(ROIdata === "-") ? '-' : (ROIdata*1).toFixed(0)+'%'}</div>
            }},
        { field: 'last7DaysROI', headerName: 'ROI(7)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'secondBlock', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const ROIdata = params.row["last7DaysROI"]
                return <div style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{(ROIdata === "-") ? '-' : (ROIdata*1).toFixed(0)+'%'}</div>
            }},
        { field: 'last14DaysROI', headerName: 'ROI(14)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'secondBlock', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const ROIdata = params.row["last14DaysROI"]
                return <div style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{(ROIdata === "-") ? '-' : (ROIdata*1).toFixed(0)+'%'}</div>
            }},
        { field: 'last30DaysROI', headerName: 'ROI(30)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'secondBlock', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const ROIdata = params.row["last30DaysROI"]
                return <div style={{color: (ROIdata > 0) ? '#4caf50' : '#ef5350'}}>{(ROIdata === "-") ? '-' : (ROIdata*1).toFixed(0)+'%'}</div>
            }},
        { field: 'cpc', headerName: 'CPC', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'thirdBlock thirdBlockLeftBorder', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                let cpc = Number(params.row.cpc)
                return <div>{cpc.toFixed(3)}</div>
            }},
        { field: 'cost_per_conversion', headerName: 'CPL', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'thirdBlock', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                let cpl = Number(params.row.spent) / Number(params.row.actions)
                return <div>{(isFinite(cpl)) ? cpl.toFixed(3) : '---' }</div>
            }},
    ];

    return (
        <div className="campaignCSV">
            <div className="trafficTopContainer">
                <div className="trafficTopWrapper">
                    <div className="campaignCSV-top" style={{ marginBottom: '10px' }}>
                        <div className='campaignCSV-top-upload'>
                            <p>{fileName}</p>
                            <label htmlFor="campaignCSV-upload">Choose</label>
                            <input id='campaignCSV-upload' type="file" accept=".csv" onChange={e => handleOnChange(e)}/>
                        </div>
                        <button onClick={e => handleUpload(e)}>Upload CSV</button>
                        {(displayLoadingMsg) ? <p style={{ color: '#FF0000' }}>It's uploading. Please wait till this message disappears, then reload the page.</p> : <></>}
                    </div>

                    {/* logo and date */}
                    <div className="dateWrapper" style={{ justifyContent: 'flex-start', marginBottom: '10px' }}>
                        <div className="dateWrapperDiv">
                            <label htmlFor="fromTopDate">From: </label>
                            <input id="fromTopDate" type="date" className="datePicker" value={fromDate} onChange={(e) => {setFromDate(e.target.value);}}/>
                        </div>
                        <div className="dateWrapperDiv">
                            <label htmlFor="toTopDate">To: </label>
                            <input id="toTopDate" type="date" className="datePicker" value={toDate} onChange={(e) => {setToDate(e.target.value);}}/>
                        </div>
                    </div>

                    {/* total values */}
                    <div className="totalContainer">
                        <div style={{display: 'flex'}}>
                            <div className="totalTitle">{fromDate}</div>
                            <div className="totalTitle">&nbsp;&#8596;&nbsp;</div>
                            <div className="totalTitle">{toDate}</div>
                        </div>
                        <div style={{lineHeight: '50px', fontSize: '20px', fontWeight: 700}}>Total:</div>

                        <div className="totalCardContainer">
                            <div className="totalCard">
                                <div className="totalCardContent">
                                    <p>Revenue</p>
                                    <div>${Number(totalValue.revenue).toFixed(0)}</div>
                                    <div style={{ fontSize: '12px' }}>RT${Number(totalValue.revenueTotal).toFixed(0)}</div>
                                </div>
                            </div>
                            <div className="totalCard">
                                <div className="totalCardContent">
                                    <p>Spent</p>
                                    <div>${Number(totalValue.spent).toFixed(0)}</div>
                                </div>
                            </div>
                            <div className="totalCard">
                                <div className="totalCardContent">
                                    <p>Result</p>
                                    <div style={{color: `${((totalValue.revenue - totalValue.spent) > 0) ? '#4caf50' : '#ef5350'}`}}>${(totalValue.revenue - totalValue.spent).toFixed(0)}</div>
                                </div>
                            </div>
                            <div className="totalCard">
                                <div className="totalCardContent">
                                    <p>ROI%</p>
                                    <div style={{color: `${(((totalValue.revenue / totalValue.spent-1)*100) > 0) ? '#4caf50' : '#ef5350'}`}}>{((totalValue.revenue / totalValue.spent-1)*100).toFixed(2)}%</div>
                                </div>
                            </div>
                            <div className="totalCard">
                                <div className="totalCardContent">
                                    <p>CPA</p>
                                    <div>{Number(totalValue.CPA).toFixed(3)}</div>
                                </div>
                            </div>
                            <div className="totalCard">
                                <div className="totalCardContent">
                                    <p>CPL</p>
                                    <div>{(isFinite(Number(totalValue.spent) / Number(totalValue.actions))) ? (Number(totalValue.spent) / Number(totalValue.actions)).toFixed(2) : '---' }</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Chart */}
                    <div className="chartContainer" style={{ height: '500px', flexDirection: 'column', marginBottom: '10px' }} >
                        <div className="chartWrapper">
                            <Line options={optionsROI} data={dataResult} />
                        </div>
                    </div>

                    {/* datagrid */}
                    <div className="dataGridContainer">
                        <DataGrid
                        components={{ Toolbar: GridToolbar }}
                        initialState={{
                            sorting: {
                              sortModel: [{ field: 'spent', sort: 'desc' }],
                            },
                        }}
                        sx={{
                            boxShadow: 3,
                            '& .datagridHeader': {
                                color: '#1976d2',
                            },
                            '& .firstBlock': {
                                background: 'rgba(75, 192, 192, 0.05)',
                            },
                            '& .firstBlockLeftBorder': {
                                borderLeft: '3px solid rgba(75, 192, 192, 0.3)',
                            },
                            '& .secondBlock': {
                                background: 'rgba(153, 102, 255, 0.05)',
                            },
                            '& .secondBlockLeftBorder': {
                                borderLeft: '3px solid rgba(153, 102, 255, 0.3)',
                            },
                            '& .thirdBlock': {
                                background: 'rgba(54, 162, 235, 0.05)',
                            },
                            '& .thirdBlockLeftBorder': {
                                borderLeft: '3px solid rgba(54, 162, 235, 0.3)',
                            },
                            '& .MuiDataGrid-cell': {
                                padding: '10px',
                            }
                        }}
                        loading={loadingTotal}
                        getRowHeight={() => 'auto'} 
                        // getRowId={(row) => row.id}
                        rows={totalRows} columns={totalColumns} disableSelectionOnClick={true}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CampaignCSV