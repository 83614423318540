import React from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import { host } from './host'

const updateSettingsTikTokLive = ({ info, act_id, active, setActiveLoading, tiktokLive, setTiktokLive, trafficToSend, inputValue, action, appContext }) => {
    setActiveLoading(true)

    const controller = new AbortController();
    const token = Cookies.get('token')
    const url = `${host}/api/bidding/update`;

    axios.post(url, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type, x-access-token",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
            "Authorization": "Bearer "+token,
            "Content-Type": "application/json",
        },
        data: [{id: info.adgroup_id, act_id: act_id, state: active, daily_budget: (action === 'budget') ? inputValue : info.daily_budget, user: Cookies.get('userArray'), action: action}], traffic: trafficToSend
    },
    {
        signal: controller.signal
    })

    .then((response) => {
        const obj = response.data

        if (obj.budgetResult?.message === 'OK' || obj.statusResult?.message === 'OK') {
            let newArr = [...tiktokLive.data]
            let newObj = {...info, status: (active) ? 'ENABLE' : 'DISABLE', daily_budget: (action === 'budget') ? inputValue : info.daily_budget}
            let indexCampaign = newArr.findIndex(f => f.campaign_name === info.campaign_name)
            let indexData = 0

            for (let i = 0; i < newArr.length; i++) {
                if (newArr[i].campaign_name === info.campaign_name) {
                    indexData = newArr[i].data.findIndex(f => f.adgroup_id === info.adgroup_id )
                }
            }
            
            newArr[indexCampaign].data[indexData] = newObj
            
            setTiktokLive(p => { return { ...p, data: newArr } })
        } else {
            if (action === 'status') {
                window.alert(response.data?.statusResult?.message ? response.data?.statusResult?.message : 'Failed! Please try again.')
            } else {
                window.alert(response.data?.budgetResult?.message ? response.data?.budgetResult?.message : 'Failed! Please try again.')
            }
        }

        setActiveLoading(false)
    })
    
    .catch((error) => {
        console.log(error);
        alert(error);
        setActiveLoading(false)
        if (error?.response?.data?.message === 'Invalid') {
            appContext.forceLogout()
        }
    }) 
}

export default updateSettingsTikTokLive