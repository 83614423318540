import React, { useState, useEffect, useContext } from 'react'
import './unusedDomains.css'
import axios from 'axios';
import Cookies from 'js-cookie';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { FaCheckSquare } from 'react-icons/fa'
import { BsXSquareFill } from 'react-icons/bs'

import useFetchUnusedDomains from '../../hooks/useFetchUnusedDomains'
import { host } from '../../hooks/host';
import { Context } from '../../AppContext';

const UnusedDomains = () => {
  const appContext = useContext(Context)

  const [filteredData, setFilteredData] = useState([])
  const [options, setOptions] = useState([])
  const [selectedAC, setSelectedAC] = useState('')
  const [refresh, setRefresh] = useState(false)

  const fetch = useFetchUnusedDomains({refresh})
  const [loading, data] = fetch

  useEffect(() => {
    setOptions(Array.from(new Set(data.map(e => e.account))))
  }, [data])
  
  useEffect(() => {
    let temp = [...data]
    let result = []

    if (selectedAC === '') {
      result = temp
    } else {
      result = temp.filter(f => f.account === selectedAC)
    }

    result.sort((a, b) => a.domain > b.domain ? 1 : -1 )

    setFilteredData(result)
  }, [data, selectedAC])

  const rows = filteredData

  const columns = [
    { field: '_id', headerName: 'ID', hide: true },
    { field: 'domain', headerName: 'Domain', minWidth: 300, flex: 1, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left' },
    { field: 'topic', headerName: 'Topic', minWidth: 250, flex: 1, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left',
      renderCell: (params) => {
        const domain = params.row.domain
        const topic = params.row.topic

        // returns either the topic or the input field if clicked on
        const TopicDiv = () => {
          const [displayInput, setDisplayInput] = useState(false)
          const [updateLoading, setUpdateLoading] = useState(false)
          const [text, setText] = useState('')

          useEffect(() => {
            setText(topic ? topic : domain.replace('.store', '').split('-').join(' ').toUpperCase())
          }, [])

          // update the unused domain API
          const handleUpdate = () => {
            const url = `${host}/api/unused_domains/update`;
            const token = Cookies.get('token')
        
            setUpdateLoading(true)
            axios.post(url, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
              },
              data: {...params.row, topic: text}
            })
            .then((response) => {
              setRefresh(p => !p)
              setDisplayInput(false)
              setUpdateLoading(false)
            })
            .catch((error) => {
              console.log(error);
              window.alert('Error! Please try again.\n' + error)
              setUpdateLoading(false)
              if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
              }
            })
          }

          return (
            <>
              {displayInput ? (
                <div className='ud-input'>
                  <input className='ud-input-input' type="text" value={text} onChange={e => setText(e.target.value)} onKeyDown={e => e.stopPropagation()}/>
                  <FaCheckSquare size={30} color='#1976d2' onClick={() => {(text === '') ? window.alert("Can't accept empty input.") : handleUpdate()}} style={{ cursor: 'pointer', opacity: updateLoading ? 0.3 : 1, pointerEvents: updateLoading ? 'none' : 'all' }}/>
                  <BsXSquareFill size={30} color='#EF5350' onClick={() => setDisplayInput(false)} style={{ cursor: 'pointer' }} />
                </div>
              ) : (
                <div onClick={() => setDisplayInput(true)} className='ud-topic' style={{ background: (topic) ? 'rgba(76, 175, 80, 0.2)' : 'rgba(25, 118, 210, 0.2)' }}>
                  { topic ? topic : domain.replace('.store', '').split('-').join(' ').toUpperCase() }
                </div>
              )}
            </>
          )
        }

        return (<>{TopicDiv()}</>)
      }},
    { field: 'account', headerName: 'Account', minWidth: 150, flex: 1, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left' }
  ]

  return (
    <div className="widget-container" style={{ gridAutoRows: 'min-content min-content 1fr' }}>
      <div style={{ height: 'fit-content', width: '100%', display: 'flex', alignItems: 'center', flexWrap: 'wrap', columnGap: '10px' }}>
        <p style={{ fontWeight: 700 }}>Unused Sedo Domains</p>
        <div onClick={() => setSelectedAC('')} style={{ padding: '10px', minWidth: '70px', borderRadius: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', background: (selectedAC === '') ? '#000000' : '#FFFFFF', color: (selectedAC === '') ? '#FFFFFF' : '#000000' }}>All</div>
        {options.map((e, i) => (
          <div key={i} onClick={() => setSelectedAC(e)} style={{ padding: '10px', minWidth: '70px', borderRadius: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', background: (selectedAC === e) ? '#000000' : '#FFFFFF', color: (selectedAC === e) ? '#FFFFFF' : '#000000' }}>{e}</div>
        ))}
      </div>
      <div style={{ height: 'fit-content', width: '100%', display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '10px' }}>
        <p style={{ fontWeight: 700 }}>Note: </p>
        <p style={{ fontWeight: 700, background: 'rgba(76, 175, 80, 0.2)', padding: '10px' }}>Manual Input</p>
        <p style={{ fontWeight: 700, background: 'rgba(25, 118, 210, 0.2)', padding: '10px' }}>Generated</p>
      </div>
      <div className="widget-datagrid-container">
        <DataGrid
        components={{ Toolbar: GridToolbar }}
        sx={{
          boxShadow: 3,
          '& .datagridHeader': {
            color: '#1976d2',
          }
        }}
        loading={loading}
        getRowHeight={() => 'auto'}
        getRowId={(row) => row._id}
        rows={rows} columns={columns} disableSelectionOnClick={true}/>
      </div>
    </div>
  )
}

export default UnusedDomains