import { useState, useEffect, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import { host } from './host'
import { Context } from "../AppContext";

const useFetchFFPPostsCreation = ({ global, refresh, setLoading, uploadFbImage }) => {
    const appContext = useContext(Context)
    
    const [data, setData] = useState({
        loading: { fb_images: false },
        widget: [],
        fb: [],
        fb_images: {},
        fb_videos: {},
        fb_fan_pages_post: [],
        fb_fan_pages_revenue: { page: [], post: [] },
        fb_token: ""
    });

    // get fb account on username change
    useEffect(() => {
        const token = Cookies.get("token");
        
        const url_facebook_accounts = `${host}/api/index/ids`;
        const url_fan_page_accounts = `${host}/api/facebook_page/accounts`;

        // API to fetch fan page accounts id and token
        const fetchFbFanPageAccounts = () => {
            axios.post(url_fan_page_accounts, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                        "Authorization": "Bearer "+token,
                        "Content-Type": "application/json",
                    },
                },
            )

            .then((response) => {
                const obj = response.data
                const fan_page_result = obj.result ? obj.result : []
                fan_page_result.sort((a, b) => {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                });
                setData(p => { return { ...p, fb_fan_pages_post: fan_page_result, fb_token: obj.token } })

                if (fan_page_result.length === 0) {
                    appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Warning", message: [{ type: "Warning", title: "Empty fan page tokens", content: "Fan page tokens are crucial if you want to work with widgets with comments. If not, you can ignore this message for now and reload the page when you want to work with comments later on." }], confirm_function: () => {}, cancel_function: () => {} }})
                }
            })
            
            .catch((error) => {
                console.log(error);
                setData(p => { return { ...p, fb_fan_pages_post: [] } })
                if (error?.response?.data?.message === 'Invalid') {
                    appContext.forceLogout()
                }
            });
        };

        // API to fetch facebook accounts
        const fetchFbAccounts = () => {
            axios.post(
                url_facebook_accounts,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                        "Authorization": "Bearer "+token,
                        "Content-Type": "application/json",
                    },
                    username: 'Southpow'
                }
            )

            .then((response) => {
                const obj = response.data;
                
                if (obj.result) {
                    let temp = obj.result
                    temp = temp.map(e => { return { ...e, unique: e.id + "~" + e.client } })
                    setData(p => {return{...p, fb: temp.sort((a, b) => a.name > b.name ? 1 : -1 )}})
                } else (
                    console.log('No Data!')
                )
            })

            .catch((error) => {
                console.log(error);
                    if (error?.response?.data?.message === 'Invalid') {
                    appContext.forceLogout()
                }
            });
        };

        fetchFbAccounts()
        fetchFbFanPageAccounts()
    }, [])


    // fetch facebook images and videos
    useEffect(() => {
        if ((data.fb_fan_pages_post.length === 0) || (data.fb.length === 0)) {
            setData(p => { return { ...p, fb_images: {}, fb_videos: {} } })
            return
        }

        const controller = new AbortController();
        const token = Cookies.get("token");
        const url_fb_images = `${host}/api/create_campaign/adimages`;
        const fb_ac_ids = Array.from(new Set(data.fb.map(e => e.id)))

        const getAllFbImageData = async () => {
            setData(p => { return { ...p, loading: { ...p.loading, fb_images: true } } })
            let images_result = {}
            let videos_result = {}

            const wait_promise = await Promise.all(fb_ac_ids.map(async (id) => {
                try {
                    const response = await axios.post(url_fb_images, {
                            headers: {
                                "Access-Control-Allow-Origin": "*",
                                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                                "Authorization": "Bearer "+token,
                                "Content-Type": "application/json",
                            },
                            account_id: id
                        },
                        {
                            signal: controller.signal
                        }
                    )

                    const obj = response.data
                    
                    if (obj.adImages && obj.adVideos) {
                        if (obj.adImages.error || obj.adVideos.error) {
                            images_result[id] = []
                            videos_result[id] = []
                        } else {
                            if (obj.adImages.data) {
                                images_result[id] = obj.adImages.data
                            }
                            if (obj.adVideos.data) {
                                videos_result[id] = obj.adVideos.data
                            }
                        }
                    } else {
                        images_result[id] = []
                        videos_result[id] = []
                    }
                } catch (error) {
                    console.log(error)
                    images_result[id] = []
                    videos_result[id] = []
                }
            }))

            setData(p => { return { ...p, loading: { ...p.loading, fb_images: false }, fb_images: images_result, fb_videos: videos_result } })
        }

        const getSingleFbImageData = async () => {
            setData(p => { return { ...p, loading: { ...p.loading, fb_images: true } } })
            let images_result = { ...data.fb_images }
            let videos_result = { ...data.fb_videos }

            try {
                const response = await axios.post(url_fb_images, {
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                            "Authorization": "Bearer "+token,
                            "Content-Type": "application/json",
                        },
                        account_id: uploadFbImage.act_id
                    },
                    {
                        signal: controller.signal
                    }
                )

                const obj = response.data
                console.log(obj)

                if (obj.adImages && obj.adVideos) {
                    if (obj.adImages.error || obj.adVideos.error) {
                        images_result[uploadFbImage.act_id] = []
                        videos_result[uploadFbImage.act_id] = []
                    } else {
                        if (obj.adImages.data) {
                            images_result[uploadFbImage.act_id] = obj.adImages.data
                        }
                        if (obj.adVideos.data) {
                            videos_result[uploadFbImage.act_id] = obj.adVideos.data
                        }
                    }
                } else {
                    images_result[uploadFbImage.act_id] = []
                    videos_result[uploadFbImage.act_id] = []
                }
            } catch (error) {
                console.log(error)
                images_result[uploadFbImage.act_id] = []
                videos_result[uploadFbImage.act_id] = []
            }

            setData(p => { return { ...p, loading: { ...p.loading, fb_images: false }, fb_images: images_result, fb_videos: videos_result } })
        }

        if (Object.keys(data.fb_images).length !== fb_ac_ids.length) {
            getAllFbImageData()
        } else {
            getSingleFbImageData()
        }

        return () => {
            controller.abort()
        };
    }, [uploadFbImage.refresh, data.fb_fan_pages_post, data.fb]);




    
    // fetch widgets
    useEffect(() => {
        const controller = new AbortController();
        const token = Cookies.get("token");
        const url = `${host}/api/index/search`;

        const getData = () => {
            setLoading(p => { return { ...p, widget: true } })
            axios.post(url, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                        "Authorization": "Bearer "+token,
                        "Content-Type": "application/json",
                    },
                    field: 'case',
                    keyword: global.widget_case
                },
                {
                    signal: controller.signal
                }
            )

            .then((response) => {
                const obj = response.data;
                console.log(obj);

                if (obj?.result?.length > 0) {
                    setData(p => { return { ...p, widget: obj?.result?.sort((a, b) => b?.widget - a?.widget) } })
                } else (
                    setData(p => { return { ...p, widget: [] } })
                )
            })

            .catch((error) => {
                console.log(error);
                if (error?.response?.data?.message === 'Invalid') {
                    appContext.forceLogout()
                }
            })
            
            .finally(() => setLoading(p => { return { ...p, widget: false } }))
        };

        getData();

        return () => {
            controller.abort()
        };
    }, [global.widget_case, refresh.widget]);





    return [data]
};

export default useFetchFFPPostsCreation