import React from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import { host } from './host'

const updateSettingsTikTokLiveRule = ({ info, setActiveLoading, tiktokLive, setTiktokLive, trafficToSend, appContext }) => {
    setActiveLoading(true)

    const token = Cookies.get('token')
    const url = `${host}/api/bidding/update/max_value`;

    axios.post(url, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type, x-access-token",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
            "Authorization": "Bearer "+token,
            "Content-Type": "application/json",
        },
        data: info, traffic: trafficToSend
    })

    .then((response) => {
        const obj = response.data

        if (obj.result && Array.isArray(obj.result) && obj.result.length > 0) {
            if (response.data.result[0].success === true) {
                let newArr = [...tiktokLive.data]
                let indexCampaign = newArr.findIndex(f => f.campaign_name === info[0].campaign_name)
                let indexData = 0
    
                for (let i = 0; i < newArr.length; i++) {
                    if (newArr[i].campaign_name === info[0].campaign_name) {
                        indexData = newArr[i].data.findIndex(f => f.adgroup_id === info[0].adgroup_id )
                    }
                }
                
                newArr[indexCampaign].data[indexData] = info[0]
                
                setTiktokLive(p => { return { ...p, data: newArr } })
            } else {
                window.alert('Unsuccessful! Please refresh the page to make sure!')
            }
        } else {
            window.alert('Unsuccessful! Please refresh the page to make sure!')
        }

        setActiveLoading(false)
    })
    
    .catch((error) => {
        console.log(error)
        setActiveLoading(false)
        if (error?.response?.data?.message === 'Invalid') {
            appContext.forceLogout()
        }
    }) 
}

export default updateSettingsTikTokLiveRule