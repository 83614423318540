import React from "react";
import Traffic from '../reusable/traffic/Traffic'
import system1Logo from '../../assets/system1Logo.svg'

const System1Facebook = () => {
    return(
        <Traffic trafficName='system1_facebook' trafficToSend='Facebook' clientName='System1' logo={system1Logo} minusDate={2} />
    )
}

export default System1Facebook