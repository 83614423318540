import React, { useContext } from 'react'

import AdminPage from '../admin/AdminPage'
import Home from '../dashboard/Home'
import { Context } from '../../AppContext'

const MainHome = () => {
    const appContext = useContext(Context)

    return (
        <>
            {(appContext.role === 'admin') ? (
                <AdminPage />
            ) : (
                <Home />
            )}
        </>
    )
}

export default MainHome