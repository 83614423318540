import React, { useContext, useState } from "react";
import './sum.css'
import '../traffic/traffic.css'
import useFetchTotal from "../../../hooks/useFetchTotal";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment/moment";
import Cookies from "js-cookie";
import { Context } from "../../../AppContext";

const Sum = ({clientName, logo, minusDate}) => {
    const appContext = useContext(Context)

    const [fromDate, setFromDate] = useState(moment().utc().add(8, 'hours').subtract(minusDate + 6, 'days').format('YYYY-MM-DD'))
    const [toDate, setToDate] = useState(moment().utc().add(8, 'hours').subtract(minusDate, 'days').format('YYYY-MM-DD'))
    
    const username = Cookies.get('userArray')

    const fetch = useFetchTotal({client: clientName, fromDate, toDate})
    const loading = fetch[0]
    const data = fetch[1]

    const rows = data

    const columns = [
        // { field: 'id', headerName: 'ID', hide: true},
        { field: 'date', headerName: 'Date', minWidth: 130, flex: 2, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left',},
        { field: 'revenue', headerName: 'Revenue', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                let revenue = Number(params.row.revenue)
                return(<div>$ {revenue.toFixed(2)}</div>)
            }},
        { field: 'spent', headerName: 'Spent', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                let spent = Number(params.row.spent)
                return(<div>$ {spent.toFixed(2)}</div>)
            }},
        { field: 'result', headerName: 'Result', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const resultData = Number(params.row.result)
                let result
                if (resultData > 0) {
                    result = <div style={{color: '#28cd41'}}>$ {resultData.toFixed(2)}</div>
                } else {
                    result = <div style={{color: '#ff3b30'}}>$ {resultData.toFixed(2)}</div>
                }

                return(result)
            }},
        { field: 'ROI%', headerName: 'ROI (%)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const ROIdata = Number(params.row["ROI%"])
                let roi
                if (ROIdata > 0) {
                    roi = <div style={{color: '#28cd41'}}>{ROIdata.toFixed(2)} %</div>
                } else {
                    roi = <div style={{color: '#ff3b30'}}>{ROIdata.toFixed(2)} %</div>
                }

                return(roi)
            }},
        { field: 'epv', headerName: 'EPV', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', type: 'number', headerAlign: 'left', align: 'left', },
        { field: 'cpc', headerName: 'CPC', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', type: 'number', headerAlign: 'left', align: 'left', },
        { field: 'CPA', headerName: 'CPA', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left', },
        { field: 'cost_per_conversion', headerName: 'CPL', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                let cpl = Number(params.row.spent) / Number(params.row.actions)
                return(<div>{(isFinite(cpl)) ? cpl.toFixed(2) : '---' }</div>)
            }},
        { field: 'actions', headerName: 'Conv', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left', },
        { field: 'clicks', headerName: 'ConvC', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', type: 'number', headerAlign: 'left', align: 'left', },
        { field: 'link_clicks', headerName: 'LinkC', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', type: 'number', headerAlign: 'left', align: 'left', },
        { field: 'visitors', headerName: 'Visitors', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', type: 'number', headerAlign: 'left', align: 'left', },
    ]

    return(
        <div className="sumContainer" >
            <div className="sumWrapper">
                <div className="sumContentContainer">
                    {/* logo and date */}
                    <div className="logoDateContainer">
                        <div className="logoWrapper">
                            <img src={logo} alt="LOGO"/>
                        </div>

                        <div className="dateWrapper">
                            <div className="dateWrapperDiv">
                                <label htmlFor="fromTopDate">From: </label>
                                <input id="fromTopDate" type="date" className="datePicker" value={fromDate} onChange={(e) => {e.preventDefault(); setFromDate(e.target.value);}}/>
                            </div>
                            <div className="dateWrapperDiv">
                                <label htmlFor="toTopDate">To: </label>
                                <input id="toTopDate" type="date" className="datePicker" value={toDate} onChange={(e) => {e.preventDefault(); setToDate(e.target.value);}}/>
                            </div>
                        </div>
                    </div>

                    {(appContext.role === 'admin') && (<div className="totalTitle">Username: {(username.toString()).replaceAll(",", " - ")}</div>)}

                    <div style={{display: 'flex', marginBottom: '10px'}}>
                        <div className="totalTitle">{fromDate}</div>
                        <div className="totalTitle">&nbsp;&#8596;&nbsp;</div>
                        <div className="totalTitle">{toDate}</div>
                    </div>

                    {/* datagrid */}
                    <div className="sumDataGridContainer">
                        <DataGrid
                        components={{ Toolbar: GridToolbar }}
                        initialState={{
                        sorting: {
                            sortModel: [{ field: 'id', sort: 'asc' }],
                        },
                        }}
                        sx={{
                        boxShadow: 3,
                        '& .datagridHeader': {
                            color: '#1976d2'
                        },
                        }}
                        loading={loading}
                        getRowId={(row) => row.date}
                        rows={rows} columns={columns} disableSelectionOnClick={true} pageSize={10}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sum