import React from "react";
import Traffic from '../reusable/traffic/Traffic'
import adsLogo from '../../assets/adsLogo.png'

const AdsFacebook = () => {
    return (
        <Traffic trafficName='ads_facebook' trafficToSend='Facebook' clientName='Ads' logo={adsLogo} minusDate={2} />
    )
}

export default AdsFacebook