import { useState, useEffect, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import { host } from './host'
import { Context } from "../AppContext";

const useFetchFFPData = ({ global, setLoading }) => {
    const appContext = useContext(Context)
    
    const [data, setData] = useState({ fb_fan_pages_details: [], fb_fan_pages_revenue: { page: [], post: [] } });

    // get fb account on username change
    useEffect(() => {
        const token = Cookies.get("token");
        const url_fan_page_details = `${host}/api/facebook_page`;

        // API to fetch facebook fan pages details
        const fetchFbFanPagesDetailsAPI = () => {
            setLoading(p => { return { ...p, fan_page: true } })

            axios.post(url_fan_page_details, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                    "Authorization": "Bearer "+token,
                    "Content-Type": "application/json",
                },
            })
            
            .then((response) => {
                const obj = response.data
                console.log(response)

                setData(p => {
                    return {
                        ...p,
                        fb_fan_pages_details: obj.result ? (
                            obj?.result?.sort((a, b) => {
                                if (a?.name < b?.name) {
                                    return -1;
                                }
                                if (a?.name > b?.name) {
                                    return 1;
                                }
                                return 0;
                            })
                        ) : []
                    }
                })
            })
            
            .catch((error) => {
                console.log(error);
                setData(p => { return { ...p, fb_fan_pages_details: [] } })
                if (error?.response?.data?.message === 'Invalid') {
                    appContext.forceLogout()
                }
            }) 

            .finally(() => setLoading(p => { return { ...p, fan_page: false } }))
        }

        fetchFbFanPagesDetailsAPI()
    }, [])



    // get fan page revenue details
    useEffect(() => {
        const controller = new AbortController();
        const token = Cookies.get("token");
        const url = `${host}/api/data`;

        const fetchFanPageRevenue = () => {
            setLoading(p => { return { ...p, fan_page_revenue: true } })
            axios.post(url, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                        "Authorization": "Bearer "+token,
                        "Content-Type": "application/json",
                    },
                    data: {
                        traffic: 'page_facebook',
                        trafficName: 'Facebook',
                        from: global.fan_page_from,
                        to: global.fan_page_to,
                        lastDate: global.fan_page_to,
                        username: 'Southpow',
                        account_id: 'all',
                    },

                },
            )
        
            .then((response) => {
                const obj = response.data;
                console.log(response)
                
                if (obj.data) {
                    setData(p => {
                        return {
                            ...p,
                            fb_fan_pages_revenue: {
                                page: obj?.data?.sort((a, b) => moment(b?.date).diff(moment(a?.date))),
                                post: obj?.post_result?.sort((a, b) => moment(b?.date).diff(moment(a?.date)))
                            }
                        }
                    })
                } else {
                    setData(p => { return { ...p, fb_fan_pages_revenue: { page: [], post: [] } } })
                }
            })
        
            .catch((error) => {
                console.log(error);
                if (error?.response?.data?.message === 'Invalid') {
                    appContext.forceLogout()
                }
            })

            .finally(() => {
                setLoading(p => { return { ...p, fan_page_revenue: false } })
            })
        };
    
        fetchFanPageRevenue()

        return () => {
            controller.abort()
        }
    }, [global.fan_page_from, global.fan_page_to])



    return [data]
};

export default useFetchFFPData