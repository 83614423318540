import React, { useEffect, useState } from "react";
import './stock.css'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from "react-chartjs-2";
import chartTrendline from "chartjs-plugin-trendline"
import ZoomPlugin from 'chartjs-plugin-zoom'
import { FaCheck } from 'react-icons/fa'
import { BiSelectMultiple, BiSearchAlt } from 'react-icons/bi'
import { RxCross1 } from 'react-icons/rx'
import useFetchStock from "../../hooks/useFetchStock";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ZoomPlugin, chartTrendline);

const Stock = () => {
    const [stock, setStock] = useState([])
    const [searchInput, setSearchInput] = useState('')
    const [triggerChartDataFetch, setTriggerChartDataFetch] = useState(false)
    const [displayStockList, setDisplayStockList] = useState(false)
    const [selectedLines, setSelectedLines] = useState([])
    const [optionsChart, setOptionsChart] = useState({
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: 'point',
            axis: 'x',
            intersect: false,
        },
        plugins: {
            legend: {
                position: 'top',
                display: false
            },
            title: {
                display: true,
                text: 'Stock CPC Chart',
            },
            tooltip: {
                callbacks: {
                    title: (xDatapoint) => {
                        return xDatapoint?.[0]?.label + " " + xDatapoint?.[0]?.dataset?.traffic_name;
                    },
                },
            },
            zoom: {
                limits: {
                    // x: {min: 0, max: 2, minRange: 0.05},
                    y: {min: 0, max: 2, minRange: 0.05}
                },
                pan: {
                    enabled: true,
                    mode: 'y',
                    // mode: 'xy',
                },
                zoom: {
                    wheel: {
                        enabled: true,
                        speed: 0.05,
                    },
                    pinch: {
                        enabled: true
                    },
                    mode: 'y',
                    // mode: 'xy',
                    onZoomComplete({chart}) {
                        // This update is needed to display up to date zoom level in the title.
                        // Without this, previous zoom level is displayed.
                        // The reason is: title uses the same beforeUpdate hook, and is evaluated before zoom.
                        chart.update('none');
                    }
                }
            },
        },
        onClick: (event, elements) => {
            if (elements.length > 0) {
                let temp_labels = event.chart.tooltip.dataPoints.map(e => e.dataset.label)
                setSelectedLines(temp_labels)
            } else {
                setSelectedLines([])
            }
        },
        scales: {
            x: {
                display: true,
                grid: {
                    display: true
                },
                ticks: {
                    autoSkip: true,
                }
            },
            y: {
                display: true,
                grid: {
                    display: true
                },
                max: 2,
                min: 0
            }
        }
    })

    const fetch = useFetchStock({ stock, triggerChartDataFetch })
    const [ loading, stockList, stockChart, stockChartDates ] = fetch

    // filtering out the datasets to be displayed in the chart
    const filterDisplayStock = () => {
        let result = []

        stock?.forEach(ele => {
            const find_stock = stockChart?.find(f => (f.stock === ele.stock) && (f.traffic === ele.traffic))
            let traffic_name = ''
            
            if (find_stock?.traffic === 'tonic_stock') {
                traffic_name = 'Tonic'
            } else if (find_stock?.traffic === 'sedo_stock') {
                traffic_name = 'Sedo'
            } else if (find_stock?.traffic === 'crossroads_stock') {
                traffic_name = 'Crossroads'
            }

            if (find_stock) {
                result.push(
                    {
                        type: 'line',
                        // label: e?.stock?.split(' ')?.map(e => e?.[0]?.toUpperCase())?.join(''),
                        traffic_name: traffic_name,
                        label: find_stock?.stock,
                        data: find_stock?.data?.map(e => e?.cpc),
                        // borderColor: find_stock?.color + ( (hoveredLines.find(f => f === find_stock.stock)) ? ", 1)" : ", 1)" ),
                        // backgroundColor: find_stock?.color + ( (hoveredLines.find(f => f === find_stock.stock)) ? ", 1)" : ", 1)" ),
                        borderColor: find_stock?.color,
                        backgroundColor: find_stock?.color,
                        borderDash: [3, 3],
                        borderWidth: 2,
                        pointRadius: 1,
                        pointHoverRadius: 4,
                        hidden: (selectedLines.length > 0 && !selectedLines.find(f => f === find_stock.stock)) ? true : false,
                        trendlineLinear: (find_stock?.data?.map(e => e?.cpc)?.length > 1) && {
                            colorMin: find_stock?.color,
                            colorMax: find_stock?.color,
                            lineStyle: "solid",
                            width: 2,
                        }
                    }
                )
            }
        })

        return result
    }

    // chart js data sets
    const dataChart = {
        labels: stockChartDates,
        datasets: ((stockChart.length > 0) && (stock.length > 0)) ? filterDisplayStock() : []
    };

    // filter stock list by traffic and sort
    const getFilteredStock = (traffic) => {
        const filteredStockList = stockList.filter(f => f?.stock?.toLowerCase()?.includes(searchInput.toLowerCase()))

        let result = filteredStockList.filter(f => f?.traffic === traffic)
        result?.sort((a, b) => a?.stock.localeCompare(b?.stock))

        return result ? result : []
    }

    // add or delete from stock state
    const handleModifyList = (e) => {
        let temp_stock = [...stock]
        const check = temp_stock.find(f => (f.stock === e.stock) && (f.traffic === e.traffic))

        if (check) {
            temp_stock = temp_stock.filter(f => (f.stock !== e.stock) || (f.traffic !== e.traffic))
        } else {
            temp_stock.push(e)
        }

        setStock(temp_stock)
    }

    // add or delete from stock state as a whole
    const handleSelectAll = ({ traffic, select_all }) => {
        let temp_stock = [...stock]
        const filteredStockList = stockList.filter(f => f?.stock?.toLowerCase()?.includes(searchInput.toLowerCase()))

        if (traffic === 'all') {
            temp_stock = filteredStockList
        } else {
            if (select_all === true) {
                temp_stock = temp_stock.filter(f => f?.traffic !== traffic)
                filteredStockList.map(e => {
                    if (e?.traffic === traffic) {
                        temp_stock.push(e)
                    }
                })
            } else {
                temp_stock = temp_stock.filter(f => f?.traffic !== traffic)
            }
        }

        setStock(temp_stock)
    }

    return (
        <div className="trafficContainer stock-scrollbar">
            <div style={{ height: '100%', minHeight: 'fit-content', width: '100%', display: 'flex', flexDirection: 'column', padding: '10px' }}>
                {/* search */}
                <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', marginBottom: '5px' }}>
                        <p style={{ marginBottom: '0px', fontSize: '20px', fontWeight: '600' }}>Select Stock:</p>
                    </div>
                    
                    <div className="stock-list-container" style={{ overflow: displayStockList ? 'visible' : 'hidden', cursor: (loading.list || loading.chart) ? 'default' : 'pointer', opacity: (loading.list || loading.chart) ? 0.5 : 1, pointerEvents: (loading.list || loading.chart) ? 'none' : 'all' }}>
                        <div className="stock-list-container-search">
                            <div><BiSearchAlt size={24} color="#AAA"/></div>
                            <input type="text" placeholder="Search List" value={searchInput} onChange={e => { setSearchInput(e.target.value); setDisplayStockList(true); }} onClick={() => setDisplayStockList(!displayStockList)} />
                        </div>

                        <div className="stock-list" style={{ height: displayStockList ? 'fit-content' : '0px', width: displayStockList ? 'fit-content' : '0px' }}>
                            <div className="stock-list-top">
                                <div>
                                    <div onClick={() => handleSelectAll({ traffic: 'all', select_all: true })} style={{ background: '#4caf50' }}>Select Everything</div>
                                </div>
                                <div>
                                    <div onClick={() => setStock([])} style={{ background: '#FF0000' }}>Unselect Everything</div>
                                </div>
                            </div>

                            <div className="stock-list-traffic-container">
                                <div className="stock-list-traffic">
                                    <div className="stock-list-traffic-sticky">
                                        <p style={{ color: 'rgb(75, 192, 192)' }}>Tonic</p>
                                        <div>
                                            <div onClick={() => handleSelectAll({ traffic: 'tonic_stock', select_all: true })} style={{ background: '#4caf50' }}><BiSelectMultiple size={16} color="#FFF"/></div>
                                            <div onClick={() => handleSelectAll({ traffic: 'tonic_stock', select_all: false })} style={{ background: '#FF0000' }}><RxCross1 size={16} color="#FFF"/></div>
                                        </div>
                                    </div>
                                    
                                    {getFilteredStock('tonic_stock').map((e, i) => {
                                        const check = stock.find(f => (f.stock === e.stock) && (f.traffic === e.traffic))
                                        return (
                                            <div className="stock-list-traffic-item" key={i} onClick={() => handleModifyList(e)} style={{ background: check ? 'rgba(75, 192, 192, 0.5)' : 'rgba(75, 192, 192, 0.1)' }}>{e?.stock}</div>
                                        )
                                    })}
                                </div>

                                <div className="stock-list-traffic">
                                    <div className="stock-list-traffic-sticky">
                                        <p style={{ color: 'rgb(153, 102, 255)' }}>Sedo</p>
                                        <div>
                                            <div onClick={() => handleSelectAll({ traffic: 'sedo_stock', select_all: true })} style={{ background: '#4caf50' }}><BiSelectMultiple size={16} color="#FFF"/></div>
                                            <div onClick={() => handleSelectAll({ traffic: 'sedo_stock', select_all: false })} style={{ background: '#FF0000' }}><RxCross1 size={16} color="#FFF"/></div>
                                        </div>
                                    </div>
                                    {getFilteredStock('sedo_stock').map((e, i) => {
                                        const check = stock.find(f => (f.stock === e.stock) && (f.traffic === e.traffic))
                                        return (
                                            <div className="stock-list-traffic-item" key={i} onClick={() => handleModifyList(e)} style={{ background: check ? 'rgba(153, 102, 255, 0.5)' : 'rgba(153, 102, 255, 0.1)' }}>{e?.stock}</div>
                                        )
                                    })}
                                </div>

                                <div className="stock-list-traffic">
                                    <div className="stock-list-traffic-sticky">
                                        <p style={{ color: 'rgb(255, 205, 86)' }}>Crossroads</p>
                                        <div>
                                            <div onClick={() => handleSelectAll({ traffic: 'crossroads_stock', select_all: true })} style={{ background: '#4caf50' }}><BiSelectMultiple size={16} color="#FFF"/></div>
                                            <div onClick={() => handleSelectAll({ traffic: 'crossroads_stock', select_all: false })} style={{ background: '#FF0000' }}><RxCross1 size={16} color="#FFF"/></div>
                                        </div>
                                    </div>
                                    {getFilteredStock('crossroads_stock').map((e, i) => {
                                        const check = stock.find(f => (f.stock === e.stock) && (f.traffic === e.traffic))
                                        return (
                                            <div className="stock-list-traffic-item" key={i} onClick={() => handleModifyList(e)} style={{ background: check ? 'rgba(255, 205, 86, 0.5)' : 'rgba(255, 205, 86, 0.1)' }}>{e?.stock}</div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="stock-button" title="Fetch Chart Data" onClick={() => { setTriggerChartDataFetch(!triggerChartDataFetch); setDisplayStockList(false); }} style={{ background: '#4caf50' }}>
                        <FaCheck size={36} color="#FFF"/>
                    </div>
                </div>

                {/* Chart */}
                <div className="chartContainer" style={{ flex: 1, minHeight: '500px', flexDirection: 'column', marginBottom: '10px' }} >
                    <div className="chartWrapper" style={{ position: 'relative' }}>
                        {(loading.list || loading.chart) ? (
                            <div style={{ position: 'absolute', height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(255,255,255,0.6)', gap: '10px' }}>
                                <div className="loader" style={{ height: '40px', width: '40px', borderRadius: '100%', border: '5px solid #1976d2', borderTop: '5px solid #E8F1FB' }}/>
                                <p>Loading</p>
                            </div>
                        ) : (<></>)}
                        <Line options={optionsChart} data={dataChart} />
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default Stock