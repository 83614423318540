import React, { useState, useEffect } from "react";
import '../traffic/traffic.css'
import '../sum/sum.css'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Cookies from "js-cookie";
import { FaChevronDown } from 'react-icons/fa'

import useFetchEmailoctopusEmails from "../../../hooks/useFetchEmailoctopusEmails";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const EmailOctopus = ({trafficName, trafficToSend, clientName, logo, minusDate}) => {
    const navigate = useNavigate()
    const [fromDate, setFromDate] = useState(moment().utc().add(8, 'hours').subtract(1, 'month').format('YYYY-MM-DD'))
    const [toDate, setToDate] = useState(moment().utc().add(8, 'hours').format('YYYY-MM-DD'))
    const [filteredMainGroup, setFilteredMainGroup] = useState([])
    const [selectedDate, setSelectedDate] = useState('')
    const [selectedDateRange, setSelectedDateRange] = useState([])
    const [calculation, setCalculation] = useState({ RPME: 0, RPC: 0 })
    const [chartPoints, setChartPoints] = useState({ revenue: [], avgRevenue: [], emails: [], rpc: [] })
    
    const username = Cookies.get('userArray')

    const fetchTraffic = useFetchEmailoctopusEmails({ traffic: trafficName, trafficToSend: trafficToSend, clientName, minusDate })
    const [ loading, data, totalValue ] = fetchTraffic

    useEffect(() => {
        if (username !== 'Southpow') {
            navigate('/')
        }
    }, [username])

    useEffect(() => {
        const from = fromDate.split('-');
        const to = toDate.split('-');
        let datesArray = []

        const dates = getDatesInRange(new Date(from[0], from[1]-1, from[2]), new Date(to[0], to[1]-1, to[2]))
        
        dates.forEach(function (date) {
            datesArray.push(moment(date).format('YYYY-MM-DD'))
        })

        let filterByDate = data.mainGroup.filter(f => datesArray.find(fin => fin === f.date))

        setSelectedDateRange(dates.map(e => moment(e).format('YYYY-MM-DD')))
        setFilteredMainGroup(filterByDate);
    }, [data, fromDate, toDate])

    useEffect(() => {
        setCalculation({
            RPME: (totalValue?.revenue?.all && totalValue?.recipientsTotal) ? ( Number(totalValue?.revenue?.all) / Number(totalValue?.recipientsTotal) )*1000 : 0,
            RPC: (totalValue?.revenue?.all && totalValue?.total_clicksTotal) ? ( Number(totalValue?.revenue?.all) / Number(totalValue?.total_clicksTotal) ) : 0
        })
    }, [data])

    // get dates in between function
    function getDatesInRange(startDate, endDate) {
        const date = new Date(startDate.getTime());
    
        const dates = [];
    
        while (date <= endDate) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }
    
        return dates;
    }

    // chart js options settings
    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false
        },
        plugins : {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Daily Email Graph',
            },
            tooltip: {
                mode: 'index'
            },
        },
        scales: {
            'revenue-y-axis': {
                type: 'linear',
                position: 'left',
                suggestedMin: 0,
                ticks: {
                    color: '#4caf50',
                }
            },
            // 'avg-revenue-y-axis': {
            //     type: 'linear',
            //     position: 'left',
            //     suggestedMin: 0,
            //     ticks: {
            //         color: '#4caf50',
            //     }
            // },
            'rpc-y-axis': {
                type: 'linear',
                position: 'right',
                suggestedMin: 0,
                ticks: {
                    color: 'rgb(33, 150, 243)',
                }
            },
            'recipients-y-axis': {
                type: 'linear',
                position: 'right',
                ticks: {
                    color: 'rgb(239, 83, 80)',
                }
            },
        }
    };
    
    // return daily average revenue
    const getAvgRevenue = (tableData) => {
        const dailyRev = [...tableData]
        var getDaysArray = function (start, end) {
            for (
                var arr = [], dt = new Date(start);
                dt <= new Date(end);
                dt.setDate(dt.getDate() + 1)
            ) {
                arr.push(moment(dt).format("YYYY-MM-DD"));
            }
            return arr;
        };
      
        for (let i = 0; i < dailyRev.length; i++) {
            if (dailyRev[i] !== undefined && dailyRev[i].date) {
                const range = getDaysArray(moment(dailyRev[i].date).subtract('7','days').format('YYYY-MM-DD'), moment(dailyRev[i].date).subtract('1','day').format(
                  "YYYY-MM-DD"
                ))
                dailyRev[i].avgRevenue = dailyRev.filter(f => range.includes(f?.date)).reduce((a, b) => a + (b?.revenue?.all ? b?.revenue?.all : 0), 0) / 7
            } else {
                dailyRev[i] = {avgRevenue: 0}
            }
        }

        return dailyRev
    }

    // calculate and set each chart points
    const finFunc = () => {
        const result = selectedDateRange.map(e => {
            const fil = data.noGroup.filter(f => f.date === e && f.revenue?.all > 0)
            const filNames = Array.from(new Set(fil.map(m => m.campaign_ID)))
            let resCalculation = filNames.map(f => fil.find(fin => fin.campaign_ID === f))?.[0]
            if (resCalculation) {
                resCalculation.chartRevenue = filNames.map(f => fil.find(fin => fin.campaign_ID === f)?.revenue?.all).reduce((a, b) => a + b, 0)
            }
            return resCalculation
        })

        const emails_sent = selectedDateRange.map(e => {
                const fil = data.noGroup.filter(f => f.date === e && f?.revenue?.all > 0 && f.date === f.complete_date)
                const filNames = Array.from(new Set(fil.map(m => m.name)))
                const resCalculation = filNames.map(f => fil.find(fin => fin.name === f)?.recipients).reduce((a, b) => a + b, 0)
                return resCalculation ? resCalculation : 0
            }
        )

        const rpc = selectedDateRange.map(e => {
                const fil = data.noGroup.filter(f => f.date === e && f?.revenue?.all > 0)
                const filCampaigns = Array.from(new Set(fil.map(m => m.campaign_ID)))
                const filNames = Array.from(new Set(fil.map(m => m.name)))
                const resCalculation = filCampaigns.map(f => fil.find(fin => fin.campaign_ID === f)?.revenue?.all).reduce((a, b) => a + b, 0) / filNames.map(f => fil.find(fin => fin.name === f)?.total_clicks).reduce((a, b) => a + b, 0)
                return resCalculation ? resCalculation : 0
            }
        )

        setChartPoints({
            avgRevenue: getAvgRevenue(result)?.map(m => m?.avgRevenue ? m?.avgRevenue : 0),
            revenue : result?.map(m => m?.revenue?.all ? m?.revenue?.all : 0),
            emails: emails_sent,
            rpc: rpc
        })
    }

    useEffect(() => {
        finFunc()
    }, [data.noGroup])

    // chart js data sets
    const chartData = {
        labels: selectedDateRange,
        datasets: [
            {
                type: 'line',
                label: 'Revenue',
                data: chartPoints.revenue,
                borderColor: '#4caf50',
                backgroundColor: '#4caf50',
                borderWidth: 4,
                yAxisID: 'revenue-y-axis'
            },
            {
                type: 'line',
                label: 'Avg Revenue',
                data: chartPoints.avgRevenue,
                borderColor: '#4caf50',
                backgroundColor: '#4caf50',
                pointRadius: 3,
                borderWidth: 2,
                borderDash: [3, 3],
                yAxisID: 'revenue-y-axis'
            },
            {
                type: 'bar',
                label: 'Emails Sent',
                data: chartPoints.emails,
                borderColor: 'rgba(239, 83, 80, 0.4)',
                backgroundColor: 'rgba(239, 83, 80, 0.1)',
                borderWidth: 1,
                yAxisID: 'recipients-y-axis'
            },
            {
                type: 'line',
                label: 'RPC',
                data: chartPoints.rpc,
                borderColor: 'rgba(33, 150, 243, 0.5)',
                backgroundColor: 'rgba(33, 150, 243, 0.5)',
                borderWidth: 2,
                pointRadius: 2,
                yAxisID: 'rpc-y-axis'
            },
        ],
    };

    // total table rows
    const totalRows = filteredMainGroup

    // total table columns
    const totalColumns = [
        { field: 'id', headerName: 'ID', hide: true },
        { field: 'date', headerName: 'Date', minWidth: 150, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'removeCellPadding', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const date = params.row.date
                const campaign_ID = params.row.campaign_ID
                return (
                    <div 
                        style={{ height: '100%', minHeight: '50px', width: '100%', display: 'flex', alignItems: 'center', cursor: (campaign_ID.length > 1) ? 'pointer' : 'default', padding: '0px 10px', color: (campaign_ID.length > 1) ? '#1976d2' : '#000', background: (selectedDate === date) ? 'rgba(0,0,0,0.05)' : 'transparent' }}
                        onMouseEnter={e => { if (campaign_ID.length > 1) { e.currentTarget.style.textDecoration = 'underline' }}}
                        onMouseLeave={e => { if (campaign_ID.length > 1) { e.currentTarget.style.textDecoration = 'none' }}}
                        onClick={() => { if (campaign_ID.length > 1) { (selectedDate === date) ? setSelectedDate('') : setSelectedDate(date) }}}
                    >
                        {date}
                    </div>
                )
            },
        },
        { field: 'name', headerName: 'Email', minWidth: 300, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'removeCellPadding', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const date = params.row.date
                const campaign_ID = params.row.campaign_ID
                const sliceNumber = (selectedDate === date) ? campaign_ID.length : 1
                return (
                    <div style={{ minHeight: '50px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0px 10px', background: (selectedDate === date) ? 'rgba(0,0,0,0.05)' : 'transparent' }}>
                        {campaign_ID.slice(0, sliceNumber).map((e, i) => {
                            const arr = data.subGroup?.[date]?.[e.campaign_ID]
                            let name = ''

                            if (Array.isArray(arr)) {
                                if (arr.length > 1) {
                                    let tempName = Array.from(new Set(arr.map(m => m.name)))
                                    let tempNameFiltered = tempName.map(a => arr.find(far => far.name === a)).filter(f => f.complete_date === f.date).map(m => m.name)
                                    name = (tempNameFiltered.length > 0) ? tempNameFiltered : ''
                                } else {
                                    if (arr[0]?.complete_date === arr[0]?.date) {
                                        name = arr[0]?.name
                                    }
                                }
                            } else {
                                name = ''
                            }

                            return (
                                <div style={{ minHeight: '50px', width: '100%', display: 'flex', alignItems: 'center', borderBottom: (selectedDate === date) ? '1px solid #CCC' : 'none' }} key={i}>
                                    <p style={{ width: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: '14px' }} onMouseEnter={e => {e.currentTarget.style.whiteSpace = 'normal'}} onMouseLeave={e => {e.currentTarget.style.whiteSpace = 'nowrap'}} >{name ? ( Array.isArray(name) ? name.join(' || ') : name ) : '-'}</p>
                                </div>
                            )
                        })}
                    </div>
                )
            },
        },
        { field: 'campaign_ID', headerName: 'Campaign ID', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'removeCellPadding', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const date = params.row.date
                const campaign_ID = params.row.campaign_ID
                const sliceNumber = (selectedDate === date) ? campaign_ID.length : 1
                return (
                    <div style={{ minHeight: '50px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0px 10px', background: (selectedDate === date) ? 'rgba(0,0,0,0.05)' : 'transparent' }}>
                        {campaign_ID.slice(0, sliceNumber).map((e, i) => {
                            if (e?.campaign_ID?.id) {
                                const temp_arr = Object.keys(e?.campaign_ID?.id)
                                let final_arr = []

                                for (let i = 0; i < temp_arr.length; i++) {
                                    if (e?.campaign_ID?.id?.[temp_arr[i]]) {
                                        final_arr.push({ client: temp_arr[i], id: e?.campaign_ID?.id?.[temp_arr[i]] })
                                    }
                                }
    
                                return (
                                    <div style={{ position: 'relative', height: '50px', width: '100%', display: 'flex', alignItems: 'center', borderBottom: (selectedDate === date) ? '1px solid #CCC' : 'none' }} key={i} onMouseEnter={e => e.currentTarget.lastChild.style.display = 'flex'} onMouseLeave={e => e.currentTarget.lastChild.style.display = 'none'}>
                                        <p style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }}>
                                            {(final_arr.length > 0) ? (
                                                (final_arr.length === 1) ? (
                                                    final_arr[0]?.id
                                                ) : (
                                                    final_arr.length + ' cam...'
                                                )
                                            ) : ''}
                                            &nbsp;{(final_arr.length > 1) ? <FaChevronDown size={12} color="#000"/> : ''}
                                        </p>
                                        <div style={{ position: 'absolute', zIndex: 10, top: '100%', background: 'white', display: 'none', flexDirection: 'column', alignItems: 'start', gap: '5px', padding: '10px', borderRadius: '10px', boxShadow: '0px 2px 4px rgba(0,0,0,0.2)' }}>
                                            {(final_arr.length > 0) ? (
                                                final_arr.map((m, mi)=> (
                                                    <p style={{ whiteSpace: 'nowrap' }} key={mi}>{m.client}: {m.id}</p>
                                                ))
                                            ) : (<></>)}
                                        </div>
                                    </div>
                                )
                            } else {
                                return (<></>) 
                            }
                        })}
                    </div>
                )
            }
        },
        { field: 'revenue', headerName: 'Revenue', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'firstBlock firstBlockLeftBorder removeCellPadding', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const date = params.row.date
                const campaign_ID = params.row.campaign_ID
                const sliceNumber = (selectedDate === date) ? campaign_ID.length : 1
                return (
                    <div style={{ minHeight: '50px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0px 10px', background: (selectedDate === date) ? 'rgba(0,0,0,0.05)' : 'transparent' }} >
                        {campaign_ID.slice(0, sliceNumber).map((e, i) => {
                            if (e?.revenue) {
                                const temp_arr = Object.keys(e?.revenue)
                                let semi_final_arr = []

                                for (let i = 0; i < temp_arr.length; i++) {
                                    if (e?.revenue?.[temp_arr[i]]) {
                                        semi_final_arr.push({ client: temp_arr[i], revenue: e?.revenue?.[temp_arr[i]] })
                                    }
                                }

                                const final_arr = semi_final_arr.filter(f => f?.client !== 'all')

                                return (
                                    <div style={{ position: 'relative', height: '50px', width: '100%', display: 'flex', alignItems: 'center', borderBottom: (selectedDate === date) ? '1px solid #CCC' : 'none' }} key={i} onMouseEnter={e => e.currentTarget.lastChild.style.display = 'flex'} onMouseLeave={e => e.currentTarget.lastChild.style.display = 'none'}>
                                        <p style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }}>
                                            $ {(selectedDate === date) ? (
                                                e.revenue?.all.toFixed(2)
                                            ) : (
                                                campaign_ID.map(ma => ma.revenue?.all).reduce((a, b) => a + b, 0).toFixed(2)
                                            )}
                                            &nbsp;{(final_arr.length > 1) ? <FaChevronDown size={12} color="#000"/> : ''}
                                        </p>
                                        <div style={{ position: 'absolute', zIndex: 10, top: '100%', background: 'white', display: 'none', flexDirection: 'column', alignItems: 'start', gap: '5px', padding: '10px', borderRadius: '10px', boxShadow: '0px 2px 4px rgba(0,0,0,0.2)' }}>
                                            {(final_arr.length > 0) ? (
                                                final_arr.map((m, mi) => (
                                                    <p style={{ whiteSpace: 'nowrap' }} key={mi}>{m.client}: ${m?.revenue?.toFixed(1)}</p>
                                                ))
                                            ) : (<></>)}
                                        </div>
                                    </div>
                                )
                            } else {
                                return (<></>)
                            }
                        })}
                    </div>
                )
            },
        },
        { field: 'recipients', headerName: 'Emails Sent', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'secondBlock secondBlockLeftBorder removeCellPadding', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const date = params.row.date
                const campaign_ID = params.row.campaign_ID
                const sliceNumber = (selectedDate === date) ? campaign_ID.length : 1
                return (
                    <div style={{ minHeight: '50px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0px 10px', background: (selectedDate === date) ? 'rgba(0,0,0,0.05)' : 'transparent' }}>
                        {campaign_ID.slice(0, sliceNumber).map((e, i) => {
                            const arr = data.subGroup?.[date]?.[e.campaign_ID]
                            let recipients = 0

                            if (arr.length > 1) {
                                let tempName = Array.from(new Set(arr.map(m => m.name)))

                                if (tempName.length > 1) {
                                    recipients = tempName.map(a => arr.find(far => far.name === a)).filter(f => f.complete_date === f.date).map(m => m.recipients).reduce((a, b) => a+b, 0)
                                } else {
                                    if (arr[0]?.complete_date === arr[0]?.date) {
                                        recipients = arr[0]?.recipients
                                    }
                                }
                            } else {
                                if (arr[0]?.complete_date === arr[0]?.date) {
                                    recipients = arr[0]?.recipients
                                }
                            }

                            return (
                                <div style={{ height: '50px', width: '100%', display: 'flex', alignItems: 'center', borderBottom: (selectedDate === date) ? '1px solid #CCC' : 'none' }} key={i}>
                                    <p style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }}>{recipients ? recipients : '-'}</p>
                                </div>
                            )
                        })}
                    </div>
                )
            },
        },
        { field: 'open_rate', headerName: 'Open Rate', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'secondBlock removeCellPadding', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const date = params.row.date
                const campaign_ID = params.row.campaign_ID
                const sliceNumber = (selectedDate === date) ? campaign_ID.length : 1
                return (
                    <div style={{ minHeight: '50px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0px 10px', background: (selectedDate === date) ? 'rgba(0,0,0,0.05)' : 'transparent' }}>
                        {campaign_ID.slice(0, sliceNumber).map((e, i) => {
                            const arr = data.subGroup?.[date]?.[e.campaign_ID]
                            let open_rate = 0

                            if (arr.length > 1) {
                                let tempName = Array.from(new Set(arr.map(m => m.name)))

                                if (tempName.length > 1) {
                                    open_rate = tempName.map(a => arr.find(far => far.name === a)).filter(f => f.complete_date === f.date).map(m => m.open_rate).reduce((a, b) => a+b, 0)
                                } else {
                                    if (arr[0]?.complete_date === arr[0]?.date) {
                                        open_rate = arr[0]?.open_rate
                                    }
                                }
                            } else {
                                if (arr[0]?.complete_date === arr[0]?.date) {
                                    open_rate = arr[0]?.open_rate
                                }
                            }

                            return (
                                <div style={{ height: '50px', width: '100%', display: 'flex', alignItems: 'center', borderBottom: (selectedDate === date) ? '1px solid #CCC' : 'none' }} key={i}>
                                    <p style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }}>{open_rate ? open_rate.toFixed(2)+'%' : '-'}</p>
                                </div>
                            )
                        })}
                    </div>
                )
            },
        },
        { field: 'click_rate', headerName: 'Click Rate', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'secondBlock removeCellPadding', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const date = params.row.date
                const campaign_ID = params.row.campaign_ID
                const sliceNumber = (selectedDate === date) ? campaign_ID.length : 1
                return (
                    <div style={{ minHeight: '50px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0px 10px', background: (selectedDate === date) ? 'rgba(0,0,0,0.05)' : 'transparent' }}>
                        {campaign_ID.slice(0, sliceNumber).map((e, i) => {
                            const arr = data.subGroup?.[date]?.[e.campaign_ID]
                            let click_rate = 0

                            if (arr.length > 1) {
                                let tempName = Array.from(new Set(arr.map(m => m.name)))

                                if (tempName.length > 1) {
                                    click_rate = tempName.map(a => arr.find(far => far.name === a)).filter(f => f.complete_date === f.date).map(m => m.click_rate).reduce((a, b) => a+b, 0)
                                } else {
                                    if (arr[0]?.complete_date === arr[0]?.date) {
                                        click_rate = arr[0]?.click_rate
                                    }
                                }
                            } else {
                                if (arr[0]?.complete_date === arr[0]?.date) {
                                    click_rate = arr[0]?.click_rate
                                }
                            }

                            return (
                                <div style={{ height: '50px', width: '100%', display: 'flex', alignItems: 'center', borderBottom: (selectedDate === date) ? '1px solid #CCC' : 'none' }} key={i}>
                                    <p style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }}>{click_rate ? click_rate.toFixed(2)+'%' : '-'}</p>
                                </div>
                            )
                        })}
                    </div>
                )
            },
        },
        { field: 'unsubscribed', headerName: 'Unsubscribes', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'secondBlock removeCellPadding', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const date = params.row.date
                const campaign_ID = params.row.campaign_ID
                const sliceNumber = (selectedDate === date) ? campaign_ID.length : 1
                return (
                    <div style={{ minHeight: '50px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0px 10px', background: (selectedDate === date) ? 'rgba(0,0,0,0.05)' : 'transparent' }}>
                        {campaign_ID.slice(0, sliceNumber).map((e, i) => {
                            const arr = data.subGroup?.[date]?.[e.campaign_ID]
                            let unsubscribed = 0

                            if (arr.length > 1) {
                                let tempName = Array.from(new Set(arr.map(m => m.name)))

                                if (tempName.length > 1) {
                                    unsubscribed = tempName.map(a => arr.find(far => far.name === a)).filter(f => f.complete_date === f.date).map(m => m.unsubscribed).reduce((a, b) => a+b, 0)
                                } else {
                                    if (arr[0]?.complete_date === arr[0]?.date) {
                                        unsubscribed = arr[0]?.unsubscribed
                                    }
                                }
                            } else {
                                if (arr[0]?.complete_date === arr[0]?.date) {
                                    unsubscribed = arr[0]?.unsubscribed
                                }
                            }

                            return (
                                <div style={{ height: '50px', width: '100%', display: 'flex', alignItems: 'center', borderBottom: (selectedDate === date) ? '1px solid #CCC' : 'none' }} key={i}>
                                    <p style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }}>{unsubscribed ? unsubscribed.toFixed(0) : '-'}</p>
                                </div>
                            )
                        })}
                    </div>
                )
            },
        },
        { field: 'clicked', headerName: 'Total Clicks', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'secondBlock removeCellPadding', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const date = params.row.date
                const campaign_ID = params.row.campaign_ID
                const sliceNumber = (selectedDate === date) ? campaign_ID.length : 1
                return (
                    <div style={{ minHeight: '50px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0px 10px', background: (selectedDate === date) ? 'rgba(0,0,0,0.05)' : 'transparent' }}>
                        {campaign_ID.slice(0, sliceNumber).map((e, i) => {
                            const arr = data.subGroup?.[date]?.[e.campaign_ID]
                            let clicked = 0

                            if (arr.length > 1) {
                                let tempName = Array.from(new Set(arr.map(m => m.name)))

                                if (tempName.length > 1) {
                                    clicked = tempName.map(a => arr.find(far => far.name === a)).filter(f => f.complete_date === f.date).map(m => m?.clicked?.total).reduce((a, b) => a+b, 0)
                                } else {
                                    if (arr[0]?.complete_date === arr[0]?.date) {
                                        clicked = arr[0]?.clicked?.total ? arr[0]?.clicked?.total : 0
                                    }
                                }
                            } else {
                                if (arr[0]?.complete_date === arr[0]?.date) {
                                    clicked = arr[0]?.clicked?.total ? arr[0]?.clicked?.total : 0
                                }
                            }

                            return (
                                <div style={{ height: '50px', width: '100%', display: 'flex', alignItems: 'center', borderBottom: (selectedDate === date) ? '1px solid #CCC' : 'none' }} key={i}>
                                    <p style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }}>{clicked ? clicked.toFixed(0) : '-'}</p>
                                </div>
                            )
                        })}
                    </div>
                )
            },
        },
        { field: 'bounced', headerName: 'Bounced', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'secondBlock removeCellPadding', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const date = params.row.date
                const campaign_ID = params.row.campaign_ID
                const sliceNumber = (selectedDate === date) ? campaign_ID.length : 1
                return (
                    <div style={{ minHeight: '50px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0px 10px', background: (selectedDate === date) ? 'rgba(0,0,0,0.05)' : 'transparent' }}>
                        {campaign_ID.slice(0, sliceNumber).map((e, i) => {
                            const arr = data.subGroup?.[date]?.[e.campaign_ID]
                            let soft_bounce = 0
                            let hard_bounce = 0

                            if (arr.length > 1) {
                                let tempName = Array.from(new Set(arr.map(m => m.name)))

                                if (tempName.length > 1) {
                                    soft_bounce = tempName.map(a => arr.find(far => far.name === a)).filter(f => f.complete_date === f.date).map(m => (m?.bounced?.soft ? m?.bounced?.soft : 0)).reduce((a, b) => a+b, 0)
                                    hard_bounce = tempName.map(a => arr.find(far => far.name === a)).filter(f => f.complete_date === f.date).map(m => (m?.bounced?.hard ? m?.bounced?.hard : 0)).reduce((a, b) => a+b, 0)
                                } else {
                                    if (arr[0]?.complete_date === arr[0]?.date) {
                                        soft_bounce = arr[0]?.bounced?.soft ? arr[0]?.bounced?.soft : 0
                                        hard_bounce = arr[0]?.bounced?.hard ? arr[0]?.bounced?.hard : 0
                                    }
                                }
                            } else {
                                if (arr[0]?.complete_date === arr[0]?.date) {
                                    soft_bounce = arr[0]?.bounced?.soft ? arr[0]?.bounced?.soft : 0
                                    hard_bounce = arr[0]?.bounced?.hard ? arr[0]?.bounced?.hard : 0
                                }
                            }

                            return (
                                <div style={{ position: 'relative', height: '50px', width: '100%', display: 'flex', alignItems: 'center', borderBottom: (selectedDate === date) ? '1px solid #CCC' : 'none' }} key={i} onMouseEnter={e => e.currentTarget.lastChild.style.display = 'flex'} onMouseLeave={e => e.currentTarget.lastChild.style.display = 'none'}>
                                    <p style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }}>{(soft_bounce + hard_bounce) ? (soft_bounce + hard_bounce) : '-'}</p>
                                    <div style={{ position: 'absolute', zIndex: 10, top: '100%', background: 'white', display: 'none', flexDirection: 'column', alignItems: 'start', gap: '5px', padding: '10px', borderRadius: '10px', boxShadow: '0px 2px 4px rgba(0,0,0,0.2)' }}>
                                        <p style={{ whiteSpace: 'nowrap' }}>Soft: {soft_bounce}</p>
                                        <p style={{ whiteSpace: 'nowrap' }}>Hard: {hard_bounce}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )
            },
        },
        { field: 'RPME', headerName: 'RPME', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'secondBlock removeCellPadding', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const date = params.row.date
                const campaign_ID = params.row.campaign_ID
                const sliceNumber = (selectedDate === date) ? campaign_ID.length : 1
                return (
                    <div style={{ minHeight: '50px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0px 10px', background: (selectedDate === date) ? 'rgba(0,0,0,0.05)' : 'transparent' }}>
                        {campaign_ID.slice(0, sliceNumber).map((e, i) => {
                            const arr = data.subGroup?.[date]?.[e.campaign_ID]
                            let RPME = 0

                            if (arr.length > 1) {
                                let tempName = Array.from(new Set(arr.map(m => m.name)))

                                if (tempName.length > 1) {
                                    RPME = ( e.revenue?.all / ( tempName.map(a => arr.find(far => far.name === a)).filter(f => f.complete_date === f.date).map(m => m.recipients).reduce((a, b) => a+b, 0) ) ) * 1000
                                } else {
                                    if (arr[0]?.complete_date === arr[0]?.date) {
                                        RPME = ( e.revenue?.all / arr[0]?.recipients ) * 1000
                                    }
                                }
                            } else {
                                if (arr[0]?.complete_date === arr[0]?.date) {
                                    RPME = ( e.revenue?.all / arr[0]?.recipients ) * 1000
                                }
                            }

                            return (
                                <div style={{ height: '50px', width: '100%', display: 'flex', alignItems: 'center', borderBottom: (selectedDate === date) ? '1px solid #CCC' : 'none' }} key={i}>
                                    <p style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }}>{(RPME && (RPME !== Infinity)) ? RPME.toFixed(3) : '-'}</p>
                                </div>
                            )
                        })}
                    </div>
                )
            },
        },
        { field: 'CPUnsub', headerName: 'CPUnsub', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'secondBlock removeCellPadding', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const date = params.row.date
                const campaign_ID = params.row.campaign_ID
                const sliceNumber = (selectedDate === date) ? campaign_ID.length : 1
                return (
                    <div style={{ minHeight: '50px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0px 10px', background: (selectedDate === date) ? 'rgba(0,0,0,0.05)' : 'transparent' }}>
                        {campaign_ID.slice(0, sliceNumber).map((e, i) => {
                            const arr = data.subGroup?.[date]?.[e.campaign_ID]
                            let CPUnsub = 0

                            if (arr.length > 1) {
                                let tempName = Array.from(new Set(arr.map(m => m.name)))

                                if (tempName.length > 1) {
                                    CPUnsub = ( e.result / ( tempName.map(a => arr.find(far => far.name === a)).filter(f => f.complete_date === f.date).map(m => m.recipients).reduce((a, b) => a+b, 0) ) ) * ( tempName.map(a => arr.find(far => far.name === a)).filter(f => f.complete_date === f.date).map(m => m.unsubscribes).reduce((a, b) => a+b, 0) )
                                } else {
                                    if (arr[0]?.complete_date === arr[0]?.date) {
                                        CPUnsub = ( e.result / arr[0]?.recipients ) * arr[0]?.unsubscribed
                                    }
                                }
                            } else {
                                if (arr[0]?.complete_date === arr[0]?.date) {
                                    CPUnsub = ( e.result / arr[0]?.recipients ) * arr[0]?.unsubscribed
                                }
                            }

                            return (
                                <div style={{ height: '50px', width: '100%', display: 'flex', alignItems: 'center', borderBottom: (selectedDate === date) ? '1px solid #CCC' : 'none' }} key={i}>
                                    <p style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }}>{CPUnsub ? ( (CPUnsub > 0) ? '0.000' : CPUnsub.toFixed(3) ) : '-'}</p>
                                </div>
                            )
                        })}
                    </div>
                )
            },
        },
        { field: 'RPC', headerName: 'RPC', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', cellClassName: 'secondBlock removeCellPadding', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const date = params.row.date
                const campaign_ID = params.row.campaign_ID
                const sliceNumber = (selectedDate === date) ? campaign_ID.length : 1
                return (
                    <div style={{ minHeight: '50px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0px 10px', background: (selectedDate === date) ? 'rgba(0,0,0,0.05)' : 'transparent' }}>
                        {campaign_ID.slice(0, sliceNumber).map((e, i) => {
                            const arr = data.subGroup?.[date]?.[e.campaign_ID]
                            let RPC = 0

                            if (arr.length > 1) {
                                let tempName = Array.from(new Set(arr.map(m => m.name)))

                                if (tempName.length > 1) {
                                    RPC = e.revenue?.all / ( tempName.map(a => arr.find(far => far.name === a)).filter(f => f.complete_date === f.date).map(m => m.total_clicks).reduce((a, b) => a+b, 0) )
                                } else {
                                    if (arr[0]?.complete_date === arr[0]?.date) {
                                        RPC = e.revenue?.all / arr[0]?.total_clicks
                                    }
                                }
                            } else {
                                if (arr[0]?.complete_date === arr[0]?.date) {
                                    RPC = e.revenue?.all / arr[0]?.total_clicks
                                }
                            }

                            return (
                                <div style={{ height: '50px', width: '100%', display: 'flex', alignItems: 'center', borderBottom: (selectedDate === date) ? '1px solid #CCC' : 'none' }} key={i}>
                                    <p style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }}>{(RPC && (RPC !== Infinity)) ? '$ '+RPC.toFixed(3) : '-'}</p>
                                </div>
                            )
                        })}
                    </div>
                )
            },
        },
        { field: 'emailoctopus_list_name', headerName: 'Email List', minWidth: 200, flex: 2, headerClassName: 'datagridHeader', cellClassName: 'thirdBlock thirdBlockLeftBorder removeCellPadding', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const date = params.row.date
                const campaign_ID = params.row.campaign_ID
                const sliceNumber = (selectedDate === date) ? campaign_ID.length : 1
                return (
                    <div style={{ minHeight: '50px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0px 10px', background: (selectedDate === date) ? 'rgba(0,0,0,0.05)' : 'transparent' }}>
                        {campaign_ID.slice(0, sliceNumber).map((e, i) => {
                            const arr = data.subGroup?.[date]?.[e.campaign_ID]
                            let emailoctopus_list_name = ''

                            if (arr.length > 1) {
                                let tempName = Array.from(new Set(arr.map(m => m.name)))

                                if (tempName.length > 1) {
                                    emailoctopus_list_name = tempName.map(a => arr.find(far => far.name === a)).filter(f => f.complete_date === f.date).map(m => m.emailoctopus_list_name)
                                } else {
                                    if (arr[0]?.complete_date === arr[0]?.date) {
                                        emailoctopus_list_name = arr[0]?.emailoctopus_list_name
                                    }
                                }
                            } else {
                                if (arr[0]?.complete_date === arr[0]?.date) {
                                    emailoctopus_list_name = arr[0]?.emailoctopus_list_name
                                }
                            }

                            return (
                                <div style={{ minHeight: '50px', width: '100%', display: 'flex', alignItems: 'center', borderBottom: (selectedDate === date) ? '1px solid #CCC' : 'none' }} key={i}>
                                    <p style={{ width: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: '14px' }} onMouseEnter={e => {e.currentTarget.style.whiteSpace = 'normal'}} onMouseLeave={e => {e.currentTarget.style.whiteSpace = 'nowrap'}} >{emailoctopus_list_name ? ( Array.isArray(emailoctopus_list_name) ? emailoctopus_list_name.join(' || ') : emailoctopus_list_name ) : '-'}</p>
                                </div>
                            )
                        })}
                    </div>
                )
            },
        },
    ];

    return (
        <div className="trafficContainer">
            {/* all campaigns between the dates */}
            <div className="trafficTopContainer">
                <div className="trafficTopWrapper">
                    {/* logo and date */}
                    <div className="logoDateContainer">
                        <div className="logoWrapper">
                            <img src={logo} alt="LOGO"/>
                        </div>
                        <div className="dateWrapper">
                            <div className="dateWrapperDiv">
                                <label htmlFor="fromTopDate">From: </label>
                                <input id="fromTopDate" type="date" className="datePicker" value={fromDate} onChange={(e) => {e.preventDefault(); setFromDate(e.target.value);}}/>
                            </div>
                            <div className="dateWrapperDiv">
                                <label htmlFor="toTopDate">To: </label>
                                <input id="toTopDate" type="date" className="datePicker" value={toDate} onChange={(e) => {e.preventDefault(); setToDate(e.target.value);}}/>
                            </div>
                        </div>
                    </div>

                    {/* total values */}
                    <div className="totalContainer">
                        <p style={{lineHeight: '50px', fontSize: '20px'}}>Southpow</p>

                        <div style={{lineHeight: '50px', fontSize: '20px', fontWeight: 700}}>Total:</div>

                        <div className="totalCardContainer" style={{ height: '150px' }}>
                            <div style={{ height: '100%', width: 'fit-content', padding: '5px' }}>
                                <div style={{ height: '100%', width: 'fit-content', minWidth: '150px', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '5px', boxShadow: '0 0 5px #CCCCCC', borderRadius: '10px', padding: '5px' }}>
                                    <p style={{ fontWeight: '700', color: '#555' }}>Revenue</p>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px', }}><p style={{ fontSize: '18px', whiteSpace: 'nowrap', color: '#f59e0b', fontWeight: 500 }}>Total:</p><p style={{ fontSize: '18px', color: '#f59e0b', fontWeight: 600 }}>${totalValue?.revenue?.all ? totalValue?.revenue?.all?.toFixed(0) : 0}</p></div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px' }}><p style={{ fontSize: '14px', whiteSpace: 'nowrap', color: '#777' }}>Tonic:</p><p style={{ fontSize: '14px', fontWeight: "500" }}>${totalValue?.revenue?.tonic ? totalValue?.revenue?.tonic?.toFixed(0) : 0}</p></div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px' }}><p style={{ fontSize: '14px', whiteSpace: 'nowrap', color: '#777' }}>Sedo:</p><p style={{ fontSize: '14px', fontWeight: "500" }}>${totalValue?.revenue?.sedo ? totalValue?.revenue?.sedo?.toFixed(0) : 0}</p></div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px' }}><p style={{ fontSize: '14px', whiteSpace: 'nowrap', color: '#777' }}>Crossroads:</p><p style={{ fontSize: '14px', fontWeight: "500" }}>${totalValue?.revenue?.crossroads ? totalValue?.revenue?.crossroads?.toFixed(0) : 0}</p></div>
                                </div>
                            </div>
                            <div style={{ height: '100%', width: 'fit-content', padding: '5px' }}>
                                <div style={{ height: '100%', width: 'fit-content', minWidth: '150px', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '5px', boxShadow: '0 0 5px #CCCCCC', borderRadius: '10px', padding: '5px' }}>
                                    <p style={{ fontWeight: '700', color: '#555' }}>Emails Sent</p>
                                    <p style={{ fontSize: '24px' }}>{totalValue.recipientsTotal ? Number(totalValue.recipientsTotal).toLocaleString(undefined, { maximumFractionDigits: 0 }) : 0}</p>
                                </div>
                            </div>
                            <div style={{ height: '100%', width: 'fit-content', padding: '5px' }}>
                                <div style={{ height: '100%', width: 'fit-content', minWidth: '150px', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '5px', boxShadow: '0 0 5px #CCCCCC', borderRadius: '10px', padding: '5px' }}>
                                    <p style={{ fontWeight: '700', color: '#555' }}>Total Clicks</p>
                                    <p style={{ fontSize: '24px' }}>{totalValue.total_clicksTotal ? Number(totalValue.total_clicksTotal).toLocaleString(undefined, { maximumFractionDigits: 0 }) : 0}</p>
                                </div>
                            </div>
                            <div style={{ height: '100%', width: 'fit-content', padding: '5px' }}>
                                <div style={{ height: '100%', width: 'fit-content', minWidth: '150px', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '5px', boxShadow: '0 0 5px #CCCCCC', borderRadius: '10px', padding: '5px' }}>
                                    <p style={{ fontWeight: '700', color: '#555' }}>RPME</p>
                                    <p style={{ fontSize: '24px' }}>{calculation.RPME.toFixed(3)}</p>
                                </div>
                            </div>
                            <div style={{ height: '100%', width: 'fit-content', padding: '5px' }}>
                                <div style={{ height: '100%', width: 'fit-content', minWidth: '150px', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '5px', boxShadow: '0 0 5px #CCCCCC', borderRadius: '10px', padding: '5px' }}>
                                    <p style={{ fontWeight: '700', color: '#555' }}>RPC</p>
                                    <p style={{ fontSize: '24px' }}>{calculation.RPC.toFixed(3)}</p>
                                </div>
                            </div>
                            <div style={{ height: '100%', width: 'fit-content', padding: '5px' }}>
                                <div style={{ height: '100%', width: 'fit-content', minWidth: '150px', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '5px', boxShadow: '0 0 5px #CCCCCC', borderRadius: '10px', padding: '5px' }}>
                                    <p style={{ fontWeight: '700', color: '#555' }}>Total Subs</p>
                                    <p style={{ fontSize: '24px' }}>{totalValue.total_subscriptions ? Number(totalValue.total_subscriptions).toLocaleString(undefined, { maximumFractionDigits: 0 }) : 0}</p>
                                </div>
                            </div>
                            <div style={{ height: '100%', width: 'fit-content', padding: '5px' }}>
                                <div style={{ height: '100%', width: 'fit-content', minWidth: '150px', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '5px', boxShadow: '0 0 5px #CCCCCC', borderRadius: '10px', padding: '5px' }}>
                                    <p style={{ fontWeight: '700', color: '#555' }}>Total Unsubs</p>
                                    <p style={{ fontSize: '24px' }}>{totalValue.unsubscribesTotal ? Number(totalValue.unsubscribesTotal).toLocaleString(undefined, { maximumFractionDigits: 0 }) : 0}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* chart */}
                    <div className="chartContainer" style={{ height: '500px', flexDirection: 'column', marginBottom: '10px' }} >
                        <div className="chartWrapper" style={{ position: 'relative' }}>
                            {loading ? (
                                <div style={{ position: 'absolute', height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(255,255,255,0.6)', gap: '10px' }}>
                                    <div className="loader" style={{ height: '40px', width: '40px', borderRadius: '100%', border: '5px solid #1976d2', borderTop: '5px solid #E8F1FB' }}/>
                                    <p>Loading</p>
                                </div>
                            ) : (<></>)}
                            <Line options={chartOptions} data={chartData} />
                        </div>
                    </div>

                    {/* datagrid */}
                    <div className="dataGridContainer">
                        <DataGrid
                        components={{ Toolbar: GridToolbar }}
                        initialState={{
                            sorting: {
                              sortModel: [{ field: 'date', sort: 'desc' }],
                            },
                        }}
                        sx={{
                            boxShadow: 3,
                            '& .datagridHeader': {
                                color: '#1976d2',
                            },
                            '& .removeCellPadding': {
                                padding: '0px',
                            },
                            '& .firstBlock': {
                                background: 'rgba(75, 192, 192, 0.05)',
                            },
                            '& .firstBlockLeftBorder': {
                                borderLeft: '3px solid rgba(75, 192, 192, 0.3)',
                            },
                            '& .secondBlock': {
                                background: 'rgba(153, 102, 255, 0.05)',
                            },
                            '& .secondBlockLeftBorder': {
                                borderLeft: '3px solid rgba(153, 102, 255, 0.3)',
                            },
                            '& .thirdBlock': {
                                background: 'rgba(54, 162, 235, 0.05)',
                            },
                            '& .thirdBlockLeftBorder': {
                                borderLeft: '3px solid rgba(54, 162, 235, 0.3)',
                            }
                        }}
                        loading={loading}
                        getRowHeight={() => 'auto'}
                        rows={totalRows} columns={totalColumns} disableSelectionOnClick={true}/>
                        {/* rows={[]} columns={[]} disableSelectionOnClick={true}/> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailOctopus