import React, { useState, useEffect, useContext } from 'react'
import './settings.css'
import axios from 'axios'
import Cookies from 'js-cookie'
import { FaUserPlus, FaTrashAlt, FaEyeSlash, FaEye, FaPlus, FaEdit, FaCheck } from 'react-icons/fa'
import { FaXmark } from 'react-icons/fa6'
import { MdSecurity } from "react-icons/md";

import { host } from '../../hooks/host'
import useFetchUsers from '../../hooks/useFetchUsers'
import { Context } from '../../AppContext'
import { access_pages_list } from '../../local/permissionList'

const Settings = () => {
    const appContext = useContext(Context)

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState({ traffic: false, fb_fan_pages: false, users: false })
    const [facebookFanPages, setFacebookFanPages] = useState([])
    const [global, setGlobal] = useState({ filterAcUser: 'Southpow', filterAcClient: '', filterFanUser: '', displayConfigureForm: false, displayUserForm: false, displayFanPageForm: false, displayFacebookTokenForm: false, displayTable: 'accounts' })
    const [tableData, setTableData] = useState([])
    const [userEdit, setUserEdit] = useState({ loading: false, user: '', pages: [] })
    const [userForm, setUserForm] = useState({
        loading: false,
        details: { username: '', password: '', role: '', manager: '', pages: [] },
        disableButton: true,
        showPassword: false,
        error: { state: false, message: '' }
    })
    const [fanPageForm, setFanPageForm] = useState({
        loading: false,
        details: { id: '', name: '', user: '' },
        error: { state: false, message: '' }
    })
    const [configureForm, setConfigureForm] = useState({
        traffic: 'Facebook',
        loading: false,
        Taboola: { ID: '', SECRET: '', accountID: '', user: '', client: '' },
        Facebook: { act_id: '', user: '', client: '', act_name: '', pixels_id: '' },
        TikTok: { app_id: '', act_id: '', user: '', client: '', act_name: '' },
        Outbrain: { ID: '', token: '', password: '', username: '', user: '', client: '' },
    })
    const [facebookTokenForm, setFacebookTokenForm] = useState({ loading: false, token: '' })

    const fetch = useFetchUsers(refresh.users)
    const [userLoading, users] = fetch

    // api to change facebook token
    const handleChangeFacebookToken = () => {
        setFacebookTokenForm(p => { return { ...p, loading: true } })
        const token = Cookies.get('token')
        const url = `${host}/api/admin/setup_facebook_token`;

        axios.post(url, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
            },
            token: facebookTokenForm.token
        })

        .then((response) => {
            const obj = response.data
            console.log(response)

            if (obj.acknowledged) {
                window.alert('Facebook Token Changed Successfully!')
            }

            setFacebookTokenForm(p => { return { ...p, loading: false, token: '' } })
        })
        
        .catch((error) => {
            window.alert(error);
            setFacebookTokenForm(p => { return { ...p, loading: false } })
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        })
    }

    // api to delete account or facebook_fan_page or user
    const handleDelete = ({ type, data_to_send }) => {
        setLoading(true)
        const token = Cookies.get('token')
        const url = `${host}/api/admin/delete`;

        axios.post(url, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
            },
            type: type,
            filter: data_to_send
        })

        .then((response) => {
            const obj = response.data

            if (type === 'Traffic') {
                setRefresh(p => { return { ...p, traffic: !p.traffic } })
            } else if (type === 'FacebookPage') {
                setRefresh(p => { return { ...p, fb_fan_pages: !p.fb_fan_pages } })
            } else if (type === 'user') {
                setRefresh(p => { return { ...p, users: !p.users } })
            }
            setLoading(false)
        })
        
        .catch((error) => {
            window.alert(error);
            setLoading(false)
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        })
    }

    // api to fetch accounts list
    const getAccountsAPI = () => {
        setLoading(true)

        const controller = new AbortController();
        const token = Cookies.get('token')
        const url = `${host}/api/admin/getTrafficList`;

        axios.post(url, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
            },
            user: global.filterAcUser
        },
        {
            signal: controller.signal
        })

        .then((response) => {
            const obj = response.data

            if (obj && Array.isArray(obj)) {
                setTableData(obj.sort((a, b) => {
                    if (a.name > b.name) return 1;
                    if (a.name < b.name) return -1;
                    return 0;
                }))
            }

            setLoading(false)
        })
        
        .catch((error) => {
            console.log(error);
            setLoading(false)
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        })
    }

    // find the correct field name to display in table NAME
    const findAccountName = (e) => {
        let name = ''

        if (e.accountID) {
            name = e.accountID
        } else if (e.username) {
            name = e.username
        } else if (e.client_ID) {
            name = e.client_ID
        } else if (e.act_name) {
            name = e.act_name
        } else {
            name = e.name
        }

        return name
    }

    // validate on user form submit
    const handleUserFormSubmit = (e) => {
        e.preventDefault()

        if (userForm.details.username === '' || userForm.details.password === '' || userForm.details.role === '') {
            setUserForm(p => {return{...p, error: { state: true, message: 'Please fill all fields.' }}});
        } else if (/\s/g.test(userForm.details.password)) {
            setUserForm(p => {return{...p, error: { state: true, message: 'Password can not contain empty spaces.' }}});
        } else {
            if (userForm.details.role === 'widget' && userForm.details.manager === '') {
                setUserForm(p => {return{...p, error: { state: true, message: 'Choose a manager to assign with this widget user.' }}});
            } else {
                setUserForm(p => {return{...p, error: { state: false, message: '' }}});
                createUserAPI()
            }
        }
    }

    // api to register new user
    const createUserAPI = () => {
        setUserForm(p => { return { ...p, loading: true } })
        const token = Cookies.get('token')
        const url = `${host}/api/admin/register`;

        axios.post(url, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
            },
            username: userForm.details.username.trim(),
            password: userForm.details.password.trim(),
            role: userForm.details.role,
            manager: (userForm.details.role === 'manager') ? userForm.details.username : userForm.details.manager,
            pages: userForm.details.pages
        })

        .then((response) => {
            const obj = response.data
            console.log(response);

            window.alert('User successfully created.')
            setRefresh(p => { return { ...p, users: !p.users } })
            setUserForm(p => {
                return {
                    ...p,
                    loading: false,
                    error: { state: false, message: '' },
                    details: { username: '', password: '', role: '', manager: '', pages: [] }
                }
            })
        })
        
        .catch((error) => {
            console.log(error);
            setUserForm(p => { return { ...p, loading: false, error: { state: true, message: 'Error! Please try again.' } } })
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        }) 
    }

    // validate empty form
    const handleConfigureFormSubmit = () => {
        if (configureForm.traffic === 'Facebook') {
            if (configureForm.Facebook.act_id === '' || configureForm.Facebook.act_name === '' || configureForm.Facebook.pixels_id === '' || configureForm.Facebook.user === '' || configureForm.Facebook.client === '') {
                window.alert("Please fill all fields!")
                return
            }
        } else if (configureForm.TikTok === 'TikTok') {
            if (configureForm.TikTok.act_id === '' || configureForm.TikTok.act_name === '' || configureForm.TikTok.app_id === '' || configureForm.TikTok.user === '' || configureForm.TikTok.client === '') {
                window.alert("Please fill all fields!")
                return
            }
        } else if (configureForm.traffic === 'Taboola') {
            if (configureForm.Taboola.ID === '' || configureForm.Taboola.SECRET === '' || configureForm.Taboola.accountID === '' || configureForm.Taboola.user === '' || configureForm.Taboola.client === '') {
                window.alert("Please fill all fields!")
                return
            }
        } else if (configureForm.traffic === 'Outbrain') {
            if (configureForm.Outbrain.ID === '' || configureForm.Outbrain.token === '' || configureForm.Outbrain.password === '' || configureForm.Outbrain.username === '' || configureForm.Outbrain.user === '' || configureForm.Outbrain.client === '') {
                window.alert("Please fill all fields!")
                return
            }
        }

        registerAccountAPI()
    }

    // api to register new account
    const registerAccountAPI = () => {
        setConfigureForm(p => { return { ...p, loading: true } })
        const token = Cookies.get('token')
        const url = `${host}/api/admin/setup`;
        let temp = {}

        if (configureForm.traffic === 'Taboola') {
            temp = { ID: configureForm.Taboola.ID.trim(), SECRET: configureForm.Taboola.SECRET.trim(), accountID: configureForm.Taboola.accountID.trim(), user: configureForm.Taboola.user.trim(), client: configureForm.Taboola.client.trim() }
        } else if (configureForm.traffic === 'Facebook') {
            temp = { act_id: configureForm.Facebook.act_id.trim(), user: configureForm.Facebook.user.trim(), client: configureForm.Facebook.client.trim(), act_name: configureForm.Facebook.act_name.trim(), pixels_id: configureForm.Facebook.pixels_id.trim() }
        } else if (configureForm.traffic === 'TikTok') {
            temp = { app_id: configureForm.TikTok.app_id.trim(), act_id: configureForm.TikTok.act_id.trim(), user: configureForm.TikTok.user.trim(), client: configureForm.TikTok.client.trim(), act_name: configureForm.TikTok.act_name.trim() }
        } else if (configureForm.traffic === 'Outbrain') {
            temp = { ID: configureForm.Outbrain.ID.trim(), token: configureForm.Outbrain.token.trim(), password: configureForm.Outbrain.password.trim(), username: configureForm.Outbrain.username.trim(), user: configureForm.Outbrain.user.trim(), client: configureForm.Outbrain.client.trim() }
        }

        axios.post(url, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
            },
            traffic: configureForm.traffic, data: temp
        })

        .then((response) => {
            const obj = response.data

            if (obj.message) {
                window.alert(obj.message ? obj.message : 'Error! Please try again.')
            } else if (obj.result) {
                window.alert('Account successfully registered.')
                setRefresh(p => { return { ...p, traffic: !p.traffic } })
            } else {
                setRefresh(p => { return { ...p, traffic: !p.traffic } })
            }

            setConfigureForm(p => { return { ...p, loading: false } })
        })
        
        .catch((error) => {
            console.log(error);
            setConfigureForm(p => { return { ...p, loading: false } })
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        }) 
    }

    // validate on facebook fan page form submit
    const handleFanPageSubmit = () => {
        if (fanPageForm.details.id.trim() === '' || fanPageForm.details.name.trim() === '' || fanPageForm.details.user.trim() === '') {
            setFanPageForm(p => {return{...p, error: { state: true, message: 'Please fill all fields.' }}});
        } else {
            setFanPageForm(p => {return{...p, error: { state: false, message: '' }}});
            registerFanPageAPI()
        }
    }

    // api to register facebook fan page
    const registerFanPageAPI = () => {
        setFanPageForm(p => { return { ...p, loading: true } })
        const token = Cookies.get('token')
        const url = `${host}/api/admin/setup_facebook_page`;

        axios.post(url, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
            },
            id: fanPageForm.details.id.trim(),
            name:fanPageForm.details.name.trim(),
            user: fanPageForm.details.user.trim()
        })
        
        .then((response) => {
            setRefresh(p => { return { ...p, fb_fan_pages: !p.fb_fan_pages } })
            setFanPageForm(p => {
                return {
                    ...p,
                    loading: false,
                    details: { id: '', name: '', user: '' },
                    error: { state: false, message: '' }
                }
            })
        })
        
        .catch((error) => {
            console.log(error);
            setFanPageForm(p => { return { ...p, loading: false, error: { state: true, message: 'Error! Please try again.' } } })
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        }) 
    }

    // api to edit user permissions
    const handleUserPermissionUpdate = () => {
        setUserEdit(p => { return { ...p, loading: true } })
        const token = Cookies.get('token')
        const url = `${host}/api/auth/changePages`;

        axios.post(url, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
            },
            user: userEdit.user,
            pages: userEdit.pages
        })
        
        .then((response) => {
            const obj = response.data
            console.log(response);
            
            setRefresh(p => { return { ...p, users: !p.users } })
            setUserEdit(p => { return { ...p, loading: false, user: '', pages: [] }})
        })
        
        .catch((error) => {
            console.log(error);
            setUserEdit(p => { return { ...p, loading: false }})
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        }) 
    }

    // update configure form state
    const handleOnChange = (e) => {
        e.preventDefault()
        
        const fieldName = e.target.getAttribute("name");
        const fieldValue = e.target.value;

        const newFormData = { ...configureForm }
        newFormData[configureForm.traffic][fieldName] = fieldValue.trimStart()

        setConfigureForm(newFormData)
    }

    // get outbrain token
    useEffect(() => {
        const getOutbrainToken = () => {
            const controller = new AbortController();
            const token = Cookies.get('token')
            const url = `${host}/api/admin/getTrafficList`;

            axios.post(url, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                    "Authorization": "Bearer "+token,
                    "Content-Type": "application/json",
                },
                user: 'Southpow'
            },
            {
                signal: controller.signal
            })

            .then((response) => {
                const obj = response.data
                
                if (obj.result) {
                    let temp = obj.result.find(f => f.username === 'outbrain@3xm.asia')

                    if (temp) {
                        setConfigureForm(p => {
                            return {
                                ...p,
                                Outbrain: { ...p.Outbrain, token: temp.outbrain_token, password: temp.password }
                            }
                        })
                    } else {
                        window.alert("Could not fetch Outbrain credentials.\nReload to try fetch it again.\nOr you can't create account in Outbrain.")
                    }
                }
            })
            
            .catch((error) => {
                console.log(error);
                if (error?.response?.data?.message === 'Invalid') {
                    appContext.forceLogout()
                }
            }) 
        }
        getOutbrainToken()
    }, [])

    // fetch facebook fan pages
    useEffect(() => {
        if (users.length === 0) {
            return
        }

        const controller = new AbortController();
        const token = Cookies.get("token"); 
        const urlFbFanPages = `${host}/api/admin/facebook_page`;
    
        const getFbFanPages = () => {
            setLoading(true)

            axios.post(urlFbFanPages,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                        "Authorization": "Bearer "+token,
                        "Content-Type": "application/json",
                    },
                    username: users.filter(f => f.role === 'manager').map(e => e.username)
                },
                {
                    signal: controller.signal
                }
            )
    
            .then((response) => {
                const obj = response.data;
                console.log(obj)
                
                if (obj && Array.isArray(obj)) {
                    setFacebookFanPages(obj.sort((a, b) => {
                        if (a.user > b.user) return 1;
                        if (a.user < b.user) return -1;
                        return 0;
                    }))
                }
                
                setLoading(false)
            })
    
            .catch((error) => {
                console.log(error);
                setLoading(false)
                if (error?.response?.data?.message === 'Invalid') {
                    appContext.forceLogout()
                }
            });
        };
    
        getFbFanPages()
    
        return () => {
            controller.abort()
        };
    }, [users, refresh.fb_fan_pages])

    // fetch accounts list on user change
    useEffect(() => {
        if (global.filterAcUser !== '') {
            getAccountsAPI()
        }
    }, [global.filterAcUser, refresh.traffic])

    // ENABLE or DISABLE the user form submit button
    useEffect(() => {
        if ((userForm.details.username === '') || (userForm.details.password === '') || (userForm.details.role === '') || (userForm.details.username.length === 0)) {
            setUserForm(p => {return{...p, disableButton: true}});
        } else {
            setUserForm(p => {return{...p, disableButton: false}});
        }
    }, [userForm.details])

    // clean state on traffic change
    useEffect(() => {
        let newDetails = {
            ...configureForm,
            Taboola: { ...configureForm.Taboola, ID: '', SECRET: '', accountID: '', user: '', client: '' },
            Facebook: { ...configureForm.Facebook, act_id: '', act_name: '', pixels_id: '', user: '', client: '' },
            TikTok: { ...configureForm.TikTok, app_id: '', act_id: '', act_name: '', user: '', client: '' },
            Outbrain: { ...configureForm.Outbrain, ID: '', username: '', user: '', client: '' },
        }

        setConfigureForm(newDetails)
    }, [configureForm.traffic])

    // jsx returning functions

    // determine which form to load
    const getForm = () => {
        let result = <></>

        if (configureForm.traffic === 'Facebook') {
            result = <>
                <div className="settings-form-input">
                    <label htmlFor="act_id">Facebook Account ID:</label>
                    <input name='act_id' type="text" placeholder='Account ID' autoComplete='off' value={configureForm.Facebook.act_id} onChange={e => handleOnChange(e)} />
                </div>
                <div className="settings-form-input">
                    <label htmlFor="act_name">Facebook Account Name:</label>
                    <input name='act_name' type="text" placeholder='Account Name' autoComplete='off' value={configureForm.Facebook.act_name} onChange={e => handleOnChange(e)} />
                </div>
                <div className="settings-form-input">
                    <label htmlFor="pixels_id">Facebook Pixels ID:</label>
                    <input name='pixels_id' type="text" placeholder='Pixels ID' autoComplete='off' value={configureForm.Facebook.pixels_id} onChange={e => handleOnChange(e)} />
                </div>
                <div className="settings-form-input">
                    <label htmlFor="user">User:</label>
                    <select name='user' autoComplete='off' value={configureForm.Facebook.user} onChange={e => handleOnChange(e)}>
                        <option value="" disabled>--- Choose ---</option>
                        {users.filter(f => f.role === 'manager').map((e, i) => (
                            <option value={e.username} key={i}>{e.username}</option>
                        ))}
                    </select>
                </div>
                <div className="settings-form-input">
                    <label htmlFor="client">Client:</label>
                    <select name='client' autoComplete='off' value={configureForm.Facebook.client} onChange={e => handleOnChange(e)}>
                        <option value="" disabled>--- Choose ---</option>
                        <option value="All">All Clients</option>
                        <option value="Adcombo">Adcombo</option>
                        <option value="Ads">Ads</option>
                        <option value="Crossroads">Crossroads</option>
                        <option value="Sedo">Sedo</option>
                        <option value="System1">System1</option>
                        <option value="Tonic">Tonic</option>
                    </select>
                </div>
                <button className="settings-form-button" onClick={e => handleConfigureFormSubmit(e)} style={{ background: '#4caf50' }} >
                    {configureForm.loading ? (
                        <div className="loader"></div>
                    ) : 'SUBMIT'}
                </button>
            </>
        } else if (configureForm.traffic === 'TikTok') {
            result = <>
                <div className="settings-form-input">
                    <label htmlFor="app_id">TikTok App ID:</label>
                    <input name='app_id' type="text" placeholder='App ID' autoComplete='off' value={configureForm.TikTok.app_id} onChange={e => handleOnChange(e)} />
                </div>
                <div className="settings-form-input">
                    <label htmlFor="act_id">TikTok Account ID:</label>
                    <input name='act_id' type="text" placeholder='Account ID' autoComplete='off' value={configureForm.TikTok.act_id} onChange={e => handleOnChange(e)} />
                </div>
                <div className="settings-form-input">
                    <label htmlFor="act_name">TikTok Account Name:</label>
                    <input name='act_name' type="text" placeholder='Account Name' autoComplete='off' value={configureForm.TikTok.act_name} onChange={e => handleOnChange(e)} />
                </div>
                <div className="settings-form-input">
                    <label htmlFor="user">User:</label>
                    <select name='user' autoComplete='off' value={configureForm.TikTok.user} onChange={e => handleOnChange(e)}>
                        <option value="" disabled>--- Choose ---</option>
                        {users.filter(f => f.role === 'manager').map((e, i) => (
                            <option value={e.username} key={i}>{e.username}</option>
                        ))}
                    </select>
                </div>
                <div className="settings-form-input">
                    <label htmlFor="client">Client:</label>
                    <select name='client' autoComplete='off' value={configureForm.TikTok.client} onChange={e => handleOnChange(e)}>
                        <option value="" disabled>--- Choose ---</option>
                        <option value="All">All Clients</option>
                        <option value="Adcombo">Adcombo</option>
                        <option value="Ads">Ads</option>
                        <option value="Crossroads">Crossroads</option>
                        <option value="Sedo">Sedo</option>
                        <option value="System1">System1</option>
                        <option value="Tonic">Tonic</option>
                    </select>
                </div>
                <button className="settings-form-button" onClick={e => handleConfigureFormSubmit(e)} style={{ background: '#4caf50' }} >
                    {configureForm.loading ? (
                        <div className="loader"></div>
                    ) : 'SUBMIT'}
                </button>
            </>
        } else if (configureForm.traffic === 'Taboola') {
            result = <>
                <div className="settings-form-input">
                    <label htmlFor="ID">Taboola ID:</label>
                    <input name='ID' type="text" placeholder='ID' autoComplete='off' value={configureForm.Taboola.ID} onChange={e => handleOnChange(e)} />
                </div>
                <div className="settings-form-input">
                    <label htmlFor="SECRET">Taboola Secret:</label>
                    <input name='SECRET' type="text" placeholder='SECRET' autoComplete='off' value={configureForm.Taboola.SECRET} onChange={e => handleOnChange(e)} />
                </div>
                <div className="settings-form-input">
                    <label htmlFor="accountID">Taboola Account ID:</label>
                    <input name='accountID' type="text" placeholder='Account ID' autoComplete='off' value={configureForm.Taboola.accountID} onChange={e => handleOnChange(e)} />
                </div>
                <div className="settings-form-input">
                    <label htmlFor="user">User:</label>
                    <select name='user' autoComplete='off' value={configureForm.Taboola.user} onChange={e => handleOnChange(e)}>
                        <option value="" disabled>--- Choose ---</option>
                        {users.filter(f => f.role === 'manager').map((e, i) => (
                            <option value={e.username} key={i}>{e.username}</option>
                        ))}
                    </select>
                </div>
                <div className="settings-form-input">
                    <label htmlFor="client">Client:</label>
                    <select name='client' autoComplete='off' value={configureForm.Taboola.client} onChange={e => handleOnChange(e)}>
                        <option value="" disabled>--- Choose ---</option>
                        <option value="All">All Clients</option>
                        <option value="Adcombo">Adcombo</option>
                        <option value="Ads">Ads</option>
                        <option value="Crossroads">Crossroads</option>
                        <option value="Sedo">Sedo</option>
                        <option value="System1">System1</option>
                        <option value="Tonic">Tonic</option>
                    </select>
                </div>
                <button className="settings-form-button" onClick={e => handleConfigureFormSubmit(e)} style={{ background: '#4caf50' }} >
                    {configureForm.loading ? (
                        <div className="loader"></div>
                    ) : 'SUBMIT'}
                </button>
            </>
        } else if (configureForm.traffic === 'Outbrain') {
            result = <>
                <div className="settings-form-input">
                    <label htmlFor="ID">Outbrain ID:</label>
                    <input name='ID' type="text" placeholder='ID' autoComplete='off' value={configureForm.Outbrain.ID} onChange={e => handleOnChange(e)} />
                </div>
                <div className="settings-form-input">
                    <label htmlFor="username">Outbrain Username:</label>
                    <input name='username' type="text" placeholder='username' autoComplete='off' value={configureForm.Outbrain.username} onChange={e => handleOnChange(e)} />
                </div>
                <div className="settings-form-input">
                    <label htmlFor="user">User:</label>
                    <select name='user' autoComplete='off' value={configureForm.Outbrain.user} onChange={e => handleOnChange(e)}>
                        <option value="" disabled>--- Choose ---</option>
                        {users.filter(f => f.role === 'manager').map((e, i) => (
                            <option value={e.username} key={i}>{e.username}</option>
                        ))}
                    </select>
                </div>
                <div className="settings-form-input">
                    <label htmlFor="client">Client:</label>
                    <select name='client' autoComplete='off' value={configureForm.Outbrain.client} onChange={e => handleOnChange(e)}>
                        <option value="" disabled>--- Choose ---</option>
                        <option value="All">All Clients</option>
                        <option value="Adcombo">Adcombo</option>
                        <option value="Ads">Ads</option>
                        <option value="Crossroads">Crossroads</option>
                        <option value="Sedo">Sedo</option>
                        <option value="System1">System1</option>
                        <option value="Tonic">Tonic</option>
                    </select>
                </div>
                <button className="settings-form-button" onClick={e => handleConfigureFormSubmit(e)} style={{ background: '#4caf50' }} >
                    {configureForm.loading ? (
                        <div className="loader"></div>
                    ) : 'SUBMIT'}
                </button>
            </>
        }

        return result
    }

    // determine which filters and buttons to display on top
    const getTopSettings = () => {
        let result = <></>

        if (global.displayTable === 'accounts') {
            result = (
                <div className='settings-top'>
                    <div className="settings-top-box">
                        <label>Filter By User</label>
                        <select value={global.filterAcUser} onChange={e => setGlobal(p => {return{...p, filterAcUser: e.target.value}}) }>
                            {users.filter(f => f.role === 'manager').map((e, i) => (
                                <option value={e.username} key={i}>{e.username}</option>
                            ))}
                        </select>
                    </div>
                    <div className="settings-top-box">
                        <label>Filter By Client</label>
                        <select value={global.filterAcClient} onChange={e => setGlobal(p => {return{...p, filterAcClient: e.target.value}})}>
                            <option value="">All</option>
                            <option value="All">All Clients</option>
                            <option value="Adcombo">Adcombo</option>
                            <option value="Ads">Ads</option>
                            <option value="Crossroads">Crossroads</option>
                            <option value="Sedo">Sedo</option>
                            <option value="System1">System1</option>
                            <option value="Tonic">Tonic</option>
                        </select>
                    </div>
                    <div className="settings-top-box" style={{ maxWidth: 'fit-content' }}>
                        <button style={{ background: '#4caf50' }} onClick={() => setGlobal(p => { return { ...p, displayConfigureForm: true } })}><FaPlus size={20}/>Register Account</button>
                    </div>
                    <div className="settings-top-box" style={{ maxWidth: 'fit-content' }}>
                        <button style={{ background: '#FF9900' }} onClick={() => setGlobal(p => { return { ...p, displayFacebookTokenForm: true } })}><MdSecurity size={20}/>FB Token</button>
                    </div>
                </div>
            )
        } else if (global.displayTable === 'facebook-fan-pages') {
            result = (
                <div className='settings-top'>
                    <div className="settings-top-box">
                        <label>Filter By User</label>
                        <select value={global.filterFanUser} onChange={e => setGlobal(p => {return{...p, filterFanUser: e.target.value}}) }>
                            <option value=''>All</option>
                            {users.filter(f => f.role === 'manager').map((e, i) => (
                                <option value={e.username} key={i}>{e.username}</option>
                            ))}
                        </select>
                    </div>
                    <div className="settings-top-box" style={{ maxWidth: 'fit-content' }}>
                        <button style={{ background: '#4caf50' }} onClick={() => setGlobal(p => { return { ...p, displayFanPageForm: true } })}><FaPlus size={20}/>Register Facebook Fan Page</button>
                    </div>
                </div>
            )
        } else if (global.displayTable === 'users') {
            result = (
                <div className='settings-top'>
                    <div className="settings-top-box" style={{ maxWidth: 'fit-content' }}>
                        <button style={{ background: '#4caf50' }} onClick={e => { e.preventDefault(); setGlobal(p => {return{...p, displayUserForm: true}}) }}><FaUserPlus size={20}/>Create User</button>
                    </div>
                </div>
            )
        }

        return result
    }

    // determine which table to load
    const getTable = () => {
        let result = <></>

        if (global.displayTable === 'accounts') {
            result = (
                <div className='settings-table'>
                    <table>
                        <thead>
                            <tr style={{ background: '#D7ECFB', top: 0 }}>
                                <th style={{ minWidth: '80px', textAlign: 'center' }}>Action</th>
                                <th>Name</th>
                                <th>Traffic</th>
                                <th>Client</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(tableData.filter(f => (global.filterAcClient === '') ? true : (f.client === global.filterAcClient)).length === 0) ? (
                                <tr style={{ background: '#DBF2F2' }}>
                                    <td style={{ minWidth: '80px', textAlign: 'center' }}>---No Row---</td>
                                    <td>---No Row---</td>
                                    <td>---No Row---</td>
                                    <td>---No Row---</td>
                                </tr>
                            ) : (
                                <>
                                    {tableData.filter(f => (global.filterAcClient === '') ? true : (f.client === global.filterAcClient)).map((e, i) => (
                                        <tr key={i} style={{ background: '#DBF2F2' }}>
                                            <td style={{ minWidth: '80px', textAlign: 'center' }}>
                                                <FaTrashAlt size={24} color={'#ef4444'} style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        if (window.confirm(`Confirm to delete ${findAccountName(e)} !`)) {
                                                            let accountID = ''
                                                            if (e.ID) {
                                                                accountID = e.ID
                                                            } else if (e.FB_id) {
                                                                accountID = e.FB_id
                                                            } else if (e.outbrain_id) {
                                                                accountID = e.outbrain_id
                                                            } else if (e.client_ID) {
                                                                accountID = e.client_ID
                                                            } else if (e.id) {
                                                                accountID = e.id
                                                            } else {
                                                                accountID = e.accountID
                                                            }
                                                            handleDelete({ type: 'Traffic', data_to_send: { FB_id: accountID } })
                                                        } else {
                                                            return
                                                        }
                                                    }}
                                                />
                                            </td>
                                            <td>{findAccountName(e)}</td>
                                            <td>{e.traffic}</td>
                                            <td>{e.client}</td>
                                        </tr>
                                    ))}
                                </>
                            )}
                        </tbody>
                    </table>
                </div>    
            )
        } else if (global.displayTable === 'facebook-fan-pages') {
            result = (
                <div className='settings-table'>
                    <table>
                        <thead>
                            <tr style={{ background: '#D7ECFB' }}>
                                <th style={{ minWidth: '80px', textAlign: 'center' }}>Action</th>
                                <th>Fan Page</th>
                                <th>User</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(facebookFanPages.filter(f => (global.filterFanUser === '') ? true : (f.user === global.filterFanUser)).length === 0) ? (
                                <tr style={{ background: '#DBF2F2' }}>
                                    <td style={{ minWidth: '80px', textAlign: 'center' }}>---No Row---</td>
                                    <td>---No Row---</td>
                                    <td>---No Row---</td>
                                </tr>
                            ) : (
                                <>
                                    {facebookFanPages.filter(f => (global.filterFanUser === '') ? true : (f.user === global.filterFanUser)).map((e, i) => (
                                        <tr key={i} style={{ background: '#DBF2F2' }}>
                                            <td className='settings-table-action' style={{ minWidth: '80px' }}>
                                                <div style={{ background: '#ef4444' }} onClick={() => (window.confirm(`Confirm to delete ${e.name} !`)) ? handleDelete({ type: 'FacebookPage', data_to_send: { id: e.id } }) : ''}><FaTrashAlt size={16} color={'#FFF'}/></div>
                                            </td>
                                            <td>{e.name}</td>
                                            <td>{e.user}</td>
                                        </tr>
                                    ))}
                                </>
                            )}
                        </tbody>
                    </table>
                </div> 
            )
        } else if (global.displayTable === 'users') {
            result = (
                <div className='settings-table'>
                    <table>
                        <thead>
                            <tr style={{ background: '#D7ECFB' }}>
                                <th style={{ minWidth: '80px', textAlign: 'center' }}>Action</th>
                                <th>User Name</th>
                                <th>User Role</th>
                                <th>User Permissions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(users.length === 0) ? (
                                <tr style={{ background: '#DBF2F2' }}>
                                    <td style={{ minWidth: '80px', textAlign: 'center' }}>---No Row---</td>
                                    <td>---No Row---</td>
                                    <td>---No Row---</td>
                                    <td>---No Row---</td>
                                </tr>
                            ) : (
                                <>
                                    {users.map((e, i) => (
                                        <tr key={i} style={{ background: (e.role === 'manager') ? '#DBF2F2' : '#FFF6ED' }}>
                                            {(userEdit.user === e.username) ? (
                                                userEdit.loading ? (
                                                    <td className='settings-table-action' style={{ minWidth: '80px' }}>
                                                        <div className='loader' style={{ height: '30px', width: '30px', borderRadius: '100%' }}/>
                                                    </td>
                                                ) : (
                                                    <td className='settings-table-action' style={{ minWidth: '80px', gap: '5px' }}>
                                                        <div style={{ background: '#4caf50' }} onClick={() => handleUserPermissionUpdate()}><FaCheck size={16} color={'#FFF'}/></div>
                                                        <div style={{ background: '#ef4444' }} onClick={() => setUserEdit(p => { return { ...p, user: '', pages: [] } })}><FaXmark size={16} color={'#FFF'}/></div>
                                                    </td>
                                                )
                                            ) : (
                                                <td className='settings-table-action' style={{ minWidth: '80px', gap: '5px' }}>
                                                    <div style={{ background: '#4caf50' }} onClick={() => setUserEdit(p => { return { ...p, user: e.username, pages: e.pages ? e.pages : [] } })}><FaEdit size={16} color={'#FFF'}/></div>
                                                    <div style={{ background: '#ef4444' }} onClick={() => (window.confirm(`Confirm to delete ${e.username} !`)) ? handleDelete({ type: 'user', data_to_send: { username: e.username } }) : ''}><FaTrashAlt size={16} color={'#FFF'}/></div>
                                                </td>
                                            )}

                                            <td style={{ verticalAlign: (userEdit.user === e.username) ? 'baseline' : 'middle' }}>{e.username}</td>
                                            <td style={{ verticalAlign: (userEdit.user === e.username) ? 'baseline' : 'middle' }}>{e.role} {(e.role === 'manager') ? '' : (e.manager ? `( ${e.manager} )` : e.manager) }</td>
                                            
                                            {(userEdit.user === e.username) ? (
                                                <td>
                                                    <div className="settings-form-input">
                                                        <div style={{ position: 'relative', height: 'fit-content', width: '100%', flexDirection: 'column', padding: '0px' }}>
                                                            <div style={{ paddingLeft: '5px', height: '40px', width: '100%', justifyContent: 'flex-start' }}>{userEdit.pages.length} / {Object.values(access_pages_list).flat().length} pages</div>
                                                            <div className='settings-form-absolute-picker' style={{ height: "fit-content", maxHeight: "250px", width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                                                <div className='settings-form-absolute-picker-menu'>
                                                                    <div style={{ background: '#4caf50'}} onClick={() => setUserEdit(p => { return { ...p, pages: [...Object.values(access_pages_list).flat()] }} )}>Select All</div>
                                                                    <div style={{ background: '#FF5F5F'}} onClick={() => setUserForm(p => { return {...p, pages: [] }})}>Clear All</div>
                                                                </div>
                                                                {Object.keys(access_pages_list).map((e, index) => (
                                                                    <div key={index} style={{ height: 'fit-content', width: '100%', padding: '0px 5px 5px 5px' }}>
                                                                        <div style={{ height: 'fit-content', width: '100%', background: '#EEE', display: 'flex', flexDirection: 'column', padding: '5px', borderRadius: '5px'  }}>
                                                                            <p style={{ fontWeight: '600' }}>{e}</p>
                                                                            {access_pages_list[e].map((item, i) => (
                                                                                <div key={i} className='settings-form-absolute-picker-item'
                                                                                    onClick={() => {
                                                                                        const find = userEdit.pages.find(f => f === item)
                                                                                        setUserEdit(p => {
                                                                                            return {
                                                                                                ...p,
                                                                                                pages: find ? p.pages.filter(f => f !== item) : [...p.pages, item]
                                                                                            }}
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    <input type="checkbox" checked={userEdit.pages.find(f => f === item) ? true : false} readOnly={true} style={{ height: '15px', width: '15px', pointerEvents: 'none' }}/>
                                                                                    <div style={{ paddingLeft: '5px', fontSize: '14px' }}>{item.split('-').map(m => m[0].toUpperCase()+m.slice(1, m.length)).join(' ')}</div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            ) : (
                                                <td>{e.pages ? e.pages.length + " / " + Object.values(access_pages_list).flat().length : "0 / " + Object.values(access_pages_list).flat().length} <sup style={{ whiteSpace: 'normal', display: 'inline', fontSize: '12px', fontStyle: '#AAA' }}>pages</sup></td>
                                            )}
                                        </tr>
                                    ))}
                                </>
                            )}
                        </tbody>
                    </table>
                </div>
            )
        }

        return result
    }

    return (
        <div className="settings-container" style={{ position: 'relative' }}>
            {/* loading */}
            {(loading || userLoading) ? (
                <div className='settings-absolute' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="loader" style={{ height: '200px', width: '200px' }}></div>
                </div>
            ) : (<></>)}

            {/* configure settings form */}
            {global.displayConfigureForm ? (
                <div className="settings-absolute">
                    <div className='settings-form-container'>
                        <div className="settings-form">
                            <div className="settings-form-title">
                                <p>Configure A/C</p>
                                <div style={{ height: '40px', width: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', cursor: 'pointer' }}
                                    onMouseEnter={e => e.currentTarget.style.background = 'rgba(239, 68, 68, 0.1)'}
                                    onMouseLeave={e => e.currentTarget.style.background = '#FFF'}
                                    onClick={e => {
                                        e.preventDefault();
                                        setGlobal(p => { return { ...p, displayConfigureForm: false } })
                                        setConfigureForm(p => { return {
                                            ...p,
                                            Taboola: { ...p.Taboola, ID: '', SECRET: '', accountID: '', user: '', client: '' },
                                            Facebook: { ...p.Facebook, act_id: '', act_name: '', pixels_id: '', user: '', client: '' },
                                            TikTok: { ...p.TikTok, app_id: '', act_id: '', act_name: '', user: '', client: '' },
                                            Outbrain: { ...p.Outbrain, ID: '', username: '', user: '', client: '' },
                                        }})
                                    }}
                                >
                                    <FaXmark size={24} color={'#ef4444'} style={{ cursor: 'pointer' }} />
                                </div>
                            </div>
                            <div className="settings-form-input">
                                <label htmlFor="traffic">Select Traffic:</label>
                                <select name="traffic" value={configureForm.traffic} onChange={e => {e.preventDefault(); setConfigureForm(p => {return{...p, traffic: e.target.value}});}} >
                                    <option value="Facebook">Facebook</option>
                                    <option value="TikTok">TikTok</option>
                                    <option value="Taboola">Taboola</option>
                                    <option value="Outbrain">Outbrain</option>
                                    {/* <option value="Mgid">Mgid</option> */}
                                </select>
                            </div>

                            {getForm()}
                        </div>
                    </div>
                </div>
            ) : (<></>)}

            {/* create new fan page form */}
            {global.displayFanPageForm ? (
                <div className='settings-absolute'>
                    <div className='settings-form-container'>
                        <div className="settings-form">
                            <div className="settings-form-title">
                                <p>Add Facebook Fan Page</p>
                                <div style={{ height: '40px', width: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', cursor: 'pointer' }}
                                    onMouseEnter={e => e.currentTarget.style.background = 'rgba(239, 68, 68, 0.1)'}
                                    onMouseLeave={e => e.currentTarget.style.background = '#FFF'}
                                    onClick={() => {
                                        setGlobal(p => { return { ...p, displayFanPageForm: false } })
                                        setFanPageForm({
                                            loading: false,
                                            details: { id: '', name: '', user: '' },
                                            error: { state: false, message: '' }
                                        })
                                    }}
                                >
                                    <FaXmark size={24} color={'#ef4444'}/>
                                </div>
                            </div>
                            {(fanPageForm.error.state) ? (<p style={{ fontSize: '12px', color: '#ef4444' }}>{fanPageForm.error.message}</p>) : (<></>)}
                            <div className="settings-form-input">
                                <label>Fan Page ID:</label>
                                <input type="text" placeholder='0123456789' autoComplete='off' value={fanPageForm.details.id} onChange={e => setFanPageForm(p => { return { ...p, details: { ...p.details, id: e.target.value }}}) } />
                            </div>
                            <div className="settings-form-input">
                                <label>Fan Page Name:</label>
                                <input type="text" placeholder='Search Insights' autoComplete='off' value={fanPageForm.details.name} onChange={e => setFanPageForm(p => { return { ...p, details: { ...p.details, name: e.target.value }}}) } />
                            </div>
                            <div className="settings-form-input" style={{ marginBottom: '10px' }}>
                                <label>Assign to User:</label>
                                <select value={fanPageForm.details.user} onChange={e => setFanPageForm(p => { return { ...p, details: { ...p.details, user: e.target.value }}}) } >
                                    <option value="" disabled>--- Choose ---</option>
                                    {users.filter(f => f.role === 'manager').map((e, i) => (
                                        <option value={e.username} key={i}>{e.username}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="settings-form-button" onClick={() => handleFanPageSubmit()}
                                style={{
                                    pointerEvents: (fanPageForm.loading || (fanPageForm.details.name.trim() === '') || (fanPageForm.details.user.trim() === '')) ? 'none' : 'all',
                                    background: (fanPageForm.loading || (fanPageForm.details.name.trim() === '') || (fanPageForm.details.user.trim() === '')) ? '#AAA' : '#4caf50'
                                }}
                            >
                                {fanPageForm.loading ? (
                                    <div className="loader"></div>
                                ) : 'SUBMIT'}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (<></>)}

            {/* create new user form */}
            {global.displayUserForm ? (
                <div className='settings-absolute'>
                    <div className='settings-form-container'>
                        <div className="settings-form">
                            <div className="settings-form-title">
                                <p>Create User</p>
                                <div style={{ height: '40px', width: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', cursor: 'pointer' }}
                                    onMouseEnter={e => e.currentTarget.style.background = 'rgba(239, 68, 68, 0.1)'}
                                    onMouseLeave={e => e.currentTarget.style.background = '#FFF'}
                                    onClick={e => {
                                        e.preventDefault();
                                        setGlobal(p => { return { ...p, displayUserForm: false } })
                                        setUserForm({
                                            loading: false,
                                            details: { username: '', password: '', role: '', manager: '', pages: [] },
                                            disableButton: true,
                                            showPassword: false,
                                            error: { state: false, message: '' }
                                        })
                                    }}
                                >
                                    <FaXmark size={24} color={'#ef4444'} />
                                </div>
                            </div>
                            {(userForm.error.state) ? (<p style={{ fontSize: '12px', color: '#ef4444' }}>{userForm.error.message}</p>) : (<></>)}
                            <div className="settings-form-input">
                                <label htmlFor="username">Username:</label>
                                <input name='username' type="text" placeholder='John' autoComplete='off' value={userForm.details.username} onChange={e => {e.preventDefault(); setUserForm(p => {return{...p, details: { ...p.details, username: e.target.value }}});}} />
                            </div>
                            <div className="settings-form-input">
                                <label htmlFor="password">Password:</label>
                                <div>
                                    <input name='password' type={userForm.showPassword ? 'text' : 'password'} placeholder='******' autoComplete='off' value={userForm.details.password} onChange={e => {e.preventDefault(); setUserForm(p => {return{...p, details: { ...p.details, password: e.target.value }}});}} />
                                    <div>
                                        {userForm.showPassword ? (
                                            <FaEyeSlash size={24} color={'#AAAAAA'} onClick={e => { e.preventDefault(); setUserForm(p => {return{...p, showPassword: false}}); }} />
                                        ) : (
                                            <FaEye size={24} color={'#AAAAAA'} onClick={e => { e.preventDefault(); setUserForm(p => {return{...p, showPassword: true}}); }} />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="settings-form-input" style={{ marginBottom: '10px' }}>
                                <label htmlFor="role">Role:</label>
                                <select name="role" value={userForm.details.role} onChange={e => {e.preventDefault(); setUserForm(p => {return{...p, details: { ...p.details, role: e.target.value }}});}} >
                                    <option value="" disabled>--- Choose ---</option>
                                    <option value="manager">Manager</option>
                                    <option value="widget">Widget</option>
                                </select>
                            </div>
                            {userForm.details.role === 'widget' ? (
                                <div className="settings-form-input" style={{ marginBottom: '10px' }}>
                                    <label htmlFor="role">Manager:</label>
                                    <select name="role" value={userForm.details.manager} onChange={e => {e.preventDefault(); setUserForm(p => {return{...p, details: { ...p.details, manager: e.target.value }}});}} >
                                        <option value="" disabled>--- Choose ---</option>
                                        <option value="admin">ALL</option>
                                        {users.filter(f => f.role === 'manager').map((e, i) => (
                                            <option value={e.username} key={i}>{e.username}</option>
                                        ))}
                                    </select>
                                </div>
                            ) : (<></>)}
                            <div className="settings-form-input">
                                <label htmlFor="username">User Access:</label>
                                <div style={{ position: 'relative', height: 'fit-content', width: '100%', flexDirection: 'column', padding: '0px' }}>
                                    <div style={{ paddingLeft: '5px', height: '40px', width: '100%', justifyContent: 'flex-start' }}>{userForm.details.pages.length} / {Object.values(access_pages_list).flat().length} pages</div>
                                    <div className='settings-form-absolute-picker' style={{ height: "fit-content", maxHeight: "250px", width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                        <div className='settings-form-absolute-picker-menu'>
                                            <div style={{ background: '#4caf50'}}
                                                onClick={() => {
                                                    const temp_pages = Object.values(access_pages_list).flat()
                                                    setUserForm(p => {
                                                        return {
                                                            ...p,
                                                            details: {
                                                                ...p.details,
                                                                pages: [...temp_pages]
                                                            }
                                                        }}
                                                    )
                                                }}
                                            >
                                                Select All
                                            </div>
                                            <div style={{ background: '#FF5F5F'}}
                                                onClick={() => {
                                                    setUserForm(p => {
                                                        return {
                                                            ...p,
                                                            details: {
                                                                ...p.details,
                                                                pages: []
                                                            }
                                                        }}
                                                    )
                                                }}
                                            >
                                                Clear All
                                            </div>
                                        </div>
                                        {Object.keys(access_pages_list).map((e, i) => (
                                            <div key={i} style={{ height: 'fit-content', width: '100%', padding: '0px 5px 5px 5px' }}>
                                                <div style={{ height: 'fit-content', width: '100%', background: '#EEE', display: 'flex', flexDirection: 'column', padding: '5px', borderRadius: '5px'  }}>
                                                    <p style={{ fontWeight: '600' }}>{e}</p>
                                                    {access_pages_list[e].map((item, i) => (
                                                        <div key={i} className='settings-form-absolute-picker-item'
                                                            onClick={() => {
                                                                const find = userForm.details.pages.find(f => f === item)
            
                                                                setUserForm(p => {
                                                                    return {
                                                                        ...p,
                                                                        details: {
                                                                            ...p.details,
                                                                            pages: find ? p.details.pages.filter(f => f !== item) : [...p.details.pages, item]
                                                                        }
                                                                    }}
                                                                )
                                                            }}
                                                        >
                                                            <input type="checkbox" checked={userForm.details.pages.find(f => f === item) ? true : false} readOnly={true} style={{ height: '15px', width: '15px', pointerEvents: 'none' }}/>
                                                            <div style={{ paddingLeft: '5px', fontSize: '14px' }}>{item.split('-').map(m => m[0].toUpperCase()+m.slice(1, m.length)).join(' ')}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <button className="settings-form-button" onClick={e => handleUserFormSubmit(e)} style={{ pointerEvents: (userForm.disableButton || userForm.loading) ? 'none' : 'all', background: (userForm.disableButton || userForm.loading) ? '#AAA' : '#4caf50' }} >
                                {userForm.loading ? (
                                    <div className="loader"></div>
                                ) : 'SUBMIT'}
                            </button>
                        </div>
                    </div>
                </div>
            ) : (<></>)}

            {/* create new user form */}
            {global.displayFacebookTokenForm ? (
                <div className='settings-absolute'>
                    <div className='settings-form-container'>
                        <div className="settings-form">
                            <div className="settings-form-title">
                                <p>Change Facebook Token</p>
                                <div style={{ height: '40px', width: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', cursor: 'pointer' }}
                                    onMouseEnter={e => e.currentTarget.style.background = 'rgba(239, 68, 68, 0.1)'}
                                    onMouseLeave={e => e.currentTarget.style.background = '#FFF'}
                                    onClick={e => {
                                        e.preventDefault();
                                        setGlobal(p => { return { ...p, displayFacebookTokenForm: false } })
                                        setFacebookTokenForm({ loading: false, token: '' })
                                    }}
                                >
                                    <FaXmark size={24} color={'#ef4444'} />
                                </div>
                            </div>
                            <div className="settings-form-input">
                                <input type="text" placeholder='token' autoComplete='off' value={facebookTokenForm.token} onChange={e => setFacebookTokenForm(p => { return { ...p, token: e.target.value } })} />
                            </div>
                            
                            <button className="settings-form-button" onClick={e => handleChangeFacebookToken()} style={{ pointerEvents: (!facebookTokenForm.token.trim() || facebookTokenForm.loading) ? 'none' : 'all', background: (!facebookTokenForm.token.trim() || facebookTokenForm.loading) ? '#AAA' : '#4caf50' }} >
                                {facebookTokenForm.loading ? (
                                    <div className="loader"></div>
                                ) : 'SUBMIT'}
                            </button>
                        </div>
                    </div>
                </div>
            ) : (<></>)}

            {/* settings dashboard */}
            <div className="settings">
                {/* title and change button */}
                <div className='settings-title'>
                    <p>Settings</p>
                    <div style={{ background: (global.displayTable === 'accounts') ? '#1976d2' : '#AAA' }} onClick={() => setGlobal(p => { return { ...p, displayTable: 'accounts' } })}>Traffic</div>
                    <div style={{ background: (global.displayTable === 'facebook-fan-pages') ? '#1976d2' : '#AAA' }} onClick={() => setGlobal(p => { return { ...p, displayTable: 'facebook-fan-pages' } })}>FB Fan Pages</div>
                    <div style={{ background: (global.displayTable === 'users') ? '#1976d2' : '#AAA' }} onClick={() => setGlobal(p => { return { ...p, displayTable: 'users' } })}>Users</div>
                </div>

                {/* selection part */}
                {getTopSettings()}

                {/* table to display user list */}
                {getTable()}
            </div>
        </div>
    )
}

export default Settings