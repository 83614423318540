import React, { useState, useEffect, Fragment, useContext } from 'react'
import './widget.css'
import moment from 'moment';
import axios from 'axios';
import Cookies from 'js-cookie';
import { CSVLink } from 'react-csv'
import { FaEdit, FaCopy, FaChevronLeft, FaChevronRight, FaSearch, FaTrashAlt, FaEye, FaEyeSlash } from 'react-icons/fa'
import { FaXmark, FaCommentDots } from 'react-icons/fa6'
import { MdCancel, MdCheckCircle, MdCampaign, MdEdit } from 'react-icons/md'
import { GiHandOk } from "react-icons/gi";

import noImage from '../../assets/noImage.png'
import { host } from '../../hooks/host';
import useFetchWidget from '../../hooks/useFetchWidget';
import useFetchUsers from '../../hooks/useFetchUsers';
import { Context } from '../../AppContext';

const Widget = () => {
    const appContext = useContext(Context)
    const default_name_ACK = ["1.1", "1.2", "1.3", "2.1", "2.2", "2.3", "3.1", "3.2", "3.3"]

    // change pages
    const [pageOptions, setPageOptions] = useState({ from: 0, to: 250, current: 0, display_next: true, total_length: 100 })
    // add new campaign
    const [addDetail, setAddDetail] = useState({ country: '', topic: '', fb_id: '', client: '', url: '', note: '', categories: [], daily_budget: '1', headline: ["","","","",""], picture: ["","","","",""], ad_media: [{},{},{},{},{}], message: ["","","","",""], comment: "", adset_count: '5', facebook_fan_page: '', instagram_account: '', case: 'Ads Creation' })
    const [addPhaseURL, setAddPhaseURL] = useState({ listURL: [], display: false })
    const [addSelectDisplay, setAddSelectDisplay] = useState('')
    const [uploadFbImage, setUploadFbImage] = useState({ loading: false, act_id: '', refresh: false })
    const [countryField, setCountryField] = useState({ displayWidget: 0, userInput: '', displayRegion: false })
    // update row campaign
    const [updateDetail, setUpdateDetail] = useState({ widget: Number, country: '', topic: '', fb_id: '', client: '', url: '', keyword: [], final_url: '',  note: '', categories: [], daily_budget: '1', headline: ["","","","",""], picture: ["","","","",""], ad_media: [{},{},{},{},{}], message: ["","","","",""], comment: "", adset_count: '5', facebook_fan_page: '', instagram_account: '', case: '' })
    const [updateLoading, setUpdateLoading] = useState(false)
    const [updateSelectDisplay, setUpdateSelectDisplay] = useState('')
    const [bulkUpdate, setBulkUpdate] = useState({ loading: false, display_list: false, keyword_error: [], duplicate_url: [], list: {}, open_drop_down: '', display_multi_delete: false, clear_list: true })
    // select search option
    const [selectedSearchOption, setSelectedSearchOption] = useState({ type: 'widget', input: '', result: [] })
    // set widget id to know exactly which id data is to be updated right now
    const [selectedRow, setSelectedRow] = useState({ widget: null, keyword: null, final_url: null, note: null, url: null, country: null, message: null, picture: null, comment: null })
    // refresh every time there is an update
    const [refresh, setRefresh] = useState(false)
    const [refreshUnusedDomains, setRefreshUnusedDomains] = useState(false)
    // select which client data you want filtered
    const [selectedClient, setSelectedClient] = useState('All')
    const [filteredData, setFilteredData] = useState([])
    const [filteredDataToMap, setFilteredDataToMap] = useState([])
    // url list
    const [list, setList] = useState([])
    const [displayErrorURL, setDisplayErrorURL] = useState([])
    // for admin only select username
    const [filterUser, setFilterUser] = useState('Southpow')
    const [reActCampaigns, setReActCampaigns] = useState({ loading: false, count: 0 })
    // to display facebook image or video selection component
    const [fbMediaState, setFbMediaState] = useState({ display: false, mode: '', act_id: '', widget: 0 })

    const fetchUsers = useFetchUsers()
    const [userLoading, users] = fetchUsers
    
    const fetch = useFetchWidget({ refresh: refresh, refreshUnusedDomains: refreshUnusedDomains, page: pageOptions.current, setPageOptions: setPageOptions, filterUser, uploadFbImage, users: users, setBulkUpdate: setBulkUpdate })
    const [loading, data] = fetch

    const facebook_fan_pages = ((Cookies.get('role') === 'admin') || (Cookies.get('manager') === 'admin') || (Cookies.get('userArray') === 'LFCWidget')) ?  data.fb_fan_pages.filter(f => f.user === filterUser) : data.fb_fan_pages

    // fetch off campaigns that will turn on tomorrow
    useEffect(() => {
        const controller = new AbortController();
        const token = Cookies.get('token')
        const url = `${host}/api/bidding/V2/fetch_off_campaign`;
        let count = 0

        const getData = async ({ traffic, minusDate }) => {
            setReActCampaigns(p => { return { ...p, loading: true } })
            await axios.post(url, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                    "Authorization": "Bearer "+token,
                    "Content-Type": "application/json",
                },
                traffic: traffic,
                username: [filterUser],
                lastDate: moment().utc().add(8, 'hours').subtract(minusDate, 'days').format('YYYY-MM-DD')
            },
            {
                signal: controller.signal
            })
            .then((response) => {
                const obj = response.data
                const temp = obj?.result?.autoTurnOnCampaignList?.filter(f => f?.turnOnDate === moment().utc().add(32, 'hours').format('YYYY-MM-DD'))

                if (Array.isArray(temp)) {
                    count = count + temp.length
                }
            })
            .catch((error) => {
                console.log(error);
                if (error?.response?.data?.message === 'Invalid') {
                    appContext.forceLogout()
                }
            })
        }

        const getCount = async () => {
            await getData({ traffic: 'sedo_facebook', minusDate: 2 })
            await getData({ traffic: 'system1_facebook', minusDate: 2 })
            await getData({ traffic: 'tonic_facebook', minusDate: 2 })
            await getData({ traffic: 'ads_facebook', minusDate: 2 })
            await getData({ traffic: 'adcombo_facebook', minusDate: 2 })
            await getData({ traffic: 'crossroads_facebook', minusDate: 2 })
            setReActCampaigns({ loading: false, count: count })
        }

        getCount()

        return () => {
            controller.abort()
        }
    }, [])

    // clean state when user changed
    useEffect(() => {
        setAddDetail({ country: '', topic: '', fb_id: '', client: '', url: '', note: '', categories: [], daily_budget: '1', headline: ["","","","",""], picture: ["","","","",""], ad_media: [{},{},{},{},{}], message: ["","","","",""], comment: "", adset_count: '5', facebook_fan_page: '', instagram_account: '', case: 'Ads Creation' })
        setUpdateDetail({ widget: Number, country: '', topic: '', fb_id: '', client: '', url: '', keyword: [], final_url: '',  note: '', categories: [], daily_budget: '1', headline: ["","","","",""], picture: ["","","","",""], ad_media: [{},{},{},{},{}], message: ["","","","",""], comment: "", adset_count: '5', facebook_fan_page: '', instagram_account: '', case: '' })
    }, [filterUser])

    // filter table data by client
    useEffect(() => {
        let temp = [...data.widget]
        let result = []

        if (selectedClient === 'All') {
            result = temp
        } else {
            result = temp.filter(f => f.client === selectedClient)
        }

        if (data.fb) {
            if ((Cookies.get('role') === 'admin') || (Cookies.get('manager') === 'admin') || (Cookies.get('userArray') === 'LFCWidget')) {
                setFilteredData(result.sort((a, b) => b.widget - a.widget).map(e => { return { ...e, fb_id: (e.fb_id === 'email') ? 'email' : data.fb.find(f => (f.pixels_id === e.fb_id || f.id === e.fb_id) && (f.client === e.client))?.unique } }).filter(f => f.username === filterUser))
            } else {
                setFilteredData(result.sort((a, b) => b.widget - a.widget).map(e => { return { ...e, fb_id: (e.fb_id === 'email') ? 'email' : data.fb.find(f => (f.pixels_id === e.fb_id || f.id === e.fb_id) && (f.client === e.client))?.unique } }))
            }
        }

        // if (document.getElementById('widget-table-container-scroll')) {
        //     document.getElementById('widget-table-container-scroll').scrollTop = 0
        // }
    }, [selectedClient, filterUser, data.widget, data.fb ])

    // filter unused domains options when topic is changed at ADD Phase
    useEffect(() => {
        let copy = [...data.unusedDomains]
        let temp = []
        addDetail.topic.split(' ')
            .map(m => copy
                .map(e => e.topic.toLowerCase().includes(m.toLowerCase()) ? temp.push(e) : ''))

        setAddPhaseURL(p => { return { ...p, listURL: Array.from(new Set(temp.map(e => e._id))).map(m => copy.find(f => f._id === m))}});
    }, [addDetail.topic])

    // filter unused domains options when topic is changed at UPDATE Phase
    useEffect(() => {
        let copy = [...data.unusedDomains]
        let temp = []
        updateDetail.topic.split(' ')
            .map(m => copy
                .map(e => e.topic.toLowerCase().includes(m.toLowerCase()) ? temp.push(e) : ''))

        setList(
            Array.from(new Set(temp
            .map(e => e._id)))
            .map(m => copy.find(f => f._id === m))
        );
    }, [updateDetail.topic])

    // set table data when page change
    useEffect(() => {
        setFilteredDataToMap(filteredData.slice(pageOptions.from, pageOptions.to))
    }, [filteredData, pageOptions])

    // api to create a brand new campaign
    const newCampaignAPI = (cond) => {
        setUpdateLoading(true)
        const token = Cookies.get("token");
        const manager = Cookies.get("manager");
        const url = `${host}/api/index/create`;

        const dataToSend = {
            finished: false,
            topic: addDetail.topic,
            fb_id: data?.fb?.find(f => f?.unique === addDetail.fb_id)?.id,
            client: addDetail.client,
            country: addDetail.country,
            categories: addDetail.categories,
            daily_budget: addDetail.daily_budget,
            name: (addDetail.case === "Ads Creation (Keywords)") ? default_name_ACK : addDetail.picture.map((e, i) => `${i+1}`),
            headline: addDetail.headline,
            message: addDetail.message,
            comment: addDetail.comment,
            ad_media: addDetail.ad_media,
            picture: addDetail.picture,
            facebook_fan_page: addDetail.facebook_fan_page,
            instagram_account: "",
            note: addDetail.note,
            url: '',
            keyword: [],
            final_url: (addDetail.case === "Ads Creation (Keywords)") ? ['','',''] : '',
            case: addDetail.case,
            username: ((Cookies.get('role') === 'admin') || (Cookies.get('manager') === 'admin') || (Cookies.get('userArray') === 'LFCWidget')) ? filterUser : manager
        }

        axios.post(url, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "Content-Type, x-access-token",
              "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
              "Authorization": "Bearer "+token,
              "Content-Type": "application/json",
            },
            data: dataToSend
        })
        .then((response) => {
            setRefresh(p => !p)
            setAddDetail(p => { return { ...p, country: '', topic: '', fb_id: '', client: '', url: '', note: '', categories: [], daily_budget: '1', headline: ["","","","",""], picture: ["","","","",""], ad_media: [{},{},{},{},{}], message: ["","","","",""], comment: "", adset_count: '5', facebook_fan_page: '', instagram_account: '' } })
            setAddSelectDisplay('')
            setUpdateLoading(false)
        })
        .catch((error) => {
            appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Create failed", content: error }], confirm_function: () => {}, cancel_function: () => {} }})
            setAddDetail(p => { return { ...p, country: '', topic: '', fb_id: '', client: '', url: '', note: '', categories: [], daily_budget: '1', headline: ["","","","",""], picture: ["","","","",""], ad_media: [{},{},{},{},{}], message: ["","","","",""], comment: "", adset_count: '5', facebook_fan_page: '', instagram_account: '' } })
            setAddSelectDisplay('')
            setUpdateLoading(false)
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        })
    }

    // api to update campaign - used for changing state for delete purpose & for overall update
    const updateCampaignAPI = (detail) => {
        setUpdateLoading(true)
        const token = Cookies.get("token");
        const manager = Cookies.get("manager");
        const url = `${host}/api/index/update`;

        axios.post(url,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "Content-Type, x-access-token",
              "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
              "Authorization": "Bearer "+token,
              "Content-Type": "application/json",
            },
            data: { ...detail, username: ((Cookies.get('role') === 'admin') || (Cookies.get('manager') === 'admin') || (Cookies.get('userArray') === 'LFCWidget')) ? filterUser : manager }
          }
        )
        .then((response) => {
            setUpdateDetail({ widget: Number, country: '', topic: '', fb_id: '', client: '', url: '', keyword: [], final_url: '', note: '', categories: [], daily_budget: '1', headline: ["","","","",""], picture: ["","","","",""], ad_media: [{},{},{},{},{}], message: ["","","","",""], comment: "", adset_count: '5', facebook_fan_page: '', instagram_account: '', case: '' })
            setUpdateSelectDisplay('')
            setSelectedRow(p => { return { ...p, widget: null, keyword: null, final_url: null, note: null, url: null, country: null, message: null, picture: null }})
            setRefresh(p => !p)
            setUpdateLoading(false)
        })
        .catch((error) => {
            setUpdateDetail({ widget: Number, country: '', topic: '', fb_id: '', client: '', url: '', keyword: [], final_url: '', note: '', categories: [], daily_budget: '1', headline: ["","","","",""], picture: ["","","","",""], ad_media: [{},{},{},{},{}], message: ["","","","",""], comment: "", adset_count: '5', facebook_fan_page: '', instagram_account: '', case: '' })
            setUpdateSelectDisplay('')
            setUpdateLoading(false)
            appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Update failed", content: error }], confirm_function: () => {}, cancel_function: () => {} }})
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        })
    }
    
    // generate final URL
    const handleGenerateKeyword = async (row) => {
        const fd = { ...updateDetail }
        const tonic_url = document.getElementById('tonic_url_to_generate') ? document.getElementById('tonic_url_to_generate').value.replace(/\s+/g, "") : ''

        fd.keyword = fd.keyword
            .split('\n')
            .filter(f => f)
            .map(m => m.
                trim()
                .split(' ')
                .filter(fi => fi)
                .map(e => e.charAt(0).toUpperCase() + e.slice(1))
                .join(' '))

        if (fd.url.trim() !== '' && fd.url.trim() !== row.url) {
            setUpdateLoading(true)
            const check_url = await checkUrlAPI(fd.url?.trim())
            const check_url_v2 = data.unusedDomainsV2.filter(f => (f?.url?.trim() === fd?.url?.trim()) && !f.read)
            setUpdateLoading(false)
    
            if ((check_url !== false) && check_url.widget) {
                if (Cookies.get('role') === 'admin') {
                    let should_refresh = false
    
                    if (filteredDataToMap.find(f => f.widget === check_url.widget)) {
                        should_refresh = true
                    }

                    let find_fb_name = ''
                    const local_fb = window.localStorage.getItem('fbAccounts') ? JSON.parse(window.localStorage.getItem('fbAccounts')) : []
                    
                    if (data.fb.find(f => f.id === check_url.fb_id)) {
                        find_fb_name = data.fb.find(f => f.id === check_url.fb_id)?.name
                    } else if (Array.isArray(local_fb)) {
                        find_fb_name = local_fb.find(f => f.act_id === check_url.fb_id)?.act_name
                    }

                    appContext.setDialogInfo(p => {
                        return {
                            ...p,
                            mode: "Confirm",
                            type: "Delete",
                            message: [{
                                type: "Delete",
                                title: `Same url widget found. Delete old one?`,
                                content: find_fb_name ? `Widget "${check_url.widget}" from account "${find_fb_name}", username "${check_url?.username}" and topic "${check_url?.topic}" will be permanently deleted.` : `Widget "${check_url.widget}" with topic "${check_url?.topic}" will be permanently deleted.`
                            }],
                            confirm_function: () => handleDeleteRow({ fd: [{ campaign_name: check_url.widget }], refresh_list: should_refresh }),
                            cancel_function: () => {}
                        }
                    })
                } else {
                    appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Same URL found", content: "Same URL found in the database. Please provide another URL." }], confirm_function: () => {}, cancel_function: () => {} }})
                }
                
                return
            } else if ((check_url_v2.length > 0) && (Cookies.get('role') === 'admin')) {
                let final_message = [{ type: "Info", title: 'Deleted campaigns with SAME URL', content: '' }]
                const local_fb = window.localStorage.getItem('fbAccounts') ? JSON.parse(window.localStorage.getItem('fbAccounts')) : []
                check_url_v2.map(m => {
                    const find_local_fb = local_fb.find(f => f.act_id === m.act_id)
                    const find_fb = data.fb.find(f => f.id === m.act_id)
                    final_message.push({
                        type: "Info",
                        title: `${m.campaign_name}${find_local_fb ? ' | '+find_local_fb?.act_name : (find_fb ? ' | '+find_fb?.name : '')}${m.auto ? ' | (SCHEDULED)' : ''}`,
                        content: ''
                    })
                })
                appContext.setDialogInfo(p => {
                    return {
                        ...p,
                        mode: "Alert_Widget",
                        type: "Info",
                        message: final_message,
                        cancel_function: () => {},
                        confirm_function: async () => {
                            setUpdateLoading(true)
                            appContext.setDialogInfo(p => { return { ...p, mode: "", type: "", message: [], confirm_function: () => {}, cancel_function: () => {} }})
                            await handleMarkUnusedDomainsAPI(check_url_v2.map(m => m.campaign_name))
                            setUpdateLoading(false)
                            setRefreshUnusedDomains(p => !p)
                        },
                    }
                })
            }
        }

        if ((!fd.topic || !fd.fb_id || !fd.client || !fd.url || !fd.keyword.find(f => f !== '')) && (fd.client !== 'Tonic')) {
            appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Empty fields", content: "Make sure Topic, Facebook A/C, Client, URL and Keyword are filled before generating final url." }], confirm_function: () => {}, cancel_function: () => {} }})
            return
        }
        
        if (fd.client === 'Tonic' && (!fd.headline || !fd.headline[0] || !tonic_url)) {
            appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Empty fields", content: "Make sure Tonic URL and Headline are filled before generating final url for Tonic." }], confirm_function: () => {}, cancel_function: () => {} }})
            return
        }
        
        if ((fd.client === 'Tonic' || fd.client === 'Crossroads') && isNaN(fd.url)) {
            appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: fd.client+" URL must be a number.", content: "" }], confirm_function: () => {}, cancel_function: () => {} }})
            return
        }

        let temp = {...fd}
        let keyword = temp.keyword.map(e => e.split(' ').join('+'))
        let pixel_id = data.fb.find(f => f.unique === temp.fb_id)?.pixels_id
        
        if (fd.case === 'Ads Creation (Keywords)' && Array.isArray(temp.final_url)) {
            if (keyword.length >= 9) {
                temp.final_url = [
                    `${temp.url}/?${keyword.slice(0, 3).map((e, i) => `kw${i+1}=${e}&`).toString().replaceAll(',','')}pxfb[id]=${pixel_id}&pxfb[ec]=Lead&adtitle=${keyword[0]}&network=facebook&site={{placement}}`,
                    `${temp.url}/?${keyword.slice(3, 6).map((e, i) => `kw${i+1}=${e}&`).toString().replaceAll(',','')}pxfb[id]=${pixel_id}&pxfb[ec]=Lead&adtitle=${keyword[0]}&network=facebook&site={{placement}}`,
                    `${temp.url}/?${keyword.slice(6, 9).map((e, i) => `kw${i+1}=${e}&`).toString().replaceAll(',','')}pxfb[id]=${pixel_id}&pxfb[ec]=Lead&adtitle=${keyword[0]}&network=facebook&site={{placement}}`
                ]
            } else {
                appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "There should be 9 keywords", content: "" }], confirm_function: () => {}, cancel_function: () => {} }})
                return
            }
        } else {
            if (fd.client === 'Sedo') {
                temp.final_url = `${temp.url}/?${keyword.slice(0, 10).map((e, i) => `kw${i+1}=${e}&`).toString().replaceAll(',','')}pxfb[id]=${pixel_id}&pxfb[ec]=Lead&adtitle=${keyword[0]}&network=facebook&site={{placement}}`
            } else if (fd.client === 'System1') {
                temp.final_url = `${temp.url}/?compkey=${keyword[0]}&${keyword.slice(0, 5).map((e, i) => `forcekey${String.fromCharCode(65+i)}=${e}&`).toString().replaceAll(',','')}fbid=${pixel_id}&fbclick=Lead&ref=facebook.com`
            } else if (fd.client === 'Tonic') {
                temp.final_url = `${tonic_url}/?network=facebook&site=direct&adtitle=${temp?.headline?.[0]?.trim()?.replace(/\s+/g, "+")}&dpco=1`
            } else if (fd.client === 'Ads') {
                temp.final_url = `${temp.url}/?${keyword.slice(0, 10).map((e, i) => `kw${i+1}=${e}&`).toString().replaceAll(',','')}pxfb[id]=${pixel_id}&pxfb[ec]=Lead&adtitle=${keyword[0]}&network=facebook&site={{placement}}`
            } else {
                temp.final_url = `${temp.url}/?${keyword.slice(0, 10).map((e, i) => `kw${i+1}=${e}&`).toString().replaceAll(',','')}pxfb[id]=${pixel_id}&pxfb[ec]=Lead&adtitle=${keyword[0]}&network=facebook&site={{placement}}`
            }
        }
        
        setUpdateDetail(p => {
            return {
                ...p,
                final_url: temp.final_url
            }
        })
    }

    // find background color for each cell let's say the entire row
    const findBgColor = (e) => {
        const finished = e.finished ? e.finished : false
        const widget = e.widget
        const final_url = e.final_url
        let color = '#FFCC80'

        if (widget === 'ADD') {
            color = '#E8F1FB'
        } else if (finished) {
            color = '#C9E7CB'
        } else if ((typeof final_url === 'string' && final_url?.trim() !== '') || (Array.isArray(final_url) && (final_url?.filter(f => f)?.length === final_url?.length) )) {
            color = '#FFF176'
        } else {
            color = '#FFCC80'
        }
        
        return color
    }

    // on every input in add form change
    const handleChangeAdd = (e) => {
        e.preventDefault()
        const fieldName = e.target.getAttribute("name");
        const fieldValue = e.target.value;
        
        const newFormData = { ...addDetail };

        if (fieldName === 'fb_id') {
            newFormData['fb_id'] = fieldValue;
            newFormData['picture'] = Array(Number(newFormData['adset_count'])).fill("");
            const finding_id = data?.fb?.find(f => f?.unique === fieldValue)
            if (finding_id) {
                newFormData['client'] = finding_id?.client;
            }
        } else if (fieldName === 'headline') {
            newFormData['headline'] = Array(Number(newFormData['adset_count'])).fill(fieldValue)
        } else if (fieldName === 'message') {
            newFormData['message'] = Array(Number(newFormData['adset_count'])).fill(fieldValue)
        } else {
            newFormData[fieldName] = fieldValue;
        }
        
        setAddDetail(newFormData);
        setUpdateDetail({ widget: Number, country: '', topic: '', fb_id: '', client: '', url: '', keyword: [], final_url: '',  note: '', categories: [], daily_budget: '1', headline: ["","","","",""], picture: ["","","","",""], ad_media: [{},{},{},{},{}], message: ["","","","",""], comment: "", adset_count: '5', facebook_fan_page: '', instagram_account: '', case: '' })
        setSelectedRow(p => { return { ...p, widget: null } })
    }
    
    // on submitting add form
    const handleSubmitAdd = async (e) => {
        e.preventDefault()
        
        if (addDetail.country.trim() === '' || addDetail.topic.trim() === '' || addDetail.fb_id.trim() === '' || addDetail.client.trim() === '') {
            appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Empty fields", content: "Please provide Country, Topic and Facebook Account." }], confirm_function: () => {}, cancel_function: () => {} }})
            return
        }

        if (addDetail.case === "Ads Creation" || addDetail.case === "Ads Creation (Keywords)") {
            if (!addDetail.daily_budget || Number(addDetail.daily_budget) < 1 || Number(addDetail.daily_budget) > 10) {
                appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Incorrect input", content: "Daily budget should be between 1 to 10." }], confirm_function: () => {}, cancel_function: () => {} }})
                return
            }
            if (!addDetail.adset_count || Number(addDetail.adset_count) < 1 || Number(addDetail.adset_count) > 20) {
                appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Incorrect input", content: "Adset Count should be between 1 to 20." }], confirm_function: () => {}, cancel_function: () => {} }})
                return
            }
            if (addDetail.facebook_fan_page.trim() === '' ||
                addDetail.comment.trim() === '' ||
                addDetail.headline.filter(f => f.trim()).length !== Number(addDetail.adset_count) ||
                addDetail.message.filter(f => f.trim()).length !== Number(addDetail.adset_count) ||
                addDetail.picture.filter(f => f.trim()).length !== Number(addDetail.adset_count)
            ) {
                appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Empty fields", content: "Please provide FB Fan Page, Comment, Headline, Ad Text and Pictures (as per Adset Count)" }], confirm_function: () => {}, cancel_function: () => {} }})
                return
            }
        }
        
        newCampaignAPI('')
    }

    // on row edit enable
    const handleSelectRowEdit = ({e, detail}) => {
        e.preventDefault()
        const newSelect = { ...selectedRow };
        newSelect.widget = detail.widget;

        const row = { ...detail };
        row.country = (row?.case === 'Country') ? row.country.join(',') : row.country;
        row.keyword = row.keyword.join('\n');
        row.case = row.case ? row.case : 'Facebook'
        row.fb_id = row.fb_id ? row.fb_id : ''
        row.categories = row.categories ? row.categories : []
        row.facebook_fan_page = row.facebook_fan_page ? row.facebook_fan_page : ''
        row.instagram_account = row.instagram_account ? row.instagram_account : ''
        row.daily_budget = row.daily_budget ? row.daily_budget : '1'
        row.comment = row.comment ? row.comment : ""

        if (row.case === 'Ads Creation (Keywords)') {
            row.headline = row.headline ? row.headline : ["","",""]
            row.message = row.message ? row.message : ["","",""]
            row.ad_media = row.ad_media ? row.ad_media : ((row.picture && Array.isArray(row.picture) && row.picture.find(f => f)) ? row.picture.map(e => {return{type: 'image', url: e}}) : [{},{},{}])
            row.picture = row.picture ? row.picture : ["","",""]
            row.adset_count = row.picture ? row?.picture?.length?.toString() : '3'
        } else {
            row.headline = row.headline ? row.headline : ["","","","",""]
            row.message = row.message ? row.message : ["","","","",""]
            row.ad_media = row.ad_media ? row.ad_media : ((row.picture && Array.isArray(row.picture) && row.picture.find(f => f)) ? row.picture.map(e => {return{type: 'image', url: e}}) : [{},{},{},{},{}])
            row.picture = row.picture ? row.picture : ["","","","",""]
            row.adset_count = row.picture ? row?.picture?.length?.toString() : '5'
        }
        
        setUpdateDetail(row)
        setSelectedRow(newSelect);
    }

    // on row edit disable
    const handleUnselectRowEdit = (e) => {
        e.preventDefault()
        const unselect = { ...selectedRow };
        unselect.widget = null;
        
        setSelectedRow(unselect);
        setUpdateSelectDisplay('');
        setUpdateDetail({ widget: Number, country: '', topic: '', fb_id: '', client: '', url: '', keyword: [], final_url: '',  note: '', categories: [], daily_budget: '1', headline: ["","","","",""], picture: ["","","","",""], ad_media: [{},{},{},{},{}], message: ["","","","",""], comment: "", adset_count: '5', facebook_fan_page: '', instagram_account: '', case: '' })
    }

    // handle every updateDetail input change
    const handleChangeUpdate = (e) => {
        e.preventDefault()
        const fieldName = e.target.getAttribute("name");
        const fieldValue = e.target.value;
        
        const newFormData = { ...updateDetail };
        let arr = fieldValue.split('\n')
        
        if (fieldName === 'keyword' && arr.length > getBreaksCount()) {
            setDisplayErrorURL(true)
            return
        } else {
            setDisplayErrorURL(false)
        }
        
        if (fieldName === 'url') {
            newFormData['url'] = fieldValue?.replace(/\s/g, '');
        } else if (fieldName === 'case') {
            newFormData['case'] = fieldValue;
            if (fieldValue === 'Ads Creation') {
                newFormData['country'] = "";
            }
        } else if (fieldName === 'fb_id') {
            newFormData['fb_id'] = fieldValue;
            newFormData['picture'] = Array(Number(newFormData['adset_count'])).fill("");
            const finding_id = data?.fb?.find(f => f?.unique === fieldValue)
            if (newFormData.case === 'All') {
                newFormData['client'] = ['Sedo','Tonic','System1','Crossroads','Ads','Adcombo'];
            } else if (finding_id) {
                newFormData['client'] = finding_id?.client;
            }
        } else if (fieldName === 'headline') {
            newFormData['headline'] = Array(Number(newFormData['adset_count'])).fill(fieldValue)
        } else if (fieldName === 'message') {
            newFormData['message'] = Array(Number(newFormData['adset_count'])).fill(fieldValue)
        } else {
            newFormData[fieldName] = fieldValue;
        }
        
        setUpdateDetail(newFormData);
        setAddDetail(p => { return { ...p, country: '', topic: '', fb_id: '', client: '', url: '', note: '', categories: [], daily_budget: '1', headline: ["","","","",""], picture: ["","","","",""], ad_media: [{},{},{},{},{}], message: ["","","","",""], comment: "", adset_count: '5', facebook_fan_page: '', instagram_account: '' } })
    }

    // get the number of line breaks depending on client name
    const getBreaksCount = () => {
        let count = 10

        if (updateDetail.client === 'Sedo') {
            count = 10
        } else if (updateDetail.client === 'System1') {
            count = 5
        } else if (updateDetail.client === 'Tonic') {
            count = 1
        } else {
            count = 10
        }

        return count
    }

    // handle update functions for UPDATE SUBMIT
    const handleSubmitUpdate = async ({ e, fd }) => {
        e.preventDefault()

        if (updateDetail.case !== "Facebook") {
            if (updateDetail.country.trim() === '' || updateDetail.topic.trim() === '' || updateDetail.fb_id === '' || updateDetail.client === '' || updateDetail.comment.trim() === '' ||
                updateDetail.headline.filter(f => f.trim()).length !== Number(updateDetail.adset_count) ||
                updateDetail.message.filter(f => f.trim()).length !== Number(updateDetail.adset_count) ||
                updateDetail.picture.filter(f => f.trim()).length !== Number(updateDetail.adset_count)
            ) {
                appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Empty fields", content: "Country, Topic, Facebook A/C, Client, Comment Headline, Message and Pictures (as per Adset Count) are mandatory for update." }], confirm_function: () => {}, cancel_function: () => {} }})
                return
            }
            if (!updateDetail.daily_budget || Number(updateDetail.daily_budget) < 1 || Number(updateDetail.daily_budget) > 10) {
                appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Incorrect input", content: "Daily budget should be between 1 to 10." }], confirm_function: () => {}, cancel_function: () => {} }})
                return
            }
            if (!updateDetail.adset_count || Number(updateDetail.adset_count) < 1 || Number(updateDetail.adset_count) > 20) {
                appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Incorrect input", content: "Adset Count should be between 1 to 20." }], confirm_function: () => {}, cancel_function: () => {} }})
                return
            }
        } else {
            if (updateDetail.country.trim() === '' || updateDetail.topic.trim() === '' || updateDetail.fb_id === '' || updateDetail.client === '') {
                appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Empty fields", content: "Country, Topic, Facebook A/C and Client are mandatory before update." }], confirm_function: () => {}, cancel_function: () => {} }})
                return
            }
        }

        if ((updateDetail.client === 'Tonic' || updateDetail.client === 'Crossroads') && isNaN(updateDetail.url)) {
            appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: updateDetail.client+" URL must be a number.", content: "" }], confirm_function: () => {}, cancel_function: () => {} }})
            return
        }

        let temp = {...updateDetail}
        temp.finished = (fd.case === 'Facebook') ? false : temp.finished
        temp.topic = temp.topic ? temp.topic.trim() : ''
        temp.url = temp.url ? temp.url.trim() : ''
        temp.headline = temp.headline ? temp.headline : ["","","","",""]
        temp.message = temp.message ? temp.message : ["","","","",""]
        temp.ad_media = temp.ad_media ? temp.ad_media : ((temp.picture && Array.isArray(temp.picture) && temp.picture.find(f => f)) ? temp.picture.map(e => {return{type: 'image', url: e}}) : [{},{},{},{},{}])
        temp.picture = temp.picture ? temp.picture : ["","","","",""]
        temp.name = (temp.case === "Ads Creation (Keywords)") ? default_name_ACK : temp.picture.map((e, i) => `${i+1}`)
        temp.facebook_fan_page = temp.facebook_fan_page ? temp.facebook_fan_page : ''
        temp.instagram_account = temp.instagram_account ? temp.instagram_account : ''
        temp.fb_id = data?.fb?.find(f => f?.unique === temp.fb_id)?.id
        temp.keyword = temp.keyword
            .split('\n')
            .filter(f => f)
            .map(m => m.
                trim()
                .split(' ')
                .filter(fi => fi)
                .map(e => e.charAt(0).toUpperCase() + e.slice(1))
                .join(' '))
        if (temp.case !== 'Facebook') {
            temp.ad_template_link = (temp.case === "Ads Creation (Keywords)") ? (
                    (() => {
                        let final_link_arr = []
                        let count = 0
                        for (let index = 0; index < temp.name.length; index++) {
                            final_link_arr.push(temp?.final_url?.[count])
                            if (count === 2) {
                                count = 0
                            } else {
                                count = count + 1 
                            }
                        }
                        return final_link_arr
                    })()
                ) : Array(temp.name.length).fill(temp.final_url)
            temp.ad_template_picture = (temp.case === "Ads Creation (Keywords)") ? (
                    temp.ad_media ? (
                        [...Array(temp.name.length)].map((e, i) => {
                            let link = ''
                            if (i <= 2) {
                                link = temp?.ad_media?.[0]
                            } else if (i <= 5) {
                                link = temp?.ad_media?.[1]
                            } else {
                                link = temp?.ad_media?.[2]
                            }
                            return link
                        })
                    ) : (
                        [...Array(temp.name.length)].map((e, i) => {
                            let link = ''
                            if (i <= 2) {
                                link = { type: "image", url: temp?.picture?.[0] }
                            } else if (i <= 5) {
                                link = { type: "image", url: temp?.picture?.[1] }
                            } else {
                                link = { type: "image", url: temp?.picture?.[2] }
                            }
                            return link
                        })
                    )
                ) : (
                    temp.ad_media ? temp.ad_media : temp.picture.map(img_url => { return { type: "image", url: img_url } })
                )
        }

        delete temp?.adset_count

        if (updateDetail.url.trim() !== '' && updateDetail.url.trim() !== fd.url) {
            setUpdateLoading(true)
            const check_url = await checkUrlAPI(updateDetail.url?.trim())
            const check_url_v2 = data.unusedDomainsV2.filter(f => (f?.url?.trim() === updateDetail?.url?.trim()) && !f.read)
            setUpdateLoading(false)
    
            if ((check_url !== false) && check_url.widget) {
                if (Cookies.get('role') === 'admin') {
                    let should_refresh = false
    
                    if (filteredDataToMap.find(f => f.widget === check_url.widget)) {
                        should_refresh = true
                    }

                    let find_fb_name = ''
                    const local_fb = window.localStorage.getItem('fbAccounts') ? JSON.parse(window.localStorage.getItem('fbAccounts')) : []
                    
                    if (data.fb.find(f => f.id === check_url.fb_id)) {
                        find_fb_name = data.fb.find(f => f.id === check_url.fb_id)?.name
                    } else if (Array.isArray(local_fb)) {
                        find_fb_name = local_fb.find(f => f.act_id === check_url.fb_id)?.act_name
                    }
    
                    appContext.setDialogInfo(p => {
                        return {
                            ...p,
                            mode: "Confirm",
                            type: "Delete",
                            message: [{
                                type: "Delete",
                                title: `Same url widget found. Delete old one?`,
                                content: find_fb_name ? `Widget "${check_url.widget}" from account "${find_fb_name}", username "${check_url?.username}" and topic "${check_url?.topic}" will be permanently deleted.` : `Widget "${check_url.widget}" with topic "${check_url?.topic}" will be permanently deleted.`
                            }],
                            confirm_function: () => handleDeleteRow({ fd: [{ campaign_name: check_url.widget }], refresh_list: should_refresh }),
                            cancel_function: () => {}
                        }
                    })
                } else {
                    appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Same URL found", content: "Same URL found in the database. Please provide another URL." }], confirm_function: () => {}, cancel_function: () => {} }})
                }
            } else if ((check_url_v2.length > 0) && (Cookies.get('role') === 'admin')) {
                let final_message = [{ type: "Info", title: 'Deleted campaigns with SAME URL', content: '' }]
                const local_fb = window.localStorage.getItem('fbAccounts') ? JSON.parse(window.localStorage.getItem('fbAccounts')) : []
                check_url_v2.map(m => {
                    const find_local_fb = local_fb.find(f => f.act_id === m.act_id)
                    const find_fb = data.fb.find(f => f.id === m.act_id)
                    final_message.push({
                        type: "Info",
                        title: `${m.campaign_name}${find_local_fb ? ' | '+find_local_fb?.act_name : (find_fb ? ' | '+find_fb?.name : '')}${m.auto ? ' | (SCHEDULED)' : ''}`,
                        content: ''
                    })
                })
                appContext.setDialogInfo(p => {
                    return {
                        ...p,
                        mode: "Alert_Widget",
                        type: "Info",
                        message: final_message,
                        cancel_function: () => {},
                        confirm_function: async () => {
                            setUpdateLoading(true)
                            appContext.setDialogInfo(p => { return { ...p, mode: "", type: "", message: [], confirm_function: () => {}, cancel_function: () => {} }})
                            await handleMarkUnusedDomainsAPI(check_url_v2.map(m => m.campaign_name))
                            setUpdateLoading(false)
                            setRefreshUnusedDomains(p => !p)
                            updateCampaignAPI(temp)
                        },
                    }
                })
            } else {
                updateCampaignAPI(temp)
            }
        } else {
            updateCampaignAPI(temp)
        }
    }

    // show hide keyword and final url by changing state
    const handleShowHide = ({e, key, value}) => {
        e.preventDefault()
        const newSelect = { ...selectedRow };
        newSelect[key] = value
        setSelectedRow(newSelect);
    }

    // delete the entire row - more like change state to false
    const handleDeleteRow = ({ fd, refresh_list }) => {
        setUpdateLoading(true)
        const token = Cookies.get("token");
        const url = `${host}/api/index/delete_widget`;

        axios.post(url, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
            },
            data: fd
        })
        .then((response) => {
            setUpdateLoading(false)
            if (refresh_list === true) {
                setRefresh(p => !p)
                setUpdateDetail({ widget: Number, country: '', topic: '', fb_id: '', client: '', url: '', keyword: [], final_url: '', note: '', categories: [], daily_budget: '1', headline: ["","","","",""], picture: ["","","","",""], ad_media: [{},{},{},{},{}], message: ["","","","",""], comment: "", adset_count: '5', facebook_fan_page: '', instagram_account: '', case: '' })
                setUpdateSelectDisplay('')
                setSelectedRow(p => { return { ...p, widget: null, keyword: null, final_url: null, note: null, url: null, country: null, message: null, picture: null }})
            }
        })
        .catch((error) => {
            console.log(error)
            window.alert(error)
            setUpdateLoading(false)
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        })
        .finally(() => setUpdateLoading(false))
    }

    // updates finished value
    const handleChangeSwitch = ({ fd, finished, result }) => {
        setUpdateLoading(true)
        const token = Cookies.get("token");
        const manager = Cookies.get("manager");
        const url = `${host}/api/index/update`;

        let detail = {
            ...fd,
            fb_id: data?.fb?.find(f => f?.unique === fd.fb_id)?.id,
            finished: finished,
            result: result
        }
        delete detail?.adset_count
        delete detail?.tonic_url

        axios.post(url,
            {
                headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
                },
                data: detail
            }
        )
        .then((response) => {
            if (response.data.success) {
                setRefresh(p => !p)
            } else {
                appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Switch failed", content: "Please try again or do a page refresh before trying again." }], confirm_function: () => {}, cancel_function: () => {} }})
            }
            setUpdateLoading(false)
        })
        .catch((error) => {
            setUpdateLoading(false)
            console.log(error)
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        })
    }

    // headers for downloading table data in CSV format
    const headerCSV = [
        { label: 'Campaign Name', key: 'widget' },
        { label: 'Country', key: 'country' },
        { label: 'Topic', key: 'topic' },
        { label: 'FB Account', key: 'fb_account' },
        { label: 'Client', key: 'client' },
        { label: 'Keywords', key: 'keyword' },
        { label: 'Note', key: 'note' },
        { label: 'URL', key: 'url' },
        { label: 'Final URL', key: 'final_url' },
    ]

    // data to put in CSV file to download
    const dataCSV = data.widget.map(e => {
        return { widget: e.widget, country: e.country, topic: e.topic, fb_account: data.fb.find(f => f.id === e.fb_id) ? data.fb.find(f => f.id === e.fb_id).name : '', client: e.client, keyword: e?.keyword?.join('\n'), note: e.note, url: e.url, final_url: e.final_url, username: e.username }
    })

    // jsx return function for bulk update
    const bulkUpdateReturnFunction = () => {
        // count keywords
        const getBreaksCountBUI = (client) => {
            let count = 10

            if (client === 'Sedo') {
                count = 10
            } else if (client === 'System1') {
                count = 5
            } else if (client === 'Tonic') {
                count = 1
            } else {
                count = 10
            }

            return count
        }

        // handle input fields
        const handleChangeBulkUpdateItem = ({ e, item }) => {
            const fieldName = e.target.getAttribute("name");
            const fieldValue = e.target.value;

            const newFormData = { ...item };
            let arr = fieldValue.split('\n')
            
            if (fieldName === 'keyword' && arr.length > getBreaksCountBUI(item?.client)) {
                setBulkUpdate(p => {
                    return {
                        ...p,
                        keyword_error: [ ...p.keyword_error, item?.widget?.toString() ]
                    }
                })
                return
            }

            if (fieldName === 'url') {
                newFormData['url'] = fieldValue?.replace(/\s/g, '');
            } else if (fieldName === 'case') {
                newFormData['case'] = fieldValue;
                if (fieldValue === 'Ads Creation') {
                    newFormData['country'] = "";
                } else if (fieldValue === 'Facebook') {
                    newFormData['facebook_fan_page'] = "";
                    newFormData['adset_count'] = "5";
                    newFormData['daily_budget'] = "1";
                    newFormData['categories'] = [];
                    newFormData['headline'] = ["","","","",""];
                    newFormData['message'] = ["","","","",""];
                    newFormData['comment'] = "";
                    newFormData['ad_media'] = [{},{},{},{},{}];
                    newFormData['picture'] = ["","","","",""];
                }
            } else if (fieldName === 'fb_id') {
                newFormData['fb_id'] = fieldValue;
                newFormData['picture'] = Array(Number(newFormData['adset_count'])).fill("");
                const finding_id = data?.fb?.find(f => f?.unique === fieldValue)
                if (finding_id) {
                    newFormData['client'] = finding_id?.client;
                }
            } else if (fieldName === 'headline') {
                newFormData['headline'] = Array(Number(newFormData['adset_count'])).fill(fieldValue)
            } else if (fieldName === 'message') {
                newFormData['message'] = Array(Number(newFormData['adset_count'])).fill(fieldValue)
            } else {
                newFormData[fieldName] = fieldValue;
            }
            
            setBulkUpdate(p => {
                let temp = { ...p.list }
                temp[item?.widget?.toString()] = newFormData
                return {
                    ...p,
                    keyword_error: p.keyword_error.filter(f => f !== item?.widget?.toString()),
                    duplicate_url: (fieldName === 'url') ? p.duplicate_url.filter(f => f?.widget !== item?.widget) : p.duplicate_url,
                    list: temp
                }
            })
        }

        // generate final urls for all that has conditions fulfilled
        const handleGenerateBulkUpdate = async () => {
            let temp_bulk = Object.values(bulkUpdate.list)

            if (Array.from(new Set(temp_bulk.map(e => e.url))).length !== temp_bulk.length) {
                appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Same URLs found", content: "Some of the items in the bulk update have same URLs. (*Marked RED*)" }], confirm_function: () => {}, cancel_function: () => {} }})
                return
            }
            
            const find_NaN = temp_bulk.find(e => ( (e.client === 'Tonic' || e.client === 'Crossroads') && isNaN(e.url) ))
            if (find_NaN) {
                appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: `${find_NaN?.widget} ~ ${find_NaN?.client} URL must be a number.`, content: "" }], confirm_function: () => {}, cancel_function: () => {} }})
                return
            }

            let temp_duplicates = []
            let temp_duplicates_unused_domains = []
            
            setUpdateLoading(true)
            let check_url = await Promise.all(temp_bulk.map(async item => {
                const search_widget = filteredDataToMap.find(f => f.widget === item.widget)
                
                if ((item.url.trim() !== '') && (item.url !== search_widget?.url)) {
                    const check_url = await checkUrlAPI(item.url?.trim())
                    const check_url_v2 = data.unusedDomainsV2.filter(f => (f?.url?.trim() === item?.url?.trim()) && !f.read)
            
                    if ((check_url !== false) && check_url.widget) {
                        temp_duplicates.push(check_url)
                    }
                    if (check_url_v2.length > 0) {
                        check_url_v2.map(m => temp_duplicates_unused_domains.push(m))
                    }
                }
            }))
            setUpdateLoading(false)
            
            if (temp_duplicates.length > 0) {
                if (Cookies.get('role') === 'admin') {
                    setBulkUpdate(p => { return { ...p, loading: false, duplicate_url: temp_duplicates, display_multi_delete: true, clear_list: true } })
                } else {
                    setBulkUpdate(p => { return { ...p, loading: false, duplicate_url: temp_duplicates, display_multi_delete: false, clear_list: true } })
                    appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Same URL old widgets found", content: "Same URL widgets found in the database. Please try different ones." }], confirm_function: () => {}, cancel_function: () => {} }})
                }
                return
            } else if ((temp_duplicates_unused_domains.length > 0) && (Cookies.get('role') === 'admin')) {
                let final_message = [{ type: "Info", title: 'Deleted campaigns with SAME URL', content: '' }]
                const local_fb = window.localStorage.getItem('fbAccounts') ? JSON.parse(window.localStorage.getItem('fbAccounts')) : []
                temp_duplicates_unused_domains.map(m => {
                    const find_local_fb = local_fb.find(f => f.act_id === m.act_id)
                    const find_fb = data.fb.find(f => f.id === m.act_id)
                    final_message.push({
                        type: "Info",
                        title: `${m.campaign_name}${' | '+m.url}${find_local_fb ? ' | '+find_local_fb?.act_name : (find_fb ? ' | '+find_fb?.name : '')}${m.auto ? ' | (SCHEDULED)' : ''}`,
                        content: ''
                    })
                })
                appContext.setDialogInfo(p => {
                    return {
                        ...p,
                        mode: "Alert_Widget",
                        type: "Info",
                        message: final_message,
                        cancel_function: () => {},
                        confirm_function: async () => {
                            setUpdateLoading(true)
                            appContext.setDialogInfo(p => { return { ...p, mode: "", type: "", message: [], confirm_function: () => {}, cancel_function: () => {} }})
                            await handleMarkUnusedDomainsAPI(temp_duplicates_unused_domains.map(m => m.campaign_name))
                            setUpdateLoading(false)
                            setRefreshUnusedDomains(p => !p)
                        },
                    }
                })
            }

            temp_bulk = temp_bulk.map(fd => {
                let temp = {...fd}
                let keyword = temp?.keyword?.split('\n')?.map(e => e.split(' ').join('+'))
                let pixel_id = data.fb.find(f => f.unique === temp.fb_id)?.pixels_id
                
                if ((!fd?.topic?.trim() || !fd?.fb_id?.trim() || !fd?.client?.trim() || !fd?.url?.trim() || !fd?.keyword?.trim()) && (fd?.client?.trim() !== 'Tonic')) {
                    temp.final_url = ''
                } else if (fd.client === 'Tonic' && (!fd.headline || !fd.headline[0] || !fd.tonic_url)) {
                    temp.final_url = ''
                } else {
                    if (fd.client === 'Sedo') {
                        temp.final_url = `${temp.url}/?${keyword.slice(0, 10).map((e, i) => `kw${i+1}=${e}&`).toString().replaceAll(',','')}pxfb[id]=${pixel_id}&pxfb[ec]=Lead&adtitle=${keyword[0]}&network=facebook&site={{placement}}`
                    } else if (fd.client === 'System1') {
                        temp.final_url = `${temp.url}/?compkey=${keyword[0]}&${keyword.slice(0, 5).map((e, i) => `forcekey${String.fromCharCode(65+i)}=${e}&`).toString().replaceAll(',','')}fbid=${pixel_id}&fbclick=Lead&ref=facebook.com`
                    } else if (fd.client === 'Tonic') {
                        temp.final_url = `${fd.tonic_url}/?network=facebook&site=direct&adtitle=${temp?.headline?.[0]?.trim()?.replace(/\s+/g, "+")}&dpco=1`
                    } else if (fd.client === 'Ads') {
                        temp.final_url = `${temp.url}/?${keyword.slice(0, 10).map((e, i) => `kw${i+1}=${e}&`).toString().replaceAll(',','')}pxfb[id]=${pixel_id}&pxfb[ec]=Lead&adtitle=${keyword[0]}&network=facebook&site={{placement}}`
                    } else {
                        temp.final_url = `${temp.url}/?${keyword.slice(0, 10).map((e, i) => `kw${i+1}=${e}&`).toString().replaceAll(',','')}pxfb[id]=${pixel_id}&pxfb[ec]=Lead&adtitle=${keyword[0]}&network=facebook&site={{placement}}`
                    }
                }

                return temp
            })

            setBulkUpdate(p => {
                let temp = { ...p.list }
                temp_bulk.map(m => {
                    temp[m?.widget?.toString()] = m
                })

                return {
                    ...p,
                    list: temp
                }
            })
        }

        // cancel everything and change values to defaults
        const handleCancelBulkUpdate = () => {
            setBulkUpdate(p => {
                return {
                    ...p,
                    loading: false,
                    display_list: false,
                    keyword_error: [],
                    duplicate_url: [],
                    list: {},
                    open_category: 0,
                    display_multi_delete: false,
                    clear_list: true
                }
            })
        }
        
        // loop all the updates together, wait for updates to finish and then run the index API
        const handleBulkUpdateBTN = async () => {
            const token = Cookies.get("token");
            const manager = Cookies.get("manager");
            const url = `${host}/api/index/update`;
            const bulk_list = Object.values(bulkUpdate?.list)
            const update_list = bulk_list?.map(e => { return { ...e, keyword: e?.keyword?.trim() ? e?.keyword?.split('\n') : [] } })

            const find_NaN = bulk_list.find(e => ( (e.client === 'Tonic' || e.client === 'Crossroads') && isNaN(e.url) ))
            if (find_NaN) {
                appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: `${find_NaN?.widget} ~ ${find_NaN?.client} URL must be a number.`, content: "" }], confirm_function: () => {}, cancel_function: () => {} }})
                return
            }

            for (let i = 0; i < bulk_list.length; i++) {
                if (
                    bulk_list[i]?.url !== "" &&
                    bulk_list.filter(fil => fil.widget !== bulk_list[i]?.widget).find(f => f.url === bulk_list[i]?.url)
                ) {
                    appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Same URLs found", content: "Some of the items in the bulk update have same URLs. (*Marked RED*)" }], confirm_function: () => {}, cancel_function: () => {} }})
                    return
                }

                if ((bulk_list[i]?.case === "Ads Creation") || (bulk_list[i]?.case === "Ads Creation (Keywords)")) {
                    if (!bulk_list[i]?.comment?.trim()) {
                        appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Comment field is mandatory!!!", content: "" }], confirm_function: () => {}, cancel_function: () => {} }})
                        return
                    }

                    if (!bulk_list[i]?.daily_budget || Number(bulk_list[i]?.daily_budget) < 1) {
                        appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Incorrect input", content: "Daily budget can not be empty, negative or less than 1." }], confirm_function: () => {}, cancel_function: () => {} }})
                        return
                    }
                }
            }

            setBulkUpdate(p => { return { ...p, loading: true, duplicate_url: [] } })

            let temp_duplicates = []
            let temp_duplicates_unused_domains = []

            setUpdateLoading(true)
            let check_url = await Promise.all(bulk_list.map(async item => {
                const search_widget = filteredDataToMap.find(f => f.widget === item.widget)
                
                if ((item.url.trim() !== '') && (item.url !== search_widget?.url)) {
                    const check_url = await checkUrlAPI(item.url?.trim())
                    const check_url_v2 = data.unusedDomainsV2.filter(f => (f?.url?.trim() === item?.url?.trim()) && !f.read)
            
                    if ((check_url !== false) && check_url.widget) {
                        temp_duplicates.push(check_url)
                    }
                    if (check_url_v2.length > 0) {
                        check_url_v2.map(m => temp_duplicates_unused_domains.push(m))
                    }
                }
            }))
            setUpdateLoading(false)
            setBulkUpdate(p => { return { ...p, loading: false } })

            if (temp_duplicates.length > 0) {
                if (Cookies.get('role') === 'admin') {
                    setBulkUpdate(p => { return { ...p, loading: false, duplicate_url: temp_duplicates, display_multi_delete: true, clear_list: true } })
                } else {
                    setBulkUpdate(p => { return { ...p, loading: false, duplicate_url: temp_duplicates, display_multi_delete: false, clear_list: true } })
                    appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Same URL old widgets found", content: "Same URL widgets found in the database. Please try different ones." }], confirm_function: () => {}, cancel_function: () => {} }})
                }
            } else if ((temp_duplicates_unused_domains.length > 0) && (Cookies.get('role') === 'admin')) {
                let final_message = [{ type: "Info", title: 'Deleted campaigns with SAME URL', content: '' }]
                const local_fb = window.localStorage.getItem('fbAccounts') ? JSON.parse(window.localStorage.getItem('fbAccounts')) : []
                temp_duplicates_unused_domains.map(m => {
                    const find_local_fb = local_fb.find(f => f.act_id === m.act_id)
                    const find_fb = data.fb.find(f => f.id === m.act_id)
                    final_message.push({
                        type: "Info",
                        title: `${m.campaign_name}${' | '+m.url}${find_local_fb ? ' | '+find_local_fb?.act_name : (find_fb ? ' | '+find_fb?.name : '')}${m.auto ? ' | (SCHEDULED)' : ''}`,
                        content: ''
                    })
                })
                appContext.setDialogInfo(p => {
                    return {
                        ...p,
                        mode: "Alert_Widget",
                        type: "Info",
                        message: final_message,
                        cancel_function: () => {},
                        confirm_function: async () => {
                            setUpdateLoading(true)
                            appContext.setDialogInfo(p => { return { ...p, mode: "", type: "", message: [], confirm_function: () => {}, cancel_function: () => {} }})
                            await handleMarkUnusedDomainsAPI(temp_duplicates_unused_domains.map(m => m.campaign_name))
                            setUpdateLoading(false)
                            setRefreshUnusedDomains(p => !p)
                            
                            setBulkUpdate(p => { return { ...p, loading: true } })
                            appContext.setDialogInfo(p => { return { ...p, mode: "", type: "", message: [], confirm_function: () => {}, cancel_function: () => {} }})
                            let result = await Promise.all(update_list.map(async item => {
                                const data_to_send = { ...item }
                                data_to_send.fb_id = data?.fb?.find(f => f?.unique === data_to_send.fb_id)?.id
                                data_to_send.name = (data_to_send.case === "Ads Creation (Keywords)") ? default_name_ACK : data_to_send.picture.map((e, i) => `${i+1}`)
                                if (data_to_send.case !== 'Facebook') {
                                    data_to_send.ad_template_link = (data_to_send.case === "Ads Creation (Keywords)") ? (
                                            (() => {
                                                let final_link_arr = []
                                                let count = 0
                                                for (let index = 0; index < data_to_send.name.length; index++) {
                                                    final_link_arr.push(data_to_send?.final_url?.[count])
                                                    if (count === 2) {
                                                        count = 0
                                                    } else {
                                                        count = count + 1 
                                                    }
                                                }
                                                return final_link_arr
                                            })()
                                        ) : Array(data_to_send.name.length).fill(data_to_send.final_url)
                                    data_to_send.ad_template_picture = (data_to_send.case === "Ads Creation (Keywords)") ? (
                                            data_to_send.ad_media ? (
                                                [...Array(data_to_send.name.length)].map((e, i) => {
                                                    let link = ''
                                                    if (i <= 2) {
                                                        link = data_to_send?.ad_media?.[0]
                                                    } else if (i <= 5) {
                                                        link = data_to_send?.ad_media?.[1]
                                                    } else {
                                                        link = data_to_send?.ad_media?.[2]
                                                    }
                                                    return link
                                                })
                                            ) : (
                                                [...Array(data_to_send.name.length)].map((e, i) => {
                                                    let link = ''
                                                    if (i <= 2) {
                                                        link = { type: "image", url: data_to_send?.picture?.[0] }
                                                    } else if (i <= 5) {
                                                        link = { type: "image", url: data_to_send?.picture?.[1] }
                                                    } else {
                                                        link = { type: "image", url: data_to_send?.picture?.[2] }
                                                    }
                                                    return link
                                                })
                                            )
                                        ) : (
                                            data_to_send.ad_media ? data_to_send.ad_media : data_to_send.picture.map(img_url => { return { type: "image", url: img_url } })
                                        )
                                }
                                delete data_to_send.adset_count
                                delete data_to_send.tonic_url

                                try {
                                    const response = await axios.post(url, {
                                        headers: {
                                            "Access-Control-Allow-Origin": "*",
                                            "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                                            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                                            "Authorization": "Bearer "+token,
                                            "Content-Type": "application/json",
                                        },
                                        data: data_to_send
                                    })
                                } catch (error) {
                                    console.log(error);
                                    if (error?.response?.data?.message === 'Invalid') {
                                        appContext.forceLogout()
                                    }
                                }
                            }))

                            setBulkUpdate(p => {
                                return {
                                    ...p,
                                    loading: false,
                                    display_list: false,
                                    keyword_error: [],
                                    duplicate_url: [],
                                    list: {},
                                    open_category: 0,
                                    display_multi_delete: false,
                                    clear_list: true
                                }
                            })

                            setRefresh(p => !p)
                        },
                    }
                })
            } else {
                setUpdateLoading(true)
                setBulkUpdate(p => { return { ...p, loading: true } })
                let result = await Promise.all(update_list.map(async item => {
                    const data_to_send = { ...item }
                    data_to_send.fb_id = data?.fb?.find(f => f?.unique === data_to_send.fb_id)?.id
                    data_to_send.name = (data_to_send.case === "Ads Creation (Keywords)") ? default_name_ACK : data_to_send.picture.map((e, i) => `${i+1}`)
                    if (data_to_send.case !== 'Facebook') {
                        data_to_send.ad_template_link = (data_to_send.case === "Ads Creation (Keywords)") ? (
                                (() => {
                                    let final_link_arr = []
                                    let count = 0
                                    for (let index = 0; index < data_to_send.name.length; index++) {
                                        final_link_arr.push(data_to_send?.final_url?.[count])
                                        if (count === 2) {
                                            count = 0
                                        } else {
                                            count = count + 1
                                        }
                                    }
                                    return final_link_arr
                                })()
                            ) : Array(data_to_send.name.length).fill(data_to_send.final_url)
                        data_to_send.ad_template_picture = (data_to_send.case === "Ads Creation (Keywords)") ? (
                                data_to_send.ad_media ? (
                                    [...Array(data_to_send.name.length)].map((e, i) => {
                                        let link = ''
                                        if (i <= 2) {
                                            link = data_to_send?.ad_media?.[0]
                                        } else if (i <= 5) {
                                            link = data_to_send?.ad_media?.[1]
                                        } else {
                                            link = data_to_send?.ad_media?.[2]
                                        }
                                        return link
                                    })
                                ) : (
                                    [...Array(data_to_send.name.length)].map((e, i) => {
                                        let link = ''
                                        if (i <= 2) {
                                            link = { type: "image", url: data_to_send?.picture?.[0] }
                                        } else if (i <= 5) {
                                            link = { type: "image", url: data_to_send?.picture?.[1] }
                                        } else {
                                            link = { type: "image", url: data_to_send?.picture?.[2] }
                                        }
                                        return link
                                    })
                                )
                            ) : (
                                data_to_send.ad_media ? data_to_send.ad_media : data_to_send.picture.map(img_url => { return { type: "image", url: img_url } })
                            )
                    }
                    delete data_to_send.adset_count
                    delete data_to_send.tonic_url

                    try {
                        const response = await axios.post(url, {
                            headers: {
                                "Access-Control-Allow-Origin": "*",
                                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                                "Authorization": "Bearer "+token,
                                "Content-Type": "application/json",
                            },
                            data: data_to_send
                        })
                    } catch (error) {
                        console.log(error);
                        if (error?.response?.data?.message === 'Invalid') {
                            appContext.forceLogout()
                        }
                    }
                }))
                setUpdateLoading(false)

                setBulkUpdate(p => {
                    return {
                        ...p,
                        loading: false,
                        display_list: false,
                        keyword_error: [],
                        duplicate_url: [],
                        list: {},
                        open_category: 0,
                        display_multi_delete: false,
                        clear_list: true
                    }
                })

                setRefresh(p => !p)
            }
        }

        return (
            <>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <button className='widget2-button' style={{ background: '#FFF', border: '2px solid #FF7F7F', color: '#FF7F7F', pointerEvents: bulkUpdate.loading ? 'none' : 'all' }} disabled={bulkUpdate.loading} onClick={() => handleCancelBulkUpdate()}>Cancel</button>
                    <button className='widget2-button' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pointerEvents: bulkUpdate.loading ? 'none' : 'all' }} disabled={bulkUpdate.loading} onClick={() => handleBulkUpdateBTN()}>{bulkUpdate.loading ? <div className='loader'/> : 'Update All'}</button>
                    <button className='widget2-button' style={{ background: '#1E90FF', minWidth: 'fit-content', pointerEvents: bulkUpdate.loading ? 'none' : 'all' }} disabled={bulkUpdate.loading} onClick={() => handleGenerateBulkUpdate()}>Generate Final URLs</button>
                </div>
                <div className="widget-table-container">
                    <table className="widget-table">
                        <thead>
                            <tr>
                                <th style={{ minWidth: '140px', maxWidth: '140px' }}>Campaign Name</th>
                                <th style={{ minWidth: '120px', maxWidth: '120px' }}>Country</th>
                                <th style={{ minWidth: '200px', maxWidth: '200px' }}>Topic</th>
                                <th style={{ minWidth: '150px', maxWidth: '150px' }}>FB Acc</th>
                                <th style={{ minWidth: '100px', maxWidth: '100px' }}>Client</th>
                                <th style={{ minWidth: '150px', maxWidth: '150px' }}>Categories</th>
                                <th style={{ minWidth: '100px', maxWidth: '100px' }}>Daily Budget</th>
                                <th style={{ minWidth: '200px', maxWidth: '200px' }}>Headline</th>
                                <th style={{ minWidth: '200px', maxWidth: '200px' }}>Ad Text</th>
                                <th style={{ minWidth: '200px', maxWidth: '200px' }}>Comment</th>
                                <th style={{ minWidth: '200px', maxWidth: '200px' }}>Media</th>
                                <th style={{ minWidth: '200px', maxWidth: '200px' }}>URL</th>
                                <th style={{ minWidth: '250px', maxWidth: '250px' }}>Keywords</th>
                                <th style={{ minWidth: '250px', maxWidth: '250px' }}>Final URL</th>
                                <th style={{ minWidth: '250px', maxWidth: '250px' }}>Note</th>
                                <th style={{ minWidth: '100px', maxWidth: '100px' }}>Case</th>
                            </tr>
                        </thead>

                        <tbody>
                            {Object.values(bulkUpdate.list).map((item, i) => {
                                const render_images = data.fb_images?.[data.fb.find(f => (f.unique === item?.fb_id))?.id]

                                return (
                                    <tr key={i} style={{ background: '#FFCC80' }}>
                                        <td style={{ minWidth: '140px', maxWidth: '140px' }}>
                                            <div className="widget-table-cell">
                                                {item?.widget}
                                            </div>
                                        </td>
                                        <td style={{ minWidth: '120px', maxWidth: '120px' }}>
                                            <div className="widget-table-cell">
                                                {(item?.case === "Ads Creation") ? countryFieldComponent({ info: item, cond: 'update_bulk' }) : (
                                                    <input className='widget-input' type="text" name='country' placeholder='input country' autoComplete='off' value={item?.country} onChange={e => handleChangeBulkUpdateItem({ e, item })} />
                                                )}
                                            </div>
                                        </td>
                                        <td style={{ minWidth: '200px', maxWidth: '200px' }}>
                                            <div className="widget-table-cell">
                                                <input className='widget-input' type="text" name='topic' placeholder='input topic' autoComplete='off' value={item?.topic} onChange={e => handleChangeBulkUpdateItem({ e, item })} />
                                            </div>
                                        </td>
                                        <td style={{ minWidth: '150px', maxWidth: '150px' }}>
                                            <div className="widget-table-cell" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                {(item?.case === "Ads Creation") ? (
                                                    <>
                                                        <div className='widget-add-field' style={{ width: '100%' }}>
                                                            <p className='widget-add-field-float-name'>Facebook A/C</p>
                                                            <select className='widget2-select' name='fb_id' value={item?.fb_id} onChange={e => handleChangeBulkUpdateItem({ e, item })} onFocus={e => e.target.style.borderColor = '#000'} onBlur={e => e.target.style.borderColor = '#AAA'}>
                                                                <option value="">Choose facebook A/C</option>
                                                                {data.fb.map((e, i) => (
                                                                    <option value={e.unique} key={i}>{e.name} {"("}{e.client}{")"}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className='widget-add-field' style={{ width: '100%' }} onMouseEnter={() => setBulkUpdate(p => { return { ...p, open_drop_down: item.widget+"-facebook_fan_page" } })} onMouseLeave={() => setBulkUpdate(p => { return { ...p, open_drop_down: "" } })}>
                                                            <p className='widget-add-field-float-name'>FB Fan Page</p>
                                                            <div className='widget2-div' style={{ borderColor: (bulkUpdate.open_drop_down === item.widget+"-facebook_fan_page") ? '#000' : '#AAA' }}>
                                                                <div style={{ fontSize: '14px' }}>{!bulkUpdate.list[item.widget.toString()].facebook_fan_page.trim() ? 'None selected' : facebook_fan_pages?.find(f => f?.id === bulkUpdate.list[item.widget.toString()].facebook_fan_page)?.name}</div>
                                                                <ul style={{ position: 'absolute', top: '38px', height: 'fit-content', maxHeight: '250px', width: '250px', background: '#FFF', display: (bulkUpdate.open_drop_down === item.widget+"-facebook_fan_page") ? 'block' : 'none' }}>
                                                                    <li style={{ background: (bulkUpdate.list[item.widget.toString()].facebook_fan_page === '') ? '#1E90FF' : '#FFF', color: (bulkUpdate.list[item.widget.toString()].facebook_fan_page === '') ? '#FFF' : '#000', borderBottom: '1px solid #C9E7CB' }}
                                                                        onMouseEnter={hov => hov.currentTarget.style.filter = "invert(20%)"}
                                                                        onMouseLeave={hov => hov.currentTarget.style.filter = "invert(0%)"}
                                                                        onClick={() => {
                                                                            setBulkUpdate(p => {
                                                                                return {
                                                                                    ...p,
                                                                                    list: { ...p.list, [item?.widget?.toString()]: { ...item, facebook_fan_page: '' } }
                                                                                }
                                                                            })
                                                                        }}
                                                                    >
                                                                        NONE
                                                                    </li>
                                                                    {facebook_fan_pages?.sort((a, b) => a?.name > b?.name ? 1 : -1).map((e, i) => (
                                                                        <li key={i} style={{ background: (bulkUpdate.list[item.widget.toString()].facebook_fan_page === e?.id) ? '#1E90FF' : '#FFF', color: (bulkUpdate.list[item.widget.toString()].facebook_fan_page === e?.id) ? '#FFF' : '#000', borderBottom: '1px solid #DDD' }}
                                                                            onMouseEnter={hov => hov.currentTarget.style.filter = "invert(20%)"}
                                                                            onMouseLeave={hov => hov.currentTarget.style.filter = "invert(0%)"}
                                                                            onClick={() => {
                                                                                setBulkUpdate(p => {
                                                                                    return {
                                                                                        ...p,
                                                                                        list: { ...p.list, [item?.widget?.toString()]: { ...item, facebook_fan_page: e?.id } }
                                                                                    }
                                                                                })
                                                                            }}
                                                                        >
                                                                            {e?.name}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className='widget-add-field' style={{ width: '100%' }}>
                                                            <p className='widget-add-field-float-name'>{"Adset Count (1 - 20)"}</p>
                                                            <input className='widget2-input' type="number" name='adset_count' placeholder='1 - 20' autoComplete='off' value={item?.adset_count} onChange={e => handleChangeBulkUpdateItem({ e, item })} onWheel={e => e.target.blur()}
                                                                onBlur={e => {
                                                                    let temp_count = e.target.value
                                
                                                                    if (!temp_count || Number(temp_count) < 1) {
                                                                        temp_count = '1'
                                                                    } else if (Number(temp_count) > 20) {
                                                                        temp_count = '20'
                                                                    } else {
                                                                        temp_count = Number(temp_count)?.toFixed(0)
                                                                    }

                                                                    setBulkUpdate(p => {
                                                                        let temp = { ...p.list }
                                                                        let update_item = { ...item }
                                                                        update_item.adset_count = temp_count
                                                                        update_item.headline = Array(Number(temp_count)).fill("")
                                                                        update_item.message = Array(Number(temp_count)).fill("")
                                                                        update_item.ad_media = Array(Number(temp_count)).fill({})
                                                                        update_item.picture = Array(Number(temp_count)).fill("")
                                                                        update_item.name = (update_item.case === "Ads Creation (Keywords)") ? default_name_ACK : [...Array(Number(temp_count))].map((e, i) => `${i+1}`)

                                                                        temp[item?.widget?.toString()] = update_item
                                                                        return {
                                                                            ...p,
                                                                            list: temp
                                                                        }
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <select className='widget-datagrid-select' name='fb_id' value={item?.fb_id} onChange={e => handleChangeBulkUpdateItem({ e, item })}>
                                                        <option value="">Choose</option>
                                                        {data.fb.map((e, i) => (
                                                            <option value={e.unique} key={i}>{e.name} {"("}{e.client}{")"}</option>
                                                        ))}
                                                    </select>
                                                )}
                                            </div>
                                        </td>
                                        <td style={{ minWidth: '100px', maxWidth: '100px' }}>
                                            <div className="widget-table-cell">
                                                {Array.isArray(item?.client) ? 'All Clients' : item?.client}
                                            </div>
                                        </td>
                                        <td style={{ minWidth: '100px', maxWidth: '100px' }}>
                                            <div className="widget-table-cell">
                                                {(item?.case === "Ads Creation") && (
                                                    <div className='widget-add-field' style={{ width: '100%' }} onMouseEnter={() => setBulkUpdate(p => { return { ...p, open_drop_down: item.widget+"-categories" } })} onMouseLeave={() => setBulkUpdate(p => { return { ...p, open_drop_down: "" } })}>
                                                        <div className='widget2-div' style={{ borderColor: (bulkUpdate.open_drop_down === item.widget+"-categories") ? '#000' : '#AAA' }}>
                                                            <div style={{ fontSize: '14px' }}>{(item?.categories.length === 0) ? 'None selected' : item?.categories.map(e => e[0]+e[1]+e[2]).join(", ")}</div>
                                                            <ul style={{ position: 'absolute', top: '40px', height: 'fit-content', background: '#FFF', display: (bulkUpdate.open_drop_down === item.widget+"-categories") ? 'block' : 'none' }}>
                                                                <li style={{ backgroundColor: (item?.categories.length === 0) ? '#dbeafe' : '#FFF', wordBreak: 'break-all' }}
                                                                    onClick={() => {
                                                                        setBulkUpdate(p => {
                                                                            let temp = { ...p.list }
                                                                            temp[item?.widget?.toString()] = { ...item, categories: [] }
                                                                            return {
                                                                                ...p,
                                                                                list: temp
                                                                            }
                                                                        })
                                                                    }}
                                                                >
                                                                    NONE
                                                                </li>
                                                                {["CREDIT", "HOUSING", "ISSUES_ELECTIONS_POLITICS", "EMPLOYMENT"].map((cat, i) => (
                                                                    <li key={i} style={{ backgroundColor: (item?.categories.find(f => f === cat)) ? '#dbeafe' : '#FFF', wordBreak: 'break-all' }}
                                                                        onMouseEnter={e => e.currentTarget.style.opacity = 0.5}
                                                                        onMouseLeave={e => e.currentTarget.style.opacity = 1}
                                                                        onClick={() => {
                                                                            let temp_cat = [...item.categories]
                                                                            let find_category = temp_cat.filter(f => f === cat)
                                                                            if (find_category.length > 0) {
                                                                                temp_cat = temp_cat.filter(f => f !== cat)
                                                                            } else {
                                                                                temp_cat.push(cat)
                                                                            }
                                                                            setBulkUpdate(p => {
                                                                                let temp = { ...p.list }
                                                                                temp[item?.widget?.toString()] = { ...item, categories: temp_cat }
                                                                                return {
                                                                                    ...p,
                                                                                    list: temp
                                                                                }
                                                                            })
                                                                        }}
                                                                    >
                                                                        {cat}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                        <td style={{ minWidth: '100px', maxWidth: '100px' }}>
                                            <div className="widget-table-cell">
                                                {(item?.case === "Ads Creation") &&(
                                                    <div style={{ height: '40px', display: 'flex', alignItems: 'center' }}>
                                                        $&nbsp;<input className='widget-input' type="number" name='daily_budget' placeholder='1 - 10 USD' value={item?.daily_budget} onChange={e => handleChangeBulkUpdateItem({ e, item })} onWheel={e => e.target.blur()}
                                                            onBlur={e => {
                                                                let temp_count = e.target.value
                            
                                                                if (!temp_count || Number(temp_count) < 1) {
                                                                    temp_count = '1'
                                                                } else if (Number(temp_count) > 10) {
                                                                    temp_count = '10'
                                                                }
                            
                                                                setBulkUpdate(p => {
                                                                    let temp = { ...p.list }
                                                                    let update_item = { ...item }
                                                                    update_item.daily_budget = temp_count
                                                                    temp[item?.widget?.toString()] = update_item
                                                                    return {
                                                                        ...p,
                                                                        list: temp
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                        <td style={{ minWidth: '200px', maxWidth: '200px' }}>
                                            <div className="widget-table-cell">
                                                {(item?.case === "Ads Creation") && (
                                                    <input className='widget-input' type="text" name='headline' placeholder='input headline' autoComplete='off' value={item?.headline?.[0]} onChange={e => handleChangeBulkUpdateItem({ e, item })} />
                                                )}
                                            </div>
                                        </td>
                                        <td style={{ minWidth: '200px', maxWidth: '200px' }}>
                                            <div className="widget-table-cell">
                                                {(item?.case === "Ads Creation") && (
                                                    <textarea type="text" name='message' placeholder="input ad text" rows={10} value={item?.message?.[0]} onChange={e => handleChangeBulkUpdateItem({ e, item })} className='widget2-textarea' style={{ height: '150px', maxWidth: '100%' }} />
                                                )}
                                            </div>
                                        </td>
                                        <td style={{ minWidth: '200px', maxWidth: '200px' }}>
                                            <div className="widget-table-cell">
                                                {(item?.case === "Ads Creation") && (
                                                    <textarea type="text" name='comment' placeholder="input comment" rows={10} value={item?.comment} onChange={e => handleChangeBulkUpdateItem({ e, item })} className='widget2-textarea' style={{ height: '150px', maxWidth: '100%' }} />
                                                )}
                                            </div>
                                        </td>
                                        
                                        <td style={{ minWidth: '200px', maxWidth: '200px' }}>
                                            <div className="widget-table-cell" style={{ width: '100%' }}>
                                                {(item?.case === "Ads Creation") && (
                                                    <div className='widget-add-field' style={{ width: '100%' }} onClick={() => setFbMediaState(p => { return { ...p, display: true, mode: 'update-bulk', act_id: data.fb.find(f => (f.unique === item?.fb_id))?.id, widget: item?.widget } })}>
                                                        <div className='widget2-div' style={{ borderColor: '#AAA' }}>
                                                            <div style={{ fontSize: '14px' }}>
                                                                {item?.picture?.find(f => f) ? (
                                                                    item?.picture?.filter(f => f)?.length + " / " + item?.picture?.length + " selected"
                                                                ) : "None selected"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </td>

                                        <td style={{ minWidth: '200px', maxWidth: '200px' }}>
                                            <div className="widget-table-cell">
                                                <input className='widget-input' type="text" name='url' placeholder='water-tank.shop' autoComplete='off' value={item?.url} onChange={e => handleChangeBulkUpdateItem({ e, item })}
                                                    style={{
                                                        marginBottom: '5px',
                                                        background: (
                                                            bulkUpdate.duplicate_url.find(f => f?.widget === item?.widget) ||
                                                            (
                                                                item?.url !== "" &&
                                                                Object.values(bulkUpdate?.list).filter(fil => fil.widget !== item.widget).find(f => f.url === item?.url)
                                                            )
                                                        ) ? '#FF9F9F' : 'transparent'
                                                    }}
                                                />
                                                <select className='widget-datagrid-select' name='url' style={{ height: '40px', width: '100%' }} value={item?.url} onChange={e => handleChangeBulkUpdateItem({ e, item })}>
                                                    <option value="">Choose</option>
                                                    {list.map((e, i) => (<option key={i} value={e.domain}>{e.domain}</option>))}
                                                </select>
                                            </div>
                                        </td>
                                        <td style={{ minWidth: '250px', maxWidth: '250px' }}>
                                            <div className="widget-table-cell">
                                                {(bulkUpdate.keyword_error.find(f => f === item?.widget?.toString())) && (<p style={{ fontSize: '14px', color: '#FF0000' }}>{item?.client+' - only '+getBreaksCountBUI(item?.client)+' line breaks allowed.'}</p>)}
                                                <textarea type="text" name='keyword' placeholder={item?.client+' - only '+getBreaksCountBUI(item?.client)+' line breaks allowed.'} rows={10} value={item?.keyword} onChange={e => handleChangeBulkUpdateItem({ e, item })} className='widget2-textarea' style={{ height: '150px', maxWidth: '100%' }} />
                                            </div>
                                        </td>
                                        <td style={{ minWidth: '250px', maxWidth: '250px' }}>
                                            <div className="widget-table-cell">
                                                {(item?.client === 'Tonic') ? (
                                                    <>
                                                        <div className='widget-add-field' style={{ width: '100%' }}>
                                                            <p className='widget-add-field-float-name'>Tonic URL Mandatory</p>
                                                            <input className='widget2-input' type="text" name="tonic_url" placeholder='https://url.tonic.com' autoComplete='off' value={item?.tonic_url} onChange={e => handleChangeBulkUpdateItem({ e, item })} style={{ maxWidth: '100%' }} />
                                                        </div>
                                                        <div className='widget-add-field' style={{ width: '100%', margin: '10px 0px' }}>
                                                            <p className='widget-add-field-float-name'>Final URL</p>
                                                            <input className='widget2-input' type="text" name='final_url' placeholder='final url' autoComplete='off' value={item?.final_url} onChange={e => handleChangeBulkUpdateItem({ e, item })} style={{ maxWidth: '100%' }} />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <input className='widget-input' type="text" name='final_url' placeholder='final url' autoComplete='off' value={item?.final_url} onChange={e => handleChangeBulkUpdateItem({ e, item })} style={{ marginBottom: '10px' }} />
                                                )}
                                            </div>
                                        </td>
                                        <td style={{ minWidth: '250px', maxWidth: '250px' }}>
                                            <div className="widget-table-cell">
                                                <input className='widget-input' type="text" name='note' placeholder='note' value={item?.note} onChange={e => handleChangeBulkUpdateItem({ e, item })} />
                                            </div>
                                        </td>
                                        <td style={{ minWidth: '100px', maxWidth: '100px' }}>
                                            <div className="widget-table-cell">
                                                <select className='widget-datagrid-select' name='case' value={item?.case} onChange={e => handleChangeBulkUpdateItem({ e, item })}>
                                                    {(facebook_fan_pages.length > 0) ? (
                                                        <>
                                                            <option value="Ads Creation">Ads Creation</option>
                                                            <option value="Facebook">Widget Old</option>
                                                        </>
                                                    ) : (
                                                        <option value="Facebook">Widget Old</option>
                                                    )}
                                                </select>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </>
        )
    }

    // API to copy and create widgets in bulk
    const handleBulkCopy = (manager) => {
        setUpdateLoading(true)
        let temp_bulk = Object.values(bulkUpdate.list)
        
        temp_bulk = temp_bulk.map(fd => {
            let result = {}

            if (fd.case === 'Ads Creation') {
                result = {
                    finished: false,
                    topic: fd.topic ? fd.topic : '',
                    fb_id: '',
                    client: '',
                    country: fd.country ? fd.country : '',
                    categories: fd.categories ? fd.categories : [],
                    daily_budget: "1",
                    headline: fd.headline ? fd.headline : ["","","","",""],
                    message: fd.message ? fd.message : ["","","","",""],
                    comment: fd.comment ? fd.comment : "",
                    ad_media: fd.headline ? Array(Number(fd?.headline?.length)).fill({}) : [{},{},{},{},{}],
                    picture: fd.headline ? Array(Number(fd?.headline?.length)).fill("") : ["","","","",""],
                    facebook_fan_page: "",
                    instagram_account: "",
                    note: fd?.note ? fd?.note : '',
                    url: "",
                    keyword: fd?.keyword ? fd?.keyword?.split('\n') : [],
                    final_url: '',
                    case: fd.case,
                    username: manager
                }
            } else if (fd.case === 'Ads Creation (Keywords)') {
                result = {
                    finished: false,
                    topic: fd.topic ? fd.topic : '',
                    fb_id: '',
                    client: '',
                    country: fd.country ? fd.country : '',
                    categories: fd.categories ? fd.categories : [],
                    daily_budget: "1",
                    headline: fd.headline ? fd.headline : ["","",""],
                    message: fd.message ? fd.message : ["","",""],
                    comment: fd.comment ? fd.comment : "",
                    ad_media: fd.headline ? Array(Number(fd?.headline?.length)).fill({}) : [{},{},{}],
                    picture: fd.headline ? Array(Number(fd?.headline?.length)).fill("") : ["","",""],
                    facebook_fan_page: "",
                    instagram_account: "",
                    note: fd?.note ? fd?.note : '',
                    url: "",
                    keyword: fd?.keyword ? fd?.keyword?.split('\n') : [],
                    final_url: ["","",""],
                    case: fd.case,
                    username: manager
                }
            } else {
                result = {
                    finished: false,
                    topic: fd.topic ? fd.topic : '',
                    fb_id: '',
                    client: '',
                    country: fd.country ? fd.country : '',
                    categories: [],
                    daily_budget: "1",
                    headline: ["","","","",""],
                    message: ["","","","",""],
                    comment: "",
                    ad_media: [{},{},{},{},{}],
                    picture: ["","","","",""],
                    facebook_fan_page: "",
                    instagram_account: "",
                    note: fd?.note ? fd?.note : '',
                    url: "",
                    keyword: fd?.keyword ? fd?.keyword?.split('\n') : [],
                    final_url: '',
                    case: fd.case,
                    username: manager
                }   
            }

            return result
        })

        const token = Cookies.get('token')
        const url = `${host}/api/index/copy_campaign`;

        axios.post(url, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
            },
            data: temp_bulk
        })
        .then((response) => {
            const obj = response.data
            
            if (obj.success) {
                setBulkUpdate(p => {
                    return {
                        ...p,
                        loading: false,
                        display_list: false,
                        keyword_error: [],
                        duplicate_url: [],
                        list: {},
                        open_category: 0,
                        display_multi_delete: false,
                        clear_list: true
                    }
                })
                setRefresh(p => !p)
            } else {
                appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Copy failed.", content: "Please try again or do a page refresh before trying again." }], confirm_function: () => {}, cancel_function: () => {} }})
            }
        })
        .catch((error) => {
            console.log(error);
            appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Copy failed", content: error }], confirm_function: () => {}, cancel_function: () => {} }})
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        })
        .finally(() => {
            setUpdateLoading(false)
        })
    }

    // API to delete (or update state to false) widgets in bulk
    const handleBulkDelete = async () => {
        appContext.setDialogInfo(p => {
            return {
                ...p,
                mode: "Confirm",
                type: "Delete",
                message: [
                    { type: "Delete", title: `Delete the selected ${Object.values(bulkUpdate.list).length} items?`, content: "This will permanently delete the selected items from the system." },
                ],
                confirm_function: async () => {
                    setUpdateLoading(true)
                    const token = Cookies.get("token");
                    const url = `${host}/api/index/delete_widget`;
                    let temp_bulk = Object.values(bulkUpdate.list)?.map(fd => { return { campaign_name: fd.widget } })

                    axios.post(url, {
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                            "Authorization": "Bearer "+token,
                            "Content-Type": "application/json",
                        },
                        data: temp_bulk
                    })
                    .then((response) => {
                        console.log(response)
                        setBulkUpdate(p => {
                            return {
                                ...p,
                                loading: false,
                                display_list: false,
                                keyword_error: [],
                                duplicate_url: [],
                                list: {},
                                open_category: 0,
                                display_multi_delete: false,
                                clear_list: true
                            }
                        })

                        setUpdateLoading(false)
                        setRefresh(p => !p)
                    })
                    .catch((error) => {
                        console.log(error)
                        window.alert(error)
                        setUpdateLoading(false)
                        if (error?.response?.data?.message === 'Invalid') {
                            appContext.forceLogout()
                        }
                    })
                    .finally(() => {
                        setBulkUpdate(p => {
                            return {
                                ...p,
                                loading: false,
                                display_list: false,
                                keyword_error: [],
                                duplicate_url: [],
                                list: {},
                                open_category: 0,
                                display_multi_delete: false,
                                clear_list: true
                            }
                        })

                        setUpdateLoading(false)
                    })
                },
                cancel_function: () => {}
            }
        })
    }

    // returns jsx for country selection for Ads Creation
    const countryFieldComponent = ({ info, cond }) => {
        const fb_countries = [...data.fb_countries]
        const fb_regions = [
            ...data.fb_regions,
            { name: 'TIER 1', country_codes: ["US", "CA", "GB", "IE", "AU", "NZ"] },
            { name: 'TIER 1 (NO UK)', country_codes: ["US", "CA", "IE", "AU", "NZ"] },
            { name: 'SOUTH AMERICA', country_codes: ["CO", "EC", "PE", "CL", "BO", "PY", "UY", "AR", "VE"] },
            { name: 'CAM', country_codes: ["HN", "GT", "NI", "SV", "CR", "PA", "DO"] },
            { name: 'LATAM', country_codes: ["CO", "EC", "PE", "CL", "BO", "PY", "UY", "AR", "VE", "HN", "GT", "NI", "SV", "CR", "PA", "DO", "MX"] },
            { name: 'MENA', country_codes: ["DZ", "BH", "EG", "IQ", "JO", "KW", "LB", "LY", "MR", "MA", "OM", "QA", "SA", "TN", "AE", "YE"] },
            { name: 'AFRICA', country_codes: ["NG", "ET", "EG", "CD", "TZ", "ZA", "KE", "UG", "SS", "DZ", "MA", "AO", "GH", "MZ", "MG", "CI", "CM", "NE", "ML", "BF", "MW", "ZM", "TD", "SO", "SN", "ZW", "GN", "RW", "BJ", "BI", "TN", "TG", "SL", "LY", "CG", "CF", "LR", "MR", "ER", "GM", "BW", "NA", "GA", "LS", "GW", "GQ", "MU", "DJ", "KM", "CV", "ST", "SC"] }, // no Eswatini & Sudan(only South Sudan)
        ]
        const displaySelect = (cond === 'add') ? (countryField.displayWidget === 123 ? true : false) : (countryField.displayWidget === info.widget ? true : false)
        let result = <></>

        const handleAddCountryClick = ({ code, action, type, isRegionSelected }) => {
            if (action === 'remove') {
                setAddDetail(p => {
                    let temp_country = p.country.split(',')
                    return {
                        ...p,
                        country: temp_country.filter(f => f !== code).join(','),
                    }
                })
            } else if (action === 'add') {
                if (type === 'region') {
                    let temp_country = addDetail.country.split(',').filter(f => f)
                    
                    if (isRegionSelected) {
                        code.map(e => {
                            if (addDetail.country.includes(e)) {
                                temp_country = temp_country.filter(f => f !== e)
                            }
                        })
                    } else {
                        code.map(e => {
                            if (addDetail.country.includes(e)) {
                                temp_country = temp_country.filter(f => f !== e)
                            }
                        })
                        code.map(e => temp_country.push(e))
                    }
                    
                    setAddDetail(p => {
                        return {
                            ...p,
                            country: temp_country.join(','),
                        }
                    })
                } else {
                    setAddDetail(p => {
                        let temp_country = p.country.split(',').filter(f => f)
                        temp_country.push(code)
                        return {
                            ...p,
                            country: temp_country.join(','),
                        }
                    })
                }
            }
        }
    
        const isAddRegionSelected = (codes) => {
            let result = true
    
            codes.map(e => {
                if (!addDetail.country.includes(e)) {
                    result = false
                }
            })
    
            return result
        }

        const handleUpdateCountryClick = ({ code, action, type, isRegionSelected }) => {
            if (action === 'remove') {
                setUpdateDetail(p => {
                    let temp_country = p.country.split(',')
                    return {
                        ...p,
                        country: temp_country.filter(f => f !== code).join(','),
                    }
                })
            } else if (action === 'add') {
                if (type === 'region') {
                    let temp_country = updateDetail.country.split(',').filter(f => f)
                    
                    if (isRegionSelected) {
                        code.map(e => {
                            if (updateDetail.country.includes(e)) {
                                temp_country = temp_country.filter(f => f !== e)
                            }
                        })
                    } else {
                        code.map(e => {
                            if (updateDetail.country.includes(e)) {
                                temp_country = temp_country.filter(f => f !== e)
                            }
                        })
                        code.map(e => temp_country.push(e))
                    }
                    
                    setUpdateDetail(p => {
                        return {
                            ...p,
                            country: temp_country.join(','),
                        }
                    })
                } else {
                    setUpdateDetail(p => {
                        let temp_country = p.country.split(',').filter(f => f)
                        temp_country.push(code)
                        return {
                            ...p,
                            country: temp_country.join(','),
                        }
                    })
                }
            }
        }
    
        const isUpdateRegionSelected = (codes) => {
            let result = true
    
            codes.map(e => {
                if (!updateDetail.country.includes(e)) {
                    result = false
                }
            })
    
            return result
        }

        const handleUpdateBulkCountryClick = ({ code, action, type, isRegionSelected }) => {
            let update_item = { ...bulkUpdate.list[info?.widget?.toString()] }

            if (action === 'remove') {
                let temp_country = update_item.country.split(',')
                update_item.country = temp_country.filter(f => f !== code).join(',')
            }
            else if (action === 'add') {
                if (type === 'region') {
                    let temp_country = update_item.country.split(',').filter(f => f)
                    
                    if (isRegionSelected) {
                        code.map(e => {
                            if (info.country.includes(e)) {
                                temp_country = temp_country.filter(f => f !== e)
                            }
                        })
                    } else {
                        code.map(e => {
                            if (info.country.includes(e)) {
                                temp_country = temp_country.filter(f => f !== e)
                            }
                        })
                        code.map(e => temp_country.push(e))
                    }
                    
                    update_item.country = temp_country.join(',')
                } else {
                    let temp_country = info.country.split(',').filter(f => f)
                    temp_country.push(code)
                    update_item.country = temp_country.join(',')
                }
            }

            setBulkUpdate(p => {
                let temp = { ...p.list }
                temp[info?.widget?.toString()] = update_item
                return {
                    ...p,
                    list: temp
                }
            })
        }
    
        const isUpdateBulkRegionSelected = (codes) => {
            let result = true
    
            codes.map(e => {
                if (!info.country.includes(e)) {
                    result = false
                }
            })
    
            return result
        }

        if (cond === 'add') {
            result = <div className='widget-add-field' style={{ justifyContent: 'flex-start' }} onMouseEnter={() => setCountryField(p => { return { ...p, displayWidget: 123 } })} onMouseLeave={() => setCountryField(p => { return { ...p, displayWidget: 0 } })}>
                <p className='widget-add-field-float-name'>Country</p>
                <input className='widget2-input' type="text" style={{ borderColor: displaySelect ? '#000' : '#AAA' }}
                    value={displaySelect ? countryField.userInput : (addDetail.country.split(',').filter(f => f).length + ' selected')}
                    placeholder={displaySelect ? 'search input' : (addDetail.country.split(',').filter(f => f).length + ' selected')}
                    onChange={e => {
                        if (displaySelect) {
                            setCountryField(p => { return { ...p, displayWidget: 123, userInput: e.target.value } })
                        } else {
                            return
                        }
                    }}
                />
                <div style={{ position: 'absolute', top: '38px', zIndex: 20, height: '300px', width: '250px', background: '#FFF', display: displaySelect ? 'flex' : 'none', flexDirection: 'column', border: '2px solid #AAA', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', overflowY: 'scroll' }}>
                    <div style={{ position: 'sticky', top: '0px', width: '100%', padding: '5px', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: '5px', background: '#FFF' }}>
                        <div style={{ height: '30px', background: '#4caf50', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px', textAlign: 'center', color: '#FFF', borderRadius: '5px' }} onClick={() => setAddDetail(p => {return{...p, country: fb_countries.map(e => e.country_code).join(',')}})}>Select All</div>
                        <div style={{ height: '30px', background: '#FF5F5F', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px', textAlign: 'center', color: '#FFF', borderRadius: '5px' }} onClick={() => setAddDetail(p => {return{...p, country: ''}})}>Clear<br/>All</div>
                        <div style={{ height: '30px', background: countryField.displayRegion ? '#AAA' : '#1E90FF', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px', textAlign: 'center', color: '#FFF', borderRadius: '5px' }} onClick={() => setCountryField(p => {return{...p, displayRegion: false}})}>Country</div>
                        <div style={{ height: '30px', background: countryField.displayRegion ? '#1E90FF' : '#AAA', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px', textAlign: 'center', color: '#FFF', borderRadius: '5px' }} onClick={() => setCountryField(p => {return{...p, displayRegion: true}})}>Region</div>
                    </div>
                    {countryField.displayRegion ? (
                        fb_regions.map((e, i) => (
                            <div key={i}
                                style={{ width: '100%', padding: '5px', display: 'grid', gridTemplateColumns: '15px 1fr', gap: '5px', cursor: 'pointer' }}
                                onClick={() => handleAddCountryClick({ code: e.country_codes, action: 'add', type: 'region', isRegionSelected: isAddRegionSelected(e.country_codes) })}
                                onMouseEnter={e => e.currentTarget.style.background = '#EEE'}
                                onMouseLeave={e => e.currentTarget.style.background = '#FFF'}
                            >
                                <input type="checkbox" checked={isAddRegionSelected(e.country_codes) ? true : false} readOnly={true} style={{ height: '15px', width: '15px', pointerEvents: 'none' }}/>
                                <div style={{ paddingLeft: '5px', fontSize: '12px' }}>{e.name}</div>
                            </div>
                        ))
                    ) : (
                        fb_countries.filter(f => (
                            f.name.toLowerCase().includes(countryField.userInput.toLowerCase()) || f.country_code.toLowerCase().includes(countryField.userInput.toLowerCase())
                        )).map((e, i) => {
                            const isSelected = addDetail.country.includes(e.country_code)
                            return (
                                <div key={i}
                                    style={{ width: '100%', padding: '5px', display: 'grid', gridTemplateColumns: '15px 1fr', gap: '5px', cursor: 'pointer' }}
                                    onClick={() => handleAddCountryClick({ code: e.country_code, action: isSelected ? 'remove' : 'add', type: 'country', isRegionSelected: false })}
                                    onMouseEnter={e => e.currentTarget.style.background = '#EEE'}
                                    onMouseLeave={e => e.currentTarget.style.background = '#FFF'}
                                >
                                    <input type="checkbox" checked={isSelected ? true : false} readOnly={true} style={{ height: '15px', width: '15px', pointerEvents: 'none' }}/>
                                    <div style={{  paddingLeft: '5px', fontSize: '12px' }}>{e.name}</div>
                                </div>
                            )
                        })
                    )}
                </div>
            </div>
        } else if (cond === 'update') { 
            result = <div style={{ position: 'relative', height: '40px', width: '100%' }} onMouseEnter={() => setCountryField(p => { return { ...p, displayWidget: info.widget } })} onMouseLeave={() => setCountryField(p => { return { ...p, displayWidget: 0 } })}>
                <input className='widget2-input' type="text" style={{ borderColor: displaySelect ? '#000' : '#AAA' }}
                    value={displaySelect ? countryField.userInput : (updateDetail.country.split(',').filter(f => f).length + ' selected')}
                    placeholder={displaySelect ? 'search input' : (updateDetail.country.split(',').filter(f => f).length + ' selected')}
                    onChange={e => {
                        if (displaySelect) {
                            setCountryField(p => { return { ...p, displayWidget: info.widget, userInput: e.target.value } })
                        } else {
                            return
                        }
                    }}
                />
                <div style={{ position: 'absolute', top: '38px', zIndex: 20, height: '300px', width: '250px', background: '#FFF', display: displaySelect ? 'flex' : 'none', flexDirection: 'column', border: '2px solid #AAA', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', overflowY: 'scroll' }}>
                    <div style={{ position: 'sticky', top: '0px', width: '100%', padding: '5px', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: '5px', background: '#FFF', cursor: 'pointer' }}>
                        <div style={{ height: '30px', background: '#4caf50', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px', textAlign: 'center', color: '#FFF', borderRadius: '5px' }} onClick={() => setUpdateDetail(p => {return{...p, country: fb_countries.map(e => e.country_code).join(',')}})}>Select All</div>
                        <div style={{ height: '30px', background: '#FF5F5F', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px', textAlign: 'center', color: '#FFF', borderRadius: '5px' }} onClick={() => setUpdateDetail(p => {return{...p, country: ''}})}>Clear<br/>All</div>
                        <div style={{ height: '30px', background: countryField.displayRegion ? '#AAA' : '#1E90FF', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px', textAlign: 'center', color: '#FFF', borderRadius: '5px' }} onClick={() => setCountryField(p => {return{...p, displayRegion: false}})}>Country</div>
                        <div style={{ height: '30px', background: countryField.displayRegion ? '#1E90FF' : '#AAA', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px', textAlign: 'center', color: '#FFF', borderRadius: '5px' }} onClick={() => setCountryField(p => {return{...p, displayRegion: true}})}>Region</div>
                    </div>
                    {countryField.displayRegion ? (
                        fb_regions.map((e, i) => (
                            <div key={i}
                                style={{ width: '100%', padding: '5px', display: 'grid', gridTemplateColumns: '15px 1fr', gap: '5px', cursor: 'pointer' }}
                                onClick={() => handleUpdateCountryClick({ code: e.country_codes, action: 'add', type: 'region', isRegionSelected: isUpdateRegionSelected(e.country_codes) })}
                                onMouseEnter={e => e.currentTarget.style.background = '#EEE'}
                                onMouseLeave={e => e.currentTarget.style.background = '#FFF'}
                            >
                                <input type="checkbox" checked={isUpdateRegionSelected(e.country_codes) ? true : false} readOnly={true} style={{ height: '15px', width: '15px', pointerEvents: 'none' }}/>
                                <div style={{  paddingLeft: '5px', fontSize: '12px' }}>{e.name}</div>
                            </div>
                        ))
                    ) : (
                        fb_countries.filter(f => (
                            f.name.toLowerCase().includes(countryField.userInput.toLowerCase()) || f.country_code.toLowerCase().includes(countryField.userInput.toLowerCase())
                        )).map((e, i) => {
                            const isSelected = updateDetail.country.includes(e.country_code)
                            return (
                                <div key={i}
                                    style={{ width: '100%', padding: '5px', display: 'grid', gridTemplateColumns: '15px 1fr', gap: '5px', cursor: 'pointer' }}
                                    onClick={() => handleUpdateCountryClick({ code: e.country_code, action: isSelected ? 'remove' : 'add', type: 'country', isRegionSelected: false })}
                                    onMouseEnter={e => e.currentTarget.style.background = '#EEE'}
                                    onMouseLeave={e => e.currentTarget.style.background = '#FFF'}
                                >
                                    <input type="checkbox" checked={isSelected ? true : false} readOnly={true} style={{ height: '15px', width: '15px', pointerEvents: 'none' }}/>
                                    <div style={{  paddingLeft: '5px', fontSize: '12px' }}>{e.name}</div>
                                </div>
                            )
                        })
                    )}
                </div>
            </div>
        } else if (cond === 'update_bulk') {
            result = <div style={{ position: 'relative', height: '40px', width: '100%' }} onMouseEnter={() => setCountryField(p => { return { ...p, displayWidget: info.widget } })} onMouseLeave={() => setCountryField(p => { return { ...p, displayWidget: 0 } })}>
                <input className='widget2-input' type="text" style={{ borderColor: displaySelect ? '#000' : '#AAA' }}
                    value={displaySelect ? countryField.userInput : (info.country.split(',').filter(f => f).length + ' selected')}
                    placeholder={displaySelect ? 'search input' : (info.country.split(',').filter(f => f).length + ' selected')}
                    onChange={e => {
                        if (displaySelect) {
                            setCountryField(p => { return { ...p, displayWidget: info.widget, userInput: e.target.value } })
                        } else {
                            return
                        }
                    }}
                />
                <div style={{ position: 'absolute', top: '38px', zIndex: 20, height: '300px', width: '250px', background: '#FFF', display: displaySelect ? 'flex' : 'none', flexDirection: 'column', border: '2px solid #AAA', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', overflowY: 'scroll' }}>
                    <div style={{ position: 'sticky', top: '0px', width: '100%', padding: '5px', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: '5px', background: '#FFF', cursor: 'pointer' }}>
                        <div style={{ height: '30px', background: '#4caf50', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px', textAlign: 'center', color: '#FFF', borderRadius: '5px' }}
                            onClick={() => {
                                setBulkUpdate(p => {
                                    let temp = { ...p.list }
                                    let update_item = { ...p.list[info?.widget?.toString()] }
                                    update_item.country = fb_countries.map(e => e.country_code).join(',')
                                    temp[info?.widget?.toString()] = update_item
                                    return {
                                        ...p,
                                        list: temp
                                    }
                                })
                            }}
                        >
                            Select All
                        </div>
                        <div style={{ height: '30px', background: '#FF5F5F', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px', textAlign: 'center', color: '#FFF', borderRadius: '5px' }}
                            onClick={() => {
                                setBulkUpdate(p => {
                                    let temp = { ...p.list }
                                    let update_item = { ...p.list[info?.widget?.toString()] }
                                    update_item.country = ''
                                    temp[info?.widget?.toString()] = update_item
                                    return {
                                        ...p,
                                        list: temp
                                    }
                                })
                            }}
                        >
                            Clear<br/>All
                        </div>
                        <div style={{ height: '30px', background: countryField.displayRegion ? '#AAA' : '#1E90FF', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px', textAlign: 'center', color: '#FFF', borderRadius: '5px' }} onClick={() => setCountryField(p => {return{...p, displayRegion: false}})}>Country</div>
                        <div style={{ height: '30px', background: countryField.displayRegion ? '#1E90FF' : '#AAA', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px', textAlign: 'center', color: '#FFF', borderRadius: '5px' }} onClick={() => setCountryField(p => {return{...p, displayRegion: true}})}>Region</div>
                    </div>
                    {countryField.displayRegion ? (
                        fb_regions.map((e, i) => (
                            <div key={i}
                                style={{ width: '100%', padding: '5px', display: 'grid', gridTemplateColumns: '15px 1fr', gap: '5px', cursor: 'pointer' }}
                                onClick={() => handleUpdateBulkCountryClick({ code: e.country_codes, action: 'add', type: 'region', isRegionSelected: isUpdateBulkRegionSelected(e.country_codes) })}
                                onMouseEnter={e => e.currentTarget.style.background = '#EEE'}
                                onMouseLeave={e => e.currentTarget.style.background = '#FFF'}
                            >
                                <input type="checkbox" checked={isUpdateBulkRegionSelected(e.country_codes) ? true : false} readOnly={true} style={{ height: '15px', width: '15px', pointerEvents: 'none' }}/>
                                <div style={{  paddingLeft: '5px', fontSize: '12px' }}>{e.name}</div>
                            </div>
                        ))
                    ) : (
                        fb_countries.filter(f => (
                            f.name.toLowerCase().includes(countryField.userInput.toLowerCase()) || f.country_code.toLowerCase().includes(countryField.userInput.toLowerCase())
                        )).map((e, i) => {
                            const isSelected = info.country.includes(e.country_code)
                            return (
                                <div key={i}
                                    style={{ width: '100%', padding: '5px', display: 'grid', gridTemplateColumns: '15px 1fr', gap: '5px', cursor: 'pointer' }}
                                    onClick={() => handleUpdateBulkCountryClick({ code: e.country_code, action: isSelected ? 'remove' : 'add', type: 'country', isRegionSelected: false })}
                                    onMouseEnter={e => e.currentTarget.style.background = '#EEE'}
                                    onMouseLeave={e => e.currentTarget.style.background = '#FFF'}
                                >
                                    <input type="checkbox" checked={isSelected ? true : false} readOnly={true} style={{ height: '15px', width: '15px', pointerEvents: 'none' }}/>
                                    <div style={{  paddingLeft: '5px', fontSize: '12px' }}>{e.name}</div>
                                </div>
                            )
                        })
                    )}
                </div>
            </div>
        }

        return result
    }

    // returns jsx for table's first header
    const tableHeader1 = () => {
        const bulk_list = Object.values(bulkUpdate.list)
        let result = <>Select</>

        if (bulk_list.length > 0) {
            result = <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', gap: '5px' }}>
                {!bulk_list.find(f => ((f?.case === 'Ads Creation') && (f?.finished === true)) || (f?.case === 'Ads Creation (Keywords)')) && (
                    <div style={{ height: '35px', width: '35px', background: '#4CAF50', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', cursor: 'pointer' }}
                        onMouseEnter={e => e.currentTarget.style.opacity = '50%'}
                        onMouseLeave={e => e.currentTarget.style.opacity = '100%'}
                        onClick={() => setBulkUpdate(p => { return { ...p, display_list: true } })}
                    >
                        <MdEdit size={24} color='#FFF'/>
                    </div>
                )}
                <div style={{ height: '35px', width: '35px', background: '#EF5350', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', cursor: 'pointer' }}
                    onMouseEnter={e => e.currentTarget.style.opacity = '50%'}
                    onMouseLeave={e => e.currentTarget.style.opacity = '100%'}
                    onClick={() => setBulkUpdate(p => { return { ...p, keyword_error: [], list: {}, duplicate_url: [] } })}
                >
                    <FaXmark size={24} color='#FFF'/>
                </div>
                {(!bulk_list.find(f => (f?.case === 'Facebook') || (f?.finished === false) || (f?.facebook_fan_page === '') || (f?.comment?.trim() === '') || (typeof f?.final_url === 'string' && f?.final_url?.trim() === '') || (Array.isArray(f?.final_url) && (f?.final_url?.filter(f => !f)?.length === f?.final_url?.length)) || !f?.result?.ad || (Array.isArray(f?.result?.ad) && (f?.result?.ad?.filter(fin => fin?.comment_posted === true)?.length === f?.result?.ad?.length)) )) ? (
                    <div style={{ height: '35px', width: '35px', background: '#1E90FF', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', cursor: 'pointer' }}
                        onMouseEnter={e => e.currentTarget.style.opacity = '50%'}
                        onMouseLeave={e => e.currentTarget.style.opacity = '100%'}
                        onClick={() => bulkPostComment({ items: bulk_list, fb_ids: data.fb, fb_fan_pages_no_token: facebook_fan_pages, setUpdateLoading, setRefresh: setRefresh, fb_token: data.fb_token, appContext })}
                    >
                        <FaCommentDots size={24} color='#FFF' />
                    </div>
                ) : (<></>)}
                {(!bulk_list.find(f => (
                        (f?.case === 'Facebook') ||
                        (f?.finished === true) ||
                        (f?.facebook_fan_page === '') ||
                        (f?.comment?.trim() === '') ||
                        (f?.fb_id === '') ||
                        (f?.country?.trim() === '') ||
                        (typeof f?.final_url === 'string' && f?.final_url?.trim() === '') ||
                        (Array.isArray(f?.final_url) && (f?.final_url?.filter(f => !f)?.length === f?.final_url?.length)) ||
                        (f?.headline?.[0]?.trim() === '') ||
                        (f?.message?.[0]?.trim() === '') ||
                        (f?.picture?.[0]?.trim() === '') ||
                        (f?.result && f?.result?.campaign?.id && (f?.result?.adset?.length === f?.result?.adset?.filter(f => f?.id)?.length) && (f?.result?.ad?.length === f?.result?.ad?.filter(f => f?.id)?.length))
                    ))
                ) ? (
                    <div style={{ height: '35px', width: '35px', background: '#4CAF50', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', cursor: 'pointer' }}
                        onMouseEnter={e => e.currentTarget.style.opacity = '50%'}
                        onMouseLeave={e => e.currentTarget.style.opacity = '100%'}
                        onClick={() => bulkCreateFacebookCampaignAPI({ items: bulk_list, fb_ids: data.fb, setUpdateLoading, setRefresh, appContext })}
                    >
                        <MdCampaign size={30} color='#FFF' />
                    </div>
                ) : (<></>)}
                {((Cookies.get('role') === 'admin') || (Cookies.get('manager') === 'admin') || (Cookies.get('userArray') === 'LFCWidget')) ? (
                    <div style={{ position: 'relative', height: 'fit-content', width: 'fit-content', cursor: 'pointer' }} onMouseEnter={e => e.currentTarget.lastChild.style.scale = 1} onMouseLeave={e => e.currentTarget.lastChild.style.scale = 0}>
                        <div style={{ height: '35px', width: '35px', background: '#555555', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', cursor: 'pointer' }}>
                            <FaCopy size={20} color='#FFF' />
                        </div>
                        <div style={{ position: 'absolute', zIndex: 10, height: 'fit-content', width: 'fit-content', background: '#000', padding: '5px', borderRadius: '10px', transformOrigin: 'top left', transition: '100ms', scale: 0 }}>
                        {(Cookies.get('userArray') === 'LFCWidget') ? (
                            ["LFC", "Southpow", "Moneyball"].map((mp, i) => (
                                <div key={i}
                                    style={{ padding: '5px', color: '#FFF', borderRadius: '5px', textAlign: 'left' }}
                                    onMouseEnter={e => e.currentTarget.style.background = 'rgba(255, 255, 255, 0.5)'}
                                    onMouseLeave={e => e.currentTarget.style.background = 'transparent'}
                                    onClick={e => handleBulkCopy(mp)}
                                >
                                    {mp}
                                </div>
                            ))
                        ) : (
                            users.filter(f => f.role === 'manager').map((mp, i) => (
                                <div key={i}
                                    style={{ padding: '5px', color: '#FFF', borderRadius: '5px', textAlign: 'left' }}
                                    onMouseEnter={e => e.currentTarget.style.background = 'rgba(255, 255, 255, 0.5)'}
                                    onMouseLeave={e => e.currentTarget.style.background = 'transparent'}
                                    onClick={e => handleBulkCopy(mp.username)}
                                >
                                    {mp.username}
                                </div>
                            ))
                        )}
                            
                        </div>
                    </div>
                ) : (
                    <div style={{ height: '35px', width: '35px', background: '#555555', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', cursor: 'pointer' }}
                        onMouseEnter={e => e.currentTarget.style.opacity = '50%'}
                        onMouseLeave={e => e.currentTarget.style.opacity = '100%'}
                        onClick={() => Cookies.get('manager') ? handleBulkCopy(Cookies.get('manager')) : appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Manager name missing", content: "* To FIX - Please logout, reload your browser and login again." }], confirm_function: () => {}, cancel_function: () => {} }})}
                    >
                        <FaCopy size={20} color='#FFF'/>
                    </div>
                )}
                <div style={{ height: '35px', width: '35px', background: '#EF5350', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', cursor: 'pointer' }}
                    onMouseEnter={e => e.currentTarget.style.opacity = '50%'}
                    onMouseLeave={e => e.currentTarget.style.opacity = '100%'}
                    onClick={() => handleBulkDelete()}
                >
                    <FaTrashAlt size={20} color='#FFF'/>
                </div>
            </div>
        } else {
            result = <>Select</>
        }
        
        return result
    }

    return (
        <div className="widget-container" style={{ gridAutoFlow: 'row', gridAutoRows: 'min-content min-content min-content 1fr', position: 'relative' }}>
            {/* loader */}
            {(loading || updateLoading) ? (
                <div className="ripple-logo-container" style={{ zIndex: 500 }}>
                    <div className="loader" style={{ width: '200px', height: '200px', border: '5px solid #0000FF', borderTop: '5px solid #E6E6FF' }}></div>
                </div>
            ) : (<></>)}
            
            {/* same url widget bulk delete component */}
            <SameUrlDeleteComponent
                bulkUpdate={bulkUpdate}
                setBulkUpdate={setBulkUpdate}
                filteredDataToMap={filteredDataToMap}
                deleteFunction={handleDeleteRow}
            />

            {/* facebook image or video selection component */}
            <FacebookMediaSelectComponent
                loading={(data.loading.fb_images || uploadFbImage.loading) ? true : false}
                fbMediaState={fbMediaState}
                setFbMediaState={setFbMediaState}
                fb_images={data.fb_images}
                fb_videos={data.fb_videos}
                addDetail={addDetail}
                setAddDetail={setAddDetail}
                updateDetail={updateDetail}
                setUpdateDetail={setUpdateDetail}
                bulkUpdate={bulkUpdate}
                setBulkUpdate={setBulkUpdate}
                uploadFbImage={uploadFbImage}
                setUploadFbImage={setUploadFbImage}
                fb_token={data.fb_token}
                appContext={appContext}
            />

            {/* client select */}
            <div className='widget-top'>
                {((Cookies.get('role') === 'admin') || (Cookies.get('manager') === 'admin') || (Cookies.get('userArray') === 'LFCWidget')) ? (
                    <select className='widget2-select' style={{ marginRight: '10px' }} value={filterUser} onChange={e => { setFilterUser(e.target.value); setPageOptions(p => { return { ...p, current: 0 } }); setBulkUpdate(p => {return{...p, loading: false, display_list: false, list: {}, duplicate_url: [], keyword_error: [], open_category: 0, open_drop_down: '', display_multi_delete: false, clear_list: true}}) }}>
                        {(Cookies.get('userArray') === 'LFCWidget') ? (
                            ["LFC", "Southpow", "Moneyball"].map((e, i) => (
                                <option value={e} key={i}>{e}</option>
                            ))
                        ) : (
                            users?.filter(f => f.role === 'manager').map((e, i) => (
                                <option value={e.username} key={i}>{e.username}</option>
                            ))
                        )}
                    </select>
                ) : (<></>)}
                <div className="widget-top-option" onClick={() => setSelectedClient('All')} style={{ background: (selectedClient === 'All') ? '#1c1c1e' : '#FFFFFF', color : (selectedClient === 'All') ? '#FFFFFF' : '#1c1c1e' }}>All</div>
                <div className="widget-top-option" onClick={() => setSelectedClient('Sedo')} style={{ background: (selectedClient === 'Sedo') ? '#1c1c1e' : '#FFFFFF', color : (selectedClient === 'Sedo') ? '#FFFFFF' : '#1c1c1e' }}>Sedo</div>
                <div className="widget-top-option" onClick={() => setSelectedClient('System1')} style={{ background: (selectedClient === 'System1') ? '#1c1c1e' : '#FFFFFF', color : (selectedClient === 'System1') ? '#FFFFFF' : '#1c1c1e' }}>System1</div>
                <div className="widget-top-option" onClick={() => setSelectedClient('Tonic')} style={{ background: (selectedClient === 'Tonic') ? '#1c1c1e' : '#FFFFFF', color : (selectedClient === 'Tonic') ? '#FFFFFF' : '#1c1c1e' }}>Tonic</div>
                <div className="widget-top-option" onClick={() => setSelectedClient('Ads')} style={{ background: (selectedClient === 'Ads') ? '#1c1c1e' : '#FFFFFF', color : (selectedClient === 'Ads') ? '#FFFFFF' : '#1c1c1e' }}>Ads</div>
                <div className="widget-top-option" onClick={() => setSelectedClient('Adcombo')} style={{ background: (selectedClient === 'Adcombo') ? '#1c1c1e' : '#FFFFFF', color : (selectedClient === 'Adcombo') ? '#FFFFFF' : '#1c1c1e' }}>Adcombo</div>
                <div className="widget-top-option" onClick={() => setSelectedClient('Crossroads')} style={{ background: (selectedClient === 'Crossroads') ? '#1c1c1e' : '#FFFFFF', color : (selectedClient === 'Crossroads') ? '#FFFFFF' : '#1c1c1e' }}>Crossroads</div>
                <div style={{ padding: '10px', border: '1px solid #FF7F7F', borderRadius: '10px', color: '#FF7F7F', marginRight: '10px', display: 'flex', gap: '5px', cursor: 'default' }}>
                    <div>Auto-ON-Camps Tomorrow:</div>
                    <div style={{ fontWeight: 700 }}>{reActCampaigns.loading ? (<div className='loader' style={{ height: '20px', width: '20px', border: '5px solid #FFF2F2', borderTop: '5px solid #FF7F7F' }}/>) : reActCampaigns.count}</div>
                </div>
            </div>

            {/* add new widget */}
            <form className="widget-top" style={{ gap: '10px', alignItems: 'center', pointerEvents: selectedRow }} onSubmit={e => handleSubmitAdd(e)}>
                <p>ADD:</p>
                <div className='widget-add-field'>
                    <p className='widget-add-field-float-name'>Case</p>
                    <select className='widget2-select' name='case' value={addDetail.case}
                        onFocus={e => e.target.style.borderColor = '#000'}
                        onBlur={e => e.target.style.borderColor = '#AAA'}
                        onChange={e => {
                            if (e.target.value === "Ads Creation (Keywords)"){
                                setAddDetail(p => {return{...p, country: '', case: e.target.value, categories: [], daily_budget: '1', headline: ["","",""], picture: ["","",""], ad_media: [{},{},{}], message: ["","",""], comment: "", adset_count: '3', facebook_fan_page: '', instagram_account: '', }})
                            } else {
                                setAddDetail(p => {return{...p, country: '', case: e.target.value, categories: [], daily_budget: '1', headline: ["","","","",""], picture: ["","","","",""], ad_media: [{},{},{},{},{}], message: ["","","","",""], comment: "", adset_count: '5', facebook_fan_page: '', instagram_account: '', }})
                            }
                        }}
                    >
                        {(facebook_fan_pages.length > 0) ? (
                            <>
                                <option value="Ads Creation">Ads Creation</option>
                                <option value="Ads Creation (Keywords)">Ads Creation (Keywords)</option>
                                <option value="Facebook">Widget Old</option>
                            </>
                        ) : (
                            <option value="Facebook">Widget Old</option>
                        )}
                    </select>
                </div>
                {(addDetail.case === "Ads Creation" || addDetail.case === "Ads Creation (Keywords)") ? (
                    countryFieldComponent({ info: {...addDetail}, cond: 'add' })
                ) : (
                    <div className='widget-add-field'>
                        <p className='widget-add-field-float-name'>Country</p>
                        <input className='widget2-input' type="text" name='country' autoComplete='off' placeholder='input country' value={addDetail.country} onChange={e => handleChangeAdd(e)} />
                    </div>
                )}
                <div className='widget-add-field'>
                    <p className='widget-add-field-float-name'>Topic</p>
                    <input className='widget2-input' type="text" name='topic' autoComplete='off' placeholder='input topic' value={addDetail.topic} onChange={e => handleChangeAdd(e)} />
                </div>

                {(addDetail.case === "Ads Creation") && (
                    <>
                        <div className='widget-add-field'>
                            <p className='widget-add-field-float-name'>Facebook A/C</p>
                            <select className='widget2-select' name='fb_id' value={addDetail.fb_id} onChange={e => handleChangeAdd(e)} onFocus={e => e.target.style.borderColor = '#000'} onBlur={e => e.target.style.borderColor = '#AAA'}>
                                <option value="">Choose facebook A/C</option>
                                {data.fb.map((e, i) => (
                                    <option value={e.unique} key={i}>{e.name} {"("}{e.client}{")"}</option>
                                ))}
                            </select>
                        </div>
                        <div className='widget-add-field' onMouseEnter={() => setAddSelectDisplay('facebook_fan_page')} onMouseLeave={() => setAddSelectDisplay('')}>
                            <p className='widget-add-field-float-name'>FB Fan Page</p>
                            <div className='widget2-div' style={{ borderColor: (addSelectDisplay === 'facebook_fan_page') ? '#000' : '#AAA' }}>
                                <div style={{ fontSize: '14px' }}>{!addDetail.facebook_fan_page.trim() ? 'None selected' : facebook_fan_pages?.find(f => f?.id === addDetail.facebook_fan_page)?.name}</div>
                                <ul style={{ position: 'absolute', top: '38px', height: 'fit-content', maxHeight: '300px', background: '#FFF', display: (addSelectDisplay === 'facebook_fan_page') ? 'block' : 'none' }}>
                                    <li style={{ background: (addDetail.facebook_fan_page === '') ? '#1E90FF' : '#FFF', color: (addDetail.facebook_fan_page === '') ? '#FFF' : '#000', borderBottom: '1px solid #C9E7CB' }}
                                        onMouseEnter={hov => hov.currentTarget.style.filter = "invert(20%)"}
                                        onMouseLeave={hov => hov.currentTarget.style.filter = "invert(0%)"}
                                        onClick={() => setAddDetail(p => { return { ...p, facebook_fan_page: '' }})}
                                    >
                                        NONE
                                    </li>
                                    {facebook_fan_pages?.sort((a, b) => a?.name > b?.name ? 1 : -1).map((e, i) => (
                                        <li key={i} style={{ background: (addDetail.facebook_fan_page === e?.id) ? '#1E90FF' : '#FFF', color: (addDetail.facebook_fan_page === e?.id) ? '#FFF' : '#000', borderBottom: '1px solid #DDD' }}
                                            onMouseEnter={hov => hov.currentTarget.style.filter = "invert(20%)"}
                                            onMouseLeave={hov => hov.currentTarget.style.filter = "invert(0%)"}
                                            onClick={() => setAddDetail(p => { return { ...p, facebook_fan_page: e?.id }})}
                                        >
                                            {e?.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className='widget-add-field' onMouseEnter={() => setAddSelectDisplay('categories')} onMouseLeave={() => setAddSelectDisplay('')}>
                            <p className='widget-add-field-float-name'>Categories</p>
                            <div className='widget2-div' style={{ borderColor: (addSelectDisplay === 'categories') ? '#000' : '#AAA' }}>
                                <div style={{ fontSize: '14px' }}>{(addDetail.categories.length === 0) ? 'None selected' : addDetail.categories.map(e => e[0]+e[1]+e[2]).join(", ")}</div>
                                <ul style={{ position: 'absolute', top: '38px', height: 'fit-content', background: '#FFF', display: (addSelectDisplay === 'categories') ? 'block' : 'none' }}>
                                    <li style={{ backgroundColor: (addDetail.categories.length === 0) ? '#dbeafe' : '#FFF', wordBreak: 'break-all' }}
                                        onClick={() => {
                                            setAddDetail(p => { return { ...p, categories: [] } })
                                        }}
                                    >
                                        NONE
                                    </li>
                                    {["CREDIT", "HOUSING", "ISSUES_ELECTIONS_POLITICS", "EMPLOYMENT"].map((cat, i) => (
                                        <li key={i} style={{ backgroundColor: (addDetail.categories.find(f => f === cat)) ? '#dbeafe' : '#FFF', wordBreak: 'break-all' }}
                                            onMouseEnter={e => e.currentTarget.style.opacity = 0.5}
                                            onMouseLeave={e => e.currentTarget.style.opacity = 1}
                                            onClick={() => {
                                                let temp = [...addDetail.categories]
                                                let find_category = temp.filter(f => f === cat)
                                                if (find_category.length > 0) {
                                                    temp = temp.filter(f => f !== cat)
                                                } else {
                                                    temp.push(cat)
                                                }
                                                setAddDetail(p => { return { ...p, categories: temp } })
                                            }}
                                        >
                                            {cat}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className='widget-add-field'>
                            <p className='widget-add-field-float-name'>{"Daily Budget (USD 1 - 10)"}</p>
                            <div className='widget2-input' style={{ display: 'flex', alignItems: 'center', padding: '0px 0px 0px 5px' }}>
                                <p>$</p>
                                <input className='widget2-input' type="number" name='daily_budget' placeholder='1 - 10' autoComplete='off' value={addDetail.daily_budget} onChange={e => handleChangeAdd(e)} style={{ border: '0px' }} onWheel={e => e.target.blur()}
                                    onFocus={e => e.target.parentNode.style.borderColor = '#000'}
                                    onBlur={e => {
                                        e.target.parentNode.style.borderColor = '#AAA'
                                        let temp_count = e.target.value
    
                                        if (!temp_count || Number(temp_count) < 1) {
                                            temp_count = '1'
                                        } else if (Number(temp_count) > 10) {
                                            temp_count = '10'
                                        }
    
                                        setAddDetail(p => {
                                            return {
                                                ...p,
                                                daily_budget: temp_count,
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className='widget-add-field'>
                            <p className='widget-add-field-float-name'>{"Adset Count (1 - 20)"}</p>
                            <input className='widget2-input' type="number" name='adset_count' placeholder='1 - 20' autoComplete='off' value={addDetail.adset_count} onChange={e => handleChangeAdd(e)} onWheel={e => e.target.blur()}
                                onBlur={e => {
                                    let temp_count = e.target.value

                                    if (!temp_count || Number(temp_count) < 1) {
                                        temp_count = '1'
                                    } else if (Number(temp_count) > 20) {
                                        temp_count = '20'
                                    } else {
                                        temp_count = Number(temp_count)?.toFixed(0)
                                    }

                                    setAddDetail(p => {
                                        return {
                                            ...p,
                                            adset_count: temp_count,
                                            headline: Array(Number(temp_count)).fill(""),
                                            message: Array(Number(temp_count)).fill(""),
                                            picture: Array(Number(temp_count)).fill(""),
                                            ad_media: Array(Number(temp_count)).fill({}),
                                        }
                                    })
                                }}
                            />
                        </div>
                        <div className='widget-add-field'>
                            <p className='widget-add-field-float-name'>Headline</p>
                            <input className='widget2-input' type="text" name='headline' placeholder='input headline' autoComplete='off' value={addDetail.headline[0]} onChange={e => handleChangeAdd(e)} />
                        </div>
                        <div className='widget-add-field' style={{ height: 'fit-content' }}>
                            <p className='widget-add-field-float-name'>Ad Text</p>
                            <textarea className='widget2-textarea' type="text" name='message' placeholder='input ad text' value={addDetail.message[0]} onChange={e => handleChangeAdd(e)} />
                        </div>
                        <div className='widget-add-field' style={{ height: 'fit-content' }}>
                            <p className='widget-add-field-float-name'>Comment</p>
                            <textarea className='widget2-textarea' type="text" name='comment' placeholder='input comment' value={addDetail.comment} onChange={e => handleChangeAdd(e)} />
                        </div>

                        <div className='widget-add-field' onClick={() => setFbMediaState(p => { return { ...p, display: true, mode: 'add', act_id: data.fb.find(f => (f.unique === addDetail?.fb_id))?.id, widget: 0 } })}>
                            <p className='widget-add-field-float-name'>Media</p>
                            <div className='widget2-div' style={{ borderColor: (addSelectDisplay === 'adImages') ? '#000' : '#AAA' }}>
                                <div style={{ fontSize: '14px' }}>
                                    {addDetail.picture.find(f => f) ? (
                                        addDetail.picture.filter(f => f).length + " / " + addDetail.picture.length + " selected"
                                    ) : "None selected"}
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {(addDetail.case === "Ads Creation (Keywords)") && (
                    <>
                        <div className='widget-add-field'>
                            <p className='widget-add-field-float-name'>Facebook A/C</p>
                            <select className='widget2-select' name='fb_id' value={addDetail.fb_id} onChange={e => handleChangeAdd(e)} onFocus={e => e.target.style.borderColor = '#000'} onBlur={e => e.target.style.borderColor = '#AAA'}>
                                <option value="">Choose facebook A/C</option>
                                {data.fb.filter(f => f.client === 'Sedo').map((e, i) => (
                                    <option value={e.unique} key={i}>{e.name} {"("}{e.client}{")"}</option>
                                ))}
                            </select>
                        </div>
                        <div className='widget-add-field' onMouseEnter={() => setAddSelectDisplay('facebook_fan_page')} onMouseLeave={() => setAddSelectDisplay('')}>
                            <p className='widget-add-field-float-name'>FB Fan Page</p>
                            <div className='widget2-div' style={{ borderColor: (addSelectDisplay === 'facebook_fan_page') ? '#000' : '#AAA' }}>
                                <div style={{ fontSize: '14px' }}>{!addDetail.facebook_fan_page.trim() ? 'None selected' : facebook_fan_pages?.find(f => f?.id === addDetail.facebook_fan_page)?.name}</div>
                                <ul style={{ position: 'absolute', top: '38px', height: 'fit-content', maxHeight: '300px', background: '#FFF', display: (addSelectDisplay === 'facebook_fan_page') ? 'block' : 'none' }}>
                                    <li style={{ background: (addDetail.facebook_fan_page === '') ? '#1E90FF' : '#FFF', color: (addDetail.facebook_fan_page === '') ? '#FFF' : '#000', borderBottom: '1px solid #C9E7CB' }}
                                        onMouseEnter={hov => hov.currentTarget.style.filter = "invert(20%)"}
                                        onMouseLeave={hov => hov.currentTarget.style.filter = "invert(0%)"}
                                        onClick={() => setAddDetail(p => { return { ...p, facebook_fan_page: '' }})}
                                    >
                                        NONE
                                    </li>
                                    {facebook_fan_pages?.sort((a, b) => a?.name > b?.name ? 1 : -1).map((e, i) => (
                                        <li key={i} style={{ background: (addDetail.facebook_fan_page === e?.id) ? '#1E90FF' : '#FFF', color: (addDetail.facebook_fan_page === e?.id) ? '#FFF' : '#000', borderBottom: '1px solid #DDD' }}
                                            onMouseEnter={hov => hov.currentTarget.style.filter = "invert(20%)"}
                                            onMouseLeave={hov => hov.currentTarget.style.filter = "invert(0%)"}
                                            onClick={() => setAddDetail(p => { return { ...p, facebook_fan_page: e?.id }})}
                                        >
                                            {e?.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className='widget-add-field' onMouseEnter={() => setAddSelectDisplay('categories')} onMouseLeave={() => setAddSelectDisplay('')}>
                            <p className='widget-add-field-float-name'>Categories</p>
                            <div className='widget2-div' style={{ borderColor: (addSelectDisplay === 'categories') ? '#000' : '#AAA' }}>
                                <div style={{ fontSize: '14px' }}>{(addDetail.categories.length === 0) ? 'None selected' : addDetail.categories.map(e => e[0]+e[1]+e[2]).join(", ")}</div>
                                <ul style={{ position: 'absolute', top: '38px', height: 'fit-content', background: '#FFF', display: (addSelectDisplay === 'categories') ? 'block' : 'none' }}>
                                    <li style={{ backgroundColor: (addDetail.categories.length === 0) ? '#dbeafe' : '#FFF', wordBreak: 'break-all' }}
                                        onClick={() => {
                                            setAddDetail(p => { return { ...p, categories: [] } })
                                        }}
                                    >
                                        NONE
                                    </li>
                                    {["CREDIT", "HOUSING", "ISSUES_ELECTIONS_POLITICS", "EMPLOYMENT"].map((cat, i) => (
                                        <li key={i} style={{ backgroundColor: (addDetail.categories.find(f => f === cat)) ? '#dbeafe' : '#FFF', wordBreak: 'break-all' }}
                                            onMouseEnter={e => e.currentTarget.style.opacity = 0.5}
                                            onMouseLeave={e => e.currentTarget.style.opacity = 1}
                                            onClick={() => {
                                                let temp = [...addDetail.categories]
                                                let find_category = temp.filter(f => f === cat)
                                                if (find_category.length > 0) {
                                                    temp = temp.filter(f => f !== cat)
                                                } else {
                                                    temp.push(cat)
                                                }
                                                setAddDetail(p => { return { ...p, categories: temp } })
                                            }}
                                        >
                                            {cat}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className='widget-add-field'>
                            <p className='widget-add-field-float-name'>{"Daily Budget (USD 1 - 10)"}</p>
                            <div className='widget2-input' style={{ display: 'flex', alignItems: 'center', padding: '0px 0px 0px 5px' }}>
                                <p>$</p>
                                <input className='widget2-input' type="number" name='daily_budget' placeholder='1 - 10' autoComplete='off' value={addDetail.daily_budget} onChange={e => handleChangeAdd(e)} style={{ border: '0px' }} onWheel={e => e.target.blur()}
                                    onFocus={e => e.target.parentNode.style.borderColor = '#000'}
                                    onBlur={e => {
                                        e.target.parentNode.style.borderColor = '#AAA'
                                        let temp_count = e.target.value
    
                                        if (!temp_count || Number(temp_count) < 1) {
                                            temp_count = '1'
                                        } else if (Number(temp_count) > 10) {
                                            temp_count = '10'
                                        }
    
                                        setAddDetail(p => {
                                            return {
                                                ...p,
                                                daily_budget: temp_count,
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className='widget-add-field' style={{ cursor: 'not-allowed' }}>
                            <p className='widget-add-field-float-name'>{"Adset Count"}</p>
                            <div className='widget2-input' style={{ display: 'flex', alignItems: 'center' }}>{addDetail.adset_count}</div>
                        </div>
                        <div className='widget-add-field'>
                            <p className='widget-add-field-float-name'>Headline</p>
                            <input className='widget2-input' type="text" name='headline' placeholder='input headline' autoComplete='off' value={addDetail.headline[0]} onChange={e => handleChangeAdd(e)} />
                        </div>
                        <div className='widget-add-field' style={{ height: 'fit-content' }}>
                            <p className='widget-add-field-float-name'>Ad Text</p>
                            <textarea className='widget2-textarea' type="text" name='message' placeholder='input ad text' value={addDetail.message[0]} onChange={e => handleChangeAdd(e)} />
                        </div>
                        <div className='widget-add-field' style={{ height: 'fit-content' }}>
                            <p className='widget-add-field-float-name'>Comment</p>
                            <textarea className='widget2-textarea' type="text" name='comment' placeholder='input comment' value={addDetail.comment} onChange={e => handleChangeAdd(e)} />
                        </div>

                        <div className='widget-add-field' onClick={() => setFbMediaState(p => { return { ...p, display: true, mode: 'add', act_id: data.fb.find(f => (f.unique === addDetail?.fb_id))?.id, widget: 0 } })}>
                            <p className='widget-add-field-float-name'>Media</p>
                            <div className='widget2-div' style={{ borderColor: (addSelectDisplay === 'adImages') ? '#000' : '#AAA' }}>
                                <div style={{ fontSize: '14px' }}>
                                    {addDetail.picture.find(f => f) ? (
                                        addDetail.picture.filter(f => f).length + " / " + addDetail.picture.length + " selected"
                                    ) : "None selected"}
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {(addDetail.case === "Facebook") && (
                    <div className='widget-add-field'>
                        <p className='widget-add-field-float-name'>Facebook A/C</p>
                        <select className='widget2-select' name='fb_id' value={addDetail.fb_id} onChange={e => handleChangeAdd(e)} onFocus={e => e.target.style.borderColor = '#000'} onBlur={e => e.target.style.borderColor = '#AAA'}>
                            <option value="">Choose facebook A/C</option>
                            {data.fb.map((e, i) => (
                                <option value={e.unique} key={i}>{e.name} {"("}{e.client}{")"}</option>
                            ))}
                        </select>
                    </div>
                )}
                
                <div className='widget-add-field'>
                    <p className='widget-add-field-float-name'>Note</p>
                    <input className='widget2-input' type="text" name='note' placeholder='input note' autoComplete='off' value={addDetail.note} onChange={e => handleChangeAdd(e)} />
                </div>
                <button className='widget2-button' type='submit'>SUBMIT</button>
                <CSVLink data={dataCSV} headers={headerCSV} filename={"Widget Report.csv"} target="_blank" className='widget2-button' style={{ maxWidth: 'fit-content', padding: '0px 5px', background: '#1E90FF', textDecoration: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Download CSV</CSVLink>
            </form>

            {/* page change */}
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', flexWrap: 'wrap' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <p>Page: {pageOptions.current + 1}</p>
                    {(pageOptions.current > 0) ? (
                        <div style={{ height: '30px', width: '30px', background: "#000", borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => (pageOptions.current > 0) && setPageOptions(p => { return { ...p, current: p.current - 1 }})}><FaChevronLeft size={20} color={'#FFFFFF'}/></div>
                    ) : (<>||</>)}
                    <div>{pageOptions.current*100} - {(pageOptions.current*100) + pageOptions.total_length}</div>
                    {pageOptions.display_next ? (
                        <div style={{ height: '30px', width: '30px', background: "#000", borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => setPageOptions(p => { return { ...p, current: p.current + 1 } })}><FaChevronRight size={20} color={'#FFFFFF'}/></div>
                    ) : (<></>)}
                </div>
                <select title='Select which column you want to search.' value={selectedSearchOption.type} onChange={e => setSelectedSearchOption(p => {return{...p, type: e.target.value}})} style={{ height: '40px', width: '100%', maxWidth: '100px', border: '1px solid #CCC', borderRadius: '5px', background: 'transparent', cursor: 'pointer' }}>
                    <option value="widget">Widget</option>
                    <option value="country">Country</option>
                    <option value="topic">Topic</option>
                    <option value="url">URL</option>
                    <option value="note">Note</option>
                    <option value="case">Case</option>
                </select>
                <div style={{ height: '40px', width: '100%', maxWidth: '200px', display: 'grid', gridAutoFlow: 'column', gridAutoColumns: '30px 1fr', border: '1px solid #CCC', borderRadius: '5px', overflow: 'hidden' }}>
                    <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><FaSearch size={20} color='#CCC'/></div>
                    <input type={(selectedSearchOption.type === 'widget') ? "number" : "text"} placeholder={`search by ${selectedSearchOption.type}`} style={{ height: '100%', width: '100%', border: 'none', outline: 'none' }}
                        value={selectedSearchOption.input}
                        onChange={e => setSelectedSearchOption(p => {return{...p, input: e.target.value}})}
                        onWheel={e => e.currentTarget.blur()}
                        onKeyDown={e => {
                            if ((e.key === 'Enter') && selectedSearchOption.input.trim()) {
                                searchWidgetAPI({ selectedSearchOption, setFilteredDataToMap, setUpdateLoading, filterUser })
                            }
                        }}
                    />
                </div>
                <div style={{ height: '40px', width: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#4caf50', borderRadius: '5px', border: '1px solid #4caf50', color: '#FFFFFF', cursor: 'pointer' }}
                    onMouseEnter={e => e.currentTarget.style.filter = "brightness(90%)"}
                    onMouseLeave={e => e.currentTarget.style.filter = "brightness(100%)"}
                    onClick={() => selectedSearchOption.input.trim() && searchWidgetAPI({ selectedSearchOption, setFilteredDataToMap, setUpdateLoading, filterUser })}
                >
                    Search
                </div>
                <div style={{ height: '40px', width: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#FFFFFF', borderRadius: '5px', border: '1px solid #FF0000', color: '#FF0000', cursor: 'pointer' }}
                    onMouseEnter={e => e.currentTarget.style.filter = "brightness(90%)"}
                    onMouseLeave={e => e.currentTarget.style.filter = "brightness(100%)"}
                    onClick={() => {
                        setFilteredDataToMap(filteredData)
                        setSelectedSearchOption(p => { return { ...p, input: '', result: [] } })
                    }}
                >
                    Clear
                </div>
            </div>

            {/* widget table */}
            {bulkUpdate.display_list ? (
                <>
                    {bulkUpdateReturnFunction()}
                </>
            ) : (
                <div id="widget-table-container-scroll" className="widget-table-container">
                    <table className="widget-table">
                        <thead>
                            <tr>
                                <th style={{ zIndex: '20' }}>
                                    {tableHeader1()}
                                </th>
                                <th>Action</th>
                                <th style={{ minWidth: '120px', maxWidth: '120px' }}>Campaign Name</th>
                                <th style={{ minWidth: '120px', maxWidth: '120px' }}>Country</th>
                                <th style={{ minWidth: '200px', maxWidth: '200px' }}>Topic</th>
                                <th style={{ minWidth: '150px', maxWidth: '150px' }}>FB Acc</th>
                                <th style={{ minWidth: '100px', maxWidth: '100px' }}>Client</th>
                                <th style={{ minWidth: '150px', maxWidth: '150px' }}>Categories</th>
                                <th style={{ minWidth: '100px', maxWidth: '100px' }}>Daily Budget</th>
                                <th style={{ minWidth: '200px', maxWidth: '200px' }}>Headline</th>
                                <th style={{ minWidth: '200px', maxWidth: '200px' }}>Ad Text</th>
                                <th style={{ minWidth: '200px', maxWidth: '200px' }}>Comment</th>
                                <th style={{ minWidth: '200px', maxWidth: '200px' }}>Media</th>
                                <th style={{ minWidth: '200px', maxWidth: '200px' }}>Ad Posts</th>
                                <th style={{ minWidth: '200px', maxWidth: '200px' }}>URL</th>
                                <th style={{ minWidth: '200px', maxWidth: '200px' }}>Keywords</th>
                                <th style={{ minWidth: '200px', maxWidth: '200px' }}>Final URL</th>
                                <th style={{ minWidth: '200px', maxWidth: '200px' }}>Note</th>
                                <th style={{ minWidth: '100px', maxWidth: '100px' }}>Case</th>
                            </tr>
                        </thead>

                        <tbody>
                            {filteredDataToMap.map((fd, i) => {
                                return (
                                    <Fragment key={i}>

                                        {/* once update button is clicked the following tags with inputs is mapped */}

                                        {(selectedRow.widget === fd.widget) ? (
                                            <tr style={{ background: findBgColor(fd) }}>
                                                <td style={{ background: '#EEEEEE' }}>
                                                    <div className="widget-table-cell" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <input type="checkbox" style={{ height: '30px', width: '30px', cursor: "pointer" }}
                                                            checked={bulkUpdate?.list?.[fd?.widget?.toString()] ? true : false}
                                                            onChange={e => {
                                                                if (e.target.checked) {
                                                                    setBulkUpdate(p => {
                                                                        const tempObj = { ...p.list }
                                                                        if (fd.case === 'Ads Creation (Keywords)') {
                                                                            tempObj[fd?.widget?.toString()] = {
                                                                                ...fd,
                                                                                keyword: Array.isArray(fd?.keyword) ? fd?.keyword?.join('\n') : fd?.keyword,
                                                                                categories: fd.categories ? fd.categories : [],
                                                                                facebook_fan_page: fd.facebook_fan_page ? fd.facebook_fan_page : '',
                                                                                instagram_account: fd.instagram_account ? fd.instagram_account : '',
                                                                                daily_budget: fd.daily_budget ? fd.daily_budget : '1',
                                                                                headline: fd.headline ? fd.headline : ["","",""],
                                                                                message: fd.message ? fd.message : ["","",""],
                                                                                comment: fd.comment ? fd.comment : "",
                                                                                ad_media: fd.ad_media ? fd.ad_media : ((fd.picture && Array.isArray(fd.picture) && fd.picture.find(f => f)) ? fd.picture.map(e => {return{type: 'image', url: e}}) : [{},{},{}]),
                                                                                picture: fd.picture ? fd.picture : ["","",""],
                                                                                adset_count: fd.picture ? fd.picture?.length?.toString() : "3",
                                                                                tonic_url: ""
                                                                            }
                                                                        } else {
                                                                            tempObj[fd?.widget?.toString()] = {
                                                                                ...fd,
                                                                                keyword: Array.isArray(fd?.keyword) ? fd?.keyword?.join('\n') : fd?.keyword,
                                                                                categories: fd.categories ? fd.categories : [],
                                                                                facebook_fan_page: fd.facebook_fan_page ? fd.facebook_fan_page : '',
                                                                                instagram_account: fd.instagram_account ? fd.instagram_account : '',
                                                                                daily_budget: fd.daily_budget ? fd.daily_budget : '1',
                                                                                headline: fd.headline ? fd.headline : ["","","","",""],
                                                                                message: fd.message ? fd.message : ["","","","",""],
                                                                                comment: fd.comment ? fd.comment : "",
                                                                                ad_media: fd.ad_media ? fd.ad_media : ((fd.picture && Array.isArray(fd.picture) && fd.picture.find(f => f)) ? fd.picture.map(e => {return{type: 'image', url: e}}) : [{},{},{},{},{}]),
                                                                                picture: fd.picture ? fd.picture : ["","","","",""],
                                                                                adset_count: fd.picture ? fd.picture?.length?.toString() : "5",
                                                                                tonic_url: ""
                                                                            }
                                                                        }
                                                                        return { ...p, list: tempObj }
                                                                    })
                                                                } else {
                                                                    setBulkUpdate(p => {
                                                                        const tempObj = { ...p.list }
                                                                        delete tempObj?.[fd?.widget?.toString()]
                                                                        return { ...p, list: tempObj }
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td style={{ background: '#EEEEEE' }}>
                                                    <div className="widget-table-cell">
                                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', gap: '10px' }}>
                                                                <MdCheckCircle size={30} color='#4CAF50' style={{ cursor: 'pointer' }} onClick={e => handleSubmitUpdate({ e, fd })}/>
                                                                <MdCancel size={30} color='#EF5350' style={{ cursor: 'pointer' }} onClick={e => handleUnselectRowEdit(e)}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style={{ minWidth: '120px', maxWidth: '120px' }}>
                                                    <div className="widget-table-cell">
                                                        {fd.widget}
                                                    </div>
                                                </td>
                                                <td style={{ minWidth: '120px', maxWidth: '120px' }}>
                                                    <div className="widget-table-cell">
                                                        {(updateDetail.case !== "Facebook") ? countryFieldComponent({ info: fd, cond: 'update' }) : (
                                                            <input className='widget-input' type="text" name='country' placeholder='input country' value={updateDetail.country} onChange={e => handleChangeUpdate(e)} />
                                                        )}
                                                    </div>
                                                </td>
                                                <td style={{ minWidth: '200px', maxWidth: '200px' }}>
                                                    <div className="widget-table-cell">
                                                        <input className='widget-input' type="text" name='topic' placeholder='input topic' autoComplete='off' value={updateDetail.topic} onChange={e => handleChangeUpdate(e)} />
                                                    </div>
                                                </td>
                                                <td style={{ minWidth: '150px', maxWidth: '150px' }}>
                                                    <div className="widget-table-cell" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                        {(updateDetail.case !== "Facebook") ? (
                                                            <>
                                                                <div className='widget-add-field' style={{ width: '100%' }}>
                                                                    <p className='widget-add-field-float-name'>Facebook A/C</p>
                                                                    <select className='widget2-select' name='fb_id' value={updateDetail.fb_id} onChange={e => handleChangeUpdate(e)} onFocus={e => e.target.style.borderColor = '#000'} onBlur={e => e.target.style.borderColor = '#AAA'}>
                                                                        <option value="">Choose facebook A/C</option>
                                                                        {(updateDetail.case === 'Ads Creation') ? (
                                                                            data.fb.map((e, i) => (
                                                                                <option value={e.unique} key={i}>{e.name} {"("}{e.client}{")"}</option>
                                                                            ))
                                                                        ) : (
                                                                            data.fb.filter(f => f.client === 'Sedo').map((e, i) => (
                                                                                <option value={e.unique} key={i}>{e.name} {"("}{e.client}{")"}</option>
                                                                            ))
                                                                        )}

                                                                    </select>
                                                                </div>
                                                                <div className='widget-add-field' style={{ width: '100%' }} onMouseEnter={() => setUpdateSelectDisplay('facebook_fan_page')} onMouseLeave={() => setUpdateSelectDisplay('')}>
                                                                    <p className='widget-add-field-float-name'>FB Fan Page</p>
                                                                    <div className='widget2-div' style={{ borderColor: (updateSelectDisplay === 'facebook_fan_page') ? '#000' : '#AAA' }}>
                                                                        <div style={{ fontSize: '14px' }}>{!updateDetail.facebook_fan_page.trim() ? 'None selected' : facebook_fan_pages?.find(f => f?.id === updateDetail.facebook_fan_page)?.name}</div>
                                                                        <ul style={{ position: 'absolute', top: '38px', height: 'fit-content', maxHeight: '250px', width: '250px', background: '#FFF', display: (updateSelectDisplay === 'facebook_fan_page') ? 'block' : 'none' }}>
                                                                            <li style={{ background: (updateDetail.facebook_fan_page === '') ? '#1E90FF' : '#FFF', color: (updateDetail.facebook_fan_page === '') ? '#FFF' : '#000', borderBottom: '1px solid #C9E7CB' }}
                                                                                onMouseEnter={hov => hov.currentTarget.style.filter = "invert(20%)"}
                                                                                onMouseLeave={hov => hov.currentTarget.style.filter = "invert(0%)"}
                                                                                onClick={() => setUpdateDetail(p => { return { ...p, facebook_fan_page: '' }})}
                                                                            >
                                                                                NONE
                                                                            </li>
                                                                            {facebook_fan_pages?.sort((a, b) => a?.name > b?.name ? 1 : -1).map((e, i) => (
                                                                                <li key={i} style={{ background: (updateDetail.facebook_fan_page === e?.id) ? '#1E90FF' : '#FFF', color: (updateDetail.facebook_fan_page === e?.id) ? '#FFF' : '#000', borderBottom: '1px solid #DDD' }}
                                                                                    onMouseEnter={hov => hov.currentTarget.style.filter = "invert(20%)"}
                                                                                    onMouseLeave={hov => hov.currentTarget.style.filter = "invert(0%)"}
                                                                                    onClick={() => setUpdateDetail(p => { return { ...p, facebook_fan_page: e?.id }})}
                                                                                >
                                                                                    {e?.name}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                {(updateDetail.case === 'Ads Creation') ? (
                                                                    <div className='widget-add-field' style={{ width: '100%' }}>
                                                                        <p className='widget-add-field-float-name'>{"Adset Count (1 - 20)"}</p>
                                                                        <input className='widget2-input' type="number" name='adset_count' placeholder='1 - 20' autoComplete='off' value={updateDetail.adset_count} onChange={e => handleChangeUpdate(e)} onWheel={e => e.target.blur()}
                                                                            onBlur={e => {
                                                                                let temp_count = e.target.value
                                            
                                                                                if (!temp_count || Number(temp_count) < 1) {
                                                                                    temp_count = '1'
                                                                                } else if (Number(temp_count) > 20) {
                                                                                    temp_count = '20'
                                                                                } else {
                                                                                    temp_count = Number(temp_count)?.toFixed(0)
                                                                                }
                                            
                                                                                setUpdateDetail(p => {
                                                                                    return {
                                                                                        ...p,
                                                                                        adset_count: temp_count,
                                                                                        headline: Array(Number(temp_count)).fill(""),
                                                                                        message: Array(Number(temp_count)).fill(""),
                                                                                        ad_media: Array(Number(temp_count)).fill({}),
                                                                                        picture: Array(Number(temp_count)).fill(""),
                                                                                        name: (p.case === "Ads Creation (Keywords)") ? default_name_ACK : [...Array(Number(temp_count))].map((e, i) => `${i+1}`)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div className='widget-add-field' style={{ width: '100%', cursor: 'not-allowed' }}>
                                                                        <p className='widget-add-field-float-name'>{"Adset Count"}</p>
                                                                        <div className='widget2-input' style={{ display: 'flex', alignItems: 'center' }}>{updateDetail.adset_count}</div> 
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <select className='widget-datagrid-select' name='fb_id' value={updateDetail.fb_id} onChange={e => handleChangeUpdate(e)}>
                                                                <option value="">Choose</option>
                                                                {data.fb.map((e, i) => (
                                                                    <option value={e.unique} key={i}>{e.name} {"("}{e.client}{")"}</option>
                                                                ))}
                                                            </select>
                                                        )}
                                                    </div>
                                                </td>
                                                <td style={{ minWidth: '100px', maxWidth: '100px' }}>
                                                    <div className="widget-table-cell">
                                                        {Array.isArray(fd.client) ? 'All Clients' : updateDetail.client}
                                                    </div>
                                                </td>
                                                <td style={{ minWidth: '150px', maxWidth: '150px' }}>
                                                    <div className="widget-table-cell">
                                                        {(updateDetail.case !== "Facebook") && (
                                                            <div className='widget-add-field' style={{ width: '100%' }} onMouseEnter={() => setUpdateSelectDisplay('categories')} onMouseLeave={() => setUpdateSelectDisplay('')}>
                                                                <div className='widget2-div' style={{ borderColor: (updateSelectDisplay === 'categories') ? '#000' : '#AAA' }}>
                                                                    <div style={{ fontSize: '14px' }}>{(updateDetail.categories.length === 0) ? 'None selected' : updateDetail.categories.map(e => e[0]+e[1]+e[2]).join(", ")}</div>
                                                                    <ul style={{ position: 'absolute', top: '40px', height: 'fit-content', background: '#FFF', display: (updateSelectDisplay === 'categories') ? 'block' : 'none' }}>
                                                                        <li style={{ backgroundColor: (updateDetail.categories.length === 0) ? '#dbeafe' : '#FFF', wordBreak: 'break-all' }}
                                                                            onClick={() => {
                                                                                setUpdateDetail(p => { return { ...p, categories: [] } })
                                                                            }}
                                                                        >
                                                                            NONE
                                                                        </li>
                                                                        {["CREDIT", "HOUSING", "ISSUES_ELECTIONS_POLITICS", "EMPLOYMENT"].map((cat, i) => (
                                                                            <li key={i} style={{ backgroundColor: (updateDetail.categories.find(f => f === cat)) ? '#dbeafe' : '#FFF', wordBreak: 'break-all' }}
                                                                                onMouseEnter={e => e.currentTarget.style.opacity = 0.5}
                                                                                onMouseLeave={e => e.currentTarget.style.opacity = 1}
                                                                                onClick={() => {
                                                                                    let temp = [...updateDetail.categories]
                                                                                    let find_category = temp.filter(f => f === cat)
                                                                                    if (find_category.length > 0) {
                                                                                        temp = temp.filter(f => f !== cat)
                                                                                    } else {
                                                                                        temp.push(cat)
                                                                                    }
                                                                                    setUpdateDetail(p => { return { ...p, categories: temp } })
                                                                                }}
                                                                            >
                                                                                {cat}
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                                <td style={{ minWidth: '100px', maxWidth: '100px' }}>
                                                    <div className="widget-table-cell">
                                                        {(updateDetail.case !== "Facebook") && (
                                                            <div style={{ height: '40px', display: 'flex', alignItems: 'center' }}>
                                                                $&nbsp;<input className='widget-input' type="number" name='daily_budget' placeholder='1 - 10 USD' value={updateDetail.daily_budget} onChange={e => handleChangeUpdate(e)} onWheel={e => e.target.blur()}
                                                                    onBlur={e => {
                                                                        let temp_count = e.target.value
                                    
                                                                        if (!temp_count || Number(temp_count) < 1) {
                                                                            temp_count = '1'
                                                                        } else if (Number(temp_count) > 10) {
                                                                            temp_count = '10'
                                                                        }
                                    
                                                                        setUpdateDetail(p => {
                                                                            return {
                                                                                ...p,
                                                                                daily_budget: temp_count,
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                                <td style={{ minWidth: '200px', maxWidth: '200px' }}>
                                                    <div className="widget-table-cell">
                                                        {(updateDetail.case !== "Facebook") && (<input className='widget-input' type="text" name='headline' placeholder='input headline' autoComplete='off' value={updateDetail.headline[0]} onChange={e => handleChangeUpdate(e)} />)}
                                                    </div>
                                                </td>
                                                <td style={{ minWidth: '200px', maxWidth: '200px' }}>
                                                    <div className="widget-table-cell">
                                                        {(updateDetail.case !== "Facebook") && (<textarea type="text" name='message' placeholder='input ad text' rows={10} value={updateDetail.message[0]} onChange={e => handleChangeUpdate(e)} className='widget2-textarea' style={{ height: '150px', maxWidth: '100%' }} />)}
                                                    </div>
                                                </td>
                                                <td style={{ minWidth: '200px', maxWidth: '200px' }}>
                                                    <div className="widget-table-cell">
                                                        {(updateDetail.case !== "Facebook") && (<textarea type="text" name='comment' placeholder='input comment' rows={10} value={updateDetail.comment} onChange={e => handleChangeUpdate(e)} className='widget2-textarea' style={{ height: '150px', maxWidth: '100%' }} />)}
                                                    </div>
                                                </td>
                                                
                                                <td style={{ minWidth: '200px', maxWidth: '200px' }}>
                                                    <div className="widget-table-cell" style={{ width: '100%' }}>
                                                        {(updateDetail.case !== "Facebook") && (
                                                            <div className='widget-add-field' style={{ width: '100%' }} onClick={() => setFbMediaState(p => { return { ...p, display: true, mode: 'update', act_id: data.fb.find(f => (f.unique === updateDetail?.fb_id))?.id, widget: 0 } })}>
                                                                <div className='widget2-div' style={{ borderColor: (addSelectDisplay === 'adImages') ? '#000' : '#AAA' }}>
                                                                    <div style={{ fontSize: '14px' }}>
                                                                        {updateDetail.picture.find(f => f) ? (
                                                                            updateDetail.picture.filter(f => f).length + " / " + updateDetail.picture.length + " selected"
                                                                        ) : "None selected"}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                                <td style={{ minWidth: '200px', maxWidth: '200px' }}>
                                                    <div className="widget-table-cell" style={{ width: '100%' }}>
                                                    </div>
                                                </td>
                                                <td style={{ minWidth: '200px', maxWidth: '200px' }}>
                                                    <div className="widget-table-cell" style={{ width: '100%' }}>
                                                        <div className='widget-add-field' style={{ width: '100%' }}>
                                                            <div style={{ position: 'relative', width: '100%' }}
                                                                onMouseEnter={e => {
                                                                    e.currentTarget.childNodes[0].style.borderColor = '#000'
                                                                    e.currentTarget.childNodes[1].style.display = 'flex'
                                                                }}
                                                                onMouseLeave={e => {
                                                                    e.currentTarget.childNodes[0].style.borderColor = '#AAA'
                                                                    e.currentTarget.childNodes[1].style.display = 'none'
                                                                }}
                                                            >
                                                                <input className='widget2-input' type="text" name='url' placeholder='water-tank.shop' value={updateDetail.url} autoComplete='off' onChange={e => handleChangeUpdate(e)} />
                                                                <div style={{ position: 'absolute', zIndex: 40, width: '100%', height: '300px', background: '#FFF', display: 'none', flexDirection: 'column', alignItems: 'center', overflowY: 'scroll', border: '2px solid #AAA', borderRadius: '10px' }}>
                                                                    {list.map((e, i) => (
                                                                        <div key={i} style={{ height: 'fit-content', width: '100%', padding: '5px', fontSize: '12px', color: '#000', borderBottom: '1px solid #EEE' }}
                                                                            onMouseEnter={e => e.currentTarget.style.background = '#EEE'}
                                                                            onMouseLeave={e => e.currentTarget.style.background = '#FFF'}
                                                                            onClick={event => {
                                                                                setUpdateDetail(p => {return{...p, url: e.domain}})
                                                                                event.currentTarget.parentNode.style.display = 'none'
                                                                            }}
                                                                        >
                                                                            {e.domain}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style={{ minWidth: '200px', maxWidth: '200px' }}>
                                                    <div className="widget-table-cell">
                                                        {(displayErrorURL) && (<p style={{ fontSize: '14px', color: '#FF0000' }}>{updateDetail.client+' - only '+getBreaksCount()+' line breaks allowed.'}</p>)}
                                                        <textarea type="text" name='keyword' placeholder={updateDetail.client+' - only '+getBreaksCount()+' line breaks allowed.'} rows={10} value={updateDetail.keyword} onChange={e => handleChangeUpdate(e)} className='widget2-textarea' style={{ height: '150px', maxWidth: '100%' }} />
                                                    </div>
                                                </td>
                                                <td style={{ minWidth: '200px', maxWidth: '200px' }}>
                                                    <div className="widget-table-cell">
                                                        {(updateDetail.client === 'Tonic') ? (
                                                            <>
                                                                <div className='widget-add-field' style={{ width: '100%' }}>
                                                                    <p className='widget-add-field-float-name'>Tonic URL Mandatory</p>
                                                                    <input className='widget2-input' id="tonic_url_to_generate" type="text" placeholder='https://url.tonic.com' autoComplete='off' onChange={e => e.target.value = e.target.value.replace(/\s+/g, "")} style={{ maxWidth: '100%' }} />
                                                                </div>
                                                                <div className='widget-add-field' style={{ width: '100%', margin: '10px 0px' }}>
                                                                    <p className='widget-add-field-float-name'>Final URL</p>
                                                                    <input className='widget2-input' type="text" name='final_url' placeholder='final url' autoComplete='off' value={updateDetail.final_url} onChange={e => handleChangeUpdate(e)} style={{ maxWidth: '100%' }} />
                                                                </div>
                                                            </>
                                                        ) : (
                                                            (updateDetail.case === 'Ads Creation (Keywords)' && Array.isArray(updateDetail.final_url)) ? (
                                                                <>
                                                                    <input className='widget-input' type="text" placeholder='final url 1' autoComplete='off' value={updateDetail?.final_url?.[0]}
                                                                        onChange={e => setUpdateDetail(p => {
                                                                            let final_url_result = [...p.final_url]
                                                                            final_url_result[0] = e.target.value
                                                                            return { ...p, final_url: final_url_result }
                                                                        })}
                                                                    />
                                                                    <input className='widget-input' type="text" placeholder='final url 2' autoComplete='off' value={updateDetail?.final_url?.[1]}
                                                                        onChange={e => setUpdateDetail(p => {
                                                                            let final_url_result = [...p.final_url]
                                                                            final_url_result[1] = e.target.value
                                                                            return { ...p, final_url: final_url_result }
                                                                        })}
                                                                    />
                                                                    <input className='widget-input' type="text" placeholder='final url 3' autoComplete='off' value={updateDetail?.final_url?.[2]} style={{ marginBottom: '10px' }}
                                                                        onChange={e => setUpdateDetail(p => {
                                                                            let final_url_result = [...p.final_url]
                                                                            final_url_result[2] = e.target.value
                                                                            return { ...p, final_url: final_url_result }
                                                                        })}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <input className='widget-input' type="text" name='final_url' placeholder='final url' autoComplete='off' value={updateDetail.final_url} onChange={e => handleChangeUpdate(e)} style={{ marginBottom: '10px' }} />
                                                            )
                                                        )}
                                                        <button onClick={() => handleGenerateKeyword(fd)} style={{ height: '30px', width: '100%', padding: '0px 5px', border: 0, outline: 0, fontSize: '12px', color: '#FFFFFF', background: '#4791DB', borderRadius: '5px', cursor: 'pointer' }}>Generate</button>
                                                    </div>
                                                </td>
                                                <td style={{ minWidth: '200px', maxWidth: '200px' }}>
                                                    <div className="widget-table-cell">
                                                        <input className='widget-input' type="text" name='note' placeholder='note' autoComplete='off' value={updateDetail.note} onChange={e => handleChangeUpdate(e)} />
                                                    </div>
                                                </td>
                                                <td style={{ minWidth: '100px', maxWidth: '100px' }}>
                                                    <div className="widget-table-cell">
                                                        {(fd.case !== "Facebook") ? (
                                                            fd.case
                                                        ) : (
                                                            <select className='widget2-select' name='case' value={updateDetail.case} onChange={e => handleChangeUpdate(e)}>
                                                                {(facebook_fan_pages.length > 0) ? (
                                                                    <>
                                                                        <option value="Ads Creation">Ads Creation</option>
                                                                        <option value="Facebook">Widget Old</option>
                                                                    </>
                                                                ) : (
                                                                    <option value="Facebook">Widget Old</option>
                                                                )}
                                                            </select>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : (

                                            // and if there is none selected the following tags will be mapped
     
                                            <tr style={{ background: findBgColor(fd) }}>
                                                <td style={{ background: '#EEEEEE' }}>
                                                    <div className="widget-table-cell" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <input type="checkbox" style={{ height: '30px', width: '30px', cursor: "pointer" }}
                                                            checked={bulkUpdate?.list?.[fd?.widget?.toString()] ? true : false}
                                                            onChange={e => {
                                                                if (e.target.checked) {
                                                                    setBulkUpdate(p => {
                                                                        const tempObj = { ...p.list }
                                                                        if (fd.case === 'Ads Creation (Keywords)') {
                                                                            tempObj[fd?.widget?.toString()] = {
                                                                                ...fd,
                                                                                keyword: Array.isArray(fd?.keyword) ? fd?.keyword?.join('\n') : fd?.keyword,
                                                                                categories: fd.categories ? fd.categories : [],
                                                                                facebook_fan_page: fd.facebook_fan_page ? fd.facebook_fan_page : '',
                                                                                instagram_account: fd.instagram_account ? fd.instagram_account : '',
                                                                                daily_budget: fd.daily_budget ? fd.daily_budget : '1',
                                                                                headline: fd.headline ? fd.headline : ["","",""],
                                                                                message: fd.message ? fd.message : ["","",""],
                                                                                comment: fd.comment ? fd.comment : "",
                                                                                ad_media: fd.ad_media ? fd.ad_media : ((fd.picture && Array.isArray(fd.picture) && fd.picture.find(f => f)) ? fd.picture.map(e => {return{type: 'image', url: e}}) : [{},{},{}]),
                                                                                picture: fd.picture ? fd.picture : ["","",""],
                                                                                adset_count: fd.picture ? fd.picture?.length?.toString() : "3",
                                                                                tonic_url: ""
                                                                            }
                                                                        } else {
                                                                            tempObj[fd?.widget?.toString()] = {
                                                                                ...fd,
                                                                                keyword: Array.isArray(fd?.keyword) ? fd?.keyword?.join('\n') : fd?.keyword,
                                                                                categories: fd.categories ? fd.categories : [],
                                                                                facebook_fan_page: fd.facebook_fan_page ? fd.facebook_fan_page : '',
                                                                                instagram_account: fd.instagram_account ? fd.instagram_account : '',
                                                                                daily_budget: fd.daily_budget ? fd.daily_budget : '1',
                                                                                headline: fd.headline ? fd.headline : ["","","","",""],
                                                                                message: fd.message ? fd.message : ["","","","",""],
                                                                                comment: fd.comment ? fd.comment : "",
                                                                                ad_media: fd.ad_media ? fd.ad_media : ((fd.picture && Array.isArray(fd.picture) && fd.picture.find(f => f)) ? fd.picture.map(e => {return{type: 'image', url: e}}) : [{},{},{},{},{}]),
                                                                                picture: fd.picture ? fd.picture : ["","","","",""],
                                                                                adset_count: fd.picture ? fd.picture?.length?.toString() : "5",
                                                                                tonic_url: ""
                                                                            }
                                                                        }
                                                                        return { ...p, list: tempObj }
                                                                    })
                                                                } else {
                                                                    setBulkUpdate(p => {
                                                                        const tempObj = { ...p.list }
                                                                        delete tempObj?.[fd?.widget?.toString()]
                                                                        return { ...p, list: tempObj }
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td style={{ background: '#EEEEEE' }}>
                                                    <div className="widget-table-cell">
                                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', gap: '10px' }}>
                                                                {((fd.case === "Ads Creation" || fd.case === "Ads Creation (Keywords)") && (fd.finished === true) && (Cookies.get('role') !== 'admin')) ? (<></>) : (
                                                                    <div style={{ height: '30px', width: '30px', background: '#4CAF50', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', cursor: 'pointer' }}
                                                                        onMouseEnter={e => e.currentTarget.style.opacity = '50%'}
                                                                        onMouseLeave={e => e.currentTarget.style.opacity = '100%'}
                                                                        onClick={e => handleSelectRowEdit({e, detail: fd})}
                                                                    >
                                                                        <FaEdit size={16} color='#FFF'/>
                                                                    </div>
                                                                )}
                                                                <div style={{ height: '30px', width: '30px', background: '#EF5350', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', cursor: 'pointer' }}
                                                                    onMouseEnter={e => e.currentTarget.style.opacity = '50%'}
                                                                    onMouseLeave={e => e.currentTarget.style.opacity = '100%'}
                                                                    onClick={() => handleDeleteRow({fd: [{ campaign_name: fd.widget }], refresh_list: true})}
                                                                >
                                                                    <FaTrashAlt size={16} color='#FFF'/>
                                                                </div>
                                                            </div>
                                                            <div style={{ width: '100%', display: 'grid', gridAutoFlow: 'column', gap: '5px', justifyContent: 'center' }}>
                                                                {(fd.case !== "Facebook") ? (
                                                                    checkNewCampaignDisplay(fd) ? (
                                                                        (
                                                                            fd?.result?.adset &&
                                                                            fd?.result?.ad &&
                                                                            ( (fd?.result?.adset?.length !== fd?.result?.adset?.filter(f => f?.id)) || (fd?.result?.ad?.length !== fd?.result?.ad?.filter(f => f?.id)) )
                                                                        ) ? (
                                                                            <button
                                                                                onClick={() => resendFacebookCampaignAPI({ item: fd, fb_ids: data.fb, setUpdateLoading, handleChangeSwitch, appContext })}
                                                                                style={{ height: '30px', padding: '0px 5px', border: 0, outline: 0, background: '#4CAF50', color: '#FFF', fontSize: '14px', whiteSpace: 'nowrap', borderRadius: '5px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                                                            >
                                                                                Resend <MdCampaign size={30} color='#FFF'/>
                                                                            </button>
                                                                        ) : (
                                                                            <button
                                                                                onClick={() => createFacebookCampaignAPI({ item: fd, fb_ids: data.fb, setUpdateLoading, handleChangeSwitch, appContext })}
                                                                                style={{ height: '30px', padding: '0px 5px', border: 0, outline: 0, background: '#4CAF50', borderRadius: '5px', cursor: 'pointer' }}
                                                                            >
                                                                                <MdCampaign size={30} color='#FFF'/>
                                                                            </button>
                                                                        )
                                                                    ) : (
                                                                        fd.finished && (
                                                                            <div style={{ height: '30px', padding: '0px 5px', border: 0, outline: 0, background: '#98989d', borderRadius: '5px', cursor: 'not-allowed', display: 'flex' }}>
                                                                                <GiHandOk size={30} color='#FFF'/>
                                                                                <MdCampaign size={30} color='#FFF'/>
                                                                            </div>
                                                                        )
                                                                    )
                                                                ) : (
                                                                    fd.finished ? (
                                                                        <div className="rulesSwitch" style={{background: '#1976d2', cursor: 'not-allowed' }}>
                                                                            <div className="rulesSwitchButton" style={{left: '22.5px'}}/>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="rulesSwitch" onClick={() => handleChangeSwitch({ fd, finished: true, result: {} })} style={{background: '#98989d', pointerEvents: 'all', cursor: 'pointer' }}>
                                                                            <div className="rulesSwitchButton" style={{left: '2.5px'}}/>
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style={{ minWidth: '120px', maxWidth: '120px' }}>
                                                    <div className="widget-table-cell" style={{ display: 'grid', gridAutoFlow: 'column', gridAutoColumns: '1fr min-content' }}>
                                                        <p>{fd.widget}</p>
                                                        <FaCopy size={24} color='#555555' style={{ cursor: 'pointer' }} onClick={() => {navigator.clipboard.writeText(fd.widget)}} />
                                                    </div>
                                                </td>
                                                <td style={{ minWidth: '120px', maxWidth: '120px' }}>
                                                    {(fd.country !== undefined && fd.country !== '') ? (
                                                        <>
                                                            {(selectedRow.country === fd.widget) ? (
                                                                <div className="widget-table-cell" onClick={e => handleShowHide({e, key: 'country', value: null})}>
                                                                    <p className='widget-table-cell-hover' style={{ whiteSpace: 'pre-line' }}>{Array.isArray(fd.country) ? fd.country.join(" & ") : fd.country.replaceAll(',', ' & ')}</p>
                                                                </div>
                                                            ) : (
                                                                <div className="widget-table-cell" onClick={e => handleShowHide({e, key: 'country', value: fd.widget})}>
                                                                    <p className='widget-table-cell-hover' style={{ whiteSpace: 'pre-line' }}>
                                                                        {Array.isArray(fd.country) ? (
                                                                            (fd.country.join(" & ").length > 40) ? fd.country.join(" & ").substring(0, 40)+'...' : fd.country.join(" & ")
                                                                        ) : (
                                                                            (fd.country.length > 40) ? fd.country.replaceAll(',', ' & ').substring(0, 40)+'...' : fd.country.replaceAll(',', ' & ')

                                                                        )}
                                                                    </p>
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (<></>)}
                                                </td>
                                                <td style={{ minWidth: '200px', maxWidth: '200px' }}><div className="widget-table-cell">{fd.topic}</div></td>
                                                <td style={{ minWidth: '150px', maxWidth: '150px' }}>
                                                    <div className="widget-table-cell">
                                                        <p style={{ fontSize: '12px' }}>{(fd.fb_id === 'email') ? 'EMAIL' : data.fb.find(f => f.unique === fd.fb_id)?.name}</p>
                                                        {(fd.case !== "Facebook") ? (
                                                            <p style={{ fontSize: '12px', fontWeight: '700', color: '#9400D3' }}>
                                                                <sup style={{ fontSize: '10px' }}>Fan</sup>
                                                                {facebook_fan_pages?.find(f => f?.id === fd?.facebook_fan_page)?.name}
                                                            </p>
                                                        ) : ''}
                                                    </div>
                                                </td>
                                                <td style={{ minWidth: '100px', maxWidth: '100px' }}><div className="widget-table-cell">{fd.client}</div></td>
                                                <td style={{ minWidth: '100px', maxWidth: '100px' }}><div className="widget-table-cell">{(fd?.case !== "Facebook" && Array.isArray(fd.categories)) ? fd.categories.join(", ") : ''}</div></td>
                                                <td style={{ minWidth: '100px', maxWidth: '100px' }}><div className="widget-table-cell">{(fd?.case !== "Facebook") ? fd?.daily_budget : ''}</div></td>
                                                <td style={{ minWidth: '200px', maxWidth: '200px' }}><div className="widget-table-cell">{(fd?.case !== "Facebook") ? fd?.headline?.[0] : ''}</div></td>
                                                <td style={{ minWidth: '200px', maxWidth: '200px' }}>
                                                    <div className="widget-table-cell">
                                                        {(fd?.case !== "Facebook") ? (
                                                            (fd?.message?.[0] !== undefined && fd?.message?.[0] !== '') ? (
                                                                <>
                                                                    {(selectedRow.message === fd.widget) ? (
                                                                        <div className="widget-table-cell" onClick={e => handleShowHide({e, key: 'message', value: null})}>
                                                                            <p className='widget-table-cell-hover' style={{ whiteSpace: 'pre-wrap' }}>{fd?.message?.[0]}</p>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="widget-table-cell" onClick={e => handleShowHide({e, key: 'message', value: fd.widget})}>
                                                                            <p className='widget-table-cell-hover' style={{ whiteSpace: 'pre-wrap' }}>{(fd?.message?.[0].length > 40) ? fd?.message?.[0].substring(0, 40)+'...' : fd?.message?.[0]}</p>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            ) : (<></>)
                                                        ) : ''}
                                                    </div>
                                                </td>
                                                <td style={{ minWidth: '200px', maxWidth: '200px' }}>
                                                    <div className="widget-table-cell">
                                                        {(fd?.case !== "Facebook") ? (
                                                            (selectedRow.comment === fd.widget) ? (
                                                                <div className="widget-table-cell" onClick={e => handleShowHide({e, key: 'comment', value: null})}>
                                                                    <p className='widget-table-cell-hover' style={{ whiteSpace: 'pre-wrap' }}>{fd?.comment}</p>
                                                                </div>
                                                            ) : (
                                                                <div className="widget-table-cell" onClick={e => handleShowHide({e, key: 'comment', value: fd.widget})}>
                                                                    <p className='widget-table-cell-hover' style={{ whiteSpace: 'pre-wrap' }}>{(fd?.comment?.length > 40) ? fd?.comment?.substring(0, 40)+'...' : fd?.comment}</p>
                                                                </div>
                                                            )
                                                        ) : ''}
                                                    </div>
                                                </td>
                                                <td style={{ minWidth: '200px', maxWidth: '200px' }}>
                                                    {(fd?.case !== "Facebook" && fd.picture && Array.isArray(fd.picture) && fd.picture.find(f => f)) ? (
                                                        <>
                                                            {(selectedRow.picture === fd.widget) ? (
                                                                <div className="widget-table-cell" style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', width: '100%', gap:'5px', cursor: 'pointer' }} onClick={e => handleShowHide({e, key: 'picture', value: null})}>
                                                                    {fd?.picture?.map((e, i) => (
                                                                        <img key={i} src={e} alt="" style={{ height: '40px', width: '100%', objectFit: 'contain' }}/>
                                                                    ))}
                                                                </div>
                                                            ) : (
                                                                <div className="widget-table-cell" style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', width: '100%', gap:'5px', cursor: 'pointer' }} onClick={e => handleShowHide({e, key: 'picture', value: fd.widget})}>
                                                                    {fd?.picture?.slice(0, 5)?.map((e, i) => (
                                                                        <img key={i} src={e} alt="" style={{ height: '40px', width: '100%', objectFit: 'contain' }}/>
                                                                    ))}
                                                                    ...
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (<></>)}
                                                </td>
                                                <td style={{ minWidth: '200px', maxWidth: '200px' }}>
                                                    <div className="widget-table-cell">
                                                        {(fd?.case !== "Facebook") ? (
                                                            <ul style={{ listStyle: 'inside' }}>
                                                                {(fd.result && fd?.result?.ad && Array.isArray(fd?.result?.ad) && (fd?.result?.ad?.length > 0)) ? (
                                                                    fd?.result?.ad?.map((e, i) => (
                                                                        e?.comment_posted ? (
                                                                            <li key={i} style={{ fontSize: '14px', wordBreak: 'break-all' }}
                                                                                onMouseEnter={e => e.currentTarget.style.opacity = 0.5}
                                                                                onMouseLeave={e => e.currentTarget.style.opacity = 1}
                                                                            >
                                                                                <a href={'https://www.facebook.com/'+e?.post_id} target='_blank' style={{ color: '#0070E0', fontSize: '14px', wordBreak: 'break-all', textUnderlineOffset: '1.5px' }}>{e?.id}</a>
                                                                            </li>
                                                                        ) : (
                                                                            <li key={i} style={{ fontSize: '14px', wordBreak: 'break-all' }}>{e?.id}</li>
                                                                        )
                                                                    ))
                                                                ) : (<></>)}
                                                            </ul>
                                                        ) : ''}
                                                    </div>
                                                </td>
                                                
                                                <td style={{ minWidth: '200px', maxWidth: '200px' }}>
                                                    <div className="widget-table-cell">
                                                        {fd?.url}
                                                    </div>
                                                </td>
                                                <td style={{ minWidth: '200px', maxWidth: '200px' }}>
                                                    {(fd.keyword !== undefined && fd.keyword.length > 0) ? (
                                                        <>
                                                            {(selectedRow.keyword === fd.widget) ? (
                                                                <div className="widget-table-cell" onClick={e => handleShowHide({e, key: 'keyword', value: null})}>
                                                                    <p className='widget-table-cell-hover' style={{ whiteSpace: 'pre-line' }}>{fd.keyword.join('\n')}</p>
                                                                </div>
                                                            ) : (
                                                                <div className="widget-table-cell" onClick={e => handleShowHide({e, key: 'keyword', value: fd.widget})}>
                                                                    <p className='widget-table-cell-hover' style={{ whiteSpace: 'pre-line' }}>{(fd.keyword.join('\n').length > 40) ? fd.keyword.join('\n').substring(0, 40)+'...' : fd.keyword.join('\n')}</p>
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (<></>)}
                                                </td>
                                                <td style={{ minWidth: '200px', maxWidth: '200px' }}>
                                                    {(fd.final_url !== undefined && fd.final_url !== '' && fd?.final_url?.length !== 0) ? (
                                                        <div className="widget-table-cell" style={{ display: 'grid', gridAutoFlow: 'column', gridAutoColumns: '1fr min-content' }}>
                                                            {(selectedRow.final_url === fd.widget) ? (
                                                                (fd.case === 'Ads Creation (Keywords)' && Array.isArray(fd.final_url)) ? (
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        {fd.final_url.map((m, i) => <p key={i} className='widget-table-cell-hover' onClick={e => handleShowHide({e, key: 'final_url', value: null})}>{m}<br/><br/></p>)}
                                                                    </div>
                                                                ) : (
                                                                    <p className='widget-table-cell-hover' onClick={e => handleShowHide({e, key: 'final_url', value: null})}>{fd.final_url}</p>
                                                                )
                                                            ) : (
                                                                (fd.case === 'Ads Creation (Keywords)' && Array.isArray(fd.final_url)) ? (
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        {fd.final_url.map((m, i) => <p key={i} className='widget-table-cell-hover' onClick={e => handleShowHide({e, key: 'final_url', value: fd.widget})}>{(m.length > 20) ? m.substring(0, 20)+'...' : m}<br/><br/></p>)}
                                                                    </div>
                                                                ) : (
                                                                    <p className='widget-table-cell-hover' onClick={e => handleShowHide({e, key: 'final_url', value: fd.widget})}>{(fd.final_url.length > 40) ? fd.final_url.substring(0, 40)+'...' : fd.final_url}</p>
                                                                )
                                                            )}
                                                            <FaCopy size={24} color='#555555' style={{ cursor: 'pointer' }} onClick={() => {navigator.clipboard.writeText(fd.final_url)}} />
                                                        </div>
                                                    ) : (<></>)}
                                                </td>
                                                <td style={{ minWidth: '200px', maxWidth: '200px' }}>
                                                    {(fd.note !== undefined && fd.note !== '') ? (
                                                        <>
                                                            {(selectedRow.note === fd.widget) ? (
                                                                <div className="widget-table-cell" onClick={e => handleShowHide({e, key: 'note', value: null})}>
                                                                    <p className='widget-table-cell-hover' style={{ whiteSpace: 'pre-line' }}>{fd.note}</p>
                                                                </div>
                                                            ) : (
                                                                <div className="widget-table-cell" onClick={e => handleShowHide({e, key: 'note', value: fd.widget})}>
                                                                    <p className='widget-table-cell-hover' style={{ whiteSpace: 'pre-line' }}>{(fd.note.length > 40) ? fd.note.substring(0, 40)+'...' : fd.note}</p>
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (<></>)}
                                                </td>
                                                <td style={{ minWidth: '100px', maxWidth: '100px' }}><div className="widget-table-cell"  style={{ fontSize: '14px' }}>{(fd.case === 'Facebook') ? "Facebook (Widget Old)" : fd.case}</div></td>
                                            </tr>
                                        )}
                                    </Fragment>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}

export default Widget





// functions kept outside of the main Widget to make it look a little cleaner

// API to search widget
const searchWidgetAPI = ({ selectedSearchOption, setFilteredDataToMap, setUpdateLoading, filterUser }) => {
    setUpdateLoading(true)
    const api_endpoint_by_widget = `${host}/api/index/search_by_id`;
    const api_endpoint = `${host}/api/index/search`;
    const token = Cookies.get('token')
    const username_to_check = ((Cookies.get('role') === 'admin') || (Cookies.get('manager') === 'admin') || (Cookies.get('userArray') === 'LFCWidget')) ? filterUser : Cookies.get('userArray')

    if (selectedSearchOption.type === 'widget') {
        axios.post(api_endpoint_by_widget, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
            },
            id: Number(selectedSearchOption.input)
        })
        .then((response) => {
            const obj = response.data
            if (obj.result && obj?.result?.username) {
                if (obj.result.username === username_to_check) {
                    setFilteredDataToMap([obj.result])
                } else {
                    setFilteredDataToMap([])
                }
            } else {
                setFilteredDataToMap([])
            }
            setUpdateLoading(false)
        })
        .catch((error) => {
            console.log(error);
            setFilteredDataToMap([])
            setUpdateLoading(false)
        })
    } else {
        axios.post(api_endpoint, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
            },
            field: selectedSearchOption.type,
            keyword: selectedSearchOption.input
        })
        .then((response) => {
            const obj = response.data
            if (obj.result && Array.isArray(obj.result) && obj.result.length > 0) {
                setFilteredDataToMap(obj.result.filter(f => f.username === username_to_check).sort((a, b) => b.widget - a.widget))
            } else {
                setFilteredDataToMap([])
            }
            setUpdateLoading(false)
        })
        .catch((error) => {
            console.log(error);
            setUpdateLoading(false)
        })
    }
}

// API to check if the input URL is already in the database or not
const checkUrlAPI = async (url) => {
    let result = false
    const api_endpoint = `${host}/api/index/search_url`;
    const token = Cookies.get('token')

    await axios.post(api_endpoint, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type, x-access-token",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
            "Authorization": "Bearer "+token,
            "Content-Type": "application/json",
        },
        sedo_url: url
    })

    .then((response) => {
        const obj = response.data
        if (obj.state === true) {
            result = obj.result
        }
    })

    .catch((error) => {
        console.log(error);
    })

    return result
}

// condition to determine whether to show the create new campaign button
const checkNewCampaignDisplay = (item) => {
    let result = false

    if (
        item.widget &&
        item.fb_id &&
        item.categories &&
        item.facebook_fan_page &&
        (item.comment && item.comment.trim() !== '') &&
        (item.country && item.country.trim() !== '') &&
        (item.daily_budget && Number(item.daily_budget) >= 1) &&
        (item.final_url && ((typeof item.final_url === 'string' && item.final_url?.trim() !== '') || (Array.isArray(item.final_url) && (item.final_url?.filter(f => f)?.length === item.final_url?.length) ))) &&
        (item.case === "Ads Creation" || item.case === "Ads Creation (Keywords)") &&
        (item.finished === false)
    ) {
        result = true
    }

    return result
}

// API to post comment for multiple finished campaigns
const bulkPostComment = async ({ items, fb_ids, fb_fan_pages_no_token, setUpdateLoading, setRefresh, fb_token, appContext }) => {
    setUpdateLoading(true)
    let token_error_messages = []

    let main_loop = await Promise.all(items.map(async item => {
        const find_fb_fan_page_name = fb_fan_pages_no_token?.find(f => f?.id === item?.facebook_fan_page)?.name

        const response_fan_page = await fetch(`https://graph.facebook.com/v20.0/${item.facebook_fan_page}/?fields=access_token&access_token=${fb_token}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        });
        const temp_response = await response_fan_page.json();
        const find_fb_fan_page = { id: temp_response?.id, token: temp_response?.access_token, error: temp_response?.error?.message }

        if (!find_fb_fan_page.token) {
            token_error_messages.push({
                type: "Error",
                title: `${item.widget}`,
                content: find_fb_fan_page.error ? find_fb_fan_page.error : `Can not find token of "${find_fb_fan_page_name}".`
            })
            return
        }

        if (find_fb_fan_page && item?.result?.ad && item?.comment && item?.comment?.trim()) {
            const token = Cookies.get('token')
            let temp_result = { ...item?.result }
            const filtered_ads = temp_result?.ad ? temp_result?.ad?.filter(f => !f?.comment_posted) : []
            let for_comment = []

            if (filtered_ads?.length > 0) {
                let get_post_ids = await Promise.all(filtered_ads?.map(async m => {
                    try {
                        const response_post = await axios.post(`${host}/api/facebook_page/get_ads_post`, {
                            headers: {
                                "Access-Control-Allow-Origin": "*",
                                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                                "Authorization": "Bearer "+token,
                                "Content-Type": "application/json",
                            },
                            page_id: item.facebook_fan_page,
                            ad_id: m.id
                        })
                        temp_result.ad[m.name-1] = {
                            ...temp_result.ad[m.name-1],
                            post_id: response_post?.data?.result,
                            comment_posted: false
                        }
                        if ((typeof response_post?.data?.result === "string") && !response_post?.data?.result?.toLowerCase()?.includes('error')) {
                            for_comment.push({
                                ...temp_result.ad[m.name-1],
                                post_id: response_post?.data?.result,
                                comment_posted: false
                            })
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }))

                if (for_comment.length > 0) {
                    let post_comments = await Promise.all(for_comment.map(async m => {
                        if (m.post_id && (m.post_id !== null)) {
                            try {
                                const response_comment = await axios.post(`${host}/api/facebook_page/comment`, {
                                    headers: {
                                        "Access-Control-Allow-Origin": "*",
                                        "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                                        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                                        "Authorization": "Bearer "+token,
                                        "Content-Type": "application/json",
                                    },
                                    post_id: m.post_id,
                                    token: find_fb_fan_page.token,
                                    message: item.comment + "\n" + ((item.case === 'Ads Creation (Keywords)') ? item.ad_template_link[m.name-1] : item.final_url),
                                })
                                console.log(response_comment)
                                temp_result.ad[m.name-1] = {
                                    ...temp_result.ad[m.name-1],
                                    comment_posted: true
                                }
                            } catch (error) {
                                console.log(error);
                            }
                        }
                    }))
                }
            }

            if (temp_result?.ad?.filter(f => f?.comment_posted === false)?.length > 0) {
                appContext.setDialogInfo(p => {
                    return {
                        ...p,
                        mode: "Alert",
                        type: "Error",
                        message: temp_result?.ad?.filter(f => f?.comment_posted === false)?.map(msg => { return { type: "Error", title: "Ad ID: "+msg.id, content: "This account has reached its limit. Please try again later." } }),
                        confirm_function: () => {},
                        cancel_function: () => {}
                    }
                })
            }

            item.keyword = item.keyword
            .split('\n')
            .filter(f => f)
            .map(m => m.
                trim()
                .split(' ')
                .filter(fi => fi)
                .map(e => e.charAt(0).toUpperCase() + e.slice(1))
                .join(' '))

            let detail = {
                ...item,
                fb_id: fb_ids?.find(f => f?.unique === item.fb_id)?.id,
                finished: true,
                result: temp_result
            }
            delete detail?.adset_count
            delete detail?.tonic_url

            const update_response = await axios.post(`${host}/api/index/update`,
                {
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                    "Authorization": "Bearer "+token,
                    "Content-Type": "application/json",
                    },
                    data: detail
                }
            )
        }
    }))

    if (token_error_messages.length > 0) {
        appContext.setDialogInfo(p => {
            return {
                ...p,
                mode: "Alert",
                type: "Error",
                message: token_error_messages,
                confirm_function: () => {},
                cancel_function: () => {}
            }
        })
    }

    setUpdateLoading(false)
    setRefresh(p => !p)
}

// API to resend campaigns with errors in Adset and Ad levels
const resendFacebookCampaignAPI = async ({ item, fb_ids, setUpdateLoading, handleChangeSwitch, appContext }) => {
    if (checkNewCampaignDisplay(item) === false) {
        appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Missing fields", content: "Some of the necessary fields are missing from the widget row. Comment has now become mandatory as well." }], confirm_function: () => {}, cancel_function: () => {} }})
        return
    }

    try {
        setUpdateLoading(true)
        const api_endpoint = `${host}/api/create_campaign/update`;
        const token = Cookies.get('token')
        let find = fb_ids.find(f => item.fb_id === f.unique)
        let result_to_save_in_widget = { ...item?.result }
        const arr_length = item.name ? item.name.length : item.picture.length
        let alert_msg = { adset_success: [], adset_error: [], ad_success: [], ad_error: [] }
        const adset_result = item?.result?.adset ? item?.result?.adset : []
        const ad_result = item?.result?.ad ? item?.result?.ad : []

        if (adset_result.filter(f => f?.id)?.length !== arr_length) {
            for (let index = 0; index < adset_result.length; index++) {
                if (!adset_result[index]?.id) {
                    try {
                        const response = await axios.post(api_endpoint, {
                            headers: {
                                "Access-Control-Allow-Origin": "*",
                                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                                "Authorization": "Bearer "+token,
                                "Content-Type": "application/json",
                            },
                            level: "adset",
                            template: {
                                campaign_id: result_to_save_in_widget.campaign.id,
                                account_id: find.id,
                                facebook_page: item.facebook_fan_page,
                                instagram_account: item.instagram_account,
                                link: (item.ad_template_link && Array.isArray(item.ad_template_link) && (item.ad_template_link.filter(f => f.trim()).length === arr_length)) ? item.ad_template_link : Array(arr_length).fill(item.final_url),
                                headline: Array(arr_length).fill(item?.headline?.[0]),
                                picture: item.ad_template_picture ? item.ad_template_picture : ( item.ad_media ? item.ad_media : item.picture.map(img_url => { return { type: "image", url: img_url } }) ),
                                message: Array(arr_length).fill(item?.message?.[0]),
                                status: "ACTIVE",
                                name: item.name ? item.name : [...Array(arr_length)].map((m, i) => `${i+1}`)
                            },
                            index: index
                        })
    
                        console.log(response)

                        if (response?.data?.result?.id) {
                            alert_msg.adset_success.push(response.data.result.id)
                            result_to_save_in_widget.adset[index] = { ...result_to_save_in_widget.adset[index], id: response.data.result.id }
                            delete result_to_save_in_widget.adset[index]?.error
                        } else {
                            if (response?.data?.result?.error?.error_user_msg) {
                                alert_msg.adset_error.push(`Adset Name: ${adset_result[index]?.name}~${response?.data?.result?.error?.error_user_msg}`)
                            } else if (response?.data?.result?.error?.message) {
                                alert_msg.adset_error.push(`Adset Name: ${adset_result[index]?.name}~${response?.data?.result?.error?.message}`)
                            } else {
                                alert_msg.adset_error.push(`Adset Name: ${adset_result[index]?.name}~Adset creation failed. Permission error from facebook. Please try again later.`)
                            }
                        }
                    } catch (error) {
                        console.log(error)
                        alert_msg.adset_error.push(`Adset Name: ${adset_result[index]?.name}~Adset creation failed. Permission error from facebook. Please try again later.`)
                    }
                }
            }
        }

        if (ad_result.filter(f => f?.id)?.length !== arr_length) {
            for (let index = 0; index < ad_result.length; index++) {
                if (!ad_result[index]?.id) {
                    console.log({
                        level: "ad",
                            template: {
                                adset_id: result_to_save_in_widget.adset[index].id,
                                account_id: find.id,
                                facebook_page: item.facebook_fan_page,
                                instagram_account: item.instagram_account,
                                link: (item.ad_template_link && Array.isArray(item.ad_template_link) && (item.ad_template_link.filter(f => f.trim()).length === arr_length)) ? item.ad_template_link : Array(arr_length).fill(item.final_url),
                                headline: Array(arr_length).fill(item?.headline?.[0]),
                                picture: item.ad_template_picture ? item.ad_template_picture : ( item.ad_media ? item.ad_media : item.picture.map(img_url => { return { type: "image", url: img_url } }) ),
                                message: Array(arr_length).fill(item?.message?.[0]),
                                status: "ACTIVE",
                                name: item.name ? item.name : [...Array(arr_length)].map((m, i) => `${i+1}`)
                            },
                            index: index
                    })
                    try {
                        const response = await axios.post(api_endpoint, {
                            headers: {
                                "Access-Control-Allow-Origin": "*",
                                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                                "Authorization": "Bearer "+token,
                                "Content-Type": "application/json",
                            },
                            level: "ad",
                            template: {
                                adset_id: result_to_save_in_widget.adset[index].id,
                                account_id: find.id,
                                facebook_page: item.facebook_fan_page,
                                instagram_account: item.instagram_account,
                                link: (item.ad_template_link && Array.isArray(item.ad_template_link) && (item.ad_template_link.filter(f => f.trim()).length === arr_length)) ? item.ad_template_link : Array(arr_length).fill(item.final_url),
                                headline: Array(arr_length).fill(item?.headline?.[0]),
                                picture: item.ad_template_picture ? item.ad_template_picture : ( item.ad_media ? item.ad_media : item.picture.map(img_url => { return { type: "image", url: img_url } }) ),
                                message: Array(arr_length).fill(item?.message?.[0]),
                                status: "ACTIVE",
                                name: item.name ? item.name : [...Array(arr_length)].map((m, i) => `${i+1}`)
                            },
                            index: index
                        })
    
                        console.log(response)

                        if (response?.data?.result?.id) {
                            alert_msg.ad_success.push(response.data.result.id)
                            result_to_save_in_widget.ad[index] = { ...result_to_save_in_widget.ad[index], id: response.data.result.id }
                            delete result_to_save_in_widget.ad[index]?.error
                        } else {
                            if (response?.data?.result?.error?.error_user_msg) {
                                alert_msg.ad_error.push(`Ad Name: ${ad_result[index]?.name}~${response?.data?.result?.error?.error_user_msg}`)
                            } else if (response?.data?.result?.error?.message) {
                                alert_msg.ad_error.push(`Ad Name: ${ad_result[index]?.name}~${response?.data?.result?.error?.message}`)
                            } else {
                                alert_msg.ad_error.push(`Ad Name: ${ad_result[index]?.name}~Ad creation failed. Permission error from facebook. Please try again later.`)
                            }
                        }
                    } catch (error) {
                        console.log(error)
                        alert_msg.ad_error.push(`Ad Name: ${ad_result[index]?.name}~Ad creation failed. Permission error from facebook. Please try again later.`)
                    }
                }
            }
        }
    
        if (
            (result_to_save_in_widget.adset.filter(f => f?.id).length === arr_length) &&
            (result_to_save_in_widget.ad.filter(f => f?.id).length === arr_length)
        ) {
            handleChangeSwitch({ fd: item, finished: true, result: result_to_save_in_widget })
        } else {
            handleChangeSwitch({ fd: item, finished: false, result: result_to_save_in_widget })
        }

        let final_message_for_dialog = []

        if (alert_msg.adset_success.length > 0) {
            final_message_for_dialog.push({ type: "Success", title: "Successful Adsets", content: alert_msg.adset_success.join(", ") })
        }
        if (alert_msg.ad_success.length > 0) {
            final_message_for_dialog.push({ type: "Success", title: "Successful Ads", content: alert_msg.ad_success.join(", ") })
        }
        alert_msg.adset_error.forEach(element => {
            final_message_for_dialog.push({ type: "Error", title: "Error "+element.split('~')[0], content: element.split('~')[1] })
        });
        alert_msg.ad_error.forEach(element => {
            final_message_for_dialog.push({ type: "Error", title: "Error "+element.split('~')[0], content: element.split('~')[1] })
        });

        if ((alert_msg.adset_success.length > 0 || alert_msg.ad_success.length > 0) && (alert_msg.adset_error.length === 0) && (alert_msg.ad_error.length === 0)) {
            appContext.setDialogInfo(p => {
                return {
                    ...p,
                    mode: "Alert",
                    type: "Success",
                    message: final_message_for_dialog,
                    confirm_function: () => {},
                    cancel_function: () => {}
                }
            })
        } else {
            appContext.setDialogInfo(p => {
                return {
                    ...p,
                    mode: "Alert",
                    type: "Error",
                    message: final_message_for_dialog,
                    confirm_function: () => {},
                    cancel_function: () => {}
                }
            })
        }
    } catch (error) {
        console.log(error)
        setUpdateLoading(false)
        window.alert("Error resending campaign." + error)
    }
}

// API to create new campaign and adset the facebook
const createFacebookCampaignAPI = ({ item, fb_ids, setUpdateLoading, handleChangeSwitch, appContext }) => {
    if (checkNewCampaignDisplay(item) === false) {
        appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Missing fields", content: "Some of the necessary fields are missing from the widget row. Comment has now become mandatory as well." }], confirm_function: () => {}, cancel_function: () => {} }})
        return
    }

    setUpdateLoading(true)
    const api_endpoint = `${host}/api/create_campaign/create`;
    const token = Cookies.get('token')
    let find = fb_ids.find(f => item.fb_id === f.unique)
    let result_to_save_in_widget = { campaign: {}, adset: [], ad: [] }
    const arr_length = item.name ? item.name.length : item.picture.length

    axios.post(api_endpoint, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type, x-access-token",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
            "Authorization": "Bearer "+token,
            "Content-Type": "application/json",
        },
        "campaign_template": {
            "campaign_name": item.widget.toString(),
            "objective": "OUTCOME_LEADS", 
            "status": "ACTIVE",
            "categories": item.categories,
            "account_id": find.id
        },
        "adset_template": {
            "pixel_id": find.pixels_id,
            "account_id": find.id, 
            "billing_event": "IMPRESSIONS",
            "bid_strategy": "LOWEST_COST_WITHOUT_CAP",
            "destination_type": "WEBSITE",  
            "daily_budget": Number(item.daily_budget),
            "start_time": moment().format("YYYY-MM-DD HH:mm:ss"), 
            "countries": item.country.split(","),
            "status": "ACTIVE",
            "name": item.name ? item.name : [...Array(arr_length)].map((m, i) => `${i+1}`)
        },
        ad_template: {
            account_id: find.id,
            facebook_page: item.facebook_fan_page,
            instagram_account: item.instagram_account,
            link: (item.ad_template_link && Array.isArray(item.ad_template_link) && (item.ad_template_link.filter(f => f.trim()).length === arr_length)) ? item.ad_template_link : Array(arr_length).fill(item.final_url),
            headline: Array(arr_length).fill(item?.headline?.[0]),
            picture: item.ad_template_picture ? item.ad_template_picture : ( item.ad_media ? item.ad_media : item.picture.map(img_url => { return { type: "image", url: img_url } }) ),
            message: Array(arr_length).fill(item?.message?.[0]),
            status: "ACTIVE"
        }
    })

    .then((response) => {
        console.log(response)
        const obj = response.data
        let result = { campaign: '', adset: '', ad: '', campaign_created: false, adset_created: false, ad_created: false}

        if (obj.campaign.data) {
            result_to_save_in_widget.campaign = obj.campaign
            if (obj.campaign.data.error) {
                if (obj.campaign.data.error.error_user_msg) {
                    result.campaign = obj?.campaign?.data?.error?.error_user_msg
                } else if (obj.campaign.data.error.message) {
                    result.campaign = obj?.campaign?.data?.error?.message
                } else {
                    result.campaign = "Creation failed! Permission error from facebook. Please try again later."
                }
            } else if (obj.campaign.data.id) {
                result.campaign = 'Campaign successfully created.'
                result.campaign_created = true
            }
        }
        
        if (obj.adset.adset && Array.isArray(obj.adset.adset) && obj.adset.adset.length > 0) {
            result_to_save_in_widget.adset = obj.adset.adset.map((m, index) => { return { ...m, name: index+1 } })
            if (obj.adset.adset.filter(f => f?.id).length !== arr_length) {
                const err = obj.adset.adset.find(f => f?.data)
                if (err?.data?.error?.error_user_msg) {
                    result.adset = 'Adsets creation failed midway. Facebook says ' + err?.data?.error?.error_user_msg
                } else if (err?.data?.error?.message) {
                    result.adset = 'Adsets creation failed midway. Facebook says ' + err?.data?.error?.message
                } else {
                    result.adset = "Creation failed! Permission error from facebook. Please try again later."
                }
            } else {
                result.adset = 'Adsets successfully created.'
                result.adset_created = true
            }
        }

        if (obj.adset.ad && Array.isArray(obj.adset.ad) && obj.adset.ad.length > 0) {
            result_to_save_in_widget.ad = obj.adset.ad.map((m, index) => { return { ...m, name: index+1 } })
            if (obj.adset.ad.filter(f => f?.id).length !== arr_length) {
                const err = obj.adset.ad.find(f => f?.error)
                if (err?.error?.error_user_msg) {
                    result.ad = 'Ads creation failed midway. Facebook says ' + err?.error?.error_user_msg
                } else if (err?.error?.message) {
                    result.ad = 'Ads creation failed midway. Facebook says ' + err?.error?.message
                } else {
                    result.ad = "Creation failed! Permission error from facebook. Please try again later."
                }
            } else {
                result.ad = 'Ads successfully created.'
                result.ad_created = true
            }
        }

        if (result.campaign_created && result.adset_created && result.ad_created) {
            handleChangeSwitch({ fd: item, finished: true, result: result_to_save_in_widget })
        } else if (result.campaign_created) {
            handleChangeSwitch({ fd: item, finished: false, result: result_to_save_in_widget })
        } else {
            setUpdateLoading(false)
        }

        if (result.campaign_created && result.adset_created && result.ad_created) {
            appContext.setDialogInfo(p => {
                return {
                    ...p,
                    mode: "Alert",
                    type: "Success",
                    message: [
                        { type: "Success", title: result.campaign, content: "" },
                        { type: "Success", title: result.adset, content: "" },
                        { type: "Success", title: result.ad, content: "" },
                    ],
                    confirm_function: () => {},
                    cancel_function: () => {}
                }
            })
        } else if (!result.campaign_created && !result.adset_created && !result.ad_created) {
            appContext.setDialogInfo(p => {
                return {
                    ...p,
                    mode: "Alert",
                    type: "Error",
                    message: [
                        { type: "Error", title: "Campaign creation failed", content: result.campaign },
                        { type: "Error", title: "Adsets creation failed", content: result.adset },
                        { type: "Error", title: "Ads creation failed", content: result.ad },
                    ],
                    confirm_function: () => {},
                    cancel_function: () => {}
                }
            })
        } else {
            appContext.setDialogInfo(p => {
                return {
                    ...p,
                    mode: "Alert",
                    type: "Warning",
                    message: [
                        { type: result.campaign_created ? "Success" : "Error", title: result.campaign_created ? result.campaign : "Campaign creation failed", content: result.campaign_created ? "" : result.campaign },
                        { type: result.adset_created ? "Success" : "Error", title: result.adset_created ? result.adset : "Adsets creation failed", content: result.adset_created ? "" : result.adset },
                        { type: result.ad_created ? "Success" : "Error", title: result.ad_created ? result.ad : "Ads creation failed", content: result.ad_created ? "" : result.ad },
                    ],
                    confirm_function: () => {},
                    cancel_function: () => {}
                }
            })
        }
    })

    .catch((error) => {
        window.alert(error);
        console.log(error)
        setUpdateLoading(false)
    })
}

// API to create new campaign in bulk
const bulkCreateFacebookCampaignAPI = async ({ items, fb_ids, setUpdateLoading, setRefresh, appContext }) => {
    setUpdateLoading(true)
    const token = Cookies.get('token')
    const url = `${host}/api/create_campaign/create`;
    const update_url = `${host}/api/index/update`;
    let alert_msg = { campaign_success: [], campaign_error: [], adset_success: [], adset_error: [], ad_success: [], ad_error: [] }
    let alert_msg_error_detail = { campaign_error: '', adset_error: '', ad_error: '' }

    let result = await Promise.all(items.map(async e => {
        const temp = {...e}
        let find = fb_ids.find(f => temp.fb_id === f.unique)
        let result_to_save_in_widget = { campaign: {}, adset: [], ad: [] }
        const arr_length = temp.name ? temp.name.length : temp.picture.length

        try {
            // campaign, adset and ad creation api
            const response = await axios.post(url, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                    "Authorization": "Bearer "+token,
                    "Content-Type": "application/json",
                },
                "campaign_template": {
                    "campaign_name": temp.widget.toString(),
                    "objective": "OUTCOME_LEADS", 
                    "status": "ACTIVE",
                    "categories": temp.categories,
                    "account_id": find.id
                },
                "adset_template": {
                    "pixel_id": find.pixels_id,
                    "account_id": find.id, 
                    "billing_event": "IMPRESSIONS",
                    "bid_strategy": "LOWEST_COST_WITHOUT_CAP",
                    "destination_type": "WEBSITE",  
                    "daily_budget": Number(temp.daily_budget),
                    "start_time": moment().format("YYYY-MM-DD HH:mm:ss"), 
                    "countries": temp.country.split(","),
                    "status": "ACTIVE",
                    "name": temp.name ? temp.name : [...Array(arr_length)].map((m, i) => `${i+1}`)
                },
                ad_template: {
                    account_id: find.id,
                    facebook_page: temp.facebook_fan_page,
                    instagram_account: temp.instagram_account,
                    link: (temp.ad_template_link && Array.isArray(temp.ad_template_link) && (temp.ad_template_link.filter(f => f.trim()).length === arr_length)) ? temp.ad_template_link : Array(arr_length).fill(temp.final_url),
                    headline: Array(arr_length).fill(temp?.headline?.[0]),
                    picture: temp.ad_template_picture ? temp.ad_template_picture : ( temp.ad_media ? temp.ad_media : temp.picture.map(img_url => { return { type: "image", url: img_url } }) ),
                    message: Array(arr_length).fill(temp?.message?.[0]),
                    status: "ACTIVE"
                }
            })
            
            if (response.data) {
                const obj = response.data

                if (obj.campaign.data) {
                    result_to_save_in_widget.campaign = obj.campaign
                    if (obj.campaign.data.id) {
                        alert_msg.campaign_success = [ ...alert_msg.campaign_success, temp.widget ]
                    } else if (obj.campaign.data.error) {
                        alert_msg.campaign_error = [ ...alert_msg.campaign_error, temp.widget ]
                        if (obj.campaign.data.error.error_user_msg) {
                            alert_msg_error_detail.campaign_error = obj?.campaign?.data?.error?.error_user_msg
                        } else if (obj.campaign.data.error.message) {
                            alert_msg_error_detail.campaign_error = obj?.campaign?.data?.error?.message
                        }
                    }
                }
                
                if (obj.adset.adset && Array.isArray(obj.adset.adset) && obj.adset.adset.length > 0) {
                    result_to_save_in_widget.adset = obj.adset.adset.map((m, index) => { return { ...m, name: index+1 } })
                    if (obj.adset.adset.filter(f => f.id).length !== arr_length) {
                        alert_msg.adset_error = [ ...alert_msg.adset_error, temp.widget ]
                        const err = obj.adset.adset.find(f => f?.data)
                        if (err?.data?.error?.error_user_msg) {
                            alert_msg_error_detail.adset_error = err?.data?.error?.error_user_msg
                        } else if (err?.data?.error?.message) {
                            alert_msg_error_detail.adset_error = err?.data?.error?.message
                        }
                    } else {
                        alert_msg.adset_success = [ ...alert_msg.adset_success, temp.widget ]
                    }
                }

                if (obj.adset.ad && Array.isArray(obj.adset.ad) && obj.adset.ad.length > 0) {
                    result_to_save_in_widget.ad = obj.adset.ad.map((m, index) => { return { ...m, name: index+1 } })
                    if (obj.adset.ad.filter(f => f.id).length !== arr_length) {
                        alert_msg.ad_error = [ ...alert_msg.ad_error, temp.widget ]
                        const err = obj.adset.ad.find(f => f?.error)
                        if (err?.error?.error_user_msg) {
                            alert_msg_error_detail.ad_error = err?.error?.error_user_msg
                        } else if (err?.error?.message) {
                            alert_msg_error_detail.ad_error = err?.error?.message
                        }
                    } else {
                        alert_msg.ad_success = [ ...alert_msg.ad_success, temp.widget ]
                    }
                }
            }

            // change widget to finished true API
            let updateResponse

            if (alert_msg.campaign_success.find(f => f === temp.widget) && alert_msg.adset_success.find(f => f === temp.widget) && alert_msg.ad_success.find(f => f === temp.widget)) {
                let data_to_send = {
                    ...temp,
                    fb_id: find.id,
                    finished: true,
                    result: result_to_save_in_widget,
                    keyword: temp.keyword
                        .split('\n')
                        .filter(f => f)
                        .map(m => m.
                            trim()
                            .split(' ')
                            .filter(fi => fi)
                            .map(e => e.charAt(0).toUpperCase() + e.slice(1))
                            .join(' '))
                }
                delete data_to_send?.adset_count
                delete data_to_send?.tonic_url
                updateResponse = await axios.post(update_url, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                        "Authorization": "Bearer "+token,
                        "Content-Type": "application/json",
                    },
                    data: data_to_send
                })
            }

            if (updateResponse?.data) {
                console.log(updateResponse?.data)
            }
        } catch (error) {
            console.log(error);
            window.alert(error);
            // window.alert('Failed. Bulk update went to catch.')
        }
        return temp
    }))

    setUpdateLoading(false)

    let final_message_for_dialog = []

    if (alert_msg.campaign_success.length > 0) {
        final_message_for_dialog.push({ type: "Success", title: "Successful Campaigns", content: alert_msg.campaign_success.join(", ") })
    }
    if (alert_msg.campaign_error.length > 0) { 
        final_message_for_dialog.push({ type: "Error", title: "Failed Campaigns", content: alert_msg.campaign_error.join(", ") })
    }
    if (alert_msg.adset_success.length > 0) { 
        final_message_for_dialog.push({ type: "Success", title: "Successful Adsets", content: alert_msg.adset_success.join(", ") })
    }
    if (alert_msg.adset_error.length > 0) { 
        final_message_for_dialog.push({ type: "Error", title: "Failed Adsets", content: alert_msg.adset_error.join(", ") })
    }
    if (alert_msg.ad_success.length > 0) { 
        final_message_for_dialog.push({ type: "Success", title: "Successful Ads", content: alert_msg.ad_success.join(", ") })
    }
    if (alert_msg.ad_error.length > 0) { 
        final_message_for_dialog.push({ type: "Error", title: "Failed Ads", content: alert_msg.ad_error.join(", ") })
    }
    if (alert_msg_error_detail.campaign_error) {
        final_message_for_dialog.push({ type: "Error", title: "Campaign Error Facebook says", content: alert_msg_error_detail.campaign_error })
    }
    if (alert_msg_error_detail.adset_error) {
        final_message_for_dialog.push({ type: "Error", title: "Adset Error Facebook says", content: alert_msg_error_detail.adset_error })
    }
    if (alert_msg_error_detail.ad_error) {
        final_message_for_dialog.push({ type: "Error", title: "Ad Error Facebook says", content: alert_msg_error_detail.ad_error })
    }

    setRefresh(p => !p)
    appContext.setDialogInfo(p => {
        return {
            ...p,
            mode: "Alert",
            type: ( (alert_msg.campaign_error.length === 0) && (alert_msg.adset_error.length === 0) && (alert_msg.ad_error.length === 0) ) ? (
                "Success"
            ) : (
                ( (alert_msg.campaign_success.length === 0) && (alert_msg.adset_success.length === 0) && (alert_msg.ad_success.length === 0) ) ? (
                    "Error"
                ) : (
                    "Warning"
                )
            ),
            message: final_message_for_dialog,
            confirm_function: () => {},
            cancel_function: () => {}
        }
    })
}

// API to check if the input URL is already in the database or not
const uploadImageAPI = async ({ images, fb_id, setUploadFbImage, fb_token, appContext }) => {
    if (!Array.isArray(images) || images?.length < 1 || !fb_id) {
        appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Empty fields", content: "Facebook A/C and at least an image should be selected to upload." }], confirm_function: () => {}, cancel_function: () => {} }})
        return
    }

    setUploadFbImage(p => { return { ...p, loading: true } })

    const createAdImage = async (id, base64Image) => {
        let url = `https://graph.facebook.com/v19.0/${id}/adimages`;
        const response = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            bytes: base64Image,
            access_token: fb_token,
          }),
        });
      
        let data = await response.json();
        console.log(data);
        return data;
    };

    let result = await Promise.all(images.map(async item => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onloadend = async () => {
                try {
                    let data = await createAdImage(fb_id, reader.result.split('base64,')[1]);
                    resolve(data);
                } catch (error) {
                    console.log(error);
                    reject(error);
                }
            };
            reader.readAsDataURL(item);
        });
    }))

    setUploadFbImage(p => { return { ...p, loading: false, act_id: fb_id, refresh: !p.refresh } })
}

// function to render facebook image/video selection component
const FacebookMediaSelectComponent = ({ loading, fbMediaState, setFbMediaState, fb_images, fb_videos, addDetail, setAddDetail, updateDetail, setUpdateDetail, bulkUpdate, setBulkUpdate, uploadFbImage, setUploadFbImage, fb_token, appContext }) => {
    const [displayImage, setDisplayImage] = useState({ display: true, url: '', thumbnails: [] })

    const fb_images_filtered = (fb_images[fbMediaState.act_id] && Array.isArray(fb_images[fbMediaState.act_id])) ? fb_images[fbMediaState.act_id] : []
    const fb_videos_filtered = (fb_videos[fbMediaState.act_id] && Array.isArray(fb_videos[fbMediaState.act_id])) ? fb_videos[fbMediaState.act_id] : []

    // find selected images or videos length
    const returnMediaLength = () => {
        let result = { image: 0, video: 0, jsx: <></> }

        // type add
        if (fbMediaState.mode === 'add') {
            result = {
                image: addDetail.ad_media.filter(f => f.type === 'image').length,
                video: addDetail.ad_media.filter(f => f.type === 'video').length,
                jsx: <>
                    {addDetail.ad_media.find(f => f.type) ? (
                        addDetail.ad_media.filter(f => f.type).length + " / " + addDetail.ad_media.length + " selected"
                    ) : "None selected"}
                </>
            }
        }

        // type update
        else if (fbMediaState.mode === 'update') {
            result = {
                image: updateDetail.ad_media.filter(f => f.type === 'image').length,
                video: updateDetail.ad_media.filter(f => f.type === 'video').length,
                jsx: <>
                    {updateDetail.ad_media.find(f => f.type) ? (
                        updateDetail.ad_media.filter(f => f.type).length + " / " + updateDetail.ad_media.length + " selected"
                    ) : "None selected"}
                </>
            }
        }

        // type update-bulk
        else if (fbMediaState.mode === 'update-bulk') {
            result = {
                image: bulkUpdate.list[fbMediaState?.widget?.toString()]?.ad_media.filter(f => f.type === 'image').length,
                video: bulkUpdate.list[fbMediaState?.widget?.toString()]?.ad_media.filter(f => f.type === 'video').length,
                jsx: <>
                    {bulkUpdate.list[fbMediaState?.widget?.toString()]?.ad_media.find(f => f.type) ? (
                        bulkUpdate.list[fbMediaState?.widget?.toString()]?.ad_media.filter(f => f.type).length + " / " + bulkUpdate.list[fbMediaState?.widget?.toString()]?.ad_media.length + " selected"
                    ) : "None selected"}
                </>
            }
        }

        return result
    }
    
    // condition to know from where to clear media from
    const clearAllCondition = () => {
        // mode add
        if (fbMediaState.mode === 'add') {
            setAddDetail(p => { return { ...p, picture: Array(Number(p.adset_count)).fill(""), ad_media: Array(Number(p.adset_count)).fill({}) } })
        }

        // mode update
        else if (fbMediaState.mode === 'update') {
            setUpdateDetail(p => { return { ...p, picture: Array(Number(p.adset_count)).fill(""), ad_media: Array(Number(p.adset_count)).fill({}) } })
        }

        // mode add
        else if (fbMediaState.mode === 'update-bulk') {
            setBulkUpdate(p => {
                let temp = { ...p.list }
                temp[fbMediaState?.widget?.toString()] = { ...temp[fbMediaState?.widget?.toString()], picture: Array(Number(temp[fbMediaState?.widget?.toString()]?.adset_count)).fill(""), ad_media: Array(Number(temp[fbMediaState?.widget?.toString()]?.adset_count)).fill({}) }
                return {
                    ...p,
                    list: temp
                }
            })
        }
    }

    // if image or video is selected then return an absolute overlay
    const returnSelectedMedia = ({ type, item }) => {
        let result = <></>

        // mode add
        if (fbMediaState.mode === 'add') {
            // type image
            if (type === 'image') {
                result = <>
                    {addDetail.ad_media.find(f => (f.type === 'image') && (f.url === item.url)) && (
                        <div>
                            <div>{addDetail.ad_media.findIndex(obj => (obj.type === 'image') && (obj.url === item.url)) + 1}</div>
                        </div>
                    )}
                </>
            } else { // type video
                result = <>
                    {addDetail.ad_media.find(f => (f.type === 'video') && (f.video_id === item.id)) && (
                        <div onClick={() => clickCondition({ type: 'video', url: item.picture, video_id: item.id })}>
                            <div>{addDetail.ad_media.findIndex(obj => (obj.type === 'video') && (obj.video_id === item.id)) + 1}</div>
                        </div>
                    )}
                </>
            }
        }

        // mode update
        else if (fbMediaState.mode === 'update') {
            // type image
            if (type === 'image') {
                result = <>
                    {updateDetail.ad_media.find(f => (f.type === 'image') && (f.url === item.url)) && (
                        <div>
                            <div>{updateDetail.ad_media.findIndex(obj => (obj.type === 'image') && (obj.url === item.url)) + 1}</div>
                        </div>
                    )}
                </>
            } else { // type video
                result = <>
                    {updateDetail.ad_media.find(f => (f.type === 'video') && (f.video_id === item.id)) && (
                        <div onClick={() => clickCondition({ type: 'video', url: item.picture, video_id: item.id })}>
                            <div>{updateDetail.ad_media.findIndex(obj => (obj.type === 'video') && (obj.video_id === item.id)) + 1}</div>
                        </div>
                    )}
                </>
            }
        }

        // mode update-bulk
        else if (fbMediaState.mode === 'update-bulk') {
            // type image
            if (type === 'image') {
                result = <>
                    {bulkUpdate.list[fbMediaState?.widget?.toString()]?.ad_media.find(f => (f.type === 'image') && (f.url === item.url)) && (
                        <div>
                            <div>{bulkUpdate.list[fbMediaState?.widget?.toString()]?.ad_media.findIndex(obj => (obj.type === 'image') && (obj.url === item.url)) + 1}</div>
                        </div>
                    )}
                </>
            } else { // type video
                result = <>
                    {bulkUpdate.list[fbMediaState?.widget?.toString()]?.ad_media.find(f => (f.type === 'video') && (f.video_id === item.id)) && (
                        <div onClick={() => clickCondition({ type: 'video', url: item.picture, video_id: item.id })}>
                            <div>{bulkUpdate.list[fbMediaState?.widget?.toString()]?.ad_media.findIndex(obj => (obj.type === 'video') && (obj.video_id === item.id)) + 1}</div>
                        </div>
                    )}
                </>
            }
        }

        return result
    }

    // set media state depending on the type
    const clickCondition = ({ type, url, video_id }) => {
        // mode add
        if (fbMediaState.mode === 'add') {
            // type image
            if (type === 'image') {
                let temp = [ ...addDetail.picture ]
                let temp_media = [ ...addDetail.ad_media ]
                let find_item = temp_media.find(f => (f.type === 'image') && (f.url === url))
    
                if (find_item) {
                    temp.splice(temp.indexOf(url), 1)
                    temp.push('')
                    temp_media.splice(temp_media.findIndex(obj => (obj.type === 'image') && (obj.url === url)), 1)
                    temp_media.push({})
                } else {
                    let find_empty = temp_media.find(f => !f.type)
                    if (find_empty) {
                        temp[temp.indexOf('')] = url
                        temp_media[temp_media.findIndex(obj => !obj.type)] = {type: 'image', url: url}
                    }
                }
    
                setAddDetail(p => { return { ...p, picture: temp, ad_media: temp_media } })
            }
            // type video
            else {
                let temp = [ ...addDetail.picture ]
                let temp_media = [ ...addDetail.ad_media ]
                let find_item = temp_media.find(f => (f.type === 'video') && (f.video_id === video_id))
        
                if (find_item) {
                    temp.splice(temp.indexOf(url), 1)
                    temp.push('')
                    temp_media.splice(temp_media.findIndex(obj => (obj.type === 'video') && (obj.video_id === video_id)), 1)
                    temp_media.push({})
                } else {
                    let find_empty = temp_media.find(f => !f.type)
                    if (find_empty) {
                        temp[temp.indexOf('')] = url
                        temp_media[temp_media.findIndex(obj => !obj.type)] = {type: 'video', url: url, video_id: video_id}
                    }
                }
        
                setAddDetail(p => { return { ...p, picture: temp, ad_media: temp_media } })
            }
        }
        
        // mode update
        else if (fbMediaState.mode === 'update') {
            // type image
            if (type === 'image') {
                let temp = [ ...updateDetail.picture ]
                let temp_media = [ ...updateDetail.ad_media ]
                let find_item = temp_media.find(f => (f.type === 'image') && (f.url === url))
    
                if (find_item) {
                    temp.splice(temp.indexOf(url), 1)
                    temp.push('')
                    temp_media.splice(temp_media.findIndex(obj => (obj.type === 'image') && (obj.url === url)), 1)
                    temp_media.push({})
                } else {
                    let find_empty = temp_media.find(f => !f.type)
                    if (find_empty) {
                        temp[temp.indexOf('')] = url
                        temp_media[temp_media.findIndex(obj => !obj.type)] = {type: 'image', url: url}
                    }
                }
    
                setUpdateDetail(p => { return { ...p, picture: temp, ad_media: temp_media } })
            }
            // type video
            else {
                let temp = [ ...updateDetail.picture ]
                let temp_media = [ ...updateDetail.ad_media ]
                let find_item = temp_media.find(f => (f.type === 'video') && (f.video_id === video_id))
        
                if (find_item) {
                    temp.splice(temp.indexOf(url), 1)
                    temp.push('')
                    temp_media.splice(temp_media.findIndex(obj => (obj.type === 'video') && (obj.video_id === video_id)), 1)
                    temp_media.push({})
                } else {
                    let find_empty = temp_media.find(f => !f.type)
                    if (find_empty) {
                        temp[temp.indexOf('')] = url
                        temp_media[temp_media.findIndex(obj => !obj.type)] = {type: 'video', url: url, video_id: video_id}
                    }
                }
        
                setUpdateDetail(p => { return { ...p, picture: temp, ad_media: temp_media } })
            }
        }

        // mode update-bulk
        else if (fbMediaState.mode === 'update-bulk') {
            // type image
            if (type === 'image') {
                let temp = [ ...bulkUpdate.list[fbMediaState?.widget?.toString()].picture ]
                let temp_media = [ ...bulkUpdate.list[fbMediaState?.widget?.toString()].ad_media ]
                let find_item = temp_media.find(f => (f.type === 'image') && (f.url === url))
    
                if (find_item) {
                    temp.splice(temp.indexOf(url), 1)
                    temp.push('')
                    temp_media.splice(temp_media.findIndex(obj => (obj.type === 'image') && (obj.url === url)), 1)
                    temp_media.push({})
                } else {
                    let find_empty = temp_media.find(f => !f.type)
                    if (find_empty) {
                        temp[temp.indexOf('')] = url
                        temp_media[temp_media.findIndex(obj => !obj.type)] = {type: 'image', url: url}
                    }
                }

                setBulkUpdate(p => {
                    let temp_list = { ...p.list }
                    temp_list[fbMediaState?.widget?.toString()] = { ...temp_list[fbMediaState?.widget?.toString()], picture: temp, ad_media: temp_media }
                    return {
                        ...p,
                        list: temp_list
                    }
                })
            }
            // type video
            else {
                let temp = [ ...bulkUpdate.list[fbMediaState?.widget?.toString()].picture ]
                let temp_media = [ ...bulkUpdate.list[fbMediaState?.widget?.toString()].ad_media ]
                let find_item = temp_media.find(f => (f.type === 'video') && (f.video_id === video_id))
        
                if (find_item) {
                    temp.splice(temp.indexOf(url), 1)
                    temp.push('')
                    temp_media.splice(temp_media.findIndex(obj => (obj.type === 'video') && (obj.video_id === video_id)), 1)
                    temp_media.push({})
                } else {
                    let find_empty = temp_media.find(f => !f.type)
                    if (find_empty) {
                        temp[temp.indexOf('')] = url
                        temp_media[temp_media.findIndex(obj => !obj.type)] = {type: 'video', url: url, video_id: video_id}
                    }
                }
        
                setBulkUpdate(p => {
                    let temp_list = { ...p.list }
                    temp_list[fbMediaState?.widget?.toString()] = { ...temp_list[fbMediaState?.widget?.toString()], picture: temp, ad_media: temp_media }
                    return {
                        ...p,
                        list: temp_list
                    }
                })
            }
        }
    }

    return (
        <div className='widget-fb-media-container' style={{ display: fbMediaState.display ? 'flex' : 'none' }}>
            <div className='widget-fb-media-scroll'>
                <div className='widget-fb-media'>

                    <div className='widget-fb-media-top'>
                        <div className='widget-fb-media-top-navigate'>
                            <div>
                                <div style={{ background: displayImage.display ? '#000' : '#EEE' }} onClick={() => setDisplayImage(p => { return { ...p, display: true, url: '', thumbnails: [] } })}>
                                    <div style={{ color: displayImage.display ? '#000' : '#777' }}>{returnMediaLength().image}</div>
                                    <div style={{ color: displayImage.display ? '#FFF' : '#777' }}>Images</div>
                                </div>
                                <div style={{ background: displayImage.display ? '#EEE' : '#000' }} onClick={() => setDisplayImage(p => { return { ...p, display: false, url: '', thumbnails: [] } })}>
                                    <div style={{ color: displayImage.display ? '#777' : '#000' }}>{returnMediaLength().video}</div>
                                    <div style={{ color: displayImage.display ? '#777' : '#FFF' }}>Videos</div>
                                </div>
                            </div>
                            <div style={{ gridTemplateColumns: "1fr", alignItems: 'center', fontSize: '14px' }}>
                            {returnMediaLength().jsx}
                            </div>
                        </div>


                        <div className='widget-fb-media-top-close' onClick={() => setFbMediaState(p => { return { ...p, display: false, mode: '', act_id: '', widget: 0 } })}>
                            <FaXmark size={30} color={"#F00"} />
                        </div>
                    </div>

                    {displayImage.display ? (
                        <div className='widget-fb-media-bottom'>
                            <div className='widget-fb-media-bottom-left'>
                                {loading ? (
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <div className='loader' style={{ height: '100px', width: '100px' }}/>
                                    </div>
                                ) : (
                                    (fb_images_filtered.length > 0) ? (
                                        <div>
                                            {fb_images_filtered.map((item, i) => (
                                                <div key={i} className='widget-fb-media-bottom-list-image'>
                                                    <div onMouseEnter={() => setDisplayImage(p => { return { ...p, url: item.url } })}
                                                        onMouseLeave={() => setDisplayImage(p => { return { ...p, url: "" } })}
                                                        onClick={() => clickCondition({ type: 'image', url: item.url, video_id: '' })}
                                                    >
                                                        {returnSelectedMedia({ type: 'image', item: item })}
                                                        <img src={item?.url_128} alt=""/>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <p style={{ fontSize: '16px', textAlign: 'center' }}>{!fbMediaState.act_id ? 'Select a Facebook account first.' : 'No images! Either this Facebook account has no images or this Facebook account has exceeded its request limit. If the problem persist, you can try after 10-15 minutes so that the facebook limit can reset POSSIBLY.'}</p>
                                        </div>
                                    )
                                )}
                            </div>

                            <div className='widget-fb-media-bottom-right'>
                                <div className='widget-fb-media-bottom-buttons'>
                                    <input id='widget-fb-image-upload' type='file' accept='image/*' multiple style={{ display: 'none' }}
                                        onChange={e => fbMediaState.act_id && uploadImageAPI({ images: Array.from(e.target.files), fb_id: fbMediaState.act_id, setUploadFbImage, fb_token, appContext })}
                                    />
                                    <div style={{ background: '#4caf50', cursor: loading ? 'not-allowed' : 'pointer' }} onClick={() => !loading && document.getElementById('widget-fb-image-upload').click()}>Upload</div>
                                    <div style={{ background: '#FF7F7F', cursor: loading ? 'not-allowed' : 'pointer' }} onClick={() => !loading && clearAllCondition()}>Clear All</div>
                                    <div style={{ background: '#1976d2', cursor: loading ? 'not-allowed' : 'pointer' }} onClick={() => !loading && setUploadFbImage(p => { return { ...p, act_id: fbMediaState.act_id, refresh: !p.refresh } })}>Refresh Images</div>
                                </div>

                                <div className='widget-fb-media-bottom-zoom-image'>
                                    <img src={(displayImage.url === '') ? noImage : displayImage.url} alt="" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='widget-fb-media-bottom'>
                            <div className='widget-fb-media-bottom-left'>
                                {loading ? (
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <div className='loader' style={{ height: '100px', width: '100px' }}/>
                                    </div>
                                ) : (
                                    (fb_videos_filtered.length > 0) ? (
                                        <div>
                                            {fb_videos_filtered.map((item, i) => (
                                                <div key={i} className='widget-fb-media-bottom-list-image'>
                                                    <div>
                                                        <div style={{ position: 'absolute', zIndex: '2', top: 'calc(100% - 35px)', left: 'calc(100% - 35px)', height: 'fit-content', width: 'fit-content', background: '#FFF', borderRadius: '100%', padding: '0px' }}
                                                            onClick={() => {
                                                                if (displayImage.url === item?.picture) {
                                                                    setDisplayImage(p => { return { ...p, url: "", thumbnails: [] } })
                                                                } else {
                                                                    setDisplayImage(p => { return { ...p, url: item.picture, thumbnails: (item.thumbnails && item.thumbnails.data && Array.isArray(item.thumbnails.data)) ? item.thumbnails.data : [] } })
                                                                }
                                                            }}
                                                        >
                                                            {(displayImage.url === item?.picture) ? (
                                                                <div id='widget-media-component-eye-close' style={{ height: '30px', width: '30px', background: '#000' }}>
                                                                    <FaEyeSlash size={24} color={"#FFF"}/>
                                                                </div>
                                                            ) : (
                                                                <div id='widget-media-component-eye-open' style={{ height: '30px', width: '30px', background: '#FFF' }}>
                                                                    <FaEye size={24} color={"#000"}/>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <img src={item.picture} alt="" onClick={() => clickCondition({ type: 'video', url: item.picture, video_id: item.id })}/>
                                                        {returnSelectedMedia({ type: 'video', item: item })}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <p style={{ fontSize: '16px', textAlign: 'center' }}>{!fbMediaState.act_id ? 'Select a Facebook account first.' : 'No videos! Either this Facebook account has no videos or this Facebook account has exceeded its request limit. If the problem persist, you can try after 10-15 minutes so that the facebook limit can reset POSSIBLY.'}</p>
                                        </div>
                                    )
                                )}
                            </div>

                            <div className='widget-fb-media-bottom-right'>
                                <div className='widget-fb-media-bottom-buttons' style={{ gridTemplateColumns: '1fr 1fr' }}>
                                    <div style={{ background: '#FF7F7F', cursor: loading ? 'not-allowed' : 'pointer' }} onClick={() => !loading && clearAllCondition()}>Clear All</div>
                                    <div style={{ background: '#1976d2', cursor: loading ? 'not-allowed' : 'pointer' }} onClick={() => !loading && setUploadFbImage(p => { return { ...p, act_id: fbMediaState.act_id, refresh: !p.refresh } })}>Refresh Videos</div>
                                </div>

                                <div className='widget-fb-media-bottom-zoom-video'>
                                    <div>
                                        <img src={(displayImage.url === '') ? noImage : displayImage.url} alt="" />
                                        {(displayImage.thumbnails.length > 0) && <p>Thumbnails</p>}
                                        <div>
                                            {displayImage.thumbnails.map((item, i) => (
                                                <img key={i} src={item?.uri} style={{ display: item.uri ? 'inline-block' : 'none' }}/>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div>
    )
}

// function to render same url widget bulk delete component
const SameUrlDeleteComponent = ({ bulkUpdate, setBulkUpdate, filteredDataToMap, deleteFunction }) => {
    const [rowsToDelete, setRowsToDelete] = useState([])

    return (
        <div className="dialog-main" style={{ display: ((Cookies.get('role') === 'admin') && bulkUpdate.display_multi_delete) ? 'flex' : 'none' }}>
            <div className="dialog-container">
                <div className="dialog-box" style={{ borderTopColor: '#ff5757' }}>
                    <div className='dialog-box-confirm-ui'>
                        <FaTrashAlt color={'#ff5757'} size={50} />
                        <div>
                            <p style={{ fontSize: '18px', fontWeight: '600', marginBottom: '4px' }}>Same URL widgets found. Select to delete!</p>
                            {bulkUpdate.duplicate_url.map((e, i) => {
                                let find_fb_name = ''
                                const local_fb = window.localStorage.getItem('fbAccounts') ? JSON.parse(window.localStorage.getItem('fbAccounts')) : []
                                
                                if (Array.isArray(local_fb)) {
                                    find_fb_name = local_fb.find(f => f.act_id === e.fb_id)?.act_name
                                }

                                return (
                                    <div key={i} style={{ display: 'flex', alignItems: 'center', gap: '10px', padding: '5px 0px', borderBottom: '1px solid #EEE', cursor: 'pointer' }}
                                        onMouseEnter={e => e.currentTarget.style.background = '#EEE'}
                                        onMouseLeave={e => e.currentTarget.style.background = '#FFF'}
                                        onClick={() => setRowsToDelete(p => {
                                            let temp = [...p]
                                            if (temp.find(f => f.campaign_name === e.widget)) {
                                                temp = temp.filter(f => f.campaign_name !== e.widget)
                                            } else {
                                                temp.push({ campaign_name: e.widget })
                                            }
                                            return temp
                                        })}
                                    >
                                        <input type='checkbox' checked={rowsToDelete.find(f => f.campaign_name === e.widget) ? true : false} readOnly={true} style={{ height: '20px', width: '20px' }} />
                                        <p style={{ fontSize: '14px', fontWeight: '600' }}>{e.widget} ~ <span style={{ fontSize: '14px', fontWeight: '400', color: '#1976d2' }}>{find_fb_name}</span> ~ <span style={{ fontSize: '14px', fontWeight: '400', color: '#9E3AC3', wordBreak: 'break-all' }}>{e?.username}</span></p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <hr style={{ border: '1px solid rgba(0, 0, 0, 0.05)' }}/>

                    <div className="dialog-box-buttons">
                        <div className="dialog-box-button-cancel"
                            onClick={() => {
                                setRowsToDelete([])
                                setBulkUpdate(p => { return { ...p, loading: false, duplicate_url: [], display_multi_delete: false, clear_list: true } })
                            }}
                        >
                            Cancel
                        </div>
                        <div className="dialog-box-button-confirm"
                            onClick={() => {
                                if (rowsToDelete.length > 0) {
                                    let should_refresh = false
                                    if (filteredDataToMap.find(f => rowsToDelete.find(fin => f.widget === fin.campaign_name))) {
                                        should_refresh = true
                                    }
                                    setBulkUpdate(p => { return { ...p, loading: false, duplicate_url: [], display_multi_delete: false, clear_list: false } })
                                    setRowsToDelete([])
                                    deleteFunction({ fd: rowsToDelete, refresh_list: should_refresh })
                                } else {
                                    setBulkUpdate(p => { return { ...p, loading: false, duplicate_url: [], display_multi_delete: false, clear_list: true } })
                                    setRowsToDelete([])
                                }
                            }}
                        >
                            Confirm
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const handleMarkUnusedDomainsAPI = async (dataToSend) => {
    try {
        const api_endpoint = `${host}/api/unused_domains/update_unused_domain`;
        const token = Cookies.get('token')
    
        const response = await axios.post(api_endpoint, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
            },
            data: dataToSend,
        })
    
        console.log(response)
    } catch (error) {
        console.log(error)
    }
}