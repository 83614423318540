import React, { useState, useEffect, useContext } from "react";
import './sedo.css'
import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Papa from "papaparse";

import sedoLogo from "../../assets/sedoLogo.png";
import { host } from '../../hooks/host'
import { Context } from "../../AppContext";

const SedoDomain = () => {
  const appContext = useContext(Context)

  const [fileData, setFileData] = useState(null); // csv file
  const [data, setData] = useState([]); // database data
  const [filteredData, setFilteredData] = useState([]);
  const [domainNames, setDomainNames] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState('top-dental-implants.store');
  const [fromDate, setFromDate] = useState(moment().utc().add(8, 'hours').subtract(2, 'days').format('YYYY-MM-DD'))
  const [toDate, setToDate] = useState(moment().utc().add(8, 'hours').subtract(2, 'days').format('YYYY-MM-DD'))
  const url = `${host}`;
  const token = Cookies.get("token");
  const username = Cookies.get('userArray')

  const columns = [
    { field: "Keyword", headerName: "Keyword", minWidth: 400, flex: 1, headerClassName: 'datagridHeader' },
    { field: "Clicks", headerName: "Clicks", minWidth: 100, flex: 1, headerClassName: 'datagridHeader', type: 'number' },
  ];

  // submit file
  async function fetchSubmit() {
    try {
      const req = await axios.post(`${url}/saveFile`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
          "Authorization": "Bearer "+token,
        },
        file: fileData,
      });
      const obj = req.data.result;
      return obj;
    } catch (error) {
      window.alert('Could not complete the process.\nMake sure you are uploading a CSV file.')
      if (error?.response?.data?.message === 'Invalid') {
        appContext.forceLogout()
      }
    }
  }
  
  // get db data
  async function getDomainData() {
    try {
      const req = await axios.post(`${url}/getFile`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
          "Authorization": "Bearer "+token,
        },
      });
      const obj = req.data.result;
  
      let sameDomain = obj.filter((ele, ind) => ind === obj.findIndex( elem => elem.Domain === ele.Domain))
      let domainList = sameDomain.map((e) => e.Domain)
  
      setDomainNames(domainList)
      setData(obj);
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message === 'Invalid') {
        appContext.forceLogout()
      }
    }
  }

  // upload file
  const uploadFile = (e) => {
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setFileData(results.data);
      },
    });
  };

  useEffect(() => {
    if (selectedDomain === '') {
      setFilteredData([]);
    } else {
      let filterByDomain = data.filter(e => { return e.Domain === selectedDomain })
      if (fromDate !== null && toDate !== null) {
        let filterByDate = filterByDomain.filter((obj) => { return obj.Date >= fromDate && obj.Date <= toDate })

        const countBy = (arr,name) =>{
          let clicks = {}
          let s = 0
          let arra = arr.filter((f) => f.Keyword === name)
          s  = arra.map(e=>e.Clicks).reduce((acc, val) => acc  + val )
          clicks = {Keyword: name, Clicks:s}
          return clicks
        }

        let s = Array.from(new Set(filterByDate.map((e)=> e.Keyword))) 

        setFilteredData(s.map(e=> countBy(filterByDate,e)))
      }
    }

  }, [fromDate, toDate, selectedDomain])

  useEffect(() => {
    getDomainData();
  }, []);

  useEffect(() => {}, [data]);

  return (
    <div className="trafficContainer">
      <div className="trafficBottomContainer">
        <div className="trafficBottomWrapper">
          {/* logo and date */}
          <div className="logoDateContainer">
            <div className="logoWrapper">
              <img src={sedoLogo} alt="LOGO"/>
            </div>
            <div id="domainUsername" className="totalTitle">Username:<br/>{(username.toString()).replaceAll(",", " - ")}</div>
          </div>

          {/* list and date */}
          <div className="csvFileSave">
            <div>
              <label htmlFor="file">Select your CSV file to upload.</label>
              {/* input file .csv */}
              <input
                className="chooseFile"
                type="file"
                name="file"
                onChange={uploadFile}
              />
            </div>

            {(fileData) ? (
              <div>
                {/* Submit button */}
                <button className="saveDatabaseButton" onClick={() => fetchSubmit()}>
                  Save in DataBase
                </button>
              </div>
            ) : <></>}
          </div>

          {/* list and date */}
          <div className="listDateContainer">
            <div className="selectContainer">
              <p>Select Domain:</p>
              <select name="traffic" id="traffic" defaultValue='' onChange={(e) => {setSelectedDomain(e.target.value);}}>
                <option value='' disabled>Choose...</option>
                {domainNames.map((e, i) => (
                  <option value={e} key={i}>{e}</option>
                ))}
              </select>
            </div>

            <div className="dateWrapper">
              <div className="dateWrapperDiv">
                <label htmlFor="fromTopDate">From: </label>
                <input id="fromTopDate" type="date" className="datePicker" value={fromDate} onChange={(e) => {setFromDate(e.target.value);}}/>
              </div>
              <div className="dateWrapperDiv">
                <label htmlFor="toTopDate">To: </label>
                <input id="toTopDate" type="date" className="datePicker" value={toDate} onChange={(e) => {setToDate(e.target.value);}}/>
              </div>
            </div>
          </div>

          {/* datagrid */}
          <div className="dataGridContainer">
            <DataGrid
              components={{ Toolbar: GridToolbar }}
              sx={{
              boxShadow: 3,
              '& .datagridHeader': {
                color: '#1976d2'
              },
              }}
              // loading={loading} 
              getRowId={(row) => row.Keyword}
              rows={filteredData}
              columns={columns}
              disableSelectionOnClick={true}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SedoDomain;
