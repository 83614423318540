import { useState, useEffect, useContext } from "react";
import axios from "axios";
import moment from "moment";
import Cookies from "js-cookie";
import { host } from './host'
import { Context } from "../AppContext";

const useFetchCampaignCSV = ({ fromDate, toDate }) => {
  const appContext = useContext(Context)

  const [loadingTotal, setLoadingTotal] = useState(true);
  const [campaignData, setCampaignData] = useState([]);
  const [resultsArray, setResultsArray] = useState({date: [], result: [], spent: [], roi: []});
  const [totalValue, setTotalValue] = useState({
    revenue: 0,
    spent: 0,
    actions: 0,
    result: 0,
    CPA: 0,
    clicks: 0,
    "link_clicks": 0,
    "cost_per_conversion" : 0,
    "ROI%": 0,
    "green/red": 0,
  });

  useEffect(() => {
    const controller = new AbortController();
    const token = Cookies.get("token");
    const url = `${host}/api/data/`;

    const getData = async () => {
      setLoadingTotal(true);
      let user = Cookies.get("userArray");

      await axios
        .post(
          url,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "Content-Type, x-access-token",
              "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
              "Authorization": "Bearer "+token,
              "Content-Type": "application/json",
            },
            data: {
              traffic: 'csv_facebook',
              from: fromDate,
              trafficName: 'Facebook',
              to: toDate,
              username: user,
              lastDate: moment().utc().add(8, 'hours').subtract(2, 'days').format('YYYY-MM-DD')
            },
          },
          {
            signal: controller.signal
          }
        )

        .then((response) => {
          const obj = response.data;

          if (obj.message) {
            setCampaignData([]);
            setTotalValue({
              revenue: 0,
              spent: 0,
              actions: 0,
              result: 0,
              CPA: 0,
              clicks: 0,
              "link_clicks": 0,
              "cost_per_conversion" : 0,
              "ROI%": 0,
              "green/red": 0,
            });
            setResultsArray({date: [], result: [], spent: [], roi: []})
          } else {
            let total = obj.totalByDate.filter((e) => e.date !== "Total").sort((a, b) => new Date(a.date) - new Date(b.date))
            setResultsArray({
              date: total.map((data) => data.date),
              result: total.map((data) => data.result.toFixed(2) * 1),
              spent: total.map((data) => data.spent.toFixed(2) * 1),
              roi: total.map((data) => data["ROI%"].toFixed(2) * 1)
            });
            setCampaignData(obj.data);
            setTotalValue(obj.totalByDate.find(f => f.date === 'Total'));
          }

          setLoadingTotal(false);
        })

        .catch((error) => {
          console.log(error);
          setLoadingTotal(false);
          if (error?.response?.data?.message === 'Invalid') {
            appContext.forceLogout()
          }
        });
    };

    getData();

    return () => {
      controller.abort()
    };
  }, [fromDate, toDate]);

  return [loadingTotal, campaignData, totalValue, resultsArray];
};

export default useFetchCampaignCSV;