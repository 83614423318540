import React, { Fragment, useState, useEffect } from "react";
import "./App.css";
import Cookies from "js-cookie";
import axios from "axios";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Context } from "./AppContext";
import { host } from "./hooks/host";
import PageNotFound from "./components/pageNotFound/PageNotFound";
import DialogBox from "./components/reusable/dialogBox/DialogBox";
import Login from "./components/login/Login";
import Topbar from "./components/topbar/Topbar";
import Sidebar from "./components/sidebar/Sidebar";
import AllFacebook from "./components/all/AllFacebook";
import AllCountriesFacebook from "./components/allCountries/AllCountriesFacebook";
import AllEmailOctopus from "./components/all/AllEmailOctopus";
import AdsTotal from "./components/ads/AdsTotal";
import AdsFacebook from "./components/ads/AdsFacebook";
import TonicTotal from "./components/tonic/TonicTotal";
import TonicFacebook from './components/tonic/TonicFacebook'
import TonicTiktok from "./components/tonic/TonicTiktok";
import System1Total from "./components/system1/System1Total";
import System1Facebook from "./components/system1/System1Facebook";
import SedoTotal from "./components/sedo/SedoTotal";
import SedoFacebook from "./components/sedo/SedoFacebook";
import SedoTikTok from "./components/sedo/SedoTikTok";
import SedoDomain from "./components/sedo/SedoDomain"
import CrossroadsTotal from "./components/crossroads/CrossroadsTotal";
import CrossroadsFacebook from "./components/crossroads/CrossroadsFacebook";
import UserPage from "./components/user/UserPage";
import MainHome from "./components/mainHome/MainHome";
import FetchStates from "./components/fetchStates/FetchStates";
import Widget from "./components/widget/Widget";
import UnusedDomains from "./components/unusedDomains/UnusedDomains";
import Stock from "./components/stock/Stock";
import ErrorAlert from "./components/errorAlert/ErrorAlert";
import FBaccounts from "./components/fbAccounts/FBaccounts";
import FFPData from "./components/fbFanPages/FFPData";
import FFPLinksSetUp from "./components/fbFanPages/FFPLinksSetUp";
import FFPPostsCreation from "./components/fbFanPages/FFPPostsCreation";
import BestPerforming from "./components/bestPerforming/BestPerforming";
import CampaignCSV from "./components/campaignCSV/CampaignCSV";
import CreateCampaign from "./components/createCampaign/CreateCampaign";
import Settings from "./components/settings/Settings";

import useFetchBestPerforming from "./hooks/useFetchBestPerforming";

function App() {
  const [loading, setLoading] = useState(false);
  const [loginStatus, setLoginStatus] = useState(false);
  const [hideSidebar, setHideSidebar] = useState(false);
  const [role, setRole] = useState("widget");
  const [errorAlerts, setErrorAlerts] = useState({ display: false, errors: 0 });
  const [controlA, setControlA] = useState(true);
  const [sessionScreen, setSessionScreen] = useState({ loading: false, display: false, message: '', displayCount: false, count: 3 });
  const [rulesInfo, setRulesInfo] = useState({ loading: false, singleLoading: [], data: [], refresh: false, refresh_after_opt: false })
  const [bestPerform, setBestPerform] = useState({ loading: false, data: [], refresh: false })
  const [dialogInfo, setDialogInfo] = useState({ mode: "", type: "", message: [], confirm_function: () => {}, cancel_function: () => {} })  
  
  const new_version = "6.6.0"
  
  const fetch = useFetchBestPerforming({ loginStatus, bestPerform, setBestPerform })
  const user_permissions = Cookies.get('userPermissions') ? JSON.parse(Cookies.get('userPermissions')) : []

  const linkCondition = path => {
    let result = false

    if ((path === "") && (role === "admin")) {
      result = true
    } else if ((role === "admin") || user_permissions.find(f => f === path)) {
      result = true
    }

    return result
  }

  const handleSidebar = () => {
    setHideSidebar(!hideSidebar);
  };

  // on token expired; clean all the cookies, local storage and then force user to login page
  const forceLogout = () => {
    Object.keys(Cookies.get()).map(name => Cookies.remove(name))
    window.localStorage.removeItem("fbAccounts");
    setLoginStatus(false)
    setBestPerform(p => { return { ...p, loading: false, data: [] } })
  }

  // check login status after session out
  const checkLogin = async () => {
    setSessionScreen(p => { return { ...p, loading: true } })
    const token = Cookies.get("token");
    const url = `${host}/loginState`
    let intervalID = null

    await axios.post(url,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type, x-access-token",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
          "Authorization": "Bearer "+token,
          "Content-Type": "application/json",
        },
        version: new_version
      }
    )
    .then((response) => {
      if (response?.data?.data === true) {
        setSessionScreen(p => { return { ...p, loading: false, display: false, message: '', count: 3, displayCount: false } })
      } else if (response?.data?.message?.includes('refresh')) {
        setSessionScreen(p => { return { ...p, loading: false, message: 'Old version detected. Refreshing the site in,', displayCount: true } })
        intervalID = setInterval(() => {
          setSessionScreen(p => {
            if (p.count <= 1) {
              clearInterval(intervalID)
              Object.keys(Cookies.get()).map(name => Cookies.remove(name))
              window.localStorage.removeItem("fbAccounts");
              setLoginStatus(false);
              setRole("");
              window.location.reload()
            } else {
              return { ...p, count: p.count - 1 }
            }
          })
        }, 1000);
      }
    })
    .catch((error) => {
      console.log(error);
      Object.keys(Cookies.get()).map(name => Cookies.remove(name))
      window.localStorage.removeItem("fbAccounts");
      setLoginStatus(false);
      setRole("");
      setSessionScreen(p => { return { ...p, loading: false, display: false, message: '', count: 3, displayCount: false } })
    })
  };

  // session out after 15 minutes
  useEffect(() => {
    let timeoutID = null
    const events_to_check = ['mousemove', 'mousedown', 'keypress', 'touchmove', 'scroll', 'click']

    const resetTimer = () => {
      clearTimeout(timeoutID)
      timeoutID = setTimeout(() => {
        setSessionScreen(p => { return { ...p, display: true, count: 3, displayCount: false, message: '' } })
      }, 15*60*1000);
    }

    resetTimer()

    events_to_check.forEach((event) => {
      window.addEventListener(event, resetTimer)
    })
    
    return () => {
      events_to_check.forEach((event) => {
        window.removeEventListener(event, () => clearTimeout(timeoutID))
      })
    }
  }, [])
  
  // check login status on initial run and on FORCEFUL CHECK
  useEffect(() => {
    const controller = new AbortController();
    const token = Cookies.get("token");
    const role = Cookies.get("role");
    const url = `${host}/loginState`

    const checkLoginAndVersion = async () => {
      setLoading(true)

      await axios.post(url,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type, x-access-token",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
            "Authorization": "Bearer "+token,
            "Content-Type": "application/json",
          },
          version: new_version
        },
        {
          signal: controller.signal
        }
      )
      .then((response) => {
        console.log(response)
        const obj = response.data
        if (obj.data === true) {
          setLoginStatus(true);
          setRole(role ? role : '')
          Cookies.set("token", obj.token);
        } else if (response?.data?.message?.includes('refresh')) {
          Object.keys(Cookies.get()).map(name => Cookies.remove(name))
          window.localStorage.removeItem("fbAccounts");
          setLoginStatus(false);
          setRole("");
          if (window.confirm('Old version detected. Please refresh the page to use the latest version. Current version ' + new_version)) {
            window.location.reload()
          } else {
            window.location.reload()
          }
        } else {
          Object.keys(Cookies.get()).map(name => Cookies.remove(name))
          window.localStorage.removeItem("fbAccounts");
          setLoginStatus(false);
          setRole("");
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
        Object.keys(Cookies.get()).map(name => Cookies.remove(name))
        window.localStorage.removeItem("fbAccounts");
        setLoginStatus(false);
        setRole("");
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
    };

    if (controlA) {
      checkLoginAndVersion();
    }

    return () => {
      controller.abort()
      setLoading(false)
    }
  }, [controlA]);

  // error alert check
  useEffect(() => {
    const controller = new AbortController()
    const token = Cookies.get('token')
    const user = Cookies.get('userArray')
    const manager = Cookies.get('manager')
    const url = `${host}/api/unused_domains/error`;
    
    if (token === undefined || token === null || token === '') {
      setLoginStatus(false)
      return
    }

    // fetch error accounts to display on sidebar
    const getData = async () => {
      await axios.post(url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type, x-access-token",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
          "Authorization": "Bearer "+token,
          "Content-Type": "application/json",
        },
      },
      {
        signal: controller.signal
      })

      .then((response) => {
        const obj = response.data

        if (obj.result) {
          if (role === 'admin') {
            const ads = (Array.isArray(obj.result?.ads)) ? obj.result.ads.length : 0
            const account = (Array.isArray(obj.result?.account)) ? obj.result.account.length : 0
            setErrorAlerts({ display: true, errors: ads+account })
          } else if (role === 'widget') {
            const ads = (Array.isArray(obj.result?.ads)) ? obj.result.ads.filter(f => f.user === manager).length : 0
            const account = (Array.isArray(obj.result?.account)) ? obj.result.account.filter(f => f.user === manager).length : 0
            setErrorAlerts({ display: true, errors: ads+account })
          } else {
            const ads = (Array.isArray(obj.result?.ads)) ? obj.result.ads.filter(f => f.user === user).length : 0
            const account = (Array.isArray(obj.result?.account)) ? obj.result.account.filter(f => f.user === user).length : 0
            setErrorAlerts({ display: true, errors: ads+account })
          }
        } else {
          setErrorAlerts({ display: false, errors: 0 })
        }
      })
      
      .catch((error) => {
        console.log(error);
        setErrorAlerts({ display: false, errors: 0 })
      })
    }

    getData()

    return () => {
      controller.abort()
    }
  }, [loginStatus])
  
  // fetch AUTO OPTIMIZATION rules API
  useEffect(() => {
    const controller = new AbortController();
    const token = Cookies.get("token");
    const url = `${host}/api/data/rules_list`;

    // API to get rules
    const getRules = async () => {
      setRulesInfo(p => { return { ...p, loading: true } })
  
      await axios.post(url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type, x-access-token",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
          "Authorization": "Bearer "+token,
          "Content-Type": "application/json",
        },
        data: {
          traffic: 'all'
        }
      },
      {
        signal: controller.signal
      })
      .then((response) => {
        console.log(response);
        setRulesInfo(p => { return { ...p, loading: false, data: response.data, singleLoading: [] } })
      })
      .catch((error) => {
        console.log(error)
        setRulesInfo(p => { return { ...p, loading: false, singleLoading: [] } })
        if (error?.response?.data?.message === 'Invalid') {
          forceLogout()
        }
      })
    }
    
    getRules()

    return () => {
      controller.abort()
      setRulesInfo(p => { return { ...p, loading: false, singleLoading: [] } })
    }
  }, [rulesInfo.refresh, rulesInfo.refresh_after_opt])

  return (
    <Context.Provider value={{ role, forceLogout, rulesInfo, setRulesInfo, bestPerform, setBestPerform, setDialogInfo }}>
      <Router>
        {loading ? (
          <div style={{ height: '100vh', width: '100%', display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', gap: '20px', padding: '10px' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <div className="loader"/>
              <p>Checking Login Status</p>
              <div className="loader"/>
            </div>
            <div
              style={{ height: '50px', width: '100%', maxWidth: 'fit-content', background: '#1c1c1e', color: '#FFF', padding: '0px 25px', borderRadius: '50px', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', transition: '200ms', cursor: 'pointer' }}
              onMouseEnter={ e => e.currentTarget.style.opacity = '50%' }
              onMouseLeave={ e => e.currentTarget.style.opacity = '100%' }
              onClick={() => {
                setControlA(false)
                Object.keys(Cookies.get()).map(name => Cookies.remove(name))
                window.localStorage.removeItem("fbAccounts");
                setLoginStatus(false)
                setRole("");
                setLoading(false)
                setBestPerform(p => { return { ...p, loading: false, data: [] } })
              }}
            >
              Stuck in this page? Go to Login Page
            </div>
          </div>
        ) : (
          <>
            {/* display session page on top of everything */}
            {sessionScreen.display ? (
              <div style={{ position: 'fixed', zIndex: 1000, height: '100vh', width: '100%', background: '#FFF', display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', gap: '20px', padding: '10px' }}>
                <p style={{ fontSize: '24px', fontWeight: '600', textAlign: 'center' }}>Session Expired</p>
                {(sessionScreen.message !== '') ? (
                  <p style={{ textAlign: 'center' }}>{sessionScreen.message}</p>
                ) : (<></>)}
                {sessionScreen.displayCount ? (
                  <p style={{ fontSize: '24px', fontWeight: '600' }}>{sessionScreen.count}</p>
                ) : (
                  <div
                    style={{ height: '50px', width: '100%', maxWidth: 'fit-content', background: '#1c1c1e', color: '#FFF', padding: '0px 25px', borderRadius: '50px', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', transition: '200ms', cursor: 'pointer' }}
                    onMouseEnter={ e => e.currentTarget.style.opacity = '50%' }
                    onMouseLeave={ e => e.currentTarget.style.opacity = '100%' }
                    onClick={() => checkLogin()}
                  >
                    {sessionScreen.loading ? (
                      <div className="loader"/>
                    ) : (
                      'Continue to where you left'
                    )}
                  </div>
                )}
              </div>
            ) : (<></>)}

            <DialogBox dialogInfo={dialogInfo} setDialogInfo={setDialogInfo} />

            {loginStatus ? (
              <Fragment>
                <Topbar setLoginStatus={setLoginStatus} handleSidebar={handleSidebar} />
                <div className="container">
                  <span>
                    <Sidebar hideSidebar={hideSidebar} handleSidebar={handleSidebar} errorAlerts={errorAlerts} setErrorAlerts={setErrorAlerts}/>
                      <Routes>
                        <Route path="*" element={<PageNotFound/>}/>

                        {role === "widget" ? (
                          <Route exact path="/" element={<Widget/>}/>
                        ) : (
                          <>
                            <Route exact path="/" element={<MainHome/>}/>
                            <Route exact path="/widget" element={<Widget/>}/>
                          </>
                        )}

                        {linkCondition("") && <Route exact path="/settings" element={<Settings/>}/>}
                        {linkCondition("") && <Route exact path="/userPage" element={<UserPage/>}/>}

                        {linkCondition("best-performing") && <Route exact path="/bestPerforming" element={<BestPerforming/>}/>}
                        {linkCondition("topic-trends") && <Route exact path="/stock" element={<Stock/>}/>}
                        {linkCondition("facebook-accounts") && <Route exact path="/fbAccounts" element={<FBaccounts/>}/>}

                        {linkCondition("fb-fan-page-data") && <Route exact path="/ffpData" element={<FFPData/>}/>}
                        {linkCondition("fb-fan-page-links-set-up") && <Route exact path="/ffpLinksSetUp" element={<FFPLinksSetUp/>}/>}
                        {linkCondition("fb-fan-page-posts-creation") && <Route exact path="/ffpPostsCreation" element={<FFPPostsCreation/>}/>}

                        {linkCondition("error-alert") && <Route exact path="/errorAlert" element={<ErrorAlert/>}/>}
                        {linkCondition("states") && <Route exact path="/fetchStates" element={<FetchStates/>}/>}
                        {linkCondition("unused-domain") && <Route exact path="/unusedDomains" element={<UnusedDomains/>}/>}
                        {linkCondition("") && <Route exact path="/createCampaign" element={<CreateCampaign/>}/>}
                        {linkCondition("") && <Route exact path="/campaignCSV" element={<CampaignCSV/>}/>}
                        
                        {linkCondition("rotation-by-clients") && <Route exact path="/allFacebook" element={<AllFacebook/>}/>}
                        {linkCondition("rotation-by-countries") && <Route exact path="/allCountriesFacebook" element={<AllCountriesFacebook/>}/>}
                        {linkCondition("rotation-by-emailoctopus") && <Route exact path="/allEmailOctopus" element={<AllEmailOctopus/>}/>}
                        
                        {(linkCondition("tonic-facebook") || linkCondition("tonic-tiktok")) && <Route exact path="/tonicTotal" element={<TonicTotal/>}/>}
                        {linkCondition("tonic-facebook") && <Route exact path="/tonicFacebook" element={<TonicFacebook/>}/>}
                        {linkCondition("tonic-tiktok") && <Route exact path="/tonicTiktok" element={<TonicTiktok/>}/>}

                        {linkCondition("system1-facebook") && <Route exact path="/system1Total" element={<System1Total/>}/>}
                        {linkCondition("system1-facebook") && <Route exact path="/system1Facebook" element={<System1Facebook/>}/>}
                        
                        {(linkCondition("sedo-facebook") || linkCondition("sedo-tiktok") || linkCondition("sedo-domain")) && <Route exact path="/sedoTotal" element={<SedoTotal/>}/>}
                        {linkCondition("sedo-facebook") && <Route exact path="/sedoFacebook" element={<SedoFacebook/>}/>}
                        {linkCondition("sedo-tiktok") && <Route exact path="/sedoTiktok" element={<SedoTikTok/>}/>}
                        {linkCondition("sedo-domain") && <Route exact path="/sedoDomain" element={<SedoDomain/>}/>}

                        {linkCondition("crossroads-facebook") && <Route exact path="/crossroadsTotal" element={<CrossroadsTotal/>}/>}
                        {linkCondition("crossroads-facebook") && <Route exact path="/crossroadsFacebook" element={<CrossroadsFacebook/>}/>}

                        {linkCondition("ads-facebook") && <Route exact path="/adsTotal" element={<AdsTotal/>}/>}
                        {linkCondition("ads-facebook") && <Route exact path="/adsFacebook" element={<AdsFacebook/>}/>}
                      </Routes>
                  </span>
                </div>
              </Fragment>
            ) : (
              <Login setRole={setRole} setLoginStatus={setLoginStatus}/>
            )}
          </>
        )}
      </Router>
    </Context.Provider>
  );
}

export default App;
