import React from "react";
import Sum from "../reusable/sum/Sum";
import tonicLogo from '../../assets/tonicLogo.svg'

const TonicTotal = () => {
    return(
        <Sum clientName='tonic' logo={tonicLogo} minusDate={2} />
    )
}

export default TonicTotal