import React, { useState, useEffect, useContext } from "react";
import './fbFanPages.css'
import Cookies from "js-cookie";
import axios from "axios";
import moment from "moment";
import { FaSync } from "react-icons/fa";
import { FaPencil, FaTrashCan, FaCopy, FaXmark, FaEye, FaEyeSlash } from "react-icons/fa6";
import { MdOutlineCancel } from 'react-icons/md'
import { TbCalendarTime } from 'react-icons/tb'

import { host } from "../../hooks/host";
import { Context } from "../../AppContext";
import { country_code } from "../../local/FacebookCountries";
import noImage from "../../assets/noImage.png"
import useFetchFFPPostsCreation from "../../hooks/useFetchFFPPostsCreation";

const FFPPostsCreation = () => {
    const appContext = useContext(Context)

    const [global, setGlobal] = useState({ display_form: '', widget_case: 'Post Creation', expand_row: null })
    const [refresh, setRefresh] = useState({ fan_page: false, settings: false, widget: false })
    const [loading, setLoading] = useState({ fan_page: false, fan_page_revenue: false, settings: false, settings_create: false, settings_update: false, widget: false, widget_create: false, widget_update: false })

    const [settings, setSettings] = useState({ loading: false, data: [], display: false, refresh: false, topic: '', link: '', facebook_page_id: '', facebook_page_name: '', link_type: 'Post', client: '', client_id: '' })

    const [bulk, setBulk] = useState([])
    const [postReschedule, setPostReschedule] = useState({ data: {}, display: false, complete_date: moment().add(1, 'day').format('YYYY-MM-DDTHH:mm'), repeat: '1', days: '1', message: "", comment: "", result: {} })

    const [addSelectDisplay, setAddSelectDisplay] = useState('')
    const [postScheduleLoop, setPostScheduleLoop] = useState({ repeat: '1', days: '1', result: {} })
    const [selectedRow, setSelectedRow] = useState({ widget: null, keyword: null, final_url: null, note: null, emailoctopus_list: null, emailoctopus_campaign: null, url: null, country: null, message: null, picture: null })
    const [addDetail, setAddDetail] = useState({ country: '', topic: '', fb_id: '', client: '', url: '', note: '', emailoctopus_list_id: [], emailoctopus_campaign_id: [], complete_date: moment().format('YYYY-MM-DDTHH:mm'), categories: [], daily_budget: '1', headline: [""], picture: [""], ad_media: [{}], message: [""], comment: "", adset_count: '1', facebook_fan_page: '', instagram_account: '', case: 'Post Creation' })
    const [updateLoading, setUpdateLoading] = useState(false)
    const [updateDetail, setUpdateDetail] = useState({ widget: Number, country: '', topic: '', fb_id: '', client: '', url: '', keyword: [], final_url: '',  note: '', emailoctopus_list_id: [], emailoctopus_campaign_id: [], complete_date: moment().format('YYYY-MM-DDTHH:mm'), categories: [], daily_budget: '1', headline: [""], picture: [""], ad_media: [{}], message: [""], adset_count: '1', facebook_fan_page: '', instagram_account: '', case: '' })
    const [uploadFbImage, setUploadFbImage] = useState({ loading: false, act_id: '', refresh: false })
    const [fbMediaState, setFbMediaState] = useState({ display: false, mode: '', act_id: '', widget: 0 })
    const [displayAddAll, setDisplayAddAll] = useState({ client: false, country: false })
    const [addAllUrl, setAddAllUrl] = useState({
        isFixed: false,
        dynamic_clients_with_url: [],
        error: false,
        finalUrl: '',
        clients_percent: [],
        clients_with_url: [
            { client: 'Adcombo', id: '', url: '', index: 0 },
            { client: 'Ads', id: '', url: '', index: 1 },
            { client: 'Crossroads', id: '', url: '', index: 2 },
            { client: 'Sedo', id: '', url: '', index: 3 },
            { client: 'System1', id: '', url: '', index: 4 },
            { client: 'Tonic', id: '', url: '', index: 5 },
        ], 
        action: {
            Adcombo: {state: false, ['%']: '0'},
            Ads: {state: false, ['%']: '0'},
            Crossroads: {state: false, ['%']: '0'},
            Sedo: {state: false, ['%']: '0'},
            System1: {state: false, ['%']: '0'},
            Tonic: {state: false, ['%']: '0'}
        }
    })
    const [addAllCountryUrl, setAddAllCountryUrl] = useState({ displayEO: false, displayFC: false, filteredCountry: country_code, country: [], url: {}, id: {}, domain: {}, mode: 'Country', region: [], region_url: {}, region_id: {}, finalUrl: '' })
    
    const [displayUpdateAll, setDisplayUpdateAll] = useState({ client: false, country: false })
    const [updateAllUrl, setUpdateAllUrl] = useState({
        error: false,
        finalUrl: '',
        clients_percent: [],
        clients_with_url: [
            { client: 'Adcombo', id: '', url: '', index: 0 },
            { client: 'Ads', id: '', url: '', index: 1 },
            { client: 'Crossroads', id: '', url: '', index: 2 },
            { client: 'Sedo', id: '', url: '', index: 3 },
            { client: 'System1', id: '', url: '', index: 4 },
            { client: 'Tonic', id: '', url: '', index: 5 },
        ], 
        action: {
            Adcombo: {state: false, ['%']: '0'},
            Ads: {state: false, ['%']: '0'},
            Crossroads: {state: false, ['%']: '0'},
            Sedo: {state: false, ['%']: '0'},
            System1: {state: false, ['%']: '0'},
            Tonic: {state: false, ['%']: '0'}
        }
    })
    const [updateAllCountryUrl, setUpdateAllCountryUrl] = useState({ displayFC: false, filteredCountry: country_code, country: [], url: {}, id: {}, mode: 'Country', region: [], region_url: {}, region_id: {}, finalUrl: '' })

    const fetch = useFetchFFPPostsCreation({ global, refresh, setLoading, uploadFbImage })
    const [data] = fetch

    // fetch settings
    useEffect(() => {
        const fetchSettings = async () => {
            setSettings(p => { return { ...p, loading: true } })
    
            const response = await settingsCRUDapi({
                action: "read",
                rules: {},
                filter: {},
                update: {}
            })
    
            if (response.result && Array.isArray(response.result)) {
                let temp_settings = response.result.map((e, i) => { return { ...e, id: 101+i } })
                setSettings(p => { return { ...p, data: temp_settings, loading: false } })
            } else {
                setSettings(p => { return { ...p, data: [], loading: false } })
            }
        }

        fetchSettings()
    }, [settings.refresh])
    
    // set states when addDetail.country value changes
    useEffect(() => {
        if (global.widget_case === 'Country') {
            let lower_case_country = addDetail.country.trim().toLowerCase()
            let filter_country = (lower_case_country === '') ? country_code : country_code.filter(f => (f.name.common.toLowerCase().includes(lower_case_country) || f.name.official.toLowerCase().includes(lower_case_country) || f.cca2.toLowerCase().includes(lower_case_country) || f.ccn3.toLowerCase().includes(lower_case_country) || f.cca3.toLowerCase().includes(lower_case_country) || f.cioc.toLowerCase().includes(lower_case_country)))
            setAddAllCountryUrl(p => { return { ...p, displayFC: true, filteredCountry: filter_country } })
        }
    }, [addDetail.country])

    // add country's 'cca2' inside urls to display as input fields when countries are added or removed in ADD Phase
    useEffect(() => {
        if (global.widget_case === 'Country' && addAllCountryUrl.mode === 'Country') {
            let temp_url_id = {}
            let temp_domain = {}
            addAllCountryUrl.country.forEach(e => {
                temp_url_id[e] = ''
                temp_domain[e] = false
            });

            setAddAllCountryUrl(p => { return { ...p, url: temp_url_id, id: temp_url_id, domain: temp_domain } }) 
        }
    }, [addAllCountryUrl.country])

    // add region inside urls to display as input fields when regions are added or removed in ADD Phase
    useEffect(() => {
        if (global.widget_case === 'Country' && addAllCountryUrl.mode === 'Region') {
            let temp_url_id = {}
            addAllCountryUrl.region.forEach(e => {
                temp_url_id[e] = ''
            });
            setAddAllCountryUrl(p => { return { ...p, region_url: temp_url_id, region_id: temp_url_id } }) 
        }
    }, [addAllCountryUrl.region])

    // set states when updateDetail.country value changes
    useEffect(() => {
        if (global.widget_case === 'Country') {
            let lower_case_country = updateDetail.country.trim().toLowerCase()
            let filter_country = (lower_case_country === '') ? country_code : country_code.filter(f => (f.name.common.toLowerCase().includes(lower_case_country) || f.name.official.toLowerCase().includes(lower_case_country) || f.cca2.toLowerCase().includes(lower_case_country) || f.ccn3.toLowerCase().includes(lower_case_country) || f.cca3.toLowerCase().includes(lower_case_country) || f.cioc.toLowerCase().includes(lower_case_country)))
            
            setUpdateAllCountryUrl(p => { return { ...p, displayFC: true, filteredCountry: filter_country } }) 
        }
    }, [updateDetail.country])

    // add country's 'cca2' inside urls to display as input fields when countries are added or removed in UPDATE Phase
    useEffect(() => {
        if (global.widget_case === 'Country' && updateAllCountryUrl.mode === 'Country') {
            let temp_url = {}
            let temp_id = {}
            updateAllCountryUrl.country.forEach(e => {
                temp_url[e] = updateAllCountryUrl.url[e] ? updateAllCountryUrl.url[e] : ''
                temp_id[e] = updateAllCountryUrl.id[e] ? updateAllCountryUrl.id[e] : ''
            });
            setUpdateAllCountryUrl(p => { return { ...p, url: temp_url, id: temp_id } }) 
        }
    }, [updateAllCountryUrl.country])

    // add region inside urls to display as input fields when regions are added or removed in UPDATE Phase
    useEffect(() => {
        if (global.widget_case === 'Country' && updateAllCountryUrl.mode === 'Region') {
            let temp_url = {}
            let temp_id = {}
            updateAllCountryUrl.region.forEach(e => {
                temp_url[e] = updateAllCountryUrl.region_url[e] ? updateAllCountryUrl.region_url[e] : ''
                temp_id[e] = updateAllCountryUrl.region_id[e] ? updateAllCountryUrl.region_id[e] : ''
            });
            setUpdateAllCountryUrl(p => { return { ...p, region_url: temp_url, region_id: temp_id } }) 
        }
    }, [updateAllCountryUrl.region])

    // api to create a brand new campaign
    const newCampaignAPI = async ({ cond, result }) => {
        setUpdateLoading(true)
        const token = Cookies.get("token");
        const manager = Cookies.get("manager");
        const url = `${host}/api/index/create`;

        let tempClient = { Sedo: '', System1: '', Tonic: '', Crossroads: '', Adcombo: '', Ads: '' }

        let action = {
            Adcombo: {state: false, ['%']: 0},
            Ads: {state: false, ['%']: 0},
            Crossroads: {state: false, ['%']: 0},
            Sedo: {state: false, ['%']: 0},
            System1: {state: false, ['%']: 0},
            Tonic: {state: false, ['%']: 0}
        }

        const tempUrl = addAllUrl.clients_with_url.map(e => { return { client: e?.client, url: e?.url?.replace(/\s/g, '') } })

        const tempUrlFilter = tempUrl.filter(f => f.url !== '').map(e => e.client)

        tempUrlFilter.forEach(e => {
            action[e] = {state: true, ['%']: (100/tempUrlFilter.length)}
        });

        let urlToSend = ''
        let countryArrayRegion = []
        
        let all_clients_action = {...addAllUrl.action}
        Object.keys(all_clients_action).forEach(e => {
            all_clients_action[e]["%"] = (all_clients_action[e]["%"] === '') ? 0 : Number(all_clients_action[e]["%"])
        });

        if (cond === 'All' || cond === 'Country') {
            if (cond === 'All') {
                let temp_url_object = {}
                let temp_id_object = {}

                if (addAllUrl.isFixed) {
                    addAllUrl.clients_with_url.map(e => {
                        temp_url_object[e?.client] = e?.url?.replace(/\s/g, '')
                        temp_id_object[e?.client] = e?.id?.replace(/\s/g, '')
                    })
                } else {
                    addAllUrl.clients_with_url.map(e => {
                        if (e.index < 6) {
                            temp_url_object[e?.client] = addAllUrl.dynamic_clients_with_url.find(f => f?.client === e?.client) ? addAllUrl.dynamic_clients_with_url.find(f => f?.client === e?.client)?.url?.replace(/\s/g, '') : ''
                            temp_id_object[e?.client] = addAllUrl.dynamic_clients_with_url.find(f => f?.client === e?.client) ? addAllUrl.dynamic_clients_with_url.find(f => f?.client === e?.client)?.id?.replace(/\s/g, '') : ''
                        } else {
                            temp_url_object[e?.client] = e?.url?.replace(/\s/g, '')
                            temp_id_object[e?.client] = e?.id?.replace(/\s/g, '')
                        }
                    })
                }
            
                urlToSend = {
                    url: temp_url_object,
                    id: temp_id_object,
                    action: addAllUrl.action
                }
            }
            if (cond === 'Country' && addAllCountryUrl.mode === 'Country') {
                urlToSend = {
                    url: addAllCountryUrl.url,
                    id: {...tempClient, [addDetail.client]: addAllCountryUrl.id},
                    action: action
                }
            }
            if (cond === 'Country' && addAllCountryUrl.mode === 'Region') {
                let extracted_countries_url = {}
                let extracted_countries_id = {}
                addAllCountryUrl.region.map(e => {
                    country_code.filter(f => f.region === e).map(reg => {
                        extracted_countries_url[reg.cca2] = addAllCountryUrl.region_url[e]
                        extracted_countries_id[reg.cca2] = addAllCountryUrl.region_id[e]
                    })
                    if (e === 'OTHER') {
                        extracted_countries_url.OTHER = addAllCountryUrl.region_url.OTHER
                        extracted_countries_id.OTHER = addAllCountryUrl.region_id.OTHER
                    }
                })

                urlToSend = {
                    url: extracted_countries_url,
                    id: {...tempClient, [addDetail.client]: extracted_countries_id},
                    action: action
                }

                countryArrayRegion = Object.keys(extracted_countries_url)
            }
        } else {
            urlToSend = addDetail?.url?.replace(/\s/g, '')
        }

        let dataToSend = {}
        
        if (global.widget_case === 'Country') {
            dataToSend = {
                finished: false,
                topic: addDetail.topic,
                client: addDetail.client,
                country: (addAllCountryUrl.mode === 'Country') ? addAllCountryUrl.country : countryArrayRegion,
                country_mode: addAllCountryUrl.mode,
                region: addAllCountryUrl.region,
                region_url: { url: addAllCountryUrl.region_url, id: addAllCountryUrl.region_id },
                message: addDetail.message,
                ad_media: addDetail.ad_media,
                picture: addDetail.picture,
                facebook_fan_page: addDetail.facebook_fan_page,
                complete_date: addDetail.complete_date,
                url: urlToSend,
                final_url: addAllCountryUrl.finalUrl,
                case: 'Country',
                username: 'Southpow'
            }
        } else if (global.widget_case === 'All') {
            dataToSend = {
                finished: false,
                topic: addDetail.topic,
                country: addDetail.country,
                message: addDetail.message,
                ad_media: addDetail.ad_media,
                picture: addDetail.picture,
                facebook_fan_page: addDetail.facebook_fan_page,
                complete_date: addDetail.complete_date,
                url: urlToSend,
                final_url: addAllUrl.finalUrl,
                case: 'All',
                username: 'Southpow'
            }
        } else if (global.widget_case === 'Post Creation') {
            const find_topic = settings.data.find(f => (f.facebook_page_id === addDetail.facebook_fan_page) && (f.link_type === 'Post') && (f.link === addDetail.topic))
            if (!find_topic) {
                window.alert('Can not find the topic link! Please try again. If the problem persist, contact the developers.')
                return
            }
            dataToSend = {
                finished: true,
                topic: find_topic?.topic ? find_topic?.topic : '',
                country: addDetail.country,
                message: addDetail.message,
                comment: addDetail.comment,
                ad_media: addDetail.ad_media,
                picture: addDetail.picture,
                facebook_fan_page: addDetail.facebook_fan_page,
                complete_date: addDetail.complete_date,
                url: addDetail.url,
                final_url: find_topic?.link ? find_topic?.link : '',
                result: result,
                case: 'Post Creation',
                username: 'Southpow',
                client: addDetail.client
            }
        }

        await axios.post(url, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "Content-Type, x-access-token",
              "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
              "Authorization": "Bearer "+token,
              "Content-Type": "application/json",
            },
            data: dataToSend
        })
        .then((response) => {
            console.log(response)
            setRefresh(p => { return { ...p, widget: !p.widget } })
            setDisplayAddAll({ client: false, country: false });
            setPostScheduleLoop(p => { return { ...p, days: '1', repeat: '1', result: {} } })
            setAddDetail(p => { return { ...p, country: '', topic: '', fb_id: '', client: '', url: '', note: '', emailoctopus_list_id: [], emailoctopus_campaign_id: [], complete_date: moment().format('YYYY-MM-DDTHH:mm'), categories: [], daily_budget: '1', headline: [""], picture: [""], ad_media: [{}], message: [""], comment: "", adset_count: '1', facebook_fan_page: '', instagram_account: '' } })
            setAddAllUrl({
                isFixed: false,
                dynamic_clients_with_url: [],
                error: false,
                finalUrl: '',
                clients_percent: [],
                clients_with_url: [
                    { client: 'Adcombo', id: '', url: '', index: 0 },
                    { client: 'Ads', id: '', url: '', index: 1 },
                    { client: 'Crossroads', id: '', url: '', index: 2 },
                    { client: 'Sedo', id: '', url: '', index: 3 },
                    { client: 'System1', id: '', url: '', index: 4 },
                    { client: 'Tonic', id: '', url: '', index: 5 },
                ], 
                action: {
                    Adcombo: {state: false, ['%']: '0'},
                    Ads: {state: false, ['%']: '0'},
                    Crossroads: {state: false, ['%']: '0'},
                    Sedo: {state: false, ['%']: '0'},
                    System1: {state: false, ['%']: '0'},
                    Tonic: {state: false, ['%']: '0'}
                }
            })
            setAddAllCountryUrl({ displayEO: false, displayFC: false, filteredCountry: country_code, country: [], url: {}, id: {}, domain: {}, mode: 'Country', region: [], region_url: {}, region_id: {}, finalUrl: '' })
            setAddSelectDisplay('')
            setUpdateLoading(false)
        })
        .catch((error) => {
            appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Create failed", content: error }], confirm_function: () => {}, cancel_function: () => {} }})
            setDisplayAddAll({ client: false, country: false });
            setPostScheduleLoop(p => { return { ...p, days: '1', repeat: '1', result: {} } })
            setAddDetail(p => { return { ...p, country: '', topic: '', fb_id: '', client: '', url: '', note: '', emailoctopus_list_id: [], emailoctopus_campaign_id: [], complete_date: moment().format('YYYY-MM-DDTHH:mm'), categories: [], daily_budget: '1', headline: [""], picture: [""], ad_media: [{}], message: [""], comment: "", adset_count: '1', facebook_fan_page: '', instagram_account: '' } })
            setAddAllUrl({
                isFixed: false,
                dynamic_clients_with_url: [],
                error: false,
                finalUrl: '',
                clients_percent: [],
                clients_with_url: [
                    { client: 'Adcombo', id: '', url: '', index: 0 },
                    { client: 'Ads', id: '', url: '', index: 1 },
                    { client: 'Crossroads', id: '', url: '', index: 2 },
                    { client: 'Sedo', id: '', url: '', index: 3 },
                    { client: 'System1', id: '', url: '', index: 4 },
                    { client: 'Tonic', id: '', url: '', index: 5 },
                ], 
                action: {
                    Adcombo: {state: false, ['%']: '0'},
                    Ads: {state: false, ['%']: '0'},
                    Crossroads: {state: false, ['%']: '0'},
                    Sedo: {state: false, ['%']: '0'},
                    System1: {state: false, ['%']: '0'},
                    Tonic: {state: false, ['%']: '0'}
                }
            })
            setAddAllCountryUrl({ displayEO: false, displayFC: false, filteredCountry: country_code, country: [], url: {}, id: {}, domain: {}, mode: 'Country', region: [], region_url: {}, region_id: {}, finalUrl: '' })
            setAddSelectDisplay('')
            setUpdateLoading(false)
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        })
    }

    // api to update campaign - used for changing state for delete purpose & for overall update
    const updateCampaignAPI = (detail) => {
        setUpdateLoading(true)
        const token = Cookies.get("token");
        const manager = Cookies.get("manager");
        const url = `${host}/api/index/update`;

        axios.post(url,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "Content-Type, x-access-token",
              "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
              "Authorization": "Bearer "+token,
              "Content-Type": "application/json",
            },
            data: { ...detail, username: 'Southpow' }
          }
        )
        .then((response) => {
            setUpdateDetail({ widget: Number, country: '', topic: '', fb_id: '', client: '', url: '', keyword: [], final_url: '', note: '', emailoctopus_list_id: [], emailoctopus_campaign_id: [], complete_date: moment().format('YYYY-MM-DDTHH:mm'), categories: [], daily_budget: '1', headline: [""], picture: [""], ad_media: [{}], message: [""], adset_count: '1', facebook_fan_page: '', instagram_account: '', case: '' })
            setSelectedRow(p => { return { ...p, widget: null, keyword: null, final_url: null, note: null, emailoctopus_list: null, emailoctopus_campaign: null, url: null, country: null, message: null, picture: null }})
            setDisplayUpdateAll({ client: false, country: false })
            setUpdateAllUrl({
                error: false,
                finalUrl: '',
                clients_percent: [],
                clients_with_url: [
                    { client: 'Adcombo', id: '', url: '', index: 0 },
                    { client: 'Ads', id: '', url: '', index: 1 },
                    { client: 'Crossroads', id: '', url: '', index: 2 },
                    { client: 'Sedo', id: '', url: '', index: 3 },
                    { client: 'System1', id: '', url: '', index: 4 },
                    { client: 'Tonic', id: '', url: '', index: 5 },
                ], 
                action: {
                    Adcombo: {state: false, ['%']: '0'},
                    Ads: {state: false, ['%']: '0'},
                    Crossroads: {state: false, ['%']: '0'},
                    Sedo: {state: false, ['%']: '0'},
                    System1: {state: false, ['%']: '0'},
                    Tonic: {state: false, ['%']: '0'}
                }
            })
            setPostScheduleLoop(p => { return { ...p, days: '1', repeat: '1', result: {} } })
            setPostReschedule(p => { return { ...p, data: {}, display: false, complete_date: moment().add(1, 'day').format('YYYY-MM-DDTHH:mm'), repeat: '1', days: '1', message: "", comment: "", result: {} }})
            setAddDetail(p => { return { ...p, country: '', topic: '', fb_id: '', client: '', url: '', note: '', emailoctopus_list_id: [], emailoctopus_campaign_id: [], complete_date: moment().format('YYYY-MM-DDTHH:mm'), categories: [], daily_budget: '1', headline: [""], picture: [""], ad_media: [{}], message: [""], comment: "", adset_count: '1', facebook_fan_page: '', instagram_account: '' } })
            setRefresh(p => { return { ...p, widget: !p.widget } })
            setUpdateLoading(false)
        })
        .catch((error) => {
            setUpdateDetail({ widget: Number, country: '', topic: '', fb_id: '', client: '', url: '', keyword: [], final_url: '', note: '', emailoctopus_list_id: [], emailoctopus_campaign_id: [], complete_date: moment().format('YYYY-MM-DDTHH:mm'), categories: [], daily_budget: '1', headline: [""], picture: [""], ad_media: [{}], message: [""], adset_count: '1', facebook_fan_page: '', instagram_account: '', case: '' })
            setDisplayUpdateAll({ client: false, country: false })
            setUpdateLoading(false)
            setUpdateAllUrl({
                error: false,
                finalUrl: '',
                clients_percent: [],
                clients_with_url: [
                    { client: 'Adcombo', id: '', url: '', index: 0 },
                    { client: 'Ads', id: '', url: '', index: 1 },
                    { client: 'Crossroads', id: '', url: '', index: 2 },
                    { client: 'Sedo', id: '', url: '', index: 3 },
                    { client: 'System1', id: '', url: '', index: 4 },
                    { client: 'Tonic', id: '', url: '', index: 5 },
                ], 
                action: {
                    Adcombo: {state: false, ['%']: '0'},
                    Ads: {state: false, ['%']: '0'},
                    Crossroads: {state: false, ['%']: '0'},
                    Sedo: {state: false, ['%']: '0'},
                    System1: {state: false, ['%']: '0'},
                    Tonic: {state: false, ['%']: '0'}
                }
            })
            setPostScheduleLoop(p => { return { ...p, days: '1', repeat: '1', result: {} } })
            setPostReschedule(p => { return { ...p, data: {}, display: false, complete_date: moment().add(1, 'day').format('YYYY-MM-DDTHH:mm'), repeat: '1', days: '1', message: "", comment: "", result: {} }})
            setAddDetail(p => { return { ...p, country: '', topic: '', fb_id: '', client: '', url: '', note: '', emailoctopus_list_id: [], emailoctopus_campaign_id: [], complete_date: moment().format('YYYY-MM-DDTHH:mm'), categories: [], daily_budget: '1', headline: [""], picture: [""], ad_media: [{}], message: [""], comment: "", adset_count: '1', facebook_fan_page: '', instagram_account: '' } })
            appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Update failed", content: error }], confirm_function: () => {}, cancel_function: () => {} }})
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        })
    }

    // updates finished value
    const handleChangeSwitch = ({ fd, finished, result }) => {
        setUpdateLoading(true)
        const token = Cookies.get("token");
        const url = `${host}/api/index/update`;

        let detail = {
            ...fd,
            fb_id: data?.fb?.find(f => f?.unique === fd.fb_id)?.id,
            finished: finished,
            result: result
        }
        delete detail?.adset_count

        axios.post(url,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                    "Authorization": "Bearer "+token,
                    "Content-Type": "application/json",
                },
                data: detail
            }
        )
        .then((response) => {
            console.log(response)
            console.log(detail)

            if (response.data.success) {
                setRefresh(p => { return { ...p, widget: !p.widget } })
            } else {
                appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Switch failed", content: "Please try again or do a page refresh before trying again." }], confirm_function: () => {}, cancel_function: () => {} }})
            }
            setUpdateLoading(false)
        })
        .catch((error) => {
            setUpdateLoading(false)
            console.log(error)
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        })
    }

    // on row edit enable
    const handleSelectRowEdit = ({e, detail}) => {
        e.preventDefault()
        const newSelect = { ...selectedRow };
        newSelect.widget = detail.widget;

        const row = { ...detail };
        row.country = (row?.case === 'Country') ? row.country.join(',') : row.country;
        row.case = row.case ? row.case : 'All'
        row.facebook_fan_page = row.facebook_fan_page ? row.facebook_fan_page : ''

        if (row.case === 'Post Creation') {
            row.message = row.message ? row.message : [""]
            row.ad_media = row.ad_media ? row.ad_media : ((row.picture && Array.isArray(row.picture) && row.picture.find(f => f)) ? [{type: 'image', url: row.picture[0]}] : [{}])
            row.picture = row.picture ? row.picture : [""]
        }
        
        if (detail.case === 'All') {
            const check_url = row?.url?.url
            const check_id = row?.url?.id
            let check_action = row?.url?.action
            let temp_clients_with_url = []
            let temp_clients_percent = []

            if (check_url && check_action && check_id) {
                Object.keys(check_action).forEach(e => {
                    check_action[e]["%"] = check_action[e]["%"].toString()
                });

                Object.keys(check_url).forEach(key => {
                    temp_clients_with_url.push({ client: key, id: check_id[key], url: check_url[key] })
                    if (check_url[key] !== '') {
                        temp_clients_percent.push(key)
                    }
                });
            }

            setUpdateAllUrl(p => { return { ...p,
                finalUrl: row?.final_url,
                clients_percent: temp_clients_percent,
                clients_with_url: temp_clients_with_url, 
                action: check_action
            }})
            setDisplayUpdateAll(p => { return { ...p, client: true } })
            setUpdateDetail(row)
        } else if (detail.case === 'Country') {
            if (detail?.country_mode === 'Region') {
                setDisplayUpdateAll(p => { return { ...p, country: true } })
                setUpdateAllCountryUrl({ displayFC: false, filteredCountry: country_code, country: detail?.country, url: detail?.url?.url, id: detail?.url?.id?.[row?.client], mode: 'Region', region: detail?.region, region_url: detail?.region_url?.url, region_id: detail?.region_url?.id, finalUrl: detail?.final_url })
                setUpdateDetail(row)
            } else {
                setDisplayUpdateAll(p => { return { ...p, country: true } })
                setUpdateAllCountryUrl({ displayFC: false, filteredCountry: country_code, country: detail?.country, url: detail?.url?.url, id: detail?.url?.id?.[row?.client], mode: 'Country', region: [], region_url: {}, region_id: {}, finalUrl: detail?.final_url })
                setUpdateDetail(row)
            }
        } else {
            setUpdateDetail(row)
            setSelectedRow(newSelect);
        }
    }

    // on every input in add form change
    const handleChangeAdd = (e) => {
        e.preventDefault()
        const fieldName = e.target.getAttribute("name");
        const fieldValue = e.target.value;
        
        const newFormData = { ...addDetail };

        if (newFormData.case === 'Post Creation') {
            if (newFormData.adset_count !== '1') {
                newFormData['adset_count'] = '1'
                newFormData['headline'] = [""]
                newFormData['message'] = [""]
                newFormData['picture'] = [""]
                newFormData['comment'] = ""
                newFormData['ad_media'] = [{}]
            }
            if (fieldName === 'topic') {
                const find_topic = settings.data.find(f => (f.facebook_page_id === newFormData.facebook_fan_page) && (f.link_type === 'Post') && (f.link === fieldValue))
                newFormData['client'] = find_topic?.client ? find_topic?.client : ''
                newFormData['url'] = find_topic?.client_id ? find_topic?.client_id : ''
            } else if (fieldName === 'facebook_fan_page') {
                newFormData['topic'] = ''
                newFormData['client'] = ''
                newFormData['url'] = ''
            }
        }

        if (fieldName === 'fb_id') {
            newFormData['fb_id'] = fieldValue;
            const finding_id = data?.fb?.find(f => f?.unique === fieldValue)
            if (newFormData.case === 'All') {
                newFormData['client'] = ['Sedo','Tonic','System1','Crossroads','Ads','Adcombo'];
            } else if (finding_id) {
                newFormData['client'] = finding_id?.client;
            }
        } else if (fieldName === 'headline') {
            newFormData['headline'] = Array(Number(newFormData['adset_count'])).fill(fieldValue)
        } else if (fieldName === 'message') {
            newFormData['message'] = Array(Number(newFormData['adset_count'])).fill(fieldValue)
        } else {
            newFormData[fieldName] = fieldValue;
        }
        
        setAddDetail(newFormData);
        setUpdateDetail({ widget: Number, country: '', topic: '', fb_id: '', client: '', url: '', keyword: [], final_url: '',  note: '', emailoctopus_list_id: [], emailoctopus_campaign_id: [], complete_date: moment().format('YYYY-MM-DDTHH:mm'), categories: [], daily_budget: '1', headline: [""], picture: [""], ad_media: [{}], message: [""], adset_count: '1', facebook_fan_page: '', instagram_account: '', case: '' })
        setSelectedRow(p => { return { ...p, widget: null } })
    }

    // on submitting add form
    const handleSubmitAdd = async (e) => {
        e.preventDefault()

        if (!addDetail.topic.trim() || !addDetail.facebook_fan_page.trim() || !addDetail.message?.[0]?.trim() || !addDetail.ad_media?.[0]?.url?.trim() || !addDetail.picture?.[0]?.trim() || !addDetail.complete_date.trim() || !addDetail.client.trim() || !addDetail.url?.trim()) {
            appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Empty fields", content: "Topic, Facebook Fan page, Ad text, Image, Schedule, Client and Client ID required." }], confirm_function: () => {}, cancel_function: () => {} }})
            return
        }
        if (moment(addDetail.complete_date).isBefore(moment().add(25, 'minutes'))) {
            appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Schedule is inappropriate", content: "To post, the schedule should be set at least 25 minutes advanced from now." }], confirm_function: () => {}, cancel_function: () => {} }})
            return
        }
        if ((Number(postScheduleLoop.repeat) > 30)) {
            appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Loop Repeat Error", content: "Loop can not be repeated more than 30 times." }], confirm_function: () => {}, cancel_function: () => {} }})
            return
        }
        if ((Number(postScheduleLoop.days) > 10)) {
            appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Loop Repeat Days Error", content: "Loop repeat days can not be set more than 10." }], confirm_function: () => {}, cancel_function: () => {} }})
            return
        }

        createPostAPI({ item: {...addDetail}, postScheduleLoop, fb_fan_pages_post: data.fb_fan_pages_post, setUpdateLoading, appContext, settings, addDetail, updateCampaignAPI })
    }

    // condition to check if the total action % is not more than 100
    const handleAddActionInput = ({ e, cli }) => {
        let temp_action = {...addAllUrl.action}
        const temp_clients = addAllUrl.clients_percent
        const temp_client_last = temp_clients[temp_clients.length - 1]

        temp_action[cli].state = true
        temp_action[cli]['%'] = e.target.value.trim()

        if (temp_clients.length > 1 && cli !== temp_client_last) {
            let sum_percent = 0
            temp_clients.filter(f => f !== temp_client_last).map(e => sum_percent = sum_percent + Number(temp_action[e]['%']))
            temp_action[temp_client_last].state = true
            temp_action[temp_client_last]['%'] = (100 - sum_percent).toString()
        }

        const sum = Object.values(temp_action).map(m => Number(m["%"])).reduce((a, b) => a + b, 0)

        if (sum !== 100  || temp_action[temp_client_last]['%'] < 0 || temp_action[temp_client_last]['%'] > 100) {
            setAddAllUrl(p => {
                return {
                    ...p,
                    error: true
                }
            })
        } else {
            setAddAllUrl(p => {
                return {
                    ...p,
                    error: false,
                    action: {
                        ...p.action,
                        [cli]: {
                            state: true, ['%']: e.target.value.trim()
                        }
                    }
                }
            })
        }
    }

    // on submitting add all form
    const handleSubmitAddAll = (e) => {
        e.preventDefault()

        if (addDetail.country.trim() === '' || addDetail.topic.trim() === '' || addDetail.facebook_fan_page.trim() === '' || addAllUrl.finalUrl.trim() === '') {
            appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Empty fields", content: "Country, Topic, Facebook Fan Page and Final URL required." }], confirm_function: () => {}, cancel_function: () => {} }})
            return
        }
        
        if (addAllUrl.clients_percent.length > 0) {
            let temp_action = {...addAllUrl.action}
            const temp_clients = addAllUrl.clients_percent
            const temp_client_last = temp_clients[temp_clients.length - 1]
            const sum = Object.values(temp_action).map(m => Number(m["%"])).reduce((a, b) => a + b, 0)
            if (sum !== 100 || temp_action[temp_client_last]['%'] < 0 || temp_action[temp_client_last]['%'] > 100) {
                appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Incorrect inputs", content: "The total % as a whole should be exactly 100. Value can not be negative or more than 100." }], confirm_function: () => {}, cancel_function: () => {} }})
                return
            }
        }

        newCampaignAPI({ cond: 'All', result: {} })
    }

    // on submitting add all country form
    const handleSubmitAddAllCountry = (e) => {
        e.preventDefault()

        if (addAllCountryUrl.mode === 'Country') {
            if (addAllCountryUrl.country.length === 0 || addAllCountryUrl.finalUrl.trim() === '' || addDetail.topic.trim() === '' || addDetail.facebook_fan_page.trim() === '' || addDetail.client.trim() === '') {
                appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Empty fields", content: "Country, Topic, Facebook Fan Page, Client and Final URL required." }], confirm_function: () => {}, cancel_function: () => {} }})
                return
            }
        } else {
            if (addAllCountryUrl.region.length === 0 || addAllCountryUrl.finalUrl.trim() === '' || addDetail.topic.trim() === '' || addDetail.facebook_fan_page.trim() === '' || addDetail.client.trim() === '') {
                appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Empty fields", content: "Country, Topic, Facebook Fan Page, Client and Final URL required." }], confirm_function: () => {}, cancel_function: () => {} }})
                return
            }
        }
        
        newCampaignAPI({ cond: 'Country', result: {} })
    }

    // handle every updateDetail input change
    const handleChangeUpdate = (e) => {
        e.preventDefault()
        const fieldName = e.target.getAttribute("name");
        const fieldValue = e.target.value;
        
        const newFormData = { ...updateDetail };

        if (newFormData.case === 'Post Creation') {
            if (newFormData.adset_count !== '1') {
                newFormData['message'] = [""]
                newFormData['picture'] = [""]
                newFormData['ad_media'] = [{}]
            }
        }
        
        if (fieldName === 'url') {
            newFormData['url'] = fieldValue?.replace(/\s/g, '');
        } else if (fieldName === 'case') {
            newFormData['case'] = fieldValue;
            if (newFormData.case === 'Post Creation') {
                newFormData['country'] = "";
                newFormData['message'] = [""]
                newFormData['ad_media'] = [{}]
                newFormData['picture'] = [""]
            }
        } else if (fieldName === 'message') {
            newFormData['message'] = Array(Number(newFormData['adset_count'])).fill(fieldValue)
        } else {
            newFormData[fieldName] = fieldValue;
        }
        
        setUpdateDetail(newFormData);
        setAddDetail(p => { return { ...p, country: '', topic: '', fb_id: '', client: '', url: '', note: '', emailoctopus_list_id: [], emailoctopus_campaign_id: [], complete_date: moment().format('YYYY-MM-DDTHH:mm'), categories: [], daily_budget: '1', headline: [""], picture: [""], ad_media: [{}], message: [""], comment: "", adset_count: '1', facebook_fan_page: '', instagram_account: '' } })
    }

    // condition to check if the total action % is not more than 100
    const handleUpdateActionInput = ({ e, cli }) => {
        let temp_action = {...updateAllUrl.action}
        const temp_clients = updateAllUrl.clients_percent
        const temp_client_last = temp_clients[temp_clients.length - 1]

        temp_action[cli].state = true
        temp_action[cli]['%'] = e.target.value.trim()

        if (temp_clients.length > 1 && cli !== temp_client_last) {
            let sum_percent = 0
            temp_clients.filter(f => f !== temp_client_last).map(e => sum_percent = sum_percent + Number(temp_action[e]['%']))
            temp_action[temp_client_last].state = true
            temp_action[temp_client_last]['%'] = (100 - sum_percent).toString()
        }

        const sum = Object.values(temp_action).map(m => Number(m["%"])).reduce((a, b) => a + b, 0)

        if (sum !== 100  || temp_action[temp_client_last]['%'] < 0 || temp_action[temp_client_last]['%'] > 100) {
            setUpdateAllUrl(p => {
                return {
                    ...p,
                    error: true
                }
            })
        } else {
            setUpdateAllUrl(p => {
                return {
                    ...p,
                    error: false,
                    action: {
                        ...p.action,
                        [cli]: {
                            state: true, ['%']: e.target.value.trim()
                        }
                    }
                }
            })
        }
    }

    // on submitting update all clients
    const handleSubmitUpdateAll = (e) => {
        e.preventDefault()

        if (updateDetail.country.trim() === '' || updateDetail.topic.trim() === ''  || updateDetail.facebook_fan_page.trim() === '' || updateAllUrl.finalUrl.trim() === '') {
            appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Empty fields", content: "Please provide Country, Topic and Final URL." }], confirm_function: () => {}, cancel_function: () => {} }})
            return
        }
        
        if (updateAllUrl.clients_percent.length > 0) {
            let temp_action = {...updateAllUrl.action}
            const temp_clients = updateAllUrl.clients_percent
            const temp_client_last = temp_clients[temp_clients.length - 1]
            const sum = Object.values(temp_action).map(m => Number(m["%"])).reduce((a, b) => a + b, 0)
            if (sum !== 100 || temp_action[temp_client_last]['%'] < 0 || temp_action[temp_client_last]['%'] > 100) {
                appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Incorrect inputs", content: "The total % as a whole should be exactly 100. Value can not be negative or more than 100." }], confirm_function: () => {}, cancel_function: () => {} }})
                return
            }
        }

        let temp_url_object = {}
        let temp_id_object = {}

        updateAllUrl.clients_with_url.map(e => {
            temp_url_object[e?.client] = e?.url?.replace(/\s/g, '')
            temp_id_object[e?.client] = e?.id?.replace(/\s/g, '')
        })
    
        let urlToSend = {
            url: temp_url_object,
            id: temp_id_object,
            action: updateAllUrl.action
        }
        
        let temp = { ...updateDetail }
        temp.country = temp.country ? temp.country.trim() : ''
        temp.topic = temp.topic ? temp.topic.trim() : ''
        temp.complete_date = temp.complete_date ? temp.complete_date : null
        temp.url = urlToSend
        temp.final_url = updateAllUrl.finalUrl

        updateCampaignAPI(temp)
    }
    
    // on submitting update all country
    const handleSubmitUpdateAllCountries = (e) => {
        e.preventDefault()

        if (updateAllCountryUrl.country.length === 0 || updateDetail.topic.trim() === '' || updateAllCountryUrl.finalUrl.trim() === '' || updateDetail.facebook_fan_page.trim() === '' || updateDetail.client.trim() === '') {
            appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Empty fields", content: "Country, Topic, Facebook Fan Page, Client and Final URL required." }], confirm_function: () => {}, cancel_function: () => {} }})
            return
        }

        let temp = { ...updateDetail }
        let tempClient = { Sedo: '', System1: '', Tonic: '', Crossroads: '', Adcombo: '', Ads: '' }
        let urlToSend = ''
        let countryArrayRegion = []

        if (updateAllCountryUrl.mode === 'Country') {
            urlToSend = {
                url: updateAllCountryUrl.url,
                id: {...tempClient, [temp.client]: updateAllCountryUrl.id},
                action: temp?.url?.action
            }
        }
        if (updateAllCountryUrl.mode === 'Region') {
            let extracted_countries_url = {}
            let extracted_countries_id = {}
            updateAllCountryUrl.region.map(e => {
                country_code.filter(f => f.region === e).map(reg => {
                    extracted_countries_url[reg.cca2] = updateAllCountryUrl.region_url[e]
                    extracted_countries_id[reg.cca2] = updateAllCountryUrl.region_id[e]
                })
                if (e === 'OTHER') {
                    extracted_countries_url.OTHER = updateAllCountryUrl.region_url.OTHER
                    extracted_countries_id.OTHER = updateAllCountryUrl.region_id.OTHER
                }
            })

            urlToSend = {
                url: extracted_countries_url,
                id: {...tempClient, [temp.client]: extracted_countries_id},
                action: temp?.url?.action
            }

            countryArrayRegion = Object.keys(extracted_countries_url)
        }
        
        // updates inside temp
        temp.country = (updateAllCountryUrl.mode === 'Country') ? updateAllCountryUrl.country : countryArrayRegion
        temp.topic = temp.topic ? temp.topic.trim() : ''
        temp.complete_date = temp.complete_date ? temp.complete_date : null
        temp.url = urlToSend
        temp.country_mode = updateAllCountryUrl.mode
        temp.region = (updateAllCountryUrl.mode === 'Country') ? [] : updateAllCountryUrl.region
        temp.region_url = (updateAllCountryUrl.mode === 'Country') ? { url: {}, id: {} } : { url: updateAllCountryUrl.region_url, id: updateAllCountryUrl.region_id }
        temp.final_url = updateAllCountryUrl.finalUrl

        updateCampaignAPI(temp)
    }

    // on submitting post reschedule form
    const handleSubmitPostReschedule = () => {
        if (moment(postReschedule.complete_date).isBefore(moment().add(25, 'minutes'))) {
            appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Schedule is inappropriate", content: "To post, the schedule should be set at least 25 minutes advanced from now." }], confirm_function: () => {}, cancel_function: () => {} }})
            return
        }
        if ((Number(postReschedule.repeat) > 30)) {
            appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Loop Repeat Error", content: "Loop can not be repeated more than 30 times." }], confirm_function: () => {}, cancel_function: () => {} }})
            return
        }
        if ((Number(postReschedule.days) > 10)) {
            appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Loop Repeat Days Error", content: "Loop repeat days can not be set more than 10." }], confirm_function: () => {}, cancel_function: () => {} }})
            return
        }
        if (!postReschedule.message.trim()) {
            appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Ad Text field required", content: "" }], confirm_function: () => {}, cancel_function: () => {} }})
            return
        }

        appContext.setDialogInfo({
            mode: "Confirm",
            type: "Confirm",
            message: [
                { type: "Confirm", title: "Confirm reschedule", content: "Confirm your rescheduling inputs are satisfactory for you before proceeding." },
            ],
            confirm_function: () => reschedulePostAPI({ postReschedule, fb_fan_pages_post: data.fb_fan_pages_post, setUpdateLoading, appContext, updateCampaignAPI }),
            cancel_function: () => {}
        })
    }

    return (
        <div className="widget-container" style={{ gridAutoFlow: 'row', gridAutoRows: 'min-content 1fr', position: 'relative' }}>
            {/* loader */}
            {(loading.fan_page || loading.fan_page_revenue || loading.settings || loading.widget || updateLoading || settings.loading) ? (
                <div className="ripple-logo-container" style={{ zIndex: 500 }}>
                    <div className="loader" style={{ width: '200px', height: '200px', border: '5px solid #0000FF', borderTop: '5px solid #E6E6FF' }}></div>
                </div>
            ) : (<></>)}

            {/* add all client form */}
            {displayAddAll.client ? (
                <div className="widget-all-form-container">
                    <div className='widget-all-form'>
                        <div className='widget-all-form-nav'>
                            <p style={{ fontSize: '24px', fontWeight: 600, color: '#36a2eb' }}>Add All Clients</p>
                            <MdOutlineCancel size={24} color='#FF0000' style={{ cursor: 'pointer' }}
                                onClick={() => {
                                        setDisplayAddAll(p => { return { ...p, client: false } });
                                        setAddDetail(p => { return { ...p, country: '', topic: '', fb_id: '', client: '', url: '', note: '', emailoctopus_list_id: [], emailoctopus_campaign_id: [], complete_date: moment().format('YYYY-MM-DDTHH:mm'), categories: [], daily_budget: '1', headline: [""], picture: [""], ad_media: [{}], message: [""], comment: "", adset_count: '1', facebook_fan_page: '', instagram_account: '' } })
                                        setAddAllUrl({
                                            isFixed: false,
                                            dynamic_clients_with_url: [],
                                            error: false,
                                            finalUrl: '',
                                            clients_percent: [],
                                            clients_with_url: [
                                                { client: 'Adcombo', id: '', url: '', index: 0 },
                                                { client: 'Ads', id: '', url: '', index: 1 },
                                                { client: 'Crossroads', id: '', url: '', index: 2 },
                                                { client: 'Sedo', id: '', url: '', index: 3 },
                                                { client: 'System1', id: '', url: '', index: 4 },
                                                { client: 'Tonic', id: '', url: '', index: 5 },
                                            ], 
                                            action: {
                                                Adcombo: {state: false, ['%']: '0'},
                                                Ads: {state: false, ['%']: '0'},
                                                Crossroads: {state: false, ['%']: '0'},
                                                Sedo: {state: false, ['%']: '0'},
                                                System1: {state: false, ['%']: '0'},
                                                Tonic: {state: false, ['%']: '0'}
                                            }
                                        })
                                    }
                                }
                            />
                        </div>

                        <label className='widget-all-form-label'>
                            Case:&nbsp;
                            <span style={{ color: '#000', fontWeight: '600' }}>{global.widget_case}</span>&nbsp;
                            <span
                                style={{ color: '#4caf50', fontWeight: '600', background: 'rgba(76,175,80,0.2)', border: '2px solid #4caf50', padding: '0px 5px', borderRadius: '5px', cursor: 'pointer' }}
                                onClick={() => {
                                    setAddAllUrl({
                                        isFixed: !addAllUrl.isFixed,
                                        dynamic_clients_with_url: [],
                                        error: false,
                                        finalUrl: '',
                                        clients_percent: [],
                                        clients_with_url: [
                                            { client: 'Adcombo', id: '', url: '', index: 0 },
                                            { client: 'Ads', id: '', url: '', index: 1 },
                                            { client: 'Crossroads', id: '', url: '', index: 2 },
                                            { client: 'Sedo', id: '', url: '', index: 3 },
                                            { client: 'System1', id: '', url: '', index: 4 },
                                            { client: 'Tonic', id: '', url: '', index: 5 },
                                        ], 
                                        action: {
                                            Adcombo: {state: false, ['%']: '0'},
                                            Ads: {state: false, ['%']: '0'},
                                            Crossroads: {state: false, ['%']: '0'},
                                            Sedo: {state: false, ['%']: '0'},
                                            System1: {state: false, ['%']: '0'},
                                            Tonic: {state: false, ['%']: '0'}
                                        }
                                    })
                                }}
                            >
                                <FaSync size={14} color='#4caf50'/>&nbsp;
                                {addAllUrl.isFixed ? 'Dynamic' : 'Fixed'}
                            </span>
                        </label>

                        <label className='widget-all-form-label'>Language:</label>
                        <input className='widget2-input' type="text" name='country' autoComplete="off" placeholder='language' value={addDetail.country} onChange={e => handleChangeAdd(e)} style={{ maxWidth: '100%' }} />
                        
                        <label className='widget-all-form-label'>Topic:</label>
                        <input className='widget2-input' type="text" name='topic' autoComplete="off" placeholder='topic' value={addDetail.topic} onChange={e => handleChangeAdd(e)} style={{ maxWidth: '100%' }}/>
                        
                        <label className='widget-all-form-label'>Facebook Fan Page:</label>
                        <select className='widget2-select' name='facebook_fan_page' value={addDetail.facebook_fan_page} onChange={e => handleChangeAdd(e)} style={{ maxWidth: '100%' }} onFocus={e => e.target.style.borderColor = '#000'} onBlur={e => e.target.style.borderColor = '#AAA'}>
                            <option value="">Choose fan page</option>
                            {data.fb_fan_pages_post.map((e, i) => (
                                <option value={e.id} key={i}>{e.name}</option>
                            ))}
                        </select>

                        {addAllUrl.isFixed ? (
                            <>
                                <div className='widget-all-form-dynamic-url'>
                                    {addAllUrl.clients_with_url.map(info => (
                                        <div key={info.index}>
                                            <div style={{ position: 'relative', width: '100%' }}>
                                                <label className='widget-all-form-label'>{info.client} ID:</label>
                                                <input className='widget2-input' type="text" placeholder={`${info.client} id`}  value={info.id} autoComplete='off' style={{ maxWidth: '100%' }}
                                                    onChange={e => {
                                                        setAddAllUrl(p => {
                                                            let temp = [...p.clients_with_url]
                                                            temp[info.index].id = e.target.value?.replace(/\s/g, '')
                                                            return {
                                                                ...p,
                                                                clients_with_url: temp,
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>

                                            <div style={{ position: 'relative', width: '100%' }}>
                                                <label className='widget-all-form-label'>{info.client} URL:</label>
                                                <select className='widget2-select' style={{ maxWidth: '100%' }} onFocus={e => e.target.style.borderColor = '#000'} onBlur={e => e.target.style.borderColor = '#AAA'}
                                                    value={info.url}
                                                    onChange={e => {
                                                        setAddAllUrl(p => {
                                                            let temp_url = [...p.clients_with_url]
                                                            let temp_percent = [...p.clients_percent]
                                                            let temp_action = {...p.action}
                                                            temp_url[info.index].url = e.target.value?.replace(/\s/g, '')

                                                            if (e.target.value?.replace(/\s/g, '') === '') {
                                                                temp_percent = temp_percent.filter(f => f !== info.client)
                                                                temp_action[info.client].state = false
                                                                temp_action[info.client]['%'] = '0'
                                                            } else {
                                                                if (!temp_percent.find(f => f === info.client)) {
                                                                    temp_percent.push(info.client)
                                                                }
                                                            }

                                                            return {
                                                                ...p,
                                                                clients_with_url: temp_url,
                                                                clients_percent: temp_percent,
                                                                action: temp_action
                                                            }
                                                        })
                                                    }}    
                                                >
                                                    <option value="">Choose Link</option>
                                                    {settings.data.filter(f => (f.facebook_page_id === addDetail.facebook_fan_page) && (f.link_type === 'Fixed')).map((e, i) => (
                                                        <option value={e.link} key={i}>{e.topic} {"(" + e?.link?.substring(0, 30) + "...)"}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div style={{ height: 'fit-content', width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                                    {(addAllUrl.clients_with_url.length > 6) ? (
                                        <button style={{ width: 'fit-content', height: '30px', padding: '0px 5px', background: 'rgba(255,0,0,0.1)', outline: 'none', border: '1px solid #F00', borderRadius: '5px', fontSize: '14px', color: '#F00', cursor: 'pointer' }}
                                            onClick={() => setAddAllUrl(p => {
                                                let temp_url = [...p.clients_with_url]
                                                let temp_percent = [...p.clients_percent]
                                                let temp_action = {...p.action}
                                                if (temp_url[temp_url.length - 1].index > 5) {
                                                    delete temp_action[temp_url[temp_url.length - 1].client]
                                                    temp_percent = temp_percent.filter(f => f !== temp_url[temp_url.length - 1].client)
                                                    temp_url.pop()
                                                }
                                                return {
                                                    ...p,
                                                    clients_with_url: temp_url,
                                                    clients_percent: temp_percent,
                                                    action: temp_action
                                                }
                                            })}
                                        >
                                            Remove Url -
                                        </button>
                                    ) : (<></>)}
                                    <button style={{ width: 'fit-content', height: '30px', padding: '0px 5px', background: 'rgba(76,175,80,0.1)', outline: 'none', border: '1px solid #4caf50', borderRadius: '5px', fontSize: '14px', color: '#4caf50', cursor: 'pointer' }}
                                        onClick={() => setAddAllUrl(p => {
                                            let temp_url = [...p.clients_with_url]
                                            let temp_action = {...p.action}
                                            temp_url.push({ client: `Other${temp_url.length-5}`, id: '', url: '', index: temp_url.length })
                                            temp_action[`Other${temp_url.length-6}`] = {state: false, ['%']: '0'}
                                            return {
                                                ...p,
                                                clients_with_url: temp_url,
                                                action: temp_action
                                            }
                                        })}
                                    >
                                        Add Url +
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                <label className='widget-all-form-label'>Add URL:</label>

                                <div className='widget-all-form-dynamic-url'>
                                    {addAllUrl.dynamic_clients_with_url.map(info => (
                                        <div key={info.index} style={{ gridTemplateColumns: '1fr 1fr 1fr' }}>
                                            <div style={{ position: 'relative', width: '100%' }}>
                                                <label className='widget-all-form-label'>{info.client} Client:</label>
                                                <select className='widget2-select' name='fb_id' value={info.client} style={{ maxWidth: '100%' }}
                                                    onChange={e => {
                                                        setAddAllUrl(p => {
                                                            let temp_url = [...p.dynamic_clients_with_url]
                                                            let temp_percent = [...p.clients_percent]
                                                            let temp_action = {...p.action}
                                                            
                                                            if (e.target.value.trim() === '') {
                                                                temp_percent = temp_percent.filter(f => f !== info.client)
                                                                temp_action[info.client].state = false
                                                                temp_action[info.client]['%'] = '0'
                                                                temp_url[info.index].id = ''
                                                                temp_url[info.index].url = ''
                                                            }

                                                            temp_url[info.index].client = e.target.value

                                                            return {
                                                                ...p,
                                                                dynamic_clients_with_url: temp_url,
                                                                clients_percent: temp_percent,
                                                                action: temp_action
                                                            }
                                                        })
                                                    }}
                                                >
                                                    <option value="">Select Client</option>
                                                    {(info.client !== '') ? <option value={info.client}>{info.client}</option> : <></>}
                                                    {['Adcombo', 'Ads', 'Crossroads', 'Sedo', 'System1', 'Tonic'].filter(f => !addAllUrl.dynamic_clients_with_url.find(fin => fin?.client === f)).map((e, ind) => (
                                                        <option value={e} key={ind}>{e}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div style={{ position: 'relative', width: '100%', opacity: !info.client ? '0.5' : '1', pointerEvents: !info.client ? 'none' : 'all' }}>
                                                <label className='widget-all-form-label'>{info.client} ID:</label>
                                                <input className='widget2-input' type="text" placeholder={`${info.client} id`}  value={info.id} autoComplete='off' style={{ maxWidth: '100%' }} disabled={!info.client ? true : false}
                                                    onChange={e => {
                                                        setAddAllUrl(p => {
                                                            let temp = [...p.dynamic_clients_with_url]
                                                            temp[info.index].id = e.target.value?.replace(/\s/g, '')
                                                            return {
                                                                ...p,
                                                                dynamic_clients_with_url: temp,
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>

                                            <div style={{ position: 'relative', width: '100%', opacity: !info.client ? '0.5' : '1', pointerEvents: !info.client ? 'none' : 'all' }}>
                                                <label className='widget-all-form-label'>{info.client} URL:</label>
                                                <select className='widget2-select' style={{ maxWidth: '100%' }} disabled={!info.client ? true : false} onFocus={e => e.target.style.borderColor = '#000'} onBlur={e => e.target.style.borderColor = '#AAA'}
                                                    value={info.url}
                                                    onChange={e => {
                                                        setAddAllUrl(p => {
                                                            let temp_url = [...p.dynamic_clients_with_url]
                                                            let temp_percent = [...p.clients_percent]
                                                            let temp_action = {...p.action}
                                                            temp_url[info.index].url = e.target.value?.replace(/\s/g, '')

                                                            if (e.target.value?.replace(/\s/g, '') === '') {
                                                                temp_percent = temp_percent.filter(f => f !== info.client)
                                                                temp_action[info.client].state = false
                                                                temp_action[info.client]['%'] = '0'
                                                            } else {
                                                                if (!temp_percent.find(f => f === info.client)) {
                                                                    temp_percent.push(info.client)
                                                                }
                                                            }

                                                            return {
                                                                ...p,
                                                                dynamic_clients_with_url: temp_url,
                                                                clients_percent: temp_percent,
                                                                action: temp_action
                                                            }
                                                        })
                                                    }}
                                                >
                                                    <option value="">Choose Link</option>
                                                    {settings.data.filter(f => (f.facebook_page_id === addDetail.facebook_fan_page) && (f.link_type === 'Fixed')).map((e, i) => (
                                                        <option value={e.link} key={i}>{e.topic} {"(" + e?.link?.substring(0, 30) + "...)"}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div style={{ height: 'fit-content', width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                                    {(addAllUrl.dynamic_clients_with_url.length > 0) ? (
                                        <button style={{ width: 'fit-content', height: '30px', padding: '0px 5px', background: 'rgba(255,0,0,0.1)', outline: 'none', border: '1px solid #F00', borderRadius: '5px', fontSize: '14px', color: '#F00', cursor: 'pointer' }}
                                            onClick={() => setAddAllUrl(p => {
                                                let temp_url = [...p.dynamic_clients_with_url]
                                                const temp_url_client_name = temp_url[temp_url.length-1].client
                                                let temp_percent = [...p.clients_percent]
                                                let temp_action = {...p.action}
                                                
                                                if (temp_url_client_name !== '') {
                                                    temp_percent = temp_percent.filter(f => f !== temp_url_client_name)
                                                    temp_action[temp_url_client_name].state = false
                                                    temp_action[temp_url_client_name]['%'] = '0'
                                                }

                                                temp_url.pop()

                                                return {
                                                    ...p,
                                                    dynamic_clients_with_url: temp_url,
                                                    clients_percent: temp_percent,
                                                    action: temp_action
                                                }
                                            })}
                                        >
                                            Remove Client URL -
                                        </button>
                                    ) : (<></>)}
                                    <button style={{ width: 'fit-content', height: '30px', padding: '0px 5px', background: 'rgba(76,175,80,0.1)', outline: 'none', border: '1px solid #4caf50', borderRadius: '5px', fontSize: '14px', color: '#4caf50', cursor: 'pointer' }}
                                        onClick={() => setAddAllUrl(p => {
                                            let temp_dynamic_url = [...p.dynamic_clients_with_url]
                                            if (temp_dynamic_url.length < 6) {
                                                temp_dynamic_url.push({ client: '', id: '', url: '', index: temp_dynamic_url.length })
                                            }
                                            return {
                                                ...p,
                                                dynamic_clients_with_url: temp_dynamic_url
                                            }
                                        })}
                                    >
                                        Add Client URL +
                                    </button>
                                </div>

                                {/* other url entry */}
                                <div className='widget-all-form-dynamic-url'>
                                    {addAllUrl.clients_with_url.slice(6).map(info => (
                                        <div key={info.index}>
                                            <div style={{ position: 'relative', width: '100%' }}>
                                                <label className='widget-all-form-label'>{info.client} ID:</label>
                                                <input className='widget2-input' type="text" placeholder={`${info.client} id`}  value={info.id} autoComplete="off" style={{ maxWidth: '100%' }}
                                                    onChange={e => {
                                                        setAddAllUrl(p => {
                                                            let temp = [...p.clients_with_url]
                                                            temp[info.index].id = e.target.value?.replace(/\s/g, '')
                                                            return {
                                                                ...p,
                                                                clients_with_url: temp,
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>

                                            <div style={{ position: 'relative', width: '100%' }}>
                                                <label className='widget-all-form-label'>{info.client} URL:</label>
                                                <select className='widget2-select' style={{ maxWidth: '100%' }} onFocus={e => e.target.style.borderColor = '#000'} onBlur={e => e.target.style.borderColor = '#AAA'}
                                                    value={info.url}
                                                    onChange={e => {
                                                        setAddAllUrl(p => {
                                                            let temp_url = [...p.clients_with_url]
                                                            let temp_percent = [...p.clients_percent]
                                                            let temp_action = {...p.action}
                                                            temp_url[info.index].url = e.target.value?.replace(/\s/g, '')

                                                            if (e.target.value?.replace(/\s/g, '') === '') {
                                                                temp_percent = temp_percent.filter(f => f !== info.client)
                                                                temp_action[info.client].state = false
                                                                temp_action[info.client]['%'] = '0'
                                                            } else {
                                                                if (!temp_percent.find(f => f === info.client)) {
                                                                    temp_percent.push(info.client)
                                                                }
                                                            }

                                                            return {
                                                                ...p,
                                                                clients_with_url: temp_url,
                                                                clients_percent: temp_percent,
                                                                action: temp_action
                                                            }
                                                        })
                                                    }}
                                                >
                                                    <option value="">Choose Link</option>
                                                    {settings.data.filter(f => (f.facebook_page_id === addDetail.facebook_fan_page) && (f.link_type === 'Fixed')).map((e, i) => (
                                                        <option value={e.link} key={i}>{e.topic} {"(" + e?.link?.substring(0, 30) + "...)"}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div style={{ height: 'fit-content', width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                                    {(addAllUrl.clients_with_url.length > 6) ? (
                                        <button style={{ width: 'fit-content', height: '30px', padding: '0px 5px', background: 'rgba(255,0,0,0.1)', outline: 'none', border: '1px solid #F00', borderRadius: '5px', fontSize: '14px', color: '#F00', cursor: 'pointer' }}
                                            onClick={() => setAddAllUrl(p => {
                                                let temp_url = [...p.clients_with_url]
                                                let temp_percent = [...p.clients_percent]
                                                let temp_action = {...p.action}
                                                if (temp_url[temp_url.length - 1].index > 5) {
                                                    delete temp_action[temp_url[temp_url.length - 1].client]
                                                    temp_percent = temp_percent.filter(f => f !== temp_url[temp_url.length - 1].client)
                                                    temp_url.pop()
                                                }
                                                return {
                                                    ...p,
                                                    clients_with_url: temp_url,
                                                    clients_percent: temp_percent,
                                                    action: temp_action
                                                }
                                            })}
                                        >
                                            Remove Other Url -
                                        </button>
                                    ) : (<></>)}
                                    <button style={{ width: 'fit-content', height: '30px', padding: '0px 5px', background: 'rgba(76,175,80,0.1)', outline: 'none', border: '1px solid #4caf50', borderRadius: '5px', fontSize: '14px', color: '#4caf50', cursor: 'pointer' }}
                                        onClick={() => setAddAllUrl(p => {
                                            let temp_url = [...p.clients_with_url]
                                            let temp_action = {...p.action}
                                            temp_url.push({ client: `Other${temp_url.length-5}`, id: '', url: '', index: temp_url.length })
                                            temp_action[`Other${temp_url.length-6}`] = {state: false, ['%']: '0'}
                                            return {
                                                ...p,
                                                clients_with_url: temp_url,
                                                action: temp_action
                                            }
                                        })}
                                    >
                                        Add Other Url +
                                    </button>
                                </div>
                            </>
                        )}

                        {addAllUrl.clients_percent.map((ele, i) => (
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }} key={i}>
                                <label className='widget-all-form-label' style={{ width: 'fit-content' }}>{ele} Action %:</label>
                                <input className='widget2-input' type="number" placeholder={ele+' %'} value={addAllUrl?.action?.[ele]?.['%']} onChange={e => handleAddActionInput({ e: e, cli: ele })} onWheel={e => { e.target.blur(); e.stopPropagation(); }} style={{ maxWidth: '100px', borderColor: addAllUrl.error ? '#F00' : '#CCC' }} />
                            </div>
                        ))}

                        <label className='widget-all-form-label'>Final URL:</label>
                        <select className='widget2-select' value={addAllUrl.finalUrl} onChange={e => setAddAllUrl(p => { return { ...p, finalUrl: e.target.value } })} style={{ maxWidth: '100%' }}>
                            <option value="">Choose Final URL</option>
                            <option value="https://esquiz.com/widget/">esquiz</option>
                            <option value="https://vehiclemambo.com/">vehiclemambo</option>
                        </select>

                        <button className='widget2-button' style={{ maxWidth: '100%' }} onClick={e => handleSubmitAddAll(e)}>SUBMIT</button>
                    </div>
                </div>
            ) : (<></>)}

            {/* update all client form */}
            {displayUpdateAll.client ? (
                <div className="widget-all-form-container">
                    <div className='widget-all-form'>
                        <div className='widget-all-form-nav'>
                            <p style={{ fontSize: '24px', fontWeight: 600, color: '#36a2eb' }}>Update All Clients</p>
                            <MdOutlineCancel size={24} color='#FF0000' style={{ cursor: 'pointer' }}
                                onClick={() => {
                                        setDisplayUpdateAll(p => { return { ...p, client: false } });
                                        setUpdateDetail({ widget: Number, country: '', topic: '', fb_id: '', client: '', url: '', keyword: [], final_url: '',  note: '', emailoctopus_list_id: [], emailoctopus_campaign_id: [], complete_date: moment().format('YYYY-MM-DDTHH:mm'), categories: [], daily_budget: '1', headline: [""], picture: [""], ad_media: [{}], message: [""], adset_count: '1', facebook_fan_page: '', instagram_account: '', case: '' })
                                        setUpdateAllUrl({
                                            error: false,
                                            finalUrl: '',
                                            clients_percent: [],
                                            clients_with_url: [
                                                { client: 'Adcombo', id: '', url: '', index: 0 },
                                                { client: 'Ads', id: '', url: '', index: 1 },
                                                { client: 'Crossroads', id: '', url: '', index: 2 },
                                                { client: 'Sedo', id: '', url: '', index: 3 },
                                                { client: 'System1', id: '', url: '', index: 4 },
                                                { client: 'Tonic', id: '', url: '', index: 5 },
                                            ], 
                                            action: {
                                                Adcombo: {state: false, ['%']: '0'},
                                                Ads: {state: false, ['%']: '0'},
                                                Crossroads: {state: false, ['%']: '0'},
                                                Sedo: {state: false, ['%']: '0'},
                                                System1: {state: false, ['%']: '0'},
                                                Tonic: {state: false, ['%']: '0'}
                                            }
                                        })
                                    }
                                }
                            />
                        </div>
                        
                        <label className='widget-all-form-label'>Case: <span style={{ color: '#000', fontWeight: '600' }}>{updateDetail.case}</span></label>

                        <label className='widget-all-form-label'>Country:</label>
                        <input className='widget2-input' type="text" name='country' autoComplete='off' placeholder='country' value={updateDetail.country} onChange={e => handleChangeUpdate(e)} style={{ maxWidth: '100%' }} />
                        
                        <label className='widget-all-form-label'>Topic:</label>
                        <input className='widget2-input' type="text" name='topic' autoComplete='off' placeholder='topic' value={updateDetail.topic} onChange={e => handleChangeUpdate(e)} style={{ maxWidth: '100%' }}/>
                        
                        <label className='widget-all-form-label'>Facebook Fan Page:</label>
                        <select className='widget2-select' name='facebook_fan_page' value={updateDetail.facebook_fan_page} onChange={e => handleChangeUpdate(e)} style={{ maxWidth: '100%' }} onFocus={e => e.target.style.borderColor = '#000'} onBlur={e => e.target.style.borderColor = '#AAA'}>
                            <option value="">Choose fan page</option>
                            {data.fb_fan_pages_post.map((e, i) => (
                                <option value={e.id} key={i}>{e.name}</option>
                            ))}
                        </select>

                        <label className='widget-all-form-label'>Add URL:</label>
                        <div className='widget-all-form-dynamic-url'>
                            {updateAllUrl.clients_with_url.map((info, i) => (
                                <div key={i}>
                                    <div style={{ position: 'relative', width: '100%' }}>
                                        <label className='widget-all-form-label'>{info.client} ID:</label>
                                        <input className='widget2-input' type="text" placeholder={`${info.client} id`} value={info.id} autoComplete='off' style={{ maxWidth: '100%' }}
                                            onChange={e => {
                                                setUpdateAllUrl(p => {
                                                    let temp = [...p.clients_with_url]
                                                    temp[temp.findIndex((loopVariable) => loopVariable.client === info.client)].id = e.target.value?.replace(/\s/g, '')
                                                    return {
                                                        ...p,
                                                        clients_with_url: temp,
                                                    }
                                                })
                                            }}
                                        />
                                    </div>

                                    <div style={{ position: 'relative', width: '100%' }}>
                                        <label className='widget-all-form-label'>{info.client} URL:</label>
                                        <select className='widget2-select' style={{ maxWidth: '100%' }} onFocus={e => e.target.style.borderColor = '#000'} onBlur={e => e.target.style.borderColor = '#AAA'}
                                            value={info.url}
                                            onChange={e => {
                                                setUpdateAllUrl(p => {
                                                    let temp_url = [...p.clients_with_url]
                                                    let temp_percent = [...p.clients_percent]
                                                    let temp_action = {...p.action}
                                                    temp_url[temp_url.findIndex((loopVariable) => loopVariable.client === info.client)].url = e.target.value?.replace(/\s/g, '')

                                                    if (e.target.value?.replace(/\s/g, '') === '') {
                                                        temp_percent = temp_percent.filter(f => f !== info.client)
                                                        temp_action[info.client].state = false
                                                        temp_action[info.client]['%'] = '0'
                                                    } else {
                                                        if (!temp_percent.find(f => f === info.client)) {
                                                            temp_percent.push(info.client)
                                                        }
                                                    }

                                                    return {
                                                        ...p,
                                                        clients_with_url: temp_url,
                                                        clients_percent: temp_percent,
                                                        action: temp_action
                                                    }
                                                })
                                            }} 
                                        >
                                            <option value="">Choose Link</option>
                                            {settings.data.filter(f => (f.facebook_page_id === updateDetail.facebook_fan_page) && (f.link_type === 'Fixed')).map((e, i) => (
                                                <option value={e.link} key={i}>{e.topic} {"(" + e?.link?.substring(0, 30) + "...)"}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div style={{ height: 'fit-content', width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                            {(
                                updateAllUrl.clients_with_url[updateAllUrl.clients_with_url.length - 1].client !== 'Adcombo' &&
                                updateAllUrl.clients_with_url[updateAllUrl.clients_with_url.length - 1].client !== 'Ads' &&
                                updateAllUrl.clients_with_url[updateAllUrl.clients_with_url.length - 1].client !== 'Crossroads' &&
                                updateAllUrl.clients_with_url[updateAllUrl.clients_with_url.length - 1].client !== 'Sedo' &&
                                updateAllUrl.clients_with_url[updateAllUrl.clients_with_url.length - 1].client !== 'System1' &&
                                updateAllUrl.clients_with_url[updateAllUrl.clients_with_url.length - 1].client !== 'Tonic'
                            ) ? (
                                <button style={{ width: 'fit-content', height: '30px', padding: '0px 5px', background: 'rgba(255,0,0,0.1)', outline: 'none', border: '1px solid #F00', borderRadius: '5px', fontSize: '14px', color: '#F00', cursor: 'pointer' }}
                                    onClick={() => setUpdateAllUrl(p => {
                                        let temp_url = [...p.clients_with_url]
                                        let temp_percent = [...p.clients_percent]
                                        let temp_action = {...p.action}
                                        if (temp_url[temp_url.length - 1].client !== 'Adcombo' &&
                                            temp_url[temp_url.length - 1].client !== 'Ads' &&
                                            temp_url[temp_url.length - 1].client !== 'Crossroads' &&
                                            temp_url[temp_url.length - 1].client !== 'Sedo' &&
                                            temp_url[temp_url.length - 1].client !== 'System1' &&
                                            temp_url[temp_url.length - 1].client !== 'Tonic'
                                        ) {
                                            delete temp_action[temp_url[temp_url.length - 1].client]
                                            temp_percent = temp_percent.filter(f => f !== temp_url[temp_url.length - 1].client)
                                            temp_url.pop()
                                        }
                                        return {
                                            ...p,
                                            clients_with_url: temp_url,
                                            clients_percent: temp_percent,
                                            action: temp_action
                                        }
                                    })}
                                >
                                    Remove Url -
                                </button>
                            ) : (<></>)}
                            <button style={{ width: 'fit-content', height: '30px', padding: '0px 5px', background: 'rgba(76,175,80,0.1)', outline: 'none', border: '1px solid #4caf50', borderRadius: '5px', fontSize: '14px', color: '#4caf50', cursor: 'pointer' }}
                                onClick={() => setUpdateAllUrl(p => {
                                    let temp_url = [...p.clients_with_url]
                                    let temp_action = {...p.action}
                                    temp_url.push({ client: `Other${temp_url.length-5}`, id: '', url: '', index: temp_url.length })
                                    temp_action[`Other${temp_url.length-6}`] = {state: false, ['%']: '0'}
                                    return {
                                        ...p,
                                        clients_with_url: temp_url,
                                        action: temp_action
                                    }
                                })}
                            >
                                Add Url +
                            </button>
                        </div>

                        {updateAllUrl.clients_percent.map((ele, i) => (
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }} key={i}>
                                <label className='widget-all-form-label' style={{ width: 'fit-content' }}>{ele} Action %:</label>
                                <input className='widget2-input' type="number" placeholder={ele+' %'} value={updateAllUrl?.action?.[ele]?.['%']} onChange={e => handleUpdateActionInput({ e: e, cli: ele })} onWheel={e => { e.target.blur(); e.stopPropagation(); }} style={{ maxWidth: '100px', borderColor: updateAllUrl.error ? '#F00' : '#CCC' }} />
                            </div>
                        ))}

                        <label className='widget-all-form-label'>Final URL:</label>
                        <select className='widget2-select' value={updateAllUrl.finalUrl} onChange={e => setUpdateAllUrl(p => { return { ...p, finalUrl: e.target.value } })} style={{ maxWidth: '100%' }}>
                            <option value="">Choose Final URL</option>
                            <option value="https://esquiz.com/widget/">esquiz</option>
                            <option value="https://vehiclemambo.com/">vehiclemambo</option>
                        </select>

                        <button className='widget2-button' style={{ maxWidth: '100%' }} onClick={e => handleSubmitUpdateAll(e)}>UPDATE</button>
                    </div>
                </div>
            ) : (<></>)}

            {/* add all countries form */}
            {displayAddAll.country ? (
                <div className="widget-all-form-container">
                    <div className='widget-all-form'>
                        <div className='widget-all-form-nav'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <p style={{ fontSize: '24px', fontWeight: 600, color: '#36a2eb' }}>Add {addAllCountryUrl.mode}</p>
                                <div
                                    style={{ height: '30px', width: '30px', background: '#1976d2', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
                                    onClick={() => {
                                        if (addAllCountryUrl.mode === 'Country') {
                                            setAddAllCountryUrl(p => { return { ...p, displayEO: false, displayFC: false, country: [], url: {}, id: {}, domain: {}, mode: 'Region', region: [], region_url: {}, region_id: {}, finalUrl: '' } })
                                        } else {
                                            setAddAllCountryUrl(p => { return { ...p, displayEO: false, displayFC: false, country: [], url: {}, id: {}, domain: {}, mode: 'Country', region: [], region_url: {}, region_id: {}, finalUrl: '' } })
                                        }
                                    }}
                                >
                                    <FaSync size={16} color={'#FFF'}/>
                                </div>
                            </div>
                            <MdOutlineCancel size={24} color='#FF0000' style={{ cursor: 'pointer' }}
                                onClick={() => {
                                        setDisplayAddAll(p => { return { ...p, country: false } });
                                        setAddDetail(p => { return { ...p, country: '', topic: '', fb_id: '', client: '', url: '', note: '', emailoctopus_list_id: [], emailoctopus_campaign_id: [], complete_date: moment().format('YYYY-MM-DDTHH:mm'), categories: [], daily_budget: '1', headline: [""], picture: [""], ad_media: [{}], message: [""], comment: "", adset_count: '1', facebook_fan_page: '', instagram_account: '' } })
                                        setAddAllCountryUrl({ displayEO: false, displayFC: false, filteredCountry: country_code, country: [], url: {}, id: {}, domain: {}, mode: 'Country', region: [], region_url: {}, region_id: {}, finalUrl: '' })
                                    }
                                }
                            />
                        </div>

                        <label className='widget-all-form-label'>Case: <span style={{ color: '#000', fontWeight: '600' }}>{global.widget_case}</span></label>

                        <label className='widget-all-form-label'>{addAllCountryUrl.mode}:</label>
                        {(addAllCountryUrl.mode === 'Country') ? (
                            <div className='widget2-input' style={{ position: 'relative', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center', maxWidth: '100%', height: 'fit-content', padding: '5px', gap: '5px' }}>
                                {addAllCountryUrl.country.map((e, i) => (
                                    <div key={i}
                                        style={{ height: '30px', width: 'fit-content', padding: '0px 5px', border: '1px solid #000', display: 'flex', alignItems: 'center', borderRadius: '5px', cursor: 'pointer' }}
                                        onMouseEnter={e => e.currentTarget.style.background = 'rgba(0,0,0,0.1)'}
                                        onMouseLeave={e => e.currentTarget.style.background = '#FFF'}
                                        onClick={() => setAddAllCountryUrl(p => { return { ...p, country: p.country.filter(f => f !== e) } })}
                                    >
                                        &#10060;{e}
                                    </div>
                                ))}
                                <input className='widget2-input' type="text" name='country' placeholder='country' autoComplete='off' value={addDetail.country} onClick={() => setAddAllCountryUrl(p => {return{...p, displayFC: !p.displayFC}})} onChange={e => handleChangeAdd(e)} style={{ flex: 1, minWidth: '200px', maxWidth: '100%', border: 'none' }} />
                                <div style={{ position: 'absolute', zIndex: 40, top: '100%', left: '0', width: '100%', height: '200px', background: '#222', display: addAllCountryUrl.displayFC ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center', overflowY: 'scroll', borderRadius: '10px' }}>
                                    <div style={{ height: 'fit-content', width: '100%', padding: '5px', fontSize: '14px', color: '#FFF', borderBottom: '1px solid #EEE', cursor: 'pointer', background: (addAllCountryUrl.country.find(f => f === 'OTHER')) ? '#555' : '#222' }}
                                        onClick={() => {
                                            setAddAllCountryUrl(p => {
                                                let find_country = p.country.find(f => f === 'OTHER')
                                                let tempArr = [...p.country]
                                                if (find_country) {
                                                    tempArr = p.country.filter(f => f !== 'OTHER')
                                                } else {
                                                    tempArr.push('OTHER')
                                                }
                                                return{...p, country: tempArr, displayFC: false}
                                            })
                                        }}
                                        onMouseEnter={e => e.currentTarget.style.opacity = '50%'}
                                        onMouseLeave={e => e.currentTarget.style.opacity = '100%'}
                                    >
                                        OTHER
                                    </div>
                                    {addAllCountryUrl.filteredCountry.map((con, i) => (
                                        <div key={i}
                                            style={{ height: 'fit-content', width: '100%', padding: '5px', fontSize: '14px', color: '#FFF', borderBottom: '1px solid #EEE', cursor: 'pointer', background: (addAllCountryUrl.country.find(f => f === con.cca2)) ? '#555' : '#222' }}
                                            onClick={() => {
                                                setAddAllCountryUrl(p => {
                                                    let find_country = p.country.find(f => f === con.cca2)
                                                    let tempArr = [...p.country]
                                                    if (find_country) {
                                                        tempArr = p.country.filter(f => f !== con.cca2)
                                                    } else {
                                                        tempArr.push(con.cca2)
                                                    }
                                                    return{...p, country: tempArr, displayFC: false}
                                                })
                                            }}
                                            onMouseEnter={e => e.currentTarget.style.opacity = '50%'}
                                            onMouseLeave={e => e.currentTarget.style.opacity = '100%'}
                                        >
                                            {con.name.common}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className='widget2-input' style={{ position: 'relative', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center', maxWidth: '100%', height: 'fit-content', padding: '5px', gap: '5px' }}>
                                {addAllCountryUrl.region.map((e, i) => (
                                    <div key={i}
                                        style={{ height: '30px', width: 'fit-content', padding: '0px 5px', border: '1px solid #000', display: 'flex', alignItems: 'center', borderRadius: '5px', cursor: 'pointer' }}
                                        onMouseEnter={e => e.currentTarget.style.background = 'rgba(0,0,0,0.1)'}
                                        onMouseLeave={e => e.currentTarget.style.background = '#FFF'}
                                        onClick={() => setAddAllCountryUrl(p => { return { ...p, region: p.region.filter(f => f !== e) } })}
                                    >
                                        &#10060;{e}
                                    </div>
                                ))}
                                <input className='widget2-input' type="button" value='Choose Regions' onClick={() => setAddAllCountryUrl(p => {return{...p, displayFC: !p.displayFC}})} style={{ flex: 1, minWidth: '200px', maxWidth: '100%', border: 'none', cursor: 'pointer' }} />
                                <div style={{ position: 'absolute', zIndex: 40, top: '100%', left: '0', width: '100%', height: '200px', background: '#222', display: addAllCountryUrl.displayFC ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center', overflowY: 'scroll', borderRadius: '10px' }}>
                                    <div style={{ height: 'fit-content', width: '100%', padding: '5px', fontSize: '14px', color: '#FFF', borderBottom: '1px solid #EEE', cursor: 'pointer', background: (addAllCountryUrl.region.find(f => f === 'OTHER')) ? '#555' : '#222' }}
                                        onClick={() => {
                                            setAddAllCountryUrl(p => {
                                                let find_region = p.region.find(f => f === 'OTHER')
                                                let tempArr = [...p.region]
                                                if (find_region) {
                                                    tempArr = p.region.filter(f => f !== 'OTHER')
                                                } else {
                                                    tempArr.push('OTHER')
                                                }
                                                return{...p, region: tempArr, displayFC: false}
                                            })
                                        }}
                                        onMouseEnter={e => e.currentTarget.style.opacity = '50%'}
                                        onMouseLeave={e => e.currentTarget.style.opacity = '100%'}
                                    >
                                        OTHER
                                    </div>

                                    {["Africa","Americas","Antarctic","Asia","Europe","Oceania"].map((con, i) => (
                                        <div key={i}
                                            style={{ height: 'fit-content', width: '100%', padding: '5px', fontSize: '14px', color: '#FFF', borderBottom: '1px solid #EEE', cursor: 'pointer', background: (addAllCountryUrl.region.find(f => f === con)) ? '#555' : '#222' }}
                                            onClick={() => {
                                                setAddAllCountryUrl(p => {
                                                    let find_region = p.region.find(f => f === con)
                                                    let tempArr = [...p.region]
                                                    if (find_region) {
                                                        tempArr = p.region.filter(f => f !== con)
                                                    } else {
                                                        tempArr.push(con)
                                                    }
                                                    return{...p, region: tempArr, displayFC: false}
                                                })
                                            }}
                                            onMouseEnter={e => e.currentTarget.style.opacity = '50%'}
                                            onMouseLeave={e => e.currentTarget.style.opacity = '100%'}
                                        >
                                            {con}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        
                        <label className='widget-all-form-label'>Topic:</label>
                        <input className='widget2-input' type="text" name='topic' placeholder='topic' autoComplete='off' value={addDetail.topic} onChange={e => handleChangeAdd(e)} style={{ maxWidth: '100%' }}/>

                        <label className='widget-all-form-label'>Facebook Fan Page:</label>
                        <select className='widget2-select' name='facebook_fan_page' value={addDetail.facebook_fan_page} onChange={e => handleChangeAdd(e)} style={{ maxWidth: '100%' }} onFocus={e => e.target.style.borderColor = '#000'} onBlur={e => e.target.style.borderColor = '#AAA'}>
                            <option value="">Choose fan page</option>
                            {data.fb_fan_pages_post.map((e, i) => (
                                <option value={e.id} key={i}>{e.name}</option>
                            ))}
                        </select>
                        
                        <label className='widget-all-form-label'>Client:</label>
                        <select className='widget2-select' name="client" value={addDetail.client} onChange={e => handleChangeAdd(e)} style={{ maxWidth: '100%' }}>
                            <option value="">Choose Client</option>
                            {['Sedo','Tonic','System1','Crossroads','Ads','Adcombo','Other'].map((e, i) => (
                                <option value={e}>{e}</option>
                            ))}
                        </select>

                        {(addAllCountryUrl.mode === 'Country') ? (
                            <div className='widget-all-form-url-grid' style={{ gridTemplateColumns: '1fr' }}>
                                {((addAllCountryUrl.country.length > 0) && addDetail.facebook_fan_page && addDetail.client) ? (
                                    addAllCountryUrl.country.map((con, i) => (
                                        <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }} key={i}>
                                            <div>
                                                <label className='widget-all-form-label'>{con} ID for {addDetail.client}:</label>
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <input className='widget2-input' type="text" placeholder={`${con} id`} value={addAllCountryUrl.id[con]} autoComplete='off' onClick={() => setAddAllCountryUrl(p => {return{...p, domain: {...p.domain, [con]: !p.domain[con]}}})} onChange={e => setAddAllCountryUrl(p => {return{...p, id: { ...p.id, [con]: e.target.value?.replace(/\s/g, '') } }})} style={{ maxWidth: '100%' }} />
                                                </div>
                                            </div>
                                            <div>
                                                <label className='widget-all-form-label'>{con} URL for {addDetail.client}:</label>
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <select className='widget2-select' style={{ maxWidth: '100%' }} onFocus={e => e.target.style.borderColor = '#000'} onBlur={e => e.target.style.borderColor = '#AAA'}
                                                        value={addAllCountryUrl.url?.[con]}
                                                        onChange={e => setAddAllCountryUrl(p => {return{...p, url: { ...p.url, [con]: e.target.value?.replace(/\s/g, '') } }})}
                                                    >
                                                        <option value="">Choose Link</option>
                                                        {settings.data.filter(f => f.link_type === 'Fixed').map((e, i) => (
                                                            <option value={e.link} key={i}>{e.topic} {"(" + e?.link?.substring(0, 30) + "...)"}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (<></>)}
                            </div>
                        ) : (
                            <div className='widget-all-form-url-grid' style={{ gridTemplateColumns: '1fr' }}>
                                {((addAllCountryUrl.region.length > 0) && addDetail.facebook_fan_page && addDetail.client) ? (
                                    addAllCountryUrl.region.map((con, i) => (
                                        <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }} key={i}>
                                            <div>
                                                <label className='widget-all-form-label'>{con} ID for {addDetail.client}:</label>
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <input className='widget2-input' type="text" placeholder={`${con} id`} value={addAllCountryUrl.region_id[con]} autoComplete='off' onChange={e => setAddAllCountryUrl(p => {return{...p, region_id: { ...p.region_id, [con]: e.target.value?.replace(/\s/g, '') } }})} style={{ maxWidth: '100%' }} />
                                                </div>
                                            </div>
                                            <div>
                                                <label className='widget-all-form-label'>{con} URL for {addDetail.client}:</label>
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <select className='widget2-select' style={{ maxWidth: '100%' }} onFocus={e => e.target.style.borderColor = '#000'} onBlur={e => e.target.style.borderColor = '#AAA'}
                                                        value={addAllCountryUrl.region_url?.[con]}
                                                        onChange={e => setAddAllCountryUrl(p => {return{...p, region_url: { ...p.region_url, [con]: e.target.value?.replace(/\s/g, '') } }})}
                                                    >
                                                        <option value="">Choose Link</option>
                                                        {settings.data.filter(f => f.link_type === 'Fixed').map((e, i) => (
                                                            <option value={e.link} key={i}>{e.topic} {"(" + e?.link?.substring(0, 30) + "...)"}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (<></>)}
                            </div>
                        )}

                        <label className='widget-all-form-label'>Final URL:</label>
                        <select className='widget2-select' value={addAllCountryUrl.finalUrl} onChange={e => setAddAllCountryUrl(p => { return { ...p, finalUrl: e.target.value } })} style={{ maxWidth: '100%' }}>
                            <option value="">Choose Final URL</option>
                            <option value="https://esquiz.com/widget/">esquiz</option>
                            <option value="https://vehiclemambo.com/">vehiclemambo</option>
                        </select>

                        <button className='widget2-button' style={{ maxWidth: '100%' }} onClick={e => handleSubmitAddAllCountry(e)}>SUBMIT</button>
                    </div>
                </div>
            ) : (<></>)}

            {/* update all countries form */}
            {displayUpdateAll.country ? (
                <div className="widget-all-form-container">
                    <div className='widget-all-form'>
                        <div className='widget-all-form-nav'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <p style={{ fontSize: '24px', fontWeight: 600, color: '#36a2eb' }}>Update {updateAllCountryUrl.mode}</p>
                                <div
                                    style={{ height: '30px', width: '30px', background: '#1976d2', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
                                    onClick={() => {
                                        if (updateAllCountryUrl.mode === 'Country') {
                                            setUpdateAllCountryUrl(p => { return { ...p, displayEO: false, displayFC: false, mode: 'Region' } })
                                        } else {
                                            setUpdateAllCountryUrl(p => { return { ...p, displayEO: false, displayFC: false, mode: 'Country' } })
                                        }
                                    }}
                                >
                                    <FaSync size={16} color={'#FFF'}/>
                                </div>
                            </div>
                            <MdOutlineCancel size={24} color='#FF0000' style={{ cursor: 'pointer' }}
                                onClick={() => {
                                        setDisplayUpdateAll(p => { return { ...p, country: false } });
                                        setUpdateDetail({ widget: Number, country: '', topic: '', fb_id: '', client: '', url: '', keyword: [], final_url: '',  note: '', emailoctopus_list_id: [], emailoctopus_campaign_id: [], complete_date: moment().format('YYYY-MM-DDTHH:mm'), categories: [], daily_budget: '1', headline: [""], picture: [""], ad_media: [{}], message: [""], adset_count: '1', facebook_fan_page: '', instagram_account: '', case: '' })
                                        setUpdateAllCountryUrl({ displayFC: false, filteredCountry: country_code, country: [], url: {}, id: {}, mode: 'Country', region: [], region_url: {}, region_id: {}, finalUrl: '' })
                                    }
                                }
                            />
                        </div>

                        <label className='widget-all-form-label'>Case: <span style={{ color: '#000', fontWeight: '600' }}>{updateDetail.case}</span></label>

                        <label className='widget-all-form-label'>{updateAllCountryUrl.mode}:</label>
                        {(updateAllCountryUrl.mode === 'Country') ? (
                            <div className='widget2-input' style={{ position: 'relative', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center', maxWidth: '100%', height: 'fit-content', padding: '5px', gap: '5px' }}>
                                {updateAllCountryUrl.country.map((e, i) => (
                                    <div key={i}
                                        style={{ height: '30px', width: 'fit-content', padding: '0px 5px', border: '1px solid #000', display: 'flex', alignItems: 'center', borderRadius: '5px', cursor: 'pointer' }}
                                        onMouseEnter={e => e.currentTarget.style.background = 'rgba(0,0,0,0.1)'}
                                        onMouseLeave={e => e.currentTarget.style.background = '#FFF'}
                                        onClick={() => setUpdateAllCountryUrl(p => { return { ...p, country: p.country.filter(f => f !== e) } })}
                                    >
                                        &#10060;{e}
                                    </div>
                                ))}
                                <input className='widget2-input' type="text" name='country' placeholder='country' value={updateDetail.country} onClick={() => setUpdateAllCountryUrl(p => {return{...p, displayFC: !p.displayFC}})} onChange={e => handleChangeUpdate(e)} style={{ flex: 1, minWidth: '200px', maxWidth: '100%', border: 'none' }} />
                                <div style={{ position: 'absolute', zIndex: 40, top: '100%', left: '0', width: '100%', height: '200px', background: '#222', display: updateAllCountryUrl.displayFC ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center', overflowY: 'scroll', borderRadius: '10px' }}>
                                    <div style={{ height: 'fit-content', width: '100%', padding: '5px', fontSize: '14px', color: '#FFF', borderBottom: '1px solid #EEE', cursor: 'pointer', background: (updateAllCountryUrl.country.find(f => f === 'OTHER')) ? '#555' : '#222' }}
                                        onClick={() => {
                                            setUpdateAllCountryUrl(p => {
                                                let find_country = p.country.find(f => f === 'OTHER')
                                                let tempArr = [...p.country]
                                                if (find_country) {
                                                    tempArr = p.country.filter(f => f !== 'OTHER')
                                                } else {
                                                    tempArr.push('OTHER')
                                                }
                                                return{...p, country: tempArr, displayFC: false}
                                            })
                                        }}
                                        onMouseEnter={e => e.currentTarget.style.opacity = '50%'}
                                        onMouseLeave={e => e.currentTarget.style.opacity = '100%'}
                                    >
                                        OTHER
                                    </div>
                                    {updateAllCountryUrl.filteredCountry.map((con, i) => (
                                        <div key={i}
                                            style={{ height: 'fit-content', width: '100%', padding: '5px', fontSize: '14px', color: '#FFF', borderBottom: '1px solid #EEE', cursor: 'pointer', background: (updateAllCountryUrl.country.find(f => f === con.cca2)) ? '#555' : '#222' }}
                                            onClick={() => {
                                                setUpdateAllCountryUrl(p => {
                                                    let find_country = p.country.find(f => f === con.cca2)
                                                    let tempArr = [...p.country]
                                                    if (find_country) {
                                                        tempArr = p.country.filter(f => f !== con.cca2)
                                                    } else {
                                                        tempArr.push(con.cca2)
                                                    }
                                                    return{...p, country: tempArr, displayFC: false}
                                                })
                                            }}
                                            onMouseEnter={e => e.currentTarget.style.opacity = '50%'}
                                            onMouseLeave={e => e.currentTarget.style.opacity = '100%'}
                                        >
                                            {con.name.common}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className='widget2-input' style={{ position: 'relative', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center', maxWidth: '100%', height: 'fit-content', padding: '5px', gap: '5px' }}>
                                {updateAllCountryUrl.region.map((e, i) => (
                                    <div key={i}
                                        style={{ height: '30px', width: 'fit-content', padding: '0px 5px', border: '1px solid #000', display: 'flex', alignItems: 'center', borderRadius: '5px', cursor: 'pointer' }}
                                        onMouseEnter={e => e.currentTarget.style.background = 'rgba(0,0,0,0.1)'}
                                        onMouseLeave={e => e.currentTarget.style.background = '#FFF'}
                                        onClick={() => setUpdateAllCountryUrl(p => { return { ...p, region: p.region.filter(f => f !== e) } })}
                                    >
                                        &#10060;{e}
                                    </div>
                                ))}
                                <input className='widget2-input' type="button" value='Choose Regions' onClick={() => setUpdateAllCountryUrl(p => {return{...p, displayFC: !p.displayFC}})} style={{ flex: 1, minWidth: '200px', maxWidth: '100%', border: 'none', cursor: 'pointer' }} />
                                <div style={{ position: 'absolute', zIndex: 40, top: '100%', left: '0', width: '100%', height: '200px', background: '#222', display: updateAllCountryUrl.displayFC ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center', overflowY: 'scroll', borderRadius: '10px' }}>
                                    <div style={{ height: 'fit-content', width: '100%', padding: '5px', fontSize: '14px', color: '#FFF', borderBottom: '1px solid #EEE', cursor: 'pointer', background: (updateAllCountryUrl.region.find(f => f === 'OTHER')) ? '#555' : '#222' }}
                                        onClick={() => {
                                            setUpdateAllCountryUrl(p => {
                                                let find_region = p.region.find(f => f === 'OTHER')
                                                let tempArr = [...p.region]
                                                if (find_region) {
                                                    tempArr = p.region.filter(f => f !== 'OTHER')
                                                } else {
                                                    tempArr.push('OTHER')
                                                }
                                                return{...p, region: tempArr, displayFC: false}
                                            })
                                        }}
                                        onMouseEnter={e => e.currentTarget.style.opacity = '50%'}
                                        onMouseLeave={e => e.currentTarget.style.opacity = '100%'}
                                    >
                                        OTHER
                                    </div>

                                    {["Africa","Americas","Antarctic","Asia","Europe","Oceania"].map((con, i) => (
                                        <div key={i}
                                            style={{ height: 'fit-content', width: '100%', padding: '5px', fontSize: '14px', color: '#FFF', borderBottom: '1px solid #EEE', cursor: 'pointer', background: (updateAllCountryUrl.region.find(f => f === con)) ? '#555' : '#222' }}
                                            onClick={() => {
                                                setUpdateAllCountryUrl(p => {
                                                    let find_region = p.region.find(f => f === con)
                                                    let tempArr = [...p.region]
                                                    if (find_region) {
                                                        tempArr = p.region.filter(f => f !== con)
                                                    } else {
                                                        tempArr.push(con)
                                                    }
                                                    return{...p, region: tempArr, displayFC: false}
                                                })
                                            }}
                                            onMouseEnter={e => e.currentTarget.style.opacity = '50%'}
                                            onMouseLeave={e => e.currentTarget.style.opacity = '100%'}
                                        >
                                            {con}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        <label className='widget-all-form-label'>Topic:</label>
                        <input className='widget2-input' type="text" name='topic' placeholder='topic' value={updateDetail.topic} onChange={e => handleChangeUpdate(e)} style={{ maxWidth: '100%' }}/>

                        <label className='widget-all-form-label'>Facebook Fan Page:</label>
                        <select className='widget2-select' name='facebook_fan_page' value={updateDetail.facebook_fan_page} onChange={e => handleChangeUpdate(e)} style={{ maxWidth: '100%' }} onFocus={e => e.target.style.borderColor = '#000'} onBlur={e => e.target.style.borderColor = '#AAA'}>
                            <option value="">Choose fan page</option>
                            {data.fb_fan_pages_post.map((e, i) => (
                                <option value={e.id} key={i}>{e.name}</option>
                            ))}
                        </select>

                        <label className='widget-all-form-label'>Client:</label>
                        <select className='widget2-select' name="client" value={updateDetail.client} onChange={e => handleChangeUpdate(e)} style={{ maxWidth: '100%' }}>
                            <option value="">Choose Client</option>
                            {['Sedo','Tonic','System1','Crossroads','Ads','Adcombo','Other'].map((e, i) => (
                                <option value={e}>{e}</option>
                            ))}
                        </select>

                        {(updateAllCountryUrl.mode === 'Country') ? (
                            <div className='widget-all-form-url-grid' style={{ gridTemplateColumns: '1fr' }}>
                                {(updateAllCountryUrl.country.length > 0) ? (
                                    updateAllCountryUrl.country.map((con, i) => (
                                        <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }} key={i}>
                                            <div>
                                                <label className='widget-all-form-label'>{con} ID for {updateDetail.client}:</label>
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <input className='widget2-input' type="text" placeholder={`${con} id`} value={updateAllCountryUrl?.id?.[con]} autoComplete='off' onChange={e => setUpdateAllCountryUrl(p => {return{...p, id: { ...p.id, [con]: e.target.value?.replace(/\s/g, '') } }})} style={{ maxWidth: '100%' }} />
                                                </div>
                                            </div>
                                            <div>
                                                <label className='widget-all-form-label'>{con} URL for {updateDetail.client}:</label>
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <select className='widget2-select' style={{ maxWidth: '100%' }} onFocus={e => e.target.style.borderColor = '#000'} onBlur={e => e.target.style.borderColor = '#AAA'}
                                                        value={updateAllCountryUrl.url?.[con]}
                                                        onChange={e => setUpdateAllCountryUrl(p => {return{...p, url: { ...p.url, [con]: e.target.value?.replace(/\s/g, '') } }})}
                                                    >
                                                        <option value="">Choose Link</option>
                                                        {settings.data.filter(f => f.link_type === 'Fixed').map((e, i) => (
                                                            <option value={e.link} key={i}>{e.topic} {"(" + e?.link?.substring(0, 30) + "...)"}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (<></>)}
                            </div>
                        ) : (
                            <div className='widget-all-form-url-grid' style={{ gridTemplateColumns: '1fr' }}>
                                {(updateAllCountryUrl.region.length > 0) ? (
                                    updateAllCountryUrl.region.map((con, i) => (
                                        <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }} key={i}>
                                            <div>
                                                <label className='widget-all-form-label'>{con} ID for {updateDetail.client}:</label>
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <input className='widget2-input' type="text" placeholder={`${con} id`} value={updateAllCountryUrl.region_id?.[con]} autoComplete='off' onChange={e => setUpdateAllCountryUrl(p => {return{...p, region_id: { ...p.region_id, [con]: e.target.value?.replace(/\s/g, '') } }})} style={{ maxWidth: '100%' }} />
                                                </div>
                                            </div>
                                            <div>
                                                <label className='widget-all-form-label'>{con} URL for {updateDetail.client}:</label>
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <select className='widget2-select' style={{ maxWidth: '100%' }} onFocus={e => e.target.style.borderColor = '#000'} onBlur={e => e.target.style.borderColor = '#AAA'}
                                                        value={updateAllCountryUrl.region_url?.[con]}
                                                        onChange={e => setUpdateAllCountryUrl(p => {return{...p, region_url: { ...p.region_url, [con]: e.target.value?.replace(/\s/g, '') } }})}
                                                    >
                                                        <option value="">Choose Link</option>
                                                        {settings.data.filter(f => f.link_type === 'Fixed').map((e, i) => (
                                                            <option value={e.link} key={i}>{e.topic} {"(" + e?.link?.substring(0, 30) + "...)"}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (<></>)}
                            </div>
                        )}

                        <label className='widget-all-form-label'>Final URL:</label>
                        <select className='widget2-select' value={updateAllCountryUrl.finalUrl} onChange={e => setUpdateAllCountryUrl(p => { return { ...p, finalUrl: e.target.value } })} style={{ maxWidth: '100%' }}>
                            <option value="">Choose Final URL</option>
                            <option value="https://esquiz.com/widget/">esquiz</option>
                            <option value="https://vehiclemambo.com/">vehiclemambo</option>
                        </select>

                        <button className='widget2-button' style={{ maxWidth: '100%' }} onClick={e => handleSubmitUpdateAllCountries(e)}>UPDATE</button>
                    </div>
                </div>
            ) : (<></>)}

            {/* re-schedule post form */}
            {postReschedule.display ? (
                <div className="widget-all-form-container">
                    <div className='widget-all-form' style={{ maxWidth: '400px' }}>
                        <div className='widget-all-form-nav'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <p style={{ fontSize: '24px', fontWeight: 600, color: '#36a2eb' }}>Reschedule {postReschedule.data?.widget}</p>
                            </div>
                            <MdOutlineCancel size={24} color='#FF0000' style={{ cursor: 'pointer' }} onClick={() => setPostReschedule(p => { return { ...p, data: {}, display: false, complete_date: moment().add(1, 'day').format('YYYY-MM-DDTHH:mm'), repeat: '1', days: '1', message: "", comment: "", result: {} }})} />
                        </div>

                        <label className='widget-all-form-label'>Latest:&nbsp;
                            <span style={{ color: '#000', fontWeight: '600' }}>
                            {(
                                () => {
                                    const sorted_schedules = Array.isArray(postReschedule.data?.result?.posts) ? postReschedule.data?.result?.posts?.filter(f => f?.post_id)?.map(m => m?.schedule)?.sort((a, b) => moment(b).diff(moment(a))) : []
                                    return moment(sorted_schedules?.[0]).format('YYYY-MM-DD hh:mm a')
                                }
                            )()}
                            </span>
                        </label>

                        <label className='widget-all-form-label'>{"Schedule (25+ minutes):"}</label>
                        <div className='widget-add-field' style={{ width: '100%' }}>
                            {(
                                () => {
                                    const sorted_schedules = Array.isArray(postReschedule.data?.result?.posts) ? postReschedule.data?.result?.posts?.filter(f => f?.post_id)?.map(m => m?.schedule)?.sort((a, b) => moment(b).diff(moment(a))) : []
                                    const min_date = moment(sorted_schedules?.[0]).isAfter() ? moment(sorted_schedules?.[0]).add(1, 'day').format('YYYY-MM-DDTHH:mm') : moment().format('YYYY-MM-DDTHH:mm')
                                    return (
                                        <input className='widget2-input' style={{ maxWidth: '100%' }} type="datetime-local" autoComplete='off' min={min_date} value={postReschedule.complete_date} onChange={e => setPostReschedule(p => { return { ...p, complete_date: e.target.value } })} />
                                    )
                                }
                            )()}
                        </div>

                        <label className='widget-all-form-label'>{"Loop Repeat (30 max):"}</label>
                        <div className='widget-add-field' style={{ width: '100%' }}>
                            <input className='widget2-input' style={{ maxWidth: '100%' }} type="number" placeholder='1' autoComplete='off' value={postReschedule.repeat} onChange={e => setPostReschedule(p => { return { ...p, repeat: e.target.value } })} onWheel={e => e.target.blur()}
                                onBlur={e => {
                                    let temp_count = e.target.value

                                    if (!temp_count || Number(temp_count) < 1) {
                                        temp_count = '1'
                                    } else if (Number(temp_count) > 30) {
                                        temp_count = '30'
                                    } else {
                                        temp_count = Number(temp_count)?.toFixed(0)
                                    }

                                    setPostReschedule(p => { return { ...p, repeat: temp_count } })
                                }}
                            />
                        </div>

                        <label className='widget-all-form-label'>{"Loop Every ** Days (10 max):"}</label>
                        <div className='widget-add-field' style={{ width: '100%' }}>
                            <input className='widget2-input' style={{ maxWidth: '100%' }} type="number" placeholder='1' autoComplete='off' value={postReschedule.days} onChange={e => setPostReschedule(p => { return { ...p, days: e.target.value } })} onWheel={e => e.target.blur()}
                                onBlur={e => {
                                    let temp_count = e.target.value

                                    if (!temp_count || Number(temp_count) < 1) {
                                        temp_count = '1'
                                    } else if (Number(temp_count) > 10) {
                                        temp_count = '10'
                                    } else {
                                        temp_count = Number(temp_count)?.toFixed(0)
                                    }

                                    setPostReschedule(p => { return { ...p, days: temp_count } })
                                }}
                            />
                        </div>

                        <label className='widget-all-form-label'>Ad Text:</label>
                        <div className='widget-add-field' style={{ height: 'fit-content', width: '100%' }}>
                            <textarea className='widget2-textarea' style={{ maxWidth: '100%' }} type="text" placeholder='input ad text' value={postReschedule.message} onChange={e => setPostReschedule(p => { return { ...p, message: e.target.value } })} />
                        </div>

                        <label className='widget-all-form-label'>Post Comment:</label>
                        <div className='widget-add-field' style={{ height: 'fit-content', width: '100%' }}>
                            <textarea className='widget2-textarea' style={{ maxWidth: '100%' }} type="text" placeholder='input post comment' value={postReschedule.comment} onChange={e => setPostReschedule(p => { return { ...p, comment: e.target.value } })} />
                        </div>

                        <button className='widget2-button' style={{ maxWidth: '100%' }} onClick={() => handleSubmitPostReschedule()}>SUBMIT</button>
                    </div>
                </div>
            ) : (<></>)}

            {/* create link form */}
            {(global.display_form === 'create-link') ? (
                <div className="widget-all-form-container">
                    <div className='widget-all-form' style={{ maxWidth: '400px' }}>
                        <div className='widget-all-form-nav'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <p style={{ fontSize: '24px', fontWeight: 600, color: '#36a2eb' }}>Create Post Link</p>
                            </div>
                            <MdOutlineCancel size={24} color='#FF0000' style={{ cursor: 'pointer' }} onClick={() => {
                                setGlobal(p => { return { ...p, display_form: '' } })
                                setSettings(p => { return { ...p, loading: false, data: [], display: false, refresh: false, topic: '', link: '', facebook_page_id: '', facebook_page_name: '', link_type: 'Post', client: '', client_id: '' } })
                            }} />
                        </div>

                        <label className='widget-all-form-label'>Topic:</label>
                        <div className='widget-add-field' style={{ width: '100%' }}>
                            <input className='widget2-input' style={{ maxWidth: '100%' }} type="text" placeholder='TOPIC' autoComplete='off' value={settings.topic} onChange={e => setSettings(p => { return { ...p, topic: e.target.value } })}/>
                        </div>
                        
                        <label className='widget-all-form-label'>Link:</label>
                        <div className='widget-add-field' style={{ width: '100%' }}>
                            <input className='widget2-input' style={{ maxWidth: '100%' }} type="text" placeholder='TOPIC' autoComplete='off' value={settings.link} onChange={e => setSettings(p => { return { ...p, link: e.target.value.replace(/\s/g, '') } })}/>
                        </div>

                        <label className='widget-all-form-label'>Facebook Fan Page:</label>
                        <div className='widget-add-field' style={{ width: '100%' }}>
                            <select className='widget2-input' style={{ maxWidth: '100%' }} value={settings.facebook_page_id}
                                onChange={e => setSettings(p => {
                                    return {
                                        ...p,
                                        facebook_page_id: e.target.value,
                                        facebook_page_name: data.fb_fan_pages_post.find(f => f.id === e.target.value) ? data.fb_fan_pages_post.find(f => f.id === e.target.value).name : ""
                                    }
                                })}
                            >
                                <option value="">Choose</option>
                                {data.fb_fan_pages_post.map((e, i) => (
                                    <option key={i} value={e.id}>{e.name}</option>
                                ))}
                            </select>
                        </div>

                        <label className='widget-all-form-label'>Client:</label>
                        <div className='widget-add-field' style={{ width: '100%' }}>
                            <select className='widget2-select' style={{ maxWidth: '100%' }} value={settings.client} onChange={e => setSettings(p => { return { ...p, client: e.target.value } })}>
                                <option value="">Choose</option>
                                {['Sedo','Tonic','System1','Crossroads','Ads','Adcombo','Other'].map((e, i) => (
                                    <option key={i} value={e}>{e}</option>
                                ))}
                            </select>
                        </div>
                        
                        <label className='widget-all-form-label'>Client ID:</label>
                        <div className='widget-add-field' style={{ width: '100%' }}>
                            <input className='widget2-input' style={{ maxWidth: '100%' }} type="text" placeholder='CLIENTID' autoComplete='off' value={settings.client_id} onChange={e => setSettings(p => { return { ...p, client_id: e.target.value } })}/>
                        </div>

                        <button className='widget2-button'
                            style={{
                                maxWidth: '100%',
                                background: (settings.loading || !settings.topic.trim() || !settings.link.trim() || !settings.facebook_page_id.trim()) ? '#AAA' : '#4caf50',
                                cursor: (settings.loading || !settings.topic.trim() || !settings.link.trim() || !settings.facebook_page_id.trim()) ? 'not-allowed' : 'pointer',
                            }}
                            onClick={() => !settings.loading && handleSubmitSettings({ settings, setSettings, setGlobal, appContext })}
                        >
                            SUBMIT
                        </button>
                    </div>
                </div>
            ) : (<></>)}

            {/* facebook image or video selection component */}
            <FacebookMediaSelectComponent
                loading={(data.loading.fb_images || uploadFbImage.loading) ? true : false}
                fbMediaState={fbMediaState}
                setFbMediaState={setFbMediaState}
                fb_images={data.fb_images}
                fb_videos={data.fb_videos}
                addDetail={addDetail}
                setAddDetail={setAddDetail}
                global={global}
                uploadFbImage={uploadFbImage}
                setUploadFbImage={setUploadFbImage}
                fb_token={data.fb_token}
                appContext={appContext}
            />

            {/* top nav */}
            <div className="fbfp-top">
                <div className="fbfp-top-left-widget">
                    <div>
                        <div className="fbfp-top-left-widget-links-menu" style={{ gridTemplateColumns: '1fr' }}>
                            <div><p>Creation</p></div>
                            <button style={{ border: '1px solid #1976d2', background: (global.widget_case === 'Post Creation') ? '#1976d2' : 'transparent', color: (global.widget_case === 'Post Creation') ? '#FFF' : '#1976d2' }}
                                onClick={() => setGlobal(p => { return { ...p, widget_case: 'Post Creation' } })}
                            >
                                Post Creation
                            </button>
                        </div>
                        <div className="fbfp-top-left-widget-links-menu">
                            <div><p>Link Rotation</p></div>
                            <button style={{ border: '1px solid #1976d2', background: (global.widget_case === 'All') ? '#1976d2' : 'transparent', color: (global.widget_case === 'All') ? '#FFF' : '#1976d2' }}
                                onClick={() => setGlobal(p => { return { ...p, widget_case: 'All' } })}
                            >
                                By Client
                            </button>
                            <button style={{ border: '1px solid #1976d2', background: (global.widget_case === 'Country') ? '#1976d2' : 'transparent', color: (global.widget_case === 'Country') ? '#FFF' : '#1976d2' }}
                                onClick={() => setGlobal(p => { return { ...p, widget_case: 'Country' } })}
                            >
                                By Country
                            </button>
                        </div>
                    </div>
                    <div>
                        {(global.widget_case === 'All') && (<button style={{ background: '#4caf50', width: 'fit-content', padding: '0px 5px' }} onClick={() => setDisplayAddAll(p => { return { ...p, client: true } })}>Create All</button>)}
                        {(global.widget_case === 'Country') && (<button style={{ background: '#4caf50', width: 'fit-content', padding: '0px 5px' }} onClick={() => setDisplayAddAll(p => { return { ...p, country: true } })}>Create Country</button>)}
                        {(global.widget_case === 'Post Creation') && (
                            <>
                                <div className='widget-add-field'>
                                    <p className='widget-add-field-float-name'>FB Fan Page</p>
                                    <select className='widget2-select' name='facebook_fan_page' value={addDetail.facebook_fan_page} onChange={e => handleChangeAdd(e)} onFocus={e => e.target.style.borderColor = '#000'} onBlur={e => e.target.style.borderColor = '#AAA'}>
                                        <option value="">Choose fan page</option>
                                        {data.fb_fan_pages_post.map((e, i) => (
                                            <option value={e.id} key={i}>{e.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='widget-add-field'>
                                    <p className='widget-add-field-float-name'>Link / Topic</p>
                                    <select className='widget2-select' name='topic' value={addDetail.topic} onChange={e => handleChangeAdd(e)} onFocus={e => e.target.style.borderColor = '#000'} onBlur={e => e.target.style.borderColor = '#AAA'}>
                                        <option value="">Choose Link</option>
                                        {settings.data.filter(f => (f.facebook_page_id === addDetail.facebook_fan_page) && (f.link_type === 'Post')).map((e, i) => (
                                            <option value={e.link} key={i}>{e.topic} {"(" + e?.link?.substring(0, 30) + ")"}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='widget-add-field'>
                                    <p className='widget-add-field-float-name'>Facebook A/C</p>
                                    <select className='widget2-select' name='fb_id' value={addDetail.fb_id} onChange={e => handleChangeAdd(e)} onFocus={e => e.target.style.borderColor = '#000'} onBlur={e => e.target.style.borderColor = '#AAA'}>
                                        <option value="">Choose facebook A/C</option>
                                        {data.fb.map((e, i) => (
                                            <option value={e.id} key={i}>{e.name} {"("}{e.client}{")"}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='widget-add-field' style={{ height: 'fit-content' }}>
                                    <p className='widget-add-field-float-name'>Ad Text</p>
                                    <textarea className='widget2-textarea' type="text" name='message' placeholder='input ad text' value={addDetail.message[0]} onChange={e => handleChangeAdd(e)} />
                                </div>
                                <div className='widget-add-field' style={{ height: 'fit-content' }}>
                                    <p className='widget-add-field-float-name'>Post Comment</p>
                                    <textarea className='widget2-textarea' type="text" name='comment' placeholder='input post comment' value={addDetail.comment} onChange={e => handleChangeAdd(e)} />
                                </div>
                                <div className='widget-add-field' onClick={() => setFbMediaState(p => { return { ...p, display: true, mode: 'add', act_id: addDetail?.fb_id, widget: 0 } })}>
                                    <p className='widget-add-field-float-name'>Media</p>
                                    <div className='widget2-div' style={{ borderColor: (addSelectDisplay === 'adImages') ? '#000' : '#AAA' }}>
                                        {addDetail.picture.find(f => f) ? (
                                            <div style={{ justifyContent: 'center' }}>
                                                <img src={addDetail.picture[0]} alt='no image' style={{ height: '100%' }}/>
                                            </div>
                                        ) : (
                                            <div style={{ fontSize: '14px' }}>None Selected</div>
                                        )}
                                    </div>
                                </div>
                                <div className='widget-add-field'>
                                    <p className='widget-add-field-float-name'>Schedule (25+ minutes)</p>
                                    <input className='widget2-input' type="datetime-local" name='complete_date' placeholder='complete_date' autoComplete='off' min={moment().format('YYYY-MM-DDTHH:mm')} value={addDetail.complete_date} onChange={e => handleChangeAdd(e)} />
                                </div>
                                <div className='widget-add-field'>
                                    <p className='widget-add-field-float-name'>{"Loop Repeat (30 max)"}</p>
                                    <input className='widget2-input' type="number" placeholder='1' autoComplete='off' value={postScheduleLoop.repeat} onChange={e => setPostScheduleLoop(p => { return { ...p, repeat: e.target.value } })} onWheel={e => e.target.blur()}
                                        onBlur={e => {
                                            let temp_count = e.target.value

                                            if (!temp_count || Number(temp_count) < 1) {
                                                temp_count = '1'
                                            } else if (Number(temp_count) > 30) {
                                                temp_count = '30'
                                            } else {
                                                temp_count = Number(temp_count)?.toFixed(0)
                                            }

                                            setPostScheduleLoop(p => { return { ...p, repeat: temp_count } })
                                        }}
                                    />
                                </div>
                                <div className='widget-add-field'>
                                    <p className='widget-add-field-float-name'>{"Loop Every ** Days (10 max)"}</p>
                                    <input className='widget2-input' type="number" placeholder='1' autoComplete='off' value={postScheduleLoop.days} onChange={e => setPostScheduleLoop(p => { return { ...p, days: e.target.value } })} onWheel={e => e.target.blur()}
                                        onBlur={e => {
                                            let temp_count = e.target.value

                                            if (!temp_count || Number(temp_count) < 1) {
                                                temp_count = '1'
                                            } else if (Number(temp_count) > 10) {
                                                temp_count = '10'
                                            } else {
                                                temp_count = Number(temp_count)?.toFixed(0)
                                            }

                                            setPostScheduleLoop(p => { return { ...p, days: temp_count } })
                                        }}
                                    />
                                </div>
                                <div className='widget-add-field'>
                                    <p className='widget-add-field-float-name'>{"Client (For Revenue)"}</p>
                                    <div className='widget2-select' style={{ display: 'flex', alignItems: 'center' }}>{addDetail.client}</div>
                                </div>
                                <div className='widget-add-field'>
                                    <p className='widget-add-field-float-name'>{"ID (For Revenue)"}</p>
                                    <div className='widget2-select hide-scrollbar' title={addDetail.url} style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>{addDetail.url}</div>
                                </div>
                                <button className='widget2-button' style={{ background: '#4caf50' }} onClick={e => handleSubmitAdd(e)}>SUBMIT</button>
                                <button className='widget2-button' style={{ background: '#9400D3' }} onClick={() => setGlobal(p => { return { ...p, display_form: 'create-link' } })}>Create Link</button>
                            </>
                        )}
                    </div>
                </div>
            </div>

            {/* widget page */}
            <div id="widget-table-container-scroll" className="widget-table-container">
                <table className="widget-table">
                    <thead>
                        <tr>
                            <th>
                                {(bulk.length > 0) ? (
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                        <div style={{ height: '30px', width: '30px', background: '#FFF', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #EF5350', borderRadius: '5px', cursor: 'pointer' }}
                                            onMouseEnter={e => e.currentTarget.style.opacity = '50%'}
                                            onMouseLeave={e => e.currentTarget.style.opacity = '100%'}
                                            onClick={() => setBulk([])}
                                        >
                                            <FaXmark size={16} color='#EF5350'/>
                                        </div>
                                        <div style={{ height: '30px', width: '30px', background: '#EF5350', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #EF5350', borderRadius: '5px', cursor: 'pointer' }}
                                            onMouseEnter={e => e.currentTarget.style.opacity = '50%'}
                                            onMouseLeave={e => e.currentTarget.style.opacity = '100%'}
                                            onClick={() => handleBulkWidgetDelete({ setLoading, dataToDelete: bulk.map(e => { return { campaign_name: e } }), setBulk, setRefresh, appContext })}
                                        >
                                            <FaTrashCan size={16} color='#FFF'/>
                                        </div>
                                    </div>
                                ) : (
                                    "Select"
                                )}
                            </th>
                            <th>Action</th>
                            <th style={{ minWidth: '120px', maxWidth: '120px' }}>Campaign</th>
                            {(global.widget_case !== 'Post Creation') && (
                                <th style={{ minWidth: '120px', maxWidth: '120px' }}>{(global.widget_case === 'Country') ? 'Country' : 'Language'}</th>
                            )}
                            <th style={{ minWidth: '200px', maxWidth: '200px' }}>Topic</th>
                            <th style={{ minWidth: '200px', maxWidth: '200px' }}>Fan Page</th>
                            {(global.widget_case === 'Post Creation') && (
                                <>
                                    <th style={{ minWidth: '200px', maxWidth: '200px' }}>Ad Text</th>
                                    <th style={{ minWidth: '200px', maxWidth: '200px' }}>Comment</th>
                                    <th style={{ minWidth: '120px', maxWidth: '120px' }}>Media</th>
                                    <th style={{ minWidth: '170px', maxWidth: '170px' }}>Posts</th>
                                    <th style={{ minWidth: '170px', maxWidth: '170px' }}>Schedule</th>
                                </>
                            )}
                            {((global.widget_case === 'Post Creation') || (global.widget_case === 'Country')) && (
                                <th style={{ minWidth: '150px', maxWidth: '150px' }}>Client</th>
                            )}
                            <th style={{ minWidth: '200px', maxWidth: '200px' }}>{(global.widget_case === 'Post Creation') ? 'Client ID' : 'URL'}</th>
                            <th style={{ minWidth: '200px', maxWidth: '200px' }}>{(global.widget_case === 'Post Creation') ? 'Link' : 'Final URL'}</th>
                            {(global.widget_case === 'Post Creation') && (
                                <th style={{ minWidth: '200px', maxWidth: '200px' }}>Final URL</th>
                            )}
                        </tr>
                    </thead>

                    <tbody>
                        {data.widget.map((fd, i) => {
                            return (
                                <tr key={i} style={{ background: (fd.finished) ? '#C9E7CB' : '#FFF176', cursor: 'zoom-in' }}
                                    onMouseEnter={e => e.currentTarget.style.background = '#EEE'}
                                    onMouseLeave={e => e.currentTarget.style.background = (fd.finished) ? '#C9E7CB' : '#FFF176'}
                                >
                                    <td style={{ background: '#EEEEEE', cursor: 'default' }}>
                                        <div className="widget-table-cell" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <input type="checkbox" style={{ height: '30px', width: '30px', cursor: "pointer" }}
                                                checked={bulk.find(f => f === fd.widget) ? true : false}
                                                onChange={e => {
                                                    if (bulk.find(f => f === fd.widget)) {
                                                        setBulk(p => { return p.filter(f => f !== fd.widget) })
                                                    } else {
                                                        setBulk(p => { return [ ...p, fd.widget ] })
                                                    }
                                                }}
                                            />
                                        </div>
                                    </td>
                                    <td style={{ background: '#EEEEEE', cursor: 'default' }}>
                                        <div className="widget-table-cell">
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', gap: '10px' }}>
                                                    {(fd.case === "All" || fd.case === "Country") && (
                                                        <div style={{ height: '30px', width: '30px', background: '#4CAF50', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', cursor: 'pointer' }}
                                                            onMouseEnter={e => e.currentTarget.style.opacity = '50%'}
                                                            onMouseLeave={e => e.currentTarget.style.opacity = '100%'}
                                                            onClick={e => handleSelectRowEdit({e, detail: fd})}
                                                        >
                                                            <FaPencil size={16} color='#FFF'/>
                                                        </div>
                                                    )}
                                                    {(fd.case === "Post Creation") && (
                                                        <>
                                                            <div style={{ height: '30px', width: '30px', background: '#555', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', cursor: 'pointer' }}
                                                                onMouseEnter={e => e.currentTarget.style.opacity = '50%'}
                                                                onMouseLeave={e => e.currentTarget.style.opacity = '100%'}
                                                                onClick={() => setAddDetail(p => {
                                                                    return {
                                                                        ...p,
                                                                        message: fd?.message?.[0] ? [fd?.message?.[0]] : [''],
                                                                        picture: fd?.picture?.[0] ? [fd?.picture?.[0]] : [''],
                                                                        comment: fd.comment ? fd.comment : '',
                                                                        ad_media: fd?.picture?.[0] ? [{ type: 'image', url: fd?.picture?.[0] }] : [{}],
                                                                        complete_date: moment().format('YYYY-MM-DDTHH:mm'),
                                                                    }
                                                                })}
                                                            >
                                                                <FaCopy size={16} color='#FFF' />
                                                            </div>
                                                            <div style={{ height: '30px', width: '30px', background: '#4CAF50', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', cursor: 'pointer' }}
                                                                onMouseEnter={e => e.currentTarget.style.opacity = '50%'}
                                                                onMouseLeave={e => e.currentTarget.style.opacity = '100%'}
                                                                onClick={() => setPostReschedule(p => {
                                                                    let temp_date = moment().add(30, 'minutes').format('YYYY-MM-DDTHH:mm')
                                                                    let sorted_schedules = Array.isArray(fd?.result?.posts) ? fd?.result?.posts?.filter(f => f?.post_id)?.map(m => m?.schedule)?.sort((a, b) => moment(b).diff(moment(a))) : []

                                                                    if (moment(sorted_schedules?.[0]).isAfter()) {
                                                                        temp_date = moment(sorted_schedules?.[0]).add(1, 'day').format('YYYY-MM-DDTHH:mm')
                                                                    }

                                                                    return {
                                                                        ...p,
                                                                        data: { ...fd },
                                                                        display: true,
                                                                        repeat: '1',
                                                                        days: '1',
                                                                        result: {},
                                                                        message: fd?.message?.[0] ? fd?.message?.[0] : "",
                                                                        comment: fd.comment ? fd.comment : "",
                                                                        complete_date: temp_date
                                                                    }
                                                                })}
                                                            >
                                                                <TbCalendarTime size={16} color='#FFF'/>
                                                            </div>
                                                        </>
                                                    )}
                                                    <div style={{ height: '30px', width: '30px', background: '#EF5350', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', cursor: 'pointer' }}
                                                        onMouseEnter={e => e.currentTarget.style.opacity = '50%'}
                                                        onMouseLeave={e => e.currentTarget.style.opacity = '100%'}
                                                        onClick={() => handleBulkWidgetDelete({ setLoading, dataToDelete: [{ campaign_name: fd.widget }], setBulk, setRefresh, appContext })}
                                                    >
                                                        <FaTrashCan size={16} color='#FFF'/>
                                                    </div>
                                                </div>
                                                <div style={{ width: '100%', display: 'grid', gridAutoFlow: 'column', gap: '5px', justifyContent: 'center' }}>
                                                    {fd.finished ? (
                                                        <div className="rulesSwitch" style={{background: '#1976d2', cursor: 'not-allowed' }}>
                                                            <div className="rulesSwitchButton" style={{left: '22.5px'}}/>
                                                        </div>
                                                    ) : (
                                                        <div className="rulesSwitch" onClick={() => handleChangeSwitch({ fd, finished: true, result: {} })} style={{background: '#98989d', pointerEvents: 'all', cursor: 'pointer' }}>
                                                            <div className="rulesSwitchButton" style={{left: '2.5px'}}/>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ minWidth: '120px', maxWidth: '120px' }} onClick={() => setGlobal(p => { return { ...p, expand_row: (p.expand_row === fd.widget) ? null : fd.widget } })}>
                                        <div className="widget-table-cell" style={{ display: 'grid', gridAutoFlow: 'column', gridAutoColumns: '1fr min-content' }}>
                                            <p>{fd.widget}</p>
                                            <FaCopy size={24} color='#555555' style={{ cursor: 'pointer' }} onMouseEnter={e => e.currentTarget.style.opacity = 0.5} onMouseLeave={e => e.currentTarget.style.opacity = 1} onClick={() => {navigator.clipboard.writeText(fd.widget)}} />
                                        </div>
                                    </td>
                                    {(fd.case !== 'Post Creation') && (
                                        <td style={{ minWidth: '120px', maxWidth: '120px' }} onClick={() => setGlobal(p => { return { ...p, expand_row: (p.expand_row === fd.widget) ? null : fd.widget } })}>
                                            {(fd.country) ? (
                                                <>
                                                    {(global.expand_row === fd.widget) ? (
                                                        <div className="widget-table-cell">
                                                            <p className='widget-table-cell-hover' style={{ whiteSpace: 'pre-line' }}>{Array.isArray(fd.country) ? fd.country.join(" & ") : fd.country.replaceAll(',', ' & ')}</p>
                                                        </div>
                                                    ) : (
                                                        <div className="widget-table-cell">
                                                            <p className='widget-table-cell-hover' style={{ whiteSpace: 'pre-line' }}>
                                                                {Array.isArray(fd.country) ? (
                                                                    (fd.country.join(" & ").length > 40) ? fd.country.join(" & ").substring(0, 40)+'...' : fd.country.join(" & ")
                                                                ) : (
                                                                    (fd.country.length > 40) ? fd.country.replaceAll(',', ' & ').substring(0, 40)+'...' : fd.country.replaceAll(',', ' & ')

                                                                )}
                                                            </p>
                                                        </div>
                                                    )}
                                                </>
                                            ) : (<></>)}
                                        </td>
                                    )}
                                    <td style={{ minWidth: '200px', maxWidth: '200px' }} onClick={() => setGlobal(p => { return { ...p, expand_row: (p.expand_row === fd.widget) ? null : fd.widget } })}><div className="widget-table-cell">{fd.topic}</div></td>
                                    <td style={{ minWidth: '200px', maxWidth: '200px' }} onClick={() => setGlobal(p => { return { ...p, expand_row: (p.expand_row === fd.widget) ? null : fd.widget } })}>
                                        <div className="widget-table-cell">
                                            {data.fb_fan_pages_post?.find(f => f?.id === fd?.facebook_fan_page)?.name}
                                        </div>
                                    </td>
                                    {(fd?.case === 'Post Creation') && (
                                        <>
                                            <td style={{ minWidth: '200px', maxWidth: '200px' }} onClick={() => setGlobal(p => { return { ...p, expand_row: (p.expand_row === fd.widget) ? null : fd.widget } })}>
                                                <div className="widget-table-cell">
                                                    {fd?.message?.[0] ? (
                                                        <>
                                                            {(global.expand_row === fd.widget) ? (
                                                                <div className="widget-table-cell">
                                                                    <p className='widget-table-cell-hover' style={{ whiteSpace: 'pre-line' }}>{fd?.message?.[0]}</p>
                                                                </div>
                                                            ) : (
                                                                <div className="widget-table-cell">
                                                                    <p className='widget-table-cell-hover' style={{ whiteSpace: 'pre-line' }}>{(fd?.message?.[0].length > 40) ? fd?.message?.[0].substring(0, 40)+'...' : fd?.message?.[0]}</p>
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (<></>)}
                                                </div>
                                            </td>
                                            <td style={{ minWidth: '200px', maxWidth: '200px' }} onClick={() => setGlobal(p => { return { ...p, expand_row: (p.expand_row === fd.widget) ? null : fd.widget } })}>
                                                <div className="widget-table-cell">
                                                    {fd.comment ? (
                                                        <>
                                                            {(global.expand_row === fd.widget) ? (
                                                                <div className="widget-table-cell">
                                                                    <p className='widget-table-cell-hover' style={{ whiteSpace: 'pre-line' }}>{fd.comment}</p>
                                                                </div>
                                                            ) : (
                                                                <div className="widget-table-cell">
                                                                    <p className='widget-table-cell-hover' style={{ whiteSpace: 'pre-line' }}>{(fd.comment.length > 40) ? fd.comment.substring(0, 40)+'...' : fd.comment}</p>
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (<></>)}
                                                </div>
                                            </td>
                                            <td style={{ minWidth: '120px', maxWidth: '120px' }} onClick={() => setGlobal(p => { return { ...p, expand_row: (p.expand_row === fd.widget) ? null : fd.widget } })}>
                                                {(fd?.picture?.[0]) ? (
                                                    <div className="widget-table-cell" style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', width: '100%', gap:'5px', cursor: 'pointer' }}>
                                                        <img src={fd?.picture?.[0]} alt="" style={{ height: '40px', width: '100%', objectFit: 'contain' }}/>
                                                    </div>
                                                ) : (<></>)}
                                            </td>
                                            <td style={{ minWidth: '170px', maxWidth: '170px' }} onClick={() => setGlobal(p => { return { ...p, expand_row: (p.expand_row === fd.widget) ? null : fd.widget } })}>
                                                <div className="widget-table-cell" style={{ fontSize: '14px' }}>
                                                    <ul style={{ listStyle: 'inside' }}>
                                                        {(fd.result && fd.result.posts) && (
                                                            fd?.result?.posts?.
                                                            filter(f => (global.expand_row !== fd?.widget) ? (f?.post_id && moment(f?.schedule).isAfter()) : f?.post_id)?.
                                                            sort((a, b) => moment(a?.schedule).diff(moment(b?.schedule)))?.
                                                            slice(0, (global.expand_row === fd.widget) ? fd?.result?.posts?.length : 5)?.
                                                            map(m => (
                                                                (global.expand_row === fd?.widget) ? (
                                                                    <li style={{ color: moment(m?.schedule).isBefore() ? '#FF5F5F' : '#1976D2' }}>
                                                                        <a href={`https://www.facebook.com/${m?.post_id}`} target="_blank"
                                                                            style={{ fontSize: '12px', color: moment(m?.schedule).isBefore() ? '#FF5F5F' : '#1976D2', textDecoration: 'none', textUnderlineOffset: '2px', wordBreak: 'break-all' }}
                                                                            onMouseEnter={e => e.currentTarget.style.textDecoration = 'underline'}
                                                                            onMouseLeave={e => e.currentTarget.style.textDecoration = 'none'}
                                                                        >
                                                                            {m?.post_id}
                                                                        </a>
                                                                    </li>
                                                                ) : (
                                                                    <li style={{ fontSize: '12px', wordBreak: 'break-all' }}>{m?.post_id}</li>
                                                                )
                                                            ))
                                                        )}
                                                    </ul>
                                                    {((fd?.result?.posts?.length > 5) && (global.expand_row !== fd?.widget)) ? '...' : ''}
                                                </div>
                                            </td>
                                            <td style={{ minWidth: '170px', maxWidth: '170px' }} onClick={() => setGlobal(p => { return { ...p, expand_row: (p.expand_row === fd.widget) ? null : fd.widget } })}>
                                                <div className="widget-table-cell" style={{ fontSize: '14px' }}>
                                                    <ul style={{ listStyle: 'none' }}>
                                                        {(fd.result && fd.result.posts) && (
                                                            fd?.result?.posts?.
                                                            filter(f => (global.expand_row !== fd?.widget) ? (f?.post_id && moment(f?.schedule).isAfter()) : f?.post_id)?.
                                                            sort((a, b) => moment(a?.schedule).diff(moment(b?.schedule)))?.
                                                            slice(0, (global.expand_row === fd.widget) ? fd?.result?.posts?.length : 5)?.
                                                            map(m => (
                                                                (global.expand_row === fd?.widget) ? (
                                                                    <li style={{ fontSize: '12px', color: moment(m?.schedule).isBefore() ? '#FF5F5F' : '#1976D2' }}>{moment(m?.schedule).format('YYYY-MM-DD, hh:mm a')}</li>
                                                                ) : (
                                                                    <li style={{ fontSize: '12px' }}>{moment(m?.schedule).format('YYYY-MM-DD, hh:mm a')}</li>
                                                                )
                                                            ))
                                                        )}
                                                    </ul>
                                                    {((fd?.result?.posts?.length > 5) && (global.expand_row !== fd?.widget)) ? '...' : ''}
                                                </div>
                                            </td>
                                        </>
                                    )}
                                    {((fd?.case === 'Post Creation') || (fd?.case === 'Country')) && (
                                        <td style={{ minWidth: '150px', maxWidth: '150px' }} onClick={() => setGlobal(p => { return { ...p, expand_row: (p.expand_row === fd.widget) ? null : fd.widget } })}>
                                            <div className="widget-table-cell">
                                                {fd?.client}
                                            </div>
                                        </td>
                                    )}
                                    <td style={{ minWidth: '200px', maxWidth: '200px' }} onClick={() => setGlobal(p => { return { ...p, expand_row: (p.expand_row === fd.widget) ? null : fd.widget } })}>
                                        <div className="widget-table-cell">
                                            {(fd.case === 'Post Creation') ? (
                                                (global.expand_row === fd.widget) ? (
                                                    <p className='widget-table-cell-hover' style={{ whiteSpace: 'pre-line' }}>{fd?.url}</p>
                                                ) : (
                                                    <p className='widget-table-cell-hover' style={{ whiteSpace: 'pre-line' }}>{(fd?.url?.length > 40) ? fd?.url?.substring(0, 40)+'...' : fd?.url}</p>
                                                )
                                            ) : (
                                                (global.expand_row === fd.widget) ? (
                                                    (fd.case === 'All') ? (
                                                        <div className="widget-table-cell">
                                                            {fd?.url?.url && (
                                                                Object.keys(fd?.url?.url).map((m, i) => {
                                                                    if (fd?.url?.url?.[m]) {
                                                                        return (
                                                                            <p key={i} className='widget-table-cell-hover' style={{ whiteSpace: 'pre-line', fontSize: '14px' }}>
                                                                                {m}: {`(${fd?.url?.action?.[m]?.['%']}%)`}<br/>
                                                                                {fd?.url?.url?.[m]}<br/><br/>
                                                                            </p>
                                                                        )
                                                                    } else {
                                                                        return <></>
                                                                    }
                                                                })
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div className="widget-table-cell">
                                                            {fd?.url?.url && (
                                                                Object.keys(fd?.url?.url).map((m, i) => {
                                                                    if (fd?.url?.url?.[m]) {
                                                                        return (
                                                                            <p key={i} className='widget-table-cell-hover' style={{ whiteSpace: 'pre-line', fontSize: '14px' }}>
                                                                                {m}: {fd?.url?.url?.[m]}<br/><br/>
                                                                            </p>
                                                                        )
                                                                    } else {
                                                                        return <></>
                                                                    }
                                                                })
                                                            )}
                                                        </div>
                                                    )
                                                ) : (
                                                    <div className="widget-table-cell">
                                                        <p className='widget-table-cell-hover' style={{ whiteSpace: 'pre-line' }}>{(JSON.stringify(fd?.url)?.length > 40) ? JSON.stringify(fd?.url)?.substring(0, 40)+'...' : JSON.stringify(fd?.url)}</p>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </td>
                                    <td style={{ minWidth: '200px', maxWidth: '200px' }} onClick={() => setGlobal(p => { return { ...p, expand_row: (p.expand_row === fd.widget) ? null : fd.widget } })}>
                                        {((fd.case === 'All') || (fd.case === 'Country')) ? (
                                            fd.final_url.includes(fd?.widget?.toString()) ? (
                                                <div className="widget-table-cell" style={{ display: 'grid', gridAutoFlow: 'column', gridAutoColumns: '1fr min-content', wordBreak: 'break-all' }}>
                                                    {fd.final_url}
                                                    <FaCopy size={24} color='#555555' style={{ cursor: 'pointer' }} onClick={() => {navigator.clipboard.writeText(fd.final_url)}} />
                                                </div>
                                            ) : (
                                                <div className="widget-table-cell" style={{ display: 'grid', gridAutoFlow: 'column', gridAutoColumns: '1fr min-content', wordBreak: 'break-all' }}>
                                                    {fd.final_url + fd?.widget?.toString()}
                                                    <FaCopy size={24} color='#555555' style={{ cursor: 'pointer' }} onClick={() => {navigator.clipboard.writeText(fd.final_url + fd?.widget?.toString())}} />
                                                </div>
                                            )
                                        ) : (
                                            <div className="widget-table-cell" style={{ display: 'grid', gridAutoFlow: 'column', gridAutoColumns: '1fr min-content' }}>
                                                {(global.expand_row === fd.widget) ? (
                                                    <p className='widget-table-cell-hover'>{fd.final_url}</p>
                                                ) : (
                                                    <p className='widget-table-cell-hover'>{(fd.final_url.length > 40) ? fd.final_url.substring(0, 40)+'...' : fd.final_url}</p>
                                                )}
                                            </div>
                                        )}
                                    </td>
                                    {(fd?.case === 'Post Creation') && (
                                        <td style={{ minWidth: '200px', maxWidth: '200px' }} onClick={() => setGlobal(p => { return { ...p, expand_row: (p.expand_row === fd.widget) ? null : fd.widget } })}>
                                            <div className="widget-table-cell" style={{ display: 'grid', gridAutoFlow: 'column', gridAutoColumns: '1fr min-content', wordBreak: 'break-all' }}>
                                                https://esquiz.com/widget/{fd?.widget}
                                                <FaCopy size={24} color='#555555' style={{ cursor: 'pointer' }} onClick={() => {navigator.clipboard.writeText(`https://esquiz.com/widget/${fd?.widget}`)}} />
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default FFPPostsCreation



// settings CRUD api
const settingsCRUDapi = async (params) => {
    let result
    const token = Cookies.get("token");
    const url = `${host}/api/facebook_page/topic`;

    try {
        const response = await axios.post(url, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
            },
            action: params.action,
            rules: params.rules,
            filter: params.filter,
            update: params.update
        })

        result = response.data
    } catch (error) {
        console.log(error)
        window.alert(error)
    }

    return result
}

// handle SUBMIT click for settings
const handleSubmitSettings = async ({ settings, setSettings, setGlobal, appContext }) => {
    if (!settings.topic.trim() || !settings.link.trim() || !settings.facebook_page_id.trim() || !settings.client.trim() || !settings.client_id.trim()) {
        appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "All fields required.", content: "" }], confirm_function: () => {}, cancel_function: () => {} }})
        return
    }

    setSettings(p => { return { ...p, loading: true } })
    
    const validate_link = await settingsCRUDapi({
            action: "read_one",
            rules: { link: settings.link.trim() },
            filter: {},
            update: {}
        })

    if (validate_link.result && (validate_link.result.link === settings.link.trim())) {
        appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Same link found", content: "The input link is already present in the database. Please use another one." }], confirm_function: () => {}, cancel_function: () => {} }})
        setSettings(p => { return { ...p, loading: false } })
        return
    }

    const create_setting = await settingsCRUDapi({
        action: "create_one",
        rules: {},
        filter: {},
        update: { topic: settings.topic.trim(), link: settings.link.trim(), link_type: "Post", facebook_page_id: settings.facebook_page_id, facebook_page_name: settings.facebook_page_name, client: settings.client, client_id: settings.client_id }
    })

    if (create_setting.result && (create_setting?.result?.acknowledged === true)) {
        setSettings(p => { return { ...p, loading: false, refresh: !p.refresh, topic: '', link: '', facebook_page_id: '', facebook_page_name: '', client: '', client_id: '' } })
        setGlobal(p => { return { ...p, display_form: '' } })
    } else {
        setSettings(p => { return { ...p, loading: false } })
        appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Failed", content: "Please try again." }], confirm_function: () => {}, cancel_function: () => {} }})
    }
}

// API to create new post in the facebook fan page
const createPostAPI = async ({ item, postScheduleLoop, fb_fan_pages_post, setUpdateLoading, appContext, settings, addDetail, updateCampaignAPI }) => {
    setUpdateLoading(true)
    const token = Cookies.get('token')
    const url_create_widget = `${host}/api/index/create`;
    const url_schedule_post = `${host}/api/facebook_page/post`;
    const url_schedule_comment = `${host}/api/facebook_page/comment`;
    let find = fb_fan_pages_post.find(f => item.facebook_fan_page === f.id)
    let alert_msg = { success: [], error: [] }
    let post_result = []
    let schedule_list = []
    let schedule_days_count = 0

    if (!find) {
        appContext.setDialogInfo(p => {
            return {
                ...p,
                mode: "Alert",
                type: "Error",
                message: [ { type: "Error", title: "Fan page ID and TOKEN missing", content: "Try reloading the page to get fresh ID and TOKEN. If the same error persists, contact the developers." } ],
                confirm_function: () => {},
                cancel_function: () => {}
            }
        })
        setUpdateLoading(false)
        return
    }

    for (let i = 0; i < postScheduleLoop.repeat; i++) {
        schedule_list = [ ...schedule_list, moment(item.complete_date).add(schedule_days_count, 'days').format('YYYY-MM-DDTHH:mm') ]
        schedule_days_count = schedule_days_count + Number(postScheduleLoop.days)
    }

    const find_topic = settings.data.find(f => (f.facebook_page_id === addDetail.facebook_fan_page) && (f.link_type === 'Post') && (f.link === addDetail.topic))
    if (!find_topic) {
        window.alert('Can not find the topic link! Please try again. If the problem persist, contact the developers.')
        setUpdateLoading(false)
        return
    }

    let create_widget_response = await axios.post(url_create_widget, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type, x-access-token",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
          "Authorization": "Bearer "+token,
          "Content-Type": "application/json",
        },
        data: {
            finished: false,
            topic: find_topic?.topic ? find_topic?.topic : '',
            client: addDetail.client,
            country: addDetail.country,
            message: addDetail.message,
            comment: addDetail.comment.trimStart(),
            ad_media: addDetail.ad_media,
            picture: addDetail.picture,
            facebook_fan_page: addDetail.facebook_fan_page,
            complete_date: addDetail.complete_date,
            url: addDetail.url,
            final_url: find_topic?.link ? find_topic?.link : '',
            result: {},
            case: 'Post Creation',
            username: 'Southpow'
        }
    })

    if (!create_widget_response?.data?.newIndex) {
        window.alert('Failed to create new widget')
        setUpdateLoading(false)
        return
    }

    let schedule_post_response = await Promise.all(schedule_list.map(async schedule => {
        try {
            const response = await axios.post(url_schedule_post, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                    "Authorization": "Bearer "+token,
                    "Content-Type": "application/json",
                },
                id: find.id,
                token: find.token, 
                message: item.message[0] + "\n" + "https://esquiz.com/widget/" + create_widget_response.data.newIndex.toString(),
                image_url: item.ad_media[0].url,
                dateTime: moment(schedule).unix()
            })

            const obj = response.data

            if (obj.result) {
                if (obj.result.id) {
                    alert_msg.success = [ ...alert_msg.success, moment(schedule).format('YYYY-MM-DD hh:mm a') ]
                } else {
                    alert_msg.error = [ ...alert_msg.error, { date: moment(schedule).format('YYYY-MM-DD hh:mm a'), message: obj?.result?.error?.message ? obj?.result?.error?.message : 'Error from facebook!' } ]
                }
            } else {
                alert_msg.error = [ ...alert_msg.error, { date: moment(schedule).format('YYYY-MM-DD hh:mm a'), message: 'Error from facebook!' } ]
            }

            post_result = [
                ...post_result,
                {
                    post_id: obj?.result?.id ? obj?.result?.id : null,
                    schedule: schedule
                }
            ]
        } catch (error) {
            console.log(error);
            window.alert(error);
            post_result = [
                ...post_result,
                {
                    post_id: null,
                    schedule: schedule
                }
            ]
        }
    }))

    if (addDetail.comment.trim()) {
        let schedule_comment_response = await Promise.all(post_result.map(async schedule => {
            if (schedule.post_id && (schedule.post_id !== null)) {
                try {
                    const response = await axios.post(url_schedule_comment, {
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                            "Authorization": "Bearer "+token,
                            "Content-Type": "application/json",
                        },
                        post_id: schedule.post_id,
                        token: find.token, 
                        message: addDetail.comment + "\n" + "https://esquiz.com/widget/" + create_widget_response.data.newIndex.toString(),
                    })
                } catch (error) {
                    console.log(error);
                    window.alert(error);
                }
            }
        }))
    }

    setUpdateLoading(false)

    if (
        (alert_msg.success.length > 0) ||
        (alert_msg.error.length > 0)
    ) {
        if (alert_msg.success.length > 0) {
            const data_to_update = {
                _id: create_widget_response.data.result.insertedId,
                widget: create_widget_response.data.newIndex,
                finished: true,
                result: { posts: post_result }
            }
            updateCampaignAPI(data_to_update)
        }

        let dialogue_message = []
        dialogue_message.push({ type: "Success", title: (alert_msg.success.length > 0) ? "Successful Post Schedules" : "", content: (alert_msg.success.length > 0) ? alert_msg.success.join(",   ") : "" })
        if (alert_msg.error.length > 0) {
            alert_msg.error.sort((a, b) => moment(a.date).diff(moment(b.date))).map(e => dialogue_message.push({ type: "Error", title: e.date + " ~ Failed Post Schedule", content: e.message }))
        }

        appContext.setDialogInfo(p => {
            return {
                ...p,
                mode: "Alert",
                type: (alert_msg.error.length === 0) ? (
                    "Success"
                ) : (
                    (alert_msg.success.length === 0) ? (
                        "Error"
                    ) : (
                        "Warning"
                    )
                ),
                message: dialogue_message,
                confirm_function: () => {},
                cancel_function: () => {}
            }
        })
    }
}

// API to reschedule post in the facebook fan page
const reschedulePostAPI = async ({ postReschedule, fb_fan_pages_post, setUpdateLoading, appContext, updateCampaignAPI }) => {
    setUpdateLoading(true)
    const token = Cookies.get('token')
    const url_schedule_post = `${host}/api/facebook_page/post`;
    const url_schedule_comment = `${host}/api/facebook_page/comment`;
    const item = postReschedule.data
    let find = fb_fan_pages_post.find(f => item.facebook_fan_page === f.id)
    let alert_msg = { success: [], error: [] }
    let post_result = postReschedule?.data?.result?.posts ?  [ ...postReschedule?.data?.result?.posts ] : []
    let post_result_for_comment = []
    let schedule_list = []
    let schedule_days_count = 0

    if (!find) {
        appContext.setDialogInfo(p => {
            return {
                ...p,
                mode: "Alert",
                type: "Error",
                message: [ { type: "Error", title: "Fan page ID and TOKEN missing", content: "Try reloading the page to get fresh ID and TOKEN. If the same error persists, contact the developers." } ],
                confirm_function: () => {},
                cancel_function: () => {}
            }
        })
        setUpdateLoading(false)
        return
    }

    for (let i = 0; i < postReschedule.repeat; i++) {
        schedule_list = [ ...schedule_list, moment(postReschedule.complete_date).add(schedule_days_count, 'days').format('YYYY-MM-DDTHH:mm') ]
        schedule_days_count = schedule_days_count + Number(postReschedule.days)
    }

    let create_post_response = await Promise.all(schedule_list.map(async schedule => {
        try {
            const response = await axios.post(url_schedule_post, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                    "Authorization": "Bearer "+token,
                    "Content-Type": "application/json",
                },
                id: find.id,
                token: find.token, 
                message: postReschedule.message + "\n" + "https://esquiz.com/widget/" + item.widget.toString(),
                image_url: item.ad_media[0].url,
                dateTime: moment(schedule).unix()
            })

            const obj = response.data

            if (obj.result) {
                if (obj.result.id) {
                    alert_msg.success = [ ...alert_msg.success, moment(schedule).format('YYYY-MM-DD hh:mm a') ]
                } else {
                    alert_msg.error = [ ...alert_msg.error, { date: moment(schedule).format('YYYY-MM-DD hh:mm a'), message: obj?.result?.error?.message ? obj?.result?.error?.message : 'Error from facebook!' } ]
                }
            } else {
                alert_msg.error = [ ...alert_msg.error, { date: moment(schedule).format('YYYY-MM-DD hh:mm a'), message: 'Error from facebook!' } ]
            }

            post_result = [
                ...post_result,
                {
                    post_id: obj?.result?.id ? obj?.result?.id : null,
                    schedule: schedule
                }
            ]
            post_result_for_comment = [
                ...post_result_for_comment,
                {
                    post_id: obj?.result?.id ? obj?.result?.id : null,
                    schedule: schedule
                }
            ]
        } catch (error) {
            console.log(error);
            window.alert(error);
            post_result = [
                ...post_result,
                {
                    post_id: null,
                    schedule: schedule
                }
            ]
            post_result_for_comment = [
                ...post_result_for_comment,
                {
                    post_id: null,
                    schedule: schedule
                }
            ]
        }
    }))

    if (postReschedule?.comment?.trim()) {
        let schedule_comment_response = await Promise.all(post_result_for_comment.map(async schedule => {
            if (schedule.post_id && (schedule.post_id !== null)) {
                try {
                    const response = await axios.post(url_schedule_comment, {
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                            "Authorization": "Bearer "+token,
                            "Content-Type": "application/json",
                        },
                        post_id: schedule.post_id,
                        token: find.token, 
                        message: postReschedule.comment + "\n" + "https://esquiz.com/widget/" + item.widget.toString(),
                    })
                } catch (error) {
                    console.log(error);
                    window.alert(error);
                }
            }
        }))
    }

    setUpdateLoading(false)

    if (
        (alert_msg.success.length > 0) ||
        (alert_msg.error.length > 0)
    ) {
        if (alert_msg.success.length > 0) {
            const data_to_update = {
                _id: item._id,
                widget: item.widget,
                message: [ postReschedule.message ],
                comment: postReschedule.comment.trimStart(),
                result: { posts: post_result }
            }
            updateCampaignAPI(data_to_update)
        }

        let dialogue_message = []
        dialogue_message.push({ type: "Success", title: (alert_msg.success.length > 0) ? "Successful Post Schedules" : "", content: (alert_msg.success.length > 0) ? alert_msg.success.join(",   ") : "" })
        if (alert_msg.error.length > 0) {
            alert_msg.error.sort((a, b) => moment(a.date).diff(moment(b.date))).map(e => dialogue_message.push({ type: "Error", title: e.date + " ~ Failed Post Schedule", content: e.message }))
        }

        appContext.setDialogInfo(p => {
            return {
                ...p,
                mode: "Alert",
                type: (alert_msg.error.length === 0) ? (
                    "Success"
                ) : (
                    (alert_msg.success.length === 0) ? (
                        "Error"
                    ) : (
                        "Warning"
                    )
                ),
                message: dialogue_message,
                confirm_function: () => {},
                cancel_function: () => {}
            }
        })
    }
}

// delete widgets in bulk
const handleBulkWidgetDelete = async ({ setLoading, dataToDelete, setBulk, setRefresh, appContext }) => {
    appContext.setDialogInfo(p => {
        return {
            ...p,
            mode: "Confirm",
            type: "Delete",
            message: [
                { type: "Delete", title: `Delete the selected ${dataToDelete.length} items?`, content: "This will permanently delete the selected items from the system." },
            ],
            confirm_function: async () => {
                setLoading(p => { return { ...p, widget: true } })

                const token = Cookies.get("token");
                const url = `${host}/api/index/delete_widget`;
                // let temp_bulk = dataToDelete.map(e => { return { campaign_name: e } })

                axios.post(url, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                        "Authorization": "Bearer "+token,
                        "Content-Type": "application/json",
                    },
                    data: dataToDelete
                })
                .then((response) => {
                    console.log(response)
                    setBulk([])
                    setLoading(p => { return { ...p, widget: false } })
                    setRefresh(p => { return { ...p, widget: !p.widget } })
                })
                .catch((error) => {
                    console.log(error)
                    window.alert(error)
                    setLoading(p => { return { ...p, widget: false } })
                    if (error?.response?.data?.message === 'Invalid') {
                        appContext.forceLogout()
                    }
                })
            },
            cancel_function: () => {}
        }
    })
}

// API to upload image to a facebook account
const uploadImageAPI = async ({ images, fb_id, setUploadFbImage, fb_token, appContext }) => {
    if (!Array.isArray(images) || images?.length < 1 || !fb_id) {
        appContext.setDialogInfo(p => { return { ...p, mode: "Alert", type: "Error", message: [{ type: "Error", title: "Empty fields", content: "Facebook A/C and at least an image should be selected to upload." }], confirm_function: () => {}, cancel_function: () => {} }})
        return
    }

    setUploadFbImage(p => { return { ...p, loading: true } })

    const createAdImage = async (id, base64Image) => {
        let url = `https://graph.facebook.com/v19.0/${id}/adimages`;
        const response = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            bytes: base64Image,
            access_token: fb_token,
          }),
        });
      
        let data = await response.json();
        console.log(data);
        return data;
    };

    let result = await Promise.all(images.map(async item => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onloadend = async () => {
                try {
                    let data = await createAdImage(fb_id, reader.result.split('base64,')[1]);
                    resolve(data);
                } catch (error) {
                    console.log(error);
                    reject(error);
                }
            };
            reader.readAsDataURL(item);
        });
    }))

    setUploadFbImage(p => { return { ...p, loading: false, act_id: fb_id, refresh: !p.refresh } })
}

// function to render facebook image/video selection component
const FacebookMediaSelectComponent = ({ loading, fbMediaState, setFbMediaState, fb_images, fb_videos, addDetail, setAddDetail, global, uploadFbImage, setUploadFbImage, fb_token, appContext }) => {
    const [displayImage, setDisplayImage] = useState({ display: true, url: '', thumbnails: [] })

    const fb_images_filtered = (fb_images[fbMediaState.act_id] && Array.isArray(fb_images[fbMediaState.act_id])) ? fb_images[fbMediaState.act_id] : []
    const fb_videos_filtered = (fb_videos[fbMediaState.act_id] && Array.isArray(fb_videos[fbMediaState.act_id])) ? fb_videos[fbMediaState.act_id] : []

    // find selected images or videos length
    const returnMediaLength = () => {
        let result = { image: 0, video: 0, jsx: <></> }

        // type add
        if (fbMediaState.mode === 'add') {
            result = {
                image: addDetail.ad_media.filter(f => f.type === 'image').length,
                video: addDetail.ad_media.filter(f => f.type === 'video').length,
                jsx: <>
                    {addDetail.ad_media.find(f => f.type) ? (
                        addDetail.ad_media.filter(f => f.type).length + " / " + addDetail.ad_media.length + " selected"
                    ) : "None selected"}
                </>
            }
        }

        return result
    }
    
    // condition to know from where to clear media from
    const clearAllCondition = () => {
        // mode add
        if (fbMediaState.mode === 'add') {
            setAddDetail(p => { return { ...p, picture: Array(Number(p.adset_count)).fill(""), ad_media: Array(Number(p.adset_count)).fill({}) } })
        }
    }

    // if image or video is selected then return an absolute overlay
    const returnSelectedMedia = ({ type, item }) => {
        let result = <></>

        // mode add
        if (fbMediaState.mode === 'add') {
            // type image
            if (type === 'image') {
                result = <>
                    {addDetail.ad_media.find(f => (f.type === 'image') && (f.url === item.url)) && (
                        <div>
                            <div>{addDetail.ad_media.findIndex(obj => (obj.type === 'image') && (obj.url === item.url)) + 1}</div>
                        </div>
                    )}
                </>
            } else { // type video
                result = <>
                    {addDetail.ad_media.find(f => (f.type === 'video') && (f.video_id === item.id)) && (
                        <div onClick={() => clickCondition({ type: 'video', url: item.picture, video_id: item.id })}>
                            <div>{addDetail.ad_media.findIndex(obj => (obj.type === 'video') && (obj.video_id === item.id)) + 1}</div>
                        </div>
                    )}
                </>
            }
        }

        return result
    }

    // set media state depending on the type
    const clickCondition = ({ type, url, video_id }) => {
        // mode add
        if (fbMediaState.mode === 'add') {
            // type image
            if (type === 'image') {
                let temp = [ ...addDetail.picture ]
                let temp_media = [ ...addDetail.ad_media ]
                let find_item = temp_media.find(f => (f.type === 'image') && (f.url === url))
    
                if (find_item) {
                    temp.splice(temp.indexOf(url), 1)
                    temp.push('')
                    temp_media.splice(temp_media.findIndex(obj => (obj.type === 'image') && (obj.url === url)), 1)
                    temp_media.push({})
                } else {
                    let find_empty = temp_media.find(f => !f.type)
                    if (find_empty) {
                        temp[temp.indexOf('')] = url
                        temp_media[temp_media.findIndex(obj => !obj.type)] = {type: 'image', url: url}
                    }
                }
    
                setAddDetail(p => { return { ...p, picture: temp, ad_media: temp_media } })
            }
            // type video
            else {
                let temp = [ ...addDetail.picture ]
                let temp_media = [ ...addDetail.ad_media ]
                let find_item = temp_media.find(f => (f.type === 'video') && (f.video_id === video_id))
        
                if (find_item) {
                    temp.splice(temp.indexOf(url), 1)
                    temp.push('')
                    temp_media.splice(temp_media.findIndex(obj => (obj.type === 'video') && (obj.video_id === video_id)), 1)
                    temp_media.push({})
                } else {
                    let find_empty = temp_media.find(f => !f.type)
                    if (find_empty) {
                        temp[temp.indexOf('')] = url
                        temp_media[temp_media.findIndex(obj => !obj.type)] = {type: 'video', url: url, video_id: video_id}
                    }
                }
        
                setAddDetail(p => { return { ...p, picture: temp, ad_media: temp_media } })
            }
        }
    }

    return (
        <div className='widget-fb-media-container' style={{ display: fbMediaState.display ? 'flex' : 'none' }}>
            <div className='widget-fb-media-scroll'>
                <div className='widget-fb-media'>

                    <div className='widget-fb-media-top'>
                        <div className='widget-fb-media-top-navigate'>
                            <div style={{ gridTemplateColumns: ((fbMediaState.mode === 'add') && (global.widget_case === "Post Creation")) ? '1fr' : '1fr 1fr' }}>
                                <div style={{ background: displayImage.display ? '#000' : '#EEE' }} onClick={() => setDisplayImage(p => { return { ...p, display: true, url: '', thumbnails: [] } })}>
                                    <div style={{ color: displayImage.display ? '#000' : '#777' }}>{returnMediaLength().image}</div>
                                    <div style={{ color: displayImage.display ? '#FFF' : '#777' }}>Images</div>
                                </div>
                                {((fbMediaState.mode === 'add') && (global.widget_case === "Post Creation")) ? (
                                    <></>
                                ) : (
                                    <div style={{ background: displayImage.display ? '#EEE' : '#000' }} onClick={() => setDisplayImage(p => { return { ...p, display: false, url: '', thumbnails: [] } })}>
                                        <div style={{ color: displayImage.display ? '#777' : '#000' }}>{returnMediaLength().video}</div>
                                        <div style={{ color: displayImage.display ? '#777' : '#FFF' }}>Videos</div>
                                    </div>
                                )}
                            </div>
                            <div style={{ gridTemplateColumns: "1fr", alignItems: 'center', fontSize: '14px' }}>
                                {returnMediaLength().jsx}
                            </div>
                        </div>


                        <div className='widget-fb-media-top-close' onClick={() => setFbMediaState(p => { return { ...p, display: false, mode: '', act_id: '', widget: 0 } })}>
                            <FaXmark size={30} color={"#F00"} />
                        </div>
                    </div>

                    {displayImage.display ? (
                        <div className='widget-fb-media-bottom'>
                            <div className='widget-fb-media-bottom-left'>
                                {loading ? (
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <div className='loader' style={{ height: '100px', width: '100px' }}/>
                                    </div>
                                ) : (
                                    (fb_images_filtered.length > 0) ? (
                                        <div>
                                            {fb_images_filtered?.map((item, i) => (
                                                <div key={i} className='widget-fb-media-bottom-list-image'>
                                                    <div onMouseEnter={() => setDisplayImage(p => { return { ...p, url: item.url } })}
                                                        onMouseLeave={() => setDisplayImage(p => { return { ...p, url: "" } })}
                                                        onClick={() => clickCondition({ type: 'image', url: item.url, video_id: '' })}
                                                    >
                                                        {returnSelectedMedia({ type: 'image', item: item })}
                                                        <img src={item?.url_128} alt=""/>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <p style={{ fontSize: '16px', textAlign: 'center' }}>{!fbMediaState.act_id ? 'Select a Facebook account first.' : 'No images! Either this Facebook account has no images or this Facebook account has exceeded its request limit. If the problem persist, you can try after 10-15 minutes so that the facebook limit can reset POSSIBLY.'}</p>
                                        </div>
                                    )
                                )}
                            </div>

                            <div className='widget-fb-media-bottom-right'>
                                <div className='widget-fb-media-bottom-buttons'>
                                    <input id='widget-fb-image-upload' type='file' accept='image/*' multiple style={{ display: 'none' }}
                                        onChange={e => fbMediaState.act_id && uploadImageAPI({ images: Array.from(e.target.files), fb_id: fbMediaState.act_id, setUploadFbImage, fb_token, appContext })}
                                    />
                                    <div style={{ background: '#4caf50', cursor: loading ? 'not-allowed' : 'pointer' }} onClick={() => !loading && document.getElementById('widget-fb-image-upload').click()}>Upload</div>
                                    <div style={{ background: '#FF7F7F', cursor: loading ? 'not-allowed' : 'pointer' }} onClick={() => !loading && clearAllCondition()}>Clear All</div>
                                    <div style={{ background: '#1976d2', cursor: loading ? 'not-allowed' : 'pointer' }} onClick={() => !loading && setUploadFbImage(p => { return { ...p, act_id: fbMediaState.act_id, refresh: !p.refresh } })}>Refresh Images</div>
                                </div>

                                <div className='widget-fb-media-bottom-zoom-image'>
                                    <img src={(displayImage.url === '') ? noImage : displayImage.url} alt="" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='widget-fb-media-bottom'>
                            <div className='widget-fb-media-bottom-left'>
                                {loading ? (
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <div className='loader' style={{ height: '100px', width: '100px' }}/>
                                    </div>
                                ) : (
                                    (fb_videos_filtered.length > 0) ? (
                                        <div>
                                            {fb_videos_filtered?.map((item, i) => (
                                                <div key={i} className='widget-fb-media-bottom-list-image'>
                                                    <div>
                                                        <div style={{ position: 'absolute', zIndex: '2', top: 'calc(100% - 35px)', left: 'calc(100% - 35px)', height: 'fit-content', width: 'fit-content', background: '#FFF', borderRadius: '100%', padding: '0px' }}
                                                            onClick={() => {
                                                                if (displayImage.url === item?.picture) {
                                                                    setDisplayImage(p => { return { ...p, url: "", thumbnails: [] } })
                                                                } else {
                                                                    setDisplayImage(p => { return { ...p, url: item.picture, thumbnails: (item.thumbnails && item.thumbnails.data && Array.isArray(item.thumbnails.data)) ? item.thumbnails.data : [] } })
                                                                }
                                                            }}
                                                        >
                                                            {(displayImage.url === item?.picture) ? (
                                                                <div id='widget-media-component-eye-close' style={{ height: '30px', width: '30px', background: '#000' }}>
                                                                    <FaEyeSlash size={24} color={"#FFF"}/>
                                                                </div>
                                                            ) : (
                                                                <div id='widget-media-component-eye-open' style={{ height: '30px', width: '30px', background: '#FFF' }}>
                                                                    <FaEye size={24} color={"#000"}/>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <img src={item.picture} alt="" onClick={() => clickCondition({ type: 'video', url: item.picture, video_id: item.id })}/>
                                                        {returnSelectedMedia({ type: 'video', item: item })}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <p style={{ fontSize: '16px', textAlign: 'center' }}>{!fbMediaState.act_id ? 'Select a Facebook account first.' : 'No videos! Either this Facebook account has no videos or this Facebook account has exceeded its request limit. If the problem persist, you can try after 10-15 minutes so that the facebook limit can reset POSSIBLY.'}</p>
                                        </div>
                                    )
                                )}
                            </div>

                            <div className='widget-fb-media-bottom-right'>
                                <div className='widget-fb-media-bottom-buttons' style={{ gridTemplateColumns: '1fr 1fr' }}>
                                    <div style={{ background: '#FF7F7F', cursor: loading ? 'not-allowed' : 'pointer' }} onClick={() => !loading && clearAllCondition()}>Clear All</div>
                                    <div style={{ background: '#1976d2', cursor: loading ? 'not-allowed' : 'pointer' }} onClick={() => !loading && setUploadFbImage(p => { return { ...p, act_id: fbMediaState.act_id, refresh: !p.refresh } })}>Refresh Videos</div>
                                </div>

                                <div className='widget-fb-media-bottom-zoom-video'>
                                    <div>
                                        <img src={(displayImage.url === '') ? noImage : displayImage.url} alt="" />
                                        {(displayImage.thumbnails.length > 0) && <p>Thumbnails</p>}
                                        <div>
                                            {displayImage?.thumbnails?.map((item, i) => (
                                                <img key={i} src={item?.uri} style={{ display: item.uri ? 'inline-block' : 'none' }}/>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div>
    )
}