import React from 'react'
import "./dialogBox.css"
import { PiCheckCircleBold, PiInfoBold, PiWarningDiamondBold, PiXCircleBold, PiTrashBold, PiCheckCircleFill, PiInfoFill, PiWarningDiamondFill, PiXCircleFill, PiTrashFill } from "react-icons/pi";

const DialogBox = ({ dialogInfo, setDialogInfo }) => {
    // const [dialogInfo, setDialogInfo] = useState({
    //     mode: "Confirm",
    //     type: "Delete",
    //     message: [
    //         { type: "Delete", title: "Delete user John Doe?", content: "The user will be permanently deleted from the system." },
    //     ],
    //     confirm_function: () => {},
    //     cancel_function: () => {}
    // })
    // const [dialogInfo, setDialogInfo] = useState({
    //     mode: "Alert",
    //     type: "Warning",
    //     message: [
    //         { type: "Success", title: "Campaign created", content: "Campaign successfully created in facebook." },
    //         { type: "Info", title: "Adset creation", content: "Only available options are {NONE, CREDIT, HOUSING, ISSUES_ELECTIONS_POLITICS, EMPLOYMENT}." },
    //         { type: "Warning", title: "Adset creation failed", content: "Country and categories do not match as per the permission given by the account. Only available ones are {NONE, CREDIT, HOUSING, ISSUES_ELECTIONS_POLITICS, EMPLOYMENT}." },
    //         { type: "Error", title: "Ads creation failed", content: "Ad name needs to be provided to create ads." },
    //     ],
    //     confirm_function: () => {},
    //     cancel_function: () => {}
    // })

    // depending on the type, conditionally return icons and colors === is used for headers as well as messages
    const checkType = ({ type, icon_size }) => {
        let result = { color: '#000000', bg: 'rgba(0, 0, 0, 0.1)', icon_bg: 'rgba(0, 0, 0, 0.1)', icon: (<></>), icon_fill: (<></>) }

        if (type === "Success") {
            result = { color: '#1ea97c', bg: 'rgba(30, 169, 124, 0.1)', icon_bg: 'rgba(30, 169, 124, 0.1)', icon: (<PiCheckCircleBold size={icon_size} color={'#1ea97c'}/>), icon_fill: (<PiCheckCircleFill size={icon_size} color={'#1ea97c'}/>) }
        } else if (type === "Info") {
            result = { color: '#3b82f6', bg: 'rgba(59, 130, 246, 0.1)', icon_bg: 'rgba(59, 130, 246, 0.1)', icon: ((<PiInfoBold size={icon_size} color={'#3b82f6'}/>)), icon_fill: ((<PiInfoFill size={icon_size} color={'#3b82f6'}/>)) }
        } else if (type === "Warning") {
            result = { color: '#cc8925', bg: 'rgba(204, 137, 37, 0.1)', icon_bg: 'rgba(204, 137, 37, 0.1)', icon: ((<PiWarningDiamondBold size={icon_size} color={'#cc8925'}/>)), icon_fill: ((<PiWarningDiamondFill size={icon_size} color={'#cc8925'}/>)) }
        } else if (type === "Error") {
            result = { color: '#ff5757', bg: 'rgba(255, 87, 87, 0.1)', icon_bg: 'rgba(255, 87, 87, 0.1)', icon: ((<PiXCircleBold size={icon_size} color={'#ff5757'}/>)), icon_fill: ((<PiXCircleFill size={icon_size} color={'#ff5757'}/>)) }
        } else if (type === "Confirm") {
            result = { color: '#cc8925', bg: 'rgba(204, 137, 37, 0.1)', icon_bg: 'rgba(204, 137, 37, 0.1)', icon: ((<PiWarningDiamondBold size={icon_size} color={'#cc8925'}/>)), icon_fill: ((<PiWarningDiamondFill size={icon_size} color={'#cc8925'}/>)) }
        } else if (type === "Delete") {
            result = { color: '#ff5757', bg: 'rgba(255, 87, 87, 0.1)', icon_bg: 'rgba(255, 87, 87, 0.1)', icon: ((<PiTrashBold size={icon_size} color={'#ff5757'}/>)), icon_fill: ((<PiTrashFill size={icon_size} color={'#ff5757'}/>)) }
        }

        return result
    }

    // depending on the mode, return jsx component
    const ReturnAlertJsx = () => {
        let result = <></>

        if (dialogInfo.mode.trim() !== "") {
            if (dialogInfo.mode.trim() === "Alert") {
                result = (
                    <div className="dialog-main">
                        <div className="dialog-container">
                            <div className="dialog-box" style={{ borderTopColor: checkType({ type: dialogInfo.type, icon_size: 0 }).color }}>
                                <div className="dialog-box-title">
                                    {checkType({ type: dialogInfo.type, icon_size: 30 }).icon_fill}
                                    <p style={{ color: checkType({ type: dialogInfo.type, icon_size: 0 }).color }}>{dialogInfo.type}</p>
                                </div>

                                <div className="dialog-box-message-list">
                                    {dialogInfo.message.map((e, i) => (
                                        <div className='dialog-box-message' key={i} style={{ background: checkType({ type: e.type, icon_size: 0 }).bg, display: (e?.title?.trim() === "" && e?.content?.trim() === "") ? 'none' : 'grid' }}>
                                            <div className='dialog-box-message-icon'>
                                                {checkType({ type: e.type, icon_size: 18 }).icon}
                                            </div>
                                            <div className='dialog-box-message-content'>
                                                <p className='dialog-box-message-content-title' style={{ color: checkType({ type: e.type, icon_size: 0 }).color }}>{e.title}</p>
                                                <p className='dialog-box-message-content-message' style={{ color: checkType({ type: e.type, icon_size: 0 }).color }}>{e.content}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <hr style={{ border: '1px solid rgba(0, 0, 0, 0.05)' }}/>

                                <div className="dialog-box-buttons">
                                    <div className="dialog-box-button-confirm"
                                        onClick={() => {
                                            setDialogInfo(p => {
                                                return {
                                                    ...p,
                                                    mode: "",
                                                    type: "",
                                                    message: [],
                                                    confirm_function: () => {}
                                                }
                                            })
                                        }}
                                    >
                                        OK
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else if (dialogInfo.mode.trim() === "Confirm") {
                result = (
                    <div className="dialog-main">
                        <div className="dialog-container">
                            <div className="dialog-box" style={{ borderTopColor: checkType({ type: dialogInfo.type, icon_size: 0 }).color }}>
                                <div className='dialog-box-confirm-ui'>
                                    {checkType({ type: dialogInfo.type, icon_size: 50 }).icon_fill}
                                    <div>
                                        <p style={{ fontSize: '18px', fontWeight: '600', marginBottom: '4px' }}>{dialogInfo.message[0].title}</p>
                                        <p style={{ fontSize: '14px', fontWeight: '400' }}>{dialogInfo.message[0].content}</p>
                                    </div>
                                </div>

                                <hr style={{ border: '1px solid rgba(0, 0, 0, 0.05)' }}/>

                                <div className="dialog-box-buttons">
                                    <div className="dialog-box-button-cancel"
                                        onClick={() => {
                                            dialogInfo.cancel_function()
                                            setDialogInfo(p => {
                                                return {
                                                    ...p,
                                                    mode: "",
                                                    type: "",
                                                    message: [],
                                                    confirm_function: () => {},
                                                    cancel_function: () => {}
                                                }
                                            })
                                        }}
                                    >
                                        Cancel
                                    </div>
                                    <div className="dialog-box-button-confirm"
                                        onClick={() => {
                                            dialogInfo.confirm_function()
                                            setDialogInfo(p => {
                                                return {
                                                    ...p,
                                                    mode: "",
                                                    type: "",
                                                    message: [],
                                                    confirm_function: () => {},
                                                    cancel_function: () => {}
                                                }
                                            })
                                        }}
                                    >
                                        Confirm
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else if (dialogInfo.mode.trim() === "Alert_Widget") {
                result = (
                    <div className="dialog-main">
                        <div className="dialog-container">
                            <div className="dialog-box" style={{ borderTopColor: checkType({ type: dialogInfo.type, icon_size: 0 }).color }}>
                                <div className='dialog-box-confirm-ui'>
                                    {checkType({ type: dialogInfo.type, icon_size: 50 }).icon_fill}
                                    <div>
                                        <p style={{ fontSize: '18px', fontWeight: '600', marginBottom: '4px' }}>{dialogInfo.message[0].title}</p>
                                        <p style={{ fontSize: '14px', fontWeight: '400' }}>{dialogInfo.message[0].content}</p>
                                    </div>
                                </div>

                                <div className="dialog-box-message-list">
                                    {dialogInfo.message.slice(1).map((e, i) => (
                                        <div className='dialog-box-message' key={i} style={{ background: checkType({ type: e.type, icon_size: 0 }).bg, display: (e?.title?.trim() === "" && e?.content?.trim() === "") ? 'none' : 'grid' }}>
                                            <div className='dialog-box-message-icon'>
                                                {checkType({ type: e.type, icon_size: 18 }).icon}
                                            </div>
                                            <div className='dialog-box-message-content'>
                                                <p className='dialog-box-message-content-title' style={{ color: checkType({ type: e.type, icon_size: 0 }).color }}>{e.title}</p>
                                                <p className='dialog-box-message-content-message' style={{ color: checkType({ type: e.type, icon_size: 0 }).color }}>{e.content}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <hr style={{ border: '1px solid rgba(0, 0, 0, 0.05)' }}/>

                                <div className="dialog-box-buttons">
                                    <div className="dialog-box-button-cancel" onClick={() => {
                                        setDialogInfo(p => {
                                            return {
                                                ...p,
                                                mode: "",
                                                type: "",
                                                message: [],
                                                confirm_function: () => {},
                                                cancel_function: () => {}
                                            }
                                        })
                                    }}>
                                        Cancel
                                    </div>
                                    <div className="dialog-box-button-confirm" onClick={() => dialogInfo.confirm_function()}>
                                        Proceed
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }

        return result
    }

    return <ReturnAlertJsx/>
}

export default DialogBox