import React, { useState, useEffect, useContext } from 'react';
import './bestPerforming.css'
import axios from 'axios';
import moment from 'moment';
import Cookies from 'js-cookie';
import { host } from '../../hooks/host';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid';
import { FaCopy } from 'react-icons/fa';
import { FaXmark } from "react-icons/fa6";
import { MdDoneAll, MdRemoveDone, MdRefresh } from 'react-icons/md';


import useFetchUsers from '../../hooks/useFetchUsers';
import { copyCampaign } from '../../hooks/copyCampaign';
import { Context } from '../../AppContext';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport
                csvOptions={{
                    allColumns: true, // export all columns except the ones with props disableExport=true
                    fileName: 'BestPerforming.csv', // Set the file name
                }}
            />
        </GridToolbarContainer>
    );
  }

const BestPerforming = () => {
    const appContext = useContext(Context)
    const loading = appContext.bestPerform.loading
    const data = appContext.bestPerform.data
    
    const [selectedClient, setSelectedClient] = useState('All')
    const [filteredData, setFilteredData] = useState([])
    const [checkedCampaigns, setCheckedCampaigns] = useState([])
    const [userToSend, setUserToSend] = useState('')
    const [copyC2WLoading, setCopyC2WLoading] = useState(false)
    const [ads, setAds] = useState({ campaignToDisplay: '', ads: [], loading: false })
    const [history, setHistory] = useState({ loading: false, selected_dates: [], data: [], display: false })

    const fetchUsers = useFetchUsers()
    const [userLoading, users] = fetchUsers

    const history_dates = [
        "2024-05-31",
        "2024-04-30",
        "2024-03-31",
        "2024-02-29",
        "2024-01-31",
        "2023-12-31",
        "2023-11-30",
        "2023-10-31",
        "2023-09-30",
        "2023-08-31",
        "2023-07-31",
        "2023-06-30",
        "2023-05-31",
    ];

    // API to get ads
    const getAds = ({e, info}) => {
        e.preventDefault()

        setAds(p => { return { ...p, campaignToDisplay: info.campaign_id, loading: true, ads: [] } })
        
        const fromDateCondition = () => {
            let date = 0
      
            if (info.client === 'Sedo') {
                if ((moment().utc().add(8, 'hours').format('HH') * 1) >= 11) {
                    date = moment().utc().add(8, 'hours').subtract(7, 'days').format('YYYY-MM-DD')
                } else {
                    date = moment().utc().add(8, 'hours').subtract(8, 'days').format('YYYY-MM-DD')
                }
            } else {
                date = moment().utc().add(8, 'hours').subtract(8, 'days').format('YYYY-MM-DD')
            }
      
            return date
        }

        const toDateCondition = () => {
            let date = 0
      
            date = moment().utc().add(8, 'hours').subtract(2, 'days').format('YYYY-MM-DD')
      
            return date
        }

        const controller = new AbortController();
        const token = Cookies.get('token')
        // const username = Cookies.get('userArray')

        // if (!username) {
        //     window.alert('Select a user first!')
        //     navigate('/')
        //     return;
        // }
        
        const url = `${host}/api/bidding/v2/fetch/ad`;
    
        axios.post(url, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Authorization": "Bearer "+token,
                "Content-Type": "application/json",
            },
            // user: username.split(',')[0],
            from: fromDateCondition(), to: toDateCondition(), id: info.act_id, name: info.campaign_name, campaign_id: info.campaign_id, traffic: 'Facebook'
        },
        {
            signal: controller.signal
        })
    
        .then((response) => {
            console.log(response);
            if (response.data.result) {
                setAds(p => { return { ...p, ads: response.data.result } })
            }
            setAds(p => { return { ...p, loading: false } })
        })
        
        .catch((error) => {
            console.log(error);
            if (error?.response?.data?.message === 'Invalid') {
                appContext.forceLogout()
            }
        })
        
        .finally(() => {
            setAds(p => { return { ...p, loading: false } })
        })
    }

    // ads table on campaign name click
    const AdsetsLink = ({params}) => {
        const info = { campaign_name: params.campaign_name, campaign_id: params.campaign_id, client: params.client, act_id: params.act_id }

        return (
            <>
                {(ads.campaignToDisplay === info.campaign_id) ? (
                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                            <p className='adsets-link-close' onClick={e => { e.preventDefault(); setAds(p => { return { ...p, campaignToDisplay: '', ads: [], loading: false } }) }} style={{ opacity: (ads.loading) ? 0.5 : 1, pointerEvents: (ads.loading) ? 'none' : 'all'  }}>Back</p>
                            {(ads.loading) ? (<div className="loader" style={{ borderColor: '#B026FF', borderTopColor: '#F7E9FF' }}></div>) : (<></>)}
                        </div>
                        <table className='adsets-table'>
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Name</th>
                                    <th>CPL</th>
                                    <th>LINK</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ads.ads.map((m, i) => (
                                    <tr key={i}>
                                        <td><p style={{ color: (m.effective_status === 'ACTIVE') ? '#4caf50' : '#ef5350', background: (m.effective_status === 'ACTIVE') ? 'rgba(76, 175, 80, 0.1)' : 'rgba(239, 83, 80, 0.1)', borderRadius: '50px', padding: '0px 5px' }}>{m.effective_status}</p></td>
                                        <td>{m.name}</td>
                                        <td>{m.cpl ? m.cpl.toFixed(3) : '-'}</td>
                                        <td><a href={m.link.replaceAll('"', '')} target='_blank'>Visit Ad</a></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className='adsets-link-open' onClick={e => getAds({e, info})}>
                        {info.campaign_name}
                    </div>
                )}
            </>
        )
    }

    // in case of admin show all these
    const admin_columns = [
        { field: 'uniqueID', headerName: 'ID', hide: true, disableExport: true },
        { field: 'check', headerName: 'Select', width: 80, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left', disableExport: true,
            renderCell: (params) => {
                const camp = params.row.campaign_name
                return (
                    <div className='rulesSwitchContainer'>
                        <input type="checkbox" style={{ height: '30px', width: '30px' }} checked={(checkedCampaigns.find(f => f === camp)) ? true : false} onChange={e => handleChangeCheckBox({e, camp})} />
                    </div>
                )
            }},
        { field: 'country', headerName: 'Country', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left' },
        { field: 'campaign_name', headerName: 'Campaign Name', minWidth: 300, flex: 1, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left',
            renderCell: (params) => (<AdsetsLink params={params.row}/>)},
        { field: 'topic', headerName: 'Topic', minWidth: 200, flex: 1, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left' },
        { field: 'keyword', headerName: 'Keyword', hide: true },
        { field: 'result', headerName: 'Result', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', type: 'number', headerAlign: 'left', align: 'left',
                renderCell: (params) => {
                    const roi = params.row["result"]
                    return(
                        <div style={{color: (roi > 0) ? '#4caf50' : '#ef5350'}}>{(roi === "-") ? '-' : '$ '+(roi*1).toFixed(0)}</div>
                    )
                }},
        { field: 'result_7', headerName: 'Result(7)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', type: 'number', headerAlign: 'left', align: 'left',
                renderCell: (params) => {
                    const roi = params.row["result_7"]
                    return(
                        <div style={{color: (roi > 0) ? '#4caf50' : '#ef5350'}}>{(roi === "-") ? '-' : '$ '+(roi*1).toFixed(0)}</div>
                    )
                }},
        { field: 'result_14', headerName: 'Result(14)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', type: 'number', headerAlign: 'left', align: 'left',
                renderCell: (params) => {
                    const roi = params.row["result_14"]
                    return(
                        <div style={{color: (roi > 0) ? '#4caf50' : '#ef5350'}}>{(roi === "-") ? '-' : '$ '+(roi*1).toFixed(0)}</div>
                    )
                }},
        { field: 'result_30', headerName: 'Result(30)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', type: 'number', headerAlign: 'left', align: 'left',
                renderCell: (params) => {
                    const roi = params.row["result_30"]
                    return(
                        <div style={{color: (roi > 0) ? '#4caf50' : '#ef5350'}}>{(roi === "-") ? '-' : '$ '+(roi*1).toFixed(0)}</div>
                    )
                }},
        { field: 'ROI%', headerName: 'ROI', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', type: 'number', headerAlign: 'left', align: 'left',
                renderCell: (params) => {
                    const roi = params.row["ROI%"]
                    return(
                        <div style={{color: (roi > 0) ? '#4caf50' : '#ef5350'}}>{(roi === "-") ? '-' : (roi*1).toFixed(0)+'%'}</div>
                    )
                }},
        { field: 'ROI_7', headerName: 'ROI(7)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', type: 'number', headerAlign: 'left', align: 'left',
                renderCell: (params) => {
                    const roi = params.row["ROI_7"]
                    return(
                        <div style={{color: (roi > 0) ? '#4caf50' : '#ef5350'}}>{(roi === "-") ? '-' : (roi*1).toFixed(0)+'%'}</div>
                    )
                }},
        { field: 'ROI_14', headerName: 'ROI(14)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', type: 'number', headerAlign: 'left', align: 'left',
                renderCell: (params) => {
                    const roi = params.row["ROI_14"]
                    return(
                        <div style={{color: (roi > 0) ? '#4caf50' : '#ef5350'}}>{(roi === "-") ? '-' : (roi*1).toFixed(0)+'%'}</div>
                    )
                }},
        { field: 'ROI_30', headerName: 'ROI(30)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', type: 'number', headerAlign: 'left', align: 'left',
                renderCell: (params) => {
                    const roi = params.row["ROI_30"]
                    return(
                        <div style={{color: (roi > 0) ? '#4caf50' : '#ef5350'}}>{(roi === "-") ? '-' : (roi*1).toFixed(0)+'%'}</div>
                    )
                }},
        { field: 'client', headerName: 'Client', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left' },
        { field: 'act_name', headerName: 'Account Name', minWidth: 200, flex: 1, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left' },
        { field: 'url', headerName: 'URL', minWidth: 300, flex: 1, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left' }
    ]

    // in case of manager show just these
    const manager_columns = [
        { field: 'uniqueID', headerName: 'ID', hide: true, disableExport: true },
        { field: 'check', headerName: 'Select', width: 80, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left', disableExport: true,
            renderCell: (params) => {
                const camp = params.row.campaign_name
                return (
                    <div className='rulesSwitchContainer'>
                        <input type="checkbox" style={{ height: '30px', width: '30px' }} checked={(checkedCampaigns.find(f => f === camp)) ? true : false} onChange={e => handleChangeCheckBox({e, camp})} />
                    </div>
                )
            }},
        { field: 'country', headerName: 'Country', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left' },
        { field: 'campaign_name', headerName: 'Campaign Name', minWidth: 300, flex: 1, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left',
            renderCell: (params) => (<AdsetsLink params={params.row}/>)},
        { field: 'topic', headerName: 'Topic', minWidth: 200, flex: 1, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left' },
        { field: 'keyword', headerName: 'Keyword', hide: true },
        { field: 'ROI%', headerName: 'ROI', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', type: 'number', headerAlign: 'left', align: 'left',
                renderCell: (params) => {
                    const roi = params.row["ROI%"]
                    return(
                        <div style={{color: (roi > 0) ? '#4caf50' : '#ef5350'}}>{(roi === "-") ? '-' : (roi*1).toFixed(0)+'%'}</div>
                    )
                }},
        { field: 'ROI_7', headerName: 'ROI(7)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', type: 'number', headerAlign: 'left', align: 'left',
                renderCell: (params) => {
                    const roi = params.row["ROI_7"]
                    return(
                        <div style={{color: (roi > 0) ? '#4caf50' : '#ef5350'}}>{(roi === "-") ? '-' : (roi*1).toFixed(0)+'%'}</div>
                    )
                }},
        { field: 'ROI_14', headerName: 'ROI(14)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', type: 'number', headerAlign: 'left', align: 'left',
                renderCell: (params) => {
                    const roi = params.row["ROI_14"]
                    return(
                        <div style={{color: (roi > 0) ? '#4caf50' : '#ef5350'}}>{(roi === "-") ? '-' : (roi*1).toFixed(0)+'%'}</div>
                    )
                }},
        { field: 'ROI_30', headerName: 'ROI(30)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', type: 'number', headerAlign: 'left', align: 'left',
                renderCell: (params) => {
                    const roi = params.row["ROI_30"]
                    return(
                        <div style={{color: (roi > 0) ? '#4caf50' : '#ef5350'}}>{(roi === "-") ? '-' : (roi*1).toFixed(0)+'%'}</div>
                    )
                }},
    ]

    // history column
    const history_columns = [
        { field: 'uniqueID', headerName: 'ID', hide: true },
        { field: 'date', headerName: 'Date', width: 150, flex: 0, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left' },
        { field: 'country', headerName: 'Country', minWidth: 200, flex: 1, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left' },
        { field: 'topic', headerName: 'Topic', minWidth: 300, flex: 1, headerClassName: 'datagridHeader', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const topic = params.row.topic
                const has_topic = params.row.has_topic
                return (
                    <div style={{ color: has_topic ? '#000' : '#FF9900' }}>{topic}</div>
                )
        }},
        { field: 'ROI_30', headerName: 'ROI(30)', minWidth: 100, flex: 1, headerClassName: 'datagridHeader', type: 'number', headerAlign: 'left', align: 'left',
            renderCell: (params) => {
                const roi = params.row["ROI_30"]
                return(
                    <div style={{color: (roi > 0) ? '#4caf50' : '#ef5350'}}>{(roi === "-") ? '-' : (roi*1).toFixed(0)+'%'}</div>
                )
        }},
    ]

    // filter data by client name
    useEffect(() => {
        if (selectedClient === 'All') {
            setFilteredData(data)
        } else {
            setFilteredData(data.filter(f => f.client === selectedClient ))
        }
    }, [data, selectedClient])

    // campaigns check box on value change
    const handleChangeCheckBox = ({e, camp}) => {
        e.preventDefault();
        const value = e.target.checked
        let temp = [...checkedCampaigns]

        if (value === true) {
            temp.push(camp)
        } else {
            const index = temp.indexOf(camp);
            if (index > -1) { 
                temp.splice(index, 1);
            }
        }
        setCheckedCampaigns(temp);
    }

    // select all campaigns - update state
    const handleCheckAllCampaigns = (e) => {
        e.preventDefault()
        setCheckedCampaigns([])

        let camp = (selectedClient === 'All') ? data : data.filter(f => f.client === selectedClient)

        let temp = camp.map(m => m.campaign_name)

        setCheckedCampaigns(temp)
    }

    // copy selected campaigns to widget
    const handleCopyCampaign = async () => {
        setCopyC2WLoading(true)
        if (checkedCampaigns.length < 1) {
            window.alert('Please select some campaigns first.')
            setCopyC2WLoading(false)
            return
        }
        
        if (((Cookies.get('role') === 'admin') || (Cookies.get('manager') === 'admin') || (Cookies.get('userArray') === 'LFCWidget')) && (userToSend === '')) {
            window.alert('Please select a manager first.')
            setCopyC2WLoading(false)
            return
        }

        if (checkedCampaigns.filter(f => !Number(f)).length > 0) {
            window.alert('Text Campaigns can not be copied.')
            setCopyC2WLoading(false)
            return
        }

        const username = Cookies.get('userArray')
        const token = Cookies.get('token')
        const url = `${host}/api/index/search_by_id`;
        let tempResult = []

        checkedCampaigns.map(e => {
            const temp = data.find(f => f.campaign_name === e)
            let temp_username = ''

            if ((Cookies.get('role') === 'admin') || (Cookies.get('manager') === 'admin') || (Cookies.get('userArray') === 'LFCWidget')) {
                temp_username = userToSend
            } else {
                if ((Cookies.get('role') === 'widget')) {
                    temp_username = Cookies.get('manager')
                } else {
                    temp_username = username
                }
            }
            
            if (temp) {
                tempResult.push({
                    finished: false,
                    topic: "",
                    fb_id: "",
                    client: "",
                    country: "",
                    categories: [],
                    daily_budget: "1",
                    name: ["1"],
                    headline: [""],
                    message: [""],
                    comment: "",
                    ad_media: [{}],
                    picture: [""],
                    facebook_fan_page: "",
                    instagram_account: "",
                    note: temp.act_name ? (e+' / '+temp.act_name) : e,
                    url: "",
                    keyword: [],
                    final_url: "",
                    case: "Ads Creation",
                    username: temp_username
                })
            }
        })

        let result = await Promise.all(tempResult.map(async e => {
            const temp = {...e}
            try {
                const response = await axios.post(url, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                        "Authorization": "Bearer "+token,
                        "Content-Type": "application/json",
                    },
                    id: Number(e.note.split(' ')[0])
                })
                if (response.data) {
                    if (response.data.result) {
                        const obj = response.data.result
                        temp.topic = obj.topic ? obj.topic : ''
                        temp.country = obj.country ? obj.country : ''
                        temp.keyword = obj.keyword ? obj.keyword : []
                        temp.comment = obj.comment ? obj.comment : ''
                        
                        if (obj.headline && Array.isArray(obj.headline) && (obj.headline.length > 0)) {
                            temp.name = obj.headline.map((m, i) => `${i+1}`)
                            temp.headline = obj.headline
                            temp.message = obj.message ? obj.message : obj.headline.map(m => "")
                            temp.ad_media = obj.headline.map(m => ({}))
                            temp.picture = obj.headline.map(m => "")
                        }
                    }
                }
            } catch (error) {
                console.log(error);
                if (error?.response?.data?.message === 'Invalid') {
                    appContext.forceLogout()
                }
            }
            return temp
        }))

        console.log(result)

        copyCampaign({ setCopyC2WLoading: setCopyC2WLoading, copyData: result, setCheckedCampaigns: setCheckedCampaigns, appContext: appContext, setUserToSend })
    }

    return (
        <div className="widget-container">
            {/* <MultiCopySelectionForm /> */}

            {/* client select */}
            <div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <p style={{ fontWeight: 700, marginBottom: '5px' }}>Top 300 Best Performing Campaigns</p>
                    <div style={{ height: '30px', padding: '0px 10px', borderRadius: '5px', background: appContext.bestPerform.loading ? '#AAA' : '#1976d2', color: '#FFF', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', pointerEvents: appContext.bestPerform.loading ? 'none' : 'all' }}
                        onClick={() => appContext.setBestPerform(p => { return { ...p, refresh: !p.refresh } })}
                    >
                        {appContext.bestPerform.loading ? (
                            <div className="loader" style={{ height: '20px', width: '20px' }}/>
                        ) : (
                            <><MdRefresh size={16} color={"#FFF"}/>&nbsp;Refresh</>
                        )}
                    </div>
                </div>
                {(Cookies.get('role') === 'admin') ? (
                    <div className='widget-top'>
                        <div className="widget-top-option" onClick={() => setSelectedClient('All')} style={{ background: (selectedClient === 'All') ? '#1c1c1e' : '#FFFFFF', color : (selectedClient === 'All') ? '#FFFFFF' : '#1c1c1e' }}>All</div>
                        <div className="widget-top-option" onClick={() => setSelectedClient('Sedo')} style={{ background: (selectedClient === 'Sedo') ? '#1c1c1e' : '#FFFFFF', color : (selectedClient === 'Sedo') ? '#FFFFFF' : '#1c1c1e' }}>Sedo</div>
                        <div className="widget-top-option" onClick={() => setSelectedClient('System1')} style={{ background: (selectedClient === 'System1') ? '#1c1c1e' : '#FFFFFF', color : (selectedClient === 'System1') ? '#FFFFFF' : '#1c1c1e' }}>System1</div>
                        <div className="widget-top-option" onClick={() => setSelectedClient('Tonic')} style={{ background: (selectedClient === 'Tonic') ? '#1c1c1e' : '#FFFFFF', color : (selectedClient === 'Tonic') ? '#FFFFFF' : '#1c1c1e' }}>Tonic</div>
                        <div className="widget-top-option" onClick={() => setSelectedClient('Bodis')} style={{ background: (selectedClient === 'Bodis') ? '#1c1c1e' : '#FFFFFF', color : (selectedClient === 'Bodis') ? '#FFFFFF' : '#1c1c1e' }}>Bodis</div>
                        <div className="widget-top-option" onClick={() => setSelectedClient('Adcombo')} style={{ background: (selectedClient === 'Adcombo') ? '#1c1c1e' : '#FFFFFF', color : (selectedClient === 'Adcombo') ? '#FFFFFF' : '#1c1c1e' }}>Adcombo</div>
                        <div className="widget-top-option" onClick={() => setSelectedClient('Crossroads')} style={{ background: (selectedClient === 'Crossroads') ? '#1c1c1e' : '#FFFFFF', color : (selectedClient === 'Crossroads') ? '#FFFFFF' : '#1c1c1e' }}>Crossroads</div>
                    </div>
                ) : (<></>)}
                <div className="listDateContainer" style={{ borderTop: '0.5px solid #000000', height: 'fit-content', gap: '10px', alignItems: 'center', margin: '10px 0px 0px 0px' }}>
                    <p>Select campaigns:</p>
                    <button style={{ height: '40px', padding: '0px 10px', borderRadius: '5px', border: 0, outline: 0, background: '#555555', color: '#FFFFFF', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={e => handleCheckAllCampaigns(e)}><MdDoneAll size={30} color={'#FFFFFF'}/></button>
                    <button style={{ height: '40px', padding: '0px 10px', borderRadius: '5px', border: '2px solid #AAAAAA', outline: 0, background: '#FFFFFF', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={e => {e.preventDefault(); setCheckedCampaigns([])}}><MdRemoveDone size={30} color={'#AAAAAA'}/></button>
                    {((Cookies.get('role') === 'admin') || (Cookies.get('manager') === 'admin') || (Cookies.get('userArray') === 'LFCWidget')) ? (
                        (checkedCampaigns.length > 0) ? (
                            <>
                                {userLoading ? (
                                    <div className='loader'></div>
                                ) : (
                                    <select className='widget2-select' value={userToSend} disabled={copyC2WLoading} onChange={e => setUserToSend(e.target.value)}>
                                        <option value="">Select Manager</option>
                                        {(Cookies.get('userArray') === 'LFCWidget') ? (
                                            ["LFC", "Southpow", "Moneyball"].map((e, i) => (
                                                <option value={e} key={i}>{e}</option>
                                            ))
                                        ) : (
                                            users?.filter(f => f.role === 'manager').map((e, i) => (
                                                <option value={e.username} key={i}>{e.username}</option>
                                            ))
                                        )}
                                    </select>
                                )}
                                <button style={{ height: '40px', padding: '0px 10px', borderRadius: '5px', border: 0, outline: 0, background: '#555', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: copyC2WLoading ? '50%' : '100%' }} disabled={copyC2WLoading}
                                    onClick={() => {
                                        if (checkedCampaigns.length < 1) {
                                            window.alert('Please select some campaigns first.')
                                            setCopyC2WLoading(false)
                                            return
                                        }
                                        if (window.confirm('Do you want to copy selected ' + checkedCampaigns.length + ' campaigns to widget?')) {
                                            handleCopyCampaign()
                                        } else {
                                            return
                                        }
                                    }}
                                >
                                    {copyC2WLoading ? (
                                        <div className="loader" style={{ height: '30px', width: '30px' }}/>
                                    ) : (
                                        <FaCopy size={30} color={'#FFF'}/>
                                    )}
                                </button>
                            </>
                        ) : (
                            <></>
                        )
                    ) : (
                        <button style={{ height: '40px', padding: '0px 10px', borderRadius: '5px', border: 0, outline: 0, background: '#555', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: copyC2WLoading ? '50%' : '100%' }} disabled={copyC2WLoading}
                            onClick={() => {
                                if (checkedCampaigns.length < 1) {
                                    window.alert('Please select some campaigns first.')
                                    setCopyC2WLoading(false)
                                    return
                                }
                                if (window.confirm('Do you want to copy selected ' + checkedCampaigns.length + ' campaigns to widget?')) {
                                    handleCopyCampaign()
                                } else {
                                    return
                                }
                            }}
                        >
                            {copyC2WLoading ? (
                                <div className="loader" style={{ height: '30px', width: '30px' }}/>
                            ) : (
                                <FaCopy size={30} color={'#FFF'}/>
                            )}
                        </button>
                    )}
                    
                </div>
            </div>

            <div className="widget-datagrid-container">
                <DataGrid
                slots
                    components={{ Toolbar: CustomToolbar }}
                    sx={{
                        boxShadow: 3,
                        '& .datagridHeader': {
                            color: '#1976d2',
                        },
                        '& .MuiDataGrid-cell': {
                            padding: '10px'
                        },
                    }}
                    loading={loading}
                    getRowHeight={() => 'auto'}
                    getRowId={(row) => row.uniqueID}
                    rows={filteredData}
                    columns={(Cookies.get('role') === 'admin') ? admin_columns : manager_columns}
                    disableSelectionOnClick={true}
                />
            </div>

            <p style={{ marginTop: '20px', fontSize: '24px', fontWeight: '600' }}>BEST IN HISTORY</p>

            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <div className='widget-add-field' onMouseEnter={() => setHistory(p => { return { ...p, display: true } })} onMouseLeave={() => setHistory(p => { return { ...p, display: false } })}>
                    <p className='widget-add-field-float-name'>Dates</p>
                    <div className='widget2-div' style={{ borderColor: history.display ? '#000' : '#AAA' }}>
                        <div style={{ fontSize: '14px' }}>{(history.selected_dates.length === 0) ? 'None selected' : history.selected_dates.length+' selected'}</div>
                        <ul style={{ position: 'absolute', top: '38px', height: 'fit-content', maxHeight: '400px', background: '#FFF', display: history.display ? 'block' : 'none' }}>
                            <li style={{ backgroundColor: (history.selected_dates.length === 0) ? '#dbeafe' : '#FFF', wordBreak: 'break-all' }}
                                onClick={() => {
                                    setHistory(p => { return { ...p, selected_dates: [] } })
                                }}
                            >
                                NONE
                            </li>
                            {history_dates.map((e, i) => (
                                <li key={i} style={{ backgroundColor: (history.selected_dates.find(f => f === e)) ? '#dbeafe' : '#FFF', wordBreak: 'break-all' }}
                                    onMouseEnter={e => e.currentTarget.style.opacity = 0.5}
                                    onMouseLeave={e => e.currentTarget.style.opacity = 1}
                                    onClick={() => {
                                        let temp = [...history.selected_dates]
                                        let find_date = temp.filter(f => f === e)
                                        if (find_date.length > 0) {
                                            temp = temp.filter(f => f !== e)
                                        } else {
                                            temp.push(e)
                                        }
                                        setHistory(p => { return { ...p, selected_dates: temp } })
                                    }}
                                >
                                    {e}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <button className='widget2-button' style={{ maxWidth: 'fit-content', fontWeight: '600' }} onClick={() => fetchBestPerformingHistory({ history, setHistory, appContext })}>Get History</button>
            </div>

            <div className="widget-datagrid-container">
                <DataGrid
                components={{ Toolbar: GridToolbar }}
                sx={{
                boxShadow: 3,
                    '& .datagridHeader': {
                        color: '#1976d2',
                    },
                    '& .MuiDataGrid-cell': {
                        padding: '10px'
                    },
                }}
                loading={history.loading}
                getRowHeight={() => 'auto'}
                getRowId={(row) => row.uniqueID}
                rows={history.data}
                columns={history_columns}
                disableSelectionOnClick={true}/>
            </div>
        </div>
    )
}

export default BestPerforming




// fetch best performing monthly history
const fetchBestPerformingHistory = ({ history, setHistory, appContext }) => {
    setHistory(p => { return { ...p, loading: true } })
    const controller = new AbortController();
    const token = Cookies.get('token')
    const url = `${host}/api/fb/history`;

    axios.post(url, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type, x-access-token",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
            "Authorization": "Bearer "+token,
            "Content-Type": "application/json",
        },
        date: history.selected_dates
    })

    .then((response) => {
        console.log(response);
        const obj = response.data

        if (obj) {
            let temp = obj.map((e, i) => {
                return {
                    ...e,
                    uniqueID: i,
                    has_topic: e.topic ? true : false,
                    topic: e.topic ? e.topic : e.campaign_name,
                }
            })
            
            temp.sort((a, b) => b?.['ROI_30'] - a?.['ROI_30'])
            setHistory(p => { return { ...p, data: temp, loading: false } })
        } else {
            setHistory(p => { return { ...p, data: [], loading: false } })
        }
    })
    
    .catch((error) => {
        console.log(error);
        setHistory(p => { return { ...p, loading: false } })
        if (error?.response?.data?.message === 'Invalid') {
            appContext.forceLogout()
        }
    }) 
}

// multi copy selection form
const MultiCopySelectionForm = ({}) => {
    return (
        <div className='bp-multi-container'>
            <div className='bp-multi-scroll'>
                <div className='bp-multi'>
                    <div className='bp-multi-top'>
                        <p>Topics/GEO copy form</p>
                        <div>
                            <FaXmark size={20} color={"#555"}/>
                        </div>
                    </div>

                    <div className='bp-multi-body'>
                        body
                    </div>
                    
                    <div className='bp-multi-bottom'>
                        <p>To Copy <span>0</span> "MAX 10"</p>
                        <button>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    )
}