import React from "react";
import Traffic from "../reusable/traffic/Traffic";
import sedoLogo from '../../assets/sedoLogo.png'

const SedoTiktok = () => {
    return(
        <Traffic trafficName='sedo_tiktok' trafficToSend='TikTok' clientName='Sedo' logo={sedoLogo} minusDate={2} />
    )
}

export default SedoTiktok