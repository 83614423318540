import React from 'react'
import './fetchStates.css'
import '../reusable/sum/sum.css'
import '../dashboard/home.css'
import useFetchState from '../../hooks/useFetchState'

const FetchStates = () => {
    const fetch = useFetchState()
    const [loading, states] = fetch

    return (
        <div className="sumContainer">
            <div className="sumWrapper">
                <div className="sumContentContainer">
                    <div style={{fontSize: '20px', fontWeight: '700', marginBottom: '20px', display: 'flex'}}>
                        Today's Fetch Status:&nbsp;
                        {(loading) ? (<div className="loadingSpinner"/>): (<></>)}
                    </div>

                    <div style={{fontSize: '20px', fontWeight: '700', color: '#4bc0c0', display: 'flex'}}>
                        Tonic:&nbsp;
                        {(loading) ? (<div className="loadingSpinner"/>): (<></>)}
                    </div>
                    <div className="gridContainer" style={{marginBottom: '20px'}}>
                        <div className="gridItem" style={{height: '150px'}}>
                            <div className="fetchCard">
                                <div>All</div>
                                <div className="wave wave1 waveTonic" style={{backgroundColor: `${(states?.tonic === 'err!') ? 'rgba(239, 83, 80, 0.5)' : ''}`}}></div>
                                <div className="wave wave2 waveTonic" style={{backgroundColor: `${(states?.tonic === 'err!') ? 'rgba(239, 83, 80, 0.5)' : ''}`}}></div>
                                <div className='fetchCardStatus'>{(states?.tonic === 'err!') ? 'No Data' : 'Fetched'}</div>
                            </div>
                        </div>
                        <div className="gridItem" style={{height: '150px'}}>
                            <div className="fetchCard">
                                <div>Facebook</div>
                                <div className="wave wave1 waveTonic" style={{backgroundColor: `${(states?.facebook_tonic === 'err!') ? 'rgba(239, 83, 80, 0.5)' : ''}`}}></div>
                                <div className="wave wave2 waveTonic" style={{backgroundColor: `${(states?.facebook_tonic === 'err!') ? 'rgba(239, 83, 80, 0.5)' : ''}`}}></div>
                                <div className='fetchCardStatus'>{(states?.facebook_tonic === 'err!') ? 'No Data' : 'Fetched'}</div>
                            </div>
                        </div>
                        <div className="gridItem" style={{height: '150px'}}>
                            <div className="fetchCard">
                                <div>Taboola</div>
                                <div className="wave wave1 waveTonic" style={{backgroundColor: `${(states?.taboola_tonic === 'err!') ? 'rgba(239, 83, 80, 0.5)' : ''}`}}></div>
                                <div className="wave wave2 waveTonic" style={{backgroundColor: `${(states?.taboola_tonic === 'err!') ? 'rgba(239, 83, 80, 0.5)' : ''}`}}></div>
                                <div className='fetchCardStatus'>{(states?.taboola_tonic === 'err!') ? 'No Data' : 'Fetched'}</div>
                            </div>
                        </div>
                        <div className="gridItem" style={{height: '150px'}}>
                            <div className="fetchCard">
                                <div>Outbrain</div>
                                <div className="wave wave1 waveTonic" style={{backgroundColor: `${(states?.outbrain_tonic === 'err!') ? 'rgba(239, 83, 80, 0.5)' : ''}`}}></div>
                                <div className="wave wave2 waveTonic" style={{backgroundColor: `${(states?.outbrain_tonic === 'err!') ? 'rgba(239, 83, 80, 0.5)' : ''}`}}></div>
                                <div className='fetchCardStatus'>{(states?.outbrain_tonic === 'err!') ? 'No Data' : 'Fetched'}</div>
                            </div>
                        </div>
                    </div>

                    <div style={{fontSize: '20px', fontWeight: '700', color: '#36a2eb', display: 'flex'}}>
                        System1:&nbsp;
                        {(loading) ? (<div className="loadingSpinner"/>): (<></>)}
                    </div>
                    <div className="gridContainer" style={{marginBottom: '20px'}}>
                        <div className="gridItem" style={{height: '150px'}}>
                            <div className="fetchCard">
                                <div>All</div>
                                <div className="wave wave1 waveSystem1" style={{backgroundColor: `${(states?.system1 === 'err!') ? 'rgba(239, 83, 80, 0.5)' : ''}`}}></div>
                                <div className="wave wave2 waveSystem1" style={{backgroundColor: `${(states?.system1 === 'err!') ? 'rgba(239, 83, 80, 0.5)' : ''}`}}></div>
                                <div className='fetchCardStatus'>{(states?.system1 === 'err!') ? 'No Data' : 'Fetched'}</div>
                            </div>
                        </div>
                        <div className="gridItem" style={{height: '150px'}}>
                            <div className="fetchCard">
                                <div>Facebook</div>
                                <div className="wave wave1 waveSystem1" style={{backgroundColor: `${(states?.facebook_system1 === 'err!') ? 'rgba(239, 83, 80, 0.5)' : ''}`}}></div>
                                <div className="wave wave2 waveSystem1" style={{backgroundColor: `${(states?.facebook_system1 === 'err!') ? 'rgba(239, 83, 80, 0.5)' : ''}`}}></div>
                                <div className='fetchCardStatus'>{(states?.facebook_system1 === 'err!') ? 'No Data' : 'Fetched'}</div>
                            </div>
                        </div>
                        <div className="gridItem" style={{height: '150px'}}>
                            <div className="fetchCard">
                                <div>Taboola</div>
                                <div className="wave wave1 waveSystem1" style={{backgroundColor: `${(states?.taboola_system1 === 'err!') ? 'rgba(239, 83, 80, 0.5)' : ''}`}}></div>
                                <div className="wave wave2 waveSystem1" style={{backgroundColor: `${(states?.taboola_system1 === 'err!') ? 'rgba(239, 83, 80, 0.5)' : ''}`}}></div>
                                <div className='fetchCardStatus'>{(states?.taboola_system1 === 'err!') ? 'No Data' : 'Fetched'}</div>
                            </div>
                        </div>
                    </div>

                    <div style={{fontSize: '20px', fontWeight: '700', color: '#9966ff', display: 'flex'}}>
                        Sedo:&nbsp;
                        {(loading) ? (<div className="loadingSpinner"/>): (<></>)}
                    </div>
                    <div className="gridContainer" style={{marginBottom: '20px'}}>
                        <div className="gridItem" style={{height: '150px'}}>
                            <div className="fetchCard">
                                <div>All</div>
                                <div className="wave wave1 waveSedo" style={{backgroundColor: `${(states?.sedo === 'err!') ? 'rgba(239, 83, 80, 0.5)' : ''}`}}></div>
                                <div className="wave wave2 waveSedo" style={{backgroundColor: `${(states?.sedo === 'err!') ? 'rgba(239, 83, 80, 0.5)' : ''}`}}></div>
                                <div className='fetchCardStatus'>{(states?.sedo === 'err!') ? 'No Data' : 'Fetched'}</div>
                            </div>
                        </div>
                        <div className="gridItem" style={{height: '150px'}}>
                            <div className="fetchCard">
                                <div>Facebook</div>
                                <div className="wave wave1 waveSedo" style={{backgroundColor: `${(states?.facebook_sedo === 'err!') ? 'rgba(239, 83, 80, 0.5)' : ''}`}}></div>
                                <div className="wave wave2 waveSedo" style={{backgroundColor: `${(states?.facebook_sedo === 'err!') ? 'rgba(239, 83, 80, 0.5)' : ''}`}}></div>
                                <div className='fetchCardStatus'>{(states?.facebook_sedo === 'err!') ? 'No Data' : 'Fetched'}</div>
                            </div>
                        </div>
                        <div className="gridItem" style={{height: '150px'}}>
                            <div className="fetchCard">
                                <div>Taboola</div>
                                <div className="wave wave1 waveSedo" style={{backgroundColor: `${(states?.taboola_sedo === 'err!') ? 'rgba(239, 83, 80, 0.5)' : ''}`}}></div>
                                <div className="wave wave2 waveSedo" style={{backgroundColor: `${(states?.taboola_sedo === 'err!') ? 'rgba(239, 83, 80, 0.5)' : ''}`}}></div>
                                <div className='fetchCardStatus'>{(states?.taboola_sedo === 'err!') ? 'No Data' : 'Fetched'}</div>
                            </div>
                        </div>
                        <div className="gridItem" style={{height: '150px'}}>
                            <div className="fetchCard">
                                <div>Outbrain</div>
                                <div className="wave wave1 waveSedo" style={{backgroundColor: `${(states?.outbrain_sedo === 'err!') ? 'rgba(239, 83, 80, 0.5)' : ''}`}}></div>
                                <div className="wave wave2 waveSedo" style={{backgroundColor: `${(states?.outbrain_sedo === 'err!') ? 'rgba(239, 83, 80, 0.5)' : ''}`}}></div>
                                <div className='fetchCardStatus'>{(states?.outbrain_sedo === 'err!') ? 'No Data' : 'Fetched'}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FetchStates