import React, { useState, useEffect, useContext } from 'react'
import './errorAlert.css'
import axios from 'axios'
import Cookies from 'js-cookie'

import { host } from '../../hooks/host'
import { Context } from '../../AppContext'

const ErrorAlert = () => {
    const appContext = useContext(Context)

    const [loading, setLoading] = useState(false)
    const [ads, setAds] = useState([])
    const [account, setAccount] = useState([])

    useEffect(() => {
        const controller = new AbortController();
        const token = Cookies.get('token')
        const user = Cookies.get('userArray')
        const manager = Cookies.get('manager')
        const url = `${host}/api/unused_domains/error`;
        
        const getData = async () => {
            setLoading(true)
            await axios.post(url, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                    "Authorization": "Bearer "+token,
                    "Content-Type": "application/json",
                },
            },
            {
                signal: controller.signal
            })

            .then((response) => {
                console.log(response)
                const obj = response.data

                if (obj.result) {
                    if (appContext.role === 'admin') {
                        setAds(obj.result.ads ? obj.result.ads.sort((a, b) => a.account_name > b.account_name ? 1 : -1 ) : [])
                        setAccount(obj.result.account ? obj.result.account.sort((a, b) => a.account_name > b.account_name ? 1 : -1 ) : [])
                    } else if (appContext.role === 'widget') {
                        setAds(obj.result.ads ? obj.result.ads.filter(f => f.user === manager).sort((a, b) => a.account_name > b.account_name ? 1 : -1 ) : [])
                        setAccount(obj.result.account ? obj.result.account.filter(f => f.user === manager).sort((a, b) => a.account_name > b.account_name ? 1 : -1 ) : [])
                    } else {
                        setAds(obj.result.ads ? obj.result.ads.filter(f => f.user === user).sort((a, b) => a.account_name > b.account_name ? 1 : -1 ) : [])
                        setAccount(obj.result.account ? obj.result.account.filter(f => f.user === user).sort((a, b) => a.account_name > b.account_name ? 1 : -1 ) : [])
                    }
                } else {
                    window.alert('No data. Please refresh.')
                }

                setLoading(false)
            })

            .catch((error) => {
                console.log(error);
                setLoading(false)
                if (error?.response?.data?.message === 'Invalid') {
                    appContext.forceLogout()
                }
            })
        }
    
        getData()

        return () => {
            controller.abort()
        }
    }, [])

    return (
        <div className="widget-container">
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <p style={{ fontWeight: 700 }}>Error Alert</p>
                {loading ? (<div className="loader" style={{ borderColor: '#0000FF', borderTopColor: '#FFFFFF' }}></div>) : (<></>)}
            </div>

            <div className="ea-container">
                <div className='ea-table-container'>
                    <p className='ea-table-title'>Ads</p>
                    {/* scrollable table */}
                    <div className='ea-table'>
                        <table style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Account</th>
                                    <th>Account ID</th>
                                    <th style={{ minWidth: '200px' }}>Ad</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ads.map((e, i) => (
                                    <tr key={i}>
                                        <td style={{ borderBottom: (ads.length === i+1) ? '0px' : '1px solid #EEEEEE' }}>{i+1}</td>
                                        <td style={{ borderBottom: (ads.length === i+1) ? '0px' : '1px solid #EEEEEE' }}>{e.account_name}</td>
                                        <td style={{ borderBottom: (ads.length === i+1) ? '0px' : '1px solid #EEEEEE' }}>{e.account_id}</td>
                                        <td style={{ borderBottom: (ads.length === i+1) ? '0px' : '1px solid #EEEEEE' }}>{e.name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="ea-table-container">
                    <div className="ea-table-title">Accounts</div>
                    {/* scrollable table */}
                    <div className="ea-table">
                        <table className='ea-table'>
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th style={{ minWidth: '200px' }}>Account</th>
                                    <th>Account ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {account.map((e, i) => (
                                    <tr key={i}>
                                        <td style={{ borderBottom: (account.length === i+1) ? '0px' : '1px solid #EEEEEE' }}>{i+1}</td>
                                        <td style={{ borderBottom: (account.length === i+1) ? '0px' : '1px solid #EEEEEE' }}>{e.account_name}</td>
                                        <td style={{ borderBottom: (account.length === i+1) ? '0px' : '1px solid #EEEEEE' }}>{e.account_id}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ErrorAlert